import { useHistory } from "react-router-dom";

function useBackHistory(defaultUrl = "") {
  let history = useHistory();

  return () => {
    history.length >= 3 ? history.goBack() : history.push(defaultUrl);
  };
}

export default useBackHistory;
