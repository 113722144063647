import React from "react";

import { formatKeyAmount } from "lib/numbers";
import { useAprContext } from "contexts/AprProvider";

export default function APRLabel({ chainId, label }) {
  const processedData = useAprContext();

  return (
    <>
      {formatKeyAmount(processedData[chainId], label, 2, 2, true) !== "..." ? (
        `${formatKeyAmount(processedData[chainId], label, 2, 2, true)}%`
      ) : (
        <span className="skeleton-box" style={{ width: "45px", height: "19.6px" }} />
      )}
    </>
  );
}
