import { FANTOM, OP } from "config/chains";
import { useThemeContext } from "contexts/ThemeProvider";
import useMarketAssetsPick from "hooks/useMarketAssetsPick";
import { Result } from "hooks/useOpenInterestStats";
import NoDataIcon from "img/analytics/no-data-icon.svg";
import { ACTIVE_CHAIN_IDS_V2 } from "lib/legacy";
import { formatNumber } from "lib/numbers";
import { FC, useMemo } from "react";
import { useMedia } from "react-use";
import { Cell, ResponsiveContainer, Tooltip, Treemap } from "recharts";
import styled from "styled-components";
import { isIphoneDevice } from "utils/helpers";
import TinyBarChart from "./TinyBarChart";

interface Props {
  chainId: number;
  data: Result | undefined;
  dailyVolumes: any;
}

const COLORS = ["#4480FF", "#70CE8A", "#FEB13D", "#F6475D", "#5BBCE1", "#F8C12D"];

function CustomizedContent(props) {
  const { root, depth, x, y, width, height, index, payload, colors, rank, name } = props;
  const { isLightTheme } = useThemeContext() as any;

  return (
    <g>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        style={{
          fill: depth < 2 ? colors[Math.floor((index / root?.children?.length) * 6)] : "#ffffff00",
          stroke: "#fff",
          strokeWidth: 2 / (depth + 1e-10),
          strokeOpacity: 1 / (depth + 1e-10),
        }}
      ></rect>
      {depth === 1 ? (
        // <text
        //   x={x + width / 2}
        //   y={y + height / 2 + 7}
        //   textAnchor="middle"
        //   fill="#fff"
        //   fontSize={14}
        // >
        //   {name}
        // </text>
        <foreignObject
          x={x}
          y={y}
          width={width}
          height={height}
          style={{
            position: "relative",
          }}
        >
          <div
            style={{
              width: "100%",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              position: "absolute",
              top: "50%",
              left: isIphoneDevice() ? `${x}px` : "unset",
              transform: "translateY(-50%)",
              textAlign: "center",
              color: isLightTheme ? "#0D1A16" : "#fff",
            }}
          >
            {width > 18 && height > 18 && name}
          </div>
        </foreignObject>
      ) : null}
    </g>
  );
}

const NUMBER_OF_TRENDING = 5;

const StatVolumeAndTrending: FC<Props> = ({ chainId, data, dailyVolumes }) => {
  const isTablet = useMedia("(max-width: 1023px)");

  const assets = useMarketAssetsPick(["symbol"]);

  const trendingAssets = useMemo(() => {
    return Object.entries(data || {})
      .filter((d) => d[1].type !== "other" && Number(d[1].value) != 0)
      .sort((a: any, b: any) => Number(b[1].value) - Number(a[1].value))
      .map(([_, data]) => {
        return {
          name: data.assetSymbol,
          value: +data.value,
        };
      });
  }, [data]);

  const CustomTooltip = (props) => {
    const { active, payload, label, isSwapToUsd, depth } = props;
    if (active && payload) {
      return (
        <div className="custom-tooltip">
          {payload.map((p) => {
            const { payload: data } = p;
            const percentRatio = (data.value * 100) / data.root.value;
            return (
              <div>
                <div className="data-row" key={p.dataKey}>
                  <div
                    className="dot"
                    style={{
                      width: 16,
                      height: 16,
                      backgroundColor:
                        data.depth < 2
                          ? COLORS[Math.floor((data.index / data.root?.children?.length) * 6)]
                          : "#ffffff00",
                    }}
                  />
                  <div
                    className="date"
                    style={{
                      color:
                        data.depth < 2
                          ? COLORS[Math.floor((data.index / data.root?.children?.length) * 6)]
                          : "#ffffff00",
                    }}
                  >
                    {data.name}
                  </div>
                </div>
                <div className="data-row" key={p.dataKey}>
                  <div className="date">Open interest: </div>
                  <div className="date">
                    ${formatNumber(data.value, 2)}
                    {` (${formatNumber(percentRatio, 2)}%)`}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      );
    }
    return null;
  };

  const startTimestamp = {
    [FANTOM]: 1698278400,
    [OP]: 1709683200,
  }[chainId];

  return (
    <Wrapper>
      <div className="inner-chart volume-by-asset-charts">
        <h3 className="title-styled">Volume By Asset</h3>
        {dailyVolumes &&
        Object.keys(dailyVolumes) &&
        Object.keys(dailyVolumes)?.length > 0 &&
        ACTIVE_CHAIN_IDS_V2.includes(chainId) ? (
          <>
            <div className="header">
              <div className="header-title">Asset</div>
              <div className="header-title">Volume</div>
            </div>
            <div className="body">
              {Object.entries(dailyVolumes || {}).map(([assetId, data]) => {
                const filterd = startTimestamp && (data as any).filter((d) => d.timestamp >= startTimestamp);
                return (
                  <div className="body-row">
                    {assets[assetId]?.symbol || "..."}
                    <TinyBarChart data={filterd as any[]} />
                  </div>
                );
              })}
            </div>
          </>
        ) : (
          <div className="no-record-chart">
            <img src={NoDataIcon} className="no-record-icon-styled" />
            <span>No Data</span>
          </div>
        )}
      </div>
      <div className="inner-chart trending-asset-chart">
        <h3 className="title-styled">Trending Assets</h3>
        <ResponsiveContainer width="100%" height={isTablet ? 240 : "100%"}>
          {trendingAssets && trendingAssets.length > 0 && ACTIVE_CHAIN_IDS_V2.includes(chainId) ? (
            <Treemap
              width={400}
              height={200}
              aspectRatio={4 / 3}
              data={trendingAssets.filter((data, i) => i < NUMBER_OF_TRENDING && Number(data.value) !== 0)}
              dataKey="value"
              fill="transparent"
              content={<CustomizedContent colors={COLORS} />}
            >
              <Tooltip content={<CustomTooltip />} />

              {trendingAssets
                .filter((data, i) => i < NUMBER_OF_TRENDING && Number(data.value) !== 0)
                .map((_, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    stroke={COLORS[index % COLORS.length]}
                    style={{
                      cursor: "pointer",
                    }}
                  />
                ))}
            </Treemap>
          ) : (
            <div className="no-data-record">No Data</div>
          )}
        </ResponsiveContainer>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 424px 1fr;
  gap: 24px;
  margin-top: 24px;
  grid-template-rows: 424px;
  rect {
    stroke: none !important;
  }
  text {
    width: 20px !important;
    overflow: hidden !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    border: 1px solid #ccc !important;
  }
  .no-data-record {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.1);
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    border: 1px solid transparent;
    border: 1px solid rgba(13, 26, 22, 0.07) !important;
  }

  .inner-chart {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px;
    background: transparent;
    border-radius: 16px;
    border: 1px solid var(--white-10, rgba(255, 255, 255, 0.1));
    position: relative;
    .no-record-chart {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      span {
        font-size: 14px;
        font-weight: 400;
        line-height: 140%;
      }
    }
    &.volume-by-asset-charts {
      display: flex;
      flex-direction: column;
      align-items: stretch;

      .header {
        display: grid;
        grid-template-columns: 1.5fr 4fr;
        gap: 16px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        padding-bottom: 8px;

        .header-title {
          font-size: 16px;
          font-weight: 500;
          line-height: 120%; /* 19.2px */
        }
      }

      .body {
        /* max-height: 100; */
        overflow-y: auto;
        overflow-x: hidden;
        padding-right: 10px;
        .body-row {
          display: grid;
          grid-template-columns: 1.5fr 4fr;
          align-items: center;
          gap: 16px;
          padding: 12px 0 0;

          font-size: 16px;
          font-weight: 500;
          line-height: 120%; /* 19.2px */

          &:not(:last-child) {
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
          }
        }
        &::-webkit-scrollbar {
          border-radius: 100px;
          right: -5px !important;
        }

        &::-webkit-scrollbar-track {
          border-radius: 100px;
        }

        &::-webkit-scrollbar-thumb {
          width: 8px;
          border-radius: 100px;
        }
      }
    }

    &.trending-asset-chart {
      display: flex;
      align-items: stretch;
      flex-direction: column;
    }
    .title-styled {
      font-size: 16px;
      font-weight: 500;
      line-height: 120%;
    }

    @media screen and (max-width: 767px) {
      padding: 16px;
    }
  }
  @media screen and (max-width: 1023px) {
    grid-template-columns: 1fr;
  }

  @media screen and (max-width: 767px) {
  }
`;

export default StatVolumeAndTrending;
