import fantom from "img/home/fantom.svg";
import wonderland from "img/home/wonderland.svg";
import mexc from "img/home/mexc.svg";
import spooky from "img/home/spooky.svg";
import velodrome from "img/home/velodrome.svg";
import oneInch from "img/home/1inch.svg";
import openOcean from "img/home/openOcean.svg";
import fireBird from "img/home/fireBird.svg";
import equalizer from "img/home/equalizer.svg";
import kyberSwap from "img/home/kyberSwap.svg";
import chainlink from "img/home/chainlink.svg";
import pyth from "img/home/pyth.svg";
import layerZero from "img/home/layerZero.svg";
import lBank from "img/home/lbBank.svg";
import qSwap from "img/home/qSwap.svg";
import odos from "img/home/odos.svg";
import voyager from "img/home/voyager.svg";
import tokenTerminal from "img/home/tokenTerminal.svg";
import deBank from "img/home/deBank.svg";
import defiLlama from "img/home/defiLlama.svg";
import coinGecko from "img/home/coinGecko.svg";
import coinMarketCap from "img/home/coinMarketCap.svg";
import dappRadar from "img/home/dappRadar.svg";

const PARTNERS = [
  {
    img: fantom,
    link: "https://fantom.foundation/",
  },
  {
    img: layerZero,
    link: "https://layerzero.network/",
  },
  {
    img: pyth,
    link: "https://pyth.network/",
  },
  {
    img: chainlink,
    link: "https://chain.link/",
  },
  {
    img: wonderland,
    link: "https://wonderland.money/",
  },
  {
    img: mexc,
    link: "https://www.mexc.com/",
  },
  // {
  //   img: spooky,
  //   link: "https://spooky.fi/#/swap",
  // },
  {
    img: velodrome,
    link: "https://velodrome.finance/",
  },
  {
    img: oneInch,
    link: "https://app.1inch.io/",
  },
  {
    img: openOcean,
    link: "https://openocean.finance/",
  },
  {
    img: fireBird,
    link: "https://firebird.finance/",
  },
  {
    img: equalizer,
    link: "https://equalizer.exchange/",
  },
  // {
  //   img: kyberSwap,
  //   link: "https://kyberswap.com/",
  // },
  // {
  //   img: lBank,
  //   link: "https://www.lbank.com/",
  // },
  {
    img: qSwap,
    link: "https://o3swap.com/",
  },
  {
    img: odos,
    link: "https://odos.xyz/",
  },
  {
    img: voyager,
    link: "https://app.thevoyager.io/",
  },
  {
    img: tokenTerminal,
    link: "https://tokenterminal.com/",
  },
  {
    img: deBank,
    link: "https://debank.com/",
  },
  {
    img: defiLlama,
    link: "https://defillama.com/",
  },
  {
    img: coinGecko,
    link: "https://www.coingecko.com/",
  },
  {
    img: coinMarketCap,
    link: "https://coinmarketcap.com/",
  },
  {
    img: dappRadar,
    link: "https://dappradar.com/",
  },
];

export default PARTNERS;
