import { getMarketTokensInfo } from "lib/legacy";
import useAssetsPrices from "./useAssetsPrices";
import useMarketAssets from "./useMarketAssets";

function useMarketTokensInfo() {
  const assets = useMarketAssets();
  const assetInArray = Object.values(assets);
  const marketPrices = useAssetsPrices();

  const marketTokensInfo = getMarketTokensInfo(assetInArray, marketPrices);

  return { marketTokensInfo, assets };
}

export default useMarketTokensInfo;
