import { BigNumber, ethers } from "ethers";
import useSWR from "swr";

import { getContract } from "config/contracts";
import { formatMulticallReponse } from "lib/contracts";

import VaultNav from "abis/VaultNav.json";
import Multicall from "abis/Multicall.json";

import { useEffect, useState } from "react";
import { getProvider } from "lib/rpc";
import multicall from "domain/multicall";

export default function useVaultNavContract(chainId: number, library?: any) {
  const [data, setData] = useState({});

  const vaultMslpAddress = getContract(chainId, "VaultMsp");
  const multicallAddress = getContract(chainId, "Multicall");

  useEffect(() => {
    const fetch = async () => {
      try {
        const provider = getProvider(library, chainId);
        if (!provider) return;
        const multicallContract = new ethers.Contract(multicallAddress, Multicall.abi, provider);
        const calls = [
          {
            address: vaultMslpAddress,
            name: "getNLPPrice",
            params: [],
          },
          {
            address: vaultMslpAddress,
            name: "getVaultUSDBalance",
            params: [],
          },
          {
            address: vaultMslpAddress,
            name: "totalUSD",
            params: [],
          },
        ];

        const keyOfCalls = calls.map((call) => call.name);
        const response = await multicall(
          multicallContract,
          VaultNav.abi,
          calls.map(({ address, name, params }) => ({
            address,
            name,
            params,
          }))
        );
        const result = {};
        for (const index in keyOfCalls) {
          result[keyOfCalls[index]] = response[index];
        }

        setData(formatMulticallReponse(result));
      } catch (error) {
        console.error("[ERROR]: useVaultNavContract", error);
      }
    };

    fetch();
    const timerId = setInterval(fetch, 30000);

    return () => clearInterval(timerId);
  }, [chainId, library, multicallAddress, vaultMslpAddress]);

  return {
    nlpPrice: data["getNLPPrice"],
    vaultUSDBalance: data["getVaultUSDBalance"],
    totalUSD: data["totalUSD"],
  };
}
