export default function formatPrice(price: number, decimal = 2) {
  if (price === 0) {
    return "0.00";
  }
  if (price) {
    const splittedPrice = price.toString().split(".");
    const handledPrice = `${splittedPrice[0] && Number(splittedPrice[0]).toLocaleString("en-US")}.${
      splittedPrice[1] && splittedPrice[1].length >= 1 ? splittedPrice[1].slice(0, decimal) : "00"
    }`;
    return handledPrice;
  }
  return "--";
}

export function formatShortNumber(number, decimalVal?: number) {

  const decimal = decimalVal ?? 2;
  const numberConvert = typeof number === "number" ? number : parseFloat(number.replace(/,/g, ""));

  if (numberConvert >= 1e18) {
    return  "∞";
  } else if (numberConvert >= 1e15) {
    return (numberConvert / 1e15).toFixed(decimal) + "Q";
  } else if (numberConvert >= 1e12) {
    return (numberConvert / 1e12).toFixed(decimal) + "T";
  } else if (numberConvert >= 1e9) {
    return (numberConvert / 1e9).toFixed(decimal) + "B";
  } else if (numberConvert >= 1e6) {
    return (numberConvert / 1e6).toFixed(decimal) + "M";
  } else if (numberConvert >= 1e3) {
    return (numberConvert / 1e3).toFixed(decimal) + "K";
  }
  return numberConvert.toFixed(decimal);
}
