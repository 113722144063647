import { useCallback, useEffect, useState } from "react";
import { useChainId } from "lib/chains";
import { getServerBaseUrl, getServerUrl } from "config/backend";
import axios from "axios";
const useAssetsPrices = () => {
  const [assets, setAssets] = useState({});
  const { chainId } = useChainId();
  const fetchIds = useCallback(async () => {
    const res = await axios.get(`${getServerBaseUrl(chainId, true)}/assets/prices`, {});
    if (res && res.data?.data) {
      const data = res.data.data || [];
      setAssets(data);
    }
  }, [chainId]);

  useEffect(() => {
    fetchIds();
    const interval = setInterval(async () => {
      fetchIds();
    }, 7000);
    return () => clearInterval(interval);
  }, [fetchIds]);

  return assets;
};

export default useAssetsPrices;
