import { FiX } from "react-icons/fi";
import logoImg from "img/logo_MMY_v2.svg";
import { t } from "@lingui/macro";

import "./Header.scss";
import { Link } from "react-router-dom";
import ExternalLink from "components/ExternalLink/ExternalLink";

type Props = {
  small?: boolean;
  clickCloseIcon?: () => void;
};

const HOME_MENUS = [
  {
    label: `App`,
    link: "https://app.mummy.finance/#/trade",
    isTargetSelf: true,
  },
  {
    label: `Protocol`,
    link: "https://github.com/mummy-finance",
  },
  {
    label: `Governance`,
    link: "https://gov.mummy.finance",
  },
  // {
  //   label: `Voting`,
  //   link: "https://snapshot.org/#/gmx.eth",
  // },
  {
    label: `Docs`,
    link: "https://docs.mummy.finance/",
  },
];

export function HomeHeaderLinks({ small, clickCloseIcon }: Props) {
  return (
    <div className="App-header-links">
      {small && (
        <div className="App-header-links-header">
          <Link className="App-header-link-main" to="/">
            <img src={logoImg} alt="MMY Logo" />
          </Link>
          <div
            className="App-header-menu-icon-block mobile-cross-menu"
            onClick={() => clickCloseIcon && clickCloseIcon()}
          >
            <FiX className="App-header-menu-icon" />
          </div>
        </div>
      )}
      {HOME_MENUS.map(({ link, label, isTargetSelf }) => (
        <div key={label} className="App-header-link-container head-landing">
          <ExternalLink target={isTargetSelf ? "_self" : "_blank"} href={link}>
            {label}
          </ExternalLink>
        </div>
      ))}
    </div>
  );
}
