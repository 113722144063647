const GreenArraowIcon = (props: JSX.IntrinsicElements["svg"]) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M9.33333 3.28662L8.33333 4.28662L11.3802 7.3335H2V8.66683H11.3802L8.33333 11.7137L9.33333 12.7137L14.0469 8.00016L9.33333 3.28662Z"
        fill={props.fill ? props.fill : "#02B27F"}
      />
    </svg>
  );
};

export default GreenArraowIcon;
