import React, { useState } from "react";
import styled, { css } from "styled-components";

import arrow from "img/trade/arrow-down.svg";
import arrowLight from "img/trade/arrow-downLight.svg";
import { useThemeContext } from "contexts/ThemeProvider";

const RowsDropdown = ({ handler, children }) => {
  const [isExpand, setIsExpand] = useState(false);
  return (
    <div style={{ width: "100%" }}>
      {React.cloneElement(handler, {
        onClick: () => setIsExpand((prev) => !prev),
        isHandler: true,
        isExpand: isExpand,
      })}
      <ExpandSection $isExpand={isExpand}>{children}</ExpandSection>
    </div>
  );
};

export const RowsDropdownHandler = ({ children, isExpand, ...props }) => {
  const { isLightTheme } = useThemeContext();
  return (
    <RowsDropdownHandlerWrapper $isExpand={isExpand} {...props} className="row-dropdown">
      {children}
      <img src={isLightTheme ? arrowLight : arrow} alt="arrow" />
    </RowsDropdownHandlerWrapper>
  );
};

const RowsDropdownHandlerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  color: var(--white-100, #fff);
  font-size: 14px;
  font-weight: 500;
  line-height: 120%; /* 16.8px */

  img {
    width: 16px;
    height: 16px;
    rotate: 180deg;
  }

  ${({ $isExpand }) =>
    $isExpand &&
    css`
      img {
        rotate: 0deg;
      }
    `}
`;

const ExpandSection = styled.div`
  height: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-self: stretch;

  .handler {
    grid-template-columns: 1fr auto auto;
    cursor: pointer;
  }

  .isExpand img {
    rotate: 0deg !important;
  }

  ${({ $isExpand }) =>
    $isExpand &&
    css`
      margin-top: 12px;
      height: unset;
    `}
`;

export default RowsDropdown;
