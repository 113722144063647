import React, { memo } from "react";

const Icon1 = (props) => (
  <svg
    width="142"
    height="40"
    viewBox="0 0 142 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={props.class}
  >
    <mask
      id="mask0_207_9746"
      maskUnits="userSpaceOnUse"
      x="1"
      y="4"
      width="140"
      height="32"
    >
      <path d="M140.594 4H1.07422V36H140.594V4Z" fill="white" />
    </mask>
    <g mask="url(#mask0_207_9746)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.12391 7.52062L1.91703 7.45672L1.91156 8.67482L1.91095 8.92872C1.91059 9.12652 1.91011 9.46792 1.90963 10.0432C1.90867 11.1937 1.90771 13.28 1.90771 17.0239V17.5048L3.3377 18.9461C2.77149 19.3051 2.2026 19.66 1.63111 20.0108L1.07869 20.3499L1.07422 28.3793L7.67016 35.0315L8.10316 35.06C16.1967 35.5928 24.5203 35.0016 32.319 32.2899L33.0953 32.02V22.3714L31.4081 20.671C31.6956 20.385 31.9824 20.0965 32.2671 19.8063L32.5999 19.4671V18.9899C32.5999 18.6607 32.6009 16.9361 32.6018 15.2931L32.604 11.609L25.8102 4.76172L25.134 4.99002C18.1126 7.36072 10.5818 7.91522 3.12391 7.52062ZM25.5 6.09092L31.4518 12.0896C31.4518 12.0896 31.4479 18.3304 31.4479 18.9899C31.165 19.2783 30.8797 19.5652 30.5935 19.85C30.3194 20.1227 30.0443 20.3934 29.7696 20.6615L31.9433 22.8523V31.1923C24.3321 33.8389 16.1718 34.4277 8.17826 33.9014L2.22645 27.8989L2.23029 21.0024C2.88115 20.6029 3.52872 20.1981 4.17291 19.7879C4.50535 19.5762 4.83688 19.363 5.16749 19.1483L3.05967 17.0239C3.05967 13.0501 3.06075 10.944 3.06177 9.84262C3.06267 8.86862 3.06352 8.68002 3.06352 8.68002C3.44712 8.70032 3.83107 8.71812 4.21531 8.73342C11.3915 9.01802 18.6638 8.39912 25.5 6.09092Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.06363 8.67992C10.6116 9.07932 18.2978 8.52262 25.5001 6.09082L31.4519 12.0895C31.4519 12.0895 31.4481 18.3303 31.4481 18.9898C30.8942 19.5544 30.3313 20.1133 29.7697 20.6614L31.9434 22.8522V31.1922C24.3323 33.8388 16.1719 34.4276 8.17835 33.9013L2.22656 27.8988L2.23041 21.0023C3.21714 20.3967 4.19633 19.7788 5.16762 19.1482L3.05978 17.0238C3.05978 9.53642 3.06363 8.67992 3.06363 8.67992Z"
        fill="#0D0D0D"
      />
      <path
        d="M29.1462 15.124V18.4677C25.433 22.036 21.7198 25.1475 18.0105 27.9224C21.8888 27.6631 25.7632 27.04 29.6453 25.9099C29.6453 27.1445 29.6453 28.3752 29.6453 29.6098C24.2925 31.1733 18.9436 31.7654 13.5946 31.7654C12.5579 31.7654 11.5211 31.7422 10.4844 31.6996V28.3558C14.1975 25.9757 17.9107 23.3479 21.62 20.3409C18.9513 20.6853 16.2825 20.8324 13.6138 20.8324C12.8458 20.8324 12.0817 20.8208 11.3138 20.7975C11.3138 19.563 11.3138 18.3323 11.3138 17.0977C12.074 17.121 12.8305 17.1325 13.5908 17.1325C18.7746 17.1325 23.9585 16.5791 29.1423 15.1201L29.1462 15.124Z"
        fill="white"
      />
      <path
        d="M65.6714 27.4326V23.1416H54.586L64.9418 16.663V12.5684H46.7843V16.8594H56.4105L46.0547 23.3379V27.4326H65.6714Z"
        fill={props.fill ?? "white"}
      />
      <path
        d="M87.6001 22.636H81.2577C80.6962 23.4774 79.9665 23.7578 77.1883 23.7578C74.1292 23.7578 73.063 23.4493 72.9787 21.0093H87.7122V19.4948C87.7122 13.9418 84.4007 12.2871 77.3004 12.2871H76.8514C69.7511 12.2871 66.6641 13.9418 66.6641 19.4948V20.5045C66.6641 26.0575 69.7511 27.7123 76.8514 27.7123H77.3564C84.5692 27.7123 86.7862 26.31 87.6001 22.636ZM77.1883 15.877C80.135 15.877 81.398 16.3257 81.398 18.1206V18.2328H73.0066C73.2033 16.1855 74.3819 15.877 77.1883 15.877Z"
        fill={props.fill ?? "white"}
      />
      <path
        d="M109.763 27.4318V18.6254C109.763 13.7735 107.714 12.2871 100.418 12.2871H99.8842C92.8682 12.2871 90.7072 13.9979 89.6688 17.111H96.2361C96.6848 16.2696 97.4148 15.905 99.7721 15.905C102.55 15.905 103.449 16.3257 103.449 18.0365V18.2609L94.2996 18.5694C91.0441 18.6815 89.0234 19.7753 89.0234 22.6921V22.9164C89.0234 26.0856 91.1562 27.7123 96.2922 27.7123C100.446 27.7123 102.522 26.6746 103.28 24.7955H103.449V27.4318H109.763ZM95.394 22.8043V22.6921C95.394 21.8227 96.0116 21.458 97.5551 21.402L103.449 21.2057V21.402C103.449 23.253 101.568 24.3187 98.481 24.3187C96.2357 24.3187 95.394 23.898 95.394 22.8043Z"
        fill={props.fill ?? "white"}
      />
      <path d="M117.904 27.4318V7.7998H111.59V27.4318H117.904Z" fill={props.fill ?? "white"}/>
      <path
        d="M130.349 32.2004L140.592 12.5684H134.137L130.096 21.4028H129.9L125.69 12.5684H118.898L126.504 27.5167L123.725 32.2004H130.349Z"
        fill={props.fill ?? "white"}
      />
    </g>
  </svg>
);
export default memo(Icon1);
