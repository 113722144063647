import styled from "styled-components";

import backIc from "img/ic_arrowleft16.svg";
import { useHistory } from "react-router-dom";

const StyledBackUrl = styled.span`
  text-decoration: none;
  text-transform: uppercase;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  color: #34f5ae;
  transition: 500ms;
  cursor: pointer;

  img {
    filter: invert(66%) sepia(41%) saturate(684%) hue-rotate(105deg) brightness(114%) contrast(92%);
  }

  /* &:hover {
    opacity: 0.7;
  } */
`;

const StyledBackUrl1 = styled.span`
  display: inline-flex;
  padding: var(--Border_radius, 4px);
  align-items: flex-start;
  gap: 10px;
  border-radius: var(--Base-Unit-XS-8, 8px);
  background: var(--Nature-2, #20233b);
  cursor: pointer;

  &:hover {
    path {
      fill: #34f5ae;
    }
  }
`;

const BackUrl = ({ defaultUrl = "", isV2 = false }) => {
  let history = useHistory();

  if (isV2) {
    return (
      <StyledBackUrl1
        onClick={() => {
          history.length >= 3 ? history.goBack() : history.push(defaultUrl);
        }}
        className="back-url-styled"
      >
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_12266_16505)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M15 17.2969C14.9503 17.1035 14.8218 16.9655 14.6849 16.8288C13.1041 15.2512 11.5259 13.671 9.94425 12.0942C9.86866 12.0188 9.8689 11.981 9.94437 11.9058C11.5261 10.3291 13.1044 8.749 14.6853 7.1714C14.8222 7.0347 14.9506 6.8966 15 6.7031V6.4922C14.9218 6.2423 14.7587 6.0772 14.5078 6H14.2734C14.0819 6.0617 13.9438 6.1957 13.8055 6.3341C12.1154 8.0261 10.4241 9.71686 8.73145 11.4063C8.59038 11.5471 8.45201 11.6866 8.39062 11.8828V12.1172C8.45299 12.3128 8.58982 12.4533 8.73112 12.5943C10.4239 14.2835 12.1152 15.9742 13.8051 17.6663C13.9435 17.8049 14.0822 17.9379 14.2734 18H14.5078C14.5535 17.9807 14.5994 17.962 14.6448 17.9421C14.8371 17.8579 14.9314 17.6933 15 17.5078V17.2969Z"
              fill="white"
              stroke="#ADABAB"
              stroke-width="0.2"
            />
          </g>
          <defs>
            <clipPath id="clip0_12266_16505">
              <rect width="8" height="14" fill="white" transform="matrix(-1 0 0 -1 16 19)" />
            </clipPath>
          </defs>
        </svg>
      </StyledBackUrl1>
    );
  }
  return (
    <StyledBackUrl
      onClick={() => {
        // console.log(history, defaultUrl);
        history.length >= 3 ? history.goBack() : history.push(defaultUrl);
      }}
      className="back-url-styled"
    >
      <img src={backIc} alt="" />
      Back
    </StyledBackUrl>
  );
};

export default BackUrl;
