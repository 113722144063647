import React, { useEffect, useMemo, useState } from "react";
import { Switch } from "antd";
import { formatAmount, parseValue } from "lib/numbers";
import { Trans } from "@lingui/macro";
import cx from "classnames";
import { USD_DECIMALS, getPnlWithoutFee } from "lib/legacy";
const StopLossCard = ({
  marketPrice,
  onTakeProfitPrice,
  onTakeProfitPercentage,
  posQty,
  marketSymbol,
  isLong,
  onActiveStopLoss,
  onChaneTriggerPrice,
  onChangeCloseQuantity,
  liquidPrice,
  show,
  setShow,
  stopLossTriggerError,
}) => {
  const [percentTrigger, setValTrigger] = useState(5);
  const [closePercentQuantity, setPercentQuantity] = useState(100);
  const [closeQuantity, setCloseQuantity] = useState("0");
  const [triggerPrice, setTriggerPrices] = useState("0");
  const [isLoaded, setIsLoaded] = useState(false);
  const onChange = (checked) => {
    onActiveStopLoss && onActiveStopLoss(!show);
    setShow(!show);
  };

  useEffect(() => {
    if (marketPrice && marketPrice.gt(0) && percentTrigger === 5 && !isLoaded) {
      onChangeTriggertInput(5);
      setIsLoaded(true);
    }
  }, [marketPrice]);

  useEffect(() => {
    if (!show) {
      onChangeTriggertInput(5);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const onChangeTriggerPercent = (e) => {
    const value = e.target.value;
    if (!value.includes("-")) {
      if (value <= (isLong ? 1000000 : 99)) {
        if (value.includes(".")) {
          const arr = value.split(".")[1];
          if (arr.length <= 2) {
            setValTrigger(value);
            onChangeTriggertInput(value);
            // console.log("????1");
            // onChaneTriggerPrice && onChaneTriggerPrice(value);
          }
        } else {
          setValTrigger(value);
          onChangeTriggertInput(value);
          // console.log("????2");
          // onChaneTriggerPrice && onChaneTriggerPrice(value);
        }
      }
    }
  };

  const onChangeTriggertInput = (percent) => {
    const quantity = percent;

    if (marketPrice && quantity) {
      const value = !isLong
        ? marketPrice
            .mul(parseValue(1, USD_DECIMALS))
            .add(marketPrice.mul(parseValue(quantity / 100, USD_DECIMALS)))
        : marketPrice
            .mul(parseValue(1, USD_DECIMALS))
            .sub(marketPrice.mul(parseValue(quantity / 100, USD_DECIMALS)));
      const finalValue = formatAmount(value, USD_DECIMALS + USD_DECIMALS, 4, false);
      // console.log("????3", finalValue);
      setTriggerPrices(finalValue);
      onChaneTriggerPrice && onChaneTriggerPrice(finalValue);
    }
  };

  const onHandleTriggerPercentageBtnClick = (value) => {
    setValTrigger(value);
    onChangeTriggertInput(value);
  };

  const onTriggerValueChange = (e) => {
    const value = e.target.value;

    if (!value.includes("-")) {
      const numberValue = Number(value);
      if (!value || value === "0") {
        setTriggerPrices(value);
        // console.log("????4");
        onChaneTriggerPrice && onChaneTriggerPrice(value);
        return;
      }
      if (numberValue && marketPrice) {
        if (!isLong) {
          // if (parseValue(value, USD_DECIMALS).gt(marketPrice)) {

          // }
          const val = parseValue(value, USD_DECIMALS)
            .sub(marketPrice)
            .mul(parseValue(100, USD_DECIMALS))
            .div(marketPrice);
          const finalAmount = Number(formatAmount(val, USD_DECIMALS, 2, false));
          setValTrigger(finalAmount);
          setTriggerPrices(value);
          // console.log("????5");
          onChaneTriggerPrice && onChaneTriggerPrice(value);
        } else {
          // if (parseValue(value, USD_DECIMALS).lt(marketPrice)) {

          // }
          const val = marketPrice
            .sub(parseValue(value, USD_DECIMALS))
            .mul(parseValue(100, USD_DECIMALS))
            .div(marketPrice);
          const finalAmount = Number(formatAmount(val, USD_DECIMALS, 2, false));
          setValTrigger(finalAmount);
          setTriggerPrices(value);
          // console.log("????6");
          onChaneTriggerPrice && onChaneTriggerPrice(value);
        }
      }
    }
  };

  useEffect(() => {
    if (posQty && closePercentQuantity === 100) {
      onChangeQuantitytInput(100);
    }
  }, [posQty, closePercentQuantity]);

  const onChangeQuantityPercent = (e) => {
    const value = e.target.value;
    if (!value.includes("-")) {
      if (value <= 100) {
        if (value.includes(".")) {
          const arr = value.split(".")[1];
          if (arr.length <= 2) {
            setPercentQuantity(value);
            onChangeCloseQuantity && onChangeCloseQuantity(value);
          }
        } else {
          setPercentQuantity(value);
          onChangeCloseQuantity && onChangeCloseQuantity(value);
        }
        onChangeQuantitytInput(value);
      }
    }
  };
  const onChangeQuantitytInput = (percent) => {
    const quantity = percent;
    if (posQty && quantity) {
      const value = posQty.mul(parseValue(quantity / 100, 5));
      const finalValue = formatAmount(value, 5 + 5, 8, false);
      setCloseQuantity(finalValue);
    }
  };
  const onHandlePercentageBtnClick = (value) => {
    setPercentQuantity(value.toString());
    onChangeCloseQuantity && onChangeCloseQuantity(value);
    onChangeQuantitytInput(value);
  };
  const onCloseQuantityValueChange = (e) => {
    const value = e.target.value;

    if (!value.includes("-")) {
      const numberValue = Number(value);
      if (numberValue && posQty) {
        if (parseValue(value, 5).gt(posQty)) {
          setPercentQuantity(100);
          onChangeCloseQuantity && onChangeCloseQuantity(100);
        } else {
          const percentBig = parseValue(value, 5).mul(parseValue(100, 5)).div(posQty);

          const percent = Number(formatAmount(percentBig, 5, 2, false));
          setPercentQuantity(percent);
          onChangeCloseQuantity && onChangeCloseQuantity(percent);
        }
      } else {
        setPercentQuantity(0);
        onChangeCloseQuantity(0);
      }
      setCloseQuantity(value);
    }
  };
  const estPnl =
    triggerPrice && closeQuantity && marketPrice.gt(0) & show
      ? getPnlWithoutFee(
          isLong,
          parseValue(triggerPrice, 30),
          marketPrice,
          parseValue(closeQuantity, 30).mul(marketPrice).div(parseValue(1, 30)),
        )
      : null;
  return (
    <div className="toogle-card-container">
      <div className="title-container">
        <div className="title">Stop Loss</div>
        <Switch defaultChecked onChange={onChange} checked={show} />
      </div>
      {show && (
        <>
          <div className="Exchange-swap-section exchange-section-first">
            <div className="Exchange-swap-section-top">
              <div className="muted">
                <div className="Exchange-swap-usd">
                  <Trans>Trigger Price</Trans>
                </div>
              </div>
              {/* <div className="muted align-right clickable">
                <Trans>Balance: 1.9001</Trans>
              </div> */}
            </div>
            <div className="Exchange-swap-section-bottom">
              <div className="Exchange-swap-input-container">
                <input
                  type="number"
                  min="0"
                  placeholder="0.0"
                  className="Exchange-swap-input"
                  value={triggerPrice}
                  onChange={onTriggerValueChange}
                />
                <div className="PositionEditor-token-symbol">USD</div>
              </div>
            </div>
          </div>
          {stopLossTriggerError && <span className="validate-error">{stopLossTriggerError}</span>}
          <div className="percentage-btn-container">
            <div className="percentahe-value">
              <input onChange={onChangeTriggerPercent} value={percentTrigger} placeholder="0" />
              <span>%</span>
            </div>
            {[0.01, 0.02, 0.03, 0.05].map((item) => (
              <div
                className={`percentage-btn ${
                  item * 100 == percentTrigger ? "percentage-btn-selected" : ""
                }`}
                key={item}
                onClick={() => onHandleTriggerPercentageBtnClick(item * 100)}
              >
                {`${item * 100}%`}
              </div>
            ))}
          </div>
          <div
            className="Exchange-swap-section exchange-section-first"
            style={{ marginTop: "16px" }}
          >
            <div className="Exchange-swap-section-top">
              <div className="muted">
                <div className="Exchange-swap-usd">
                  <Trans>Close Quantity</Trans>
                </div>
              </div>
              {/* <div className="muted align-right clickable">
                <Trans>Balance: 1.9001</Trans>
              </div> */}
            </div>
            <div className="Exchange-swap-section-bottom">
              <div className="Exchange-swap-input-container">
                <input
                  type="number"
                  min="0"
                  placeholder="0.0"
                  className="Exchange-swap-input"
                  value={closeQuantity}
                  onChange={onCloseQuantityValueChange}
                />
                <div className="PositionEditor-token-symbol">{marketSymbol}</div>
              </div>
            </div>
          </div>
          <div className="percentage-btn-container">
            <div className="percentahe-value">
              <input
                onChange={onChangeQuantityPercent}
                value={closePercentQuantity}
                placeholder="0"
              />
              <span>%</span>
            </div>
            {[0.25, 0.5, 0.75, 1].map((item) => (
              <div
                className={`percentage-btn ${
                  item * 100 == closePercentQuantity ? "percentage-btn-selected" : ""
                }`}
                key={item}
                onClick={() => onHandlePercentageBtnClick(item * 100)}
              >
                {`${item * 100}%`}
              </div>
            ))}
          </div>
          <div className="Exchange-info-row" style={{ marginTop: "16px" }}>
            <div className="Exchange-info-label">
              <Trans>Est. Loss</Trans>
            </div>
            <div className="align-right">
              <div
                className={cx("Exchange-list-info-label", {
                  positive: estPnl && estPnl.gt(0),
                  negative: estPnl && estPnl.lt(0),
                })}
                style={{ fontSize: "14px" }}
              >
                {estPnl && !estPnl.eq(0) ? (estPnl && estPnl.gt(0) ? "+" : "-") : ""}$
                {estPnl && !estPnl.eq(0)
                  ? formatAmount(estPnl, 30, 2, true).replace("-", "")
                  : "--"}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default StopLossCard;
