import { useWeb3React } from "@web3-react/core";
import { BigNumber, ethers } from "ethers";
import { useCallback, useEffect, useRef, useState } from "react";
import ReaderMsp from "abis/ReaderMsp.json";
import OrderVaultMsp from "abis/OrderVaultMsp.json";
import PositionVault from "abis/PositionVault.json";
import SettingsManager from "abis/SettingsManager.json";
import Multicall from "abis/Multicall.json";
import { getContract } from "config/contracts";
import { useChainId } from "lib/chains";

import {
  convertPositionData,
  DEFAULT_SLIPPAGE_AMOUNT,
  getLiquidPriceV2,
  POSITION_TYPES,
  USD_DECIMALS,
} from "lib/legacy";
import { useLocalStorageSerializeKey } from "lib/localStorage";
import { SLIPPAGE_BPS_KEY } from "config/localStorage";
import { formatAmount, parseValue } from "lib/numbers";
import multicall from "domain/multicall";
import { SUPPORTED_CHAIN_IDS, SUPPORTED_V2_CHAINS } from "config/chains";

const useUserOrdersInfo = (account) => {
  const { chainId } = useChainId();
  const {  library } = useWeb3React();

  const [listOrders, setListOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const readerAddress = getContract(chainId, "ReaderMsp");
  const orderVaultAddress = getContract(chainId, "OrderVaultMsp");
  const positionVaultAddress = getContract(chainId, "PositionVault");
  const settingsManagerAddress = getContract(chainId, "SettingsManager");
  const multicallAddress = getContract(chainId, "Multicall");
  var timeout = useRef(null);
  var oldAccount = useRef(null);
  const [savedSlippageAmount] = useLocalStorageSerializeKey([chainId, SLIPPAGE_BPS_KEY], DEFAULT_SLIPPAGE_AMOUNT);
  const getTradeData = useCallback(async () => {
    if (account) {
      const readerContract = new ethers.Contract(readerAddress, ReaderMsp.abi, library?.getSigner());
      const orderVaultContract = new ethers.Contract(orderVaultAddress, OrderVaultMsp.abi, library?.getSigner());
      const positionVaultContract = new ethers.Contract(positionVaultAddress, PositionVault.abi, library?.getSigner());
      const settingsManagerContract = new ethers.Contract(
        settingsManagerAddress,
        SettingsManager.abi,
        library?.getSigner()
      );
      const multicallContract = new ethers.Contract(multicallAddress, Multicall.abi, library?.getSigner());
      if (readerContract && account) {
        const [tradeData, limitOdersId] = await Promise.all([
          readerContract.getUserAlivePositions(account),
          positionVaultContract.getUserOpenOrderIds(account),
        ]);
        const mergedLimitOrderCalls = [
          ...limitOdersId.map((element) => ({
            address: orderVaultAddress,
            name: "getOrder",
            params: [element],
          })),
          ...limitOdersId.map((element) => ({
            address: orderVaultAddress,
            name: "getTriggerOrderInfo",
            params: [element],
          })),
        ];
        const mergedOrderData = await multicall(multicallContract, OrderVaultMsp.abi, mergedLimitOrderCalls);

        const limitOders = mergedOrderData.slice(0, limitOdersId.length);
        const limitTriggerOders = mergedOrderData.slice(limitOdersId.length, mergedOrderData.length);

        // const limitOders = await multicall(
        //   multicallContract,
        //   OrderVaultMsp.abi,
        //   limitOdersId.map((element) => ({
        //     address: orderVaultAddress,
        //     name: "getOrder",
        //     params: [element],
        //   }))
        // );

        // const limitTriggerOders = await multicall(
        //   multicallContract,
        //   OrderVaultMsp.abi,
        //   limitOdersId.map((element) => ({
        //     address: orderVaultAddress,
        //     name: "getTriggerOrderInfo",
        //     params: [element],
        //   }))
        // );

        const limitPositions = await multicall(
          multicallContract,
          PositionVault.abi,
          limitOdersId.map((element) => ({
            address: positionVaultAddress,
            name: "getPosition",
            params: [element],
          }))
        );

        const mergedThresholdCalls = [
          ...tradeData[1].map((element) => ({
            address: settingsManagerAddress,
            name: "liquidateThreshold",
            params: [element.tokenId],
          })),
          ...limitPositions.map((element) => ({
            address: settingsManagerAddress,
            name: "liquidateThreshold",
            params: [element[0]?.tokenId],
          })),
        ];
        const mergedThresholdData = await multicall(multicallContract, SettingsManager.abi, mergedThresholdCalls);

        const listLiquidateThreshold = mergedThresholdData.slice(0, tradeData[1].length);
        const listLiquidateThresholdLimit = mergedThresholdData.slice(tradeData[1].length, mergedThresholdData.length);

        // const listLiquidateThreshold = await multicall(
        //   multicallContract,
        //   SettingsManager.abi,
        //   tradeData[1].map((element) => ({
        //     address: settingsManagerAddress,
        //     name: "liquidateThreshold",
        //     params: [element.tokenId],
        //   }))
        // );

        // const listLiquidateThresholdLimit = await multicall(
        //   multicallContract,
        //   SettingsManager.abi,
        //   limitPositions.map((element) => ({
        //     address: settingsManagerAddress,
        //     name: "liquidateThreshold",
        //     params: [element[0]?.tokenId],
        //   }))
        // );

        const orders = getListOrderTriggers(
          tradeData,
          listLiquidateThreshold,
          limitOders,
          limitPositions,
          limitOdersId,
          limitTriggerOders,
          listLiquidateThresholdLimit
        );
        // console.log("????", orders);

        setListOrders(orders);
        setLoading(false);
      }
    }
  }, [account, library, readerAddress, settingsManagerAddress, positionVaultAddress, orderVaultAddress]);

  useEffect(() => {
    if (account && SUPPORTED_V2_CHAINS.includes(chainId)) {
      getTradeData();
      const clear = setInterval(() => {
        getTradeData().catch((err) => console.error(err.stack));
      }, 5000);

      return () => clearInterval(clear);
    } else {
      setLoading(false);
      setListOrders([]);
    }
  }, [account, chainId]);
  return { positions: listOrders, loading };
};

export default useUserOrdersInfo;
const getListOrderTriggers = (
  positionsData,
  listLiquidateThreshold,
  limitOrderData,
  limitPositions,
  limitIds,
  limitTriggerOders,
  listLiquidateThresholdLimit
) => {
  let resultTriggers = [];
  if (!positionsData && !limitOrderData) return resultTriggers;
  const posIds = positionsData[0];
  const positions = positionsData[1];
  const triggersData = positionsData[3];
  const trailings = positionsData[2];
  const accruedFeesInfoData = positionsData[5];

  for (let i = 0; i < triggersData.length; i++) {
    const item = triggersData[i];
    const accruedFeesInfo = accruedFeesInfoData[i];
    for (let j = 0; j < item.triggers.length; j++) {
      const triggerData = item.triggers[j];
      if (Number(triggerData.status) === 2) {
        const trigger = {
          type: "TRIGGER",
          posId: Number(posIds[i]).toString(),
          tokenId: Number(positions?.[i]?.tokenId).toString(),
          isLong: positions?.[i]?.isLong,
          status: triggerData.status,
          size: positions?.[i]?.size,
          collateral: positions?.[i]?.collateral,
          leverage: Number(
            formatAmount(
              positions?.[i]?.size.mul(parseValue(1, USD_DECIMALS)).div(positions?.[i]?.collateral),
              USD_DECIMALS,
              2,
              false
            )
          ),
          averagePrice: positions?.[i]?.averagePrice,
          positionType: POSITION_TYPES[positions?.[i]?.positionType],
          createdAt: triggerData?.createdAt,
          trigger: {
            amountPercent: triggerData?.amountPercent,
            createdAt: triggerData?.createdAt,
            triggeredAt: triggerData?.triggeredAt,
            price: triggerData?.price,
            id: `${posIds[i]}-${j}`,
            isTP: triggerData?.isTP,
            posId: Number(posIds[i]),
          },
          liquidationPrice: parseValue(
            getLiquidPriceV2(
              positions?.[i]?.size,
              positions?.[i]?.collateral,
              positions?.[i]?.isLong,
              accruedFeesInfo[1].add(accruedFeesInfo[2]),
              positions?.[i]?.averagePrice,
              listLiquidateThreshold[i][0]
            ),
            USD_DECIMALS
          ),
        };
        resultTriggers.push(trigger);
      }
    }
  }
  for (let i = 0; i < trailings.length; i++) {
    const item = trailings[i];
    if (BigNumber.from(item.size).gt(0)) {
      const accruedFeesInfo = accruedFeesInfoData[i];
      const trigger = {
        type: "TRAILING",
        posId: Number(posIds[i]).toString(),
        tokenId: Number(positions?.[i]?.tokenId).toString(),
        isLong: positions?.[i]?.isLong,
        status: item.status,
        size: positions?.[i]?.size,
        collateral: positions?.[i]?.collateral,
        leverage: Number(
          formatAmount(
            positions?.[i]?.size.mul(parseValue(1, USD_DECIMALS)).div(positions?.[i]?.collateral),
            USD_DECIMALS,
            2,
            false
          )
        ),
        averagePrice: positions?.[i]?.averagePrice,
        positionType: POSITION_TYPES[positions?.[i]?.positionType],
        stepAmount: item?.stepAmount,
        stepType: Number(item?.stepType),
        sizeDelta: item?.size,
        collateralDelta: item?.collateral,
        stopPrice: item?.stpPrice,
        price: item?.lmtPrice,
        liquidationPrice: parseValue(
          getLiquidPriceV2(
            positions?.[i]?.size,
            positions?.[i]?.collateral,
            positions?.[i]?.isLong,
            accruedFeesInfo[1].add(accruedFeesInfo[2]),
            positions?.[i]?.averagePrice,
            listLiquidateThreshold[i][0]
          ),
          USD_DECIMALS
        ),
        createdAt: item?.timestamp,
      };
      resultTriggers.push(trigger);
    }
  }
  for (let i = 0; i < limitOrderData.length; i++) {
    const item = limitOrderData[i][0];

    if (Number(item.status) === 1) {
      const trigger = {
        type: "PENDDING",
        posId: Number(limitIds[i]).toString(),
        tokenId: Number(limitPositions[i][0].tokenId).toString(),
        isLong: limitPositions[i][0].isLong,
        status: item.status,
        pendingSize: item.size,
        pendingCollateral: item.collateral,
        averagePrice: Number(item.positionType) === 2 ? item.stpPrice : item.lmtPrice,
        lmtPrice: item.lmtPrice,
        leverage:
          item.collateral && item.collateral.gt(0)
            ? Number(
                formatAmount(item.size.mul(parseValue(1, USD_DECIMALS)).div(item.collateral), USD_DECIMALS, 2, false)
              )
            : 0,
        stpPrice: item.stpPrice,
        positionType: POSITION_TYPES[item.positionType],
        createdAt: item?.timestamp,
        liquidationPrice: parseValue(
          getLiquidPriceV2(
            item.size,
            item.collateral,
            limitPositions[i][0].isLong,
            BigNumber.from(0),
            Number(item.positionType) === 2 ? item.stpPrice : item.lmtPrice,
            listLiquidateThresholdLimit[i][0]
          ),
          USD_DECIMALS
        ),
      };
      resultTriggers.push(trigger);
    }
  }
  for (let i = 0; i < limitTriggerOders.length; i++) {
    const triggers = limitTriggerOders[i][0]?.triggers;
    if (triggers.length > 0) {
      for (let j = 0; j < triggers.length; j++) {
        const item = triggers[j];

        if (Number(limitOrderData[i][0].status) === 1 && Number(item.status) === 2) {
          const trigger = {
            type: "TRIGGER",
            posId: Number(limitIds[i]).toString(),
            tokenId: Number(limitPositions[i][0].tokenId).toString(),
            isLong: limitPositions[i][0].isLong,
            status: item.status,
            size: limitOrderData[i][0].size,
            collateral: limitOrderData[i][0].collateral,
            averagePrice:
              Number(limitOrderData[i][0].positionType) === 2
                ? limitOrderData[i][0].stpPrice
                : limitOrderData[i][0].lmtPrice,

            leverage:
              limitOrderData[i][0].collateral && limitOrderData[i][0].collateral.gt(0)
                ? Number(
                    formatAmount(
                      limitOrderData[i][0].size.mul(parseValue(1, USD_DECIMALS)).div(limitOrderData[i][0].collateral),
                      USD_DECIMALS,
                      2,
                      false
                    )
                  )
                : 0,
            liquidationPrice: BigNumber.from(0),
            // liquidationPrice: parseValue(
            //   getLiquidPriceV2(
            //     limitOrderData[i][0].size,
            //     limitOrderData[i][0].collateral,
            //     limitPositions[i][0].isLong,
            //     BigNumber.from(0),
            //     Number(limitOrderData[i][0].positionType) === 2 ? limitOrderData[i][0].stpPrice : limitOrderData[i][0].lmtPrice,
            //     listLiquidateThresholdLimit[i][0]
            //   ),
            //   USD_DECIMALS
            // ),
            createdAt: item?.createdAt,
            trigger: {
              amountPercent: item?.amountPercent,
              createdAt: item?.createdAt,
              triggeredAt: item?.triggeredAt,
              price: item?.price,
              id: `${limitIds[i]}-${j}`,
              isTP: item?.isTP,
              posId: Number(limitIds[i]),
            },
          };
          resultTriggers.push(trigger);
        }
      }
    }
  }

  return resultTriggers.sort((a, b) => b.createdAt - a.createdAt);
};
export const mergedApiOnchainOrders = (onchainOrders, apiOrders) => {
  let result = [];
  if (onchainOrders.length > apiOrders.length) {
    for (let i = 0; i < onchainOrders.length; i++) {
      const onchainOrder = onchainOrders[i];
      let apiOrder;
      if (onchainOrder.type === "TRIGGER") {
        apiOrder = apiOrders?.find(
          (x) =>
            x?.posId === onchainOrder?.posId &&
            onchainOrder?.type === x?.type &&
            x?.trigger?.id === onchainOrder?.trigger?.id
        );
      } else {
        apiOrder = apiOrders?.find((x) => x?.posId === onchainOrder?.posId && onchainOrder?.type === x?.type);
      }
      if (apiOrder) {
        result.push({
          ...onchainOrder,
          ...apiOrder,
        });
      } else {
        result.push(onchainOrder);
      }
    }
  } else {
    result = apiOrders;
  }

  return result.sort((a, b) => b.createdAt - a.createdAt);
};
