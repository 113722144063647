import React, { useState, useCallback, useEffect, useMemo } from "react";
import { BigNumber, ethers } from "ethers";
import cx from "classnames";
import { Trans, t } from "@lingui/macro";
import { BsArrowRight } from "react-icons/bs";
import SettingsManager from "abis/SettingsManager.json";
import { MARKET, STOP, getLiqPrice, getLiqRisk, getPnlWithoutFee, getUnrealizedPnl, importImage } from "lib/legacy";
import { ARBITRUM, getChainName, getConstant, IS_NETWORK_DISABLED } from "config/chains";
import { createDecreaseOrder, useHasOutdatedUi } from "domain/legacy";
import { getContract } from "config/contracts";
import PositionRouter from "abis/PositionRouter.json";
import Checkbox from "../Checkbox/Checkbox";
import Tab from "../Tab/Tab";
import Modal from "../Modal/Modal";
import ExchangeInfoRow from "./ExchangeInfoRow";
import Tooltip from "../Tooltip/Tooltip";
import "./PositionSeller.css";
import { formatAmount, limitDecimals, parseValue } from "lib/numbers";
import PercentageButtons from "./PercentageButtons";
import moment from "moment";
import RowsDropdown from "./RowsDropdown";
import useSWR from "swr";
import { contractFetcher } from "lib/contracts";
import { preventSpecialCharacters } from "utils/helpers";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { THEME_KEY } from "config/localStorage";
const antIcon = <LoadingOutlined style={{ fontSize: 16, color: "grey" }} spin />;
function secondsToHMS(secs) {
  function z(n) {
    return (n < 10 ? "" : "") + n;
  }
  var sign = secs < 0 ? "-" : "";
  secs = Math.abs(secs);
  return sign + z((secs / 3600) | 0) + "h:" + z(((secs % 3600) / 60) | 0) + "m:" + z((secs % 60).toFixed(0)) + "s";
}
export default function ClosePosition(props) {
  const {
    library,
    isVisible,
    selectedPosition,
    setIsVisible,
    chainId,
    handleClosePosition,
    marketTokensInfo,
    positions,
    isConfirm,
  } = props;
  const currentPos = positions.find((x) => x.posId === selectedPosition.posId) || {};
  const position = { ...selectedPosition, ...currentPos };

  const currentMarket = marketTokensInfo?.find((x) => x.id === position.tokenId);
  const [fromValue, setFromValue] = useState("");
  const [closeAmount, setCloseAmount] = useState("");
  const [sizeAmount, setSizeAmount] = useState(0);
  const handleSelectPercentageCloseAmount = (percentage) => {
    const value = position.quantity.mul(parseValue(percentage, 30)).div(parseValue(1, 30));
    setCloseAmount(formatAmount(value, 30, 30, false));
  };

  const accruedPositionFee = parseValue(position.accruedPositionFee, 30);
  const accruedFundingFee = parseValue(position.accruedFundingFee, 30);
  const accruedBorrowFee = parseValue(position.accruedBorrowFee, 30);

  const avgEntryPrice = parseValue(position.averagePrice, 30);
  const markPrice = BigNumber.from(position?.isLong ? currentMarket?.askPrice || 0 : currentMarket?.bidPrice || 0);

  const posQty = parseValue(position.size, 30).mul(parseValue(1, 30)).div(parseValue(position.averagePrice, 30));
  const collateral = parseValue(position.collateral, 30);
  const pendingCollateral = parseValue(position.pendingCollateral, 30);
  const dateFormat = "YYYY-MM-DD";
  const paidPositionFee = parseValue(position.paidPositionFee, 30);
  const paidBorrowFee = parseValue(position.paidBorrowFee, 30);
  const paidFundingFee = parseValue(position.paidFundingFee, 30);
  const duration = secondsToHMS(Date.now() / 1000 - position.createdAt);
  const onClossePosition = () => {
    if (parseValue(closeAmount, 30).eq(position.quantity))
      handleClosePosition && handleClosePosition(position, parseValue(position.size, 30));
    else {
      handleClosePosition &&
        handleClosePosition(
          position,
          parseValue(closeAmount, 30).mul(parseValue(position.averagePrice, 30)).div(parseValue(1, 30))
        );
    }
  };
  const getPnl = (entryPrice, size, markPrice, isLong) => {
    const pnlWithoutFee = isLong
      ? markPrice.sub(entryPrice).mul(size).div(entryPrice)
      : entryPrice.sub(markPrice).mul(size).div(entryPrice);
    return pnlWithoutFee;
  };
  const settingsManagerAddress = getContract(chainId, "SettingsManager");
  const closeAmountDelta = closeAmount
    ? parseValue(closeAmount, 30).eq(posQty)
      ? parseValue(position.size, 30)
      : parseValue(closeAmount, 30).mul(parseValue(position.averagePrice, 30)).div(parseValue(1, 30))
    : null;
  const { data: liquidateThreshold } = useSWR(
    closeAmount && [
      "ExChange:liquidateThreshold",
      chainId,
      settingsManagerAddress,
      "liquidateThreshold",
      position.tokenId,
    ],
    {
      fetcher: contractFetcher(library, SettingsManager),
    }
  );
  const { data: minProfitDurations } = useSWR(
    closeAmount && [
      "ExChange:minProfitDurations",
      chainId,
      settingsManagerAddress,
      "minProfitDurations",
      position.tokenId,
    ],
    {
      fetcher: contractFetcher(library, SettingsManager),
    }
  );
  const { data: maxCloseProfitPercents } = useSWR(
    closeAmount && [
      "ExChange:maxCloseProfitPercents",
      chainId,
      settingsManagerAddress,
      "maxCloseProfitPercents",
      position.tokenId,
    ],
    {
      fetcher: contractFetcher(library, SettingsManager),
    }
  );

  const { data: maxCloseProfits } = useSWR(
    closeAmount && ["ExChange:maxCloseProfits", chainId, settingsManagerAddress, "maxCloseProfits", position.tokenId],
    {
      fetcher: contractFetcher(library, SettingsManager),
    }
  );

  const newSize =
    closeAmount && parseValue(closeAmount, 30).gt(0)
      ? parseValue(position.size, 30)
          .sub(parseValue(closeAmount, 30).mul(parseValue(position.averagePrice, 30)).div(parseValue(1, 30)))
          .div(parseValue(1, 25))
      : null;
  const newCollateral = newSize ? newSize.mul(parseValue(1, 5)).div(parseValue(position.leverage, 5)) : null;

  const sizeDelta = newSize
    ? parseValue(closeAmount, 30).eq(position.quantity)
      ? parseValue(position.size, 30)
      : parseValue(closeAmount, 30).mul(parseValue(position.averagePrice, 30)).div(parseValue(1, 30))
    : null;
  const pnlWithoutFee = sizeDelta
    ? getPnlWithoutFee(position.isLong, markPrice, parseValue(position.averagePrice, 30), sizeDelta)
    : null;
  const accruedFees = parseValue(position.accruedPositionFee, 30)
    .add(parseValue(position.accruedFundingFee, 30))
    .add(parseValue(position.accruedBorrowFee, 30));
  const percentSizeDelta = closeAmount
    ? parseValue(closeAmount, 30).eq(position.quantity, 30)
      ? parseValue(1, 30)
      : parseValue(closeAmount, 30).mul(parseValue(1, 30)).div(position.quantity)
    : null;
  const uPnL =
    pnlWithoutFee && percentSizeDelta
      ? pnlWithoutFee.sub(accruedFees.mul(percentSizeDelta).div(parseValue(1, 30)))
      : null;
  const pnlClose = newSize
    ? getPnl(parseValue(position.averagePrice, 30), sizeDelta, markPrice, position.isLong)
    : null;
  const estValueRecieve =
    percentSizeDelta && uPnL
      ? percentSizeDelta.mul(parseValue(position.collateral, 30)).div(parseValue(1, 30)).add(uPnL)
      : null;
  const liquidPrice =
    newCollateral && newSize && newSize.gt(0) && liquidateThreshold
      ? getLiqPrice(
          liquidateThreshold,
          newCollateral,
          newSize,
          position.accruedFees.div(parseValue(1, 25)),
          position.isLong,
          parseValue(position.averagePrice, 30)
        )
      : newSize && newSize.eq(0)
      ? 0
      : null;
  const rPnL = position.realisedPnl;
  const ROI = uPnL ? uPnL.mul(parseValue(100, 30)).div(parseValue(position.collateral, 30)) : null;

  const getClosePositionError = () => {
    if (!closeAmount || !Number(closeAmount)) {
      return "Enter an amount";
    }

    if (position.quantity && parseValue(closeAmount, 30).gt(position.quantity)) {
      return "Amount exceeds balance";
    }

    return null;
  };
  const getPrimaryText = () => {
    return getClosePositionError() ?? (isConfirm ? "Closing..." : "Close Position");
  };
  const isDisableCondition = () => {
    if (!minProfitDurations || !maxCloseProfitPercents || !maxCloseProfits) return true;
    const inTime = Date.now() / 1000 - position.createdAt < minProfitDurations.toNumber();
    if (inTime) {
      if (uPnL.gt(maxCloseProfits) || ROI.gt(parseValue(1, 27).mul(maxCloseProfitPercents))) return true;
      return false;
    }
    return false;
  };
  return (
    <div className="PositionEditor">
      {position && (
        <Modal
          className={`PositionSeller-modal  confirmation-modal details-position-modal`}
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          label={"Close Position"}
          allowContentTouchMove
        >
          <div className="order-title-line order-title-line-details">
            <img src={importImage("ic_" + position?.symbol?.toLowerCase() + "_24.svg")} alt="" />
            <div className="position-info-container">
              <div className="position-info">
                <div className="title">{position.symbol}</div>
                <div className={`side ${position?.isLong ? "side-long" : "side-short"}`}>
                  {position?.isLong ? "LONG" : "SHORT"}
                </div>
                <div className="side">
                  {position?.positionType === "Market Order" ? "Market" : position?.positionType}
                </div>
              </div>
              <div className="position-id">#{position?.posId}</div>
            </div>
          </div>
          <div className="content-container">
            <div className="Exchange-swap-section" style={{ marginBottom: "8px", marginTop: "0px" }}>
              <div className="Exchange-swap-section-top">
                <div className="muted">
                  <span>Close Amount</span>
                </div>
                <div className="right-balance" style={{ marginLeft: "auto" }}>
                  {/* <button
                    className="max-btn"
                    onClick={() => setCloseAmount(formatAmount(position.quantity, 30, 18, false))}
                  >
                    Max
                  </button> */}
                  <span>Max: {formatAmount(position.quantity, 30, 4, true)}</span>
                </div>
              </div>
              <div className="Exchange-swap-section-bottom">
                <div className="Exchange-swap-input-container">
                  <input
                    type="number"
                    min="0"
                    placeholder="0.0"
                    className="Exchange-swap-input"
                    onKeyDown={preventSpecialCharacters}
                    value={closeAmount}
                    onChange={(e) => setCloseAmount(e.target.value)}
                  />
                </div>
                <div className="PositionEditor-token-symbol">{position.symbol}</div>
              </div>
            </div>
            <PercentageButtons
              onChangePercentage={handleSelectPercentageCloseAmount}
              balance={Number(formatAmount(position.quantity, 30, 30, false))}
              value={closeAmount}
            />
            <div className="order-details-container" style={{ marginTop: "16px" }}>
              <div className="square-container">
                <ExchangeInfoRow
                  label={`Unrealized PnL`}
                  isPositive={uPnL && uPnL.gt(0) ? true : uPnL && uPnL.lt(0) ? false : undefined}
                >
                  {uPnL && uPnL.gt(0) ? "+" : uPnL && uPnL.lt(0) ? "-" : ""}$
                  {formatAmount(uPnL || 0, 30, 2, true).replace("-", "")}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Pos. Size`}>
                  ${formatAmount(parseValue(position.size, 30), 30, 2, true)}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Pos. Qty`}>
                  {formatAmount(position.quantity, 30, 4, true)} {position.symbol}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Leverage`}>
                  <div
                    style={{
                      color: "#FFC700",
                      backgroundColor: "rgba(255, 199, 0, 0.20)",
                      height: 16,
                      display: "inline-block",
                      opacity: 1,
                      borderRadius: "4px",
                      padding: "0 4px",
                      fontSize: "12px",
                      lineHeight: "15.6px",
                      fontWeight: 400,
                      marginLeft: "4px",
                    }}
                    className="leverage-box"
                  >
                    {formatAmount(parseValue(position.leverage, 30), 30, 2, true)}X
                  </div>
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Close Amount`}>
                  ${closeAmountDelta ? formatAmount(closeAmountDelta, 30, 2, true) : "--"}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={` Est. Received Amount`}>
                  {estValueRecieve && estValueRecieve.lt(0) ? "-" : ""}$
                  {estValueRecieve ? formatAmount(estValueRecieve, 30, 2, true).replace("-", "") : "--"}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Mark Price`}>
                  ${formatAmount(markPrice, 30, currentMarket?.decimals || 2, true)}
                </ExchangeInfoRow>
                {/* <ExchangeInfoRow label={`Leverage`}>
                  {position.leverage?.toString().includes(".")
                    ? formatAmount(parseValue(position.leverage, 30), 30, 2, true)
                    : position?.leverage}
                  x
                </ExchangeInfoRow> */}
                <ExchangeInfoRow label={`Avg. Entry Price`}>
                  ${formatAmount(avgEntryPrice, 30, currentMarket?.decimals || 2, true)}
                </ExchangeInfoRow>
                <ExchangeInfoRow
                  label={`ROI`}
                  isPositive={ROI && ROI.gt(0) ? true : ROI && ROI.lt(0) ? false : undefined}
                >
                  {/* {ROI ? (ROI.gt(0) ? "+" : "-") : ""}${rPnL ? formatAmount(rPnL, 30, 2, true).replace("-", "") : "--"}{" "} */}
                  {ROI ? (ROI.gt(0) ? "+" : "-") : ""}
                  {ROI ? formatAmount(ROI, 30, 2, true).replace("-", "") : "--"}%
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Current Liq. Price`}>
                  ${formatAmount(parseValue(position?.liquidationPrice, 30), 30, currentMarket?.decimals || 2, true)}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`New Liq. Price`}>
                  ${liquidPrice ? formatAmount(liquidPrice, 30, currentMarket?.decimals || 2, true) : "--"}
                </ExchangeInfoRow>
              </div>
              <div className="fw-500">Fees</div>
              <div className="square-container square-fee-container">
                <RowsDropdown
                  handler={
                    <ExchangeInfoRow label={`Paid`} isPositive={position.paidFees.gte(0)}>
                      {position.paidFees.lt(0) ? "-" : ""}$
                      {formatAmount(position.paidFees, 30, 4, true).replace("-", "")}
                    </ExchangeInfoRow>
                  }
                >
                  <ExchangeInfoRow label={`Position`}>${formatAmount(paidPositionFee, 30, 4, true)}</ExchangeInfoRow>
                  <ExchangeInfoRow label={`Funding`} isPositive={paidFundingFee.gte(0)}>
                    {paidFundingFee.gte(0) ? "" : "-"}${formatAmount(paidFundingFee, 30, 4, true).replace("-", "")}
                  </ExchangeInfoRow>
                  <ExchangeInfoRow label={`Borrow`}>${formatAmount(paidBorrowFee, 30, 4, true)}</ExchangeInfoRow>
                  {/* <ExchangeInfoRow label={`Fee Discount (--%)`}>$--</ExchangeInfoRow> */}
                </RowsDropdown>
                <div className="divider" />
                <RowsDropdown
                  handler={
                    <ExchangeInfoRow label={`Accrued`} isPositive={accruedFees.gte(0)}>
                      {accruedFees && percentSizeDelta ? (accruedFees.gte(0) ? "" : "-") : ""}$
                      {percentSizeDelta
                        ? formatAmount(accruedFees.mul(percentSizeDelta).div(parseValue(1, 30)), 30, 4, true).replace(
                            "-",
                            ""
                          )
                        : "--"}
                    </ExchangeInfoRow>
                  }
                >
                  <ExchangeInfoRow label={`Close Position`}>
                    {accruedPositionFee.gte(0) ? "" : "-"}$
                    {percentSizeDelta
                      ? formatAmount(
                          accruedPositionFee.mul(percentSizeDelta).div(parseValue(1, 30)),
                          30,
                          4,
                          true
                        ).replace("-", "")
                      : "--"}
                  </ExchangeInfoRow>
                  <ExchangeInfoRow label={`Funding`} isPositive={accruedFundingFee.gte(0)}>
                    {accruedFundingFee.gte(0) ? "" : "-"}$
                    {percentSizeDelta
                      ? formatAmount(
                          accruedFundingFee.mul(percentSizeDelta).div(parseValue(1, 30)),
                          30,
                          4,
                          true
                        ).replace("-", "")
                      : "--"}
                  </ExchangeInfoRow>
                  <ExchangeInfoRow label={`Borrow`}>
                    {accruedBorrowFee.gte(0) ? "" : "-"}$
                    {percentSizeDelta
                      ? formatAmount(
                          accruedBorrowFee.mul(percentSizeDelta).div(parseValue(1, 30)),
                          30,
                          4,
                          true
                        ).replace("-", "")
                      : "--"}
                  </ExchangeInfoRow>
                  {/* <ExchangeInfoRow label={`Fee Discount (--%)`}>$--</ExchangeInfoRow> */}
                </RowsDropdown>
              </div>
            </div>
          </div>
          <div className="Exchange-swap-button-container">
            <button
              className="App-cta Exchange-swap-button"
              onClick={onClossePosition}
              disabled={!!getClosePositionError() || isConfirm || isDisableCondition()}
            >
              {getPrimaryText()}
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
}
