import { getServerBaseUrl } from "config/backend";
import { useEffect, useState } from "react";
import axios from "axios";
import { parseValue } from "lib/numbers";

const DEFAULT_DATA = {
  portfolioValue: 0,
  totalCollateral: 0,
  totalSize: 0,
  freeCollateral: 0,
  marginUsage: 0,
  openLongs: 0,
  openShorts: 0,
};

export const usePortfolio = (chainId, account) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(DEFAULT_DATA);

  useEffect(() => {
    if (account) {
      const fetchPortfolioData = async () => {
        try {
          const { data: response } = await axios.get(`${getServerBaseUrl(chainId, true)}/public/portfolios/${account}`);

          const formatted = {};

          for (const [key, value] of Object.entries(response.data)) {
            formatted[key] = parseValue(value, 30);
          }

          setData(formatted);
        } finally {
          setIsLoading(false);
        }
      };
      fetchPortfolioData();
      const interval = setInterval(fetchPortfolioData, 30000);

      return () => clearInterval(interval);
    } else {
      setIsLoading(false);
      setData(DEFAULT_DATA);
    }
  }, [account, chainId]);

  return {
    data,
    isLoading,
  };
};
