import React, { useState, useCallback, useEffect, useMemo } from "react";
import { BigNumber, ethers } from "ethers";
import cx from "classnames";
import { Trans, t } from "@lingui/macro";
import { BsArrowRight } from "react-icons/bs";

import {
  DEFAULT_SLIPPAGE_AMOUNT,
  DEFAULT_HIGHER_SLIPPAGE_AMOUNT,
  USD_DECIMALS,
  DUST_USD,
  BASIS_POINTS_DIVISOR,
  MIN_PROFIT_TIME,
  getLiquidationPrice,
  getLeverage,
  getMarginFee,
  PRECISION,
  MARKET,
  STOP,
  DECREASE,
  calculatePositionDelta,
  getDeltaStr,
  getProfitPrice,
  getNextToAmount,
  USDG_DECIMALS,
  adjustForDecimals,
  isAddressZero,
  MAX_ALLOWED_LEVERAGE,
  importImage,
  TAKE_PROFIT,
  STOP_LOSS,
  LEVERAGE_MARKS,
  getLiqPrice,
} from "lib/legacy";
import Slider, { SliderTooltip } from "rc-slider";
import { ARBITRUM, getChainName, getConstant, IS_NETWORK_DISABLED } from "config/chains";
import { createDecreaseOrder, useHasOutdatedUi } from "domain/legacy";
import { getContract } from "config/contracts";
import PositionRouter from "abis/PositionRouter.json";
import Checkbox from "../Checkbox/Checkbox";
import Tab from "../Tab/Tab";
import Modal from "../Modal/Modal";
import ExchangeInfoRow from "./ExchangeInfoRow";
import Tooltip from "../Tooltip/Tooltip";
import TokenSelector from "./TokenSelector";
import "./PositionSeller.css";
import StatsTooltipRow from "../StatsTooltip/StatsTooltipRow";
import { callContract, contractFetcher } from "lib/contracts";
import { getTokenAmountFromUsd } from "domain/tokens";
import { TRIGGER_PREFIX_ABOVE, TRIGGER_PREFIX_BELOW } from "config/ui";
import { useLocalStorageByChainId, useLocalStorageSerializeKey } from "lib/localStorage";
import { CLOSE_POSITION_RECEIVE_TOKEN_KEY, SLIPPAGE_BPS_KEY, THEME_KEY } from "config/localStorage";
import { getTokenInfo, getUsd } from "domain/tokens/utils";
import { usePrevious } from "lib/usePrevious";
import {
  bigNumberify,
  expandDecimals,
  formatAmount,
  formatAmountFree,
  getDisplayDecimalByAmount,
  limitDecimals,
  parseValue,
} from "lib/numbers";
import { getTokens, getWrappedToken } from "config/tokens";
import { formatDateTime, getTimeRemaining } from "lib/dates";
import ExternalLink from "components/ExternalLink/ExternalLink";
import PercentageButtons from "./PercentageButtons";
import useSWR from "swr";
import SettingsManager from "abis/SettingsManager.json";
import { preventSpecialCharacters } from "utils/helpers";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
const antIcon = <LoadingOutlined style={{ fontSize: 16, color: "grey" }} spin />;
const { AddressZero } = ethers.constants;
const ORDER_SIZE_DUST_USD = expandDecimals(1, USD_DECIMALS - 1); // $0.10

export default function EditLeverage(props) {
  const {
    onConfirm,
    library,
    isVisible,
    position,
    setIsVisible,
    isHigherSlippageAllowed,
    setIsHigherSlippageAllowed,
    chainId,
    marketTokensInfo,
    isConfirm,
  } = props;
  const currentMarket = marketTokensInfo?.find((x) => x.id == position.tokenId);
  const markPrice = BigNumber.from(position.isLong ? currentMarket.askPrice || 1 : currentMarket?.bidPrice || 1);
  const maxLeverage = currentMarket?.maxLeverage ? Number(currentMarket?.maxLeverage) / BASIS_POINTS_DIVISOR : 100;
  let [orderOption, setOrderOption] = useState(TAKE_PROFIT);
  const [fromValue, setFromValue] = useState("");
  const [triggerPriceInput, setTriggerPriceInput] = useState(position?.markPrice || "");
  const [closeQuantity, setCloseQuantity] = useState(0);
  const [savedSlippageAmount] = useLocalStorageSerializeKey([chainId, SLIPPAGE_BPS_KEY], DEFAULT_SLIPPAGE_AMOUNT);
  const [triggerPriceValue, setTriggerPriceValue] = useState("");
  const [keepLeverage, setKeepLeverage] = useLocalStorageSerializeKey([chainId, "Exchange-keep-leverage"], true);
  const ORDER_OPTIONS = [TAKE_PROFIT, STOP_LOSS];
  const [leverageOption, setLeverageOption] = useState(position.leverage);
  const ORDER_OPTION_LABELS = {
    [TAKE_PROFIT]: TAKE_PROFIT,
    [STOP_LOSS]: STOP_LOSS,
  };
  const onOrderOptionChange = (option) => {
    setOrderOption(option);
  };
  let allowedSlippage = savedSlippageAmount;
  if (isHigherSlippageAllowed) {
    allowedSlippage = DEFAULT_HIGHER_SLIPPAGE_AMOUNT;
  }
  const { positionNetValue } = position;
  const handleSelectPercentageTriggerPrice = (percentage) => {
    setTriggerPriceInput(percentage);
  };
  const handleSelectPercentageCloseQuantity = (percentage) => {
    setCloseQuantity(percentage);
  };
  const settingsManagerAddress = getContract(chainId, "SettingsManager");
  const { data: liquidateThreshold } = useSWR(
    position.tokenId && [
      `ExChange:liquidateThreshold-${position.posId}`,
      chainId,
      settingsManagerAddress,
      "liquidateThreshold",
      position.tokenId,
    ],
    {
      fetcher: contractFetcher(library, SettingsManager),
    }
  );
  const newCollateral =
    leverageOption && Number(leverageOption) && position.leverage !== leverageOption
      ? parseValue(position.size, 30).mul(parseValue(1, 30)).div(parseValue(leverageOption, 30))
      : null;
  // console.log("????", {newCollateral, size: parseValue(position.size, 30), leverage: leverageOption});
  const accruedFees =
    parseValue(position.accruedPositionFee, 30)
      .add(parseValue(position.accruedFundingFee, 30))
      .add(parseValue(position.accruedBorrowFee, 30)) || BigNumber.from(0);
  const newLiquidPrice = newCollateral
    ? getLiqPrice(
        liquidateThreshold,
        newCollateral,
        parseValue(position.size, 30),
        accruedFees,
        position.isLong,
        parseValue(position.averagePrice, 30)
      )
    : null;

  const leverageSliderHandle = (props) => {
    const { value, dragging, index, ...restProps } = props;
    return (
      <SliderTooltip
        prefixCls="rc-slider-tooltip"
        overlay={`${parseFloat(value).toFixed(2)}x`}
        visible={dragging}
        placement="top"
        key={index}
      >
        <Slider.Handle value={value} {...restProps} />
      </SliderTooltip>
    );
  };
  const handleConfirm = () => {
    // console.log(
    //   "??????",
    //   parseValue(position.collateral, 30),
    //   parseValue(position.collateral, 30).sub(newCollateral),
    //   position
    // );
    onConfirm &&
      onConfirm(
        position,
        parseValue(position.size, 30).mul(parseValue(1, 30)).div(parseValue(position.leverage, 30)).sub(newCollateral)
      );
  };

  const handleBlurLeverage = ({ target }) => {
    const val = target.value;
    if (!val || val < 1.1 || val > 100) {
      setLeverageOption(1.1);
      return;
    }
    setLeverageOption(target.value);
  };

  const errorLeverage = Number(leverageOption) < Number(position?.leverage) ? "You must increase leverage" : null;
  const getErrorLeverage = () => {
    if (!Number(leverageOption) || Number(leverageOption) < Number(position?.leverage)) {
      return "You must increase leverage";
    }
    if (markPrice && newLiquidPrice) {
      return position.isLong ? markPrice.lt(newLiquidPrice) : markPrice.gt(newLiquidPrice);
    }
    return false;
  };

  const isDisableButton = () => {
    return !!getErrorLeverage();
  };

  const changeToLiq = useMemo(() => {
    let resultValue = {};
    //% Change to Liq = (Est.liq price - Market price ) / Market price
    if (position && position.liquidationPrice && currentMarket && currentMarket.askPrice) {
      const current = parseValue(position.liquidationPrice, 30).sub(markPrice).mul(100).mul(parseValue(1, 30));
      const currentResult = current.div(markPrice);
      resultValue = {
        ...resultValue,
        current: currentResult,
      };
      if (newLiquidPrice) {
        const _new = newLiquidPrice.sub(markPrice).mul(100).mul(parseValue(1, 30));
        resultValue = {
          ...resultValue,
          current: current.div(markPrice),
          new: _new.div(markPrice),
        };
      }
    }

    return resultValue;
  }, [currentMarket?.askPrice, newLiquidPrice, position?.liquidationPrice]);
  const onChangeLeverage = (value) => {
    if (value && value.includes(".")) {
      var arr = value.split(".");
      if (arr[1].length <= 1) setLeverageOption(value);
    } else {
      setLeverageOption(value);
    }
  };
  const getConfirmText = () => {
    if (isConfirm) {
      return "Increasing...";
    } else if (markPrice && newLiquidPrice) {
      const isLiquid = position.isLong ? markPrice.lt(newLiquidPrice) : markPrice.gt(newLiquidPrice);
      if (isLiquid) return "Position will be liquidated";
    }
    return "Increase Leverage";
    // isConfirm ? "Increasing..." : " Increase Leverage"
  };
  return (
    <div className="PositionEditor">
      {position && (
        <Modal
          className={`PositionSeller-modal confirmation-modal`}
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          label={"Increase Leverage"}
          allowContentTouchMove
        >
          <div className="order-title-line order-title-line-details">
            <img src={importImage("ic_" + position?.symbol?.toLowerCase() + "_24.svg")} alt="" />
            <div className="position-info-container">
              <div className="position-info">
                <div className="title">{position.symbol}</div>
                <div className={`side ${position?.isLong ? "side-long" : "side-short"}`}>
                  {position?.isLong ? "LONG" : "SHORT"}
                </div>
                <div className="side">
                  {position?.positionType === "Market Order" ? "Market" : position?.positionType}
                </div>
              </div>
              <div className="position-id">#{position?.posId}</div>
            </div>
          </div>
          <div className="content-container">
            <div style={{ fontSize: "14px", fontWeight: 700 }}>Leverage</div>
            <div className="Exchange-leverage-slider-container">
              <div className="percentahe-value">
                {/* <span>
                  {leverageOption.toString().includes(".")
                    ? formatAmount(parseValue(leverageOption, 30), 30, 2, true)
                    : leverageOption}
                </span>
                <span>x</span> */}
                <input
                  className="percentahe-input"
                  value={leverageOption}
                  type="number"
                  onChange={({ target }) => onChangeLeverage(target.value)}
                  onBlur={handleBlurLeverage}
                  onKeyDown={preventSpecialCharacters}
                />
                <span>x</span>
              </div>
              <div
                className={cx("Exchange-leverage-slider", "App-slider", {
                  positive: position?.isLong,
                  negative: !position?.isLong,
                  normalSlider: true,
                  // shortSlider: isShort,
                })}
              >
                <Slider
                  min={1.0}
                  max={maxLeverage}
                  step={0.5}
                  marks={LEVERAGE_MARKS(chainId, maxLeverage)}
                  handle={leverageSliderHandle}
                  onChange={(value) => setLeverageOption(value)}
                  defaultValue={leverageOption}
                  value={leverageOption}
                />
              </div>
            </div>
            {errorLeverage && (
              <div className="validate-error" style={{ fontSize: 12 }}>
                {errorLeverage}
              </div>
            )}
            <div className="PositionEditor-info-box" style={{ marginTop: "16px" }}>
              <div className="square-container square-fee-container">
                <ExchangeInfoRow label={`Mark Price`}>
                  {currentMarket?.askPrice
                    ? `$${formatAmount(currentMarket.askPrice, 30, currentMarket?.decimals || 2, true)}`
                    : "--"}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Pos. Size`}>
                  ${formatAmount(parseValue(position.size, 30), 30, 2, true)}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Pos. Qty`}>
                  {formatAmount(position.quantity, 30, 4, true)} {position.symbol}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Avg. Entry Price`}>
                  ${formatAmount(parseValue(position.averagePrice, 30), 30, currentMarket?.decimals || 2, true)}
                </ExchangeInfoRow>

                <div className="divider" />

                <div className="position-change-line">
                  <div className="position-change-info" />
                  <div className="position-change-info">Current</div>
                  <div className="position-change-info">New</div>
                </div>
                <div className="position-change-line">
                  <div className="position-change-info">Leverage</div>
                  <div className="position-change-info change-value">
                    {" "}
                    {formatAmount(parseValue(position.leverage, 30), 30, 2, true)}X
                  </div>
                  <div className="position-change-info change-value">
                    {formatAmount(parseValue(leverageOption, 30), 30, 2, true)}X
                  </div>
                </div>
                <div className="position-change-line">
                  <div className="position-change-info">Est. Liq. Price</div>
                  <div className="position-change-info change-value">
                    ${formatAmount(parseValue(position.liquidationPrice, 30), 30, currentMarket?.decimals || 2, true)}
                  </div>
                  <div className="position-change-info change-value">
                    ${newLiquidPrice ? formatAmount(newLiquidPrice, 30, currentMarket?.decimals || 2, true) : "--"}
                  </div>
                </div>
                <div className="position-change-line">
                  <div className="position-change-info">% Change to Liq</div>
                  <div className="position-change-info change-value">
                    {changeToLiq.current ? formatAmount(changeToLiq.current, 30, 2, true).replace("-", "") : "--"}%
                  </div>
                  <div className="position-change-info change-value">
                    {changeToLiq.new ? formatAmount(changeToLiq.new, 30, 2, true).replace("-", "") : "--"}%
                  </div>
                </div>
                <div className="position-change-line">
                  <div className="position-change-info">Collateral Used</div>
                  <div className="position-change-info change-value">
                    ${formatAmount(parseValue(position.collateral, 30), 30, 2, true)}
                  </div>
                  <div className="position-change-info change-value">
                    ${newCollateral ? formatAmount(newCollateral, 30, 2, true) : "--"}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="Exchange-swap-button-container">
            <button
              className="App-cta Exchange-swap-button"
              onClick={handleConfirm}
              disabled={isDisableButton() || isConfirm}
            >
              {getConfirmText()}
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
}
