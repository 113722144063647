import Footer from "components/Footer/Footer";
import axios from "axios";
import "./News.scss";
import styled from "styled-components";
import { useEffect, useState } from "react";
import useSWR from "swr";
import { arrayURLFetcher } from "lib/legacy";
import ExternalLink from "components/ExternalLink/ExternalLink";
import bg from "img/earn/bg.svg";
import { useThemeContext } from "contexts/ThemeProvider";
const TABS = ["All", "Hot", "Rising", "Bullish", "Important"];

const News = () => {
  const { lightThemeClassName } = useThemeContext();
  const [currentTab, setCurentTab] = useState("All");
  const [currentPage, setCurrentPage] = useState(1);
  const [filterNews, setFilterNews] = useState([]);
  const [isLoadMore, setIsLoadMore] = useState(false);
  const timeSince = (date) => {
    var seconds = Math.floor((new Date() - date) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
      return Math.floor(interval) + "y";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + "m";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + "d";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + "h";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + "min";
    }
    return Math.floor(seconds) + "s";
  };
  const fetchNews = async (currentPage) => {
    const res = await axios.get(
      `https://api.mummy.finance/news?page=${currentPage}&filter=${currentTab.toLowerCase()}`
    );
    if (res?.data?.data) {
      setFilterNews([...filterNews, ...res?.data?.data?.results]);
      setIsLoadMore(!!res?.data?.data?.next);
    }
  };
  const filterFetchNews = async () => {
    const res = await axios.get(`https://api.mummy.finance/news?page=1&filter=${currentTab.toLowerCase()}`);
    if (res?.data?.data) {
      setFilterNews(res?.data?.data?.results);
      setIsLoadMore(!!res?.data?.data?.next);
      setCurrentPage(1);
    }
  };
  const handleLoadMore = async () => {
    if (isLoadMore) {
      fetchNews(currentPage + 1);
      await setCurrentPage(currentPage + 1);
    }
  };
  useEffect(() => {
    fetchNews(1);
  }, []);
  useEffect(() => {
    filterFetchNews();
  }, [currentTab]);
  return (
    <Wrapper className={lightThemeClassName}>
      <div className="new-container">
        <div class="frame-427320323">
          <div class="frame-427320322">
            <div class="up-to-the-minute-and-accurate-updates-on-cryptocurrency-news">
              Up-to-the-Minute and Accurate Updates on Cryptocurrency News
            </div>

            <div class="frame-427320153">
              <div class="aggregated-by-crypto-panic">Aggregated by CryptoPanic</div>
              <ExternalLink className="not-underline" href={`https://cryptopanic.com/`}>
                <img class="image-126" src="/image_126.png" alt="" />
              </ExternalLink>
            </div>
          </div>

          <div class="frame-427320321">
            <div class="frame-427319432">
              {TABS.map((item) => (
                <div
                  class={`frame-427319414 ${currentTab === item ? "frame-427319413" : ""}`}
                  key={item}
                  onClick={() => setCurentTab(item)}
                >
                  <div class="hot">{item}</div>
                </div>
              ))}
            </div>

            <div class="frame-427320319">
              <div class="frame-427320320">
                {filterNews.map((item) => (
                  <div class="news-item" key={item.slug}>
                    <div class="frame-427320316">
                      <div class="_25-min">
                        {timeSince(new Date(Date.now() - (Date.now() - new Date(item.published_at).getTime())))}
                      </div>

                      <div class="rectangle-3332"></div>
                    </div>

                    <div class="frame-427320318">
                      <ExternalLink
                        className="not-underline link-open"
                        href={`https://cryptopanic.com/news/click/${item.id}/`}
                        key={item.id}
                      >
                        <div class="frame-427320315">
                          <div class="cosmos-vs-polkadot-is-there-a-clear-winner">{item.title}</div>

                          {/* <div class="cosmos-and">
                            Cosmos and Polkadot experience surging development activity, while social engagements
                            decline and sentiment turns negative. Stakers remain optimistic despite the challenges.
                          </div> */}

                          <div class="frame-427320314">
                            {item?.currencies && item?.currencies.length > 0 && (
                              <div class="frame-427320313">
                                {item?.currencies.map((cur) => (
                                  <div class="frame-427320309" key={cur.code}>
                                    <div class="dot">{cur.code}</div>
                                  </div>
                                ))}
                              </div>
                            )}

                            <div class="frame-427320311">
                              <div class="ambcrypto-com">{item?.source?.title}</div>
                            </div>
                          </div>
                        </div>
                      </ExternalLink>
                    </div>
                  </div>
                ))}
              </div>
              {isLoadMore && (
                <div class="button">
                  <div class="button2" onClick={handleLoadMore}>
                    Load more articles
                  </div>
                </div>
              )}
              {!isLoadMore && filterNews.length > 0 && (
                <div style={{ opacity: 0.6 }}>No further news is currently available</div>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </Wrapper>
  );
};
export default News;
const Wrapper = styled.div`
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-size: contain;

  &.theme--light {
    .frame-427319432 {
      background: #fff;

      .frame-427319413 {
        background: var(--Border, rgba(13, 26, 22, 0.07));
      }
    }

    .frame-427320319 {
      border: 1px solid var(--Border, rgba(13, 26, 22, 0.07));
      background: var(--Nature-1, #fff);
    }

    .rectangle-3332 {
      background: var(--Border, rgba(13, 26, 22, 0.07));
    }

    .frame-427320309 {
      background: var(--Nature-2, #f2f5f7);

      .dot {
        color: var(--Primary, #02b27f);
      }
    }

    .frame-427320311 {
      background: var(--Nature-2, #f2f5f7);
      border: none;

      .ambcrypto-com {
        color: var(--Leverage-Text, #d69e03);
      }
    }

    .button {
      border: 1px solid var(--Border, rgba(13, 26, 22, 0.07));

      .button2:hover {
        color: #02b27f !important ;
      }

      &:hover {
        border-color: #02b27f;
        * {
          color: #02b27f;
        }
      }
    }
  }
`;
