import Footer from "components/Footer/Footer";
import SEO from "components/Common/SEO";
import { getPageTitle } from "lib/legacy";
import styled from "styled-components";
// import NFTInfo from "./components/NFTInfo";
import StartBlock from "./components/StartBlock";
export default function YourNFT() {
  return (
    <SEO title={getPageTitle("Your NFT")}>
      <Wrap>
        {/* <NFTInfo /> */}
        <StartBlock /> <Footer />
      </Wrap>
    </SEO>
  );
}

const Wrap = styled.div`
  .container {
    width: 100%;
    max-width: 1144px;
    padding-right: 24px;
    padding-left: 24px;
    margin-right: auto;
    margin-left: auto;

    @media (max-width: 767px) {
      padding-right: 16px;
      padding-left: 16px;
    }
  }
  .btn-outline {
    font-weight: 500;
    font-size: 14px;
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    color: #ffffff;
    justify-content: center;
    display: flex;
    align-items: center;
  }
  .Footer-wrapper {
    position: unset;
    width: 100%;
    transform: none;
  }
  .btn-primary {
    border: none;
    background: #03f5ae;
    border-radius: 8px;
    color: #080715;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background: #04d397;
      color: #080715 !important;
    }
  }
`;
