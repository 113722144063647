import { useWeb3React } from "@web3-react/core";
import { BigNumber, ethers } from "ethers";
import { createContext, useEffect, useMemo, useState } from "react";
import useSWR from "swr";

import { getServerUrl, getServerUrlV2 } from "config/backend";
import { ARBITRUM, BASE, FANTOM, FANTOM_LEGACY, OP } from "config/chains";
import { getContract } from "config/contracts";
import { getWhitelistedTokens } from "config/tokens";
import { useGmxPrice, useTotalGmxStaked } from "domain/legacy";
import { useInfoTokens } from "domain/tokens";
import { useChainId } from "lib/chains";
import { contractFetcher } from "lib/contracts";
import {
  ACTIVE_CHAIN_IDS,
  ACTIVE_CHAIN_IDS_V2,
  GLP_DECIMALS,
  GMX_DECIMALS,
  USD_DECIMALS,
  arrayURLFetcher,
  getStatsInfo,
  getStatsInfoV2,
} from "lib/legacy";
import { bigNumberify, expandDecimals, parseValue } from "lib/numbers";

import GlpManager from "abis/GlpManager.json";
import ReaderV2 from "abis/ReaderV2.json";
import Token from "abis/Token.json";
import { isEmpty } from "lodash";
import moment from "moment";
import { gql } from "@apollo/client";
import { getGmxGraphClient } from "lib/subgraph";

const { AddressZero } = ethers.constants;

export const StatsContext = createContext();

const StatsProvider = ({ children }) => {
  const utcToday = moment().utc().startOf("day").unix();
  const { chainId } = useChainId();
  const { active, library } = useWeb3React();

  const { data: dataStatsAll } = useSWR(
    ACTIVE_CHAIN_IDS.map((chainId) => getServerUrl(chainId, "/app-stats")),
    {
      fetcher: arrayURLFetcher,
      refreshInterval: 30000,
    }
  );

  const { data: DailyStatsAll } = useSWR(
    ACTIVE_CHAIN_IDS.map((chainId) => `${getServerUrl(chainId, "/daily-stats")}?from=${utcToday}&to=${utcToday}`),
    {
      fetcher: arrayURLFetcher,
      refreshInterval: 30000,
    }
  );

  const { data: dataStatsAllV2 } = useSWR(
    ACTIVE_CHAIN_IDS_V2.map((chainId) => getServerUrlV2(chainId, "/public/app-stats")),
    {
      fetcher: arrayURLFetcher,
      refreshInterval: 30000,
    }
  );

  const { gmxPrice, gmxPriceFromFantom, gmxPriceFromOP, gmxPriceFromArbitrum, gmxPriceFromBase } = useGmxPrice(
    chainId,
    { arbitrum: chainId === ARBITRUM ? library : undefined, op: chainId === OP ? library : undefined },
    active
  );
  const currentStats = getStatsInfo(dataStatsAll);
  const currentStatsV2 = getStatsInfoV2(dataStatsAllV2);
  const whitelistedTokens = getWhitelistedTokens(chainId);
  const tokenList = whitelistedTokens.filter((t) => !t.isWrapped);

  const glpManagerAddress = getContract(chainId, "GlpManager");
  const glpManagerAddressFANTOM = getContract(FANTOM, "GlpManager");
  const glpManagerAddressOP = getContract(OP, "GlpManager");
  const glpManagerAddressARB = getContract(ARBITRUM, "GlpManager");
  const glpManagerAddressBase = getContract(BASE, "GlpManager");

  const tokenFarmAddress = getContract(FANTOM, "MMYFarm");

  const readerAddress = getContract(chainId, "Reader");
  const gmxAddress = getContract(chainId, "GMX");
  const glpAddress = getContract(chainId, "GLP");
  const usdgAddress = getContract(chainId, "USDG");
  const glpVesterAddress = getContract(chainId, "GlpVester");
  const gmxVesterAddress = getContract(chainId, "GmxVester");
  const tokensForSupplyQuery = [gmxAddress, glpAddress, usdgAddress];

  const { data: aums } = useSWR([`Dashboard:getAums`, chainId, glpManagerAddress, "getAums"], {
    fetcher: contractFetcher(library, GlpManager),
  });

  const { infoTokens } = useInfoTokens(library, chainId, active, undefined, undefined);

  let aum;
  if (aums && aums.length > 0) {
    aum = aums[0].add(aums[1]).div(2);
  }

  let adjustedUsdgSupply = bigNumberify(0);

  for (let i = 0; i < tokenList.length; i++) {
    const token = tokenList[i];
    const tokenInfo = infoTokens[token.address];
    if (tokenInfo && tokenInfo.usdgAmount) {
      adjustedUsdgSupply = adjustedUsdgSupply.add(tokenInfo.usdgAmount);
    }
  }

  const { data: totalSupplies } = useSWR(
    [`Dashboard:totalSupplies:${active}`, chainId, readerAddress, "getTokenBalancesWithSupplies", AddressZero],
    {
      fetcher: contractFetcher(library, ReaderV2, [tokensForSupplyQuery]),
      refreshInterval: 30000,
    }
  );

  const { data: gmxSupplyOfGmxVester } = useSWR(
    [`Dashboard:gmxSupplyOfGmxVester:${active}`, chainId, gmxAddress, "balanceOf", gmxVesterAddress],
    {
      fetcher: contractFetcher(library, Token),
      refreshInterval: 30000,
    }
  );

  const { data: gmxSupplyOfGlpVester } = useSWR(
    [`Dashboard:gmxSupplyOfGlpVester:${active}`, chainId, gmxAddress, "balanceOf", glpVesterAddress],
    {
      fetcher: contractFetcher(library, Token),
      refreshInterval: 30000,
    }
  );

  const {
    total: totalGmxStaked,
    fantom: fantomGmxStaked,
    // fantomlegacy: fantomGmxStakedLegacy,
    op: opGmxStaked,
    // oplegacy: opGmxStakedLegacy,
    arbitrum: arbitrumStakedGmx,
    base: baseStakedGmx,
  } = useTotalGmxStaked();
  let currentChainGmxStaked = fantomGmxStaked;
  if (chainId === OP) currentChainGmxStaked = opGmxStaked;
  if (chainId === ARBITRUM) currentChainGmxStaked = arbitrumStakedGmx;
  if (chainId === BASE) currentChainGmxStaked = baseStakedGmx;

  const { data: aumsOP } = useSWR([`Dashboard:getAumsOP`, OP, glpManagerAddressOP, "getAums"], {
    fetcher: contractFetcher(library, GlpManager),
    refreshInterval: 30000,
  });

  const { data: aumsFANTOM } = useSWR([`Dashboard:getAumsFANTOM`, FANTOM, glpManagerAddressFANTOM, "getAums"], {
    fetcher: contractFetcher(library, GlpManager),
    refreshInterval: 30000,
  });
  // const { data: aumsFANTOMlegacy } = useSWR(
  //   [`Dashboard:getAumsFANTOMlegacy`, FANTOM, "0x304951d7172bCAdA54ccAC1E4674862b3d5b3d5b", "getAums"],
  //   {
  //     fetcher: contractFetcher(library, GlpManager),
  //     refreshInterval: 30000,
  //   }
  // );

  const [aumsAllChain, setAumsAllChain] = useState({});

  useEffect(() => {
    const query = gql(` {
      glpStats(where: {id_gte: "${utcToday}"}) {
        aumInUsdg
      }
    }`);

    (async () => {
      const notIncludeLegacy = ACTIVE_CHAIN_IDS.filter((chainId) => chainId !== FANTOM_LEGACY);

      const data = await Promise.all(notIncludeLegacy.map((chainId) => getGmxGraphClient(chainId)?.query({ query })));

      const result = {};

      notIncludeLegacy.forEach((chainId, index) => {
        result[chainId] = data[index].data.glpStats.length ? data[index].data.glpStats[0].aumInUsdg : undefined;
      });

      setAumsAllChain(result);
    })();
  }, []);

  const { data: aumsArb } = useSWR([`Dashboard:getAumsARB`, ARBITRUM, glpManagerAddressARB, "getAums"], {
    fetcher: contractFetcher(library, GlpManager),
    refreshInterval: 30000,
  });

  const { data: aumsBase } = useSWR([`Dashboard:getAumsBase`, BASE, glpManagerAddressBase, "getAums"], {
    fetcher: contractFetcher(library, GlpManager),
    refreshInterval: 30000,
  });

  let aumOP;
  if (aumsOP && aumsOP.length > 0) {
    aumOP = aumsOP[0].add(aumsOP[1]).div(2);
  }

  let aumFANTOM;
  if (aumsFANTOM && aumsFANTOM.length > 0) {
    aumFANTOM = aumsFANTOM[0].add(aumsFANTOM[1]).div(2);
  }

  // let aumFANTOMlegacy;
  // if (aumsFANTOMlegacy && aumsFANTOMlegacy.length > 0) {
  //   aumFANTOMlegacy = aumsFANTOMlegacy[0].add(aumsFANTOMlegacy[1]).div(2);
  // }

  let aumArb;
  if (aumsArb && aumsArb.length > 0) {
    aumArb = aumsArb[0].add(aumsArb[1]).div(2);
  }

  let aumBase;
  if (aumsBase && aumsBase.length > 0) {
    aumBase = aumsBase[0].add(aumsBase[1]).div(2);
  }

  let glpPrice;
  let glpSupply;
  let glpMarketCap;
  if (aum && totalSupplies && totalSupplies[3]) {
    glpSupply = totalSupplies[3];
    if (gmxSupplyOfGmxVester && gmxSupplyOfGlpVester) {
      glpSupply = glpSupply.sub(gmxSupplyOfGmxVester).sub(gmxSupplyOfGlpVester);
    }

    glpPrice =
      aum && aum.gt(0) && glpSupply.gt(0)
        ? aum.mul(expandDecimals(1, GLP_DECIMALS)).div(glpSupply)
        : expandDecimals(1, USD_DECIMALS);
    glpMarketCap = glpPrice.mul(glpSupply).div(expandDecimals(1, GLP_DECIMALS));
  }

  let tvl;
  if (currentChainGmxStaked && aum && gmxPrice) {
    tvl = gmxPrice.mul(currentChainGmxStaked).div(expandDecimals(1, GMX_DECIMALS)).add(aum);
  }

  // let fantomTvl;
  // if (fantomGmxStaked && aumFANTOM && gmxPriceFromFantom) {
  //   fantomTvl = gmxPriceFromFantom.mul(fantomGmxStaked).div(expandDecimals(1, GMX_DECIMALS)).add(aumFANTOM);
  // }

  // let fantomLegacyTvl;
  // if (fantomGmxStakedLegacy && aumFANTOMlegacy && gmxPriceFromFantom) {
  //   fantomLegacyTvl = gmxPriceFromFantom
  //     .mul(fantomGmxStakedLegacy)
  //     .div(expandDecimals(1, GMX_DECIMALS))
  //     .add(aumFANTOMlegacy);
  // }
  // let opTvl;
  // if (opGmxStaked && opGmxStakedLegacy && aumOP && gmxPriceFromOP) {
  //   opTvl = gmxPriceFromOP.mul(opGmxStaked.add(opGmxStakedLegacy)).div(expandDecimals(1, GMX_DECIMALS)).add(aumOP);
  // }

  // let arbTvl;
  // if (arbitrumStakedGmx && aumArb && gmxPriceFromArbitrum) {
  //   arbTvl = gmxPriceFromArbitrum.mul(arbitrumStakedGmx).div(expandDecimals(1, GMX_DECIMALS)).add(aumArb);
  // }

  // let baseTvl;
  // if (baseStakedGmx && aumBase && gmxPriceFromBase) {
  //   baseTvl = gmxPriceFromBase.mul(baseStakedGmx).div(expandDecimals(1, GMX_DECIMALS)).add(aumBase);
  // }

  const [fantomTvl, opTvl, arbTvl, baseTvl, v2FantomTvl, v2opTvl] = useMemo(() => {
    let fantomLogValue;
    if (fantomGmxStaked && gmxPriceFromFantom) {
      // fantomLogValue = gmxPriceFromFantom.mul(fantomGmxStaked).div(expandDecimals(1, GMX_DECIMALS)).add(aumFANTOM);

      fantomLogValue = parseValue(DailyStatsAll?.[0]?.[0]?.gmxStakedValue || 0, 30).add(
        parseValue(aumsAllChain[FANTOM], 12) || BigNumber.from(0)
      );
    }

    // let fantomLegacyLogValue;
    // if (fantomGmxStakedLegacy && aumFANTOMlegacy && gmxPriceFromFantom) {
    //   fantomLegacyLogValue = gmxPriceFromFantom
    //     .mul(fantomGmxStakedLegacy)
    //     .div(expandDecimals(1, GMX_DECIMALS))
    //     .add(aumFANTOMlegacy);
    // }
    let opLogValue;
    if (opGmxStaked && gmxPriceFromOP) {
      // opLogValue = gmxPriceFromOP.mul(opGmxStaked).div(expandDecimals(1, GMX_DECIMALS)).add(aumOP);
      opLogValue = parseValue(DailyStatsAll?.[2]?.[0]?.gmxStakedValue || 0, 30).add(
        parseValue(aumsAllChain[OP], 12) || BigNumber.from(0)
      );
    }

    let arbLogValue;
    if (arbitrumStakedGmx && gmxPriceFromArbitrum) {
      // arbLogValue = gmxPriceFromArbitrum.mul(arbitrumStakedGmx).div(expandDecimals(1, GMX_DECIMALS)).add(aumArb);
      arbLogValue = parseValue(DailyStatsAll?.[3]?.[0]?.gmxStakedValue || 0, 30).add(
        parseValue(aumsAllChain[ARBITRUM], 12) || BigNumber.from(0)
      );
    }

    let baseLogValue;
    if (baseStakedGmx && gmxPriceFromBase) {
      // baseLogValue = gmxPriceFromBase.mul(baseStakedGmx).div(expandDecimals(1, GMX_DECIMALS)).add(aumBase);
      baseLogValue = parseValue(DailyStatsAll?.[4]?.[0]?.gmxStakedValue || 0, 30).add(
        parseValue(aumsAllChain[BASE], 12) || BigNumber.from(0)
      );
    }
    let v2FantomLogValue = BigNumber.from(0);
    if (currentStatsV2?.[FANTOM]?.nlpTvl) {
      v2FantomLogValue = parseValue(currentStatsV2?.[FANTOM]?.nlpTvl || 0, USD_DECIMALS);
    }
    let v2opTvl = BigNumber.from(123);
    if (currentStatsV2?.[OP]?.nlpTvl) {
      v2opTvl = parseValue(currentStatsV2?.[OP]?.nlpTvl || 0, USD_DECIMALS);
    }
    return [
      fantomLogValue,
      // fantomLegacyLogValue,
      opLogValue,
      arbLogValue,
      baseLogValue,
      v2FantomLogValue,
      v2opTvl,
    ];
  }, [
    fantomGmxStaked,
    aumFANTOM,
    gmxPriceFromFantom,
    opGmxStaked,
    aumOP,
    gmxPriceFromOP,
    arbitrumStakedGmx,
    aumArb,
    gmxPriceFromArbitrum,
    baseStakedGmx,
    aumBase,
    gmxPriceFromBase,
    currentStatsV2?.nlpTvl,
  ]);

  const totalTvl = useMemo(() => {
    let totalLogVal;
    if (fantomTvl && opTvl && arbTvl && baseTvl) {
      totalLogVal = fantomTvl.add(opTvl).add(arbTvl).add(baseTvl);
    }
    return totalLogVal;
  }, [fantomTvl, opTvl, arbTvl, baseTvl]);

  const totalTvlAll = useMemo(() => {
    let totalLogValAll;
    if (totalTvl && v2FantomTvl && v2opTvl) {
      totalLogValAll = totalTvl.add(v2FantomTvl).add(v2opTvl);
    }
    return totalLogValAll;
  }, [totalTvl, v2FantomTvl, v2opTvl]);
  // console.log(totalTvl, "totalTvl------------");

  // let totalTvl;
  // if (fantomTvl && fantomLegacyTvl && opTvl && arbTvl && baseTvl) {
  //   totalTvl = fantomTvl.add(opTvl).add(arbTvl).add(baseTvl);
  // }

  const totalAllVersion = useMemo(() => {
    if (!isEmpty(currentStats) && !isEmpty(currentStatsV2)) {
      return {
        totalTradingVolume: currentStats?.totalVolume?.add(parseValue(currentStatsV2?.volume || 0, USD_DECIMALS)),
        totalFees: currentStats?.totalFees?.add(parseValue(currentStatsV2?.fees || 0, USD_DECIMALS)),
        totalFeeSince: currentStats?.feeSinceToNow?.add(parseValue(currentStatsV2?.feeSince || 0, USD_DECIMALS)),
        longOpenInterest: currentStats?.longOpenInterest?.add(
          parseValue(currentStatsV2?.longAmountInterest || 0, USD_DECIMALS)
        ),
        shortOpenInterest: currentStats?.shortOpenInterest?.add(
          parseValue(currentStatsV2?.shortAmountInterest || 0, USD_DECIMALS)
        ),
        totalUsers: currentStats?.totalUser?.add(parseValue(currentStatsV2?.users || 0, 0)),
      };
    }
    return {};
  }, [currentStats, currentStatsV2]);

  const valueProvider = useMemo(() => {
    return {
      glpPrice,
      gmxPrice,
      totalValueLocked: {
        total: totalTvl,
        totalAll: totalTvlAll,
        totalV2: v2FantomTvl,
        [FANTOM]: fantomTvl,
        // [FANTOM_LEGACY]: fantomLegacyTvl,
        [OP]: opTvl,
        [ARBITRUM]: arbTvl,
        [BASE]: baseTvl,
        v2: {
          [FANTOM]: v2FantomTvl,
          [OP]: v2opTvl,
        },
      },
      totalFees: {
        total: currentStats.totalFees,
        // [FANTOM_LEGACY]: currentStats[FANTOM_LEGACY]?.totalFees,
        [FANTOM]: currentStats[FANTOM]?.totalFees,
        [OP]: currentStats[OP]?.totalFees,
        [ARBITRUM]: currentStats[ARBITRUM]?.totalFees,
        [BASE]: currentStats[BASE]?.totalFees,
      },
      totalUsers: {
        total: currentStats.totalUser,
        [FANTOM]: currentStats[FANTOM]?.totalUser,
        // [FANTOM_LEGACY]: currentStats[FANTOM_LEGACY]?.totalUser,
        [OP]: currentStats[OP]?.totalUser,
        [ARBITRUM]: currentStats[ARBITRUM]?.totalUser,
        [BASE]: currentStats[BASE]?.totalUser,
      },
      totalTradingVolume: {
        total: currentStats.totalVolume,
        [FANTOM]: currentStats[FANTOM]?.totalVolume,
        // [FANTOM_LEGACY]: currentStats[FANTOM_LEGACY]?.totalVolume,
        [OP]: currentStats[OP]?.totalVolume,
        [ARBITRUM]: currentStats[ARBITRUM]?.totalVolume,
        [BASE]: currentStats[BASE]?.totalVolume,
      },
      longOpenInterest: {
        total: currentStats.longOpenInterest,
        [FANTOM]: currentStats[FANTOM]?.longOpenInterest,
        // [FANTOM_LEGACY]: currentStats[FANTOM_LEGACY]?.longOpenInterest,
        [OP]: currentStats[OP]?.longOpenInterest,
        [ARBITRUM]: currentStats[ARBITRUM]?.longOpenInterest,
        [BASE]: currentStats[BASE]?.longOpenInterest,
      },
      shortOpenInterest: {
        total: currentStats.shortOpenInterest,
        [FANTOM]: currentStats[FANTOM]?.shortOpenInterest,
        // [FANTOM_LEGACY]: currentStats[FANTOM_LEGACY]?.shortOpenInterest,
        [OP]: currentStats[OP]?.shortOpenInterest,
        [ARBITRUM]: currentStats[ARBITRUM]?.shortOpenInterest,
        [BASE]: currentStats[BASE]?.shortOpenInterest,
      },
      feeSinceToNow: {
        total: currentStats.feeSinceToNow,
        [FANTOM]: {
          value: currentStats[FANTOM]?.feeSinceToNow,
          timestamps: currentStats[FANTOM]?.feeSinceTimestamp,
        },
        // [FANTOM_LEGACY]: {
        //   value: currentStats[FANTOM_LEGACY]?.feeSinceToNow,
        //   timestamps: currentStats[FANTOM_LEGACY]?.feeSinceTimestamp,
        // },
        [OP]: {
          value: currentStats[OP]?.feeSinceToNow, //TODO
          timestamps: currentStats[OP]?.feeSinceTimestamp,
        },
        [ARBITRUM]: {
          value: currentStats[ARBITRUM]?.feeSinceToNow,
          timestamps: currentStats[ARBITRUM]?.feeSinceTimestamp,
        },
        [BASE]: {
          value: currentStats[BASE]?.feeSinceToNow,
          timestamps: currentStats[BASE]?.feeSinceTimestamp,
        },
      },
      v2: currentStatsV2,
      totalAllVersion,
    };
  }, [
    glpPrice,
    gmxPrice,
    totalTvl,
    totalTvlAll,
    v2FantomTvl,
    fantomTvl,
    opTvl,
    arbTvl,
    baseTvl,
    currentStats,
    currentStatsV2,
    totalAllVersion,
  ]);

  return <StatsContext.Provider value={valueProvider}>{children}</StatsContext.Provider>;
};

export default StatsProvider;
