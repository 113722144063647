import { ARBITRUM, FANTOM } from "config/chains";

import ftm16Icon from "img/ic_ftm_16.svg";
import op16Icon from "img/ic_op_16.svg";
import arb16Icon from "img/ic_arb_24.svg";

export default function getImageChainMini(chainId) {
  if (chainId === FANTOM) {
    return <img src={ftm16Icon} alt="FANTOM Icon" className="selected-network-symbol" />;
  }
  if (chainId === ARBITRUM) {
    return <img src={arb16Icon} alt="ARBITRUM Icon" className="selected-network-symbol" />;
  }

  return <img src={op16Icon} alt="OP Icon" className="selected-network-symbol" />;
}
