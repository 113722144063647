import { useWeb3React } from "@web3-react/core";
import { useEffect, useState } from "react";

import SEO from "components/Common/SEO";
import Footer from "components/Footer/Footer";
import { ARBITRUM, FANTOM, MUMBAI } from "config/chains";
import { useChainId } from "lib/chains";
import { getPageTitle } from "lib/legacy";
import styled from "styled-components";
import Banner from "./components/Banner";
import Banner2 from "./components/Banner2";
import HowTo from "./components/Howto";
import StakeNWFTMain from "./components/StakeNFTInfo";
import StartBlock from "./components/StartBlock";
import "./style.scss";
import NewCollection from "./components/NewCollection";
import { useThemeContext } from "contexts/ThemeProvider";

function NFT({ connectWallet, setPendingTxns, pendingTxns }) {
  const { lightThemeClassName } = useThemeContext();
  const { chainId } = useChainId();
  // const { active, library, account, chainId: waletChainId } = useWeb3React();
  const [isVisible, setIsVisible] = useState(false);
  // const [activedTab, setActivedTab] = useState(chainId);
  // const handleClickTab = (value) => {
  //   setActivedTab(value);
  //   switchNetwork(value, true);
  // };
  return (
    <SEO title={getPageTitle("NFT")}>
      <NFTWrap className={lightThemeClassName}>
        {/* <ClubTabs>
          {CLUBS.map((item) => (
            <ClubItem
              className={`${item.value === activedTab ? "actived" : ""}`}
              onClick={() => {
                handleClickTab(item.value);
              }}
              key={item.name}
            >
              <img alt="img" src={item.icon} />
              {item.name}
            </ClubItem>
          ))}
        </ClubTabs> */}
        <Banner
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          connectWallet={connectWallet}
          setPendingTxns={setPendingTxns}
          pendingTxns={pendingTxns}
        />

        {(chainId === FANTOM || chainId === MUMBAI) && <NewCollection />}
        {chainId !== ARBITRUM && (
          <>
            <StakeNWFTMain
              isVisible={isVisible}
              setIsVisible={setIsVisible}
              connectWallet={connectWallet}
              setPendingTxns={setPendingTxns}
              pendingTxns={pendingTxns}
            />
            {/* <Participate /> */}
            <Banner2 />
            <HowTo isVisible={isVisible} setIsVisible={setIsVisible} />
            <StartBlock />
          </>
        )}
        {chainId === ARBITRUM && <div className="nft-notavailable-outside"></div>}
      </NFTWrap>
      <Footer />
    </SEO>
  );
}

const NFTWrap = styled.div`
  .btn-primary {
    &:disabled {
      background: #848e8b !important;
      opacity: 1;
    }
  }
  margin-bottom: 160px;
  .container {
    width: 100%;
    max-width: 1144px;
    padding-right: 24px;
    padding-left: 24px;
    margin-right: auto;
    margin-left: auto;

    @media (max-width: 767px) {
      padding-right: 16px;
      padding-left: 16px;
    }
  }
  .btn-primary {
    border: none;
    background: #03f5ae;
    border-radius: 8px;
    color: #080715;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background: #04d397;
      color: #080715 !important;
    }
  }
  .disable {
    background: #b4cfc7;
  }
  .btn-outline {
    font-weight: 500;
    font-size: 14px;
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    color: #ffffff;
    justify-content: center;
    display: flex;
    align-items: center;
  }
  .Footer-wrapper {
    position: unset;
    width: 100%;
    transform: none;
  }

  &.theme--light {
    .btn-primary {
      background: var(--Primary, #02b27f);
      color: #fff !important;

      &:not(:disabled):hover {
        background: #03805b !important;
      }

      &:not(:disabled):active {
        background: #03805b !important;
      }
    }

    .avt-content {
      * {
        color: #fff !important;
      }
    }
  }
`;

export default NFT;
