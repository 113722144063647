import React from "react";
import { AdvancedChart } from "react-tradingview-embed";

const CRYPTO_COMPARE = "54c69a67adfc783963d3589c5a08a40a5d619b0f22b94b1c79df9acc9129c5ff";



class Dashboard extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.loadChartData();
    }

    loadChartData = async () => {
        const response = await fetch(
            `https://min-api.cryptocompare.com/data/blockchain/histo/day?fsym=${this.props.symbol || "BTC"}&api_key=${CRYPTO_COMPARE}&limit=30`
        );
        const data = await response.json();
        const bulkData = data?.Data?.Data;
        const dataArray = [];
        {
            bulkData?.map((y) =>
                dataArray?.push({
                    x: y.time * 1000,
                    y: y.transaction_count * y.average_transaction_value,
                })
            );
        }
    };

    render() {
        const query = this.props.symbol || "BTC"

        return (
            <div>
                <div className="charty">
                    <AdvancedChart
                        widgetProps={{
                            symbol: `${query}USD`,
                        }}
                    />
                </div>
            </div>
        );
    }
}

export default React.memo(Dashboard);
