import Modal from "components/Modal/Modal";
import React from "react";
import styled from "styled-components";
export default function ConfirmModal({ isVisible, setIsVisible, handleConfirm, className, text }) {
  return (
    <Modal
      label="Minting Mummy Rebirth Confirmation"
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      className={className}
    >
      <Body>
        <Content>
          <div>
            Wallets that have staked MMY or esMMY will not be able to vest bonus esMMY from minting Mummy Rebirth
            without maintaining a reserve. To vest those esMMY without a reserve, we recommend using a new wallet for
            minting Mummy Rebirth.
          </div>
          <div>
            If you're holding V1 NFTs, ensure that you transfer all of them to the new wallet for burning, which will
            enable you to receive your multiDAI shares.
          </div>
          <div>
            If you have been vesting your bonuses from minting old NFTs without staking any MMY or esMMY, you can
            continue minting Mummy Rebirth on this wallet and depositing your bonus esMMY to vest.{" "}
            <a
              href="https://medium.com/@mummyftm/mummy-rebirth-is-now-on-air-7c04c6a58bd6"
              target="_blank"
              rel="noreferrer"
            >
              Read more
            </a>

          </div>
        </Content>
        <ButtonGroup>
          {" "}
          <button className="border-btn" onClick={() => setIsVisible(false)}>
            Cancel
          </button>
          <button className="default-btn" onClick={handleConfirm} disabled={text === "Confirming..." ? true : false}>
            {text}
          </button>
        </ButtonGroup>
      </Body>
    </Modal>
  );
}

const ButtonGroup = styled.div`
  margin-top: 24px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
`;
const Body = styled.div``;

const Content = styled.div`
  > div:not(:last-child) {
    margin-bottom: 12px;
  }
`;
