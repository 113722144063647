import React, { useState } from "react";
import "./ConfirmationBox.css";
import { BASIS_POINTS_DIVISOR, USD_DECIMALS, importImage } from "lib/legacy";

import Modal from "../Modal/Modal";

import ExchangeInfoRow from "./ExchangeInfoRow";

import { formatAmount, parseValue } from "lib/numbers";
import { helperToast } from "lib/helperToast";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { THEME_KEY } from "config/localStorage";
const antIcon = <LoadingOutlined style={{ fontSize: 16, color: "grey" }} spin />;
export default function ConfirmationBox(props) {
  const {
    isSwap,
    isLong,
    orderOption,
    onConfirmationClick,
    setIsConfirming,
    leverage,
    spread,
    openPosFee,
    markPrice,
    posQty,
    posSize,
    liqPrice,
    isSubmitting,
    isMarketOrder,
    isPendingConfirmation,
    takeProfitTriggerPrice,
    stopLossTriggerPrice,
    isTakeProfit,
    isStopLoss,
    savedSlippageAmount,
    setSavedSlippageAmount,
    chartSymbol,
    isLimitOrder,
    limitPrice,
    isStopOrder,
    stopPrice,
    isStopLimitOrder,
    currentMarket,
  } = props;
  const slippage = parseInt(savedSlippageAmount);
  const [slippageAmount, setSlippageAmount] = useState((slippage / BASIS_POINTS_DIVISOR) * 100);
  const renderMarginSection = () => {
    return (
      <>
        <div>
          <div className="order-details-container">
            <div className="title">Order Details</div>
            <div className="square-container">
              <ExchangeInfoRow label={`Pos. Size`}>${formatAmount(posSize, 5, 2, true)}</ExchangeInfoRow>
              <ExchangeInfoRow label={`Pos. Qty`}>
                {formatAmount(posQty, 5, 3, true)} {chartSymbol}
              </ExchangeInfoRow>
              <ExchangeInfoRow label={`Leverage`}>
                <div className="position-list-leve">{leverage}x</div>
              </ExchangeInfoRow>

              {!isLimitOrder && !isStopOrder && !isStopLimitOrder && (
                <ExchangeInfoRow label={`Entry Price`}>
                  ${formatAmount(markPrice, USD_DECIMALS, currentMarket?.decimals || 2, true)}
                </ExchangeInfoRow>
              )}
              {(isLimitOrder || isStopLimitOrder) && (
                <ExchangeInfoRow label={`Limit Price`}>
                  ${formatAmount(parseValue(limitPrice, 30), 30, currentMarket?.decimals, true)}
                </ExchangeInfoRow>
              )}
              {(isStopOrder || isStopLimitOrder) && (
                <ExchangeInfoRow label={`Stop Price`}>
                  ${formatAmount(parseValue(stopPrice, 30), 30, currentMarket?.decimals, true)}
                </ExchangeInfoRow>
              )}
              {isTakeProfit && (
                <ExchangeInfoRow label={`Take Profit Price`}>
                  ${formatAmount(parseValue(takeProfitTriggerPrice, 30), 30, currentMarket?.decimals || 2, true)}
                </ExchangeInfoRow>
              )}
              {isStopLoss && (
                <ExchangeInfoRow label={`Stop Loss Price`}>
                  ${formatAmount(parseValue(stopLossTriggerPrice, 30), 30, currentMarket?.decimals || 2, true)}
                </ExchangeInfoRow>
              )}
              <ExchangeInfoRow label={`Liq. Price`}>
                ${formatAmount(liqPrice, USD_DECIMALS, currentMarket?.decimals, true)}
              </ExchangeInfoRow>
              <ExchangeInfoRow label={`Open Pos. Fees (0.08%)`}>
                ${formatAmount(openPosFee, 10, 3, true)}
              </ExchangeInfoRow>
              {/* <ExchangeInfoRow label={`Fee Discount (0%)`}>~${formatAmount(feeDiscount, 18, 3, true)}</ExchangeInfoRow> */}
              {/* <ExchangeInfoRow label={`Fee Discount (--%)`}>$--</ExchangeInfoRow> */}
              <ExchangeInfoRow label={`Spread`}>{formatAmount(spread, 30, 3, true)}%</ExchangeInfoRow>
            </div>
          </div>
          <div style={{ marginTop: "16px" }} />
          {!isLimitOrder && !isStopOrder && !isStopLimitOrder && (
            <div className="allow-slippaged-line">
              <ExchangeInfoRow label={`Slippage`}>
                <div className="allow-slippage-container">
                  <input
                    type="number"
                    className="App-slippage-tolerance-input"
                    min="0"
                    value={slippageAmount}
                    onChange={(e) => setSlippageAmount(e.target.value)}
                  />
                  <div>%</div>
                </div>
              </ExchangeInfoRow>
            </div>
          )}
          {/* {renderExecutionFee()} */}
        </div>
      </>
    );
  };

  const getPrimaryText = () => {
    if (!isPendingConfirmation) {
      const action = isMarketOrder ? (isLong ? `Long` : `Short`) : `Create Order`;
      return action;
    }
    if (isMarketOrder) {
      if (isLong) {
        return `Longing...`;
      }
      return `Shorting...`;
    } else return "Creating...";
  };
  const isPrimaryEnabled = () => {
    return !isPendingConfirmation && !isSubmitting;
  };
  const handleConfirmClick = async () => {
    const slippage = parseFloat(slippageAmount);
    if (isNaN(slippage)) {
      helperToast.error(`Invalid slippage value`);
      return;
    }
    if (slippage > 5) {
      helperToast.error(`Slippage should be less than 5%`);
      return;
    }
    const basisPoints = (slippage * BASIS_POINTS_DIVISOR) / 100;
    if (parseInt(basisPoints) !== parseFloat(basisPoints)) {
      helperToast.error(`Max slippage precision is 0.01%`);
      return;
    }
    await setSavedSlippageAmount(basisPoints);
    onConfirmationClick && onConfirmationClick();
  };
  return (
    <div className="Confirmation-box">
      <Modal
        isVisible={true}
        setIsVisible={() => setIsConfirming(false)}
        label={"New Position Order"}
        allowContentTouchMove
        className={`confirmation-modal`}
      >
        <div className="order-title-line order-title-line-details">
          <img className="small" src={importImage("ic_" + chartSymbol.toLowerCase() + "_24.svg")} alt="" />
          <div className="title">{chartSymbol}</div>
          <div className={`side ${isLong ? "side-long" : "side-short"}`}>{isLong ? "LONG" : "SHORT"}</div>
          <div className="side">{orderOption}</div>
        </div>
        <div className="content-container">{renderMarginSection()}</div>
        <div className="Confirmation-box-row">
          <button
            onClick={handleConfirmClick}
            className={`App-cta Confirmation-box-button ${isLong ? "default-btn" : "short-btn-v2"}`}
            disabled={!isPrimaryEnabled()}
          >
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
    </div>
  );
}
