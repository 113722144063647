import { t, Trans } from "@lingui/macro";
import { useWeb3React } from "@web3-react/core";
import ExternalLink from "components/ExternalLink/ExternalLink";
import GMXAprTooltip from "components/Stake/GMXAprTooltip";
import StatsTooltip from "components/StatsTooltip/StatsTooltip";
import Tooltip from "components/Tooltip/Tooltip";
import { ARBITRUM, BASE, getConstant } from "config/chains";
import { getContract } from "config/contracts";
import { useTotalGmxStaked, useTotalEsGmxStaked } from "domain/legacy";
import { ethers } from "ethers";
import TokenIcon from "img/earn/mmy_coin.svg";
import { useChainId } from "lib/chains";
import { callContract, contractFetcher } from "lib/contracts";
import { helperToast } from "lib/helperToast";
import { BASIS_POINTS_DIVISOR, USD_DECIMALS } from "lib/legacy";
import {
  formatAddKeysAmount,
  formatAmount,
  formatAmountFree,
  formatKeyAmount,
  formatKeyAmountFixed,
  parseValue,
} from "lib/numbers";
import { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import useSWR from "swr";
import "./Earn.scss";
import "./EarnTheme.scss";
import Token from "abis/Token.json";
import { approveTokens } from "domain/tokens";
import RewardRouter from "abis/RewardRouter.json";
import Modal from "components/Modal/Modal";
import { useThemeContext } from "contexts/ThemeProvider";
const { AddressZero } = ethers.constants;
function StakeModal(props) {
  const {
    isVisible,
    setIsVisible,
    chainId,
    title,
    maxAmount,
    value,
    setValue,
    active,
    account,
    library,
    stakingTokenSymbol,
    stakingTokenAddress,
    farmAddress,
    rewardRouterAddress,
    stakeMethodName,
    setPendingTxns,
  } = props;
  const [isStaking, setIsStaking] = useState(false);
  const [isApproving, setIsApproving] = useState(false);

  const { data: tokenAllowance } = useSWR(
    active &&
      stakingTokenAddress && [
        `allowance:${active}:${account}:${farmAddress}`,
        chainId,
        stakingTokenAddress,
        "allowance",
        account,
        farmAddress,
      ],
    {
      fetcher: contractFetcher(library, Token),
      refreshInterval: 10000,
    }
  );
  let amount = parseValue(value, 18);
  const needApproval = farmAddress !== AddressZero && tokenAllowance && amount && amount.gt(tokenAllowance);

  const getError = () => {
    if (!amount || amount.eq(0)) {
      return `Enter an amount`;
    }
    if (maxAmount && amount.gt(maxAmount)) {
      return `Max amount exceeded`;
    }
  };

  const onClickPrimary = () => {
    if (needApproval) {
      approveTokens({
        setIsApproving,
        library,
        tokenAddress: stakingTokenAddress,
        spender: farmAddress,
        chainId,
      });
      return;
    }

    setIsStaking(true);
    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter.abi, library.getSigner());

    callContract(chainId, contract, stakeMethodName, [amount], {
      sentMsg: `Stake submitted!`,
      failMsg: `Stake failed.`,
      setPendingTxns,
    })
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsStaking(false);
      });
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }
    if (isApproving) {
      return false;
    }
    if (isStaking) {
      return false;
    }
    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }
    if (isApproving) {
      return `Approving ${stakingTokenSymbol}...`;
    }
    if (needApproval) {
      return `Approve ${stakingTokenSymbol}`;
    }
    if (isStaking) {
      return `Staking...`;
    }
    return `Stake`;
  };

  return (
    <ModalWrapper className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={title}>
        <div className="Exchange-swap-section">
          <StyledSectionTop className="Exchange-swap-section-top">
            <div className="muted">
              <div className="Exchange-swap-usd">
                <span>Stake</span>
              </div>
            </div>
            <div className="muted align-right clickable" onClick={() => setValue(formatAmountFree(maxAmount, 18, 18))}>
              <span>Max: {formatAmount(maxAmount, 18, 4, true)}</span>
            </div>
          </StyledSectionTop>
          <div className="Exchange-swap-section-bottom">
            <div>
              <StyledInput
                type="number"
                placeholder="0.0"
                className="Exchange-swap-input"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </div>
            <StyledTokenSymbol className="PositionEditor-token-symbol">{stakingTokenSymbol}</StyledTokenSymbol>
          </div>
        </div>
        {stakingTokenSymbol === "esMMY" && chainId !== ARBITRUM && chainId !== BASE ? (
          <Warning>
            Please note that your staked esMMY amount includes the esMMY bonus from minting NFTs. Keep in mind that if
            you decide to unstake and vest esMMY to MMY later, you will be required to deposit a reserve amount of MMY
            that corresponds to the rewards generated.
          </Warning>
        ) : null}

        <div className="Exchange-swap-button-container">
          <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
    </ModalWrapper>
  );
}
function UnstakeModal(props) {
  const {
    isVisible,
    setIsVisible,
    chainId,
    title,
    maxAmount,
    value,
    setValue,
    library,
    unstakingTokenSymbol,
    rewardRouterAddress,
    unstakeMethodName,
    multiplierPointsAmount,
    reservedAmount,
    bonusGmxInFeeGmx,
    setPendingTxns,
  } = props;
  const [isUnstaking, setIsUnstaking] = useState(false);

  let amount = parseValue(value, 18);
  let burnAmount;

  if (
    multiplierPointsAmount &&
    multiplierPointsAmount.gt(0) &&
    amount &&
    amount.gt(0) &&
    bonusGmxInFeeGmx &&
    bonusGmxInFeeGmx.gt(0)
  ) {
    burnAmount = multiplierPointsAmount.mul(amount).div(bonusGmxInFeeGmx);
  }

  const shouldShowReductionAmount = true;
  let rewardReductionBasisPoints;
  if (burnAmount && bonusGmxInFeeGmx) {
    rewardReductionBasisPoints = burnAmount.mul(BASIS_POINTS_DIVISOR).div(bonusGmxInFeeGmx);
  }

  const getError = () => {
    if (!amount) {
      return `Enter an amount`;
    }
    if (amount && amount.lte(0)) return "Enter an amount";
    if (amount.gt(maxAmount)) {
      return `Max amount exceeded`;
    }
  };

  const onClickPrimary = () => {
    setIsUnstaking(true);
    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter.abi, library.getSigner());
    callContract(chainId, contract, unstakeMethodName, [amount], {
      sentMsg: `Unstake submitted!`,
      failMsg: `Unstake failed.`,
      successMsg: `Unstake completed!`,
      setPendingTxns,
    })
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsUnstaking(false);
      });
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }
    if (isUnstaking) {
      return false;
    }
    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }
    if (isUnstaking) {
      return `Unstaking...`;
    }
    return `Unstake`;
  };

  return (
    <ModalWrapper className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={title}>
        <div className="Exchange-swap-section">
          <StyledSectionTop className="Exchange-swap-section-top">
            <div className="muted">
              <div className="Exchange-swap-usd">
                <span>Unstake</span>
              </div>
            </div>
            <div className="muted align-right clickable" onClick={() => setValue(formatAmountFree(maxAmount, 18, 18))}>
              <span>Max: {formatAmount(maxAmount, 18, 4, true)}</span>
            </div>
          </StyledSectionTop>
          <div className="Exchange-swap-section-bottom">
            <div>
              <StyledInput
                type="number"
                placeholder="0.0"
                className="Exchange-swap-input"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </div>
            <StyledTokenSymbol className="PositionEditor-token-symbol">{unstakingTokenSymbol}</StyledTokenSymbol>
          </div>
        </div>
        {reservedAmount && reservedAmount.gt(0) && (
          <div className="Modal-note">
            You have {formatAmount(reservedAmount, 18, 2, true)} tokens reserved for vesting.
          </div>
        )}
        {burnAmount && burnAmount.gt(0) && rewardReductionBasisPoints && rewardReductionBasisPoints.gt(0) && (
          <div className="Modal-note">
            Unstaking will burn&nbsp;
            <ExternalLink href="https://docs.mummy.finance/rewards">
              {formatAmount(burnAmount, 18, 4, true)} Multiplier Points
            </ExternalLink>
            .&nbsp;
            {shouldShowReductionAmount && (
              <span>Boost Percentage: -{formatAmount(rewardReductionBasisPoints, 2, 2)}%.</span>
            )}
          </div>
        )}
        <div className="Exchange-swap-button-container">
          <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
    </ModalWrapper>
  );
}
const MMYandESMMY = (props) => {
  const { lightThemeClassName } = useThemeContext();
  const { chainId } = useChainId();
  const {
    setPendingTxns,
    bonusGmxInFeeGmx,
    processedData,
    multiplierPointsAmount,
    connectWallet,
    maxUnstakeableGmx,
    vestingData,
    gmxPrice,
    stakedGmxSupplyUsd,
    stakedEsGmxSupplyUsd,
    hasMultiplierPoints,
    totalGmxSupply,
    totalSupplyUsd,
    esGmxSupplyUsd,
    esGmxSupply,
  } = props;
  const nativeTokenSymbol = getConstant(chainId, "nativeTokenSymbol");
  const wrappedTokenSymbol = getConstant(chainId, "wrappedTokenSymbol");
  let {
    op: opGmxStaked,
    // oplegacy: opGmxStakedLegacy,
    fantom: fantomGmxStaked,
    // fantomlegacy: fantomGmxStakedLegacy,
    total: totalGmxStaked,
    arbitrum: arbitrumGmxStaked,
    base: baseGmxStaked,
  } = useTotalGmxStaked();
  let {
    op: opEsGmxStaked,
    fantom: fantomEsGmxStaked,
    // fantomlegacy: fantomEsGmxStakedLegacy,
    arbitrum: arbitrumEsGmxStaked,
    base: baseEsGmxStaked,
    total: totalEsGmxStaked,
  } = useTotalEsGmxStaked();

  const { account, active, library } = useWeb3React();
  const isGmxTransferEnabled = true;

  const [isStakeModalVisible, setIsStakeModalVisible] = useState(false);
  const [stakeModalTitle, setStakeModalTitle] = useState("");
  const [stakeModalMaxAmount, setStakeModalMaxAmount] = useState(undefined);
  const [stakeValue, setStakeValue] = useState("");
  const [stakingTokenSymbol, setStakingTokenSymbol] = useState("");
  const [stakingTokenAddress, setStakingTokenAddress] = useState("");
  const [stakingFarmAddress, setStakingFarmAddress] = useState("");
  const [stakeMethodName, setStakeMethodName] = useState("");
  const stakedGmxTrackerAddress = getContract(chainId, "StakedGmxTracker");

  const esGmxAddress = getContract(chainId, "ES_GMX");
  const gmxAddress = getContract(chainId, "GMX");
  const showStakeGmxModal = () => {
    if (!isGmxTransferEnabled) {
      helperToast.error(`MMY transfers not yet enabled`);
      return;
    }

    setIsStakeModalVisible(true);
    setStakeModalTitle(`Stake MMY`);
    setStakeModalMaxAmount(processedData.gmxBalance);
    setStakeValue("");
    setStakingTokenSymbol("MMY");
    setStakingTokenAddress(gmxAddress);
    setStakingFarmAddress(stakedGmxTrackerAddress);
    setStakeMethodName("stakeGmx");
  };

  const showStakeEsGmxModal = () => {
    setIsStakeModalVisible(true);
    setStakeModalTitle(`Stake esMMY`);
    setStakeModalMaxAmount(processedData.esGmxBalance);
    setStakeValue("");
    setStakingTokenSymbol("esMMY");
    setStakingTokenAddress(esGmxAddress);
    setStakingFarmAddress(AddressZero);
    setStakeMethodName("stakeEsGmx");
  };
  const showUnstakeEsGmxModal = () => {
    setIsUnstakeModalVisible(true);
    setUnstakeModalTitle(`Unstake esMMY`);
    let maxAmount = processedData.esGmxInStakedGmx;
    if (
      processedData.esGmxInStakedGmx &&
      vestingData &&
      vestingData.gmxVesterPairAmount.gt(0) &&
      maxUnstakeableGmx &&
      maxUnstakeableGmx.lt(processedData.esGmxInStakedGmx)
    ) {
      maxAmount = maxUnstakeableGmx;
    }
    setUnstakeModalMaxAmount(maxAmount);
    setUnstakeModalReservedAmount(vestingData.gmxVesterPairAmount);
    setUnstakeValue("");
    setUnstakingTokenSymbol("esMMY");
    setUnstakeMethodName("unstakeEsGmx");
  };
  const [isUnstakeModalVisible, setIsUnstakeModalVisible] = useState(false);
  const [unstakeModalTitle, setUnstakeModalTitle] = useState("");
  const [unstakeModalMaxAmount, setUnstakeModalMaxAmount] = useState(undefined);
  const [unstakeModalReservedAmount, setUnstakeModalReservedAmount] = useState(undefined);
  const [unstakeValue, setUnstakeValue] = useState("");
  const [unstakingTokenSymbol, setUnstakingTokenSymbol] = useState("");
  const [unstakeMethodName, setUnstakeMethodName] = useState("");
  const rewardRouterAddress = getContract(chainId, "RewardRouter");
  const showUnstakeGmxModal = () => {
    if (!isGmxTransferEnabled) {
      helperToast.error(`MMY transfers not yet enabled`);
      return;
    }
    setIsUnstakeModalVisible(true);
    setUnstakeModalTitle(`Unstake MMY`);
    let maxAmount = processedData.gmxInStakedGmx;
    if (
      processedData.gmxInStakedGmx &&
      vestingData &&
      vestingData.gmxVesterPairAmount.gt(0) &&
      maxUnstakeableGmx &&
      maxUnstakeableGmx.lt(processedData.gmxInStakedGmx)
    ) {
      maxAmount = maxUnstakeableGmx;
    }
    setUnstakeModalMaxAmount(maxAmount);
    setUnstakeModalReservedAmount(vestingData.gmxVesterPairAmount);
    setUnstakeValue("");
    setUnstakingTokenSymbol("MMY");
    setUnstakeMethodName("unstakeGmx");
  };
  return (
    <div className={`earn-content-container ${lightThemeClassName}`}>
      <div className="tab-content-title">MMY & esMMY</div>
      <div className="tab-content-desc">Earn rewards by staking MMY and esMMY tokens.</div>
      <div className="tab-main-content">
        <div className="main-content left-content">
          <div className="left-box-content main-content right-content">
            <div className="right-content-border-box">
              <div className="box-main-price">
                <div className="price-info">
                  <div className="title">MMY price</div>
                  <div className="value">
                    {gmxPrice ? (
                      "$" + formatAmount(gmxPrice, USD_DECIMALS, 3, true)
                    ) : (
                      <div className="skeleton-box" style={{ width: "65px", height: "20px" }} />
                    )}
                  </div>
                </div>
                <img src={TokenIcon} alt="mmy-tokens" />
              </div>
              <div className="buy-btn-container">
                <Link className="default-btn" to="/buy_mmy">
                  <span>Buy MMY</span>
                </Link>
              </div>
              <div className="box-devider">
                <div className="divider-title">MMY APR</div>
                <div className="divider-line" />
              </div>
              <div className="box-apr">
                {processedData.gmxAprTotalWithBoost ? (
                  <Tooltip
                    handle={`${formatKeyAmount(processedData, "gmxAprTotalWithBoost", 2, 2, true)}%`}
                    position="right-bottom"
                    renderContent={() => (
                      <GMXAprTooltip processedData={processedData} nativeTokenSymbol={nativeTokenSymbol} />
                    )}
                  />
                ) : (
                  <div className="skeleton-box" style={{ width: "60px", height: "20px" }} />
                )}
              </div>
              <div className="box-token-info">
                <div className="title">Total Staked</div>
                <div className="value">
                  {totalGmxStaked ? (
                    // formatAmount(totalGmxStaked, 18, 0, true) +
                    // " MMY" +
                    // ` ($${formatAmount(stakedGmxSupplyUsd, USD_DECIMALS, 2, true)})`
                    <>
                      <Tooltip
                        position="right-bottom"
                        className="nowrap"
                        handle={<>{formatAmount(totalGmxStaked, 18, 0, true)} MMY</>}
                        renderContent={() => (
                          <StatsTooltip
                            showDollar={false}
                            title={`Staked`}
                            opValue={opGmxStaked}
                            // opLegacyValue={opGmxStakedLegacy}
                            arbValue={arbitrumGmxStaked}
                            baseValue={baseGmxStaked}
                            fantomValue={fantomGmxStaked}
                            // fantomLegacyValue={fantomGmxStakedLegacy}
                            total={totalGmxStaked}
                            decimalsForConversion={18}
                            symbol="MMY"
                          />
                        )}
                      />
                      <span>${formatAmount(stakedGmxSupplyUsd, USD_DECIMALS, 0, true)}</span>
                    </>
                  ) : (
                    <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                  )}
                </div>
              </div>
              <div className="box-token-info">
                <div className="title">Total Supply</div>
                <div className="value">
                  {totalGmxSupply ? (
                    <>
                      {formatAmount(totalGmxSupply, 18, 0, true)} MMY{" "}
                      <span>${formatAmount(totalSupplyUsd, USD_DECIMALS, 0, true)}</span>
                    </>
                  ) : (
                    <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                  )}
                </div>
              </div>
              <div className="box-token-info">
                <div className="title">MPs APR</div>
                <div className="value">
                  <Tooltip
                    handle={`100.00%`}
                    position="right-bottom"
                    renderContent={() => {
                      return (
                        <span>
                          Boost your rewards with Multiplier Points.&nbsp;
                          <ExternalLink href="https://docs.mummy.finance/rewards#multiplier-points">
                            More info
                          </ExternalLink>
                          .
                        </span>
                      );
                    }}
                  />
                </div>
              </div>
              <div className="box-token-info">
                <div className="title">Boost Percentage</div>
                <div className="value">
                  {processedData.boostBasisPoints ? (
                    <Tooltip
                      handle={`${formatAmount(processedData.boostBasisPoints, 2, 2, false)}%`}
                      position="right-bottom"
                      renderContent={() => {
                        return (
                          <div>
                            You are earning{" "}
                            <span style={{ fontWeight: 700, color: "white" }}>
                              {formatAmount(processedData.boostBasisPoints, 2, 2, false)}%
                            </span>{" "}
                            more {nativeTokenSymbol} rewards using{" "}
                            <span style={{ fontWeight: 700, color: "white" }}>
                              {formatAmount(processedData.bnGmxInFeeGmx, 18, 4, 2, true)}
                            </span>{" "}
                            Staked Multiplier Points.
                            <br />
                            <div style={{ marginTop: "8px" }}>
                              <span>Use the "Compound" button to stake your Multiplier Points.</span>
                            </div>
                          </div>
                        );
                      }}
                    />
                  ) : (
                    <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                  )}
                </div>
              </div>
              <div className="box-devider">
                <div className="divider-title">esMMY APR</div>
                <div className="divider-line" />
              </div>
              <div className="box-apr">
                {processedData.gmxAprTotalWithBoost ? (
                  <Tooltip
                    handle={`${formatKeyAmount(processedData, "gmxAprTotalWithBoost", 2, 2, true)}%`}
                    position="right-bottom"
                    renderContent={() => (
                      <GMXAprTooltip processedData={processedData} nativeTokenSymbol={nativeTokenSymbol} />
                    )}
                  />
                ) : (
                  <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                )}
              </div>
              <div className="box-token-info">
                <div className="title">Total Staked</div>
                <div className="value">
                  {totalEsGmxStaked ? (
                    <>
                      <Tooltip
                        position="right-bottom"
                        className="nowrap"
                        handle={<>{formatAmount(totalEsGmxStaked, 18, 0, true)} esMMY</>}
                        renderContent={() => (
                          <StatsTooltip
                            showDollar={false}
                            title={`Staked`}
                            opValue={opEsGmxStaked}
                            fantomValue={fantomEsGmxStaked}
                            // fantomLegacyValue={fantomEsGmxStakedLegacy}
                            arbValue={arbitrumEsGmxStaked}
                            baseValue={baseEsGmxStaked}
                            total={totalEsGmxStaked}
                            decimalsForConversion={18}
                            symbol="esMMY"
                          />
                        )}
                      />
                      <span>${formatAmount(stakedEsGmxSupplyUsd, USD_DECIMALS, 0, true)}</span>
                    </>
                  ) : (
                    <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                  )}
                </div>
              </div>
              <div className="box-token-info">
                <div className="title">Total Supply</div>
                <div className="value">
                  {esGmxSupply ? (
                    <>
                      {formatAmount(esGmxSupply, 18, 0, true)} esMMY
                      <span>${formatAmount(esGmxSupplyUsd, USD_DECIMALS, 0, true)}</span>
                    </>
                  ) : (
                    <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                  )}
                </div>
              </div>
              <div className="box-token-info">
                <div className="title">MPs APR</div>
                <div className="value">
                  <Tooltip
                    handle={`100%`}
                    position="right-bottom"
                    renderContent={() => (
                      <GMXAprTooltip processedData={processedData} nativeTokenSymbol={nativeTokenSymbol} />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="left-box-content">
            <div className="main-title-container">
              <div className="main-title">MMY</div>
            </div>
            <div className="box-desc">Stake MMY to earn {nativeTokenSymbol}, esMMY, and MPs.</div>
            <div className="rewards-es-container">
              <div className="account-info">
                <div className="reward-info">
                  <div className="title">Wallet</div>
                  {processedData?.gmxBalance ? (
                    <div className="value">
                      {formatKeyAmountFixed(processedData, "gmxBalance", 18, 2, true)} MMY{" "}
                      <span>${formatKeyAmountFixed(processedData, "gmxBalanceUsd", USD_DECIMALS, 2, true)}</span>
                    </div>
                  ) : (
                    <div className="value">
                      <div className="skeleton-box" style={{ width: "65px", height: "20px" }} />
                    </div>
                  )}
                </div>
                <div className="reward-info">
                  <div className="title">Staked</div>
                  {processedData?.gmxInStakedGmx ? (
                    <div className="value">
                      {formatKeyAmount(processedData, "gmxInStakedGmx", 18, 2, true)} MMY{" "}
                      <span>${formatKeyAmount(processedData, "gmxInStakedGmxUsd", USD_DECIMALS, 2, true)}</span>
                    </div>
                  ) : (
                    <div className="value">
                      <div className="skeleton-box" style={{ width: "65px", height: "20px" }} />
                    </div>
                  )}
                </div>
              </div>
              <div className="button-actions">
                {account && (
                  <button onClick={() => showStakeGmxModal()} className="App-button-option App-card-option">
                    <span>Stake</span>
                  </button>
                )}
                {account && (
                  <button
                    onClick={() => showUnstakeGmxModal()}
                    className="App-button-option App-card-option border-btn"
                  >
                    Unstake
                  </button>
                )}
                {!account && (
                  <button className="App-button-option App-card-option" onClick={() => connectWallet()}>
                    Connect Wallet
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="left-box-content">
            <div className="main-title-container">
              <div className="main-title">esMMY</div>
            </div>
            <div className="box-desc">Stake esMMY to earn {nativeTokenSymbol}, esMMY, and MPs.</div>
            <div className="rewards-es-container">
              <div className="account-info">
                <div className="reward-info">
                  <div className="title">Wallet</div>
                  {processedData.esGmxBalance ? (
                    <div className="value">
                      {formatKeyAmountFixed(processedData, "esGmxBalance", 18, 2, true)} esMMY{" "}
                      <span>${formatKeyAmountFixed(processedData, "esGmxBalanceUsd", USD_DECIMALS, 2, true)}</span>
                    </div>
                  ) : (
                    <div className="value">
                      <div className="skeleton-box" style={{ width: "65px", height: "20px" }} />
                    </div>
                  )}
                </div>
                <div className="reward-info">
                  <div className="title">Staked</div>
                  {processedData?.esGmxInStakedGmx ? (
                    <div className="value">
                      {formatKeyAmount(processedData, "esGmxInStakedGmx", 18, 2, true)} esMMY{" "}
                      <span>${formatKeyAmount(processedData, "esGmxInStakedGmxUsd", USD_DECIMALS, 2, true)}</span>
                    </div>
                  ) : (
                    <div className="value">
                      <div className="skeleton-box" style={{ width: "65px", height: "20px" }} />
                    </div>
                  )}
                </div>
              </div>
              <div className="button-actions">
                {account && (
                  <button onClick={() => showStakeEsGmxModal()} className="App-button-option App-card-option">
                    <span>Stake</span>
                  </button>
                )}
                {account && (
                  <button
                    onClick={() => showUnstakeEsGmxModal()}
                    className="App-button-option App-card-option border-btn"
                  >
                    Unstake
                  </button>
                )}
                {!account && (
                  <button className="App-button-option App-card-option" onClick={() => connectWallet()}>
                    Connect Wallet
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="main-content left-content right-content">
          <div className="left-box-content">
            <div className="main-title-container">
              <div className="main-title">Pending rewards</div>
              {/* <div className="text-button-green">
                <Link to="/earn-v2/">Claim page</Link>
              </div> */}
            </div>
            <div className="green-value green-pending">
              $
              {formatAddKeysAmount(
                processedData,
                "feeGmxTrackerRewardsUsd",
                "stakedGmxTrackerRewardsUsd",
                USD_DECIMALS,
                2,
                true
              )}
            </div>
            <div className="rewards-container  pending-rewards-content">
              <div className="reward-info">
                <div className="title">
                  {nativeTokenSymbol} ({wrappedTokenSymbol})
                </div>
                {processedData?.feeGmxTrackerRewards ? (
                  <div className="value">
                    {formatKeyAmount(processedData, "feeGmxTrackerRewards", 18, 4, true)}{" "}
                    <span>${formatKeyAmount(processedData, "feeGmxTrackerRewardsUsd", USD_DECIMALS, 2, true)}</span>
                  </div>
                ) : (
                  <div className="value">
                    <div className="skeleton-box" style={{ width: "65px", height: "20px" }} />
                  </div>
                )}
              </div>
              <div className="reward-info">
                <div className="title">esMMY</div>
                {processedData?.stakedGmxTrackerRewards ? (
                  <div className="value">
                    {formatKeyAmount(processedData, "stakedGmxTrackerRewards", 18, 4, true)}{" "}
                    <span>${formatKeyAmount(processedData, "stakedGmxTrackerRewardsUsd", USD_DECIMALS, 2, true)}</span>
                  </div>
                ) : (
                  <div className="value">
                    <div className="skeleton-box" style={{ width: "65px", height: "20px" }} />
                  </div>
                )}
              </div>
            </div>
            <div className={`btns no-chain`}>
              <Link to="/earn-v2/" className="border-btn full">
                Claim page
              </Link>
            </div>
          </div>
        </div>
      </div>
      <StakeModal
        isVisible={isStakeModalVisible}
        setIsVisible={setIsStakeModalVisible}
        chainId={chainId}
        title={stakeModalTitle}
        maxAmount={stakeModalMaxAmount}
        value={stakeValue}
        setValue={setStakeValue}
        active={active}
        account={account}
        library={library}
        stakingTokenSymbol={stakingTokenSymbol}
        stakingTokenAddress={stakingTokenAddress}
        farmAddress={stakingFarmAddress}
        rewardRouterAddress={rewardRouterAddress}
        stakeMethodName={stakeMethodName}
        hasMultiplierPoints={hasMultiplierPoints}
        setPendingTxns={setPendingTxns}
        nativeTokenSymbol={nativeTokenSymbol}
        wrappedTokenSymbol={wrappedTokenSymbol}
      />
      <UnstakeModal
        setPendingTxns={setPendingTxns}
        isVisible={isUnstakeModalVisible}
        setIsVisible={setIsUnstakeModalVisible}
        chainId={chainId}
        title={unstakeModalTitle}
        maxAmount={unstakeModalMaxAmount}
        reservedAmount={unstakeModalReservedAmount}
        value={unstakeValue}
        setValue={setUnstakeValue}
        library={library}
        unstakingTokenSymbol={unstakingTokenSymbol}
        rewardRouterAddress={rewardRouterAddress}
        unstakeMethodName={unstakeMethodName}
        multiplierPointsAmount={multiplierPointsAmount}
        bonusGmxInFeeGmx={bonusGmxInFeeGmx}
      />
    </div>
  );
};
export default MMYandESMMY;
const Warning = styled.div`
  margin: 8px 0;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
  line-height: 19.6px;
`;

const StyledTokenSymbol = styled.div`
  font-size: 16px !important;
`;

const StyledInput = styled.input`
  font-weight: 700 !important;
`;
const StyledSectionTop = styled.div`
  > div {
    font-weight: 400 !important;
  }
`;
const ModalWrapper = styled.div`
  .Modal-body {
    padding-left: 24px !important;
    padding-right: 24px !important;

    @media (max-width: 767px) {
      padding-left: 16px !important;
      padding-right: 16px !important;
      padding-top: 16px !important;
    }
  }
`;
