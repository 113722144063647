import { commify } from "ethers/lib/utils";
import { formatAmount, parseValue } from "lib/numbers";
import React, { useMemo } from "react";
import styled from "styled-components";

interface SmallTextNumberProps {
  val: string;
  decimal?: number;
}
const SmallTextNumber: React.FC<SmallTextNumberProps> = ({ val = "--", decimal }) => {
  const convertNumber = val !== "--" ? convertCountZeroNumber(parseFloat(val)) : null;

  return (
    <StyleWapper className="small-text-number">
      {convertNumber && convertNumber.count < 4 && (
        <span>{formatAmount(parseValue(val, 30), 30, decimal || 6, true)}</span>
      )}
      {convertNumber && convertNumber.count >= 4 && (
        <span>
          <span className="start">{convertNumber.count <= 6 ? convertNumber.start : 0}</span>.0
          <span className="zero-count">{convertNumber.count <= 6 ? convertNumber.count : ">6"}</span>
          <span className="end">{convertNumber.count <= 6 ? convertNumber.end : 0}</span>
        </span>
      )}
      {!convertNumber && <span>{val}</span>}
    </StyleWapper>
  );
};
export default SmallTextNumber;
const StyleWapper = styled.span`
  font-size: 12px;
  .zero-count {
    position: relative;
    top: 5px;
    font-size: 9px;
    margin: 0 1.5px;
  }
  .d-none {
    display: none;
  }
`;
function convertCountZeroNumber(num: number, minimumZero = 4, endStringLength = 3) {
  var count = -Math.floor(Math.log(num) / Math.log(10) + 1);
  if (num !== 0 && count >= minimumZero) {
    let start = Math.floor(num);
    let decimal = num - Math.floor(num) + "";
    if (decimal.includes("e-")) {
      let splitAfterZero = decimal.split("e-")[0];
      let end = 0;
      if (splitAfterZero.includes(".")) {
        let after = parseFloat(splitAfterZero.replace(".", ""));
        var length = after.toString().length;
        if (length > endStringLength) {
          after = parseInt((after / Math.pow(10, length - endStringLength)).toFixed());
        }
        end = after;
      } else {
        // var length = end.toString().length
        end = parseInt((parseInt(splitAfterZero) / Math.pow(10, 0)).toFixed());
      }
      return { origin: num, start, end, count };
    } else {
      let splitAfterZero = decimal.split(".")[1];
      var endString = splitAfterZero?.slice(count, splitAfterZero.length);
      let end = 0;
      if (endString?.length <= endStringLength) {
        end = parseInt(endString);
      } else {
        let endStringCut = parseInt((parseInt(endString) / Math.pow(10, endString.length - endStringLength)).toFixed());
        end = endStringCut;
      }
      return { origin: num, start, end, count };
    }
  }
  return { origin: num, count };
}
