import Button from "components/Common/Button";
import React, { useEffect } from "react";
import styled from "styled-components";
import { Select } from "antd";
import { StyledPagination } from "../styles/AnalyticsV3.styled";
import cx from "classnames";

import leftIc from "img/ic_arrowleft16.svg";
import rightIc from "img/ic_arrowright16.svg";
import downIc from "img/downArrow.svg";
import "App/App.scss";
import { useThemeContext } from "contexts/ThemeProvider";

const StyledButton = styled(Button)`
  width: 30px;
  height: 30px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--Border_radius, 4px);

  .btn-label {
    margin: 0;
    padding-bottom: 5px;
  }
`;

const ROWS_PER_PAGE_OPTIONS = [
  {
    value: 10,
    label: 10,
  },
  {
    value: 20,
    label: 20,
  },
  {
    value: 50,
    label: 50,
  },
  {
    value: 100,
    label: 100,
  },
];

const Pagination = ({ row, currentPage, setCurrentPage, rowsPerPage, setRowsPerPage, className = "" }) => {
  const { lightThemeClassName } = useThemeContext() as any;
  const handleChangePage = (type) => {
    if (type === "next") {
      setCurrentPage((prev) => prev + 1);
    }
    if (type === "prev") {
      setCurrentPage((prev) => prev - 1);
    }
  };

  useEffect(() => {
    if ([currentPage * rowsPerPage - (rowsPerPage - 1)] > row) {
      setCurrentPage(Math.floor(Number(row) / Number(rowsPerPage)) + 1);
    }
  }, [currentPage, rowsPerPage, row, setCurrentPage]);

  return (
    <StyledPagination className={cx(lightThemeClassName, className)}>
      <div className="rowsPerPage">
        <span style={{ marginRight: "8px" }}>Rows per page:</span>
        <Select
          showArrow
          suffixIcon={<img className="downIc" src={downIc} alt="" />}
          onChange={(value) => {
            setRowsPerPage(value);
          }}
          defaultValue={rowsPerPage}
          style={{
            width: "fit-content",
          }}
          bordered={true}
          options={ROWS_PER_PAGE_OPTIONS}
          className={`hasBorder ${lightThemeClassName}`}
          popupClassName={lightThemeClassName}
        />
      </div>
      <div className="movePage">
        <StyledButton isDisabled={currentPage === 1} onClick={() => handleChangePage("prev")}>
          <img src={leftIc} alt="" />
        </StyledButton>
        <span>
          {currentPage * rowsPerPage - (rowsPerPage - 1)}-
          {currentPage * rowsPerPage > row ? row : currentPage * rowsPerPage} of page {currentPage}
        </span>
        <StyledButton isDisabled={currentPage * rowsPerPage >= row} onClick={() => handleChangePage("next")}>
          <img src={rightIc} alt="" />
        </StyledButton>
      </div>
    </StyledPagination>
  );
};

export default Pagination;
