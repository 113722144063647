import { Menu } from "@headlessui/react";
import { Trans } from "@lingui/macro";
import { HiDotsVertical } from "react-icons/hi";
import { AiOutlineEdit } from "react-icons/ai";
import { BiSelectMultiple } from "react-icons/bi";
import { RiShareBoxFill } from "react-icons/ri";
import "./PositionDropdown.css";
import editIcon from "img/trade/edit.svg";
import shareIcon from "img/trade/share.svg";
import addPositionIcon from "img/trade/add_positon.svg";
import increaseLeverageIcon from "img/trade/increase_leverge.svg";
import increaseCollateralIcon from "img/trade/increase_collateral.svg";
import addTpIcon from "img/trade/add_tp.svg";
import addTrailingIcon from "img/trade/add_trailing.svg";
import { useMediaQuery } from "react-responsive";

function PositionDropdown({
  handlAddTrailingStop,
  handlAddCollateral,
  handleShare,
  handlAddToPosition,
  handleAddTPSL,
  handleEditLeverage,
  handleEditTakeProfit,
  handleEditStopLoss,
  position
}) {
  const isSmallScreen = useMediaQuery({ query: "(max-width: 1024px)" });
  return (
    <Menu>
      <Menu.Button as="div">
        <button className="PositionDropdown-dots-icon" disabled={position && !position.liquidationPrice}>
          <img src={editIcon} alt="icon" style={{ width: "16px", height: "16px", maxWidth: "16px" }} />
          {isSmallScreen ? "Edit Order" : ""}
        </button>
      </Menu.Button>
      <div className="PositionDropdown-extra-options">
        <Menu.Items as="div" className="menu-items">
          <Menu.Item>
            <div
              className="menu-item"
              onClick={(e) => {
                e.stopPropagation();
                handleShare && handleShare();
              }}
            >
              <img src={shareIcon} alt="icon" />
              <p>
                <span>Share</span>
              </p>
            </div>
          </Menu.Item>
          <Menu.Item>
            <div
              className="menu-item"
              onClick={(e) => {
                e.stopPropagation();
                handlAddToPosition && handlAddToPosition();
              }}
            >
              <img src={addPositionIcon} alt="icon" />
              <p>
                <span>Add to Position</span>
              </p>
            </div>
          </Menu.Item>
          <Menu.Item>
            <div
              className="menu-item"
              onClick={(e) => {
                e.stopPropagation();
                handleEditLeverage && handleEditLeverage();
              }}
            >
              <img src={increaseLeverageIcon} alt="icon" />
              <p>
                <span>Increase Leverage</span>
              </p>
            </div>
          </Menu.Item>
          <Menu.Item>
            <div
              className="menu-item"
              onClick={(e) => {
                e.stopPropagation();
                handlAddCollateral && handlAddCollateral();
              }}
            >
              <img src={increaseCollateralIcon} alt="icon" />
              <p>
                <span>Increase Collateral</span>
              </p>
            </div>
          </Menu.Item>
          <Menu.Item>
            <div
              className="menu-item"
              onClick={(e) => {
                e.stopPropagation();
                handleAddTPSL && handleAddTPSL();
              }}
            >
              <img src={addTpIcon} alt="icon" />
              <p>
                <span>Add TP/SL</span>
              </p>
            </div>
          </Menu.Item>
          <Menu.Item>
            <div
              className="menu-item"
              onClick={(e) => {
                e.stopPropagation();
                handlAddTrailingStop && handlAddTrailingStop();
              }}
            >
              <img src={addTrailingIcon} alt="icon" />
              <p>
                <span>Add Trailing Stop</span>
              </p>
            </div>
          </Menu.Item>
        </Menu.Items>
      </div>
    </Menu>
  );
}

export default PositionDropdown;
