import useSWR from "swr";
import { getServerUrl } from "config/backendV2";
import { getServerUrl as getServerUrlV1 } from "config/backend";
import { DEFAULT_CHAIN_ID } from "config/chains";
import { fetcher } from "lib/fetcher";

export type Result = Record<string, { value: string; type: string }>;

function useDailyVolumesStats(chainId: number = DEFAULT_CHAIN_ID, params) {
  const { data: response } = useSWR([getServerUrl(chainId, "/assets/daily-volumes"), params], {
    fetcher,
    refreshInterval: 20000,
  });

  return {
    data: response ? response.data : undefined,
    dataV1: null
  };
}

export default useDailyVolumesStats;
