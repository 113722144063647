import React, { memo } from "react";

const Icon2 = (props) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={props.class}
  >
    <path
      d="M21.0388 23.5121C22.7623 23.5121 24.1596 22.0912 24.1596 20.3384C24.1596 18.5857 22.7623 17.1648 21.0388 17.1648C19.3152 17.1648 17.918 18.5857 17.918 20.3384C17.918 22.0912 19.3152 23.5121 21.0388 23.5121Z"
      fill="#296eff"
    />
    <path
      d="M3.28637 30.9185H4.32665V33.0342C4.32665 34.7842 5.72654 36.2078 7.44741 36.2078H34.6328C36.3537 36.2078 37.7536 34.7842 37.7536 33.0342V7.64484C37.7536 5.8948 36.3537 4.47119 34.6328 4.47119H7.44741C5.72654 4.47119 4.32665 5.8948 4.32665 7.64484V9.76057H3.28637C2.71133 9.76057 2.24609 10.2338 2.24609 10.8185V17.1659C2.24609 17.7506 2.71133 18.2237 3.28637 18.2237H4.32665V22.4553H3.28637C2.71133 22.4553 2.24609 22.9285 2.24609 23.5132V29.8605C2.24609 30.4452 2.71133 30.9185 3.28637 30.9185ZM31.4427 18.2237C31.4427 17.639 31.908 17.1659 32.4829 17.1659C33.0579 17.1659 33.5232 17.639 33.5232 18.2237V22.4553C33.5232 23.04 33.0579 23.5132 32.4829 23.5132C31.908 23.5132 31.4427 23.04 31.4427 22.4553V18.2237ZM15.8388 20.3395C15.8388 19.2633 16.1601 18.2635 16.7054 17.4272L11.9825 12.6243C11.5761 12.2111 11.5761 11.5416 11.9825 11.1284C12.3888 10.7152 13.0472 10.7152 13.4535 11.1284L18.1763 15.9313C18.9987 15.3769 19.9818 15.05 21.0401 15.05C22.0984 15.05 23.0815 15.3769 23.9039 15.9313L28.6267 11.1284C29.033 10.7152 29.6913 10.7152 30.0977 11.1284C30.504 11.5416 30.504 12.2111 30.0977 12.6243L25.3749 17.4272C25.92 18.2635 26.2413 19.2633 26.2413 20.3395C26.2413 21.4158 25.92 22.4155 25.3749 23.2518L30.0977 28.0547C30.504 28.4679 30.504 29.1375 30.0977 29.5506C29.8945 29.7573 29.6283 29.8606 29.3623 29.8606C29.0961 29.8606 28.8299 29.7573 28.6267 29.5506L23.9039 24.7477C23.0815 25.3021 22.0984 25.629 21.0401 25.629C19.9818 25.629 18.9987 25.3021 18.1763 24.7477L13.4535 29.5506C13.2503 29.7573 12.9841 29.8606 12.718 29.8606C12.4519 29.8606 12.1857 29.7573 11.9826 29.5506C11.5762 29.1375 11.5762 28.4679 11.9826 28.0547L16.7054 23.2518C16.1601 22.4155 15.8388 21.4158 15.8388 20.3395Z"
      fill={props.fill ?? "white"}
    />
  </svg>
);
export default memo(Icon2);
