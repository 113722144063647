import "./Referrals.css";
import React, { useRef, useState } from "react";
import { useCopyToClipboard, useLocalStorage } from "react-use";
import { Trans, t } from "@lingui/macro";
import { useWeb3React } from "@web3-react/core";
import { useParams } from "react-router-dom";
import SEO from "components/Common/SEO";
import Tab from "components/Tab/Tab";
import Loader from "components/Common/Loader";
import Footer from "components/Footer/Footer";
import { getPageTitle, isHashZero } from "lib/legacy";
import {
  useReferralsData,
  registerReferralCode,
  useCodeOwner,
  useReferrerTier,
  useUserReferralCode,
  useUserReferralCodeV2,
} from "domain/referrals";
import JoinReferralCode, { ReferralCodeForm } from "components/Referrals/JoinReferralCode";
import AffiliatesStats from "components/Referrals/AffiliatesStats";
import TradersStats from "components/Referrals/TradersStats";
import AddAffiliateCode, { AffiliateCodeForm } from "components/Referrals/AddAffiliateCode";
import {
  deserializeSampleStats,
  getReferralCodeTradeUrl,
  getTierIdDisplay,
  getUSDValue,
  isRecentReferralCodeNotExpired,
  tierDiscountInfo,
} from "components/Referrals/referralsHelper";
import { BigNumber, ethers } from "ethers";
import { useLocalStorageSerializeKey } from "lib/localStorage";
import { REFERRALS_SELECTED_TAB_KEY } from "config/localStorage";
import { useChainId } from "lib/chains";
import ExternalLink from "components/ExternalLink/ExternalLink";

import header_ref from "img/parrten.svg";
import ref_header_cash from "img/ref_header_cash.svg";
import icCoppy from "img/ic_copy.svg";
import icTrader from "img/as_trader.svg";
import icRef from "img/as_ref.svg";
import icTraderLight from "img/as_trader_light.svg";
import icRefLight from "img/as_referrer_light.svg";
import icEdit from "img/ic_edit.svg";
import "./Referrals.scss";
import PayoutTab from "./PayoutTab";
import MyRefCodes from "./MyRefCodes";
import Modal from "components/Modal/Modal";
import { helperToast } from "lib/helperToast";
import { parseValue } from "lib/numbers";
import EditIcon from "components/Icon/EditIcon";
import useUserStats from "hooks/useUserStats";
import { FANTOM, SUPPORTED_V2_CHAINS } from "config/chains";
import { useThemeContext } from "contexts/ThemeProvider";

const TRADERS = "Traders";
const AFFILIATES = "Affiliates";
const TAB_OPTIONS = [TRADERS, AFFILIATES];
const PAYOUT_TABS = ["Payout activities", "My referral codes"];
function Referrals({ connectWallet, setPendingTxns, pendingTxns }) {
  const { active, account: walletAccount, library } = useWeb3React();
  const { account: queryAccount } = useParams();
  let account;
  if (queryAccount && ethers.utils.isAddress(queryAccount)) {
    account = ethers.utils.getAddress(queryAccount);
  } else {
    account = walletAccount;
  }
  const theme = useThemeContext();
  const [, copyToClipboard] = useCopyToClipboard();
  const [currentPayoutTab, setCurrentPayoutTab] = useState(PAYOUT_TABS[0]);
  const { chainId } = useChainId();
  const [activeTab, setActiveTab] = useLocalStorage(REFERRALS_SELECTED_TAB_KEY, TRADERS);
  const { data: referralsData, loading } = useReferralsData(chainId, account);
  // console.log("????", referralsData);
  const [recentlyAddedCodes, setRecentlyAddedCodes] = useLocalStorageSerializeKey([chainId, "REFERRAL", account], [], {
    deserializer: deserializeSampleStats,
  });
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const editModalRef = useRef(null);

  const open = () => setIsEditModalOpen(true);
  const close = () => setIsEditModalOpen(false);

  const { userReferralCode, userReferralCodeString } = useUserReferralCode(library, chainId, account);
  const { codeOwner } = useCodeOwner(library, chainId, account, userReferralCode);
  const { referrerTier: traderTier } = useReferrerTier(library, chainId, codeOwner);

  function handleCreateReferralCode(referralCode) {
    return registerReferralCode(chainId, referralCode, library, {
      sentMsg: `Referral code submitted!`,
      failMsg: `Referral code creation failed.`,
      pendingTxns,
    });
  }
  const { userReferralStats } = useUserReferralCodeV2(chainId, account);
  const getTotalReferrer = () => {
    let total = 0;
    if (userReferralStats?.totalReferrals && SUPPORTED_V2_CHAINS.includes(chainId))
      total += userReferralStats?.totalReferrals;
    if (referralsData?.cumulativeStats?.registeredReferralsCount)
      total += referralsData?.cumulativeStats?.registeredReferralsCount;
    return total;
  };
  const getTotalRefVolume = () => {
    let data = referralsData?.cumulativeStats?.volume || BigNumber.from(0);
    if (userReferralStats?.volume && SUPPORTED_V2_CHAINS.includes(chainId)) {
      data = data.add(parseValue(userReferralStats?.volume, 30));
    }
    return getUSDValue(data) || "$0.00";
  };
  const getTotalRefEarn = () => {
    let data = referralsData?.referrerRebates || BigNumber.from(0);
    if (userReferralStats?.rebate && SUPPORTED_V2_CHAINS.includes(chainId)) {
      data = data.add(parseValue(userReferralStats?.rebate, 30));
    }
    return getUSDValue(data, 3) || "$0.000";
  };
  const getTotalTraderVolume = () => {
    let data = referralsData?.referralTotalStats?.volume || BigNumber.from(0);
    if (stats?.volume) {
      data = data.add(stats.volume);
    }
    return getUSDValue(data) || "$0.00";
  };
  const stats = useUserStats(account);
  function renderAffiliatesTab() {
    const isReferralCodeAvailable =
      referralsData?.codes?.length > 0 || recentlyAddedCodes?.filter(isRecentReferralCodeNotExpired).length > 0;

    if (loading) return <div className="skeleton-box" style={{ height: "30px", marginTop: "20px" }} />;

    return (
      <div className="container-ref">
        <div className="content-ref">
          <AddAffiliateCode
            isReferralCodeAvailable={isReferralCodeAvailable}
            referralsData={referralsData}
            handleCreateReferralCode={handleCreateReferralCode}
            active={active}
            connectWallet={connectWallet}
            recentlyAddedCodes={recentlyAddedCodes}
            setRecentlyAddedCodes={setRecentlyAddedCodes}
          />
          <AffiliatesStats
            referralsData={referralsData}
            handleCreateReferralCode={handleCreateReferralCode}
            setRecentlyAddedCodes={setRecentlyAddedCodes}
            recentlyAddedCodes={recentlyAddedCodes}
            chainId={chainId}
          />
        </div>
      </div>
    );

    // if (loading) return <Loader />;
    // if (isReferralCodeAvailable) {
    //   return (
    //     <AffiliatesStats
    //       referralsData={referralsData}
    //       handleCreateReferralCode={handleCreateReferralCode}
    //       setRecentlyAddedCodes={setRecentlyAddedCodes}
    //       recentlyAddedCodes={recentlyAddedCodes}
    //       chainId={chainId}
    //     />
    //   );
    // } else {
    //   return (
    //     <AddAffiliateCode
    //       handleCreateReferralCode={handleCreateReferralCode}
    //       active={active}
    //       connectWallet={connectWallet}
    //       recentlyAddedCodes={recentlyAddedCodes}
    //       setRecentlyAddedCodes={setRecentlyAddedCodes}
    //     />
    //   );
    // }
  }

  function renderTradersTab() {
    if (loading) return <div className="skeleton-box" style={{ height: "30px", marginTop: "20px" }} />;

    return (
      <div className="container-ref">
        <div className="content-ref">
          <JoinReferralCode
            userReferralCodeString={userReferralCodeString}
            connectWallet={connectWallet}
            active={active}
            setPendingTxns={setPendingTxns}
            pendingTxns={pendingTxns}
          />
          <TradersStats
            userReferralCodeString={userReferralCodeString}
            chainId={chainId}
            referralsData={referralsData}
            setPendingTxns={setPendingTxns}
            pendingTxns={pendingTxns}
            traderTier={traderTier}
          />
        </div>
      </div>
    );
  }
  const TAB_OPTION_LABELS = { [TRADERS]: `Traders`, [AFFILIATES]: `Affiliates` };

  return (
    <SEO title={getPageTitle("Referrals")}>
      <Modal
        className="Connect-wallet-modal"
        isVisible={isEditModalOpen}
        setIsVisible={close}
        label={userReferralCodeString ? `Edit Referral Code` : "Enter Referral Code"}
        onAfterOpen={() => editModalRef.current?.focus()}
      >
        <div className="edit-referral-modal">
          <ReferralCodeForm
            userReferralCodeString={userReferralCodeString}
            setPendingTxns={setPendingTxns}
            pendingTxns={pendingTxns}
            type={userReferralCodeString ? "edit" : "join"}
            callAfterSuccess={() => setIsEditModalOpen(false)}
          />
        </div>
      </Modal>
      <Modal
        isVisible={isVisible}
        setIsVisible={() => setIsVisible(false)}
        label="Create Referral Code"
        allowContentTouchMove
      >
        <div className="card-action">
          <div className="card-action">
            {active ? (
              <AffiliateCodeForm
                handleCreateReferralCode={handleCreateReferralCode}
                recentlyAddedCodes={recentlyAddedCodes}
                setRecentlyAddedCodes={setRecentlyAddedCodes}
              />
            ) : (
              <button className="App-cta Exchange-swap-button default-btn" type="submit" onClick={connectWallet}>
                <span>Connect Wallet</span>
              </button>
            )}
          </div>
        </div>
      </Modal>
      <div className="default-container page-layout Referrals">
        <div className="top-bg-container">
          <div
            style={{
              backgroundImage: `url(${header_ref})`,
            }}
            className="section-title-block"
          >
            <div className="section-title-content">
              <div className="left">
                <div className="Page-title">
                  <span>Referrals</span>
                </div>
                <div className="Page-description">
                  <div>
                    Get fee discounts and earn rebates through the Mummy referral program.
                    <br />
                    For more information, please read the{" "}
                    <ExternalLink href="https://docs.mummy.finance/social-features/referrals-v2">
                      referral program details
                    </ExternalLink>
                    .
                  </div>
                </div>
                <div className="more-info-container">
                  <div className="earn">
                    Refer your friends and <span>earn rewards</span>
                  </div>
                  <div className="ref-code-container">
                    <div className="ref-content">
                      https://app.mummy.finance/?ref=
                      {referralsData?.codes && referralsData?.codes?.length > 0 ? referralsData.codes[0] : ""}
                      <div className="coppy-container">
                        <img
                          alt=""
                          src={icCoppy}
                          onClick={() => {
                            copyToClipboard(
                              getReferralCodeTradeUrl(
                                referralsData?.codes && referralsData?.codes?.length > 0 ? referralsData.codes[0] : ""
                              )
                            );
                            helperToast.success("Referral link copied to your clipboard");
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <img className="right" src={ref_header_cash} alt="ref_header_cash" />
            </div>
          </div>
        </div>

        {/* <div className="referral-tab-container">
          <div className="container">
            <Tab
              options={TAB_OPTIONS}
              optionLabels={TAB_OPTION_LABELS}
              option={activeTab}
              setOption={setActiveTab}
              onChange={setActiveTab}
            />
          </div>
        </div>
        {activeTab === AFFILIATES ? renderAffiliatesTab() : renderTradersTab()} */}
        <div className="ref-stats-container">
          <div className="ref-as-container">
            <div className="ref-as-content">
              <div className="title">
                <img alt="" src={theme?.isLightTheme ? icTraderLight : icTrader} /> As Trader
              </div>
              <div className="ref-as-line">
                <div className="line-title">Your Active Referral Code</div>
                {userReferralCodeString ? (
                  <div className="line-value">
                    {userReferralCodeString} <EditIcon onClick={open} />
                  </div>
                ) : (
                  <div className="line-value">No Code</div>
                )}
              </div>
              <div className="ref-as-line">
                <div className="line-title">Your Total Trading Volume</div>
                <div className="line-value">{getTotalTraderVolume()}</div>
              </div>
              <div className="ref-as-line">
                <div className="line-title">Trade V1 Discount</div>
                <div className="line-value">
                  {traderTier ? `${getTotalReferrer() > 0 ? tierDiscountInfo[traderTier] : "0"}%` : "0%"}{" "}
                  <span className="tier">{traderTier ? `(Tier ${getTierIdDisplay(getTotalReferrer() > 0 ? traderTier : null)})` : "(Tier 0)"}</span>
                </div>
              </div>
              {SUPPORTED_V2_CHAINS.includes(chainId) && (
                <div className="ref-as-line">
                  <div className="line-title">Trade V2 Discount</div>
                  <div className="line-value">0%</div>
                </div>
              )}

              <div className="ref-as-line">
                <div className="line-title">Total Earned</div>
                <div className="line-value green-value">
                  {referralsData?.referralTotalStats?.discountUsd
                    ? getUSDValue(referralsData?.referralTotalStats?.discountUsd, 3)
                    : "$0.000"}
                </div>
              </div>
              {active ? (
                <button className={userReferralCodeString ? "default-btn" : "border-btn"} onClick={open}>
                  {userReferralCodeString ? "Edit code" : "Enter referral code "}
                </button>
              ) : (
                <button className="App-cta Exchange-swap-button default-btn" type="submit" onClick={connectWallet}>
                  <span>Connect Wallet</span>
                </button>
              )}

              <div className="ref-desc">
                Enter a referral code to receive up to a 5% discount on trading fees. The discount rebates will be
                airdropped weekly to your wallet address.
              </div>
            </div>

            <div className="ref-as-content">
              <div className="title">
                <img alt="" src={theme?.isLightTheme ? icRefLight : icRef} /> As Referrer
              </div>
              <div className="ref-as-line">
                <div className="line-title">Total Traders Referred</div>
                <div className="line-value">{getTotalReferrer()}</div>
              </div>
              <div className="ref-as-line">
                <div className="line-title">Total Trading Volume</div>
                <div className="line-value">{getTotalRefVolume()}</div>
              </div>
              <div className="ref-as-line">
                <div className="line-title">Your V1 Fee Share</div>
                <div className="line-value">
                  {traderTier ? `${tierDiscountInfo[traderTier]}%` : "0%"}{" "}
                  <span className="tier">{traderTier ? `(Tier ${getTierIdDisplay(traderTier)})` : "(Tier 0)"}</span>
                </div>
              </div>
              {SUPPORTED_V2_CHAINS.includes(chainId) && (
                <div className="ref-as-line">
                  <div className="line-title">Your V2 Fee Share</div>
                  <div className="line-value">5%</div>
                </div>
              )}

              <div className="ref-as-line">
                <div className="line-title">Total Earned</div>
                <div className="line-value green-value">{getTotalRefEarn()}</div>
              </div>
              {active ? (
                <button className="border-btn" onClick={() => setIsVisible(true)}>
                  Create new code
                </button>
              ) : (
                <button className="App-cta Exchange-swap-button default-btn" type="submit" onClick={connectWallet}>
                  <span>Connect Wallet</span>
                </button>
              )}
            </div>
          </div>
          <div className="right-content-container">
            <div className="payout-container">
              <div className="payout-tab-container">
                {PAYOUT_TABS.map((item) => (
                  <div
                    key={item}
                    onClick={() => setCurrentPayoutTab(item)}
                    className={`payout-tab ${item === currentPayoutTab ? "payout-tab-active" : ""}`}
                  >
                    {item}
                  </div>
                ))}
              </div>
              {active && currentPayoutTab === PAYOUT_TABS[1] && (
                <button className="default-btn" onClick={() => setIsVisible(true)}>
                  Create Code
                </button>
              )}
            </div>
            <div className="main-content-container">
              {currentPayoutTab === PAYOUT_TABS[0] ? (
                <PayoutTab />
              ) : (
                <MyRefCodes
                  userReferralStats={userReferralStats}
                  referrerTotalStats={referralsData?.referrerTotalStats}
                  firstCode={referralsData?.codes?.[0]}
                  recentlyAddedCodes={recentlyAddedCodes}
                  chainId={chainId}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </SEO>
  );
}

export default Referrals;
