import { ethers } from "ethers";

import { getContract } from "config/contracts";

import PoolRewarder from "abis/PoolRewarder.json";

import Multicall from "abis/Multicall.json";
import multicall from "domain/multicall";
import { useEffect, useState } from "react";
import { getProvider } from "lib/rpc";
import { formatMulticallReponse } from "lib/contracts";

export default function usePoolRewardersVMMY(
  chainId: number,
  active: boolean,
  account: string | undefined | null,
  library
): any {
  const [data, setData] = useState({});
  const poolRewardersVMMYAddress = getContract(chainId, "PoolRewardersVMMY");
  const multicallAddress = getContract(chainId, "Multicall");

  const _pid = 0;

  useEffect(() => {
    const fetchNoAccount = async () => {
      if (!account) {
        const provider = getProvider(undefined, chainId);
        const multicallContract = new ethers.Contract(multicallAddress, Multicall.abi, provider);
        const calls = [
          {
            address: poolRewardersVMMYAddress,
            name: "poolInfo",
            params: [_pid],
          },
        ];

        const keyOfCalls = calls.map((call) => call.name);

        const response = await multicall(
          multicallContract,
          PoolRewarder.abi,
          calls.map(({ address, name, params }) => ({
            address,
            name,
            params,
          }))
        );
        const result = {};
        for (const index in keyOfCalls) {
          result[keyOfCalls[index]] = response[index];
        }
        // setData((prev) => ({ ...prev, ...result }));
        setData((prev) => formatMulticallReponse({ ...prev, ...result }));
      }
    };
    const fetch = async () => {
      try {
        if (account) {
          const provider = getProvider(undefined, chainId);
          const multicallContract = new ethers.Contract(multicallAddress, Multicall.abi, provider);
          const calls = [
            {
              address: poolRewardersVMMYAddress,
              name: "poolInfo",
              params: [_pid],
            },
          ];

          const keyOfCalls = calls.map((call) => call.name);

          const response = await multicall(
            multicallContract,
            PoolRewarder.abi,
            calls.map(({ address, name, params }) => ({
              address,
              name,
              params,
            }))
          );
          const result = {};
          for (const index in keyOfCalls) {
            result[keyOfCalls[index]] = response[index];
          }
          setData((prev) => formatMulticallReponse({ ...prev, ...result }));
          // setData((prev) => ({ ...prev, ...result }));
        }
      } catch (error) {
        console.error("[ERROR]: usePoolRewarderVMMY", error);
      }
    };

    fetchNoAccount();
    fetch();
    const timerId = setInterval(() => {
      fetchNoAccount();
      fetch();
    }, 10000);

    return () => {
      clearInterval(timerId);
    };
  }, [account, chainId, multicallAddress, poolRewardersVMMYAddress]);

  return {
    poolInfo: data["poolInfo"],
  };
}
