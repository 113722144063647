import { BigNumber } from "ethers";
import { secondsToDuration } from "lib/dates";
import { getLiqRisk } from "lib/legacy";
import { isNegative, limitDecimals, parseValue } from "lib/numbers";
import moment from "moment";

export const getUnixDate = (str) => +moment.utc(str).toDate() / 1000;
export const getUnixDateHour = (str) => moment.utc(str).format("HH:mm");

export const formatClosedPosition = (rawPosition, assets) => {
  const { totalSize, totalCollateral, averagePrice, tokenId, closedAt, createdAt, totalROI } = rawPosition;
  const { tokenSymbol, symbol, decimals: tokenDisplayDecimal } = assets?.[tokenId] || {};
  const leverage = BigNumber.from(totalSize || 0)
    .mul(parseValue("1", 30))
    .div(BigNumber.from(totalCollateral || 0));
  const quantity = BigNumber.from(totalSize || 0)
    .mul(parseValue("1", 30))
    .div(BigNumber.from(averagePrice || 0));
  const duration = secondsToDuration(closedAt - createdAt);
  const roiPrefix = isNegative(totalROI) ? "-" : "+";
  const roiText = roiPrefix + limitDecimals(Number(totalROI) / 1000, 2).replace("-", "") + "%";

  return {
    ...rawPosition,
    leverage,
    quantity,
    tokenSymbol,
    symbol,
    tokenDisplayDecimal,
    duration,
    roiText,
  };
};

export const formatOpenPosition = (rawPosition, assets, marketTokensInfo) => {
  const {
    tokenId,
    leverage: rawLeverage,
    isLong,
    size,
    averagePrice,
    accruedFundingFee,
    accruedBorrowFee,
    collateral: rawCollateral,
    pendingPnl,
  } = rawPosition;
  const { tokenSymbol, symbol, decimals: tokenDisplayDecimal } = assets?.[tokenId] || {};
  const leverage = parseValue(rawLeverage, 30);
  const _accruedFundingFee = parseValue(accruedFundingFee, 30);
  const _accruedBorrowFee = parseValue(accruedBorrowFee, 30);
  const pnlWithoutFee = parseValue(pendingPnl, 30);

  const currentMarket = marketTokensInfo?.find((x) => x.id === tokenId);
  const markPrice = BigNumber.from((isLong ? currentMarket?.askPrice : currentMarket?.bidPrice) || 0);

  // const pnlWithoutFee = isLong
  //   ? markPrice.sub(parseValue(averagePrice, 30)).mul(parseValue(size, 30)).div(parseValue(averagePrice, 30))
  //   : parseValue(averagePrice, 30).sub(markPrice).mul(parseValue(size, 30)).div(parseValue(averagePrice, 30));

  // const accruedFees =
  //   parseValue(accruedPositionFee, 30).add(parseValue(accruedFundingFee, 30)).add(parseValue(accruedBorrowFee, 30)) ||
  //   BigNumber.from(0);

  // const pnlWithFee = pnlWithoutFee.sub(accruedFees);
  const pnlAfterFee = pnlWithoutFee.sub(_accruedFundingFee).sub(_accruedBorrowFee);
  const percentPnl = pnlAfterFee.mul(parseValue(100, 30)).div(parseValue(rawCollateral, 30));
  const prefix = pnlAfterFee?.gt(0) ? "+" : pnlAfterFee?.lt(0) ? "-" : "";
  const netValue = pnlAfterFee.add(parseValue(rawCollateral, 30));

  const collateral = parseValue(rawCollateral, 30);
  const posQty = parseValue(size, 30).mul(parseValue(1, 30)).div(parseValue(averagePrice, 30));
  const LiqRisk = getLiqRisk(pnlAfterFee, collateral);

  return {
    ...rawPosition,
    tokenSymbol,
    symbol,
    tokenDisplayDecimal,
    leverage,
    pnlWithoutFee,
    pnlAfterFee,
    percentPnl,
    prefix,
    netValue,
    currentMarket,
    posQty,
    markPrice,
    LiqRisk,
    accruedFundingFee: _accruedFundingFee,
    accruedBorrowFee: _accruedBorrowFee,
  };
};

export const formatOrder = (rawOrder, assets, marketTokensInfo) => {
  const { tokenId, leverage: rawLeverage } = rawOrder;
  const { tokenSymbol, symbol, decimals: tokenDisplayDecimal } = assets?.[tokenId] || {};
  const leverage = rawLeverage !== null ? parseValue(rawLeverage, 30) : undefined;
  const currentMarket = marketTokensInfo?.find((x) => x.id === tokenId);

  return {
    ...rawOrder,
    tokenSymbol,
    symbol,
    tokenDisplayDecimal,
    leverage,
    currentMarket,
  };
};

export const formatHistory = (rawHistory, assets) => {
  const { tokenId, collateral, tradeVolume, averagePrice } = rawHistory;
  const { tokenSymbol, symbol, decimals: tokenDisplayDecimal } = assets?.[tokenId] || {};
  const leverage = BigNumber.from(tradeVolume || 0)
    .mul(parseValue("1", 30))
    .div(BigNumber.from(collateral || 0));

  const posQty = parseValue(tradeVolume, 30).mul(parseValue(1, 30)).div(parseValue(averagePrice, 30));

  return {
    ...rawHistory,
    tokenSymbol,
    symbol,
    tokenDisplayDecimal,
    leverage,
    posQty,
  };
};
