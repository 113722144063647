import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { useWeb3React } from "@web3-react/core";
import { Sparklines, SparklinesLine } from "react-sparklines";
import { ethers } from "ethers";

import { getTokenBySymbol, getWhitelistedTokens } from "config/tokens";
import { getServerBaseUrl } from "config/backend";
import { formatAmount, formatNumber, numberWithCommas, parseValue } from "lib/numbers";
import { getContract } from "config/contracts";
import { LONG, SHORT, SWAP, USD_DECIMALS, importImage } from "lib/legacy";
import { getTokenInfo, getTokenSymbolFromString, useInfoTokens } from "domain/tokens";

import { getTokenChartPrice } from "domain/tradingview/requests";
import { useLocalStorageByChainId, useLocalStorageSerializeKey } from "lib/localStorage";
import { getConstant } from "config/chains";
import { getSkeletonClassName } from "utils/skeletonHelper";
import TooltipComponent from "components/Tooltip/Tooltip";
import useMarketAssets from "hooks/useMarketAssets";
import formatPrice from "utils/formatPrice";
import { useThemeContext } from "contexts/ThemeProvider";

const Markets = ({ chainId, setTradeVersion }) => {
  const { lightThemeClassName, isLightTheme } = useThemeContext();
  const { AddressZero } = ethers.constants;
  const { active, library } = useWeb3React();
  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");

  const whitelistedTokens = getWhitelistedTokens(chainId);
  const marketTokens = whitelistedTokens.filter((token) => !token.isStable && !token.isWrapped);

  const { infoTokens } = useInfoTokens(library, chainId, active, undefined, undefined);

  const [marketTokensInfo, setMarketTokenInfo] = useState([]);

  const defaultCollateralSymbol = getConstant(chainId, "defaultCollateralSymbol");
  const [, setDefaultChartToken] = useLocalStorageSerializeKey("default-chart-token", "BTC/USD");

  const defaultTokenSelection = useMemo(
    () => ({
      [SWAP]: {
        from: AddressZero,
        to: getTokenBySymbol(chainId, defaultCollateralSymbol).address,
      },
      [LONG]: {
        from: AddressZero,
        to: AddressZero,
      },
      [SHORT]: {
        from: getTokenBySymbol(chainId, defaultCollateralSymbol).address,
        to: AddressZero,
      },
    }),
    [chainId, defaultCollateralSymbol]
  );

  const [tokenSelection, setTokenSelection] = useLocalStorageByChainId(
    chainId,
    "Exchange-token-selection-v3",
    defaultTokenSelection
  );

  const setToTokenAddress = useCallback(
    (selectedSwapOption, address) => {
      const newTokenSelection = JSON.parse(JSON.stringify(tokenSelection));
      newTokenSelection[selectedSwapOption].to = address;
      if (selectedSwapOption === LONG || selectedSwapOption === SHORT) {
        newTokenSelection[LONG].to = address;
        newTokenSelection[SHORT].to = address;
      }
      setTokenSelection(newTokenSelection);
    },
    [tokenSelection, setTokenSelection]
  );

  useEffect(() => {
    const handleData = async () => {
      const marketTokensPrice = await Promise.all(
        marketTokens.map(async (token) => {
          const tokenInfo = getTokenInfo(infoTokens, token.address);
          const priceData = await getTokenChartPrice(chainId, token.symbol, "1h");

          const LIMIT = 30;
          let close;
          let high;
          let low;
          let deltaPrice;
          let delta;
          let deltaPercentage;
          let deltaPercentageStr;

          const currentAveragePrice =
            tokenInfo.maxPrice && tokenInfo.minPrice ? tokenInfo.maxPrice.add(tokenInfo.minPrice).div(2) : null;

          const now = parseInt(Date.now() / 1000);
          const timeThreshold = now - 24 * 60 * 60;

          if (priceData) {
            for (let i = priceData.length - 1; i > 0; i--) {
              const price = priceData[i];
              if (price.time < timeThreshold) {
                break;
              }
              if (!low) {
                low = price.low;
              }
              if (!high) {
                high = price.high;
              }

              if (price.high > high) {
                high = price.high;
              }
              if (price.low < low) {
                low = price.low;
              }

              deltaPrice = price.open;
            }

            close = priceData.slice(-LIMIT).map((_) => _.close);
          }

          if (deltaPrice && currentAveragePrice) {
            const average = parseFloat(formatAmount(currentAveragePrice, USD_DECIMALS, 2));
            delta = average - deltaPrice;
            deltaPercentage = (delta * 100) / average;
            if (deltaPercentage > 0) {
              deltaPercentageStr = `+${deltaPercentage.toFixed(2)}%`;
            } else {
              deltaPercentageStr = `${deltaPercentage.toFixed(2)}%`;
            }
            if (deltaPercentage === 0) {
              deltaPercentageStr = "0.00";
            }
          }

          return {
            symbol: tokenInfo.symbol,
            displayDecimals: tokenInfo.displayDecimals,
            address: tokenInfo.address,
            lastPrice: tokenInfo?.maxPrice,
            dailyChange: deltaPercentageStr,
            dailyHigh: high,
            close,
          };
        })
      );

      const { data: marketTokensStat } = await axios.get(`${getServerBaseUrl(chainId)}/token-stats`);

      const handledMarketTokens = marketTokensPrice.map((token) => ({
        ...token,
        ...marketTokensStat[
          token.address.toLowerCase() === AddressZero ? nativeTokenAddress.toLowerCase() : token.address.toLowerCase()
        ],
      }));

      setMarketTokenInfo(handledMarketTokens);
    };

    handleData();
  }, [chainId, JSON.stringify(infoTokens)]);

  const navigation = (token, isV2) => {
    if (isV2) {
      setDefaultChartToken(token.symbol);
      // setTradeVersion("V2");
      window.location.href = "/#/trade";
    } else {
      setToTokenAddress(LONG, token.address);
      // setTradeVersion("V1");
      window.location.href = "/#/v1";
    }
  };

  const marketV2 = useMarketAssets();

  return (
    <Wrapper className={`Market-table-wrapper ${lightThemeClassName}`}>
      <Title>
        <span>V1 Markets</span>
      </Title>
      {marketTokensInfo && marketTokensInfo.length > 0 ? (
        <>
          <MarketTableContainer>
            <TokensTable>
              <thead>
                <tr>
                  <th>
                    <span>Pair</span>
                  </th>
                  <th>
                    <span>Last Price</span>
                  </th>
                  <th>
                    <span>24h Change (%)</span>
                  </th>
                  <th>
                    <span>24h High</span>
                  </th>
                  <th>
                    <TooltipComponent
                      position="left-bottom"
                      handle={`24h Volume`}
                      renderContent={() => (
                        <div style={{ fontWeight: 400 }} className="custom">
                          The 24H volume represents the total trading volume within the past 24 hours.
                        </div>
                      )}
                    />
                  </th>
                  <th>
                    <span>Open Interest</span>
                  </th>
                  <th>
                    <span>Chart</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {marketTokensInfo?.map((token) => {
                  const tokenImage = importImage("ic_" + token.symbol.toLowerCase() + "_40.svg");
                  return (
                    <tr key={token.symbol} onClick={() => navigation(token)}>
                      <td>
                        <TokenInfo>
                          <img src={tokenImage} alt={token.symbol} width="36px" />
                          <TokenSymbol>{token.symbol}/USD</TokenSymbol>
                        </TokenInfo>
                      </td>
                      <td>
                        <div className={`${getSkeletonClassName(token.lastPrice)}`}>
                          ${formatAmount(token.lastPrice, USD_DECIMALS, token.displayDecimals || 2, true)}
                        </div>
                      </td>
                      <td className={token?.dailyChange?.indexOf("-") !== -1 ? "negative" : "positive"}>
                        <div className={`${getSkeletonClassName(token.dailyChange)}`}>{token.dailyChange}</div>
                      </td>
                      <td>
                        <div className={`${getSkeletonClassName(token.dailyHigh)}`}>
                          $
                          {token?.dailyHigh
                            ? numberWithCommas(token.dailyHigh.toFixed(token.displayDecimals || 2))
                            : ".."}
                        </div>
                      </td>
                      <td>
                        <div className={`${getSkeletonClassName(token.volume24H)}`}>
                          ${formatAmount(token.volume24H, USD_DECIMALS, 2, true)}
                        </div>
                      </td>
                      <td>
                        <div className={`${getSkeletonClassName(token.openInterest)}`}>
                          ${formatAmount(token.openInterest, USD_DECIMALS, 2, true)}
                        </div>
                      </td>
                      <td className="sparklines-wrapper">
                        <div className={`${getSkeletonClassName(token.dailyChange)}`}>
                          <Sparklines width={80} height={37.5} data={token.close} limit={15}>
                            {isLightTheme ? (
                              <SparklinesLine color={token?.dailyChange?.indexOf("-") !== -1 ? "#f6475d" : "#02B27F"} />
                            ) : (
                              <SparklinesLine color={token?.dailyChange?.indexOf("-") !== -1 ? "#FF2D44" : "#34f5ae"} />
                            )}
                          </Sparklines>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </TokensTable>
          </MarketTableContainer>
          <MarketGridMobile>
            {marketTokensInfo.map((token) => {
              const tokenImage = importImage("ic_" + token.symbol.toLowerCase() + "_24.svg");
              return (
                <TokenCard className={lightThemeClassName} key={token.symbol} onClick={() => navigation(token)}>
                  <CardRow>
                    <div className="label">
                      <span>Pair</span>
                    </div>
                    <div className="mobile-token-card">
                      <img src={tokenImage} alt={token.symbol} width="24px" />
                      <div className="token-symbol-text">{token.symbol}/USD</div>
                    </div>
                  </CardRow>
                  <CardRow>
                    <div className="label">
                      <span>Last Price</span>
                    </div>
                    <div>
                      $
                      {token.lastPrice
                        ? formatAmount(token.lastPrice, USD_DECIMALS, token.displayDecimals || 2, true)
                        : "--"}
                    </div>
                  </CardRow>
                  <CardRow>
                    <div className="label">
                      <span>24h Change (%)</span>
                    </div>
                    <div className={token?.dailyChange?.indexOf("-") !== -1 ? "negative" : "positive"}>
                      {token.dailyChange}
                    </div>
                  </CardRow>
                  <CardRow>
                    <div className="label">
                      <span>24h High</span>
                    </div>
                    <div>${token.dailyHigh ? Number(token.dailyHigh).toFixed(3) : "--"}</div>
                  </CardRow>
                  <CardRow>
                    <div
                      className="label"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      <TooltipComponent
                        position="left-bottom"
                        handle={`24h Volume`}
                        renderContent={() => (
                          <div style={{ fontWeight: 400 }} className="custom">
                            The 24H volume represents the total trading volume within the past 24 hours.
                          </div>
                        )}
                      />
                    </div>
                    <div>
                      $
                      {token.volume24H
                        ? formatAmount(token.volume24H, USD_DECIMALS, token.displayDecimals || 2, true)
                        : "--"}
                    </div>
                  </CardRow>
                  <CardRow>
                    <div className="label">
                      <span>Open Interest</span>
                    </div>
                    <div>
                      $
                      {token.openInterest
                        ? formatAmount(token.openInterest, USD_DECIMALS, token.displayDecimals || 2, true)
                        : "--"}
                    </div>
                  </CardRow>
                  <CardRow>
                    <div className="label">
                      <span>Chart</span>
                    </div>
                    <div className="chart-container">
                      <Sparklines width={80} height={37.5} data={token.close} limit={15}>
                        {isLightTheme ? (
                          <SparklinesLine color={token?.dailyChange?.indexOf("-") !== -1 ? "#f6475d" : "#02B27F"} />
                        ) : (
                          <SparklinesLine color={token?.dailyChange?.indexOf("-") !== -1 ? "#FF2D44" : "#34f5ae"} />
                        )}
                      </Sparklines>
                    </div>
                  </CardRow>
                </TokenCard>
              );
            })}
          </MarketGridMobile>
        </>
      ) : (
        <div className="skeleton-box"></div>
      )}

      <Title
        style={{
          marginTop: 64,
        }}
        className="mobile-title"
      >
        <span>V2 Markets</span>
      </Title>
      {Object.entries(marketV2 || {}).length > 0 ? (
        <>
          <MarketTableContainer>
            <TokensTable>
              <thead>
                <tr>
                  <th>
                    <span>Pair</span>
                  </th>
                  <th>
                    <span>Last Price</span>
                  </th>
                  <th>
                    <span>24h Change (%)</span>
                  </th>
                  {/* <th>
                    <span>24h High</span>
                  </th> */}
                  <th>
                    <TooltipComponent
                      position="center-bottom"
                      handle={`24h Volume`}
                      renderContent={() => (
                        <div style={{ fontWeight: 400 }} className="custom">
                          The 24H volume represents the total trading volume within the past 24 hours.
                        </div>
                      )}
                    />
                  </th>
                  <th>
                    <span>Open Interest</span>
                  </th>
                  {/* <th>
                    <span>Chart</span>
                  </th> */}
                </tr>
              </thead>
              <tbody>
                {Object.entries(marketV2)?.map(([posId, token]) => {
                  const tokenImage = importImage(
                    "ic_" + getTokenSymbolFromString(token.symbol).toLowerCase() + "_40.svg"
                  );
                  return (
                    <tr key={posId} onClick={() => navigation(token, true)}>
                      <td>
                        <TokenInfo>
                          <img src={tokenImage} alt={token.symbol} width="36px" />
                          <TokenSymbol>{token.symbol}</TokenSymbol>
                        </TokenInfo>
                      </td>
                      <td>
                        <div className={`${getSkeletonClassName(token.latestPrice)}`}>
                          {token.latestPrice !== null &&
                            "$" + formatAmount(parseValue(token.latestPrice, 30), 30, token?.decimals || 2, true)}
                        </div>
                      </td>
                      <td className={token?.price24HChanged < 0 ? "negative" : "positive"}>
                        <div className={`${getSkeletonClassName(token.price24HChanged)}`}>
                          {token?.latestPrice * token?.price24HChanged < 0 ? "-" : "+"}$
                          {formatPrice((token?.latestPrice * token?.price24HChanged) / 100).replace("-", "")} (
                          {token?.price24HChanged < 0 ? "" : "+"}
                          {formatAmount(parseValue(token.price24HChanged, 30), 30, 2, true)}%)
                        </div>
                      </td>
                      {/* <td>
                        <div className={`${getSkeletonClassName(token.dailyHigh)}`}>
                          $
                          {token?.dailyHigh
                            ? numberWithCommas(token.dailyHigh.toFixed(token.displayDecimals || 2))
                            : ".."}
                        </div>
                      </td> */}
                      <td>
                        <div className={`${getSkeletonClassName(token.volume24h !== undefined)}`}>
                          ${formatNumber(token.volume24h, 2, true)}
                        </div>
                      </td>
                      <td>
                        <div className={`${getSkeletonClassName(token.openInterest)}`}>
                          ${formatNumber(+token?.openInterest?.total, 2)}
                        </div>
                      </td>
                      {/* <td className="sparklines-wrapper">
                        <div className={`${getSkeletonClassName(token.dailyChange)}`}>
                          <Sparklines width={80} height={37.5} data={token.close} limit={15}>
                            <SparklinesLine color={token?.dailyChange?.indexOf("-") !== -1 ? "#FF2D44" : "#34f5ae"} />
                          </Sparklines>
                        </div>
                      </td> */}
                    </tr>
                  );
                })}
              </tbody>
            </TokensTable>
          </MarketTableContainer>
          <MarketGridMobile>
            {Object.entries(marketV2)?.map(([posId, token]) => {
              const tokenImage = importImage("ic_" + getTokenSymbolFromString(token.symbol).toLowerCase() + "_40.svg");
              return (
                <TokenCard className={lightThemeClassName} key={posId} onClick={() => navigation(token, true)}>
                  <CardRow>
                    <div className="label">
                      <span>Pair</span>
                    </div>
                    <div className="mobile-token-card">
                      <img src={tokenImage} alt={token.symbol} width="24px" />
                      <div className="token-symbol-text">{token.symbol}</div>
                    </div>
                  </CardRow>
                  <CardRow>
                    <div className="label">
                      <span>Last Price</span>
                    </div>
                    <div>
                      {token.latestPrice !== null && "$" + formatAmount(parseValue(token.latestPrice, 30), 30, 2, true)}
                    </div>
                  </CardRow>
                  <CardRow>
                    <div className="label">
                      <span>24h Change (%)</span>
                    </div>
                    <div className={token?.dailyChange?.indexOf("-") !== -1 ? "negative" : "positive"}>
                      {token?.latestPrice * token?.price24HChanged < 0 ? "-" : "+"}$
                      {formatPrice((token?.latestPrice * token?.price24HChanged) / 100).replace("-", "")} (
                      {token?.price24HChanged < 0 ? "" : "+"}
                      {formatAmount(parseValue(token.price24HChanged, 30), 30, 2, true)}%)
                    </div>
                  </CardRow>
                  {/* <CardRow>
                    <div className="label">
                      <span>24h High</span>
                    </div>
                    <div>${token.dailyHigh ? Number(token.dailyHigh).toFixed(3) : "--"}</div>
                  </CardRow> */}
                  <CardRow>
                    <div
                      className="label"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      <TooltipComponent
                        position="left-bottom"
                        handle={`24h Volume`}
                        renderContent={() => (
                          <div style={{ fontWeight: 400 }} className="custom">
                            The 24H volume represents the total trading volume within the past 24 hours.
                          </div>
                        )}
                      />
                    </div>
                    <div>${formatNumber(token.volume24h, 2, true)}</div>
                  </CardRow>
                  <CardRow>
                    <div className="label">
                      <span>Open Interest</span>
                    </div>
                    <div>${formatNumber(+token?.openInterest?.total, 2)}</div>
                  </CardRow>
                  {/* <CardRow>
                    <div className="label">
                      <span>Chart</span>
                    </div>
                    <div className="chart-container">
                      <Sparklines width={80} height={37.5} data={token.close} limit={15}>
                        <SparklinesLine color={token?.dailyChange?.indexOf("-") !== -1 ? "#FF2D44" : "#34f5ae"} />
                      </Sparklines>
                    </div>
                  </CardRow> */}
                </TokenCard>
              );
            })}
          </MarketGridMobile>
        </>
      ) : (
        <div className="skeleton-box"></div>
      )}
    </Wrapper>
  );
};

const TokenSymbol = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
`;

const TokenInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const CardRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .mobile-token-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;

    .token-symbol-text {
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;
    }
  }

  .chart-container {
    width: 80px;
    height: 37.5px;
  }
`;

const TokenCard = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: #191b2e;
  border-radius: 12px;

  &.theme--light {
    background-color: #fff;
    border: 1px solid var(--Border, rgba(13, 26, 22, 0.07));

    .label span {
      color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7));
    }
  }
`;

const MarketGridMobile = styled.div`
  display: none;
  @media (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 8px;
    grid-template-columns: repeat(1, 1fr) !important;
  }
`;

const TokensTable = styled.table`
  width: 100%;
  border-spacing: 0;
  white-space: nowrap;

  border-collapse: separate;
  border-spacing: 0 8px;
  width: 100%;
  thead {
    th {
      padding-top: 0;
      padding-bottom: 0;
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 140% !important;
      text-transform: none;
      text-align: left;
      color: #ffffffb2;
      padding-left: 16px;
      max-height: 37.5px;
    }

    th:not(:last-child) {
      width: calc((100% - 80px) / 6);
    }

    th:last-child {
      width: 80px;
    }
  }

  tbody {
    tr {
      cursor: pointer;
      td {
        background: #212333;
        padding: 8px 16px;

        &:first-child {
          border-top-left-radius: 12px;
          border-bottom-left-radius: 12px;
        }

        &:last-child {
          border-top-right-radius: 12px;
          border-bottom-right-radius: 12px;
        }

        .dropdown-arrow {
          position: relative;
          top: 2px;
        }

        &.sparklines-wrapper {
          svg {
            width: 80px;
            height: 37.5px;
          }
        }
      }
    }
  }
`;

const MarketTableContainer = styled.div`
  background: transparent !important;
  border: none !important;
  padding: 0 !important;

  @media (max-width: 1023px) {
    display: none;
  }
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  margin-bottom: 16px;

  &.mobile-title {
    @media screen and (max-width: 767px) {
      margin-top: 48px !important;
    }
  }
`;

const Wrapper = styled.div`
  .Tooltip-popup:has(.custom) {
    width: unset !important;
  }

  &.theme--light {
    table {
      thead {
        span {
          color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7));
        }
      }

      tbody {
        tr {
          box-shadow: 0 0 0 1px #0d1a1612;
          border-radius: 12px;

          td {
            background: var(--Nature-1, #fff);

            &.positive {
              color: var(--Primary, #02b27f) !important;
              * {
                color: var(--Primary, #02b27f) !important;
              }
            }

            &.negative {
              color: #f6475d !important;
              * {
                color: #f6475d !important;
              }
            }
          }
        }
      }
    }
  }
`;

export default Markets;
