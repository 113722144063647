import axios from "axios";
import { getContract } from "config/contracts";
import { BigNumber } from "ethers";
import { useEffect, useMemo, useState } from "react";

import { getServerBaseUrl } from "config/backend";
import { parseValue } from "lib/numbers";

function useInfoMslp(chainId) {
  const [totalSupplyBlp, setTotalSupplyBlp] = useState<BigNumber>();
  const [vaultUSDBalance, setVaultUSDBalance] = useState<BigNumber>();

  const urlApi = getServerBaseUrl(chainId);

  const getMSLPInfo = async () => {
    try {
      const response = await axios.get(`${urlApi}/mslp-info`);
      const { price, totalSupply } = response.data;
      setTotalSupplyBlp(BigNumber.from(totalSupply));
      setVaultUSDBalance(BigNumber.from(price));
    } catch (error) {
      console.error("Error fetching MSLP info:", error);
    }
  };

  useEffect(() => {
    getMSLPInfo();

    const interval = setInterval(getMSLPInfo, 30000);

    return () => clearInterval(interval);
  }, []);

  return {
    totalSupply: totalSupplyBlp,
    price: vaultUSDBalance,
  };
}

export default useInfoMslp;
