import React from "react";
import styled from "styled-components";
import ReactDatePicker from "react-datepicker";
import { useThemeContext } from "contexts/ThemeProvider";

const DatePicker = ({ label, defaultDate, ...props }) => {
  const { lightThemeClassName } = useThemeContext();
  return (
    <Wrapper className={lightThemeClassName}>
      {label && <span className="DatePicker-label">{label}</span>}
      <div className="DatePicker-wrapper">
        <ReactDatePicker {...props} />
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  .DatePicker-label {
    font-size: 14px;
    flex: 1;
  }

  input {
    width: 100px;
  }

  &.theme--light {
    input {
      color: #0d1a16b2;
    }
  }
`;

export default DatePicker;
