import { useMemo } from "react";

import { bigNumberify, convertSmallENumber, formatAmountFree, parseValue } from "lib/numbers";
import { SECONDS_PER_YEAR } from "lib/legacy";
import useUsdPrice from "./useUsdPrice";
import { FANTOM, OP } from "config/chains";
import { useChainId } from "lib/chains";

export const SYMBOL_BY_ADDRESS_REWARDS = {
  [FANTOM]: {
    "0xc25c7538cabc16a2916b304f086338fc4dcdc0c6": "vMMY",
    "0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83": "FTM",
  },
  [OP]: {
    "0xe208010f555fa94622e80e279fb1593d9c474a16": "vMMY",
    "0x4200000000000000000000000000000000000006": "ETH",
  },
}
;

const useMslpApr = ({ mslpPrice, totalStaked, rewardsPrice, poolRewardsPerSec }) => {
  const { chainId } = useChainId();

  const totalStakedUsd = useUsdPrice(totalStaked, mslpPrice, 30);

  const apr = useMemo(() => {
    if (totalStakedUsd && rewardsPrice) {
      const [rewardAddresses, rewardSymbols, RewardDecimals, rewardPerSecs] = poolRewardsPerSec;
      var rewardPerSecsT = rewardPerSecs
      if(chainId === OP && Date.now() < 1716904800000) {
        var rewardPerSecsTemp = [parseValue(convertSmallENumber(99369.2799/(365*24*60*60)),18), parseValue(convertSmallENumber(0.1/(1716904800 - 1716300000)),18)]
        rewardPerSecsT = rewardPerSecsTemp;
      }
      const _totalStakedInDollar = parseFloat(formatAmountFree(totalStakedUsd, Number(18), Number(18))) || 1;

      const result = {};
      
      const totalApr = rewardPerSecsT.reduce((acc, cur, index) => {
        const rewardPrice = rewardsPrice[rewardSymbols[index]]?.price;

        const _rewardPerSec = parseFloat(
          formatAmountFree(cur, Number(RewardDecimals[index]), Number(RewardDecimals[index]))
        );

        const apr = ((+rewardPrice * _rewardPerSec * SECONDS_PER_YEAR) / _totalStakedInDollar) * 100;

        result[rewardAddresses[index]] = apr;
        return acc + apr;
      }, 0);

      return { ...result, total: totalApr };
    }
  }, [chainId, poolRewardsPerSec, rewardsPrice, totalStakedUsd, totalStaked, mslpPrice]);

  return apr;
};

export default useMslpApr;
