import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";

import GlpSwap from "components/Glp/GlpSwap";
import Footer from "components/Footer/Footer";
import "./BuyGlp.css";

import { Trans } from "@lingui/macro";
import { getNativeToken } from "config/tokens";
import { useChainId } from "lib/chains";
import ExternalLink from "components/ExternalLink/ExternalLink";
import patternImg from "img/dashboard/pattern.svg";
import { useThemeContext } from "contexts/ThemeProvider";

export default function BuyGlp(props) {
  const { lightThemeClassName } = useThemeContext();
  const { chainId } = useChainId();
  const history = useHistory();
  const [isBuying, setIsBuying] = useState(true);
  const nativeTokenSymbol = getNativeToken(chainId).symbol;

  useEffect(() => {
    const hash = history.location.hash.replace("#", "");
    const buying = hash === "redeem" ? false : true;
    setIsBuying(buying);
  }, [history.location.hash]);

  return (
    <Wrapper className={lightThemeClassName}>
      <div className="default-container page-layout">
        <HeadingPage>
          <Title>
            <span>Buy / Sell MLP</span>
          </Title>
          <Description>
            <div>
              Purchase MLP tokens to earn {chainId === 250 ? "FTM" : "ETH"} fees from swaps and leveraged trading. View
              the <Link to="/earn-v2/mlp">staking page</Link> for more information.
            </div>
          </Description>
        </HeadingPage>
        <GlpSwap {...props} isBuying={isBuying} setIsBuying={setIsBuying} />
        <Footer />
      </div>
    </Wrapper>
  );
}

const Description = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #ffffff99;

  a {
    color: #ffffff99;
  }
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  margin-bottom: 12px;
`;

const HeadingPage = styled.div`
  margin-bottom: 24px;
`;

const Wrapper = styled.div`
  background-image: url(${patternImg});
  background-repeat: no-repeat;
  background-size: contain;

  @media (max-width: 1023px) {
    padding-top: 0;
  }

  @media (max-width: 767px) {
    .page-layout {
      padding-top: 40px;
    }
  }

  &.theme--light {
    background: linear-gradient(180deg, #f7fffd 0%, #eef1f7 100%);
  }
`;
