import { AggregatorParamType } from "hooks/useAggregator";

export type DataAggregatorType = {
  amount?: string;
  chainId?: number;
  deadline?: number;
  dexes?: string;
  excludedDexes?: string;
  feeAmounts?: number | null;
  feeIn?: boolean;
  feeReceivers?: number | null;
  from?: string;
  gasInclude?: string;
  gasPrice?: string;
  hash?: string;
  quoteData?: QuoteDataType;
  receiver?: string;
  ref?: string;
  saveGas?: string;
  slippage?: number;
  source?: string;
  to?: string;
};

export type QuoteDataType = {
  encodedData?: EncodedDataType;
  maxReturn?: MaxReturnDataType;
};

export type EncodedDataType = {
  data?: string;
  router?: string;
};

export type MaxReturnDataType = {
  from?: string;
  gasPrice?: string;
  paths?: Array<PathsDataType>;
  to?: string;
  tokens?: TokensDateType;
  totalFrom?: string;
  totalGas?: number;
  totalTo?: string;
};

export type PathsDataType = {
  amountFrom?: string;
  amountTo?: string;
  gas?: number;
  swaps?: Array<SwapDataType>;
};

export type SwapDataType = {
  amountFrom?: string;
  amountTo?: string;
  dex?: string;
  from?: string;
  pool?: string;
  to?: string;
};

export type TokensDateType = {
  [key: string]: TokenInfoType;
};

export type TokenInfoType = {
  address?: string;
  decimals?: number;
  name?: string;
  price?: number;
  symbol?: string;
};

export const InitDataAggregator: DataAggregatorType = {
  amount: "1000000000000000000",
  chainId: 42161,
  deadline: 1704360087,
  dexes:
    "arbdex,arbidexv3,balancer,camelot,chronos,curve,dodo,gmx,kyberelasticv2,level,mindgames,mmfinance,mummy,ramsesexchange,ramsescl,saddle,shekel,solidlizard,solunea,sterlingfi,sushiswap,swapfish,synapse,traderjoev2,traderjoev21,uniswapv3,wombat,woofiv2,zyberstable,zyberswap,zyberswapv3",
  excludedDexes: "hashflow",
  feeAmounts: null,
  feeIn: false,
  feeReceivers: null,
  from: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
  gasInclude: "1",
  gasPrice: "",
  hash: "tmMeqMQEWxk4ksDC6QuVvNGVgIytmP0YgsrGoNsriwY=",
  quoteData: {
    encodedData: {},
    maxReturn: {
      from: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
      gasPrice: "100000000",
      paths: [
        {
          amountFrom: "499750000000000000",
          amountTo: "1108898450",
          gas: 120000,
          swaps: [
            {
              amountFrom: "499750000000000000",
              amountTo: "1109053971",
              dex: "uniswapv3",
              from: "0x82af49447d8a07e3bd95bd0d56f35241523fbab1",
              pool: "0xe754841b77c874135caca3386676e886459c2d61",
              to: "0xff970a61a04b1ca14834a43f5de4533ebddb5cc8",
            },
            {
              amountFrom: "1109053971",
              amountTo: "1108898450",
              dex: "wombat",
              from: "0xff970a61a04b1ca14834a43f5de4533ebddb5cc8",
              pool: "0xc6bc781e20f9323012f6e422bdf552ff06ba6cd1",
              to: "0xaf88d065e77c8cc2239327c5edb3a432268e5831",
            },
          ],
        },
        {
          amountFrom: "499750000000000000",
          amountTo: "1108700482",
          gas: 240000,
          swaps: [
            {
              amountFrom: "499750000000000000",
              amountTo: "1108840524",
              dex: "uniswapv3",
              from: "0x82af49447d8a07e3bd95bd0d56f35241523fbab1",
              pool: "0xe754841b77c874135caca3386676e886459c2d61",
              to: "0xff970a61a04b1ca14834a43f5de4533ebddb5cc8",
            },
            {
              amountFrom: "1108840524",
              amountTo: "1108700482",
              dex: "traderjoev21",
              from: "0xff970a61a04b1ca14834a43f5de4533ebddb5cc8",
              pool: "0xde568fd89b3349a766f45d5ab2a7c0510f476a80",
              to: "0xaf88d065e77c8cc2239327c5edb3a432268e5831",
            },
          ],
        },
      ],
      to: "0xaf88d065e77c8cc2239327c5edb3a432268e5831",
      tokens: {
        "0x82af49447d8a07e3bd95bd0d56f35241523fbab1": {
          address: "0x82af49447d8a07e3bd95bd0d56f35241523fbab1",
          decimals: 18,
          name: "Wrapped Ether",
          price: 2222.4808563345814,
          symbol: "WETH",
        },
        "0xaf88d065e77c8cc2239327c5edb3a432268e5831": {
          address: "0xaf88d065e77c8cc2239327c5edb3a432268e5831",
          decimals: 6,
          name: "USD Coin",
          price: 1,
          symbol: "USDC",
        },
        "0xff970a61a04b1ca14834a43f5de4533ebddb5cc8": {
          address: "0xff970a61a04b1ca14834a43f5de4533ebddb5cc8",
          decimals: 6,
          name: "USD Coin (Arb1)",
          price: 1,
          symbol: "USDC",
        },
      },
      totalFrom: "1000000000000000000",
      totalGas: 485000,
      totalTo: "2217598932",
    },
  },
  receiver: "0x0000000000000000000000000000000000000000",
  ref: "",
  saveGas: "0",
  slippage: 0.01,
  source: "firebird",
  to: "0xaf88d065e77c8cc2239327c5edb3a432268e5831",
};

export const InitQueriesParam: AggregatorParamType = {
  from: "",
  to: "",
  amount: "",
  slippage: "",
  source: "",
  receiver: "",
  ref: "",
};
