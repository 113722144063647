import React, { useState, useCallback, useEffect, useMemo } from "react";
import { BigNumber, ethers } from "ethers";
import cx from "classnames";

import { DEFAULT_SLIPPAGE_AMOUNT, USD_DECIMALS, importImage, TAKE_PROFIT, STOP_LOSS } from "lib/legacy";
import Tab from "../Tab/Tab";
import Modal from "../Modal/Modal";
import ExchangeInfoRow from "./ExchangeInfoRow";
import "./PositionSeller.css";
import { useLocalStorageSerializeKey } from "lib/localStorage";
import { SLIPPAGE_BPS_KEY, THEME_KEY } from "config/localStorage";
import { expandDecimals, formatAmount, parseValue } from "lib/numbers";
import PercentageButtons from "./PercentageButtons";
import TakeProfitLongTriggerButton from "./TakeProfitLongTriggerButton";
import TakeProfitShortTriggerButton from "./TakeProfitShortTriggerButton";
import { preventSpecialCharacters } from "utils/helpers";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
const antIcon = <LoadingOutlined style={{ fontSize: 16, color: "grey" }} spin />;
const { AddressZero } = ethers.constants;
const ORDER_SIZE_DUST_USD = expandDecimals(1, USD_DECIMALS - 1); // $0.10

export default function AddTPSL(props) {
  const {
    onConfirm,
    isVisible,
    position,
    setIsVisible,
    isHigherSlippageAllowed,
    setIsHigherSlippageAllowed,
    chainId,
    marketTokensInfo,
    isConfirm,
  } = props;
  const currentMarket = marketTokensInfo?.find((x) => x.id === position.tokenId);
  const markPrice = BigNumber.from(currentMarket.askPrice || 1);
  let [orderOption, setOrderOption] = useState(TAKE_PROFIT);
  const [triggerPriceInput, setTriggerPriceInput] = useState("");
  const [closeQuantity, setCloseQuantity] = useState("");
  const [savedSlippageAmount] = useLocalStorageSerializeKey([chainId, SLIPPAGE_BPS_KEY], DEFAULT_SLIPPAGE_AMOUNT);
  const ORDER_OPTIONS = [TAKE_PROFIT, STOP_LOSS];
  const ORDER_OPTION_LABELS = {
    [TAKE_PROFIT]: TAKE_PROFIT,
    [STOP_LOSS]: STOP_LOSS,
  };
  const onOrderOptionChange = (option) => {
    setOrderOption(option);
  };
  const [takeProfitPrice, setTakeProfitPrice] = useState(0);
  const [takeProfitQuantity, setTakeProfitQuantity] = useState(0);
  const [stopLossPrice, setStopLossPrice] = useState(0);
  const [stopLossQuantity, setStopLossQuantity] = useState(0);
  const setTPSLTrigerPriceValue = (value) => {
    if (orderOption === TAKE_PROFIT) {
      setTakeProfitPrice(value);
    } else {
      setStopLossPrice(value);
    }
  };
  const handleMarkPriceClicked = () => {
    const value = formatAmount(markPrice, 30, 6, false);
    setTriggerPriceInput(value);
  };
  const handleTakeProfitLongPercent = (percentage) => {
    const value = parseValue(position.averagePrice, 30).add(
      parseValue(percentage, 30).mul(parseValue(position.averagePrice, 30)).div(parseValue(1, 30))
    );
    setTriggerPriceInput(formatAmount(value, 30, 6, false));
    setTPSLTrigerPriceValue(value);
  };
  const handleTakeProfitShortPercent = (percentage) => {
    const value = parseValue(position.averagePrice, 30).sub(
      parseValue(percentage, 30).mul(parseValue(position.averagePrice, 30)).div(parseValue(1, 30))
    );
    setTriggerPriceInput(formatAmount(value, 30, 6, false));
    setTPSLTrigerPriceValue(value);
  };
  const handleTrigerPriceInputChange = (e) => {
    const value = e.target.value;
    setTriggerPriceInput(value);
    if (value) {
      setTPSLTrigerPriceValue(parseValue(value, 30));
    } else {
      setTPSLTrigerPriceValue(0);
    }
  };
  const setCloseQuantityValue = (value) => {
    if (orderOption === TAKE_PROFIT) setTakeProfitQuantity(value);
    else setStopLossQuantity(value);
  };
  const handleSelectPercentageCloseQuantity = (percentage) => {
    const value = position.quantity.mul(parseValue(percentage, 30)).div(parseValue(1, 30));
    setCloseQuantity(formatAmount(value, 30, 30, false));
    setCloseQuantityValue(value);
  };
  const estTakeProfitPnl = () => {
    if (takeProfitPrice && takeProfitQuantity) {
      // debugger
      const markPrice = takeProfitPrice;
      const averagePrice = parseValue(position.averagePrice, 30);
      let size = 0;
      if (takeProfitQuantity.eq(position.quantity)) {
        size = parseValue(position.size, 30);
      } else {
        size = takeProfitQuantity.mul(averagePrice).div(parseValue(1, 30));
      }
      const pnl = position.isLong
        ? markPrice.sub(averagePrice).mul(size).div(averagePrice)
        : averagePrice.sub(markPrice).mul(size).div(averagePrice);
      const percentPnl = pnl.mul(parseValue(100, 30)).div(parseValue(position.collateral, 30));
      return {
        pnl,
        prefix: pnl?.gt(0) ? "+" : pnl?.lt(0) ? "-" : "",
        percentPnl,
      };
    }
    return null;
  };
  const estStopLossPnl = () => {
    if (stopLossPrice && stopLossQuantity) {
      // debugger
      const markPrice = stopLossPrice;
      const averagePrice = parseValue(position.averagePrice, 30);
      let size = 0;
      if (stopLossQuantity.eq(position.quantity)) {
        size = parseValue(position.size, 30);
      } else {
        size = stopLossQuantity.mul(averagePrice).div(parseValue(1, 30));
      }
      const pnl = position.isLong
        ? markPrice.sub(averagePrice).mul(size).div(averagePrice)
        : averagePrice.sub(markPrice).mul(size).div(averagePrice);
      const percentPnl = pnl.mul(parseValue(100, 30)).div(parseValue(position.collateral, 30));
      return {
        pnl,
        prefix: pnl?.gt(0) ? "+" : pnl?.lt(0) ? "-" : "",
        percentPnl,
      };
    }
    return null;
  };
  const estTakeProfit = estTakeProfitPnl();
  const estStopLoss = estStopLossPnl();
  const onHandleAddTPSL = () => {
    const isTP = !!takeProfitPrice && !!takeProfitQuantity;
    const isSL = !!stopLossPrice && !!stopLossQuantity;
    if (isTP || isSL) {
      const prices = [];
      const isTPs = [isTP];
      const amountPercents = [];
      if (isSL && isTP) isTPs.push(false);
      if (isTP && !isSL) {
        prices.push(takeProfitPrice);
      } else if (!isTP && isSL) {
        prices.push(stopLossPrice);
      } else {
        prices.push(takeProfitPrice);
        prices.push(stopLossPrice);
      }

      if (isTP && !isSL) {
        const percent = takeProfitQuantity.eq(position.quantity)
          ? 100
          : Number(formatAmount(takeProfitQuantity.mul(parseValue(100, 30)).div(position.quantity), 30, 2, false));
        amountPercents.push(percent * 1000);
      } else if (!isTP && isSL) {
        const percent = stopLossQuantity.eq(position.quantity)
          ? 100
          : Number(formatAmount(stopLossQuantity.mul(parseValue(100, 30)).div(position.quantity), 30, 2, false));
        amountPercents.push(percent * 1000);
      } else {
        const percentTakeProfit = takeProfitQuantity.eq(position.quantity)
          ? 100
          : Number(formatAmount(takeProfitQuantity.mul(parseValue(100, 30)).div(position.quantity), 30, 2, false));
        const percentStopLoss = stopLossQuantity.eq(position.quantity)
          ? 100
          : Number(formatAmount(stopLossQuantity.mul(parseValue(100, 30)).div(position.quantity), 30, 2, false));
        amountPercents.push(percentTakeProfit * 1000);
        amountPercents.push(percentStopLoss * 1000);
      }
      // console.log("???", { position, isTPs, prices, amountPercents });
      onConfirm && onConfirm(position, isTPs, prices, amountPercents);
    }
  };
  useEffect(() => {
    if (orderOption === TAKE_PROFIT) {
      if (takeProfitPrice) {
        setTriggerPriceInput(formatAmount(takeProfitPrice, 30, 6, false));
        setCloseQuantity(formatAmount(takeProfitQuantity, 30, 30, false));
      } else {
        setTriggerPriceInput("");
        setCloseQuantity("");
      }
    } else {
      if (stopLossPrice) {
        setTriggerPriceInput(formatAmount(stopLossPrice, 30, 6, false));
        setCloseQuantity(formatAmount(stopLossQuantity, 30, 30, false));
      } else {
        setTriggerPriceInput("");
        setCloseQuantity("");
      }
    }
  }, [orderOption]);
  const handleChangeQuantityInput = (e) => {
    const value = e.target.value;
    setCloseQuantity(value);
    if (value && Number(value)) {
      if (orderOption === TAKE_PROFIT) {
        setTakeProfitQuantity(parseValue(value, 30));
      } else {
        setStopLossQuantity(parseValue(value, 30));
      }
    } else {
      if (orderOption === TAKE_PROFIT) {
        setTakeProfitQuantity(0);
      } else {
        setStopLossQuantity(0);
      }
    }
  };

  const getErrorTakeProfit = () => {
    if (triggerPriceInput === "") return false;

    if (!Number(triggerPriceInput)) {
      return "Enter an amount";
    }

    const triggerPrice = parseValue(triggerPriceInput, 30);

    if (position.isLong) {
      if (triggerPrice.lte(markPrice)) {
        return "Please adjust the Trigger Price so that it is higher than the mark price";
      }
    } else {
      if (triggerPrice.gte(markPrice)) {
        return "Please adjust the Trigger Price so that it is lower than the mark price";
      }
    }

    return false;
  };

  const getErrorStopLoss = () => {
    if (triggerPriceInput === "") return false;

    if (!Number(triggerPriceInput)) {
      return "Enter an amount";
    }

    const triggerPrice = parseValue(triggerPriceInput, 30);

    if (position.isLong) {
      if (triggerPrice.gte(markPrice) || triggerPrice.lte(parseValue(position.liquidationPrice, 30))) {
        return "Please adjust the Trigger Price so that it is lower than the mark price and higher than liquidation price";
      }
    } else {
      if (triggerPrice.lte(markPrice) || triggerPrice.gte(parseValue(position.liquidationPrice, 30))) {
        return "Please adjust the Trigger Price so that it is higher than the mark price and lower than liquidation price";
      }
    }

    return false;
  };

  const getErrorCloseQuantity = () => {
    if (closeQuantity === "") return false;

    if (!Number(closeQuantity)) {
      return "Enter close quantity";
    }

    if (position.quantity && closeQuantity && parseValue(closeQuantity, 30).gt(position.quantity)) {
      return "Amount exceeds balance";
    }

    return false;
  };

  const isDisableButton = () => {
    if (!triggerPriceInput || !Number(triggerPriceInput) || !closeQuantity || !Number(closeQuantity)) {
      return true;
    }

    if (orderOption === TAKE_PROFIT) {
      return !!getErrorTakeProfit() || !!getErrorCloseQuantity();
    }
    return !!getErrorStopLoss() || !!getErrorCloseQuantity();
  };

  const getPrimaryText = () => {
    if (closeQuantity === "") return isConfirm ? "Adding..." : "Add TP/SL";

    if (!Number(closeQuantity)) {
      return "Enter close quantity";
    }

    if (position.quantity && closeQuantity && parseValue(closeQuantity, 30).gt(position.quantity)) {
      return "Amount exceeds balance";
    }

    return "Add TP/SL";
  };
  return (
    <div className="PositionEditor">
      {position && (
        <Modal
          className={`PositionSeller-modal confirmation-modal`}
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          label={"Add Take Profit / Stop Loss"}
          allowContentTouchMove
        >
          <div className="order-title-line order-title-line-details">
            <img src={importImage("ic_" + position?.symbol?.toLowerCase() + "_24.svg")} alt="" />
            <div className="position-info-container">
              <div className="position-info">
                <div className="title">{position.symbol}</div>
                <div className={`side ${position?.isLong ? "side-long" : "side-short"}`}>
                  {position?.isLong ? "LONG" : "SHORT"}
                </div>
                <div className="side">
                  {position?.positionType === "Market Order" ? "Market" : position?.positionType}
                </div>
              </div>
              <div className="position-id">#{position?.posId}</div>
            </div>
          </div>
          <div className="content-container">
            <Tab
              options={ORDER_OPTIONS}
              option={orderOption}
              optionLabels={ORDER_OPTION_LABELS}
              onChange={onOrderOptionChange}
            />
            <div className="Exchange-swap-section" style={{ marginBottom: "8px", marginTop: "16px" }}>
              <div className="Exchange-swap-section-top">
                <div className="muted">
                  <div className="Exchange-swap-usd">
                    <span>
                      {/* Close: {convertedAmountFormatted} {position.collateralToken.symbol} */}
                      Trigger Price
                    </span>
                  </div>
                </div>
                <div className="muted align-right clickable" onClick={handleMarkPriceClicked}>
                  <span>Mark: ${formatAmount(markPrice, 30, currentMarket?.decimals || 2, true)}</span>
                </div>
              </div>
              <div className="Exchange-swap-section-bottom">
                <div className="Exchange-swap-input-container">
                  <input
                    type="number"
                    min="0"
                    placeholder="0.0"
                    className="Exchange-swap-input"
                    value={triggerPriceInput}
                    onChange={handleTrigerPriceInputChange}
                    onKeyDown={preventSpecialCharacters}
                  />
                </div>
                <div className="PositionEditor-token-symbol">USD</div>
              </div>
            </div>
            {orderOption === TAKE_PROFIT && <span className="validate-error">{getErrorTakeProfit()}</span>}
            {orderOption === STOP_LOSS && <span className="validate-error">{getErrorStopLoss()}</span>}
            {orderOption === TAKE_PROFIT ? (
              position.isLong ? (
                <TakeProfitLongTriggerButton
                  onChangePercentage={handleTakeProfitLongPercent}
                  balance={Number(formatAmount(parseValue(position.averagePrice, 30), 30, 30, false))}
                  value={triggerPriceInput}
                  percentArray={[0.01, 0.02, 0.03, 0.05]}
                />
              ) : (
                <TakeProfitShortTriggerButton
                  onChangePercentage={handleTakeProfitShortPercent}
                  balance={Number(formatAmount(parseValue(position.averagePrice, 30), 30, 30, false))}
                  value={triggerPriceInput}
                  percentArray={[0.01, 0.02, 0.03, 0.05]}
                />
              )
            ) : position.isLong ? (
              <TakeProfitShortTriggerButton
                onChangePercentage={handleTakeProfitShortPercent}
                balance={Number(formatAmount(parseValue(position.averagePrice, 30), 30, 30, false))}
                value={triggerPriceInput}
                percentArray={[0.01, 0.02, 0.03, 0.05]}
              />
            ) : (
              <TakeProfitLongTriggerButton
                onChangePercentage={handleTakeProfitLongPercent}
                balance={Number(formatAmount(parseValue(position.averagePrice, 30), 30, 30, false))}
                value={triggerPriceInput}
                percentArray={[0.01, 0.02, 0.03, 0.05]}
              />
            )}
            <div className="Exchange-swap-section" style={{ marginBottom: "8px", marginTop: "16px" }}>
              <div className="Exchange-swap-section-top">
                <div className="muted">
                  <span>Close Quantity</span>
                </div>
                <div className="muted align-right">
                  <span>Max: {formatAmount(position.quantity, 30, 4, true)}</span>
                </div>
              </div>
              <div className="Exchange-swap-section-bottom">
                <div className="Exchange-swap-input-container">
                  <input
                    type="number"
                    min="0"
                    placeholder="0.0"
                    className="Exchange-swap-input"
                    value={closeQuantity}
                    onChange={handleChangeQuantityInput}
                    onKeyDown={preventSpecialCharacters}
                  />
                </div>
                <div className="PositionEditor-token-symbol">{position.symbol}</div>
              </div>
            </div>
            {/* <span className="validate-error">{getErrorCloseQuantity()}</span> */}
            <PercentageButtons
              onChangePercentage={handleSelectPercentageCloseQuantity}
              balance={Number(formatAmount(position.quantity, 30, 30, false))}
              value={closeQuantity}
            />
            <div className="PositionEditor-info-box" style={{ marginTop: "16px" }}>
              <div className="square-container square-fee-container">
                <div style={{ fontSize: "14px" }}>Take Profit</div>
                <ExchangeInfoRow label={`Trigger Price`}>
                  ${takeProfitPrice ? formatAmount(takeProfitPrice, 30, currentMarket?.decimals || 2, true) : "--"}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`TP Qty`}>
                  {takeProfitQuantity && !takeProfitQuantity.eq(0)
                    ? formatAmount(takeProfitQuantity, 30, 4, true)
                    : "--"}{" "}
                  {position.symbol}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Est. PnL`}>
                  <div
                    className={cx({
                      positive: estTakeProfit?.pnl.gt(0),
                      negative: estTakeProfit?.pnl.lt(0),
                    })}
                  >
                    {estTakeProfit
                      ? `${estTakeProfit?.prefix}$${formatAmount(estTakeProfit?.pnl, 30, 2, true).replace("-", "")}`
                      : "$--"}
                  </div>
                </ExchangeInfoRow>
                <div className="divider" />
                <div style={{ fontSize: "14px" }}>Stop Loss</div>
                <ExchangeInfoRow label={`Trigger Price`}>
                  {" "}
                  ${stopLossPrice ? formatAmount(stopLossPrice, 30, currentMarket?.decimals || 2, true) : "--"}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`SL Qty`}>
                  {" "}
                  {stopLossQuantity ? formatAmount(stopLossQuantity, 30, 4, true) : "--"} {position.symbol}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Est. PnL`}>
                  <div
                    className={cx({
                      positive: estStopLoss?.pnl.gt(0),
                      negative: estStopLoss?.pnl.lt(0),
                    })}
                  >
                    {estStopLoss
                      ? `${estStopLoss?.prefix}$${formatAmount(estStopLoss?.pnl, 30, 2, true).replace("-", "")}`
                      : "$--"}
                  </div>
                </ExchangeInfoRow>
                <div className="divider" />
                <ExchangeInfoRow label={`Entry Price`}>
                  ${formatAmount(parseValue(position.averagePrice, 30), 30, currentMarket?.decimals || 2, true)}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Mark Price`}>
                  ${formatAmount(markPrice, 30, currentMarket?.decimals || 2, true)}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Pos. Size`}>
                  ${formatAmount(parseValue(position.size, 30), 30, currentMarket?.decimals || 2, true)}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Pos. Qty`}>
                  {formatAmount(position.quantity, 30, 4, true)} {position.symbol}
                </ExchangeInfoRow>
              </div>
            </div>
          </div>
          <div className="Exchange-swap-button-container">
            <button
              className="App-cta Exchange-swap-button"
              onClick={onHandleAddTPSL}
              disabled={isDisableButton() || isConfirm}
            >
              {/* Add TP/SL */}
              {getPrimaryText()}
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
}
