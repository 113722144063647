import { FANTOM, OP } from "config/chains";
import useMspPrice from "./useMspPrice";
import useInfoMslp from "./useInfoMslp";

function useMspPriceMultiChain() {
  return {
    [FANTOM]: useInfoMslp(FANTOM).price,
    [OP]: useInfoMslp(OP).price,
  };
}

export default useMspPriceMultiChain;
