import React, { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { TabWrapper } from "../styles/AnalyticsV3.styled";
import PositionItem from "./PositionItem";

import upIcWhite from "img/upArrowWhite.svg";
import downIc from "img/downArrow.svg";
import inInfo from "img/ic_info.svg";
import upIc from "img/upArrow.svg";
import Pagination from "./Pagination";
import { useMediaQuery } from "react-responsive";
import { Select } from "antd";
import { getApiAnalytics } from "../config";
import NoItemFound from "./NoItemFound";
import useAccountsNFT from "hooks/useAccountsNFT";
import Loader from "components/Common/Loader";
import Tooltip from "components/Tooltip/Tooltip";
import UpArrowIcon from "components/Icon/AnalyticIcon/upArrowIc";
import { useThemeContext } from "contexts/ThemeProvider";
import moment from "moment";

const ORDER_BY_OPTIONS = [
  {
    value: "asc:PNL",
    label: "Lowest to highest in PnL",
  },
  {
    value: "desc:PNL",
    label: "Highest to lowest in PnL",
  },
  {
    value: "asc:Size",
    label: "Lowest to highest in Size",
  },
  {
    value: "desc:Size",
    label: "Highest to lowest in Size",
  },
];

const TopPositionTab = ({ chainId, isThemeLight }) => {
  const [sortType, setSortType] = useState("desc"); // desc down,asc up
  const [sortBy, setSortBy] = useState("pnl"); // size,pnl
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState("desc:PNL");
  const [currentPage, setCurrentPage] = useState(1);
  const MAX_NUMBER_PAGE = 70; //HARD FIXED
  const isSmallScreen = useMediaQuery({ query: "(max-width: 1023px)" });
  const [row, setRows] = useState(100);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const NFTs = useAccountsNFT(accounts);
  const { lightThemeClassName } = useThemeContext();
  const [lastUpdate, setLastUpdate] = useState();

  let querySort = "";

  if (sortBy) {
    if (sortBy === "size") {
      if (sortType === "asc") {
        querySort = `&order=size%20ASC`;
      } else {
        querySort = `&order=size%20DESC`;
      }
    } else if (sortBy === "pnl") {
      if (sortType === "asc") {
        querySort = `&order=netPnl%20ASC`;
      } else {
        querySort = `&order=netPnl%20DESC`;
      }
    } else {
      if (sortType === "asc") {
        querySort = `&order=netValue%20ASC`;
      } else {
        querySort = `&order=netValue%20DESC`;
      }
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const response = await axios(
        `${getApiAnalytics(chainId)}leaderboard/top-position?page=${currentPage}&pageSize=${rowsPerPage}${querySort}`
      );

      const accounts = Array.from(new Set(response.data.rows.map((row) => row.account)));
      setAccounts(accounts);

      setRows(response.data.count);
      setData(response.data.rows);
      setLastUpdate(response.data.lastDataUpdateTime);
      setIsLoading(false);
    };

    fetchData();
  }, [currentPage, rowsPerPage, querySort, chainId]);

  const handleSortSize = () => {
    let resultSortType = "";

    if (sortBy === "size") {
      if (sortType !== "asc") {
        resultSortType = "asc";
      } else {
        resultSortType = "desc";
      }
    } else {
      resultSortType = "desc";
    }
    setSortBy("size");
    setSortType(resultSortType);
  };

  const handleSortPnl = () => {
    let resultSortType = "";

    if (sortBy === "pnl") {
      if (sortType !== "asc") {
        resultSortType = "asc";
      } else {
        resultSortType = "desc";
      }
    } else {
      resultSortType = "desc";
    }
    setSortBy("pnl");
    setSortType(resultSortType);
  };
  const handleSortNetValue = () => {
    let resultSortType = "";

    if (sortBy === "netValue") {
      if (sortType !== "asc") {
        resultSortType = "asc";
      } else {
        resultSortType = "desc";
      }
    } else {
      resultSortType = "desc";
    }
    setSortBy("netValue");
    setSortType(resultSortType);
  };

  const handleSortOnMobile = (value) => {
    setOrderBy(value);
    const [sortType, sortBy] = value.split(":");
    setSortBy(sortBy.toLowerCase());
    setSortType(sortType);
  };
  const renderTop = () => {
    switch (sortBy) {
      case "netValue":
        return "Sort by Net Value";
      case "pnl":
        return "Sort by PnL";
      case "size":
        return "Sort by Size";
      default:
        return "Sort by PnL";
    }
  };
  const renderTopIndex = (index) => {
    let isDesc = true;
    if (sortType === "asc") isDesc = false;
    if (isDesc) return index + 1 + rowsPerPage * (currentPage - 1);
    else {
      return row - (index + rowsPerPage * (currentPage - 1));
    }
  };
  return (
    <TabWrapper>
      {!isLoading && !isSmallScreen && (
        <span className="label-last-update onleft">
          Last update: {moment(Number(lastUpdate || "") * 1000).fromNow()}
        </span>
      )}
      <Wrapper>
        <div className="header-table">
          <div>
            <span>
              <Tooltip
                handle={`Top`}
                position="left-bottom"
                className="fit-content nowrap"
                renderContent={() => {
                  return <p className="text-white">{renderTop()}</p>;
                }}
              />
            </span>
            <span>Position</span>
          </div>
          <span
            style={{
              cursor: "pointer",
            }}
            onClick={handleSortSize}
          >
            Size
            <div className="group-arrow">
              <span>
                {sortBy === "size" && sortType === "asc" ? (
                  <UpArrowIcon fill={isThemeLight ? "#0D1A16" : "#fff"} className="up" />
                ) : (
                  <UpArrowIcon fill={isThemeLight ? "#0D1A1633" : "#ADABAB"} className="up" />
                )}
              </span>

              <span>
                {sortBy === "size" && sortType === "desc" ? (
                  <UpArrowIcon fill={isThemeLight ? "#0D1A16" : "#fff"} className="down" />
                ) : (
                  <UpArrowIcon fill={isThemeLight ? "#0D1A1633" : "#ADABAB"} className="down" />
                )}
              </span>
            </div>
          </span>
          <span
            style={{
              cursor: "pointer",
            }}
            onClick={handleSortNetValue}
          >
            Net Value
            <div className="group-arrow">
              <span>
                {sortBy === "netValue" && sortType === "asc" ? (
                  <UpArrowIcon fill={isThemeLight ? "#0D1A16" : "#fff"} className="up" />
                ) : (
                  <UpArrowIcon fill={isThemeLight ? "#0D1A1633" : "#ADABAB"} className="up" />
                )}
              </span>

              <span>
                {sortBy === "netValue" && sortType === "desc" ? (
                  <UpArrowIcon fill={isThemeLight ? "#0D1A16" : "#fff"} className="down" />
                ) : (
                  <UpArrowIcon fill={isThemeLight ? "#0D1A1633" : "#ADABAB"} className="down" />
                )}
              </span>
            </div>
          </span>
          <span>Collateral</span>
          <span
            style={{
              cursor: "pointer",
            }}
            onClick={handleSortPnl}
          >
            PnL
            <div className="group-arrow">
              <span>
                {sortBy === "pnl" && sortType === "asc" ? (
                  <UpArrowIcon fill={isThemeLight ? "#0D1A16" : "#fff"} className="up" />
                ) : (
                  <UpArrowIcon fill={isThemeLight ? "#0D1A1633" : "#ADABAB"} className="up" />
                )}
              </span>

              <span>
                {sortBy === "pnl" && sortType === "desc" ? (
                  <UpArrowIcon fill={isThemeLight ? "#0D1A16" : "#fff"} className="down" />
                ) : (
                  <UpArrowIcon fill={isThemeLight ? "#0D1A1633" : "#ADABAB"} className="down" />
                )}
              </span>
            </div>
          </span>
        </div>
        {isSmallScreen && (
          <>
            <div className="filter-block">
              Ordered by:{" "}
              <Select
                showArrow
                suffixIcon={<img className="downIc" src={downIc} alt="" />}
                onChange={handleSortOnMobile}
                defaultValue={orderBy}
                style={{
                  width: "fit-content",
                }}
                bordered={false}
                options={ORDER_BY_OPTIONS}
                className={`hasBorder ${lightThemeClassName}`}
                popupClassName={lightThemeClassName}
              />
              {!isLoading && (
                <span className="label-last-update" style={{ height: 34, display: "flex", alignItems: "center" }}>
                  Last update: {moment(Number(lastUpdate || "") * 1000).fromNow()}
                </span>
              )}
            </div>
          </>
        )}
        <div className="body-table">
          {isLoading ? (
            // <Loader />
            <div className="skeleton-box" style={{ height: "30px", marginTop: "20px" }} />
          ) : data.length > 0 ? (
            <>
              {data.map((item, index) => {
                const rank = currentPage * rowsPerPage - (rowsPerPage - 1);
                return (
                  <PositionItem
                    key={item.key}
                    item={item}
                    rank={renderTopIndex(index)}
                    chainId={chainId}
                    NFTid={NFTs?.[item.account]}
                    isThemeLight={isThemeLight}
                  />
                );
              })}
              <Pagination
                currentPage={currentPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                setCurrentPage={setCurrentPage}
                maxNumberOfPage={MAX_NUMBER_PAGE}
                row={row}
              />
            </>
          ) : (
            <NoItemFound />
          )}
        </div>
      </Wrapper>
    </TabWrapper>
  );
};

export default TopPositionTab;
const Wrapper = styled.div`
  .header-table {
    grid-template-columns: 3fr 1fr 1fr 1fr 1fr;
  }
  .body-table {
    a {
      grid-template-columns: 3fr 1fr 1fr 1fr 1fr;
    }
  }
`;
