import useSWR from "swr";
import { BigNumber, ethers } from "ethers";

import { getContract } from "config/contracts";
import { contractFetcher } from "lib/contracts";

import ReaderNav from "abis/ReaderNav.json";
import Multicall from "abis/Multicall.json";
import { PLACEHOLDER_ACCOUNT } from "lib/legacy";
import { useEffect, useState } from "react";
import { getProvider } from "lib/rpc";
import multicall from "domain/multicall";

export default function useReaderNavContract(
  chainId: number,
  active: boolean,
  account: string | null | undefined,
  library: any
) {
  const readerNavAddress = getContract(chainId, "ReaderMsp");
  const multicallAddress = getContract(chainId, "Multicall");
  const [data, setData] = useState({});

  useEffect(() => {
    const provider = getProvider(library, chainId);
    const multicallContract = new ethers.Contract(multicallAddress, Multicall.abi, provider);
    const fetchNoAccount = async () => {
      if (!account && provider) {
        const calls = [
          {
            address: readerNavAddress,
            name: "getUserNLPInfo",
            params: [PLACEHOLDER_ACCOUNT],
          },
          {
            address: readerNavAddress,
            name: "getUserNavAndEsNavInfo",
            params: [PLACEHOLDER_ACCOUNT],
          },
          {
            address: readerNavAddress,
            name: "getUserVestingInfo",
            params: [PLACEHOLDER_ACCOUNT],
          },
        ];

        const keyOfCalls = calls.map((call) => call.name);

        const response = await multicall(
          multicallContract,
          ReaderNav.abi,
          calls.map(({ address, name, params }) => ({
            address,
            name,
            params,
          }))
        );
        const result = {};
        for (const index in keyOfCalls) {
          result[keyOfCalls[index]] = response[index];
        }
        setData((prev) => ({ ...prev, ...result }));
      }
    };

    const fetch = async () => {
      try {
        if (account && provider) {
          const calls = [
            {
              address: readerNavAddress,
              name: "getUserNLPInfo",
              params: [account],
            },
            {
              address: readerNavAddress,
              name: "getUserNavAndEsNavInfo",
              params: [account],
            },
            {
              address: readerNavAddress,
              name: "getUserVestingInfo",
              params: [account],
            },
          ];

          const keyOfCalls = calls.map((call) => call.name);

          const response = await multicall(
            multicallContract,
            ReaderNav.abi,
            calls.map(({ address, name, params }) => ({
              address,
              name,
              params,
            }))
          );
          const result = {};
          for (const index in keyOfCalls) {
            result[keyOfCalls[index]] = response[index];
          }
          setData((prev) => ({ ...prev, ...result }));
        }
      } catch (error) {
        console.error("[ERROR]: useReaderNavContract", error);
      }
    };

    fetchNoAccount();
    fetch();
    const timerId = setInterval(() => {
      fetchNoAccount();
      fetch();
    }, 10000);

    return () => {
      clearInterval(timerId);
    };
  }, [account, chainId, library, multicallAddress, readerNavAddress]);

  return {
    userNLPInfo: data["getUserNLPInfo"] || [[], [], []],
    userNavAndEsNavInfo: data["getUserNavAndEsNavInfo"] || [[]],
    userVestingInfo: data["getUserVestingInfo"] || [],
  };
}
