import icNFTItem from "img/bridge/nft-item.svg";
import icClose from "img/bridge/close.svg";
import { useState } from "react";
import { getLinkCDN } from "config/chains";
import { useChainId } from "lib/chains";
const OPEN_DELAY = 0;
const CLOSE_DELAY = 100;
const SelectedNftItem = ({ item, onRemove }) => {
  const [showIcon, setShowIcon] = useState(false);
  const { chainId } = useChainId();
  let myTimeout;
  const onMouseEnter = () => {
    setTimeout(() => {
      clearTimeout(myTimeout);
      setShowIcon(true);
    }, OPEN_DELAY);
  };
  const setLeaveItem = () => {
    setShowIcon(false);
  };
  const onMouseLeave = () => {
    myTimeout = setTimeout(setLeaveItem, CLOSE_DELAY);
  };
  const handleRemoveNft = (nft) => {
    onRemove && onRemove(nft);
  };
  return (
    <div className="nft-selected-item" key={item.id} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <img alt="" src={`${getLinkCDN(chainId)}${item.id}.png`} className="main-nft" />
      {showIcon && <div className="overlay" />}
      {showIcon && (
        <div className="ic-close" onClick={() => handleRemoveNft(item)}>
          <img alt="" src={icClose} />
        </div>
      )}
    </div>
  );
};
export default SelectedNftItem;
