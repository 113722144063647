import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";

import ExternalLink from "components/ExternalLink/ExternalLink";
import OpenPositions from "./Account/OpenPositions";
import ClosePositions from "./Account/ClosePositions";
import Orders from "./Account/Orders";

import Wrapper from "./Account.styled";

import share from "img/share.svg";
import icVolume from "img/ic-volume.svg";
import icPnl from "img/ic-pnl.svg";
import icAccFee from "img/ic-acc-fee.svg";

import icVolumeLight from "img/ic-volume-light.svg";
import icPnlLight from "img/ic-pnl-light.svg";
import icAccFeeLight from "img/ic-acc-fee-light.svg";

import TradeHistoryAcc from "./Account/TradeHistoryAcc";
import BackUrl from "./BackUrl";
import Avatar from "components/Avatar";
import axios from "axios";
import { formatAmount } from "lib/numbers";
import { useHistory, useParams } from "react-router-dom";
import { getApiAnalytics } from "../config";
import { getExplorerUrl } from "config/chains";
import useYourNFTId from "hooks/useYourNFTId";
import { useThemeContext } from "contexts/ThemeProvider";

const StyledExternalLink = styled(ExternalLink)`
  &:hover {
    img {
      filter: invert(82%) sepia(64%) saturate(513%) hue-rotate(87deg) brightness(98%) contrast(97%);
    }
  }
`;

const TABS = ["Open Positions", "Orders", "Closed Positions", "Trade History"];

export default function Overview({ chainId, isThemeLight }) {
  const { isLightTheme, lightThemeClassName } = useThemeContext() as any;
  const [data, setData] = useState({
    totalTradingVolume: 0,
    totalPnl: 0,
    totalNetPnl: 0,
    totalFee: 0,
    totalOpenPosition: 0,
    totalClosePosition: 0,
    totalOpenOrder: 0,
    totalActivities: 0,
  });

  const history = useHistory();
  const { account } = useParams() as any;
  const NFTid = useYourNFTId(account);

  const [tab, setTab] = useState(history.action === "POP" ? "Closed Positions" : "Open Positions");

  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios(`${getApiAnalytics(chainId)}leaderboard/trader/${account}`);
      if (response?.data) setData(response.data);
    };
    fetchData();
    const timerId = setInterval(() => {
      fetchData();
    }, 20000);

    return () => {
      clearInterval(timerId);
    };
  }, [account, chainId]);

  const calcPnl = (totalPnl) => {
    return formatAmount(totalPnl, 30, 3, true);
  };

  const isPnlPlus = (totalPnl) => {
    const pnl = calcPnl(totalPnl);

    return !pnl.includes("-");
  };

  return (
    <AccountWrapper className={lightThemeClassName}>
      <Wrapper>
        <div className="account-container">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 16,
            }}
          >
            <BackUrl defaultUrl="/analytic/top-traders" isV2 />
            <div className="info">
              <Avatar address={account} size="48px" chainId={chainId} NFTid={NFTid} />
              <span>
                {isMobile ? account.slice(0, 6) + "..." + account.slice(account.length - 4, account.length) : account}
              </span>

              <StyledExternalLink href={`${getExplorerUrl(chainId)}address/${account}`}>
                <img src={share} alt="share" />
              </StyledExternalLink>
            </div>
          </div>

          <div className="group-info">
            <div className="child">
              <img src={isLightTheme ? icVolumeLight : icVolume} alt="icVolume" />

              <div className="content">
                <div className="label">Total Trading Volume</div>
                <div className="value">${formatAmount(data?.totalTradingVolume || 0, 30, 3, true)}</div>
              </div>
            </div>

            <div className="child">
              <img src={isLightTheme ? icPnlLight : icPnl} alt="icVolume" />

              <div className="content">
                <div className="label">Total PnL</div>
                <div className="value green">
                  <div
                    style={{
                      color:
                        Number(calcPnl(data.totalNetPnl)) === 0
                          ? "#ffffff"
                          : isPnlPlus(data.totalNetPnl)
                          ? isLightTheme
                            ? "#02B27F"
                            : "#34f5ae"
                          : isLightTheme
                          ? "#D62F44"
                          : "rgb(246, 71, 93)",
                    }}
                  >
                    {isPnlPlus(data.totalNetPnl) ? "+" : "-"}$
                    {calcPnl(data.totalNetPnl).includes("-")
                      ? calcPnl(data.totalNetPnl).slice(1)
                      : calcPnl(data.totalNetPnl)}
                  </div>
                </div>
              </div>
            </div>

            <div className="child">
              <img src={isLightTheme ? icAccFeeLight : icAccFee} alt="icVolume" />

              <div className="content">
                <div className="label">Total Fee Paid</div>
                <div className="value">${formatAmount(data?.totalFee || 0, 30, 3, true)}</div>
              </div>
            </div>
          </div>

          <div className="sub-tabs">
            {TABS.map((item) => (
              <div className={`child ${tab === item ? "active" : ""}`} onClick={() => setTab(item)}>
                {item}
                {item === "Trade History" && ` ${data.totalActivities > 0 ? `(${data.totalActivities})` : ""}`}
                {item === "Open Positions" && ` ${data.totalOpenPosition > 0 ? `(${data.totalOpenPosition})` : ""}`}
                {item === "Closed Positions" && ` ${data.totalClosePosition > 0 ? `(${data.totalClosePosition})` : ""}`}
                {item === "Orders" && ` ${data.totalOpenOrder > 0 ? `(${data.totalOpenOrder})` : ""}`}
              </div>
            ))}
          </div>

          {tab === "Open Positions" && (
            <OpenPositions account={account} chainId={chainId} isThemeLight={isThemeLight} />
          )}
          {tab === "Closed Positions" && <ClosePositions account={account} chainId={chainId} />}
          {tab === "Orders" && <Orders account={account} chainId={chainId} isThemeLight={isThemeLight} />}
          {tab === "Trade History" && <TradeHistoryAcc chainId={chainId} />}
        </div>
      </Wrapper>
    </AccountWrapper>
  );
}
const AccountWrapper = styled.div`
  margin-bottom: 286px;
  width: 100%;

  &.theme--light {
    .group-info {
      .child {
        .content {
          .value {
            color: #0d1a16 !important;
          }
        }
      }
    }

    .sub-tabs {
      .child {
        &.active {
          color: #02b27f;
          background: #02b27f26;
        }
      }
    }
  }
`;
