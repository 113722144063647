import { getContract } from "config/contracts";
import { getNormalizedAxlTokenSymbol, getTokenBySymbol, getWrappedToken } from "config/tokens";
import { getChainlinkChartPricesFromGraph, getChartPricesFromStats } from "domain/pricesV2";
import { ethers } from "ethers";
import { getServerBaseUrl, getServerUrl } from "config/backend";
import { getTokenSymbolFromString } from "domain/tokens";

export const getTokenChartPrice = async (chainId: number, symbol: string, period: string) => {
  let prices = [];
  const _symbol = getNormalizedAxlTokenSymbol(symbol);
  try {
    prices = await getChartPricesFromStats(chainId, _symbol, period);
  } catch (ex) {
    // eslint-disable-next-line no-console
    console.warn(ex, "Switching to graph chainlink data");
    try {
      // prices = await getChainlinkChartPricesFromGraph(_symbol, period);
    } catch (ex2) {
      // eslint-disable-next-line no-console
      console.warn("getChainlinkChartPricesFromGraph failed", ex2);
    }
  }
  return prices;
};

export async function getCurrentPriceOfToken(chainId: number, symbol: string) {
  try {
    // const indexPricesUrl = getServerUrl(chainId, "/prices");
    const indexPricesUrl = `${getServerBaseUrl(chainId, true)}/assets/prices`;
    const response = await fetch(indexPricesUrl);
    const { data } = await response.json();
    const dataMapped = Object.values(data).map((i: any) => ({
      symbol: getTokenSymbolFromString(i.tokenName, "_"),
      price: i.askPrice,
    }));
    return dataMapped.find((i) => i.symbol === symbol)?.price;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    return;
  }
}
