import { t, Trans } from "@lingui/macro";
import { useWeb3React } from "@web3-react/core";
import {
  encodeReferralCode,
  setTraderReferralCodeByUser,
  useCodeOwner,
  useUserReferralCode,
  validateReferralCodeExists,
} from "domain/referrals";
import { useDebounce } from "lib/useDebounce";
import { useEffect, useRef, useState } from "react";
import Modal from "../Modal/Modal";
import { StyledInput } from "./AddAffiliateCode";
import { REFERRAL_CODE_REGEX } from "./referralsHelper";

import { BiEditAlt } from "react-icons/bi";
import Tooltip from "../Tooltip/Tooltip";
import { getTierIdDisplay, tierDiscountInfo } from "./referralsHelper";

import { useReferrerTier } from "domain/referrals";
import { useChainId } from "lib/chains";
import { REFERRAL_CODE_KEY } from "config/localStorage";

function JoinReferralCode({ userReferralCodeString, setPendingTxns, pendingTxns, active, connectWallet, canEdit }) {
  const [isVisible, setIsVisible] = useState(false);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const editModalRef = useRef(null);

  const open = () => setIsEditModalOpen(true);
  const close = () => setIsEditModalOpen(false);

  const { account, library } = useWeb3React();
  const { chainId } = useChainId();

  const { userReferralCode } = useUserReferralCode(library, chainId, account);

  const { codeOwner } = useCodeOwner(library, chainId, account, userReferralCode);

  const { referrerTier: traderTier } = useReferrerTier(library, chainId, codeOwner);

  return (
    <div className="referral-card section-center mt-medium">
      <p className="top-title">Your Active Referral Code</p>
      <h2
        style={{
          fontWeight: 700,
        }}
        className="title"
      >
        {userReferralCodeString ? (
          <div className="active-referral-code">
            <div
              style={{
                justifyContent: "flex-start",
              }}
              className="edit"
            >
              <span>{userReferralCodeString}</span>
              <BiEditAlt onClick={open} />
            </div>
          </div>
        ) : (
          <span>No Code</span>
        )}

        <Modal
          className="Connect-wallet-modal"
          isVisible={isEditModalOpen}
          setIsVisible={close}
          label={`Edit Referral Code`}
          onAfterOpen={() => editModalRef.current?.focus()}
        >
          <div className="edit-referral-modal">
            <ReferralCodeForm
              userReferralCodeString={userReferralCodeString}
              setPendingTxns={setPendingTxns}
              pendingTxns={pendingTxns}
              type="edit"
              callAfterSuccess={() => setIsEditModalOpen(false)}
            />
          </div>
        </Modal>
      </h2>
      <p className="sub-title">
        <span>
          Enter a referral code to receive up to a 5% discount on trading fees. The discount rebates will be airdropped
          to your wallet address.
        </span>
      </p>

      <div className="line" />

      <p className="your-code-rate">Your Discount Rate</p>

      <p
        style={{
          fontWeight: 700,
        }}
        className="your-code-percentage"
      >
        {traderTier ? `${tierDiscountInfo[traderTier]}%` : "0%"}
      </p>

      <p className="your-code-tier">{traderTier ? `Tier ${getTierIdDisplay(traderTier)}` : "Tier 0"}</p>

      <div
        onClick={() => {userReferralCodeString ? setIsEditModalOpen(true) : setIsVisible(true)}}
        className="default-btn"
        style={{ width: "100%", justifyContent: "center", alignItems: "center" }}
      >
        {userReferralCodeString ? "Edit referral code" : "Enter referral code "}
      </div>

      <Modal isVisible={isVisible} setIsVisible={() => setIsVisible(false)} label="Referral Code" allowContentTouchMove>
        <div className="card-action">
          {active ? (
            <ReferralCodeForm setIsVisible={setIsVisible} setPendingTxns={setPendingTxns} pendingTxns={pendingTxns} />
          ) : (
            <button className="App-cta Exchange-swap-button default-btn" type="submit" onClick={connectWallet}>
              <span>Connect Wallet</span>
            </button>
          )}
        </div>
      </Modal>
    </div>
  );
}

export function ReferralCodeForm({
  setIsVisible,
  setPendingTxns,
  pendingTxns,
  callAfterSuccess,
  userReferralCodeString = "",
  type = "join",
}) {
  const { account, library, chainId } = useWeb3React();
  const [referralCode, setReferralCode] = useState("");
  const inputRef = useRef("");
  const [isValidating, setIsValidating] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [referralCodeExists, setReferralCodeExists] = useState(true);
  const debouncedReferralCode = useDebounce(referralCode, 300);

  function getPrimaryText() {
    const isEdit = type === "edit";
    if (isEdit && debouncedReferralCode === userReferralCodeString) {
      return `Same as current active code`;
    }
    if (isEdit && isSubmitting) {
      return `Updating...`;
    }

    if (isSubmitting) {
      return `Adding...`;
    }
    if (debouncedReferralCode === "") {
      return `Enter Referral Code`;
    }
    if (isValidating) {
      return `Checking code...`;
    }
    if (!referralCodeExists) {
      return `Referral Code does not exist`;
    }

    return isEdit ? `Update` : `Submit`;
  }
  function isPrimaryEnabled() {
    if (
      debouncedReferralCode === "" ||
      isSubmitting ||
      isValidating ||
      !referralCodeExists ||
      debouncedReferralCode === userReferralCodeString
    ) {
      return false;
    }
    return true;
  }

  async function handleSubmit(event) {
    const isEdit = type === "edit";
    event.preventDefault();
    setIsSubmitting(true);

    try {
      const tx = await setTraderReferralCodeByUser(chainId, referralCode, library, {
        account,
        successMsg: isEdit ? `Referral code updated!` : `Referral code added!`,
        failMsg: isEdit ? `Referral code updated failed.` : `Adding referral code failed.`,
        setPendingTxns,
        pendingTxns,
      });
      if (callAfterSuccess) {
        const referralCodeHex = encodeReferralCode(referralCode);
        localStorage.setItem(REFERRAL_CODE_KEY, referralCodeHex);
        callAfterSuccess();
      }
      const receipt = await tx.wait();
      if (receipt.status === 1) {
        setReferralCode("");
      }

      setIsVisible(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setIsSubmitting(false);
      setIsValidating(false);
    }
  }

  useEffect(() => {
    let cancelled = false;
    async function checkReferralCode() {
      if (debouncedReferralCode === "" || !REFERRAL_CODE_REGEX.test(debouncedReferralCode)) {
        setIsValidating(false);
        setReferralCodeExists(false);
        return;
      }

      setIsValidating(true);
      const codeExists = await validateReferralCodeExists(debouncedReferralCode, chainId);
      if (!cancelled) {
        setReferralCodeExists(codeExists);
        setIsValidating(false);
      }
    }
    checkReferralCode();
    return () => {
      cancelled = true;
    };
  }, [debouncedReferralCode, chainId]);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <StyledInput
        ref={inputRef}
        disabled={isSubmitting}
        type="text"
        placeholder="Enter referral code"
        className="text-input mb-sm"
        value={referralCode}
        onChange={({ target }) => {
          const { value } = target;
          setReferralCode(value);
        }}
      />
      <button
        type="submit"
        className="default-btn"
        disabled={!isPrimaryEnabled()}
        style={{ width: "100%", justifyContent: "center", alignItems: "center" }}
      >
        {getPrimaryText()}
      </button>
    </form>
  );
}
export default JoinReferralCode;
