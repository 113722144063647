import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import { createContext, useMemo } from "react";
import useSWR from "swr";

import { getServerUrl } from "config/backendV2";
import { useChainId } from "lib/chains";
import { ACTIVE_CHAIN_IDS, ACTIVE_CHAIN_IDS_V2, arrayURLFetcher, getStatsInfoV2, getStatsInfoV3 } from "lib/legacy";
import { BASE, FANTOM, FANTOM_TESTNET, OP, OP_TESTNET } from "config/chains";

const { AddressZero } = ethers.constants;

export const StatsContext = createContext();

const StatsProvider = ({ children }) => {
  const { chainId } = useChainId();
  const { active, library } = useWeb3React();

  // const { data: dataStatsAll } = useSWR(
  //   ACTIVE_CHAIN_IDS.map((chainId) => getServerUrl(chainId, "/app-stats")),
  //   {
  //     fetcher: arrayURLFetcher,
  //     refreshInterval: 10000,
  //   },
  // );
  const { data: dataStatsAll } = useSWR(
    ACTIVE_CHAIN_IDS_V2.map((chainId) => getServerUrl(chainId, "/public/app-stats")),
    {
      fetcher: arrayURLFetcher,
      refreshInterval: 10000,
    }
  );
  // const { gmxPrice } = useGmxPrice(chainId, {}, active);
  // const { gmxPriceFromFantom, gmxPriceFromOP, gmxPriceFromArbitrum } = useGmxPrice(
  //   chainId,
  //   { arbitrum: chainId === ARBITRUM ? library : undefined, op: chainId === OP ? library : undefined },
  //   active
  // );
  const currentStats = getStatsInfoV3(dataStatsAll);
  // console.log("?????", currentStats);
  // const whitelistedTokens = getWhitelistedTokens(chainId);
  // const tokenList = whitelistedTokens.filter((t) => !t.isWrapped);

  // const glpManagerAddress = getContract(chainId, "GlpManager");
  // const glpManagerAddressFANTOM = getContract(FANTOM, "GlpManager");
  // const glpManagerAddressOP = getContract(OP, "GlpManager");
  // const glpManagerAddressARB = getContract(ARBITRUM, "GlpManager");

  // const readerAddress = getContract(chainId, "Reader");
  // const gmxAddress = getContract(chainId, "GMX");
  // const glpAddress = getContract(chainId, "GLP");
  // const usdgAddress = getContract(chainId, "USDG");
  // const glpVesterAddress = getContract(chainId, "GlpVester");
  // const gmxVesterAddress = getContract(chainId, "GmxVester");
  // const tokensForSupplyQuery = [gmxAddress, glpAddress, usdgAddress];

  // const { data: aums } = useSWR([`Dashboard:getAums`, chainId, glpManagerAddress, "getAums"], {
  //   fetcher: contractFetcher(library, GlpManager),
  // });

  // const { infoTokens } = useInfoTokens(library, chainId, active, undefined, undefined);

  // let aum;
  // if (aums && aums.length > 0) {
  //   aum = aums[0].add(aums[1]).div(2);
  // }

  // let adjustedUsdgSupply = bigNumberify(0);

  // for (let i = 0; i < tokenList.length; i++) {
  //   const token = tokenList[i];
  //   const tokenInfo = infoTokens[token.address];
  //   if (tokenInfo && tokenInfo.usdgAmount) {
  //     adjustedUsdgSupply = adjustedUsdgSupply.add(tokenInfo.usdgAmount);
  //   }
  // }

  // const { data: totalSupplies } = useSWR(
  //   [`Dashboard:totalSupplies:${active}`, chainId, readerAddress, "getTokenBalancesWithSupplies", AddressZero],
  //   {
  //     fetcher: contractFetcher(library, ReaderV2, [tokensForSupplyQuery]),
  //     refreshInterval: 5000,
  //   }
  // );

  // const { data: gmxSupplyOfGmxVester } = useSWR(
  //   [`Dashboard:gmxSupplyOfGmxVester:${active}`, chainId, gmxAddress, "balanceOf", gmxVesterAddress],
  //   {
  //     fetcher: contractFetcher(library, Token),
  //     refreshInterval: 5000,
  //   }
  // );

  // const { data: gmxSupplyOfGlpVester } = useSWR(
  //   [`Dashboard:gmxSupplyOfGlpVester:${active}`, chainId, gmxAddress, "balanceOf", glpVesterAddress],
  //   {
  //     fetcher: contractFetcher(library, Token),
  //     refreshInterval: 5000,
  //   }
  // );

  // const {
  //   total: totalGmxStaked,
  //   fantom: fantomGmxStaked,
  //   fantomlegacy: fantomGmxStakedLegacy,
  //   op: opGmxStaked,
  //   oplegacy: opGmxStakedLegacy,
  //   arbitrum: arbitrumStakedGmx,
  // } = useTotalGmxStaked();
  // let currentChainGmxStaked = fantomGmxStaked;
  // if (chainId === OP) currentChainGmxStaked = opGmxStaked;
  // if (chainId === ARBITRUM) currentChainGmxStaked = arbitrumStakedGmx;

  // const { data: aumsOP } = useSWR([`Dashboard:getAumsOP`, OP, glpManagerAddressOP, "getAums"], {
  //   fetcher: contractFetcher(library, GlpManager),
  //   refreshInterval: 5000,
  // });

  // const { data: aumsFANTOM } = useSWR([`Dashboard:getAumsFANTOM`, FANTOM, glpManagerAddressFANTOM, "getAums"], {
  //   fetcher: contractFetcher(library, GlpManager),
  //   refreshInterval: 5000,
  // });
  // const { data: aumsFANTOMlegacy } = useSWR([`Dashboard:getAumsFANTOMlegacy`, FANTOM, "0x304951d7172bCAdA54ccAC1E4674862b3d5b3d5b", "getAums"], {
  //   fetcher: contractFetcher(library, GlpManager),
  //   refreshInterval: 5000,
  // });

  // const { data: aumsArb } = useSWR([`Dashboard:getAumsARB`, ARBITRUM, glpManagerAddressARB, "getAums"], {
  //   fetcher: contractFetcher(library, GlpManager),
  //   refreshInterval: 5000,
  // });

  // let aumOP;
  // if (aumsOP && aumsOP.length > 0) {
  //   aumOP = aumsOP[0].add(aumsOP[1]).div(2);
  // }

  // let aumFANTOM;
  // if (aumsFANTOM && aumsFANTOM.length > 0) {
  //   aumFANTOM = aumsFANTOM[0].add(aumsFANTOM[1]).div(2);
  // }

  // let aumFANTOMlegacy;
  // if (aumsFANTOMlegacy && aumsFANTOMlegacy.length > 0) {
  //   aumFANTOMlegacy = aumsFANTOMlegacy[0].add(aumsFANTOMlegacy[1]).div(2);
  // }

  // let aumArb;
  // if (aumsArb && aumsArb.length > 0) {
  //   aumArb = aumsArb[0].add(aumsArb[1]).div(2);
  // }

  // let glpPrice;
  // let glpSupply;
  // let glpMarketCap;
  // if (aum && totalSupplies && totalSupplies[3]) {
  //   glpSupply = totalSupplies[3];
  //   if (gmxSupplyOfGmxVester && gmxSupplyOfGlpVester) {
  //     glpSupply = glpSupply.sub(gmxSupplyOfGmxVester).sub(gmxSupplyOfGlpVester);
  //   }

  //   glpPrice =
  //     aum && aum.gt(0) && glpSupply.gt(0)
  //       ? aum.mul(expandDecimals(1, GLP_DECIMALS)).div(glpSupply)
  //       : expandDecimals(1, USD_DECIMALS);
  //   glpMarketCap = glpPrice.mul(glpSupply).div(expandDecimals(1, GLP_DECIMALS));
  // }

  // let tvl;
  // if (currentChainGmxStaked && aum && gmxPrice) {
  //   tvl = gmxPrice.mul(currentChainGmxStaked).div(expandDecimals(1, GMX_DECIMALS)).add(aum);
  // }

  // let fantomTvl;
  // if (fantomGmxStaked && aumFANTOM && gmxPriceFromFantom) {
  //   fantomTvl = gmxPriceFromFantom.mul(fantomGmxStaked).div(expandDecimals(1, GMX_DECIMALS)).add(aumFANTOM);
  // }

  // let fantomLegacyTvl;
  // if (fantomGmxStakedLegacy && aumFANTOMlegacy && gmxPriceFromFantom) {
  //   fantomLegacyTvl = gmxPriceFromFantom.mul(fantomGmxStakedLegacy).div(expandDecimals(1, GMX_DECIMALS)).add(aumFANTOMlegacy);
  // }
  // let opTvl;
  // if (opGmxStaked && opGmxStakedLegacy && aumOP && gmxPriceFromOP) {
  //   opTvl = gmxPriceFromOP.mul(opGmxStaked.add(opGmxStakedLegacy)).div(expandDecimals(1, GMX_DECIMALS)).add(aumOP);
  // }

  // let arbTvl;
  // if (arbitrumStakedGmx && aumArb && gmxPriceFromArbitrum) {
  //   arbTvl = gmxPriceFromArbitrum.mul(arbitrumStakedGmx).div(expandDecimals(1, GMX_DECIMALS)).add(aumArb);
  // }
  // let totalTvl;
  // if (glpMarketCap && gmxPrice && totalGmxStaked && aumOP && aumFANTOM && aumArb && fantomTvl && opTvl && arbTvl && fantomLegacyTvl) {
  //   totalTvl = fantomTvl.add(opTvl).add(arbTvl).add(fantomLegacyTvl)
  // }

  const valueProvider = useMemo(() => {
    return {
      // totalVolume: {
      //   total: currentStats.totalVolume,
      //   [FANTOM_TESTNET]: currentStats[FANTOM_TESTNET]?.totalVolume,
      //   [OP_TESTNET]: currentStats[OP_TESTNET]?.totalVolume,
      // },
      // total24hVolume: {
      //   total: currentStats.totalFees,
      //   [FANTOM_TESTNET]: currentStats[FANTOM_TESTNET]?.totalFees,
      //   [OP_TESTNET]: currentStats[OP_TESTNET]?.totalFees,
      // },
      // openInterest: {
      //   total: currentStats.longOpenInterest,
      //   [FANTOM_TESTNET]: currentStats[FANTOM_TESTNET]?.longOpenInterest,
      //   [OP_TESTNET]: currentStats[OP_TESTNET]?.longOpenInterest,
      // },
      trades: {
        total: currentStats?.trades,
        [FANTOM_TESTNET]: currentStats?.[FANTOM_TESTNET]?.data?.trades,
        [OP_TESTNET]: currentStats?.[OP_TESTNET]?.data?.trades,
        [FANTOM]: currentStats?.[FANTOM]?.data?.trades,
        [OP]: currentStats?.[OP]?.data?.trades,
        [BASE]: currentStats?.[BASE]?.data?.trades,
      },
      trades24H: {
        total: currentStats.trades24H,
        [FANTOM_TESTNET]: currentStats?.[FANTOM_TESTNET]?.data?.trades24H,
        [OP_TESTNET]: currentStats?.[OP_TESTNET]?.data?.trades24H,
        [FANTOM]: currentStats?.[FANTOM]?.data?.trades24H,
        [OP]: currentStats?.[OP]?.data?.trades24H,
        [BASE]: currentStats?.[BASE]?.data?.trades24H,
      },
      volume: {
        total: currentStats?.volume,
        [FANTOM_TESTNET]: currentStats?.[FANTOM_TESTNET]?.data?.volume,
        [OP_TESTNET]: currentStats?.[OP_TESTNET]?.data?.volume,
        [FANTOM]: currentStats?.[FANTOM]?.data?.volume,
        [OP]: currentStats?.[OP]?.data?.volume,
        [BASE]: currentStats?.[BASE]?.data?.volume,
      },
      volume24H: {
        total: currentStats.volume24H,
        [FANTOM_TESTNET]: currentStats?.[FANTOM_TESTNET]?.data?.volume24H,
        [OP_TESTNET]: currentStats?.[OP_TESTNET]?.data?.volume24H,
        [FANTOM]: currentStats?.[FANTOM]?.data?.volume24H,
        [OP]: currentStats?.[OP]?.data?.volume24H,
        [BASE]: currentStats?.[BASE]?.data?.volume24H,
      },
      fees: {
        total: currentStats.fees,
        [FANTOM_TESTNET]: currentStats?.[FANTOM_TESTNET]?.data?.fees,
        [OP_TESTNET]: currentStats?.[OP_TESTNET]?.data?.fees,
        [FANTOM]: currentStats?.[FANTOM]?.data?.fees,
        [OP]: currentStats?.[OP]?.data?.fees,
        [BASE]: currentStats?.[BASE]?.data?.fees,
      },
      fees24H: {
        total: currentStats.fees24H,
        [FANTOM_TESTNET]: currentStats?.[FANTOM_TESTNET]?.data?.fees24H,
        [OP_TESTNET]: currentStats?.[OP_TESTNET]?.data?.fees24H,
        [FANTOM]: currentStats?.[FANTOM]?.data?.fees24H,
        [OP]: currentStats?.[OP]?.data?.fees24H,
        [BASE]: currentStats?.[BASE]?.data?.fees24H,
      },
      realizedPnl24H: {
        total: currentStats.realizedPnl24H,
        [FANTOM_TESTNET]: currentStats?.[FANTOM_TESTNET]?.data?.realizedPnl24H,
        [OP_TESTNET]: currentStats?.[OP_TESTNET]?.data?.realizedPnl24H,
        [FANTOM]: currentStats?.[FANTOM]?.data?.realizedPnl24H,
        [OP]: currentStats?.[OP]?.data?.realizedPnl24H,
        [BASE]: currentStats?.[BASE]?.data?.realizedPnl24H,
      },
      openInterest: {
        total: currentStats?.openInterest,
        [FANTOM_TESTNET]: currentStats?.[FANTOM_TESTNET]?.data?.openInterest,
        [OP_TESTNET]: currentStats?.[OP_TESTNET]?.data?.openInterest,
        [FANTOM]: currentStats?.[FANTOM]?.data?.openInterest,
        [OP]: currentStats?.[OP]?.data?.openInterest,
        [BASE]: currentStats?.[BASE]?.data?.openInterest,
      },
      nlpPrice: {
        total: currentStats?.nlpPrice,
        [FANTOM_TESTNET]: currentStats?.[FANTOM_TESTNET]?.data?.nlpPrice,
        [OP_TESTNET]: currentStats?.[OP_TESTNET]?.data?.nlpPrice,
        [FANTOM]: currentStats?.[FANTOM]?.data?.nlpPrice,
        [OP]: currentStats?.[OP]?.data?.nlpPrice,
        [BASE]: currentStats?.[BASE]?.data?.nlpPrice,
      },
      nlpTvl: {
        total: currentStats?.nlpTvl,
        [FANTOM_TESTNET]: currentStats?.[FANTOM_TESTNET]?.data?.nlpTvl,
        [OP_TESTNET]: currentStats?.[OP_TESTNET]?.data?.nlpTvl,
        [FANTOM]: currentStats?.[FANTOM]?.data?.nlpTvl,
        [OP]: currentStats?.[OP]?.data?.nlpTvl,
        [BASE]: currentStats?.[BASE]?.data?.nlpTvl,
      },
      nlpStaked: {
        total: currentStats?.nlpStaked,
        [FANTOM_TESTNET]: currentStats?.[FANTOM_TESTNET]?.data?.nlpStaked,
        [OP_TESTNET]: currentStats?.[OP_TESTNET]?.data?.nlpStaked,
        [FANTOM]: currentStats?.[FANTOM]?.data?.nlpStaked,
        [OP]: currentStats?.[OP]?.data?.nlpStaked,
        [BASE]: currentStats?.[BASE]?.data?.nlpStaked,
      },
      totalUsers: {
        total: currentStats?.users,
        [FANTOM_TESTNET]: currentStats?.[FANTOM_TESTNET]?.data?.users,
        [OP_TESTNET]: currentStats?.[OP_TESTNET]?.data?.users,
        [FANTOM]: currentStats?.[FANTOM]?.data?.users,
        [OP]: currentStats?.[OP]?.data?.users,
        [BASE]: currentStats?.[BASE]?.data?.users,
      },
    };
  }, [currentStats]);
  return <StatsContext.Provider value={valueProvider}>{children}</StatsContext.Provider>;
};

export default StatsProvider;
