const TwitterXIcon = (props: JSX.IntrinsicElements["svg"]) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={25} height={20} viewBox="0 0 32 30" fill="none">
      <path
        d="M4.42883 3.83984L13.5276 17.1048L3.98633 28.1598H6.02008L14.4288 18.4173L21.1113 28.1598H27.5201L17.9988 14.2798L27.0101 3.83984H24.9776L17.0988 12.9673L10.8376 3.83984H4.42883Z"
        fill={props.color ? "#02b27f" : "#fff"}
      />
    </svg>
  );
};

export default TwitterXIcon;
