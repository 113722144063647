const LinkIcon = (props: JSX.IntrinsicElements["svg"]) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={19} height={18} viewBox="0 0 19 18" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.87521 12.6967C4.54977 13.0221 4.54977 13.5498 4.87521 13.8752C5.20065 14.2006 5.72828 14.2006 6.05372 13.8752L12.8807 7.04822L12.8807 12.1074C12.8807 12.5677 13.2538 12.9408 13.714 12.9408C14.1743 12.9408 14.5474 12.5677 14.5474 12.1074L14.5474 5.03638C14.5474 4.81536 14.4596 4.6034 14.3033 4.44712C14.147 4.29084 13.9351 4.20304 13.714 4.20304L6.64298 4.20304C6.18274 4.20304 5.80964 4.57614 5.80964 5.03638C5.80964 5.49661 6.18274 5.86971 6.64298 5.86971L11.7022 5.86971L4.87521 12.6967Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default LinkIcon;
