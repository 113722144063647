import React, { useCallback, useMemo } from "react";
import { Menu } from "@headlessui/react";
import { FaChevronDown } from "react-icons/fa";
import cx from "classnames";
import "./ChartTokenSelector.css";
import { importImage, LONG, SHORT, SWAP } from "lib/legacy";
import { getTokenBySymbol, getTokenSymbol, getTokenV1Adress, getTokens, getWhitelistedTokens } from "config/tokens";
import triangleIcon from "img/triangle.svg";
import { MAX_LERVERAGES } from "pages/BuyGMX/constants";
import { getContract } from "config/contracts";
import useSWR from "swr";
import { contractFetcher } from "lib/contracts";
import { getTokenSymbolFromString } from "domain/tokens";

import { useWeb3React } from "@web3-react/core";
import SelectTokenChart from "./SelectTokenChart";
import { THEME_KEY } from "config/localStorage";
import { useHistory } from "react-router-dom";
import { ethers } from "ethers";
import { getConstant } from "config/chains";
import { useLocalStorageByChainId } from "lib/localStorage";
export default function ChartTokenSelector(props) {
  const {
    chainId,
    selectedToken,
    onSelectToken,
    swapOption,
    marketAsssetList,
    setDefaultChartToken,
    defaultChartToken,
    theme
  } = props;

  const isLong = swapOption === LONG;
  const isShort = swapOption === SHORT;
  const isSwap = swapOption === SWAP;
  const { active, library } = useWeb3React();
  const history = useHistory();
  let options = getTokens(chainId);
  const { AddressZero } = ethers.constants;
  const defaultCollateralSymbol = getConstant(chainId, "defaultCollateralSymbol");
  const defaultTokenSelection = useMemo(
    () => ({
      [LONG]: {
        from: AddressZero,
        to: AddressZero,
      },
      [SHORT]: {
        from: getTokenBySymbol(chainId, defaultCollateralSymbol).address,
        to: AddressZero,
      },
    }),
    [chainId, defaultCollateralSymbol]
  );

  const [tokenSelection, setTokenSelection] = useLocalStorageByChainId(
    chainId,
    "Exchange-token-selection-v3",
    defaultTokenSelection
  );
  const setToTokenAddress = useCallback(
    (selectedSwapOption, address) => {
      const newTokenSelection = JSON.parse(JSON.stringify(tokenSelection));
      newTokenSelection[selectedSwapOption].to = address;
      if (selectedSwapOption === LONG || selectedSwapOption === SHORT) {
        newTokenSelection[LONG].to = address;
        newTokenSelection[SHORT].to = address;
      }
      setTokenSelection(newTokenSelection);
    },
    [tokenSelection, setTokenSelection]
  );
  const onSelect = async (token) => {
    if(token.isV2) {
      setDefaultChartToken(token.symbol)
    }
    if(token.isV1) {
      const v1Token = options.find(x => x.symbol === token.tokenSymbol)
      setToTokenAddress(LONG, v1Token?.address);
    }
    if(token.isV1 && !token.isV2) {
      history.push("/v1")
    }
  };

  const currentMarket = marketAsssetList
    ? marketAsssetList?.find((x) => x.symbol === defaultChartToken)
    : null;
  const tokenImage = importImage(`ic_${getTokenSymbolFromString(defaultChartToken)?.toLowerCase()}_24.svg`);
  return (
    <Menu>
      <Menu.Button as="div" disabled={isSwap}>
        <button
          className={cx("App-cta small transparent chart-token-selector", {
            "default-cursor": isSwap,
          })}
        >
          <div className="max-leverage-container">
            <img alt="" src={tokenImage} className="token-selected" />
            <span className="chart-token-selector--current">{defaultChartToken}</span>

            <img alt="" src={triangleIcon} className="arrow-chart" />
          </div>
        </button>
      </Menu.Button>
      <div className="chart-token-menu">
        <Menu.Items as="div" className="menu-items chart-token-menu-items">
          <SelectTokenChart
            tokens={marketAsssetList}
            onSelect={onSelect}
            current={defaultChartToken}
            theme={theme}
          />
        </Menu.Items>
      </div>
    </Menu>
  );
}
