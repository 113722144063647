import ExternalLink from "components/ExternalLink/ExternalLink";
import { useThemeContext } from "contexts/ThemeProvider";
import React from "react";
import styled from "styled-components";

export default function NewCollection() {
  const { lightThemeClassName } = useThemeContext() as any;
  const list = [
    {
      title: "All revenue is staked to earn fees",
      desc: "50% of the revenue will be used to boost the MLP v2, while the remaining amount is reinvested to earn more fees.",
    },
    {
      title: "Share 10% of protocol fees",
      desc: "Earn an additional share of 10% fee in the first year (adjusted annually via governance vote).",
    },
    {
      title: "Earn esMMY bonuses",
      desc: "Receive esMMY rewards from the Treasury, Vault, and DEV Fund.",
    },
    {
      title: "Join the governance vote",
      desc: "The compulsory ticket to participate in the governance vote and help decide the future of Mummy Finance.",
    },
  ];

  return (
    <Wrapper className={`container ${lightThemeClassName}`}>
      <Title>The exclusive privileges of the new collection</Title>
      <ListWrap>
        {list.map((item) => (
          <Item key={item.title}>
            <div className="box-title">{item.title}</div>
            <div className="box-desc">{item.desc}</div>
          </Item>
        ))}
      </ListWrap>
      <ExternalLink href="https://docs.mummy.finance/mummy-rebirth-nft-on-fantom" className="border-btn collection-btn">
        {" "}
        Learn more
      </ExternalLink>
    </Wrapper>
  );
}
const ListWrap = styled.div`
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(4, 1fr);

  @media screen and (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 700px) {
    grid-template-columns: 1fr;
    gap: 16px;
  }
`;
const Title = styled.div`
  font-size: 36px;
  text-align: center;
  font-weight: 700;
  line-height: 140%;
  margin-bottom: 24px;

  @media screen and (max-width: 700px) {
    font-size: 22px;
  }
`;
const Item = styled.div`
  transition: 0.3s;
  &:hover {
    transform: scale(1.01);
  }
  border-radius: 16px;
  background: var(--v-3-natural-1, #191b2e);
  padding: 24px;
  min-height: 180px;
  .box-title {
    color: var(--main-primary, #03f5ae);

    font-size: 14px;

    font-weight: 700;
    line-height: 140%;
    margin-bottom: 12px;
  }

  .box-desc {
    font-size: 14px;
    color: var(--white-60, rgba(255, 255, 255, 0.6));
    font-weight: 400;
    line-height: 140%;
  }

  @media screen and (max-width: 700px) {
    padding: 16px;
    min-height: unset;

    .box-title {
      margin-bottom: 8px;
    }
  }
`;

const Wrapper = styled.div`
  .collection-btn {
    padding: 8px 16px;
    margin: 24px auto 120px auto;
    text-decoration: none;
    display: block;
    width: fit-content;
  }

  @media screen and (max-width: 991px) {
    .collection-btn {
      margin-bottom: 72px;
    }
  }
  @media screen and (max-width: 700px) {
    .collection-btn {
      margin-bottom: 48px;
    }
  }

  &.theme--light {
    ${Item} {
      background: #fff;
      border: 1px solid var(--Border, rgba(13, 26, 22, 0.07));

      .box-title {
        color: var(--Primary, #02b27f);
      }

      .box-desc {
        color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7));
      }
    }
  }
`;
