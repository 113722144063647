import React from "react";
import { toast, ToastContent, ToastOptions } from "react-toastify";
import ToastSuccessIcon from "components/Icon/ToastSuccessIcon";
import ToastErrorIcon from "components/Icon/ToastErrorIcon";
import ToastInfoIcon from "components/Icon/ToastInfoIcon";
import ToastWarningIcon from "components/Icon/ToastWarningIcon";

export const helperToast = {
  success: (content: ToastContent, opts?: ToastOptions) => {

    toast.dismiss();
    toast.success(() => {
      return (
        <div className="toastify-container">
          <ToastSuccessIcon className="status-icon-wrapper success" />
          <div className="toastify-group">
            <span>Success</span>
            <div className="toastify-content-wrapper">
              {content as React.ReactNode}
            </div>
          </div>
        </div>
      )
    },
      opts);
  },
  error: (content: ToastContent, opts?: ToastOptions) => {
    toast.dismiss();
    toast.error(
      <div className="toastify-container">
        <ToastErrorIcon className="status-icon-wrapper error" />
        <div className="toastify-group">
          <span>Error</span>
          <div className="toastify-content-wrapper">
            {content as React.ReactNode}
          </div>
        </div>
      </div>,
      opts);
  },
  warn: (content: ToastContent, opts?: ToastOptions) => {
    toast.dismiss();
    toast.warn(
      <div className="toastify-container">
        <ToastWarningIcon className="status-icon-wrapper warn" />
        <div className="toastify-group">
          <span>Warning</span>
          <div className="toastify-content-wrapper">
            {content as React.ReactNode}
          </div>
        </div>
      </div>,
      opts);
  },
  info: (content: ToastContent, opts?: ToastOptions) => {
    toast.dismiss();
    toast.info(
      <div className="toastify-container">
        <ToastInfoIcon className="status-icon-wrapper info" />
        <div className="toastify-group">
          <span>Info</span>
          <div className="toastify-content-wrapper">
            {content as React.ReactNode}
          </div>
        </div>
      </div>,
      opts);
  },
};
