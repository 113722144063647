import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
import cx from "classnames";
import styled from "styled-components";
import { USD_DECIMALS, SWAP, CHART_PERIODS, importImage, CHART_MICROSWAP_PERIODS } from "lib/legacy";
import ChartPlaceholder from 'img/chart-placeholder.png';
import { useLocalStorageSerializeKey } from "lib/localStorage";
import { formatAmount, getDifferentValues } from "lib/numbers";
import { getTokens } from "config/tokens";
import { useMedia } from "react-use";
import SmallTextNumber from "components/Tooltip/SmallTextNumber";
import { getLocalTokenIcon, getLogoUrlByChainId } from "config/aggregators/tokens";
import { onLoadFailed } from "domain/tokens";
import { useDebounce } from "lib/useDebounce";
import useLiveChartData from "hooks/aggregator/useLiveChartData";
import Tab from "components/Tab/Tab";
import LineChart from "./LineChart";

export function getChartToken(swapOption, fromToken, toToken, chainId) {
  if (!fromToken || !toToken) {
    return;
  }

  if (swapOption !== SWAP) {
    return toToken;
  }

  if (fromToken.isUsdg && toToken.isUsdg) {
    return getTokens(chainId).find((t) => t.isStable);
  }
  if (fromToken.isUsdg) {
    return toToken;
  }
  if (toToken.isUsdg) {
    return fromToken;
  }

  if (fromToken.isStable && toToken.isStable) {
    return toToken;
  }
  if (fromToken.isStable) {
    return toToken;
  }
  if (toToken.isStable) {
    return fromToken;
  }

  return toToken;
}

const DEFAULT_PERIOD = "1H";

export default function ExchangeTVChartPadSwapAggregator(props) {
  const { isProChart, chainId, fromToken, toToken } = props;
  const fromTokenImg = getLocalTokenIcon(fromToken.symbol) || getLogoUrlByChainId(chainId, fromToken.address, true);
  const toTokenImg = getLocalTokenIcon(toToken.symbol) || getLogoUrlByChainId(chainId, toToken.address, true);
  let [period, setPeriod] = useLocalStorageSerializeKey([chainId, "Chart-microswap-aggregator-period"], DEFAULT_PERIOD);
  const tokens = useMemo(() => {
    return fromToken && toToken ? [fromToken, toToken] : [];
  }, [fromToken, toToken]);
  const [hoverValue, setHoverValue] = useState(null);
  const debouncedTokens = useDebounce(tokens, 200);
  const isMobile = useMedia("(max-width: 700px)");
  const {
    data: chartData,
    error: basicChartError,
    loading: basicChartLoading,
  } = useLiveChartData(debouncedTokens, period, chainId);
  const showingValue = useMemo(
    () => hoverValue ?? (chartData[chartData.length - 1]?.value || 0),
    [hoverValue, chartData]
  );
  if (!(period in CHART_MICROSWAP_PERIODS)) {
    period = DEFAULT_PERIOD;
  }
  const isPad = useMedia("(max-width: 1350px)");

  useEffect(() => {
    if (hoverValue !== null) {
      setHoverValue(null);
    }
  }, [chartData]);
  const { different, differentPercent } = getDifferentValues(chartData, hoverValue);
  return (
    <div
      style={{
        height: isMobile ? '30rem' : '40rem',
        borderRadius: isPad ? '0px' : '16px'
      }}
      className="ExchangeChart tv"
    >
      <div className="ExchangeChart-top App-box App-box-border" style={{ padding: "16px" }}>
        <div className="swap-token-selected-wrapper">
          <div className="icon-token-group">
            <img src={fromTokenImg} className="icon-from" onError={onLoadFailed} style={{ borderRadius: 100 }} />
            <img src={toTokenImg} className="icon-to" onError={onLoadFailed} style={{ borderRadius: 100 }} />
          </div>
          {/* {data} */}
          <div className="info-token-group">
            <div className="price-token-group">
              <div className="price-value">
                {showingValue === 0 || basicChartError ? '--' : <SmallTextNumber decimal={4} val={showingValue} />}

              </div>
              <div className="symbol-value">{fromToken.symbol + "/" + toToken.symbol}</div>
            </div>
            <div
              className={cx("apr-token-group", {
                positive: different >= 0,
                negative: different < 0,
                deltaChange: different !== 0,
              })}
            >
              <span style={{ marginRight: "4px" }}>
                {`${showingValue === 0 || basicChartError ? '--' : different}`}
              </span>
              {`(${showingValue === 0 || basicChartError ? '--' : differentPercent}%)`}
            </div>
          </div>
        </div>
      </div>
      <div style={{
        display: isProChart ? 'none' : 'unset'
      }} className="ExchangeChart-bottom App-box App-box-border">
        <div className="ExchangeChart-bottom-controls">
          <Tab options={Object.keys(CHART_MICROSWAP_PERIODS)} option={period} setOption={setPeriod} />
        </div>
        <div className="ExchangeChart-bottom-content">
          {basicChartLoading || basicChartError ?
            <WrappedChartUnavailable
              className="live-chart-wrapper"
            >
              {/* {basicChartLoading && <LoadingSpinner color={'inherit'} />} */}
              {basicChartError && (
                <>
                  {/*<IconWarningTriangle />*/}
                  <div style={{ marginTop: '-20%', color: '#999A9E' }}>
                    {'Chart is unavailable'}
                  </div>
                </>
              )}
            </WrappedChartUnavailable>
            : <LineChart
              data={chartData}
              setHoverValue={setHoverValue}
              color={'#03F5AE'}
              timeFrame={period}
              minHeight={320}
            />}

        </div>
      </div>
    </div>
  );
}
const WrappedChartUnavailable = styled.div`
  background: url('${ChartPlaceholder}') bottom center no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  min-height: calc(100%);
  background-size: 100% auto;
`;
