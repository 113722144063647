import { ethers } from "ethers";

import { getContract } from "config/contracts";
import { PLACEHOLDER_ACCOUNT } from "lib/legacy";

import MMYFarm from "abis/MMYFarm.json";

import Multicall from "abis/Multicall.json";
import multicall from "domain/multicall";
import { useEffect, useState } from "react";
import { getProvider } from "lib/rpc";

export default function useMMYFarmContractMulticall(
  chainId: number,
  active: boolean,
  account: string | undefined | null,
  library
): any {
  const [data, setData] = useState({});
  const tokenFarmAddress = getContract(chainId, "MMYFarm");
  const multicallAddress = getContract(chainId, "Multicall");

  useEffect(() => {
    const fetchNoAccount = async () => {
      if (!account) {
        const provider = getProvider(undefined, chainId);
        const multicallContract = new ethers.Contract(multicallAddress, Multicall.abi, provider);
        const calls = [
          {
            address: tokenFarmAddress,
            name: "getStakedMLP",
            params: [PLACEHOLDER_ACCOUNT],
          },
          {
            address: tokenFarmAddress,
            name: "pendingTokens",
            params: [PLACEHOLDER_ACCOUNT],
          },
          {
            address: tokenFarmAddress,
            name: "mlpPoolInfo",
            params: [],
          },
          {
            address: tokenFarmAddress,
            name: "poolRewardsPerSec",
            params: [],
          },
          {
            address: tokenFarmAddress,
            name: "vestingDuration",
            params: [],
          },
          {
            address: tokenFarmAddress,
            name: "cooldownDuration",
            params: [],
          },
          {
            address: tokenFarmAddress,
            name: "getUnlockedVestingAmount",
            params: [PLACEHOLDER_ACCOUNT],
          },
          {
            address: tokenFarmAddress,
            name: "lockedVestingAmounts",
            params: [PLACEHOLDER_ACCOUNT],
          },
          {
            address: tokenFarmAddress,
            name: "getTotalVested",
            params: [PLACEHOLDER_ACCOUNT],
          },
          {
            address: tokenFarmAddress,
            name: "claimable",
            params: [PLACEHOLDER_ACCOUNT],
          },
          {
            address: tokenFarmAddress,
            name: "poolRewarders",
            params: [],
          },
        ];

        const keyOfCalls = calls.map((call) => call.name);

        const response = await multicall(
          multicallContract,
          MMYFarm.abi,
          calls.map(({ address, name, params }) => ({
            address,
            name,
            params,
          }))
        );
        const result = {};
        for (const index in keyOfCalls) {
          result[keyOfCalls[index]] = response[index];
        }

        setData((prev) => ({ ...prev, ...result }));
      }
    };
    const fetch = async () => {
      try {
        if (account) {
          const provider = getProvider(undefined, chainId);
          const multicallContract = new ethers.Contract(multicallAddress, Multicall.abi, provider);
          const calls = [
            {
              address: tokenFarmAddress,
              name: "getStakedMLP",
              params: [account],
            },
            {
              address: tokenFarmAddress,
              name: "pendingTokens",
              params: [account],
            },
            {
              address: tokenFarmAddress,
              name: "mlpPoolInfo",
              params: [],
            },
            {
              address: tokenFarmAddress,
              name: "poolRewardsPerSec",
              params: [],
            },
            {
              address: tokenFarmAddress,
              name: "vestingDuration",
              params: [],
            },
            {
              address: tokenFarmAddress,
              name: "cooldownDuration",
              params: [],
            },
            {
              address: tokenFarmAddress,
              name: "getUnlockedVestingAmount",
              params: [account],
            },
            {
              address: tokenFarmAddress,
              name: "lockedVestingAmounts",
              params: [account],
            },
            {
              address: tokenFarmAddress,
              name: "getTotalVested",
              params: [account],
            },
            {
              address: tokenFarmAddress,
              name: "claimable",
              params: [account],
            },
            {
              address: tokenFarmAddress,
              name: "poolRewarders",
              params: [],
            },
          ];

          const keyOfCalls = calls.map((call) => call.name);

          const response = await multicall(
            multicallContract,
            MMYFarm.abi,
            calls.map(({ address, name, params }) => ({
              address,
              name,
              params,
            }))
          );
          const result = {};
          for (const index in keyOfCalls) {
            result[keyOfCalls[index]] = response[index];
          }
          setData((prev) => ({ ...prev, ...result }));
        }
      } catch (error) {
        console.error("[ERROR]: useMMYFarmContractMulticall", error);
      }
    };

    fetchNoAccount();
    fetch();
    const timerId = setInterval(() => {
      fetchNoAccount();
      fetch();
    }, 10000);

    return () => {
      clearInterval(timerId);
    };
  }, [account, chainId, library, multicallAddress, tokenFarmAddress]);

  return {
    stakedMlp: data["getStakedMLP"] || [],
    pendingTokens: data["pendingTokens"] || [[], [], [], []],
    mlpPoolInfo: data["mlpPoolInfo"] || [],
    poolRewardsPerSec: data["poolRewardsPerSec"] || [[], [], [], []],
    vestingDuration: data["vestingDuration"] || [],
    cooldownDuration: data["cooldownDuration"] || [],
    getUnlockedVestingAmount: data["getUnlockedVestingAmount"] || [],
    lockedVestingAmounts: data["lockedVestingAmounts"] || [],
    getTotalVested: data["getTotalVested"] || [],
    claimable: data["claimable"] || [],
    poolRewarders: data["poolRewarders"] || [[]],
  };
}
