import React, { memo } from "react";

const Icon1 = (props) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={props.class}
  >
    <path
      d="M32.1199 15.4546C31.2835 15.4546 30.6048 16.1334 30.6048 16.9697V36.6667H36.6654V16.9697C36.6654 16.1334 35.9866 15.4546 35.1502 15.4546H32.1199ZM13.9381 18.4849C13.1017 18.4849 12.4229 19.1637 12.4229 20V36.6667H18.4835V20C18.4835 19.1637 17.8048 18.4849 16.9684 18.4849H13.9381ZM23.029 21.5152C22.1926 21.5152 21.5139 22.194 21.5139 23.0303V36.6667H27.5745V23.0303C27.5745 22.194 26.8957 21.5152 26.0593 21.5152H23.029ZM4.84718 24.5455C4.01082 24.5455 3.33203 25.2243 3.33203 26.0607V36.6667H9.39264V26.0607C9.39264 25.2243 8.71385 24.5455 7.87749 24.5455H4.84718Z"
      fill={props.fill ?? "white"}
    />
    <path
      d="M33.6351 3.33325C32.8314 3.33325 32.0606 3.65251 31.4923 4.22081C30.924 4.7891 30.6048 5.55987 30.6048 6.36355C30.6049 6.53614 30.6197 6.70841 30.6491 6.87847L25.5858 11.0984C25.2524 10.9746 24.8998 10.9105 24.5442 10.909C23.9505 10.9096 23.3702 11.0845 22.8751 11.4121L18.4747 9.21038C18.428 8.44045 18.0894 7.7173 17.528 7.18833C16.9666 6.65936 16.2246 6.3644 15.4532 6.36355C14.6496 6.36355 13.8788 6.68282 13.3105 7.25111C12.7422 7.8194 12.4229 8.59017 12.4229 9.39386C12.4238 9.45904 12.4268 9.52418 12.4318 9.58917L7.70289 12.7408C7.28627 12.5337 6.82757 12.4254 6.36233 12.4242C5.55865 12.4242 4.78788 12.7434 4.21959 13.3117C3.65129 13.88 3.33203 14.6508 3.33203 15.4545C3.33203 16.2582 3.65129 17.0289 4.21959 17.5972C4.78788 18.1655 5.55865 18.4848 6.36233 18.4848C7.16602 18.4848 7.93679 18.1655 8.50508 17.5972C9.07337 17.0289 9.39264 16.2582 9.39264 15.4545C9.39172 15.3903 9.38876 15.3261 9.38376 15.2621L14.1127 12.1075C14.5293 12.3146 14.988 12.4229 15.4532 12.4242C16.0469 12.4236 16.6272 12.2486 17.1223 11.9211L21.5227 14.1228C21.5694 14.8927 21.908 15.6159 22.4694 16.1448C23.0308 16.6738 23.7728 16.9688 24.5442 16.9696C25.3478 16.9696 26.1186 16.6504 26.6869 16.0821C27.2552 15.5138 27.5745 14.743 27.5745 13.9393C27.5744 13.7667 27.5595 13.5945 27.5301 13.4244L32.5934 9.20446C32.9268 9.32823 33.2794 9.39234 33.6351 9.39386C34.4387 9.39386 35.2095 9.07459 35.7778 8.5063C36.3461 7.93801 36.6654 7.16724 36.6654 6.36355C36.6654 5.55987 36.3461 4.7891 35.7778 4.22081C35.2095 3.65251 34.4387 3.33325 33.6351 3.33325Z"
      fill="#296eff"
    />
  </svg>
);
export default memo(Icon1);
