import React, { useState, useCallback, useRef } from "react";
import { ethers } from "ethers";

import MMYFarm from "abis/MMYFarm.json";

import "./Stakes.scss";
import "./StakesTheme.scss";

import mmy_ic from "img/ic_mmy_24.svg";

import Modal from "components/Modal/Modal";
import TokenInput from "components/TokenInput/TokenInput";
import { callContract } from "lib/contracts";
import { getContract } from "config/contracts";
import { formatAmount, parseValue } from "lib/numbers";
import useValidateAction from "hooks/useValidateAction";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import AmountAndDollar from "components/AmountAndDollar/AmountAndDollar";
import { DEFAULT_CHAIN_ID, FANTOM } from "config/chains";
import { useThemeContext } from "contexts/ThemeProvider";
import { isActiveVmmyV2 } from "lib/legacy";

export const MslpUnstakeModal = ({
  isVisible,
  setIsVisible,
  setPendingTxns,
  chainId,
  library,
  amountStaked,
  nlpDecimal,
}) => {
  // const nlpAddress = getContract(chainId, "MSLP");
  const { lightThemeClassName } = useThemeContext() as any;
  const BootstrapFarmAddress = getContract(chainId, "MMYFarm");

  const [unstakeInputValue, setUnstakeInputValue] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  // const { approve, getButtonText, isButtonEnabled, isNeedApproval } = useValidateAction({
  //   tokenAddress: nlpAddress,
  //   spender: tokenFarmAddress,
  //   fromValue: unstakeInputValue,
  //   maxAmount: amountStaked,
  //   isSubmitting,
  //   actionText: ["Unstake", "Unstaking"],
  //   fromValueDecimal: nlpDecimal,
  // });

  /**
   * Actions:
   * - unstake
   * - handleMaxClick
   * - onInputChange
   */

  const unstake = useCallback(async () => {
    // if (isNeedApproval) {
    //   approve();
    //   return;
    // }
    setIsSubmitting(true);

    const contract = new ethers.Contract(BootstrapFarmAddress, MMYFarm.abi, library.getSigner());
    callContract(chainId, contract, "withdrawMlp", [parseValue(unstakeInputValue, nlpDecimal)], {
      sentMsg: `Unstake submitted!`,
      failMsg: `Unstake failed.`,
      successMsg: `Unstaked successfully!`,
      setPendingTxns,
    })
      .then(async (res) => {})
      .finally(() => {
        setIsSubmitting(false);
        setUnstakeInputValue("");
        setIsVisible(false);
      });
  }, [
    // isNeedApproval,
    BootstrapFarmAddress,
    library,
    chainId,
    unstakeInputValue,
    nlpDecimal,
    setPendingTxns,
    // approve,
    setIsVisible,
  ]);

  const onInputChange = (e) => {
    setUnstakeInputValue(e.target.value);
  };

  return (
    <Modal
      className={`medium-modal Bootstrap-Modal hasDivider ${lightThemeClassName}`}
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      label="Unstake MSLP"
    >
      <TokenInput
        autoFocus
        value={unstakeInputValue}
        setValue={setUnstakeInputValue}
        onChange={onInputChange}
        leftLabel="Unstake"
        rightLabel={`Max: ${formatAmount(amountStaked, nlpDecimal, 2, true)}`}
        defaultTokenSymbol="MSLP"
        withPercentSelect
        balance={amountStaked}
        tokenDecimal={nlpDecimal}
        getBalanceOnClickRightLabel
        style={{ marginTop: 0, backgroundColor: "#0E0E0E" }}
      />
      {/* <button
        onClick={unstake}
        disabled={!isButtonEnabled()}
        className="default-btn full"
        style={{ marginTop: 16 }}
      >
        {getButtonText()}
      </button> */}
      <div className="content-box-rows">
        <div className="content-box-row">
          <div className="content-box-row-label">Total Amount Staked</div>
          <div className="content-box-row-value">{formatAmount(amountStaked, nlpDecimal, 2)} MSLP</div>
        </div>
      </div>
      <button
        disabled={
          isSubmitting ||
          !unstakeInputValue ||
          !Number(unstakeInputValue) ||
          (amountStaked && parseValue(unstakeInputValue, nlpDecimal)?.gt(amountStaked))
        }
        onClick={unstake}
        className="default-btn full"
        style={{ marginTop: 24 }}
      >
        {isSubmitting ? "Unstaking..." : "Unstake"}
      </button>
    </Modal>
  );
};

export const MslpStakeModal = ({
  isVisible,
  setIsVisible,
  setPendingTxns,
  chainId,
  library,
  balanceOf,
  nlpDecimal,
  cooldownDuration,
  // isBootstrapPhase,
  // endTimeBootstrapPhase,
  amountStaked,
}) => {
  const { lightThemeClassName } = useThemeContext() as any;
  const nlpAddress = getContract(chainId, "MSLP");
  const MMYFarmAddress = getContract(chainId, "MMYFarm");

  const [stakeInputValue, setStakeInputValue] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const now = moment.utc().valueOf() / 1000;

  const { approve, getButtonText, isButtonEnabled, isNeedApproval } = useValidateAction({
    tokenAddress: nlpAddress,
    spender: MMYFarmAddress,
    fromValue: stakeInputValue,
    maxAmount: balanceOf,
    isSubmitting,
    actionText: ["Confirm & Stake", "Staking..."],
    fromValueDecimal: nlpDecimal,
  });

  /**
   * Actions:
   * - stake
   * - handleMaxClick
   * - onInputChange
   */

  const stake = useCallback(async () => {
    if (isNeedApproval) {
      approve();
      return;
    }
    setIsSubmitting(true);

    const contract = new ethers.Contract(MMYFarmAddress, MMYFarm.abi, library.getSigner());
    callContract(chainId, contract, "depositMlp", [parseValue(stakeInputValue, nlpDecimal)], {
      sentMsg: `Stake submitted!`,
      failMsg: `Stake failed.`,
      successMsg: `Staked successfully!`,
      setPendingTxns,
    })
      .then(async (res) => {})
      .finally(() => {
        setIsSubmitting(false);
        setStakeInputValue("");
        setIsVisible(false);
      });
  }, [
    approve,
    chainId,
    isNeedApproval,
    library,
    setIsVisible,
    setPendingTxns,
    stakeInputValue,
    MMYFarmAddress,
    nlpDecimal,
  ]);

  const onInputChange = (e) => {
    setStakeInputValue(e.target.value);
  };

  if (!isVisible) return null;

  const endLockTimestamp = now + Number(cooldownDuration);

  return (
    <Modal
      className={`medium-modal Bootstrap-Modal hasDivider ${lightThemeClassName}`}
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      // label={isBootstrapPhase ? "Stake NLP Bootstrap" : "Stake NLP"}
      label="Stake MSLP"
    >
      <TokenInput
        autoFocus
        value={stakeInputValue}
        setValue={setStakeInputValue}
        onChange={onInputChange}
        leftLabel="Stake"
        rightLabel={`Max: ${formatAmount(balanceOf, nlpDecimal, 2, true)}`}
        defaultTokenSymbol="MSLP"
        withPercentSelect
        balance={balanceOf}
        tokenDecimal={nlpDecimal}
        getBalanceOnClickRightLabel
        style={{ marginTop: 0, backgroundColor: "#0E0E0E" }}
      />
      {/* {now.current < Number(endTimeBootstrapPhase) + Number(cooldownDuration) ? (
        <div className="warning">
          After staking, your NLP will be locked until{" "}
          {moment((Number(endTimeBootstrapPhase) + Number(cooldownDuration)) * 1000).format("YYYY-MM-DD HH:mm:ss")}.
        </div>
      ) : (
        <div className="warning">
          After staking, your NLP will be locked for {Number(cooldownDuration) / 60} minutes, and the timelock will be
          reset after each stake.
        </div>
      )} */}
      <div className="content-box-rows">
        <div className="content-box-row">
          <div className="content-box-row-label">Total Amount Staked</div>
          <div className="content-box-row-value">{formatAmount(amountStaked, nlpDecimal, 2)} MSLP</div>
        </div>
        <div className="content-box-row">
          <div className="content-box-row-label">Unlock at</div>
          <div className="content-box-row-value">
            {moment(endLockTimestamp * 1000).format("HH:mm, ddd, MMM DD YYYY")}
          </div>
        </div>
      </div>
      <button
        onClick={stake}
        disabled={!isButtonEnabled()}
        className="default-btn full"
        style={{ marginTop: 24, fontWeight: 700 }}
      >
        {getButtonText()}
      </button>
    </Modal>
  );
};

export const DepositvMMYModal = ({
  isVisible,
  setIsVisible,
  setPendingTxns,
  chainId,
  library,
  balanceOf,
  decimal,
  dailyAvgReward,
  totalVested,
  isVestFinished,
}) => {
  const { lightThemeClassName } = useThemeContext() as any;
  const vMMYAddress = getContract(isActiveVmmyV2(chainId) ? chainId : DEFAULT_CHAIN_ID, "vMMY");
  const MMYFarmAddress = getContract(chainId, "MMYFarm");

  const [depositInputValue, setDepositInputValue] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { approve, getButtonText, isButtonEnabled, isNeedApproval } = useValidateAction({
    tokenAddress: vMMYAddress,
    spender: MMYFarmAddress,
    fromValue: depositInputValue,
    maxAmount: balanceOf,
    isSubmitting,
    actionText: ["Deposit", "Depositing..."],
    fromValueDecimal: decimal,
  });

  /**
   * Actions:
   * - deposit
   * - handleMaxClick
   * - onInputChange
   */

  const deposit = useCallback(async () => {
    if (isNeedApproval) {
      approve();
      return;
    }
    setIsSubmitting(true);

    const contract = new ethers.Contract(MMYFarmAddress, MMYFarm.abi, library.getSigner());
    callContract(chainId, contract, "depositVesting", [parseValue(depositInputValue, decimal)], {
      sentMsg: `Deposit submitted!`,
      failMsg: `Deposit failed.`,
      successMsg: `Deposited successfully!`,
      setPendingTxns,
    })
      .then(async (res) => {})
      .finally(() => {
        setIsSubmitting(false);
        setDepositInputValue("");
        setIsVisible(false);
      });
  }, [
    approve,
    chainId,
    isNeedApproval,
    library,
    setIsVisible,
    setPendingTxns,
    depositInputValue,
    MMYFarmAddress,
    decimal,
  ]);

  const onInputChange = (e) => {
    setDepositInputValue(e.target.value);
  };

  if (!isVisible) return null;

  return (
    <Modal
      className={`medium-modal Bootstrap-Modal hasDivider ${lightThemeClassName}`}
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      label="Deposit"
    >
      <TokenInput
        autoFocus
        value={depositInputValue}
        setValue={setDepositInputValue}
        onChange={onInputChange}
        leftLabel="Amount"
        rightLabel={`Balance: ${formatAmount(balanceOf, decimal, 2, true)}`}
        defaultTokenSymbol="vMMY"
        withPercentSelect
        balance={balanceOf}
        tokenDecimal={decimal}
        getBalanceOnClickRightLabel
        tokenLogo={mmy_ic}
        style={{ marginTop: 0, backgroundColor: "#0E0E0E" }}
      />
      <div className="content-box-rows">
        <div className="content-box-row">
          <div className="content-box-row-label">Current vesting amount</div>
          <div className="content-box-row-value">{isVestFinished ? "0.00" : formatAmount(totalVested, 18, 2)} vMMY</div>
        </div>
        <div className="content-box-row">
          <div className="content-box-row-label">Daily Avg. Reward</div>
          <div className="content-box-row-value">
            {isVestFinished ? "0.00" : formatAmount(dailyAvgReward, 18, 2)} MMY
          </div>
        </div>
      </div>
      <div className="text">
        Your MSLP lock period (if staking) and vMMY vesting duration will reset each time you deposit more vMMY.
      </div>
      <button
        onClick={deposit}
        disabled={!isButtonEnabled()}
        className="default-btn full"
        style={{ marginTop: 24, fontWeight: 700 }}
      >
        {getButtonText()}
      </button>
    </Modal>
  );
};

export const WithdrawVMMYModal = ({
  isVisible,
  setIsVisible,
  setPendingTxns,
  chainId,
  library,
  willWithdraw,
  willWithdrawUsd,
}) => {
  const { lightThemeClassName } = useThemeContext() as any;
  const MMYFarmAddress = getContract(chainId, "MMYFarm");

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  /**
   * Actions:
   * - withdraw
   */

  const withdraw = useCallback(async () => {
    setIsSubmitting(true);

    const contract = new ethers.Contract(MMYFarmAddress, MMYFarm.abi, library.getSigner());
    callContract(chainId, contract, "withdrawVesting", [], {
      sentMsg: `Withdraw submitted!`,
      failMsg: `Withdraw failed.`,
      successMsg: `Withdrew successfully!`,
      setPendingTxns,
    })
      .then(async (res) => {})
      .finally(() => {
        setIsSubmitting(false);
        setIsVisible(false);
      });
  }, [MMYFarmAddress, chainId, library, setIsVisible, setPendingTxns]);

  if (!isVisible) return null;

  return (
    <Modal
      className={`medium-modal Bootstrap-Modal hasDivider ${lightThemeClassName}`}
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      label="Withdraw"
    >
      <div className="box">
        <div className="box-title">You will withdraw</div>
        <div className="box-value">{formatAmount(willWithdraw, 18, 2, true)} vMMY</div>
        <div className="box-inUsd">${formatAmount(willWithdrawUsd, 18, 2, true)}</div>
      </div>
      <div className="text">
        Your MSLP lock period (if staking) will reset each time you withdraw vMMY from vesting.
      </div>
      <button onClick={withdraw} className="default-btn full" style={{ marginTop: 24, fontWeight: 700 }}>
        {isSubmitting ? "Withdrawing..." : "Withdraw"}
      </button>
    </Modal>
  );
};

export const ClaimVMMYModal = ({
  isVisible,
  setIsVisible,
  setPendingTxns,
  chainId,
  library,
  claimable,
  claimableUsd,
}) => {
  const { lightThemeClassName } = useThemeContext() as any;
  const MMYFarmAddress = getContract(chainId, "MMYFarm");

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  /**
   * Actions:
   * - withdraw
   */

  const withdraw = useCallback(async () => {
    setIsSubmitting(true);

    const contract = new ethers.Contract(MMYFarmAddress, MMYFarm.abi, library.getSigner());
    callContract(chainId, contract, "claim", [], {
      sentMsg: `Claim submitted!`,
      failMsg: `Claim failed.`,
      successMsg: `Claimed successfully!`,
      setPendingTxns,
    })
      .then(async (res) => {})
      .finally(() => {
        setIsSubmitting(false);
        setIsVisible(false);
      });
  }, [MMYFarmAddress, chainId, library, setIsVisible, setPendingTxns]);

  if (!isVisible) return null;

  return (
    <Modal
      className={`medium-modal Bootstrap-Modal hasDivider ${lightThemeClassName}`}
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      label="Claim vested MMY"
    >
      <div className="box">
        <div className="box-title">You will receive</div>
        <div className="box-value green">{formatAmount(claimable, 18, 2, true)} MMY</div>
        <div className="box-inUsd">${formatAmount(claimableUsd, 18, 2, true)}</div>
      </div>
      <div className="text">Your MSLP lock period (if staking) will reset each time you claim your vested MMY.</div>
      <button onClick={withdraw} className="default-btn full" style={{ marginTop: 24, fontWeight: 700 }}>
        {isSubmitting ? "Claiming..." : "Claim"}
      </button>
    </Modal>
  );
};

export const ClaimV2RewardModal = ({
  isVisible,
  setIsVisible,
  setPendingTxns,
  chainId,
  library,
  totalRewardUsd,
  rewards,
  // claimable,
  // claimableUsd,
}) => {
  const { lightThemeClassName } = useThemeContext() as any;
  const MMYFarmAddress = getContract(chainId, "MMYFarm");

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  /**
   * Actions:
   * - claim
   */

  const claim = useCallback(async () => {
    setIsSubmitting(true);
    const _mlp = true;
    const _vesting = false;

    const contract = new ethers.Contract(MMYFarmAddress, MMYFarm.abi, library.getSigner());
    callContract(chainId, contract, "harvestMany", [_mlp, _vesting], {
      sentMsg: `Claim submitted!`,
      failMsg: `Claim failed.`,
      successMsg: `Claimed successfully!`,
      setPendingTxns,
    })
      .then(async (res) => {})
      .finally(() => {
        setIsSubmitting(false);
        setIsVisible(false);
      });
  }, [MMYFarmAddress, chainId, library, setIsVisible, setPendingTxns]);

  if (!isVisible) return null;

  return (
    <Modal
      className={`medium-modal Bootstrap-Modal hasDivider ${lightThemeClassName}`}
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      label="Claim MSLP Pool Rewards"
    >
      <div className="box">
        <div className="box-title">You will receive</div>
        <div className="box-value green">${formatAmount(parseValue(totalRewardUsd, 18), 18, 2, true)}</div>
        <div className="content-box-rows" style={{ marginTop: 8 }}>
          <>
            {Object.entries(rewards).length > 0 && (
              <>
                {Object.entries(rewards).map(([address, rw]) => {
                  const reward: any = rw;
                  return (
                    <div className="content-box-row" key={address}>
                      <div className="content-box-row-label">{reward.symbol}</div>
                      <div className="content-box-row-value">
                        <AmountAndDollar
                          amount={formatAmount(reward.amount, reward.decimal, 4, true)}
                          dollar={`$${formatAmount(reward.inUsd, reward.decimal, 2, true)}`}
                        />
                      </div>
                    </div>
                  );
                })}{" "}
                {/* <div className="content-box-row">
                  <div className="content-box-row-label">MMY</div>
                  <div className="content-box-row-value">
                    <AmountAndDollar
                      amount={formatAmount(claimable, 18, 4, true)}
                      dollar={`$${formatAmount(claimableUsd, 18, 2, true)}`}
                    />
                  </div>
                </div> */}
              </>
            )}
            {Object.entries(rewards).length === 0 &&
              Array(3)
                .fill(null)
                .map((_, i) => (
                  <div className="content-box-row" key={i}>
                    <div
                      className="skeleton-box"
                      style={{
                        width: 90,
                        height: 19.6,
                      }}
                    ></div>
                    <div>
                      <AmountAndDollar amount={""} dollar={""} />
                    </div>
                  </div>
                ))}
          </>
          {/* {Object.entries(rewards).length > 0
            ? Object.entries(rewards).map(([address, rw]) => {
                const reward: any = rw;
                return (
                  <div className="content-box-row">
                    <div className="content-box-row-label">{reward.symbol}</div>
                    <div className="content-box-row-value">
                      <AmountAndDollar
                        amount={formatAmount(reward.amount, reward.decimal, 4, true)}
                        dollar={`$${formatAmount(reward.inUsd, reward.decimal, 2, true)}`}
                      />
                    </div>
                  </div>
                );
              })
            : Array(3)
                .fill(null)
                .map((_, i) => (
                  <div className="content-box-row" key={i}>
                    <div
                      className="skeleton-box"
                      style={{
                        width: 90,
                        height: 19.6,
                      }}
                    ></div>
                    <div>
                      <AmountAndDollar amount={""} dollar={""} />
                    </div>
                  </div>
                ))} */}
        </div>
      </div>
      <div className="text">Your MSLP lock period will reset each time you claim your rewards.</div>
      <button onClick={claim} className="default-btn full" style={{ marginTop: 24, fontWeight: 700 }}>
        {isSubmitting ? "Claiming..." : "Claim"}
      </button>
    </Modal>
  );
};

const antIcon = <LoadingOutlined style={{ fontSize: 16, marginRight: 8, color: "grey" }} spin />;
export const LoadingCpn = ({ text }) => {
  return (
    <div className="loading-spin-wrapper">
      <Spin indicator={antIcon} />
      <span className="text-value">{text ?? ""}</span>
    </div>
  );
};
