import { useMediaQuery } from "react-responsive";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
import styled from "styled-components";

import arb24Icon from "img/ic_arbitrum_24.svg";
import base24Icon from "img/ic_base_24.svg";
import ftm24Icon from "img/ic_ftm_24.svg";
import glp40Icon from "img/ic_mlp_40.svg";
import msp40Icon from "img/ic_msp-40.svg";
import gmx40Icon from "img/ic_mmy_40.svg";
import mlp40Icon from "img/ic_mlp_40.svg";
import op24Icon from "img/ic_op_24.svg";

import APRLabel from "components/APRLabel/APRLabel";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { getServerUrl } from "config/backend";
import useSWR from "swr";

import { ARBITRUM, BASE, DEFAULT_CHAIN_ID, FANTOM, OP } from "config/chains";
import { bigNumberify, expandDecimals, formatAmount, formatAmountFree, formatNumber } from "lib/numbers";

import { useWeb3React } from "@web3-react/core";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
import TooltipComponent from "components/Tooltip/Tooltip";
import { useGmxPrice } from "domain/legacy";
import { ethers } from "ethers";
import useMspPrice from "hooks/useMspPrice";
import { useChainId } from "lib/chains";
import { GLP_DECIMALS, USD_DECIMALS, PLACEHOLDER_ACCOUNT, SECONDS_PER_YEAR } from "lib/legacy";
import { getSkeletonClassName } from "utils/skeletonHelper";
import useUsdPrice from "hooks/useUsdPrice";
import useMMYFarmContractMulticall from "hooks/contracts/useMMYFarmContractMulticall";
import useTokensPrice from "hooks/useTokensPrice";
import React, { useMemo } from "react";
import useSwitchChain from "hooks/useSwitchChain";
import useMslpApr from "hooks/useMslpApr";
import { caculateAPYHourly2 } from "lib/helper";
import { useAprContext } from "contexts/AprProvider";
import useMspPriceMultiChain from "hooks/useMspPriceMultiChain";
import useTokensPriceOP from "hooks/useTokensPriceOP";
import useMslpAprOP from "hooks/useMslpAprOP";

const { AddressZero } = ethers.constants;
const EcosystemTokens = () => {
  const processedData = useAprContext();
  // const { gmxPrice, glpPrice } = useContext(StatsContext);
  const { chainId } = useChainId();
  const [switchChain] = useSwitchChain(chainId);
  const { active, library } = useWeb3React();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  const { gmxPrice, gmxPriceFromFantom, gmxPriceFromOP, gmxPriceFromArbitrum, gmxPriceFromBase } = useGmxPrice(
    chainId || FANTOM,
    {
      arbitrum: (chainId || FANTOM) === ARBITRUM ? library : undefined,
      op: (chainId || FANTOM) === OP ? library : undefined,
    },
    active
  );

  //MSLP only for FANTOM
  const rewardsPriceFantom = useTokensPrice({
    gmxPrice: gmxPriceFromFantom,
    FANTOM,
  });
  const rewardsPriceOp = useTokensPriceOP({
    gmxPrice: gmxPriceFromOP,
    OP,
  });

  const {
    mlpPoolInfo: [totalStakedFantom],
    poolRewardsPerSec: poolRewardsPerSecFantom,
  } = useMMYFarmContractMulticall(FANTOM, active, PLACEHOLDER_ACCOUNT, library);

  const {
    mlpPoolInfo: [totalStakedOp],
    poolRewardsPerSec: poolRewardsPerSecOp,
  } = useMMYFarmContractMulticall(
    OP,
    active,
    PLACEHOLDER_ACCOUNT,
    library
  );
  const mspPrices = useMspPriceMultiChain();
  const mspPrice = mspPrices[chainId] || mspPrices[DEFAULT_CHAIN_ID];

  const aprFantom = useMslpApr({
    mslpPrice: mspPrices[FANTOM],
    rewardsPrice: rewardsPriceFantom,
    totalStaked: totalStakedFantom,
    poolRewardsPerSec: poolRewardsPerSecFantom,
  });
  const aprOp = useMslpAprOP({
    mslpPrice: mspPrices[OP],
    rewardsPrice: rewardsPriceOp,
    totalStaked: totalStakedOp,
    poolRewardsPerSec: poolRewardsPerSecOp,
  });

  // const apr = useMemo(() => {
  //   if (totalStakedUsd) {
  //     const [, rewardSymbols, RewardDecimals, rewardPerSecs] = poolRewardsPerSec;
  //     const _totalStakedInDollar = parseFloat(formatAmountFree(totalStakedUsd, Number(18), Number(18))) || 1;

  //     return rewardPerSecs.reduce((acc, cur, index) => {
  //       const rewardPrice = rewardsPrice[rewardSymbols[index]]?.price;

  //       const _rewardPerSec = parseFloat(
  //         formatAmountFree(cur, Number(RewardDecimals[index]), Number(RewardDecimals[index]))
  //       );

  //       const apr = ((+rewardPrice * _rewardPerSec * SECONDS_PER_YEAR) / _totalStakedInDollar) * 100;

  //       return acc + apr;
  //     }, 0);
  //   }
  // }, [poolRewardsPerSec, rewardsPrice, totalStakedUsd]);

  // const readerAddress = getContract(chainId || FANTOM, "Reader");
  // const readerAddressFantom = getContract(FANTOM, "Reader");
  // const readerAddressOp = getContract(OP, "Reader");
  // const readerAddressArbitrum = getContract(ARBITRUM, "Reader");
  // const readerAddressBase = getContract(BASE, "Reader");

  // const glpManagerAddress = getContract(chainId || FANTOM, "GlpManager");
  // const glpManagerAddressFANTOM = getContract(FANTOM, "GlpManager");
  // const glpManagerAddressOP = getContract(OP, "GlpManager");
  // const glpManagerAddressARB = getContract(ARBITRUM, "GlpManager");
  // const glpManagerAddressBase = getContract(BASE, "GlpManager");

  // const gmxAddress = getContract(chainId || FANTOM, "GMX");
  // const glpAddress = getContract(chainId || FANTOM, "GLP");
  // const usdgAddress = getContract(chainId || FANTOM, "USDG");

  // const gmxAddressFantom = getContract(FANTOM, "GMX");
  // const glpAddressFantom = getContract(FANTOM, "GLP");
  // const usdgAddressFantom = getContract(FANTOM, "USDG");

  // const gmxAddressOp = getContract(OP, "GMX");
  // const glpAddressOp = getContract(OP, "GLP");
  // const usdgAddressOp = getContract(OP, "USDG");

  // const gmxAddressArbitrum = getContract(ARBITRUM, "GMX");
  // const glpAddressArbitrum = getContract(ARBITRUM, "GLP");
  // const usdgAddressArbitrum = getContract(ARBITRUM, "USDG");

  // const gmxAddressBase = getContract(BASE, "GMX");
  // const glpAddressBase = getContract(BASE, "GLP");
  // const usdgAddressBase = getContract(BASE, "USDG");

  // const tokensForSupplyQuery = [gmxAddress, glpAddress, usdgAddress];
  // const tokensForSupplyQueryFantom = [gmxAddressFantom, glpAddressFantom, usdgAddressFantom];
  // const tokensForSupplyQueryOp = [gmxAddressOp, glpAddressOp, usdgAddressOp];
  // const tokensForSupplyQueryArbitrum = [gmxAddressArbitrum, glpAddressArbitrum, usdgAddressArbitrum];
  // const tokensForSupplyQueryBase = [gmxAddressBase, glpAddressBase, usdgAddressBase];

  // const { data: aums } = useSWR([`Dashboard:getAums:${active}`, chainId, glpManagerAddress, "getAums"], {
  //   fetcher: contractFetcher(library, GlpManager),
  // });
  // const { data: aumsOP } = useSWR([`Dashboard:getAumsOP`, OP, glpManagerAddressOP, "getAums"], {
  //   fetcher: contractFetcher(library, GlpManager),
  //   refreshInterval: 30000,
  // });
  // const { data: aumsFANTOM } = useSWR([`Dashboard:getAumsFANTOM`, FANTOM, glpManagerAddressFANTOM, "getAums"], {
  //   fetcher: contractFetcher(library, GlpManager),
  //   refreshInterval: 30000,
  // });

  // const { data: aumsArb } = useSWR([`Dashboard:getAumsARB`, ARBITRUM, glpManagerAddressARB, "getAums"], {
  //   fetcher: contractFetcher(library, GlpManager),
  //   refreshInterval: 30000,
  // });

  // const { data: aumsBase } = useSWR([`Dashboard:getaumsBase`, BASE, glpManagerAddressBase, "getAums"], {
  //   fetcher: contractFetcher(library, GlpManager),
  //   refreshInterval: 30000,
  // });

  // const { data: totalSupplies } = useSWR(
  //   [`Dashboard:totalSupplies:${active}`, chainId, readerAddress, "getTokenBalancesWithSupplies", AddressZero],
  //   {
  //     fetcher: contractFetcher(library, ReaderV2, [tokensForSupplyQuery]),
  //   }
  // );
  // const { data: totalSuppliesFantom } = useSWR(
  //   [
  //     `Dashboard:totalSuppliesFantom:${active}`,
  //     FANTOM,
  //     readerAddressFantom,
  //     "getTokenBalancesWithSupplies",
  //     AddressZero,
  //   ],
  //   {
  //     fetcher: contractFetcher(library, ReaderV2, [tokensForSupplyQueryFantom]),
  //   }
  // );
  // const { data: totalSuppliesOP } = useSWR(
  //   [`Dashboard:totalSuppliesOP:${active}`, OP, readerAddressOp, "getTokenBalancesWithSupplies", AddressZero],
  //   {
  //     fetcher: contractFetcher(undefined, ReaderV2, [tokensForSupplyQueryOp]),
  //   }
  // );
  // console.log("?????", {readerAddressOp, tokensForSupplyQueryOp,totalSuppliesOP });
  // const { data: totalSuppliesArbitrum } = useSWR(
  //   [
  //     `Dashboard:totalSuppliesArb:${active}`,
  //     ARBITRUM,
  //     readerAddressArbitrum,
  //     "getTokenBalancesWithSupplies",
  //     AddressZero,
  //   ],
  //   {
  //     fetcher: contractFetcher(library, ReaderV2, [tokensForSupplyQueryArbitrum]),
  //   }
  // );
  const { data: mlpInfo } = useSWR(getServerUrl(chainId, "/mlp-info"), {
    refreshInterval: 30000,
  });
  const { data: mlpInfoFantom } = useSWR(getServerUrl(FANTOM, "/mlp-info"), {
    // fetcher: contractFetcher(undefined, ReaderV2, [tokensForSupplyQueryOp]),
    refreshInterval: 30000,
  });
  const { data: mlpInfoOP } = useSWR(getServerUrl(OP, "/mlp-info"), {
    // fetcher: contractFetcher(undefined, ReaderV2, [tokensForSupplyQueryOp]),
    refreshInterval: 30000,
  });
  const { data: mlpInfoArbitrum } = useSWR(getServerUrl(ARBITRUM, "/mlp-info"), {
    // fetcher: contractFetcher(undefined, ReaderV2, [tokensForSupplyQueryOp]),
    refreshInterval: 30000,
  });
  const { data: mlpInfoBase } = useSWR(getServerUrl(BASE, "/mlp-info"), {
    // fetcher: contractFetcher(undefined, ReaderV2, [tokensForSupplyQueryOp]),
    refreshInterval: 30000,
  });

  // const { data: totalSuppliesBase } = useSWR(
  //   [
  //     `Dashboard:totalSuppliesBase:${active}`,
  //     BASE,
  //     readerAddressBase,
  //     "getTokenBalancesWithSupplies",
  //     AddressZero,
  //   ],
  //   {
  //     fetcher: contractFetcher(library, ReaderV2, [tokensForSupplyQueryBase]),
  //   }
  // );
  // let aum;
  // if (aums && aums.length > 0) {
  //   aum = aums[0].add(aums[1]).div(2);
  // }
  // let aumOP;
  // if (aumsOP && aumsOP.length > 0) {
  //   aumOP = aumsOP[0].add(aumsOP[1]).div(2);
  // }

  // let aumFANTOM;
  // if (aumsFANTOM && aumsFANTOM.length > 0) {
  //   aumFANTOM = aumsFANTOM[0].add(aumsFANTOM[1]).div(2);
  // }

  // let aumArb;
  // if (aumsArb && aumsArb.length > 0) {
  //   aumArb = aumsArb[0].add(aumsArb[1]).div(2);
  // }

  // let aumBase;
  // if (aumsBase && aumsBase.length > 0) {
  //   aumBase = aumsBase[0].add(aumsBase[1]).div(2);
  // }

  let glpPrice;
  let glpSupply;
  let glpMarketCap;
  let glpPriceFantom;
  let glpPriceOp;
  let glpPriceArb;
  let glpPriceBase;
  let glpSupplyFantom;
  let glpSupplyOp;
  let glpSupplyArbitrum;
  let glpSupplyBase;
  let glpMarketCapFantom;
  let glpMarketCapOp;
  let glpMarketCapArbitrum;
  let glpMarketCapBase;
  let totalGlpSupply;
  if (mlpInfo) {
    glpSupply = bigNumberify(mlpInfo?.totalSupply || "0");

    glpPrice = bigNumberify(mlpInfo?.price || "0");
    glpMarketCap = glpPrice.mul(glpSupply).div(expandDecimals(1, GLP_DECIMALS));
  }
  if (mlpInfoFantom) {
    glpSupplyFantom = bigNumberify(mlpInfoFantom?.totalSupply || "0");

    glpPriceFantom = bigNumberify(mlpInfoFantom?.price || "0");
    glpMarketCapFantom = glpPriceFantom.mul(glpSupplyFantom).div(expandDecimals(1, GLP_DECIMALS));
  }
  if (mlpInfoOP) {
    glpSupplyOp = bigNumberify(mlpInfoOP?.totalSupply || "0");

    glpPriceOp = bigNumberify(mlpInfoOP?.price || "0");
    glpMarketCapOp = glpPriceOp.mul(glpSupplyOp).div(expandDecimals(1, GLP_DECIMALS));
  }
  if (mlpInfoArbitrum) {
    glpSupplyArbitrum = bigNumberify(mlpInfoArbitrum?.totalSupply || "0");

    glpPriceArb = bigNumberify(mlpInfoArbitrum?.price || "0");
    glpMarketCapArbitrum = glpPriceArb.mul(glpSupplyArbitrum).div(expandDecimals(1, GLP_DECIMALS));
  }

  if (mlpInfoBase) {
    glpSupplyBase = bigNumberify(mlpInfoBase?.totalSupply || "0");

    glpPriceBase = bigNumberify(mlpInfoBase?.price || "0");
  }

  //MMY
  const mmyAprFantom = parseFloat(formatAmount(processedData[FANTOM]?.gmxAprTotal, 2)) / 100;
  const mmyApyFantom = caculateAPYHourly2(mmyAprFantom) * 100;

  const mmyAprOp = parseFloat(formatAmount(processedData[OP]?.gmxAprTotal, 2)) / 100;
  const mmyApyOp = caculateAPYHourly2(mmyAprOp) * 100;

  const mmyAprArb = parseFloat(formatAmount(processedData[ARBITRUM]?.gmxAprTotal, 2)) / 100;
  const mmyApyArb = caculateAPYHourly2(mmyAprArb) * 100;

  const mmyAprBase = parseFloat(formatAmount(processedData[BASE]?.gmxAprTotal, 2)) / 100;
  const mmyApBase = caculateAPYHourly2(mmyAprBase) * 100;

  //MLP
  const mlpAprFantom = parseFloat(formatAmount(processedData[FANTOM]?.glpAprTotal, 2)) / 100;
  const mlpApyFantom = caculateAPYHourly2(mlpAprFantom) * 100;

  const mlpAprOp = parseFloat(formatAmount(processedData[OP]?.glpAprTotal, 2)) / 100;
  const mlpApyOp = caculateAPYHourly2(mlpAprOp) * 100;

  const mlpAprArb = parseFloat(formatAmount(processedData[ARBITRUM]?.glpAprTotal, 2)) / 100;
  const mlpApyArb = caculateAPYHourly2(mlpAprArb) * 100;

  const mlpAprBase = parseFloat(formatAmount(processedData[BASE]?.glpAprTotal, 2)) / 100;
  const mlpApBase = caculateAPYHourly2(mlpAprBase) * 100;

  return (
    <Wrapper className="container">
      <Fade bottom>
        <Title>Ecosystem tokens</Title>
      </Fade>
      <TokenCardContainer>
        {isMobile && (
          <Fade left>
            <TokenCard>
              <CardTitle>
                <TokenImg className="mlp40-icon">
                  <img src={msp40Icon} alt="mlp40Icon" />
                </TokenImg>
                <TokenInfo>
                  <div className="token-info">
                    <div className="token-symbol">MSLP</div>
                    <span className={`price ${getSkeletonClassName(mspPrice)}`}>
                      {!mspPrice && <div className="skeleton-box" />}
                      {mspPrice && (
                        <TooltipComponent
                          position="left-bottom"
                          className="nowrap"
                          handle={"$" + formatAmount(mspPrice, 30, 2)}
                          renderContent={() => (
                            <div className="customTooltip">
                              <StatsTooltipRow
                                label={`Price on Fantom`}
                                value={formatAmount(mspPrices[FANTOM], 30, 2)}
                                showDollar={true}
                              />
                              <StatsTooltipRow
                                label={`Price on Optimism`}
                                value={formatAmount(mspPrices[OP], 30, 2)}
                                showDollar={true}
                              />
                            </div>
                          )}
                        />
                      )}
                    </span>
                  </div>
                </TokenInfo>
              </CardTitle>
              <TokenCardDescription>
                MSLP is the liquidity provider token on Mummy V2. Accrues 60% of the V2 markets generated fees.
              </TokenCardDescription>
              <TokenAPR>
                <div className="row ftm">
                  <div className="title">
                    <img src={ftm24Icon} alt="ftm-symbol" />
                    APR
                    <span>
                      {aprFantom && aprFantom.total !== undefined && !isNaN(aprFantom.total) ? (
                        aprFantom.total === 0 ? (
                          "0.000%"
                        ) : (
                          `${formatNumber(aprFantom.total, 2)}%`
                        )
                      ) : (
                        <div className="skeleton-box" style={{ width: "60px" }} />
                      )}
                    </span>
                  </div>
                </div>
                <div className="row ftm">
                  <div className="title">
                    <img src={op24Icon} alt="ftm-symbol" />
                    APR
                    <span>
                      {aprOp && aprOp.total !== undefined && !isNaN(aprOp.total) ? (
                        aprOp.total === 0 ? (
                          "0.000%"
                        ) : (
                          `${formatNumber(aprOp.total, 2)}%`
                        )
                      ) : (
                        <div className="skeleton-box" style={{ width: "60px" }} />
                      )}
                    </span>
                  </div>
                </div>
              </TokenAPR>
              <div className="mobile-actions">
                <ExternalLink
                  href="https://app.mummy.finance/#/buy/mint"
                  className="buy-btn"
                  target="_self"
                  onClick={() => switchChain(FANTOM)}
                >
                  Buy
                </ExternalLink>
                <ExternalLink href="https://docs.mummy.finance/mlp" target={"_blank"} className="btn-readmore">
                  Read more
                </ExternalLink>
              </div>
            </TokenCard>
          </Fade>
        )}
        <Fade left>
          <TokenCard>
            <CardTitle>
              <TokenImg>
                <img src={gmx40Icon} alt="MMY Token Icon" />
              </TokenImg>
              <TokenInfo>
                <div className="token-info">
                  <div className="token-symbol">MMY</div>
                  <span className={`price ${getSkeletonClassName(gmxPrice)}`}>
                    {!gmxPrice && <div className="skeleton-box" />}
                    {gmxPrice && (
                      <TooltipComponent
                        position="left-bottom"
                        className="nowrap"
                        handle={"$" + formatAmount(gmxPrice, USD_DECIMALS, 2, true)}
                        renderContent={() => (
                          <div className="customTooltip">
                            <StatsTooltipRow
                              label={`Price on Fantom`}
                              value={formatAmount(gmxPriceFromFantom, USD_DECIMALS, 2, true)}
                              showDollar={true}
                            />
                            <StatsTooltipRow
                              label={`Price on Optimism`}
                              value={formatAmount(gmxPriceFromOP, USD_DECIMALS, 2, true)}
                              showDollar={true}
                            />
                            <StatsTooltipRow
                              label={`Price on Arbitrum`}
                              value={formatAmount(gmxPriceFromArbitrum, USD_DECIMALS, 2, true)}
                              showDollar={true}
                            />
                            <StatsTooltipRow
                              label={`Price on Base`}
                              value={formatAmount(gmxPriceFromBase, USD_DECIMALS, 2, true)}
                              showDollar={true}
                            />
                          </div>
                        )}
                      />
                    )}
                  </span>
                </div>
              </TokenInfo>
            </CardTitle>

            <TokenCardDescription>
              MMY is the utility and governance token. Accrues 30% of V1 and V2 markets generated fees.
            </TokenCardDescription>
            <TokenAPR>
              <div className="row ftm">
                <div className="title">
                  <img src={ftm24Icon} alt="ftm-symbol" />
                  APY
                  <span>{formatNumber(mmyApyFantom, 2)}%</span>
                </div>
              </div>
              <div className="row op">
                <div className="title">
                  <img src={op24Icon} alt="op-symbol" />
                  APY
                  <span>{formatNumber(mmyApyOp, 2)}%</span>
                </div>
              </div>
              <div className="row arb">
                <div className="title">
                  <img src={arb24Icon} alt="arb-symbol" />
                  APY
                  <span>{formatNumber(mmyApyArb, 2)}%</span>
                </div>
              </div>
              <div className="row base">
                <div className="title">
                  <img src={base24Icon} alt="base-symbol" />
                  APY
                  <span>{formatNumber(mmyApBase, 2)}%</span>
                </div>
              </div>
            </TokenAPR>

            <div className="mobile-actions">
              <Link to="/buy_mmy" className="buy-btn">
                Buy
              </Link>
              <ExternalLink href="https://docs.mummy.finance/tokenomics" target={"_blank"} className="btn-readmore">
                Read more
              </ExternalLink>
            </div>
          </TokenCard>
        </Fade>
        <Fade bottom>
          <TokenCard>
            <CardTitle>
              <TokenImg>
                <img src={mlp40Icon} alt="mlp40Icon" />
              </TokenImg>
              <TokenInfo>
                <div className="token-info">
                  <div className="token-symbol">MLP</div>
                  <span className={`price ${getSkeletonClassName(glpPrice)}`}>
                    {!glpPrice && <div className="skeleton-box" />}
                    {glpPrice && (
                      <TooltipComponent
                        position="left-bottom"
                        className="nowrap"
                        handle={"$" + formatAmount(glpPrice, USD_DECIMALS, 2, true)}
                        renderContent={() => (
                          <div className="customTooltip">
                            <StatsTooltipRow
                              label={`Price on Fantom`}
                              value={formatAmount(glpPriceFantom, USD_DECIMALS, 2, true)}
                              showDollar={true}
                            />
                            <StatsTooltipRow
                              label={`Price on Optimism`}
                              value={formatAmount(glpPriceOp, USD_DECIMALS, 2, true)}
                              showDollar={true}
                            />
                            <StatsTooltipRow
                              label={`Price on Arbitrum`}
                              value={formatAmount(glpPriceArb, USD_DECIMALS, 2, true)}
                              showDollar={true}
                            />
                            <StatsTooltipRow
                              label={`Price on Base`}
                              value={formatAmount(glpPriceBase, USD_DECIMALS, 2, true)}
                              showDollar={true}
                            />
                          </div>
                        )}
                      />
                    )}
                  </span>
                </div>
              </TokenInfo>
            </CardTitle>

            <TokenCardDescription>
              MLP is the liquidity provider token. Accrues 60% of the V1 markets generated fees.
            </TokenCardDescription>
            <TokenAPR>
              <div className="row ftm">
                <div className="title">
                  <img src={ftm24Icon} alt="ftm-symbol" />
                  APY
                  <span>{formatNumber(mlpApyFantom, 2)}%</span>
                </div>
              </div>
              <div className="row op">
                <div className="title">
                  <img src={op24Icon} alt="op-symbol" />
                  APY
                  <span>{formatNumber(mlpApyOp, 2)}%</span>
                </div>
              </div>
              <div className="row arb">
                <div className="title">
                  <img src={arb24Icon} alt="arb-symbol" />
                  APY
                  <span>{formatNumber(mlpApyArb, 2)}%</span>
                </div>
              </div>
              <div className="row base">
                <div className="title">
                  <img src={base24Icon} alt="base-symbol" />
                  APY
                  <span>{formatNumber(mlpApBase, 2)}%</span>
                </div>
              </div>
            </TokenAPR>
            <div className="mobile-actions">
              <Link to="/buy_mlp" className="buy-btn">
                Buy
              </Link>
              <ExternalLink href="https://docs.mummy.finance/mlp" target={"_blank"} className="btn-readmore">
                Read more
              </ExternalLink>
            </div>
          </TokenCard>
        </Fade>
        {!isMobile && (
          <Fade right>
            <TokenCard>
              <CardTitle>
                <TokenImg className="mlp40-icon">
                  <img src={msp40Icon} alt="mlp40Icon" />
                </TokenImg>
                <TokenInfo>
                  <div className="token-info">
                    <div className="token-symbol">MSLP</div>
                    <span className={`price ${getSkeletonClassName(mspPrice)}`}>
                      {!mspPrice && <div className="skeleton-box" />}
                      {mspPrice && (
                        <TooltipComponent
                          position="left-bottom"
                          className="nowrap"
                          handle={"$" + formatAmount(mspPrice, 30, 2)}
                          renderContent={() => (
                            <div className="customTooltip">
                              <StatsTooltipRow
                                label={`Price on Fantom`}
                                value={formatAmount(mspPrices[FANTOM], 30, 2)}
                                showDollar={true}
                              />
                              <StatsTooltipRow
                                label={`Price on Optimism`}
                                value={formatAmount(mspPrices[OP], 30, 2)}
                                showDollar={true}
                              />
                            </div>
                          )}
                        />
                      )}
                    </span>
                  </div>
                </TokenInfo>
              </CardTitle>

              <TokenCardDescription>
                MSLP is the liquidity provider token on Mummy V2. Accrues 70% of the V2 markets generated fees.
              </TokenCardDescription>
              <TokenAPR>
                <div className="row ftm">
                  <div className="title">
                    <img src={ftm24Icon} alt="ftm-symbol" />
                    APY
                    <span>
                      {" "}
                      {aprFantom && aprFantom.total !== undefined && !isNaN(aprFantom.total) ? (
                        <>{aprFantom.total === 0 ? "0.000" : `${formatNumber(aprFantom.total, 2)}%`}</>
                      ) : (
                        <div className="skeleton-box" style={{ width: "60px" }} />
                      )}
                    </span>
                  </div>
                </div>
                <div className="row op">
                  <div className="title">
                    <img src={op24Icon} alt="op-symbol" />
                    APY
                    <span>
                      {aprOp && aprOp.total !== undefined && !isNaN(aprOp.total) ? (
                        <>{aprOp.total === 0 ? "0.000" : `${formatNumber(aprOp.total, 2)}%`}</>
                      ) : (
                        <div className="skeleton-box" style={{ width: "60px" }} />
                      )}
                    </span>
                  </div>
                </div>
              </TokenAPR>
              <div className="mobile-actions">
                <ExternalLink
                  href="https://app.mummy.finance/#/buy/mint"
                  className="buy-btn"
                  target="_self"
                  onClick={() => switchChain(FANTOM)}
                >
                  Buy
                </ExternalLink>
                <ExternalLink href="https://docs.mummy.finance/earn/mslp" target={"_blank"} className="btn-readmore">
                  Read more
                </ExternalLink>
              </div>
            </TokenCard>
          </Fade>
        )}
      </TokenCardContainer>
    </Wrapper>
  );
};

const TokenAPR = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  overflow: hidden;
  width: 100%;
  > .row {
    width: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    min-height: 104px;
    height: 100%;
    background: linear-gradient(180deg, rgba(19, 181, 236, 0.1) 0%, rgba(19, 181, 236, 0) 100%);

    &.op {
      background: linear-gradient(180deg, rgba(255, 4, 32, 0.1) 0%, rgba(255, 4, 32, 0) 100%);
    }
    &.arb {
      background: linear-gradient(180deg, rgba(0, 119, 229, 0.1) 0%, rgba(0, 119, 229, 0) 100%);
    }
    &.base {
      background: linear-gradient(180deg, rgba(0, 82, 255, 0.1) 0%, rgba(0, 82, 255, 0) 100%);
    }

    .title {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: #ffffff99;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 8px;

      img {
        width: 24px;
        height: 24px;
      }

      > span {
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        color: #03f5ae;
      }
    }

    .btn-buy {
      border-radius: 8px;
      width: 140px;
      background-color: #03f5ae;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;
      color: #080715;
      text-decoration: none;
    }

    @media (max-width: 767px) {
      min-height: fit-content;
      align-items: start;
      .title {
        flex-direction: row;
      }
      .btn-buy {
        width: 100%;
        margin-top: 16px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`;

const TokenInfo = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  padding-left: 16px;

  .token-info {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .token-symbol {
    color: white;
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;
  }

  .price {
    color: #03f5ae;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    .Tooltip-handle {
      text-decoration-color: #03f5ae;
    }
  }

  @media screen and (max-width: 767px) {
  }
`;

const TokenImg = styled.div`
  padding: 16px;
  background-image: linear-gradient(138.67deg, rgba(3, 245, 174, 0.15) 8.79%, rgba(3, 245, 174, 0) 100%);
  border-radius: 0px 0px 12px 12px;

  img {
    width: 48px;
    height: 48px;
  }

  @media screen and (max-width: 767px) {
  }
`;

const TokenCardDescription = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #ffffff99;
  max-height: 3em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  @media screen and (max-width: 767px) {
    max-height: unset;
  }
`;

const CardTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .mlp40-icon {
    background-image: linear-gradient(139deg, rgba(245, 206, 3, 0.3) 8.79%, rgba(245, 206, 3, 0) 100%) !important;
  }
  @media screen and (max-width: 767px) {
  }
`;

const TokenCard = styled.div`
  padding: 24px;
  padding-top: 0;
  background-color: #151928;
  border-radius: 24px;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .btn-readmore {
    height: 40px;
    display: flex;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #ffffff;
    margin-right: 16px;
    margin-left: auto;
    width: 100%;
    transition: 0.3s;
    &:hover {
      color: #03f5ae;
      border: 1px solid #03f5ae;
    }
  }

  .buy-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #03f5ae;
    color: #080715;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    width: 100%;
    height: 40px;
    padding: 10px;
    border-radius: 8px;
    text-decoration: none;
    text-align: center;
    &:hover {
      background: #04d397;
      color: #080715 !important;
    }
  }

  .mobile-actions {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(2, 1fr);

    > * {
      flex: 1;
    }

    .btn-readmore {
      margin: 0;
      justify-content: center;
      padding: 0;
    }
  }

  @media screen and (max-width: 767px) {
    padding: 16px;
    padding-top: 0;
    .mobile-actions {
      gap: 12px;
    }
  }
`;

const TokenCardContainer = styled.div`
  margin-top: 36px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  width: 100%;
  @media (max-width: 1024px) {
    grid-template-columns: repeat(1, 1fr);
    margin-top: 16px;
  }
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 56px;
  line-height: 140%;
  text-align: center;
  color: #fff !important;
  @media (max-width: 1024px) {
    font-size: 40px;
  }

  @media (max-width: 767px) {
    font-size: 24px;
  }
`;

const Wrapper = styled.div`
  padding: 120px 0px 60px;
  .Tooltip-popup {
    width: 250px !important;
  }
  @media (max-width: 1024px) {
    padding: 120px 24px 60px;
  }

  @media (max-width: 767px) {
    padding: 120px 16px 60px;
    .Tooltip-popup {
      width: 210px !important;
    }
    .Tooltip-handle {
      color: #03f5ae !important;
    }
  }
`;

export default EcosystemTokens;
