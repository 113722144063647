import axios from "axios";
import { useChainId } from "lib/chains";
import { useEffect, useState } from "react";
import { getServerBaseUrl, getServerUrl } from "config/backend";
import { FANTOM, SUPPORTED_V2_CHAINS } from "config/chains";

type Params = {
  pageSize: number;
};

export const DEFAULT_PAGE_SIZE_CLOSED = 10;

const useUserClosedPositions = (account: string | undefined | null, params?: Params) => {
  const { pageSize = DEFAULT_PAGE_SIZE_CLOSED } = params || {};
  const [positions, setPositions] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const { chainId } = useChainId();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (account && SUPPORTED_V2_CHAINS.includes(chainId)) {
      const fetchPositions = async () => {
        const { data: responseData } = await axios.get(
          `${getServerBaseUrl(chainId, true)}/public/closed_positions/${account}`,
          {
            params: {
              page,
              pageSize,
            },
          },
        );

        if (responseData?.data) {
          const { rows = [], count } = responseData?.data;
          setPositions(rows);
          setCount(count);
        }
        setIsLoading(false);
      };

      fetchPositions();

      const interval = setInterval(async () => {
        fetchPositions();
      }, 10000);
      return () => clearInterval(interval);
    } else {
      setIsLoading(false);
    }
  }, [account, chainId, page, pageSize]);

  return { positions, count, currentPage: page, setCurrenPage: setPage, isLoading };
};
export default useUserClosedPositions;
