import axios from "axios";

import { getServerBaseUrl } from "config/backend";
import { PLACEHOLDER_ACCOUNT } from "lib/legacy";
import { useEffect, useState } from "react";

function useTraderInfo(chainId, account = PLACEHOLDER_ACCOUNT) {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    async function getTraderInfo() {
      try {
        (async () => {
          const { data: response } = await axios.get(
            `${getServerBaseUrl(chainId, true)}/public/account-stats/${account}`
          );

          setData(
            response.data || {
              volume: 0,
              profitLoss: 0,
              fees: 0,
            }
          );
          setIsLoading(false);
        })();
      } catch (error) {
        console.error("[ERROR]: useTraderInfo fetch failed");
      }
    }

    getTraderInfo();
    const timerId = setInterval(getTraderInfo, 30000);

    return () => clearInterval(timerId);
  }, [account, chainId]);

  return {
    data,
    isLoading,
  };
}

export default useTraderInfo;
