import axios from "axios";
import { useEffect, useState } from "react";

const useYourNFTId = (account) => {
  const [NFTid, setNFTid] = useState(null);
  useEffect(() => {
    if (account) {
      (async () => {
        const { data } = await axios.get(`https://api.mummy.finance/mummy-nft?account=${account}`);

        setNFTid(data?.nfts?.[0]?.tokenId);
      })();
    }
  }, [account]);

  return NFTid;
};

export default useYourNFTId;
