import React from "react";

const EllipsisIcon = (props: JSX.IntrinsicElements["svg"]) => {
  return (
    <svg width={11} height={3} viewBox="0 0 11 3" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0.916016 1.67969C0.916016 1.42448 1.0026 1.21029 1.17578 1.03711C1.34896 0.859375 1.58366 0.770508 1.87988 0.770508C2.18066 0.770508 2.41536 0.859375 2.58398 1.03711C2.75716 1.21029 2.84375 1.42448 2.84375 1.67969C2.84375 1.9349 2.75716 2.14909 2.58398 2.32227C2.41536 2.49544 2.18066 2.58203 1.87988 2.58203C1.58366 2.58203 1.34896 2.49544 1.17578 2.32227C1.0026 2.14909 0.916016 1.9349 0.916016 1.67969ZM4.8125 1.67969C4.8125 1.42448 4.89909 1.21029 5.07227 1.03711C5.24544 0.859375 5.48014 0.770508 5.77637 0.770508C6.07715 0.770508 6.31185 0.859375 6.48047 1.03711C6.65365 1.21029 6.74023 1.42448 6.74023 1.67969C6.74023 1.9349 6.65365 2.14909 6.48047 2.32227C6.31185 2.49544 6.07715 2.58203 5.77637 2.58203C5.48014 2.58203 5.24544 2.49544 5.07227 2.32227C4.89909 2.14909 4.8125 1.9349 4.8125 1.67969ZM8.70898 1.67969C8.70898 1.42448 8.79557 1.21029 8.96875 1.03711C9.14193 0.859375 9.37663 0.770508 9.67285 0.770508C9.97363 0.770508 10.2083 0.859375 10.377 1.03711C10.5501 1.21029 10.6367 1.42448 10.6367 1.67969C10.6367 1.9349 10.5501 2.14909 10.377 2.32227C10.2083 2.49544 9.97363 2.58203 9.67285 2.58203C9.37663 2.58203 9.14193 2.49544 8.96875 2.32227C8.79557 2.14909 8.70898 1.9349 8.70898 1.67969Z"
        fill="white"
      />
    </svg>
  );
};

export default EllipsisIcon;
