import React, { useState } from "react";
import cx from "classnames";
import shareIcon from "img/trade/share.svg";
import { getTokenSymbolFromString } from "domain/tokens";

import { importImage } from "lib/legacy";
import { formatAmount, limitDecimals, parseValue } from "lib/numbers";
import { BigNumber } from "ethers";
import PositionClosed from "./PositionClosed";
import useUserClosedPositions, { DEFAULT_PAGE_SIZE_CLOSED } from "hooks/useUserClosedPositions";
import moment from "moment";
import PaginationV2 from "pages/Analytics/components/PaginationV2";
import PositionClosedShare from "./PositionClosedShare";

function secondsToHMS(secs) {
  function z(n) {
    return (n < 10 ? "0" : "") + n;
  }
  var sign = secs < 0 ? "-" : "";
  secs = Math.abs(secs);
  return sign + z((secs / 3600) | 0) + "h:" + z(((secs % 3600) / 60) | 0) + "m:" + z(secs % 60) + "s";
}
export default function ClosedPositionsList(props) {
  const { marketAsssets, account, chainId, marketTokensInfo } = props;
  const [positionToShare, setPositionToShare] = useState(null);
  const [isPositionDetailsVisible, setIsPositionDetailsVisible] = useState(undefined);
  const [selectedPosition, setSelectedPosition] = useState(undefined);
  const [isPositionShareModalOpen, setIsPositionShareModalOpen] = useState(false);

  const { positions: myCloseds, count, currentPage, setCurrenPage, isLoading } = useUserClosedPositions(account);

  const handleSelectedPosition = (position) => {
    setSelectedPosition(position);
    setIsPositionDetailsVisible(true);
  };

  return (
    <div className="PositionsList">
      {myCloseds && (
        <div className="Exchange-list small">
          <div>
            {/* {isLoading && (
              <div className="Exchange-empty-positions-list-note App-card">
                <div className="skeleton-box" style={{ height: "25px" }} />
              </div>
            )} */}
            {!isLoading && myCloseds.length === 0 && <div className="no-data-record">No closed positions</div>}
            {myCloseds.map((position, index) => {
              const symbol = marketAsssets?.[position.tokenId]?.symbol;
              const leverage = BigNumber.from(position?.totalSize || 0)
                .mul(parseValue("1", 30))
                .div(BigNumber.from(position?.totalCollateral || 0));
              const dateFormat = "YYYY-MM-DD";
              const timeFormat = "HH:mm:ss";
              const duration = secondsToHMS(position.closedAt - position.createdAt);
              const roiPrefix = position.totalROI.includes("-") ? "-" : "+";
              const rPnLPrefix = position.realisedPnl.includes("-") ? "-" : "+";
              const isLoss = position.realisedPnl.includes("-");
              const pnlPrefix = position.realisedPnl.includes("-") ? "-" : "+";
              const posQty = BigNumber.from(position?.totalSize || 0)
                .mul(parseValue("1", 30))
                .div(BigNumber.from(position?.averagePrice || 0));

              const currentMarket = marketTokensInfo?.find((x) => x.id === position.tokenId);
              const completePosition = {
                // positionNetValue,
                ...position,
                duration,
                paidFees: BigNumber.from(position.positionFee || 0)
                  .add(BigNumber.from(position.fundingFee || 0))
                  .add(BigNumber.from(position.borrowFee || 0)),
                positionNetValue: {
                  prefix: roiPrefix,
                  percentPnl: parseValue(limitDecimals(Number(position.totalROI) / 1000, 2).replace("-", ""), 30),
                  pnl: position.realisedPnl,
                },
                leverage: formatAmount(leverage, 30, 2, true),
                symbol: getTokenSymbolFromString(marketAsssets?.[position.tokenId]?.symbol),
                quantity: posQty,
                currentMarket,
                rPnLPrefix,
              };
              return (
                <div key={index} className="App-card">
                  <div className="App-card-title">
                    <span className="Exchange-list-title">
                      <img
                        src={importImage("ic_" + getTokenSymbolFromString(symbol)?.toLowerCase() + "_24.svg")}
                        alt=""
                      />{" "}
                      {symbol} <span className="muted">#{position.posId}</span>
                    </span>
                  </div>
                  <div className="App-card-divider" />
                  <div className="App-card-content app-card-position">
                    <div className="leverage-pad">
                      <div
                        className={cx("Exchange-list-side", {
                          positive: position.isLong,
                          negative: !position.isLong,
                        })}
                      >
                        {position.isLong ? "LONG" : "SHORT"}
                      </div>
                      {leverage && (
                        <span
                          className="leverage-box"
                          style={{
                            color: "#FFC700",
                            backgroundColor: "rgba(255, 199, 0, 0.20)",
                            height: 16,
                            display: "inline-block",
                            opacity: 1,
                            borderRadius: "4px",
                            padding: "0 4px",
                            fontSize: "12px",
                            lineHeight: "15.6px",
                            fontWeight: 400,
                            marginLeft: "4px",
                          }}
                        >
                          {formatAmount(leverage, 30, 2, true)}X
                        </span>
                      )}
                    </div>
                    <div className="App-card-row">
                      <div className="label">
                        <span>Opened</span>
                      </div>
                      <div>
                        {" "}
                        <div>{moment(new Date(position.createdAt * 1000)).format(dateFormat)}</div>
                        <div className="muted fz-12">
                          {moment(new Date(position.createdAt * 1000)).format(timeFormat)}
                        </div>
                      </div>
                    </div>
                    <div className="App-card-row">
                      <div className="label">
                        <span>Closed</span>
                      </div>
                      <div>
                        <div>{moment(new Date(position.closedAt * 1000)).format(dateFormat)}</div>
                        <div className="muted fz-12">
                          {moment(new Date(position.closedAt * 1000)).format(timeFormat)}
                        </div>
                      </div>
                    </div>
                    <div className="App-card-row">
                      <div className="label">
                        <span>Duration</span>
                      </div>
                      <div>{duration}</div>
                    </div>
                    <div className="App-card-row">
                      <div className="label">
                        <span>PnL/ROI</span>
                      </div>
                      <div>
                        <div
                          className={`${!isLoss ? "isProfit" : "isLoss"} ${!isLoss ? "positive" : "negative"}`}
                          style={{
                            color: position.realisedPnl.includes("-") ? "#F6475D" : "#03F5AE ",
                          }}
                        >
                          {rPnLPrefix}${formatAmount(position.realisedPnl, 30, 2, true).replace("-", "")}
                        </div>
                        <div className="muted fz-12">
                          {roiPrefix}
                          {limitDecimals(Number(position.totalROI) / 1000, 2).replace("-", "")}%
                        </div>
                      </div>
                    </div>
                    <div className="App-card-row">
                      <div className="label">
                        <span>Avg. Entry Price</span>
                      </div>
                      <div>${formatAmount(position.averagePrice, 30, currentMarket?.decimals, true)}</div>
                    </div>
                    <div className="App-card-row">
                      <div className="label">
                        <span>Close Price</span>
                      </div>
                      <div>${formatAmount(position.markPrice, 30, currentMarket?.decimals, true)}</div>
                    </div>
                    <div className="App-card-row">
                      <div className="label">
                        <span>Pos. Size</span>
                      </div>
                      <div>
                        <div> ${formatAmount(position.totalSize, 30, 2, true)}</div>
                        <div className="muted fz-12">
                          {" "}
                          {formatAmount(posQty, 30, 4, true)} {getTokenSymbolFromString(symbol)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ marginTop: "1.3rem" }} />
                  <div className="App-card-options">
                    <button
                      className="App-button-option App-card-option"
                      onClick={() => handleSelectedPosition(completePosition)}
                      // disabled={position.size == 0}
                    >
                      <span>View</span>
                    </button>
                    <button
                      className="PositionDropdown-dots-icon"
                      onClick={(e) => {
                        e.stopPropagation();
                        setPositionToShare(completePosition);
                        setIsPositionShareModalOpen(true);
                      }}
                    >
                      <img src={shareIcon} alt="icon" style={{ width: "16px", height: "16px", maxWidth: "16px" }} />
                      Share
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {isLoading && (
        <>
          <div className="skeleton-box" style={{ height: "56.19px" }} />
          <div className="skeleton-box" style={{ height: "56.19px", marginTop: 8 }} />
          <div className="skeleton-box" style={{ height: "56.19px", marginTop: 8 }} />
        </>
      )}
      {!isLoading && (
        <table className={`Exchange-list large App-box ${myCloseds?.length === 0 ? "no-array-length" : ""}`}>
          <tbody>
            {myCloseds && myCloseds.length > 0 && (
              <tr className="Exchange-list-header">
                <th>
                  <span>Position</span>
                </th>
                <th>
                  <span>Opened</span>
                </th>
                <th>
                  <span>Closed</span>
                </th>
                <th>
                  <span>Duration</span>
                </th>
                <th>
                  <span>PnL/ROI</span>
                </th>
                <th>
                  <span>Avg. Entry Price</span>
                </th>
                <th>
                  <span>Close Price</span>
                </th>
                <th>
                  <span>Pos. Size</span>
                </th>
                <th></th>
              </tr>
            )}

            {!isLoading && myCloseds.length === 0 && (
              <tr className="no-position">
                <td colSpan="15">
                  <div className="no-data-record">No closed positions</div>
                </td>
              </tr>
            )}
            {myCloseds &&
              myCloseds.length > 0 &&
              myCloseds.map((position, index) => {
                const symbol = marketAsssets?.[position.tokenId]?.symbol;
                const leverage = BigNumber.from(position?.totalSize || 0)
                  .mul(parseValue("1", 30))
                  .div(BigNumber.from(position?.totalCollateral || 0));
                const dateFormat = "YYYY-MM-DD";
                const timeFormat = "HH:mm:ss";
                const duration = secondsToHMS(position.closedAt - position.createdAt);
                const roiPrefix = position.totalROI.includes("-") ? "-" : "+";
                const rPnLPrifix = position.realisedPnl.includes("-") ? "-" : "+";
                const isLoss = position.realisedPnl.includes("-");
                const pnlPrefix = position.realisedPnl.includes("-") ? "-" : "+";
                const posQty = BigNumber.from(position?.totalSize || 0)
                  .mul(parseValue("1", 30))
                  .div(BigNumber.from(position?.averagePrice || 0));

                const currentMarket = marketTokensInfo?.find((x) => x.id === position.tokenId);
                const completePosition = {
                  // positionNetValue,
                  ...position,
                  duration,
                  paidFees: BigNumber.from(position.positionFee || 0)
                    .add(BigNumber.from(position.fundingFee || 0))
                    .add(BigNumber.from(position.borrowFee || 0)),
                  positionNetValue: {
                    prefix: roiPrefix,
                    percentPnl: parseValue(limitDecimals(Number(position.totalROI) / 1000, 2).replace("-", ""), 30),
                    pnl: position.realisedPnl,
                  },
                  rPnLPrifix,
                  leverage: formatAmount(leverage, 30, 2, true),
                  symbol: getTokenSymbolFromString(marketAsssets?.[position.tokenId]?.symbol),
                  quantity: posQty,
                  currentMarket,
                };
                return (
                  <tr
                    key={index}
                    className="table-content clickable"
                    onClick={() => handleSelectedPosition(completePosition)}
                  >
                    <td className="clickable">
                      <div>
                        <img
                          src={importImage("ic_" + getTokenSymbolFromString(symbol)?.toLowerCase() + "_24.svg")}
                          alt="tk"
                        />{" "}
                        <div className="tk_info">
                          <div className="Exchange-list-title">
                            {symbol} <span className="muted">#{position.posId}</span>
                          </div>
                          <div className="Exchange-list-info-label">
                            <span
                              className={cx({ positive: position.isLong, negative: !position.isLong })}
                              style={{ color: position.isLong ? "#03F5AE" : "#F6475D" }}
                            >
                              {position.isLong ? `LONG` : `SHORT`}
                            </span>
                            {leverage && (
                              <span
                                className="leverage-box"
                                style={{
                                  color: "#FFC700",
                                  backgroundColor: "rgba(255, 199, 0, 0.20)",
                                  height: 16,
                                  display: "inline-block",
                                  opacity: 1,
                                  borderRadius: "4px",
                                  padding: "0 4px",
                                  lineHeight: "15.8px",
                                  fontWeight: 400,
                                  marginLeft: "4px",
                                }}
                              >
                                {formatAmount(leverage, 30, 2, true)}X
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className={`flag ${position.isLong ? "isLong" : "isShort"}`}></div>
                    </td>
                    <td>
                      <div>{moment(new Date(position.createdAt * 1000)).format(dateFormat)}</div>
                      <div className="muted fz-12">
                        {moment(new Date(position.createdAt * 1000)).format(timeFormat)}
                      </div>
                    </td>
                    <td>
                      <div>{moment(new Date(position.closedAt * 1000)).format(dateFormat)}</div>
                      <div className="muted fz-12">{moment(new Date(position.closedAt * 1000)).format(timeFormat)}</div>
                    </td>
                    <td>
                      <div className="">{duration}</div>
                    </td>
                    <td className="clickable">
                      <div
                        className={`${!isLoss ? "isProfit" : "isLoss"} ${!isLoss ? "positive" : "negative"}`}
                        style={{
                          color: position.realisedPnl.includes("-") ? "#F6475D" : "#03F5AE ",
                        }}
                      >
                        {pnlPrefix}${formatAmount(position.realisedPnl, 30, 2, true).replace("-", "")}
                      </div>
                      <div className="muted fz-12">
                        {roiPrefix}
                        {limitDecimals(Number(position.totalROI) / 1000, 2).replace("-", "")}%
                      </div>
                    </td>
                    <td className="clickable">
                      ${formatAmount(position.averagePrice, 30, currentMarket?.decimals, true)}
                    </td>
                    <td className="clickable">
                      ${formatAmount(position.markPrice, 30, currentMarket?.decimals, true)}
                    </td>

                    <td>
                      <div> ${formatAmount(position.totalSize, 30, 2, true)}</div>
                      <div className="muted muted fz-12">
                        {" "}
                        {formatAmount(posQty, 30, 4, true)} {getTokenSymbolFromString(symbol)}
                      </div>
                    </td>
                    <td style={{ width: "30px" }}>
                      <button
                        className="PositionDropdown-dots-icon"
                        onClick={(e) => {
                          e.stopPropagation();
                          setPositionToShare(completePosition);
                          setIsPositionShareModalOpen(true);
                        }}
                      >
                        <img src={shareIcon} alt="icon" style={{ width: "16px", height: "16px", maxWidth: "16px" }} />
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      )}
      {isPositionShareModalOpen && (
        <PositionClosedShare
          setIsPositionShareModalOpen={setIsPositionShareModalOpen}
          isPositionShareModalOpen={isPositionShareModalOpen}
          selectedPosition={positionToShare}
          chainId={chainId}
          account={account}
          marketTokensInfo={marketTokensInfo}
        />
      )}
      {isPositionDetailsVisible && (
        <PositionClosed
          isVisible={isPositionDetailsVisible}
          setIsVisible={setIsPositionDetailsVisible}
          position={selectedPosition}
          chainId={chainId}
          marketTokensInfo={marketTokensInfo}
        />
      )}
      {count > DEFAULT_PAGE_SIZE_CLOSED && (
        <PaginationV2
          rowsPerPage={DEFAULT_PAGE_SIZE_CLOSED}
          rows={count}
          currentPage={currentPage}
          setCurrentPage={setCurrenPage}
        />
      )}
    </div>
  );
}
