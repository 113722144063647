import {
  CHART_HEIGHT,
  COLORS,
  YAXIS_WIDTH,
  tooltipFormatter as tooltipFormatter_,
  tooltipLabelFormatter as tooltipLabelFormatter_,
} from "lib/helper";
import { sumBy } from "lodash";
import React, { useMemo } from "react";
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ComposedChart,
  Label,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import styled from "styled-components";

import { useChartViewState } from "hooks/useChartViewState";
import { formatNumber } from "lib/numbers";
import { useMedia } from "react-use";
import ChartWrapper from "./ChartWrapper";

export default function GenericChart(props) {
  const COLOR_PIE = ["#366AE2", "#FC6B03", "#3AAC6D", "#DEA821", "#AC67E4"];
  const isMobile = useMedia("(max-width:600px)");

  const {
    loading,
    title,
    data,
    description,
    height = CHART_HEIGHT,
    yaxisWidth = YAXIS_WIDTH,
    yaxisDataKey = "all",
    yaxisScale,
    truncateYThreshold,
    yaxisTickFormatter,
    yaxisDomain = [0, "auto"],
    xaxisDataKey = "timestamp",
    xaxisTickFormatter = tooltipLabelFormatter_,
    tooltipFormatter = tooltipFormatter_,
    tooltipLabelFormatter = tooltipLabelFormatter_,
    items,
    type = "Bar",
    syncId,
    children,
    rightYaxisDataKey,
    controls = {},
    isLightTheme,
  } = props;

  const {
    viewState,
    togglePercentView,
    formattedData,
    yaxisTickFormatter: defaultYaxisTickFormatter,
    itemsUnit: defaultItemUnit,
  } = useChartViewState({ controls, data });

  let ChartComponent;

  if (type === "Line") {
    ChartComponent = LineChart;
  } else if (type === "Bar") {
    ChartComponent = BarChart;
  } else if (type === "Area") {
    ChartComponent = AreaChart;
  } else {
    ChartComponent = ComposedChart;
  }

  const truncatedYDomain = useMemo(() => {
    if (typeof truncateYThreshold !== "number" || !data) {
      return null;
    }

    if (Math.max(...data.map((item) => item[yaxisDataKey])) > truncateYThreshold) {
      // Bug in recharts: dataMax number values applies via function syntax only
      // eslint-disable-next-line no-unused-vars
      return [yaxisDomain[0], (_) => truncateYThreshold];
    }

    return null;
  }, [data, truncateYThreshold, yaxisDomain?.join("-")]);

  const htmlItems = (items || []).map((item, i) => {
    const props = {
      type: "monotone",
      dataKey: item.key,
      stackId: "a",
      name: item.name || item.key,
      fill: item.color || COLORS[i % COLORS.length],
      stroke: item.color || COLORS[i % COLORS.length],
      dot: item.dot || false,
      key: "item-" + i,
      unit: item.unit || defaultItemUnit,
      strokeWidth: item.strokeWidth,
      yAxisId: item.yAxisId,
    };

    if (item.type === "Line" || type === "Line") {
      return <Line {...props} type={undefined} isAnimationActive={false} />;
    }

    if (type === "Area") {
      return <Area {...props} type={undefined} isAnimationActive={false} />;
    }

    return <Bar {...props} isAnimationActive={false} />;
  });

  const csvFields = type !== "Pie" ? items.map((item) => ({ key: item.key, name: item.name })) : [];
  const CustomTooltip = (props) => {
    const { active, payload, label } = props;
    if (active && payload && payload.length) {
      const textColor = payload[0].payload.fill || payload[0].payload.stroke || "red";
      return (
        <div className="custom-tooltip" style={{ borderRadius: "4px" }}>
          <div className="data-row">
            <div
              className="dot"
              style={{
                width: 16,
                height: 16,
                backgroundColor: textColor,
              }}
            />
            <div className="label" style={{ color: textColor }}>
              {payload[0].name}:{" "}
            </div>
            <div style={{ color: textColor }}>${payload[0].value ? `${formatNumber(payload[0].value)}` : "0.00"}</div>
          </div>
        </div>
      );
    }

    return null;
  };

  const isEmptyData = useMemo(() => {
    return data.every((d) => Number(d.value) === 0);
  }, [data]);

  const dataWithoutEmptyFields = useMemo(() => {
    const sum = sumBy(data, "value");
    const validData = data.filter((d) => Number(d.value) >= (sum * 3) / 100); //Filter not use data < 5%
    const sortAsc = validData.sort((a, b) => a.value - b.value);
    return sortAsc;
  }, [data]);
  if (type === "Pie") {
    const startAngle = 90;
    return (
      <ChartWrapper title={title} data={data}>
        <PieChartWrap>
          {/* <ResponsiveContainer height={isMobile ? 150 : 200} width={isMobile ? 150 : 200}> */}
          <ResponsiveContainer height={150} width={150}>
            <PieChart height={150} width={150}>
              {!isEmptyData && (
                <>
                  <Pie
                    data={dataWithoutEmptyFields}
                    innerRadius={45}
                    outerRadius={75}
                    fill="#8884d8"
                    dataKey="value"
                    nameKey="name"
                    cursor="pointer"
                    startAngle={startAngle}
                    endAngle={startAngle + 360}
                    label={(entry) => entry.name}
                    labelLine={false}
                  >
                    {dataWithoutEmptyFields.map((_, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLOR_PIE[index % COLOR_PIE.length]}
                        stroke={COLOR_PIE[index % COLOR_PIE.length]}
                        style={{
                          cursor: "pointer",
                        }}
                      />
                    ))}
                  </Pie>
                  <Tooltip content={<CustomTooltip />} active={false} />
                </>
              )}
              {isEmptyData && (
                <Pie
                  innerRadius={45}
                  outerRadius={75}
                  // innerRadius={isMobile ? 42 : 65}
                  // outerRadius={isMobile ? 75 : 98}
                  dataKey="value"
                  data={[{ name: "No Data", value: 0.1 }]}
                  fill={isLightTheme ? "rgba(0, 0, 0, 0.10)" : "rgba(255, 255, 255, 0.10)"}
                  stroke={isLightTheme ? "rgba(255, 255, 255, 0.10)" : "#ffffff00"}
                  className="pie-chart-custom"
                >
                  <Label value="No Data" fontSize={14} position="center" />
                </Pie>
              )}
            </PieChart>
          </ResponsiveContainer>
          {/* <LabelContainer>
            {dataWithoutEmptyFields.map((item, index) => (
              <LabelItem key={item.name} className="label-chart-item">
                {" "}
                <div style={{ background: COLOR_PIE[index] }} className="dot"></div> {item.name}
              </LabelItem>
            ))}
          </LabelContainer> */}
        </PieChartWrap>
      </ChartWrapper>
    );
  }

  return (
    <ChartWrapper
      title={title}
      loading={loading}
      data={formattedData}
      csvFields={csvFields}
      viewState={viewState}
      controls={controls}
      togglePercentView={togglePercentView}
    >
      <ResponsiveContainer width="100%" height={height}>
        {React.createElement(ChartComponent, { data: formattedData, syncId }, [
          <CartesianGrid strokeDasharray="1 1" key="a" />,
          <XAxis dataKey={xaxisDataKey} tickFormatter={xaxisTickFormatter} minTickGap={30} key="b" />,
          <YAxis
            scale={yaxisScale}
            domain={truncatedYDomain || yaxisDomain}
            dataKey={yaxisDataKey}
            tickFormatter={yaxisTickFormatter || defaultYaxisTickFormatter}
            key="c"
          />,
          rightYaxisDataKey ? (
            <YAxis
              dataKey={rightYaxisDataKey}
              tickFormatter={yaxisTickFormatter}
              orientation="right"
              yAxisId="right"
              key="c2"
            />
          ) : null,
          <Tooltip
            formatter={tooltipFormatter}
            labelFormatter={tooltipLabelFormatter}
            contentStyle={{ textAlign: "left" }}
            key="d"
          />,
          <Legend key="e" />,
          ...htmlItems,
          children,
        ])}
      </ResponsiveContainer>
      {description && <div className="chart-description">{description}</div>}
    </ChartWrapper>
  );
}
const LabelContainer = styled.div`
  width: 100%;
`;
const LabelItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);

  .dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
`;
const PieChartWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  margin: 32px 0 24px;
  .data-row {
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: flex-start;

    &.parent {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  @media screen and (max-width: 767px) {
    padding-left: 16px;
  }

  .recharts-wrapper svg {
    overflow: inherit !important;
  }

  .recharts-text.recharts-label {
    tspan {
      font-size: 16px;
      fill: #fff;
      font-weight: 500;
    }
  }
`;
