import {
  ARBITRUM,
  ARBITRUM_TESTNET,
  AVALANCHE,
  MAINNET,
  OP,
  FANTOM,
  FANTOM_LEGACY,
  FANTOM_TESTNET,
  getChainKey,
  OP_TESTNET,
  BASE,
} from "./chains";

export const STATS_API_URL = "https://api.mummy.finance/v2/fantom/public/app-stats";

const BACKEND_URLS = {
  default: "https://api.mummy.finance/v2/fantom",
  // [MAINNET]: "https://gambit-server-staging.uc.r.appspot.com",
  // [ARBITRUM_TESTNET]: "https://gambit-server-devnet.uc.r.appspot.com",
  // [AVALANCHE]: "https://gmx-avax-server.uc.r.appspot.com",
  // [FANTOM_TESTNET]: "https://api.mummy.finance/fantom-testnet",
  [FANTOM_TESTNET]: "",
  [OP_TESTNET]: "",
  [FANTOM]: "https://api.mummy.finance/v2/fantom",
  [OP]: "https://api.mummy.finance/v2/optimism",
  [BASE]: "",
};

export function getServerBaseUrl(chainId: number) {
  if (!chainId) {
    throw new Error("chainId is not provided");
  }
  if (document.location.hostname.includes("deploy-preview")) {
    const fromLocalStorage = localStorage.getItem("SERVER_BASE_URL");
    if (fromLocalStorage) {
      return fromLocalStorage;
    }
  }

  return BACKEND_URLS[chainId] || BACKEND_URLS.default;
}

export function getServerUrl(chainId: number, path: string) {
  return `${getServerBaseUrl(chainId)}${path}`;
}
export function getV2ServerUrl(chainId: number, path: string) {
  return `${getServerBaseUrl(chainId)}${path}`;
}

export function getServerUrlTemp(chainId: number, path: string) {
  return `${getServerBaseUrl(chainId)}${path}/${getChainKey(chainId)}`;
}
