import React, { useContext } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";

import fantomImg from "img/ic_ftm_40.svg";
import opImg from "img/ic_op_40.svg";
import baseImg from "img/ic_base_40.svg";
import arbImg from "img/ic_arbitrum_24.svg";
import useSwitchChain from "hooks/useSwitchChain";
import chainPattern from "img/home/chain_pattern.svg";

import { FANTOM, OP, ARBITRUM, BASE } from "config/chains";
import { StatsContext } from "contexts/StatsProvider";
import { formatAmount, parseValue } from "lib/numbers";
import { USD_DECIMALS } from "lib/legacy";
import { getSkeletonClassName } from "utils/skeletonHelper";
import { BigNumber } from "ethers";
import ExternalLink from "components/ExternalLink/ExternalLink";

const AvailableNetworks = (chainId) => {
  const [switchChain] = useSwitchChain(chainId);

  const { v2, totalValueLocked, totalFees, totalTradingVolume } = useContext(StatsContext);

  return (
    <Wrapper>
      <div className="container">
        <Title>Available on your preferred network</Title>
        <Description>Mummy is currently live on Fantom, Optimism, Arbitrum and Base.</Description>
        <Content>
          <Fade left>
            <Chain>
              <img src={fantomImg} alt="fantom-icon" />
              <div className="chain-name">Fantom</div>
              <div className="chain-info">
                <div className="row">
                  <span>Total Volume</span>
                  <div className={`value ${getSkeletonClassName(totalTradingVolume)}`}>
                    $
                    {totalTradingVolume &&
                      totalTradingVolume[FANTOM] &&
                      v2?.[FANTOM]?.["volume"] &&
                      formatAmount(
                        BigNumber.from(totalTradingVolume[FANTOM]).add(parseValue(v2?.[FANTOM]["volume"], USD_DECIMALS)),
                        USD_DECIMALS,
                        0,
                        true
                      )}
                  </div>
                </div>
                <div className="row">
                  <span>Total Value Locked</span>
                  <div className={`value ${getSkeletonClassName(totalValueLocked[FANTOM])}`}>
                    ${totalValueLocked[FANTOM] &&
                    totalValueLocked.v2?.[FANTOM] &&
                    formatAmount(totalValueLocked[FANTOM].add(totalValueLocked.v2?.[FANTOM]), USD_DECIMALS, 0, true)}
                  </div>
                </div>
                <div className="row">
                  <span>Total Fees</span>
                  <div className={`value ${getSkeletonClassName(totalFees)}`}>
                    $
                    {totalFees[FANTOM] &&
                      v2?.["fees"] &&
                      formatAmount(
                        BigNumber.from(totalFees[FANTOM]).add(parseValue(v2?.["fees"], USD_DECIMALS)),
                        USD_DECIMALS,
                        0,
                        true
                      )}
                  </div>
                </div>
              </div>
              <div className="action-btn">
                <LauchButton href="/#/trade" target="_self" onClick={() => switchChain(FANTOM)} className="fantom">
                  Launch App
                </LauchButton>
              </div>
            </Chain>
          </Fade>
          <Fade bottom>
            <Chain>
              <img src={opImg} alt="op-icon" />
              <div className="chain-name">Optimism</div>
              <div className="chain-info">
                <div className="row">
                  <span>Total Volume</span>
                  <div className={`value ${getSkeletonClassName(totalTradingVolume[OP])}`}>
                    $
                    {totalTradingVolume &&
                      totalTradingVolume[OP] &&
                      v2?.[OP]?.["volume"] &&
                      formatAmount(
                        BigNumber.from(totalTradingVolume[OP]).add(parseValue(v2?.[OP]["volume"], USD_DECIMALS)),
                        USD_DECIMALS,
                        0,
                        true
                      )}
                  </div>
                </div>
                <div className="row">
                  <span>Total Value Locked</span>
                  <div className={`value ${getSkeletonClassName(totalValueLocked[OP])}`}>
                    ${totalValueLocked[OP] && 
                    totalValueLocked.v2?.[OP] &&
                    formatAmount(totalValueLocked[OP].add(totalValueLocked.v2?.[OP]), USD_DECIMALS, 0, true)}
                  </div>
                </div>
                <div className="row">
                  <span>Total Fees</span>
                  <div className={`value ${getSkeletonClassName(totalFees[OP])}`}>
                    ${formatAmount(totalFees[OP], USD_DECIMALS, 0, true)}
                  </div>
                </div>
              </div>
              <div className="action-btn">
                <LauchButton
                  href="https://app.mummy.finance/#/v1"
                  target="_self"
                  onClick={() => switchChain(OP)}
                  className="optimism"
                >
                  Launch App
                </LauchButton>
              </div>
            </Chain>
          </Fade>
          <Fade bottom>
            <Chain
              style={{
                background: "rgba(0, 119, 229, 0.10)",
              }}
            >
              <img src={arbImg} alt="arb-icon" />
              <div className="chain-name">Arbitrum</div>
              <div className="chain-info">
                <div className="row">
                  <span>Total Volume</span>
                  <div className={`value ${getSkeletonClassName(totalTradingVolume[ARBITRUM])}`}>
                    ${formatAmount(totalTradingVolume[ARBITRUM], USD_DECIMALS, 0, true)}
                  </div>
                </div>
                <div className="row">
                  <span>Total Value Locked</span>
                  <div className={`value ${getSkeletonClassName(totalValueLocked[ARBITRUM])}`}>
                    ${formatAmount(totalValueLocked[ARBITRUM], USD_DECIMALS, 0, true)}
                  </div>
                </div>
                <div className="row">
                  <span>Total Fees</span>
                  <div className={`value ${getSkeletonClassName(totalFees[ARBITRUM])}`}>
                    ${formatAmount(totalFees[ARBITRUM], USD_DECIMALS, 0, true)}
                  </div>
                </div>
              </div>
              <div className="action-btn">
                <LauchButton
                  href="https://app.mummy.finance/#/v1"
                  target="_self"
                  onClick={() => switchChain(ARBITRUM)}
                  className="arb"
                >
                  Launch App
                </LauchButton>
              </div>
            </Chain>
          </Fade>
          <Fade right>
            <Chain
              style={{
                background: "rgba(0, 82, 255, 0.10)",
              }}
            >
              <img src={baseImg} alt="base-icon" />
              <div className="chain-name">Base</div>
              <div className="chain-info">
                <div className="row">
                  <span>Total Volume</span>
                  <div className={`value ${getSkeletonClassName(totalTradingVolume[BASE])}`}>
                    ${formatAmount(totalTradingVolume[BASE], USD_DECIMALS, 0, true)}
                  </div>
                </div>
                <div className="row">
                  <span>Total Value Locked</span>
                  <div className={`value ${getSkeletonClassName(totalValueLocked[BASE])}`}>
                    ${formatAmount(totalValueLocked[BASE], USD_DECIMALS, 0, true)}
                  </div>
                </div>
                <div className="row">
                  <span>Total Fees</span>
                  <div className={`value ${getSkeletonClassName(totalFees[BASE])}`}>
                    ${formatAmount(totalFees[BASE], USD_DECIMALS, 0, true)}
                  </div>
                </div>
              </div>
              <div className="action-btn">
                <LauchButton
                  href="https://app.mummy.finance/#/v1"
                  target="_self"
                  onClick={() => switchChain(BASE)}
                  className="base"
                >
                  Launch App
                </LauchButton>
              </div>
            </Chain>
          </Fade>
        </Content>
      </div>
    </Wrapper>
  );
};

const Content = styled.div`
  display: grid;
  gap: 24px;
  width: 100%;
  margin-top: 20px;
  grid-template-columns: repeat(4, 1fr);

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
    padding: 0;
  }

  @media (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const Chain = styled.div`
  flex: 1;
  position: relative;
  padding: 24px;
  border-radius: 24px;
  background-color: #13b5ec1a;
  overflow: hidden;

  &:has(img[alt="op-icon"]) {
    background-color: #ff04201a;
  }

  > img {
    width: 80px;
    height: 80px;
    position: absolute;
    top: -12px;
    right: -12px;
  }

  .chain-name {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;
  }

  .chain-info {
    display: flex;
    align-items: center;
    gap: 16px;
    flex-direction: column;
    margin: 24px 0;

    .row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      > span {
        color: #ffffff99;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
      }

      .value {
        font-weight: 700;
        font-size: 14px;
        line-height: 140%;
        color: #fff;

        &.skeleton-box {
          width: 100px;
        }
      }
    }
  }

  @media (max-width: 767px) {
    padding: 16px;

    > img {
      width: 64px;
      height: 64px;
    }

    .chain-name {
      font-size: 16px;
    }

    .chain-info {
      margin: 16px 0;
      gap: 12px;

      .row {
        > span {
          font-size: 14px;
        }

        .value {
          font-size: 14px;
        }
      }
    }
  }
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 36px;
  line-height: 140%;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  color: #fff !important;
  @media (max-width: 1024px) {
  }

  @media (max-width: 767px) {
    font-size: 20px;
  }
`;

const Description = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  text-align: left;
  color: #ffffff99 !important;

  @media (max-width: 767px) {
    font-size: 12px;
  }
`;

const LauchButton = styled(ExternalLink)`
  display: flex;
  flex: 1;
  height: 40px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 145%;
  border: none;
  outline: none;
  padding: 10px;
  background-color: #13b5ec;
  text-decoration: none;
  text-align: center;
  justify-content: center;
  color: #fff;
  &:hover {
    filter: brightness(80%);
    transition: 0.3s;
  }

  &.optimism {
    background-color: #ff2d44;
  }
  &.base {
    background-color: #0077e5;
  }

  &.arb {
    background-color: #0077e5;
  }
`;

const Wrapper = styled.div`
  overflow: hidden;
  padding: 64px 0px;
  background-image: linear-gradient(
    90deg,
    rgba(0, 190, 255, 0.2) 0.49%,
    rgba(255, 255, 255, 0) 50.76%,
    rgba(255, 4, 32, 0.2) 100%
  );
  position: relative;

  &::before {
    content: "";
    background-image: url(${chainPattern});
    background-position: center;
    position: absolute;
    opacity: 0.15;
    inset: 0;
  }

  .container {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 16px;
  }

  .action-btns {
    width: 424px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin-top: 20px;
  }

  @media (max-width: 1024px) {
    padding-left: 24px;
    padding-right: 24px;
  }

  @media (max-width: 767px) {
    height: fit-content;
    padding-left: 16px;
    padding-right: 16px;

    .action-btns {
      grid-template-columns: repeat(1, 171px);
      padding: 0 102px;
    }
  }
`;

export default AvailableNetworks;
