import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useGmxPrice } from "domain/legacy";
import { formatAmount } from "lib/numbers";
import { GMX_DECIMALS, USD_DECIMALS, isActiveVmmyV2 } from "lib/legacy";
import { getContract } from "config/contracts";
import { DEFAULT_CHAIN_ID, FANTOM, OP } from "config/chains";

const useTokensPrice = (options) => {
  const { gmxPrice = undefined, chainId = DEFAULT_CHAIN_ID } = options || {};
  const [assets, setAssets] = useState({});
  const vMMYAddress = getContract(isActiveVmmyV2(chainId) ? chainId : DEFAULT_CHAIN_ID, "vMMY");

  useEffect(() => {
    const fetchIds = async () => {
      var url = "https://api.mummy.finance/api/tokens/info?symbols=WFTM,FTM,USDC&chain=FANTOM";
      if (chainId === OP) {
        url = "https://api.mummy.finance/api/tokens/info?symbols=WETH,ETH,USDC&chain=OPTIMISM";
      }
      const res = await axios.get(url, {});
      if (res && res.data) {
        const data = res.data || [];
        const result = {};

        Object.values(data).forEach((d) => {
          // if (d.symbol === "NAV") {
          //   result["esNAV"] = {
          //     price: d.price,
          //     decimal: d.decimal,
          //   };
          // }
          result[d.symbol] = {
            price: d.price,
            decimal: d.decimal,
          };
          result[d.address] = {
            price: d.price,
            decimal: d.decimal,
          };
        });

        setAssets(result);
      }
    };

    fetchIds();
    const interval = setInterval(fetchIds, 30000);
    return () => clearInterval(interval);
  }, [chainId]);

  const parsedGmxPrice = gmxPrice ? +formatAmount(gmxPrice, USD_DECIMALS, USD_DECIMALS) : null;

  return {
    ...assets,
    ...(parsedGmxPrice && {
      vMMY: {
        price: parsedGmxPrice,
        decimal: GMX_DECIMALS,
      },
      [vMMYAddress]: {
        price: parsedGmxPrice,
        decimal: GMX_DECIMALS,
      },
    }),
  };
};

export default useTokensPrice;
