import { BigNumber } from "ethers";
import useSWR from "swr";

import { contractFetcher } from "lib/contracts";
import { PLACEHOLDER_ACCOUNT } from "lib/legacy";
import { ZERO_BIGNUMBER } from "utils/bignumber";

import Token from "abis/Token.json";
import { getToken } from "config/tokens";

export default function useTokenContract(
  chainId: number,
  active: boolean,
  account: string | null | undefined,
  tokenAddress: string,
  spender?: string,
) {
  const token = getToken(chainId, tokenAddress);

  const { data: balanceOf } = useSWR<BigNumber>(
    [
      `Token:balanceOf:${active}:${account}`,
      chainId,
      tokenAddress,
      "balanceOf",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(undefined, Token),
    },
  );

  return {
    ...token,
    balance: balanceOf || ZERO_BIGNUMBER,
  };
}
