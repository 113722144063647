import { Select } from "antd";
import styled from "styled-components";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";

import ReferralsItem from "./ReferralsItem";

import upIc from "img/upArrow.svg";
import upIcWhite from "img/upArrowWhite.svg";
import downIc from "img/downArrow.svg";

import "../styles/index.scss";

import { TabWrapper } from "../styles/Analytics.styled";
import inInfo from "img/ic_info.svg";
import Pagination from "./Pagination";
import DateFilter, { StyledDateFilter } from "components/DateFilter/DateFilter";
import Loader from "components/Common/Loader";
import ExternalLink from "components/ExternalLink/ExternalLink";
import Countdown from "react-countdown";
import NoItemFound from "./NoItemFound";
import { EXTERNAL_LINK, getApiAnalytics } from "../config";
import Tab from "components/Tab/Tab";
import moment from "moment";
import { ARBITRUM, BASE, FANTOM, OP } from "config/chains";
import Tooltip from "components/Tooltip/Tooltip";
import UpArrowIcon from "components/Icon/AnalyticIcon/upArrowIc";
import { useThemeContext } from "contexts/ThemeProvider";

const ORDER_BY_OPTIONS = [
  {
    value: "descTotalReferrals",
    label: "Highest to lowest in Traders Referred",
  },
  {
    value: "ascTotalReferrals",
    label: "Lowest to highest in Traders Referred",
  },
  {
    value: "descVolumeReferred",
    label: "Highest to lowest in Total Volume Referred",
  },
  {
    value: "ascVolumeReferred",
    label: "Lowest to highest in Total Volume Referred",
  },
  {
    value: "descRewards",
    label: "Highest to lowest in Rewards",
  },
  {
    value: "ascRewards",
    label: "Lowest to highest in Rewards",
  },
];

const CONTEST_TIMESTAMP = {
  [FANTOM]: ["2023-02-19", "2023-03-19", "12,000 $FTM"],
  [OP]: ["2023-03-15", "2023-03-16", "1 $ETH"],
  [ARBITRUM]: ["2023-03-15", "2023-03-16", "1 $ETH"],
  [BASE]: ["2023-03-15", "2023-03-16", "1 $ETH"],
};

export const ALL_STATS = "All Stats";
export const REF_CONTEST = "Referral Contest";

const TAB_OPTIONS = [ALL_STATS, REF_CONTEST];
const TAB_OPTIONS_LABELS = {
  [ALL_STATS]: `All Stats`,
  [REF_CONTEST]: `Referral Contest`,
};

export const getUnixDate = (str) => +moment.utc(str).toDate() / 1000;
export const getUnixDateHour = (str) => moment.utc(str).format("HH:mm");

const TopReferrals = ({ chainId, isThemeLight }) => {
  const { lightThemeClassName } = useThemeContext();
  const [activeTab, setActiveTab] = useState(ALL_STATS);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [orderBy, setOrderBy] = useState("descVolumeReferred");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [sortType, setSortType] = useState("desc"); // desc down,asc up
  const [sortBy, setSortBy] = useState("volumeReferred"); // totalReferrals,volumeReferred,rewards
  const [currentPage, setCurrentPage] = useState(1);
  const [row, setRows] = useState(100);
  const [perPage, setPerPage] = useState(10);
  const MAX_NUMBER_PAGE = 100; //HARD FIXED
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const startContestDate = useRef();
  const endContestDate = useRef();
  const [lastUpdate, setLastUpdate] = useState();

  useEffect(() => {
    startContestDate.current = getUnixDate(CONTEST_TIMESTAMP[chainId][0]);
    endContestDate.current = getUnixDate(CONTEST_TIMESTAMP[chainId][1]);
  }, [chainId]);

  const countdownRenderer = (countdownProps) => {
    const { days, hours, minutes, seconds } = countdownProps;
    const d = String(days);
    const h = String(hours);
    const m = String(minutes);
    const s = String(seconds);
    return (
      <div className="time-countdown">
        <div className="time-left">
          {d.padStart(2, "0")}
          <span>d </span>
        </div>{" "}
        <div className="space">: </div>
        <div className="time-left">
          {h.padStart(2, "0")}
          <span>h </span>
        </div>{" "}
        <div className="space">: </div>
        <div className="time-left">
          {m.padStart(2, "0")}
          <span>m</span>{" "}
        </div>{" "}
        <div className="space">: </div>
        <div className="time-left">
          {s.padStart(2, "0")}
          <span>s</span>
        </div>
      </div>
    );
  };

  const timeMonth = new Date();
  timeMonth.setMonth(timeMonth.getMonth() - 1);

  let querySort = "";

  if (sortBy) {
    if (sortBy === "totalReferrals") {
      if (sortType === "asc") {
        querySort = `&order=totalReferralRegistered%20ASC`;
      } else {
        querySort = `&order=totalReferralRegistered%20DESC`;
      }
    } else {
      if (sortBy === "volumeReferred") {
        if (sortType === "asc") {
          querySort = `&order=volume%20ASC`;
        } else {
          querySort = `&order=volume%20DESC`;
        }
      } else {
        if (sortType === "asc") {
          querySort = `&order=reward%20ASC`;
        } else {
          querySort = `&order=reward%20DESC`;
        }
      }
    }
  }

  const handleStartDate = (date) => {
    setStartDate(date);
  };

  const handleEndDate = (date) => {
    setEndDate(date);
  };

  const handleSearch = () => {
    if (endDate < startDate) {
      setIsLoading(false);
      return;
    }
    setFromDate(startDate / 1000);
    setToDate(endDate / 1000);
    setIsLoading(false);
  };

  const handleRefresh = () => {
    setIsLoading(true);
    setStartDate();
    setEndDate();
    setFromDate();
    setToDate();
    fetchData(currentPage, perPage, startDate, endDate, querySort);
  };

  const handleSortTotalReferrals = () => {
    let resultSortType = "";

    if (sortBy === "totalReferrals") {
      if (sortType !== "asc") {
        resultSortType = "asc";
      } else {
        resultSortType = "desc";
      }
    } else {
      resultSortType = "desc";
    }
    setSortBy("totalReferrals");
    setSortType(resultSortType);
  };

  const handleSortVolumeReferred = () => {
    let resultSortType = "";

    if (sortBy === "volumeReferred") {
      if (sortType !== "asc") {
        resultSortType = "asc";
      } else {
        resultSortType = "desc";
      }
    } else {
      resultSortType = "desc";
    }
    setSortBy("volumeReferred");
    setSortType(resultSortType);
  };

  const handleSortRewards = () => {
    let resultSortType = "";

    if (sortBy === "rewards") {
      if (sortType !== "asc") {
        resultSortType = "asc";
      } else {
        resultSortType = "desc";
      }
    } else {
      resultSortType = "desc";
    }
    setSortBy("rewards");
    setSortType(resultSortType);
  };

  const fetchData = async (currentPage, perPage, fromDate, toDate, querySort) => {
    setIsLoading(true);
    const response = await axios(
      `${getApiAnalytics(chainId)}leaderboard/top-referrers?page=${currentPage}&pageSize=${perPage}&from=${
        fromDate || 1652288400
      }&to=${toDate || ""}${querySort}`
    );

    setRows(response.data.count);
    setData(response.data.rows);
    setLastUpdate(response.data.lastDataUpdateTime);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData(currentPage, perPage, fromDate, toDate, querySort);
  }, [currentPage, perPage, querySort, fromDate, toDate, chainId, activeTab]);

  useEffect(() => {
    setActiveTab(ALL_STATS);
  }, [chainId]);

  useEffect(() => {
    if (activeTab === REF_CONTEST) {
      setFromDate(startContestDate.current);
      setToDate(endContestDate.current);
    } else {
      setFromDate(1652288400);
      setToDate("");
    }
  }, [activeTab]);

  const isSmallScreen = useMediaQuery({ query: "(max-width: 1024px)" });

  const renderTop = () => {
    switch (sortBy) {
      case "totalReferrals":
        return "Sort by Total Referred";
      case "volumeReferred":
        return "Sort by Volume Referred";
      case "rewards":
        return "Sort by Rewards";
      default:
        return "Sort by Volume Referred";
    }
  };
  const renderTopIndex = (index) => {
    let isDesc = true;
    if (sortType === "asc") isDesc = false;
    if (isDesc) return index + 1 + perPage * (currentPage - 1);
    else {
      return row - (index + perPage * (currentPage - 1));
    }
  };
  return (
    <TabWrapper>
      {activeTab !== ALL_STATS && (
        <StyledText>
          The top 2 referrers with the highest trading volume under their referral code will be rewarded
          <p>{CONTEST_TIMESTAMP[chainId][2]}</p>
        </StyledText>
      )}

      <StyledTab>
        {chainId !== OP && chainId !== ARBITRUM && chainId !== FANTOM && chainId !== BASE && (
          <div className="tab-wrapper">
            <TabInner
              options={TAB_OPTIONS}
              optionLabels={TAB_OPTIONS_LABELS}
              option={activeTab}
              setOption={setActiveTab}
              onChange={setActiveTab}
            />
          </div>
        )}
        {activeTab === ALL_STATS && (
          <DateFilter
            startDate={startDate}
            endDate={endDate}
            handleEndDate={handleEndDate}
            handleStartDate={handleStartDate}
            handleSearch={handleSearch}
            handleRefresh={handleRefresh}
          />
        )}
      </StyledTab>
      {!isLoading && !isSmallScreen && (
        <span className="label-last-update onleft">
          Last update: {moment(Number(lastUpdate || "") * 1000).fromNow()}
        </span>
      )}

      {activeTab !== ALL_STATS && (
        <StyledTimestamp>
          The competition runs{" "}
          {activeTab === ALL_STATS
            ? "All time"
            : `from ${moment(startContestDate.current * 1000).format("ll")}, to ${moment(
                endContestDate.current * 1000
              ).format("ll")}`}
          .{" "}
          {Math.round(new Date().getTime() / 1000) > startContestDate.current ? (
            <>
              Ends in <Countdown date={new Date(endContestDate.current * 1000)} renderer={countdownRenderer} />
            </>
          ) : (
            <>
              Starts in <Countdown date={new Date(startContestDate.current * 1000)} renderer={countdownRenderer} />
            </>
          )}
          . <StyledExternalLink href={EXTERNAL_LINK[chainId].moreDetail}>More details</StyledExternalLink>
        </StyledTimestamp>
      )}

      {isSmallScreen && (
        <>
          <div className="filter-block">
            Ordered by:{" "}
            <Select
              showArrow
              suffixIcon={<img className="downIc" src={downIc} alt="" />}
              onChange={(value) => {
                setOrderBy(value);
                if (value === "ascTotalReferrals") {
                  setSortBy("totalReferrals");
                  setSortType("asc");
                }

                if (value === "descTotalReferrals") {
                  setSortBy("totalReferrals");
                  setSortType("desc");
                }

                if (value === "ascVolumeReferred") {
                  setSortBy("volumeReferred");
                  setSortType("asc");
                }

                if (value === "descVolumeReferred") {
                  setSortBy("volumeReferred");
                  setSortType("desc");
                }

                if (value === "ascRewards") {
                  setSortBy("rewards");
                  setSortType("asc");
                }

                if (value === "descRewards") {
                  setSortBy("rewards");
                  setSortType("desc");
                }
              }}
              defaultValue={orderBy}
              style={{
                width: "fit-content",
              }}
              bordered={false}
              options={ORDER_BY_OPTIONS}
              popupClassName={lightThemeClassName}
            />
            {!isLoading && (
              <span className="label-last-update" style={{ height: 34, display: "flex", alignItems: "center" }}>
                Last update: {moment(Number(lastUpdate || "") * 1000).fromNow()}
              </span>
            )}
          </div>
        </>
      )}
      <div className="header-table referrals">
        <div>
          <span>
            <Tooltip
              handle={`Top`}
              position="left-bottom"
              className="fit-content nowrap"
              renderContent={() => {
                return <p className="text-white">{renderTop()}</p>;
              }}
            />
          </span>
          <span>Wallet</span>
        </div>
        <span>Tier</span>
        <span
          style={{
            cursor: "pointer",
          }}
          onClick={handleSortTotalReferrals}
        >
          Traders Referred
          <div className="group-arrow">
            <span>
              {sortBy === "totalReferrals" && sortType === "asc" ? (
                <UpArrowIcon fill={isThemeLight ? "#0D1A16" : "#fff"} className="up" />
              ) : (
                <UpArrowIcon fill={isThemeLight ? "#0D1A1633" : "#ADABAB"} className="up" />
              )}
            </span>

            <span>
              {sortBy === "totalReferrals" && sortType === "desc" ? (
                <UpArrowIcon fill={isThemeLight ? "#0D1A16" : "#fff"} className="down" />
              ) : (
                <UpArrowIcon fill={isThemeLight ? "#0D1A1633" : "#ADABAB"} className="down" />
              )}
            </span>
          </div>
        </span>
        <span>Number of Trades</span>
        <span
          style={{
            cursor: "pointer",
          }}
          onClick={handleSortVolumeReferred}
        >
          Total Volume Referred
          <div className="group-arrow">
            <span>
              {sortBy === "volumeReferred" && sortType === "asc" ? (
                <UpArrowIcon fill={isThemeLight ? "#0D1A16" : "#fff"} className="up" />
              ) : (
                <UpArrowIcon fill={isThemeLight ? "#0D1A1633" : "#ADABAB"} className="up" />
              )}
            </span>

            <span>
              {sortBy === "volumeReferred" && sortType === "desc" ? (
                <UpArrowIcon fill={isThemeLight ? "#0D1A16" : "#fff"} className="down" />
              ) : (
                <UpArrowIcon fill={isThemeLight ? "#0D1A1633" : "#ADABAB"} className="down" />
              )}
            </span>
          </div>
        </span>
        <span
          style={{
            cursor: "pointer",
          }}
          onClick={handleSortRewards}
        >
          Rewards
          <div className="group-arrow">
            <span>
              {sortBy === "rewards" && sortType === "asc" ? (
                <UpArrowIcon fill={isThemeLight ? "#0D1A16" : "#fff"} className="up" />
              ) : (
                <UpArrowIcon fill={isThemeLight ? "#0D1A1633" : "#ADABAB"} className="up" />
              )}
            </span>

            <span>
              {sortBy === "rewards" && sortType === "desc" ? (
                <UpArrowIcon fill={isThemeLight ? "#0D1A16" : "#fff"} className="down" />
              ) : (
                <UpArrowIcon fill={isThemeLight ? "#0D1A1633" : "#ADABAB"} className="down" />
              )}
            </span>
          </div>
        </span>

        <span></span>
      </div>
      <div className="body-table referrals">
        {!isLoading ? (
          data.length > 0 ? (
            data.map((item, index) => {
              const rank = currentPage * perPage - (perPage - 1);
              return (
                <ReferralsItem
                  hasPrize={activeTab === REF_CONTEST && sortBy === "volumeReferred" && sortType === "desc"}
                  key={index}
                  data={item}
                  rank={renderTopIndex(index)}
                  isThemeLight={isThemeLight}
                />
              );
            })
          ) : (
            <NoItemFound />
          )
        ) : (
          // <Loader />
          <div className="skeleton-box" style={{ height: "30px", marginTop: "20px" }} />
        )}
      </div>
      {data.length > 0 && (
        <Pagination
          rowsPerPage={perPage}
          setRowsPerPage={setPerPage}
          row={row}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          maxNumberOfPage={MAX_NUMBER_PAGE}
        />
      )}
    </TabWrapper>
  );
};

const StyledTab = styled.div`
  margin-bottom: 22.5px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .Tab-option {
    margin: auto;
    height: 45px;
    display: flex;
    align-items: center;
  }

  ${StyledDateFilter} {
    margin-left: auto;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const StyledTimestamp = styled.div`
  color: #b7b7bd;
  font-size: 14px;
  line-height: 2.15rem;
  margin-bottom: 2.325rem;

  .time-countdown {
    display: inline-flex;
  }

  .space {
    margin: 0 2.5px;
  }
`;

const StyledText = styled.div`
  margin: 10px 0 30px;
  display: flex;
  align-items: center;
  gap: 15px;

  p {
    display: inline-block;
    margin: 0;
    font-size: 1.75em;
    font-weight: 600;
    color: #34f5ae;
    text-shadow: rgb(56 229 103) 1px 1px 20px, rgb(56 229 103) 0px 0px 20px;
  }
`;

const StyledExternalLink = styled(ExternalLink)`
  color: #34f5ae;
`;

const TabInner = styled(Tab)`
  background-color: #191b2e !important;
  height: 60px;
  padding: 8px;
`;

export default TopReferrals;
