import cx from "classnames";
import arrow from "img/trade/arrow-down-green.svg";
import arrowLight from "img/trade/arrow-downLight.svg";
import arrowRed from "img/trade/arrow-down-red.svg";
import { useThemeContext } from "contexts/ThemeProvider";

export default function ExchangeInfoRow(props) {
  const { isLightTheme } = useThemeContext();
  const { label, children, value, isTop, isWarning, isHandler, isExpand, isPositive, textStyled } = props;

  const isPositiveClass = typeof isPositive === "undefined" ? "" : !!isPositive ? "green-value" : "red-value";
  return (
    <div
      className={cx("Exchange-info-row", { "top-line": isTop, handler: isHandler, isExpand: isExpand, isPositive })}
      {...props}
    >
      <div className="Exchange-info-label">{label}</div>
      <div
        className={`align-right ${isPositiveClass} ${isWarning ? "Exchange-info-value-warning" : ""}`}
        style={textStyled}
      >
        {children || value}
      </div>
      {isHandler &&
        (isPositiveClass === "red-value" ? (
          <img src={arrowRed} alt="arrow" />
        ) : (
          <img src={isLightTheme ? arrowLight : arrow} alt="arrow" />
        ))}
    </div>
  );
}
