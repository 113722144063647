import { useThemeContext } from "contexts/ThemeProvider";
import Portal from "../Common/Portal";
import Modal from "./Modal";

export default function ModalWithPortal(props) {
  const { lightThemeClassName, isLightTheme } = useThemeContext();

  return (
    <Portal>
      <Modal {...props} className={`${props.className} ${lightThemeClassName}`} />
    </Portal>
  );
}
