// @ts-nocheck
import axios from "axios";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import OrderItem from "../OrderItem";
import Pagination from "../Pagination";
import NoItemFound from "../NoItemFound";
import Loader from "components/Common/Loader";
import { getApiAnalytics } from "pages/Analytics/config";
import { DataTable, PositionHead, SkeletonLoadingTable, TableRow } from "pages/Analytics/table";
import { useThemeContext } from "contexts/ThemeProvider";
import { BigNumber } from "ethers";
import { formatAmount, getDisplayDecimalByAmount, parseValue } from "lib/numbers";
import { getTokenDecimal, getTokenSymbol } from "config/tokens";

import icView from "img/ic-view.svg";

export default function OrderTab({ account, chainId, isThemeLight }) {
  const { lightThemeClassName } = useThemeContext() as any;
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [row, setRows] = useState(100);
  const isSmallScreen = useMediaQuery({ query: "(max-width: 1023px)" });

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const response = await axios(
        `${getApiAnalytics(
          chainId
        )}leaderboard/trader-open-order?page=${currentPage}&pageSize=${perPage}&address=${account}`
      );

      setData(response.data.rows);
      setRows(response.data.count);
      setData(response.data.rows);
      setIsLoading(false);
    };

    fetchData();
  }, [account, currentPage, perPage, chainId]);

  // return (
  //   <>
  //     {data.length > 0 ? (
  //       <>
  //         <div className={isSmallScreen ? "table-mini" : "table"}>
  //           <div className="header" style={{ display: isSmallScreen ? "none" : "flex" }}>
  //             <div className="market child" style={{ width: "10%", flex: "unset" }}>
  //               Order Type
  //             </div>
  //             <div className="size child" style={{ width: "10%", flex: "unset" }}>
  //               Type
  //             </div>
  //             <div className="order child" style={{ width: "40%", flex: "unset" }}>
  //               Order
  //             </div>
  //             <div className="collateral child">Trigger Condition</div>
  //             <div className="child">Mark Price</div>
  //           </div>
  //           {!isLoading || data.length > 0 ? (
  //             data.map((item: any) => (
  //               <OrderItem
  //                 key={item.id}
  //                 chainId={chainId}
  //                 data={item}
  //                 isSmallScreen={isSmallScreen}
  //                 isThemeLight={isThemeLight}
  //               />
  //             ))
  //           ) : (
  //             // <Loader />
  //             <div className="skeleton-box" style={{ height: "30px", marginTop: "20px" }} />
  //           )}
  //         </div>
  //         <Pagination
  //           rowsPerPage={perPage}
  //           setRowsPerPage={setPerPage}
  //           row={row}
  //           currentPage={currentPage}
  //           setCurrentPage={setCurrentPage}
  //         />
  //       </>
  //     ) : (
  //       <NoItemFound />
  //     )}
  //   </>
  // );

  return (
    <>
      {data.length > 0 && (
        <>
          <DataTable $noIndex $autoHeight className={lightThemeClassName}>
            <div className="head">
              <div className="col-name">Order Type</div>
              <div className="col-name">Type</div>
              <div
                className="col-name"
                style={{
                  minWidth: 480,
                }}
              >
                Order
              </div>
              <div
                className="col-name"
                style={{
                  minWidth: 210,
                }}
              >
                Trigger Condition
              </div>
              <div
                className="col-name"
                style={{
                  minWidth: 210,
                }}
              >
                Mark Price
              </div>
            </div>
            <div className="body">
              {data.map((position: any, i) => {
                const triggerPricePrefix = position.triggerAboveThreshold ? "≥" : "≤";

                return (
                  <TableRow noScaleOnHover key={position.id || i}>
                    <div d-label="Order Type" className="col-body">
                      <PositionHead
                        position={position}
                        defaultTokenSymbol={getTokenSymbol(chainId, position.indexToken)}
                        hideLeverage
                      />
                    </div>
                    <div d-label="Type" className="col-body">
                      {["swap", "increase"].includes(position.type) ? "LIMIT" : "TRIGGER"}
                    </div>
                    <div
                      d-label="Order"
                      className="col-body col-min-width"
                      style={{
                        minWidth: 480,
                      }}
                    >
                      {position.type === "decrease" || position.type === "increase"
                        ? `${position.type === "decrease" ? "Decrease" : "Increase"} ${getTokenSymbol(
                            chainId,
                            position.indexToken
                          )} ${position.isLong ? "LONG" : "SHORT"} by $${formatAmount(position.size, 30, 2, true)}`
                        : position.type === "swap"
                        ? `Swap ${formatAmount(
                            position.amountIn,
                            getTokenDecimal(chainId, position.inputToken),
                            2,
                            true
                          )}
          ${getTokenSymbol(chainId, position.inputToken)} for at least ${formatAmount(
                            position.mintOut,
                            getTokenDecimal(chainId, position.outputToken),
                            2,
                            true
                          )} ${getTokenSymbol(chainId, position.outputToken)}`
                        : ""}
                    </div>
                    <div
                      d-label="Trigger Condition"
                      className="col-body"
                      style={{
                        minWidth: 210,
                      }}
                    >
                      {position.type === "swap"
                        ? !["BTC", "ETH"].includes(getTokenSymbol(chainId, position.inputToken)) &&
                          !["BTC", "ETH"].includes(getTokenSymbol(chainId, position.outputToken))
                          ? `${formatNumber2(1 / Number(formatAmount(position.triggerRatio, 30, 3)))} ${getTokenSymbol(
                              chainId,
                              position.outputToken
                            )} / ${getTokenSymbol(chainId, position.inputToken)}`
                          : ["BTC", "ETH"].includes(getTokenSymbol(chainId, position.inputToken))
                          ? `${formatNumber2(1 / (position.triggerRatio / 1e30))} ${getTokenSymbol(
                              chainId,
                              position.outputToken
                            )} / ${getTokenSymbol(chainId, position.inputToken)}`
                          : `${formatNumber2(Number(formatAmount(position.triggerRatio, 30, 3)))} ${getTokenSymbol(
                              chainId,
                              position.inputToken
                            )} / ${getTokenSymbol(chainId, position.outputToken)}`
                        : `Mark price ${triggerPricePrefix} $${formatAmount(
                            position.triggerPrice,
                            30,
                            getDisplayDecimalByAmount(position.triggerPrice, 30),
                            true
                          )}`}
                    </div>
                    <div
                      d-label="Mark Price"
                      className="col-body"
                      style={{
                        minWidth: 210,
                      }}
                    >
                      {position.type === "swap"
                        ? ["BTC", "ETH"].includes(getTokenSymbol(chainId, position.outputToken))
                          ? `${formatNumber2(
                              position.outputTokenMarketPrice / position.inputTokenMarketPrice
                            )} ${getTokenSymbol(chainId, position.inputToken)} / ${getTokenSymbol(
                              chainId,
                              position.outputToken
                            )}`
                          : `${formatNumber2(
                              position.inputTokenMarketPrice / position.outputTokenMarketPrice
                            )} ${getTokenSymbol(chainId, position.outputToken)} / ${getTokenSymbol(
                              chainId,
                              position.inputToken
                            )}`
                        : `$${formatAmount(
                            position.marketPrice,
                            30,
                            getDisplayDecimalByAmount(position.marketPrice, 30),
                            true
                          )}`}
                    </div>
                    {/* <img className="icon-view" src={icView} alt="view_ic" /> */}
                  </TableRow>
                );
              })}
            </div>
            {isLoading && <SkeletonLoadingTable noHeading />}
          </DataTable>
          <Pagination
            rowsPerPage={perPage}
            setRowsPerPage={setPerPage}
            row={row}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </>
      )}
      {data.length === 0 && !isLoading && <NoItemFound />}
    </>
  );
}

const formatNumber2 = (num) => {
  return Number(Number(num).toFixed(3)).toLocaleString("en-US", { minimumFractionDigits: 3 });
};
