import { FC } from "react";
import styled from "styled-components";

import { useChainId } from "lib/chains";
import { TXT } from "../multichainTxt";
import { useMedia } from "react-use";
const Banner2: FC = () => {
  const { chainId } = useChainId();
  const isIpad = useMedia("(max-width: 991px)");
  const isMobile = useMedia("(max-width: 500px)");

  const banner = isMobile
    ? TXT[chainId]?.middleBannerMB
    : isIpad
    ? TXT[chainId]?.middleBannerIP
    : TXT[chainId]?.middleBanner;
  return (
    <StyledBanner>
      <DeepBanner>
        <div className="banner-content" style={{ backgroundImage: `url(${banner})` }}></div>{" "}
      </DeepBanner>
    </StyledBanner>
  );
};

const StyledBanner = styled.section`
  margin-bottom: 120px;
  @media screen and (max-width: 991px) {
    margin-bottom: 72px;
  }
  @media screen and (max-width: 700px) {
    margin-bottom: 48px;
  }
`;
const DeepBanner = styled.div`
  width: 100%;
  height: 160px;
  left: 0;
  overflow: hidden;
  /* background: linear-gradient(
    270deg,
    rgba(217, 217, 217, 0.2) 0%,
    #d9d9d9 49.48%,
    rgba(217, 217, 217, 0.2) 100%
  ); */
  mask-image: linear-gradient(
    270deg,
    rgba(217, 217, 217, 0.7) 0%,
    rgb(217, 217, 217) 49.48%,
    rgba(217, 217, 217, 0.7) 100%
  );
  @media screen and (max-width: 991px) {
    height: 120px;
  }
  @media screen and (max-width: 700px) {
    height: 100px;
  }
  @keyframes slide {
    0% {
      transform: translate3d(0, 0, 0);
    }
    100% {
      transform: translate3d(-1692px, 0, 0); /* The image width */
    }
  }
  .banner-content {
    height: 160px;
    background-repeat: repeat-x;

    width: 5076px;
    animation: slide 60s linear infinite;

    @media screen and (max-width: 991px) {
      height: 120px;
    }
    @media screen and (max-width: 700px) {
      height: 100px;
    }
  }
`;

export default Banner2;
