import React, { useState, useEffect, useMemo } from "react";
import cx from "classnames";

import { BiChevronDown } from "react-icons/bi";

import Modal from "../Modal/Modal";

import dropDownIcon from "img/DROP_DOWN.svg";
import triangleIcon from "img/triangle.svg";
import "./TokenSelector.css";
import TooltipWithPortal from "../Tooltip/TooltipWithPortal";
import { bigNumberify, expandDecimals, formatAmount } from "lib/numbers";
import { PINNED_MICROSWAP_TOKENS, getToken } from "config/tokens";
import { importImage } from "lib/legacy";
import { t } from "@lingui/macro";
import searchIc from "img/icons/search.svg";
import ArrowDownSelectIcon from "components/Icon/ArrowDownSelect";
import { getLocalTokenIcon, getLogoUrlByChainId } from "config/aggregators/tokens";
import { BigNumber } from "ethers";
import { FANTOM, MICROSWAP_SUPPORTED_CHAIN_IDS } from "config/chains";

export default function TokenSelectorV3(props) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const {
    tokens,
    chainId,
    selectedToken,
    showBalances = true,
    showTokenImgInDropdown = false,
    showSymbolImage = false,
    showNewCaret = false,
    disableBodyScrollLock,
  } = props;

  const tokenInfo = selectedToken;
  const onSelectToken = (token) => {
    setIsModalVisible(false);
    props.onSelectToken(token);
  };
  const sortedTokens = useMemo(() => {
    return tokens.sort((a, b) => {
      if (
        Number(formatAmount(b.balance, b.decimals, 6, false)) !== Number(formatAmount(a.balance, a.decimals, 6, false))
      ) {
        return (
          Number(formatAmount(b.balance, b.decimals, 6, false)) - Number(formatAmount(a.balance, a.decimals, 6, false))
        );
      } else {
        return a.symbol.localeCompare(b.symbol);
      }
    });
  }, [tokens]);
  useEffect(() => {
    if (isModalVisible) {
      setSearchKeyword("");
    }
  }, [isModalVisible]);

  if (!tokenInfo) {
    return null;
  }

  const tokenImage =
    (showSymbolImage && getLocalTokenIcon(selectedToken.symbol)) ||
    getLogoUrlByChainId(chainId, selectedToken.address, true);

  const onSearchKeywordChange = (e) => {
    setSearchKeyword(e.target.value);
  };

  const filteredTokens = sortedTokens.filter((item) => {
    return (
      item.name.toLowerCase().indexOf(searchKeyword.toLowerCase()) > -1 ||
      item.symbol.toLowerCase().indexOf(searchKeyword.toLowerCase()) > -1 ||
      item.address.toLowerCase().indexOf(searchKeyword.toLowerCase()) > -1
    );
  });

  const _handleKeyDown = (e) => {
    if (e.key === "Enter" && filteredTokens.length > 0) {
      onSelectToken(filteredTokens[0]);
    }
  };
  const onLoadFailed = (e) => {
    e.currentTarget.onerror = null;
    e.currentTarget.src = getLocalTokenIcon("default");
  };
  return (
    <div className={cx("TokenSelector", props.className)}>
      <Modal
        disableBodyScrollLock={disableBodyScrollLock}
        isVisible={isModalVisible}
        setIsVisible={setIsModalVisible}
        label={props.label}
      >
        <div className="TokenSelector-tokens">
          <div className="TokenSelector-token-row TokenSelector-token-input-row">
            <img src={searchIc} alt="searchIc" className="TokenSelector-token-icon" />
            <input
              type="text"
              placeholder={`WETH, USDC, Ox...`}
              value={searchKeyword}
              onChange={(e) => onSearchKeywordChange(e)}
              onKeyDown={_handleKeyDown}
              autoFocus
            />
          </div>
          <div className="pin-tokens">
            {PINNED_MICROSWAP_TOKENS[MICROSWAP_SUPPORTED_CHAIN_IDS.includes(chainId) ? chainId : FANTOM].map(
              (token, index) => {
                const pinTokenImage =
                  (showSymbolImage && getLocalTokenIcon(token.symbol)) ||
                  getLogoUrlByChainId(chainId, token.address, true);
                return (
                  <div className="token-info" key={index} onClick={() => onSelectToken(token)}>
                    <img alt="" src={pinTokenImage} onError={onLoadFailed} />
                    <div className="symbol">{token.symbol}</div>
                  </div>
                );
              }
            )}
          </div>
          <div className="stroke-line" />
          {filteredTokens.map((token, tokenIndex) => {
            const tokenPopupImage =
              getLocalTokenIcon(token.symbol) ||
              getLogoUrlByChainId(chainId, token.address, true) ||
              getLocalTokenIcon("default");
            let balance = token?.balance || BigNumber.from(0);
            // console.log("???", balance);
            return (
              <div key={token.address} className={cx("TokenSelector-token-row")} onClick={() => onSelectToken(token)}>
                <div className="Token-info">
                  {showTokenImgInDropdown && (
                    <img
                      src={tokenPopupImage}
                      alt={token.name}
                      className="token-logo"
                      onError={onLoadFailed}
                      style={{ borderRadius: 100 }}
                    />
                  )}
                  <div className="Token-symbol">
                    <div className="Token-text">{token.symbol}</div>
                    <span className="text-accent">{token.name}</span>
                  </div>
                </div>
                <div className="Token-balance">
                  {showBalances && balance && (
                    <div className="Token-text">
                      {balance?.gt(0) && formatAmount(balance, token.decimals, 4, true)}
                      {balance?.eq(0) && "-"}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
          {filteredTokens.length === 0 && <div className="no-result-searched">No results</div>}
        </div>
      </Modal>
      {!selectedToken ? (
        <div className="TokenSelector-box" onClick={() => setIsModalVisible(true)}>
          {selectedToken.symbol}
          {!showNewCaret && <BiChevronDown className="TokenSelector-caret" />}
        </div>
      ) : (
        <div className="TokenSelector-box" onClick={() => setIsModalVisible(true)}>
          {showSymbolImage && (
            <img
              src={tokenImage}
              alt={tokenInfo.symbol}
              className="TokenSelector-box-symbol"
              style={{ borderRadius: 100 }}
              onError={onLoadFailed}
            />
          )}
          {tokenInfo.symbol}
          {showNewCaret && <img src={dropDownIcon} alt="Dropdown Icon" className="TokenSelector-box-caret" />}
          {!showNewCaret && <ArrowDownSelectIcon class="TokenSelector-caret" />}
        </div>
      )}
    </div>
  );
}
