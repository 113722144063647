import { useCallback, useEffect, useState } from "react";
import { useChainId } from "lib/chains";
import { getServerBaseUrl } from "config/backend";
import axios from "axios";
import { TOKEN_SHOWS_DECIMALS, TOKEN_WHITE_LIST, getTokenDecimal, getTokenDisplayDecimal, getTokenSymbol } from "config/tokens";
import { getTokenSymbolFromString } from "domain/tokens";
import { BigNumber } from "ethers";
import { formatAmount, parseValue } from "lib/numbers";

const useMarketAssetsV1 = () => {
  const [assets, setAssets] = useState([]);
  const { chainId } = useChainId();
  const fetchIds = useCallback(async () => {
    const res = await axios.get(`${getServerBaseUrl(chainId)}/token-stats`, {});
    if (res && res.data) {
      let data = res.data || [];
      let list = [];
      for (const [key, value] of Object.entries(data)) {
        const token = {
          symbol: getTokenSymbol(chainId, key) + "/USD",
          tokenSymbol: getTokenSymbol(chainId, key),
          askPrice: value?.price || 0,
          decimals: getTokenDisplayDecimal(chainId, key),
          address: key,
          price24HChanged: value?.last24HPrice ? Number(
            formatAmount(
              BigNumber.from(value?.price || 0)
                .sub(BigNumber.from(value?.last24HPrice || 0))
                .mul(parseValue(100, 30))
                .div(BigNumber.from(value?.last24HPrice || 0)),
              30,
              5,
              false
            )
          ) : 0,
        };
        if(getTokenSymbol(chainId, key))
          list.push(token);
      }
      setAssets(list);
    }
  }, [chainId]);

  useEffect(() => {
    fetchIds();
    const interval = setInterval(async () => {
      fetchIds();
    }, 30000);
    return () => clearInterval(interval);
  }, [chainId]);

  return assets;
};

export default useMarketAssetsV1;
