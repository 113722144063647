import React from "react";
import styled from "styled-components";
import Fade from "react-reveal/Fade";

import superiorityBg from "img/home/superiority-bg.svg";
import superiorityMobileBg from "img/home/superiority-bg-mobile.svg";
import reduceIc from "img/home/reduce.svg";
import saveCostIc from "img/home/saveCost.svg";
import swapIc from "img/home/swap.svg";

const Superiority = () => {
  return (
    <Wrapper className="container">
      <Fade bottom>
        <Title>Our superiorities</Title>
      </Fade>
      <Fade left cascade>
        <Content>
          <SuperiorityItem>
            <img src={reduceIc} alt="superiority_ic" />
            <div className="title">Reduce Liquidation Risks</div>
            <div className="desc">
              An aggregate of high-quality price feeds determine when liquidations occur. This keeps positions safe from
              temporary wicks.
            </div>
          </SuperiorityItem>
          <SuperiorityItem>
            <img src={saveCostIc} alt="superiority_ic" />
            <div className="title">Save on Costs</div>
            <div className="desc">
              Enter and exit positions with minimal spread and zero price impact. Get the optimal price without
              incurring additional costs.
            </div>
          </SuperiorityItem>
          <SuperiorityItem>
            <img src={swapIc} alt="superiority_ic" />
            <div className="title">Simple Swaps</div>
            <div className="desc">
              Open positions through a simple swap interface. Conveniently swap from any supported asset into the
              position of your choice.
            </div>
          </SuperiorityItem>
        </Content>
      </Fade>
    </Wrapper>
  );
};

const Title = styled.div`
  font-weight: 700;
  font-size: 56px;
  line-height: 140%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;

  @media (max-width: 1023px) {
    font-size: 40px;
  }

  @media (max-width: 767px) {
    font-size: 24px;
  }
`;

const SuperiorityItem = styled.div`
  height: 100%;
  background-image: url(${superiorityBg});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center top;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 0 24px 62px;
  gap: 16px;
  border-radius: 24px;
  overflow: hidden;

  img {
    width: 80px;
    height: 80px;
    margin-bottom: 44px;
    padding: 5px;
    filter: drop-shadow(0px 4px 24px #03f5ae);
  }

  .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;
    text-align: center;
  }

  .desc {
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #ffffff99;
    text-align: center;
  }

  @media (max-width: 1023px) {
    padding: 16px;

    img {
      width: 40px;
      height: 40px;
      margin-bottom: 8px;
    }

    .title {
      font-size: 16px;
    }

    .desc {
      font-size: 12px;
    }
  }

  @media (max-width: 767px) {
    background-size: cover;
    padding: 57px 20px 34px;
    background-image: url(${superiorityMobileBg});
  }
`;

const Content = styled.div`
  height: 419px;
  display: flex;
  align-items: center;
  gap: 24px;

  @media (max-width: 1023px) {
    height: 222px;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    gap: 16px;
    height: unset;
  }
`;

const Wrapper = styled.div`
  margin-top: 64px !important;
  padding: 0 24px;

  @media (max-width: 1023px) {
    margin-top: 35px !important;
  }

  @media (max-width: 767px) {
    padding: 0 16px;
    margin-top: -1px !important;
  }
`;

export default Superiority;
