const EditIcon = (props: JSX.IntrinsicElements["svg"]) => {
  return (
    <svg width={16} height={16} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6654 1.31323C13.2819 1.31323 12.8981 1.4597 12.6055 1.75203L11.7799 2.57756L13.901 4.69865L14.7266 3.87313C15.3112 3.28779 15.3112 2.33867 14.7266 1.75334L14.7253 1.75203C14.4326 1.4597 14.0489 1.31323 13.6654 1.31323ZM3.33203 2.64657C1.95136 2.64657 0.832031 3.7659 0.832031 5.14657V13.1466C0.832031 14.5272 1.95136 15.6466 3.33203 15.6466H11.332C12.7127 15.6466 13.832 14.5272 13.832 13.1466V7.38224C13.832 7.10624 13.608 6.88224 13.332 6.88224C13.056 6.88224 12.832 7.10624 12.832 7.38224V13.1466C12.832 13.9752 12.1607 14.6466 11.332 14.6466H3.33203C2.50336 14.6466 1.83203 13.9752 1.83203 13.1466V5.14657C1.83203 4.3179 2.50336 3.64657 3.33203 3.64657H9.09635C9.37235 3.64657 9.59635 3.42257 9.59635 3.14657C9.59635 2.87057 9.37235 2.64657 9.09635 2.64657H3.33203ZM11.0716 3.28459L6.71094 7.64526C6.65627 7.69993 6.61581 7.76788 6.59115 7.84188L5.88411 9.96297C5.82411 10.1423 5.87121 10.3394 6.00521 10.4734C6.13921 10.6074 6.33629 10.6545 6.51562 10.5945L8.63672 9.88745C8.71072 9.86278 8.77867 9.82233 8.83333 9.76766L13.194 5.40698L11.0716 3.28459Z"
        fill="white"
      />
    </svg>
  );
};

export default EditIcon;
