import React, { memo } from "react";
import styled from "styled-components";

type TraderDetailProps = {
  chainId:number;
};

const TraderDetail = (props: TraderDetailProps) => {
  return <Wrapper>TraderDetail</Wrapper>;
};

export default memo(TraderDetail);
const Wrapper = styled.div``;
