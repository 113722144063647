const SettingIcon = (props: JSX.IntrinsicElements["svg"]) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M9.66602 2L9.17578 4.52344C8.35161 4.83425 7.59479 5.26993 6.93164 5.81445L4.50781 4.97852L2.17188 9.02148L4.11328 10.709C4.03865 11.1672 4 11.5918 4 12C4 12.4088 4.03981 12.8326 4.11328 13.291V13.293L2.17188 14.9805L4.50781 19.0215L6.92969 18.1875C7.5929 18.7323 8.35143 19.1656 9.17578 19.4766L9.66602 22H14.334L14.8242 19.4766C15.6489 19.1655 16.4049 18.7306 17.0684 18.1855L19.4922 19.0215L21.8262 14.9805L19.8867 13.291C19.9614 12.8328 20 12.4082 20 12C20 11.5925 19.9611 11.1684 19.8867 10.7109V10.709L21.8281 9.01953L19.4922 4.97852L17.0703 5.8125C16.4071 5.26768 15.6486 4.83443 14.8242 4.52344L14.334 2H9.66602ZM11.3145 4H12.6855L13.0742 6L14.1172 6.39453C14.7459 6.63147 15.3107 6.95675 15.8008 7.35938L16.6641 8.06641L18.5859 7.40625L19.2715 8.5918L17.7363 9.92773L17.9121 11.0273V11.0293C17.9733 11.4042 18 11.7188 18 12C18 12.2812 17.9733 12.5957 17.9121 12.9707L17.7344 14.0703L19.2695 15.4062L18.584 16.5938L16.6641 15.9316L15.7988 16.6406C15.3087 17.0432 14.7459 17.3685 14.1172 17.6055H14.1152L13.0723 18L12.6836 20H11.3145L10.9258 18L9.88281 17.6055C9.25415 17.3685 8.68933 17.0432 8.19922 16.6406L7.33594 15.9336L5.41406 16.5938L4.72852 15.4082L6.26562 14.0703L6.08789 12.9746V12.9727C6.02762 12.5961 6 12.2807 6 12C6 11.7188 6.02674 11.4043 6.08789 11.0293L6.26562 9.92969L4.72852 8.59375L5.41406 7.40625L7.33594 8.06836L8.19922 7.35938C8.68933 6.95675 9.25415 6.63147 9.88281 6.39453L10.9258 6L11.3145 4ZM12 8C9.80348 8 8 9.80348 8 12C8 14.1965 9.80348 16 12 16C14.1965 16 16 14.1965 16 12C16 9.80348 14.1965 8 12 8ZM12 10C13.1115 10 14 10.8885 14 12C14 13.1115 13.1115 14 12 14C10.8885 14 10 13.1115 10 12C10 10.8885 10.8885 10 12 10Z"
        fill={props.stopColor || "#03F5AE"}
      />
      <path
        d="M9.66602 2L9.17578 4.52344C8.35161 4.83425 7.59479 5.26993 6.93164 5.81445L4.50781 4.97852L2.17188 9.02148L4.11328 10.709C4.03865 11.1672 4 11.5918 4 12C4 12.4088 4.03981 12.8326 4.11328 13.291V13.293L2.17188 14.9805L4.50781 19.0215L6.92969 18.1875C7.5929 18.7323 8.35143 19.1656 9.17578 19.4766L9.66602 22H14.334L14.8242 19.4766C15.6489 19.1655 16.4049 18.7306 17.0684 18.1855L19.4922 19.0215L21.8262 14.9805L19.8867 13.291C19.9614 12.8328 20 12.4082 20 12C20 11.5925 19.9611 11.1684 19.8867 10.7109V10.709L21.8281 9.01953L19.4922 4.97852L17.0703 5.8125C16.4071 5.26768 15.6486 4.83443 14.8242 4.52344L14.334 2H9.66602ZM11.3145 4H12.6855L13.0742 6L14.1172 6.39453C14.7459 6.63147 15.3107 6.95675 15.8008 7.35938L16.6641 8.06641L18.5859 7.40625L19.2715 8.5918L17.7363 9.92773L17.9121 11.0273V11.0293C17.9733 11.4042 18 11.7188 18 12C18 12.2812 17.9733 12.5957 17.9121 12.9707L17.7344 14.0703L19.2695 15.4062L18.584 16.5938L16.6641 15.9316L15.7988 16.6406C15.3087 17.0432 14.7459 17.3685 14.1172 17.6055H14.1152L13.0723 18L12.6836 20H11.3145L10.9258 18L9.88281 17.6055C9.25415 17.3685 8.68933 17.0432 8.19922 16.6406L7.33594 15.9336L5.41406 16.5938L4.72852 15.4082L6.26562 14.0703L6.08789 12.9746V12.9727C6.02762 12.5961 6 12.2807 6 12C6 11.7188 6.02674 11.4043 6.08789 11.0293L6.26562 9.92969L4.72852 8.59375L5.41406 7.40625L7.33594 8.06836L8.19922 7.35938C8.68933 6.95675 9.25415 6.63147 9.88281 6.39453L10.9258 6L11.3145 4Z"
        fill={props.color || "currentColor"}
      />
    </svg>
  );
};

export default SettingIcon;
