import React, { memo } from "react";

const IconArrow = ({ noOpacity = false, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width ?? "16"}
    height={props.height ?? "16"}
    viewBox="0 0 16 16"
    fill="none"
    className={props.class}
    {...props}
  >
    <path d="M7.66536 11.3333L11.4181 5.83333L3.91259 5.83333L7.66536 11.3333Z"
      fill={props.fill ?? "#ffffff99"}
    />
  </svg>
);
export default memo(IconArrow);
