import { t } from "@lingui/macro";
import { getServerUrl } from "config/backend";
import { Link } from "react-router-dom";
import styled from "styled-components";
import useSWR from "swr";

import { ARBITRUM, BASE, DEFAULT_CHAIN_ID, FANTOM, OP } from "config/chains";
import { getWhitelistedTokens } from "config/tokens";
import { useGmxPrice } from "domain/legacy";
import { useInfoTokens } from "domain/tokens";
import { PLACEHOLDER_ACCOUNT, USD_DECIMALS } from "lib/legacy";
import { bigNumberify, formatAmount, formatNumber } from "lib/numbers";

import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
import TooltipComponent from "components/Tooltip/Tooltip";

import ExternalLink from "components/ExternalLink/ExternalLink";
import { useAprContext } from "contexts/AprProvider";
import { useThemeContext } from "contexts/ThemeProvider";
import useMMYFarmContractMulticall from "hooks/contracts/useMMYFarmContractMulticall";
import useMslpApr from "hooks/useMslpApr";
import useMslpAprOP from "hooks/useMslpAprOP";
import useMspPriceMultiChain from "hooks/useMspPriceMultiChain";
import useTokensPrice from "hooks/useTokensPrice";
import useTokensPriceOP from "hooks/useTokensPriceOP";
import arb24Icon from "img/ic_arbitrum_24.svg";
import base24Icon from "img/ic_base_24.svg";
import ftm24Icon from "img/ic_ftm_24.svg";
import glp40Icon from "img/ic_mlp_40.svg";
import gmx40Icon from "img/ic_mmy_40.svg";
import msp40Icon from "img/ic_msp_40.svg";
import op24Icon from "img/ic_op_24.svg";
import { caculateAPYHourly2 } from "lib/helper";

const TokenCards = ({ chainId, active, library }) => {
  const { lightThemeClassName } = useThemeContext();

  const whitelistedTokens = getWhitelistedTokens(chainId);
  const tokenList = whitelistedTokens.filter((t) => !t.isWrapped);

  const { data: mlpInfo } = useSWR(getServerUrl(chainId, "/mlp-info"), {
    refreshInterval: 30000,
  });

  const { data: mlpInfoFantom } = useSWR(getServerUrl(FANTOM, "/mlp-info"), {
    refreshInterval: 30000,
  });

  const { data: mlpInfoOP } = useSWR(getServerUrl(OP, "/mlp-info"), {
    refreshInterval: 30000,
  });

  const { data: mlpInfoArbitrum } = useSWR(getServerUrl(ARBITRUM, "/mlp-info"), {
    refreshInterval: 30000,
  });
  const { data: mlpInfoBase } = useSWR(getServerUrl(BASE, "/mlp-info"), {
    refreshInterval: 30000,
  });

  const { infoTokens } = useInfoTokens(library, chainId, active, undefined, undefined);
  const { gmxPrice, gmxPriceFromFantom, gmxPriceFromOP, gmxPriceFromArbitrum, gmxPriceFromBase } = useGmxPrice(
    chainId,
    { arbitrum: chainId === ARBITRUM ? library : undefined, op: chainId === OP ? library : undefined },
    active
  );

  let glpPrice;
  let glpPriceFantom;
  let glpPriceOp;
  let glpPriceArb;
  let glpPriceBase;
  if (mlpInfo) {
    glpPrice = bigNumberify(mlpInfo?.price || "0");
  }
  if (mlpInfoFantom) {
    glpPriceFantom = bigNumberify(mlpInfoFantom?.price || "0");
  }

  if (mlpInfoOP) {
    glpPriceOp = bigNumberify(mlpInfoOP?.price || 0);
  }

  if (mlpInfoArbitrum) {
    glpPriceArb = bigNumberify(mlpInfoArbitrum?.price || "0");
  }

  if (mlpInfoBase) {
    glpPriceBase = bigNumberify(mlpInfoBase?.price || "0");
  }

  let adjustedUsdgSupply = bigNumberify(0);

  for (let i = 0; i < tokenList.length; i++) {
    const token = tokenList[i];
    const tokenInfo = infoTokens[token.address];
    if (tokenInfo && tokenInfo.usdgAmount) {
      adjustedUsdgSupply = adjustedUsdgSupply.add(tokenInfo.usdgAmount);
    }
  }

  // const mspPrice = useMspPrice(FANTOM);
  const mspPrices = useMspPriceMultiChain();
  const mspPrice = mspPrices[chainId] || mspPrices[DEFAULT_CHAIN_ID];

  const {
    mlpPoolInfo: [totalStakedFantom],
    poolRewardsPerSec: poolRewardsPerSecFantom,
  } = useMMYFarmContractMulticall(FANTOM, active, PLACEHOLDER_ACCOUNT, library);

  const {
    mlpPoolInfo: [totalStakedOp],
    poolRewardsPerSec: poolRewardsPerSecOp,
  } = useMMYFarmContractMulticall(OP, active, PLACEHOLDER_ACCOUNT, library);
  const rewardsPriceFantom = useTokensPrice({
    gmxPrice: gmxPriceFromFantom,
    FANTOM,
  });
  const rewardsPriceOp = useTokensPriceOP({
    gmxPrice: gmxPriceFromOP,
    OP,
  });

  const aprFantom = useMslpApr({
    mslpPrice: mspPrices[FANTOM],
    rewardsPrice: rewardsPriceFantom,
    totalStaked: totalStakedFantom,
    poolRewardsPerSec: poolRewardsPerSecFantom,
  });
  const aprOp = useMslpAprOP({
    mslpPrice: mspPrices[OP],
    rewardsPrice: rewardsPriceOp,
    totalStaked: totalStakedOp,
    poolRewardsPerSec: poolRewardsPerSecOp,
  });

  const processedData = useAprContext();

  //MMY
  const mmyAprFantom = parseFloat(formatAmount(processedData[FANTOM]?.gmxAprTotal, 2)) / 100;
  const mmyApyFantom = caculateAPYHourly2(mmyAprFantom) * 100;

  const mmyAprOp = parseFloat(formatAmount(processedData[OP]?.gmxAprTotal, 2)) / 100;
  const mmyApyOp = caculateAPYHourly2(mmyAprOp) * 100;

  const mmyAprArb = parseFloat(formatAmount(processedData[ARBITRUM]?.gmxAprTotal, 2)) / 100;
  const mmyApyArb = caculateAPYHourly2(mmyAprArb) * 100;

  const mmyAprBase = parseFloat(formatAmount(processedData[BASE]?.gmxAprTotal, 2)) / 100;
  const mmyApBase = caculateAPYHourly2(mmyAprBase) * 100;

  //MLP
  const mlpAprFantom = parseFloat(formatAmount(processedData[FANTOM]?.glpAprTotal, 2)) / 100;
  const mlpApyFantom = caculateAPYHourly2(mlpAprFantom) * 100;

  const mlpAprOp = parseFloat(formatAmount(processedData[OP]?.glpAprTotal, 2)) / 100;
  const mlpApyOp = caculateAPYHourly2(mlpAprOp) * 100;

  const mlpAprArb = parseFloat(formatAmount(processedData[ARBITRUM]?.glpAprTotal, 2)) / 100;
  const mlpApyArb = caculateAPYHourly2(mlpAprArb) * 100;

  const mlpAprBase = parseFloat(formatAmount(processedData[BASE]?.glpAprTotal, 2)) / 100;
  const mlpApBase = caculateAPYHourly2(mlpAprBase) * 100;

  return (
    <Wrapper className={lightThemeClassName}>
      <Title>
        <span>Buy MMY, MLP and MSLP</span>
      </Title>
      <TokenCardContainer>
        <TokenCard>
          <CardTitle>
            <TokenImg>
              <img src={gmx40Icon} alt="MMY Token Icon" />
            </TokenImg>
            <TokenInfo>
              <div>
                <div className="token-symbol">MMY</div>
                <div className="token-price">
                  {" "}
                  {!gmxPrice && (
                    <div
                      className="skeleton-box"
                      style={{
                        width: 64,
                        height: 33.6,
                      }}
                    />
                  )}
                  {gmxPrice && (
                    <TooltipComponent
                      position="left-bottom"
                      className="nowrap excep"
                      handle={"$" + formatAmount(gmxPrice, USD_DECIMALS, 2, true)}
                      renderContent={() => (
                        <div
                          className="customTooltip"
                          style={{
                            minWidth: 220,
                          }}
                        >
                          <StatsTooltipRow
                            label={`Price on Fantom`}
                            value={formatAmount(gmxPriceFromFantom, USD_DECIMALS, 2, true)}
                            showDollar={true}
                          />
                          <StatsTooltipRow
                            label={`Price on Optimism`}
                            value={formatAmount(gmxPriceFromOP, USD_DECIMALS, 2, true)}
                            showDollar={true}
                          />
                          <StatsTooltipRow
                            label={t`Price on Arbitrum`}
                            value={formatAmount(gmxPriceFromArbitrum, USD_DECIMALS, 2, true)}
                            showDollar={true}
                          />
                          <StatsTooltipRow
                            label={t`Price on Base`}
                            value={formatAmount(gmxPriceFromBase, USD_DECIMALS, 2, true)}
                            showDollar={true}
                          />
                        </div>
                      )}
                    />
                  )}
                </div>
              </div>
            </TokenInfo>
          </CardTitle>
          <div className="desc">
            MMY is the utility and governance token. Accrues 30% of V1 and V2 markets generated fees.
          </div>
          <TokenAPR>
            <div className="row ftm">
              <div className="title">
                <img src={ftm24Icon} alt="ftm-symbol" />
                <div className="apr-wrap">
                  APY
                  <span>{formatNumber(mmyApyFantom, 2)}%</span>
                </div>
              </div>
            </div>
            <div className="row op">
              <div className="title">
                <img src={op24Icon} alt="ftm-symbol" />
                <div className="apr-wrap">
                  APY
                  <span>{formatNumber(mmyApyOp, 2)}%</span>
                </div>
              </div>
            </div>
            <div className="row arb">
              <div className="title">
                <img src={arb24Icon} alt="ftm-symbol" />
                <div className="apr-wrap">
                  APY
                  <span>{formatNumber(mmyApyArb, 2)}%</span>
                </div>
              </div>
            </div>
            <div className="row base">
              <div className="title">
                <img src={base24Icon} alt="base-symbol" />
                <div className="apr-wrap">
                  APY
                  <span>{formatNumber(mmyApBase, 2)}%</span>
                </div>
              </div>
            </div>
          </TokenAPR>
          <div className="btns">
            <Link to="/buy_mmy" className="default-btn full">
              Buy
            </Link>
            <ExternalLink
              href="https://docs.mummy.finance/tokenomics"
              target={"_blank"}
              className="border-btn full read-more-btn"
            >
              Read more
            </ExternalLink>
          </div>
        </TokenCard>
        <TokenCard>
          <CardTitle>
            <TokenImg>
              <img src={glp40Icon} alt="mlp40Icon" />
            </TokenImg>
            <TokenInfo>
              <div>
                <div className="token-symbol">MLP</div>
                <div className="token-price">
                  {!glpPrice && (
                    <div
                      className="skeleton-box"
                      style={{
                        width: 64,
                        height: 33.6,
                      }}
                    />
                  )}
                  {glpPrice && (
                    <TooltipComponent
                      position="left-bottom"
                      className="nowrap excep"
                      handle={"$" + formatAmount(glpPrice, USD_DECIMALS, 2, true)}
                      renderContent={() => (
                        <div
                          className="customTooltip"
                          style={{
                            minWidth: 220,
                          }}
                        >
                          <StatsTooltipRow
                            label={`Price on Fantom`}
                            value={formatAmount(glpPriceFantom, USD_DECIMALS, 2, true)}
                            showDollar={true}
                          />
                          {/* <StatsTooltipRow
                            label={`Price on Fantom legacy`}
                            value={formatAmount(glpPriceFantomLegacy, USD_DECIMALS, 2, true)}
                            showDollar={true}
                          /> */}
                          <StatsTooltipRow
                            label={`Price on Optimism`}
                            value={formatAmount(glpPriceOp, USD_DECIMALS, 2, true)}
                            showDollar={true}
                          />
                          <StatsTooltipRow
                            label={t`Price on Arbitrum`}
                            value={formatAmount(glpPriceArb, USD_DECIMALS, 2, true)}
                            showDollar={true}
                          />
                          <StatsTooltipRow
                            label={t`Price on Base`}
                            value={formatAmount(glpPriceBase, USD_DECIMALS, 2, true)}
                            showDollar={true}
                          />
                        </div>
                      )}
                    />
                  )}
                </div>
              </div>{" "}
            </TokenInfo>
          </CardTitle>
          <div className="desc">MLP is the liquidity provider token. Accrues 60% of the V1 markets generated fees.</div>
          <TokenAPR>
            <div className="row ftm">
              <div className="title">
                <img src={ftm24Icon} alt="ftm-symbol" />
                <div className="apr-wrap">
                  APY
                  <span>{formatNumber(mlpApyFantom, 2)}%</span>
                </div>
              </div>
            </div>
            <div className="row op">
              <div className="title">
                <img src={op24Icon} alt="ftm-symbol" />
                <div className="apr-wrap">
                  APY
                  <span>{formatNumber(mlpApyOp, 2)}%</span>
                </div>
              </div>
            </div>
            <div className="row arb">
              <div className="title">
                <img src={arb24Icon} alt="ftm-symbol" />
                <div className="apr-wrap">
                  APY
                  <span>{formatNumber(mlpApyArb, 2)}%</span>
                </div>
              </div>
            </div>
            <div className="row base">
              <div className="title">
                <img src={base24Icon} alt="base-symbol" />
                <div className="apr-wrap">
                  APY
                  <span>{formatNumber(mlpApBase, 2)}%</span>
                </div>
              </div>
            </div>
          </TokenAPR>
          <div className="btns">
            <Link to="/buy_mlp" className="default-btn full">
              Buy
            </Link>
            <ExternalLink
              href="https://docs.mummy.finance/mlp"
              target={"_blank"}
              className="border-btn full read-more-btn"
            >
              Read more
            </ExternalLink>
          </div>
        </TokenCard>
        <TokenCard>
          <CardTitle>
            <TokenImg className="golden">
              <img src={msp40Icon} alt="msp40Icon" />
            </TokenImg>
            <TokenInfo>
              <div>
                <div className="token-symbol">MSLP</div>
                <div className="token-price">
                  {!mspPrice && (
                    <div
                      className="skeleton-box"
                      style={{
                        width: 64,
                        height: 33.6,
                      }}
                    />
                  )}
                  {/* {mspPrice && <>{"$" + formatAmount(mspPrice, 30, 2)}</>} */}
                  {mspPrice && (
                    <TooltipComponent
                      position="left-bottom"
                      className="nowrap excep"
                      handle={"$" + formatAmount(mspPrice, 30, 2)}
                      renderContent={() => (
                        <div
                          className="customTooltip"
                          style={{
                            minWidth: 220,
                          }}
                        >
                          <StatsTooltipRow
                            label={`Price on Fantom`}
                            value={formatAmount(mspPrices[FANTOM], 30, 2)}
                            showDollar={true}
                          />
                          <StatsTooltipRow
                            label={`Price on Optimism`}
                            value={formatAmount(mspPrices[OP], 30, 2)}
                            showDollar={true}
                          />
                        </div>
                      )}
                    />
                  )}
                </div>
              </div>{" "}
            </TokenInfo>
          </CardTitle>
          <div className="desc">
            MSLP is the liquidity provider token on Mummy V2. Accrues 70% of the V2 markets generated fees.
          </div>
          <TokenAPR>
            <div className="row ftm">
              <div className="title">
                <img src={ftm24Icon} alt="ftm-symbol" />
                <div className="apr-wrap">
                  APR
                  <span>
                    {aprFantom && aprFantom.total !== undefined && !isNaN(aprFantom.total) ? (
                      <>{aprFantom.total === 0 ? "0.000" : `${formatNumber(aprFantom.total, 2)}%`}</>
                    ) : (
                      <div className="skeleton-box" style={{ width: "60px" }} />
                    )}
                  </span>
                </div>
              </div>
            </div>
            <div className="row op">
              <div className="title">
                <img src={op24Icon} alt="ftm-symbol" />
                <div className="apr-wrap">
                  APR
                  <span>
                    {aprOp && aprOp.total !== undefined && !isNaN(aprOp.total) ? (
                      <>{aprOp.total === 0 ? "0.000" : `${formatNumber(aprOp.total, 2)}%`}</>
                    ) : (
                      <div className="skeleton-box" style={{ width: "60px" }} />
                    )}
                  </span>
                </div>
              </div>
            </div>
          </TokenAPR>
          <div className="btns">
            <Link to="/buy/mint" className="default-btn full">
              Buy
            </Link>
            <ExternalLink
              href="https://docs.mummy.finance/earn/mslp"
              target={"_blank"}
              className="border-btn full read-more-btn"
            >
              Read more
            </ExternalLink>
          </div>
        </TokenCard>
      </TokenCardContainer>
    </Wrapper>
  );
};

const TokenAPR = styled.div`
  margin: auto 0 16px;
  display: flex;
  align-items: center;
  flex-direction: row;
  border: 1px solid #ffffff1a;
  border-radius: 16px;
  overflow: hidden;

  .apr-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;

    color: var(--white-60, rgba(255, 255, 255, 0.6));
    font-size: 14px;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    & > span {
      color: var(--main-primary, #03f5ae);
      font-size: 14px;
      font-weight: 700;
      line-height: 140%; /* 19.6px */
      display: flex;
    }

    @media screen and (max-width: 767px) {
      flex-direction: row;
      gap: 4px;
    }
  }

  > .row {
    width: 100%;
    padding: 18px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 8px;
    background-image: linear-gradient(180deg, rgba(19, 181, 236, 0.1) 0%, rgba(19, 181, 236, 0) 100%);

    &.op {
      background-image: linear-gradient(180deg, rgba(255, 4, 32, 0.1) 0%, rgba(255, 4, 32, 0) 100%);
    }
    &.arb {
      background-image: linear-gradient(180deg, rgba(19, 181, 236, 0.1) 0%, rgba(19, 181, 236, 0) 100%);
    }
    &.base {
      background-image: linear-gradient(180deg, rgba(19, 181, 236, 0.1) 0%, rgba(19, 181, 236, 0) 100%);
    }

    .title {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: #ffffff99;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;

      > span {
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        color: #03f5ae;
      }

      @media screen and (max-width: 767px) {
        flex-direction: row;
      }
    }

    .btn-buy {
      display: flex;
      height: 40px;
      padding: 8px 16px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      align-self: stretch;
      border-radius: 8px;
      border: 1px solid var(--stroke-10, rgba(255, 255, 255, 0.1));
      background-color: transparent;
      /* color: #080715; */
      text-decoration: none;

      color: var(--white-100, #fff);
      font-size: 14px;
      font-weight: 500;
      line-height: 140%; /* 19.6px */

      &:hover {
        color: #ffffff99 !important;
      }
    }

    @media (max-width: 767px) {
      flex-direction: row;

      .btn-buy {
        width: fit-content;
      }
    }
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`;

const TokenCardInfo = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .label {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 4px;
  }
  .value {
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
  }
  /* .Tooltip-popup {
    left: 0;
    right: unset;
  } */

  @media (min-width: 768px) and (max-width: 1023px) {
    flex-direction: column;
    align-items: flex-start;
  }

  @media (max-width: 767px) {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    /* .Tooltip-popup {
      left: unset;
      right: 0;
    } */
  }
`;

const TokenCardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  gap: 12px;
  margin: 72px 0 16px;

  @media (min-width: 768px) and (max-width: 1023px) {
    flex-direction: row;
  }

  @media (max-width: 767px) {
    gap: 12px;
  }
`;

const TokenInfo = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  .token-symbol {
    color: rgba(255, 255, 255, 0.6);
    margin-bottom: 4px;
    font-weight: 400;
  }
  .token-price {
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;
  }
`;

const TokenImg = styled.div`
  padding: 16px;
  background-image: linear-gradient(138.67deg, rgba(3, 245, 174, 0.15) 8.79%, rgba(3, 245, 174, 0) 100%);
  border-radius: 0px 0px 12px 12px;

  &.golden {
    background-image: linear-gradient(139deg, rgba(245, 206, 3, 0.3) 8.79%, rgba(245, 206, 3, 0) 100%);
  }

  img {
    width: 48px;
    height: 48px;
  }
`;

const CardTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  left: 24px;
  right: 24px;

  @media (max-width: 767px) {
    left: 16px;
    right: 16px;
  }
`;

const TokenCard = styled.div`
  padding: 24px;
  background-color: #191b2e;
  border-radius: 24px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .desc {
    color: var(--white-60, rgba(255, 255, 255, 0.6));
    font-size: 16px;
    font-weight: 400;
    line-height: 140%; /* 22.4px */

    margin: 72px 0 16px;
  }

  .btns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;

    &.no-chain {
      grid-template-columns: 1fr;
    }
  }

  .read-more-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }

  @media (max-width: 767px) {
    padding: 16px;
  }
`;

const TokenCardContainer = styled.div`
  margin-top: 16px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;

  @media (max-width: 1023px) {
    grid-template-columns: repeat(1, 1fr);
  }

  .content-mlp {
    grid-template-columns: repeat(4, 1fr);

    @media (max-width: 767px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  margin-bottom: 4px;
`;

const Wrapper = styled.div`
  margin: 64px 0;

  @media (max-width: 767px) {
    margin: 36px 0;

    div.Tooltip-popup:has(.customTooltip) {
      transform: translateX(-100px);
    }
  }

  &.theme--light {
    ${TokenCard} {
      border: 1px solid var(--Border, rgba(13, 26, 22, 0.07));
      background: var(--Nature-1, #fff);

      .desc {
        color: var(--Text-Text_Primary, #0d1a16);
      }

      ${TokenImg} {
        background: var(
          --v3-bg_icon,
          linear-gradient(139deg, rgba(3, 245, 174, 0.15) 8.79%, rgba(3, 245, 174, 0) 100%)
        );

        &.golden {
          background: linear-gradient(139deg, rgba(245, 206, 3, 0.3) 8.79%, rgba(245, 206, 3, 0) 100%);
        }
      }

      ${TokenInfo} {
        .token-symbol {
          color: var(--Text-Text_Primary, #0d1a16);
        }
      }

      ${TokenAPR} {
        border: 1px solid var(--Border, rgba(13, 26, 22, 0.07));

        .row {
          background: linear-gradient(180deg, rgba(19, 181, 236, 0.1) 0%, rgba(19, 181, 236, 0) 100%);

          &.op {
            background: linear-gradient(180deg, rgba(255, 4, 32, 0.1) 0%, rgba(255, 4, 32, 0) 100%);
          }

          &.arb {
            background: linear-gradient(180deg, rgba(18, 170, 255, 0.1) 0%, rgba(0, 119, 229, 0) 100%);
          }

          &.base {
            background: linear-gradient(180deg, rgba(0, 82, 255, 0.1) 0%, rgba(0, 82, 255, 0) 100%);
          }
          /* 
          &:not(.ftm, .op) {
            background: linear-gradient(180deg, rgba(0, 119, 229, 0.1) 0%, rgba(0, 119, 229, 0) 100%);
          } */

          .title {
            .apr-wrap {
              color: var(--Text-Text_Primary, #0d1a16);

              span {
                color: var(--Primary, #02b27f);
              }
            }
          }
        }

        .btn-buy.border-btn {
          color: var(--Text-Text_Primary, #0d1a16) !important;
          border: 1px solid var(--Border, rgba(13, 26, 22, 0.07)) !important;
        }
      }
    }
  }
`;
export default TokenCards;
