import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { getServerBaseUrl } from "config/backend";

const useUserStatsWithParams = (account, chainId) => {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [params, setParams] = useState({
    to: Math.floor(Date.now() / 1000),
  });

  const setFrom = useCallback((from) => {
    setParams((prev) => ({
      ...prev,
      from,
    }));
  }, []);

  useEffect(() => {
    async function fetcher() {
      try {
        const { data: response } = await axios.get(
          `${getServerBaseUrl(chainId, true)}/public/account-stats/${account}`,
          {
            params,
          }
        );

        setData(response.data);
      } finally {
        setIsLoading(false);
      }
    }

    fetcher();
    const timer = setInterval(fetcher, 30000);

    return () => clearInterval(timer);
  }, [account, chainId, params]);

  return {
    isLoading,
    data,
    setFrom,
  };

  // const [stats, setStats] = useState(null);
  // const { chainId } = useChainId();
  // const fetchPositions = async () => {
  //   const res = await axios.get(
  //     `${getServerBaseUrl(chainId, true)}/public/account-stats/${account}`,
  //     {},
  //   );

  //   if (res && res.data?.data) {
  //     setStats(res.data?.data);
  //   } else {
  //     setStats([]);
  //   }
  // };
  // useEffect(() => {
  //   if(chainId === FANTOM) {
  //     fetchPositions();
  //   const interval = setInterval(async () => {
  //     fetchPositions();
  //   }, 15000);
  //   return () => clearInterval(interval);
  //   }
  //   else setStats([]);
  // }, [account, chainId]);
  // return stats;
};

export default useUserStatsWithParams;
