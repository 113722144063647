
const ArrowDownSelect = (props: JSX.IntrinsicElements["svg"]) => {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M8.83468 11.4017C8.43944 12.0007 7.56058 12.0007 7.16533 11.4017L4.40443 7.21743C3.96574 6.55257 4.44256 5.66669 5.23911 5.66669L10.7609 5.66669C11.5575 5.66669 12.0343 6.55257 11.5956 7.21743L8.83468 11.4017Z"
        fill="white" />
    </svg>

  );
};

export default ArrowDownSelect;
