import { useEffect, useState } from "react";
import axios from "axios";

const useAccountsNFT = (accounts) => {
  const [NFTs, setNFTs] = useState();

  useEffect(() => {
    if (accounts.length === 0) return;
    (async () => {
      const { data } = await axios.get("https://api.mummy.finance/mummy-nft/multi", {
        params: {
          accounts: accounts.join(","),
        },
      });

      const MapNFTs = Object.keys(data).reduce((acc, cur) => {
        return { ...acc, [cur]: data[cur].nfts[0]?.tokenId };
      }, {});
      setNFTs(MapNFTs);
    })();
  }, [accounts]);

  return NFTs;
};

export default useAccountsNFT;
