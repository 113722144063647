import React from "react";

import { formatAmount } from "lib/numbers";
import { DataTable, DateAndTime, PositionHead, TableRow } from "pages/AnalyticsV2/helper/table";
import { formatHistory } from "pages/AnalyticsV2/helper";

import icView from "img/ic-view-arrow.svg";
import icViewLight from "img/ic-view-arrowLight.svg";
import { useThemeContext } from "contexts/ThemeProvider";
import { TYPE_MAP_TYPE } from "..";
import { getExplorerUrl } from "config/chains";
const TradeHistories = ({ histories, chainId, assets, isEmpty }) => {
  const { lightThemeClassName, isLightTheme } = useThemeContext();

  const formattedPositions = histories.map((history) => {
    return formatHistory(history, assets);
  });

  return (
    <DataTable $noIndex $autoHeight $noHeading={isEmpty} className={lightThemeClassName}>
      <div className="head">
        <div className="col-name">Position</div>
        <div className="col-name">Date/Time</div>
        <div className="col-name">Type</div>
        <div className="col-name">Action</div>
        <div className="col-name">Pos. Size</div>
      </div>
      <div className="body">
        {formattedPositions.map((history, i) => {
          return (
            <TableRow key={history.key || i} href={`${getExplorerUrl(chainId)}tx/${history.transactionHash}`}>
              <div d-label="Position" className="col-body">
                <PositionHead position={history} />
              </div>
              <div d-label="Date/Time" className="col-body">
                <div className="col-body-content">
                  <DateAndTime unix={history.createdAt} />
                </div>
              </div>
              <div d-label="Type" className="col-body">
                {history.positionType}
              </div>
              <div d-label="Action" className="col-body">
                {history.actionType === "ADD_TRAILING_STOP"
                  ? history.trailingStopAll
                    ? "Close"
                    : "Decrease"
                  : TYPE_MAP_TYPE[history.actionType]}
              </div>
              <div d-label="Pos. Size" className="col-body">
                <div className="col-body-content">
                  <div> ${formatAmount(history.tradeVolume, 30, 2, true)}</div>
                  <div className="muted fz-12">
                    {formatAmount(history.posQty, 30, 4, true)} {history.tokenSymbol}
                  </div>
                </div>
              </div>
              <img className="icon-view" src={isLightTheme ? icViewLight : icView} alt="view_ic" />
            </TableRow>
          );
        })}
      </div>
    </DataTable>
  );
};

export default TradeHistories;
