/* eslint-disable jsx-a11y/iframe-has-title */
import { Trans, t } from "@lingui/macro";
import Button from "components/Common/Button";
import Card from "components/Common/Card";
import Footer from "components/Footer/Footer";
import Modal from "components/Modal/Modal";
import { ARBITRUM, BASE, FANTOM, OP, getChainName } from "config/chains";
import { useChainId } from "lib/chains";
import { importImage } from "lib/legacy";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import "./BuyGMX.scss";

import ftm24Icon from "img/ic_ftm_24.svg";
import op24Icon from "img/ic_op_24.svg";
import Velodrome from "img/ic_velodrome.svg";
import arb24Icon from "img/ic_arbitrum_24.svg";
import base24Icon from "img/ic_base_24.svg";

import squid from "img/Squid.svg";
import bridge from "img/bridge.svg";
import Equalizer from "img/ic_equalizer.png";
import Spooky from "img/ic_spooky.svg";
import skeleton from "img/ic_skeleton.svg";
import microswap from "img/ic_microswap.svg";

import lifi from "img/lifi.png";
import rango from "img/rango.png";
import xy from "img/xy.svg";
import Uniswap from "img/ic_uni_40.svg";

import socketBridge from "img/socket-teck.jpeg";

import APRLabel from "components/APRLabel/APRLabel";
import patternImg from "img/dashboard/pattern.svg";
import SocketBridge from "./SocketBridge";
import { CENTRALISED_EXCHANGES, DECENTRALISED_AGGRIGATORS, EXTERNAL_LINKS, TRANSFER_EXCHANGES } from "./constants";
import { useThemeContext } from "contexts/ThemeProvider";
// import { XSwapWidget } from "@xyfinance/widget";
export default function BuyGMX() {
  const { lightThemeClassName } = useThemeContext() as any;
  const { chainId } = useChainId();

  const externalLinks = EXTERNAL_LINKS[chainId];

  const [showRango, setShowRango] = useState(false);
  const [showBridge, setShowBridge] = useState(false);
  const [showBridgeLIFI, setShowBridgeLIFI] = useState(false);
  const [showSocketBridge, setShowSocketBridge] = useState(false);
  const [showSquid, setShowSquid] = useState(false);
  const [showXswap, setShowXswap] = useState(false);

  const isSmallScreen = useMediaQuery({ query: "(max-width: 600px)" });
  let linkLifi =
    "https://jumper.exchange/?isWidget=true&widgetId=23&fromChain=56&fromToken=0x55d398326f99059fF775485246999027B3197955&toChain=250&toToken=0x01e77288b38b416F972428d562454fb329350bAc";
  if (chainId === OP) {
    linkLifi =
      "https://jumper.exchange/?isWidget=true&widgetId=23&fromChain=56&fromToken=0x55d398326f99059fF775485246999027B3197955&toChain=10&toToken=0x385719545Ef34d457A88e723504544A53F0Ad9BC";
  }
  if (chainId === ARBITRUM) {
    linkLifi =
      "https://jumper.exchange/?fromChain=56&fromToken=0x55d398326f99059fF775485246999027B3197955&isWidget=true&toChain=42161&toToken=0xA6D7D0e650aa40FFa42d845A354c12c2bc0aB15f&widgetId=23";
  }

  let linkVoyager =
    "https://app.thevoyager.io/swap?isWidget=true&widgetId=23&fromChain=56&toChain=250&toToken=0x01e77288b38b416F972428d562454fb329350bAc";
  if (chainId === OP) {
    linkVoyager = "https://app.thevoyager.io/swap?isWidget=true&widgetId=23&fromChain=1&toChain=10";
  }
  if (chainId === ARBITRUM) {
    linkVoyager = "https://app.thevoyager.io/swap?isWidget=true&widgetId=23&fromChain=1&toChain=42161";
  }
  let linkRango = "https://app.rango.exchange/swap/ETH.ETH/FANTOM.MMY--0x01e77288b38b416f972428d562454fb329350bac";
  if (chainId === OP) {
    linkRango = "https://app.rango.exchange/swap/ETH.ETH/OPTIMISM.MMY--0x385719545Ef34d457A88e723504544A53F0Ad9BC";
  }
  if (chainId === ARBITRUM) {
    linkRango = "https://app.rango.exchange/swap/ETH.ETH/ARBITRUM.MMY--0xA6D7D0e650aa40FFa42d845A354c12c2bc0aB15f";
  }

  // const { gmxPrice } = useGmxPrice(chainId, {}, active);

  return (
    <Wrapper className={lightThemeClassName}>
      <div className="BuyGMXGLP default-container page-layout">
        <div className="BuyGMXGLP-container">
          <HeadingPage>
            <LeftHeading>
              <Title>
                <span>Buy MMY on {getChainName(chainId)}</span>
              </Title>
              <Description>
                MMY is the utility and governance token. Accrues 30% of V1 and V2 markets generated fees.
              </Description>
            </LeftHeading>
            <TokenAPR>
              <div className="row ftm">
                <img src={ftm24Icon} alt="ftm-symbol" />
                <div className="title">
                  MMY Fantom APR:{" "}
                  <span>
                    <APRLabel chainId={FANTOM} label="gmxAprTotal" key="FANTOM" />
                  </span>
                </div>
              </div>
              <div className="row op">
                <img src={op24Icon} alt="ftm-symbol" />
                <div className="title">
                  MMY Optimism APR:{" "}
                  <span>
                    <APRLabel chainId={OP} label="gmxAprTotal" key="OP" />
                  </span>
                </div>
              </div>
              <div className="row arb">
                <img src={arb24Icon} alt="arb-symbol" />
                <div className="title">
                  MMY Arbitrum APR:{" "}
                  <span>
                    <APRLabel chainId={ARBITRUM} label="gmxAprTotal" key="ARBITRUM" />
                  </span>
                </div>
              </div>
              <div className="row base">
                <img src={base24Icon} alt="base-symbol" />
                <div className="title">
                  MMY Base APR:{" "}
                  <span>
                    <APRLabel chainId={BASE} label="gmxAprTotal" key="BASE" />
                  </span>
                </div>
              </div>
            </TokenAPR>
          </HeadingPage>
          <div className="cards-row">
            <DecentralisedExchanges
              chainId={chainId}
              externalLinks={externalLinks}
              showRango={showRango}
              setShowRango={setShowRango}
              showBridge={showBridge}
              setShowBridge={setShowBridge}
              showBridgeLIFI={showBridgeLIFI}
              setShowBridgeLIFI={setShowBridgeLIFI}
              showSocketBridge={showSocketBridge}
              setShowSocketBridge={setShowSocketBridge}
              showSquid={showSocketBridge}
              setShowSquid={setShowSquid}
              showXswap={showXswap}
              setShowXswap={setShowXswap}
            />
            <CentralisedExchanges chainId={chainId} externalLinks={externalLinks} />
            <StyleModal className="modal-bridge" isVisible={showSquid} setIsVisible={setShowSquid} label={`Bridge`}>
              <iframe
                title="squid_widget"
                width={`${isSmallScreen ? "370px" : "420px"}`}
                height="641"
                src={`https://widget.squidrouter.com/iframe?config=%7B%22integratorId%22%3A%22squid-swap-squid%22%2C%22companyName%22%3A%22Custom%22%2C%22style%22%3A%7B%22neutralContent%22%3A%22%23959BB2%22%2C%22baseContent%22%3A%22%23E8ECF2%22%2C%22base100%22%3A%22%2310151B%22%2C%22base200%22%3A%22%23272D3D%22%2C%22base300%22%3A%22%23171D2B%22%2C%22error%22%3A%22%23ED6A5E%22%2C%22warning%22%3A%22%23FFB155%22%2C%22success%22%3A%22%232EAEB0%22%2C%22primary%22%3A%22%2371B4BD%22%2C%22secondary%22%3A%22%2371B4BD%22%2C%22secondaryContent%22%3A%22%23191C29%22%2C%22neutral%22%3A%22%23191C29%22%2C%22roundedBtn%22%3A%225px%22%2C%22roundedCornerBtn%22%3A%22999px%22%2C%22roundedBox%22%3A%225px%22%2C%22roundedDropDown%22%3A%227px%22%2C%22displayDivider%22%3Atrue%7D%2C%22slippage%22%3A1.5%2C%22infiniteApproval%22%3Afalse%2C%22enableExpress%22%3Atrue%2C%22apiUrl%22%3A%22https%3A%2F%2Fapi.squidrouter.com%22%2C%22comingSoonChainIds%22%3A%5B%22cosmoshub-4%22%2C%22injective-1%22%2C%22axelar-dojo-1%22%2C%22kichain-2%22%5D%2C%22titles%22%3A%7B%22swap%22%3A%22Swap%22%2C%22settings%22%3A%22Settings%22%2C%22wallets%22%3A%22Wallets%22%2C%22tokens%22%3A%22Select%20Token%22%2C%22chains%22%3A%22Select%20Chain%22%2C%22history%22%3A%22History%22%2C%22transaction%22%3A%22Transaction%22%2C%22allTokens%22%3A%22Select%20Token%22%2C%22destination%22%3A%22Destination%20address%22%7D%2C%22priceImpactWarnings%22%3A%7B%22warning%22%3A3%2C%22critical%22%3A5%7D%7D`}
              />
            </StyleModal>
            {/* <StyleModal
              className="modal-bridge"
              isVisible={showSocketBridge}
              setIsVisible={setShowSocketBridge}
              label={`Socket`}
            >
              <SocketBridge />
            </StyleModal> */}

            <StyleModal isVisible={showXswap} setIsVisible={setShowXswap}>
              <iframe
                title="squid_widget"
                width={`${isSmallScreen ? "370px" : "420px"}`}
                height="641"
                src={`https://widget.xy.finance/?orientation=portrait&amount=1&device=desktop&fromTokenAddress=0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE&lockmode=none&pagecolor&referrer=0x856ebdc948afFCc6E24FCe08458051B19de652F6&slippage=1&sourceChainId=1&targetChainId=10&theme=dark&toTokenAddress=0x385719545Ef34d457A88e723504544A53F0Ad9BC`}
              />
            </StyleModal>
            <StyleModal
              className="modal-bridge"
              isVisible={showBridgeLIFI}
              setIsVisible={setShowBridgeLIFI}
              label={`Bridge`}
            >
              <iframe
                id="widget__iframe"
                height="700px"
                width={`${isSmallScreen ? "370px" : "600px"}`}
                src={linkLifi}
              ></iframe>
            </StyleModal>
            <StyleModal className="modal-bridge" isVisible={showBridge} setIsVisible={setShowBridge} label={`Bridge`}>
              <iframe
                id="widget__iframe"
                height="700px"
                width={`${isSmallScreen ? "370px" : "600px"}`}
                src={linkVoyager}
              ></iframe>
            </StyleModal>
            <StyleModal className="modal-bridge" isVisible={showRango} setIsVisible={setShowRango} label={`Rango`}>
              <iframe
                id="widget__iframe"
                height="700px"
                width={`${isSmallScreen ? "370px" : "600px"}`}
                src={linkRango}
              ></iframe>
            </StyleModal>
          </div>
        </div>
        <Footer />
      </div>
    </Wrapper>
  );
}

function DecentralisedExchanges({
  chainId,
  externalLinks,
  showRango,
  setShowRango,
  showBridge,
  setShowBridge,
  showBridgeLIFI,
  setShowBridgeLIFI,
  showSocketBridge,
  setShowSocketBridge,
  setShowSquid,
  showSquid,
  showXswap,
  setShowXswap,
}) {
  const { isLightTheme } = useThemeContext() as any;
  const isFantom = chainId === FANTOM;

  return (
    <Card title={`Buy MMY from a Decentralized Exchange`}>
      <div className="App-card-content">
        {isFantom && (
          <div className="exchange-info-group">
            <div className="BuyGMXGLP-description">
              <span>Buy MMY from Microswap:</span>
            </div>
            <div className="buttons-group">
              <Button imgSrc={microswap} className="solid-btn" href={externalLinks.buyGmx.microswap}>
                <span>Microswap</span>
              </Button>
            </div>
          </div>
        )}
        {isFantom && (
          <div className="exchange-info-group">
            <div className="BuyGMXGLP-description">
              <span>Buy MMY from Spooky:</span>
            </div>
            <div className="buttons-group">
              <Button imgSrc={Spooky} className="solid-btn" href={externalLinks.buyGmx.spooky}>
                <span>Spooky</span>
              </Button>
            </div>
          </div>
        )}
        {chainId === OP && (
          <div className="exchange-info-group">
            <div className="BuyGMXGLP-description">
              <span>Buy MMY from Uniswap:</span>
            </div>
            <div className="buttons-group col-1">
              <Button imgSrc={Uniswap} className="solid-btn" href={externalLinks.buyGmx.uniswap}>
                <span>Uniswap</span>
              </Button>
            </div>
          </div>
        )}
        {chainId === ARBITRUM && (
          <div className="exchange-info-group">
            <div className="BuyGMXGLP-description">
              <Trans>Buy MMY from Uniswap:</Trans>
            </div>
            <div className="buttons-group">
              <Button imgSrc={Uniswap} className="solid-btn" href={externalLinks.buyGmx.uniswap}>
                <Trans>Uniswap</Trans>
              </Button>
            </div>
          </div>
        )}
        {isFantom && (
          <div className="exchange-info-group">
            <div className="BuyGMXGLP-description">
              <span>Buy MMY from Equalizer:</span>
            </div>
            <div className="buttons-group">
              <Button imgSrc={Equalizer} className="solid-btn" href={externalLinks.buyGmx.equalizer}>
                <span>Equalizer</span>
              </Button>
            </div>
          </div>
        )}
        <div className="exchange-info-group">
          {DECENTRALISED_AGGRIGATORS.filter((e) => e.networks.includes(chainId)).length > 0 && (
            <div className="BuyGMXGLP-description">
              <span>Buy MMY using Decentralized Exchange Aggregators:</span>
            </div>
          )}
          {/* <div className="buttons-group">
            {DECENTRALISED_AGGRIGATORS.filter((e) => e.networks.includes(chainId)).map((exchange) => {
              const icon = importImage(exchange.icon) || "";
              const link = exchange.links ? exchange.links[chainId] : exchange.link;
              return (
                <Button key={exchange.name} className="solid-btn" imgSrc={icon} href={link}>
                  <span>{exchange.name}</span>
                </Button>
              );
            })}
          </div> */}

          {DECENTRALISED_AGGRIGATORS.filter((e) => e.networks.includes(chainId)).length > 0 && (
            <div className="buttons-group">
              {DECENTRALISED_AGGRIGATORS.filter((e) => e.networks.includes(chainId)).map((exchange) => {
                const icon = importImage(isLightTheme ? exchange.iconLight || exchange.icon : exchange.icon) || "";
                const link = exchange.links ? exchange.links[chainId] : exchange.link;
                return (
                  <Button key={exchange.name} className="solid-btn" imgSrc={icon} href={link}>
                    <span>{exchange.name}</span>
                  </Button>
                );
              })}
            </div>
          )}

          {chainId !== ARBITRUM && (
            <div
              style={{
                marginTop: DECENTRALISED_AGGRIGATORS.filter((e) => e.networks.includes(chainId)).length > 0 ? 15 : 0,
              }}
              className="exchange-info-group"
            >
              <div className="BuyGMXGLP-description">
                <span>Buy MMY from Cross-chain Swap:</span>
              </div>
              <div className="buttons-group">
                {chainId !== BASE && (
                  <>
                    {/* <Button
                      imgSrc={socketBridge}
                      className="solid-btn"
                      onClick={() => setShowSocketBridge(!showSocketBridge)}
                    >
                      <span>Socket</span>
                    </Button> */}
                    <Button imgSrc={bridge} className="solid-btn" onClick={() => setShowBridge(!showBridge)}>
                      <span>Voyager</span>
                    </Button>
                    <Button imgSrc={lifi} className="solid-btn" onClick={() => setShowBridgeLIFI(!showBridgeLIFI)}>
                      <span>LIFI</span>
                    </Button>
                    <Button imgSrc={rango} className="solid-btn" onClick={() => setShowRango(!showRango)}>
                      <span>Rango</span>
                    </Button>
                  </>
                )}
                {chainId === FANTOM && (
                  <Button imgSrc={squid} className="solid-btn" onClick={() => setShowSquid(!showSquid)}>
                    Squid
                  </Button>
                )}

                {chainId === OP && (
                  <Button imgSrc={xy} className="solid-btn" onClick={() => setShowXswap(!showXswap)}>
                    XY Finance
                  </Button>
                )}

                {chainId === FANTOM && (
                  <>
                    {TRANSFER_EXCHANGES.filter((e) => e.networks.includes(chainId)).map((exchange) => {
                      // const icon = importImage(exchange.icon) || "";
                      const icon =
                        importImage(isLightTheme ? exchange.iconLight || exchange.icon : exchange.icon) || "";

                      const link = exchange.links ? exchange.links[chainId] : exchange.link;
                      return (
                        <Button key={exchange.name} className="solid-btn" imgSrc={icon} href={link}>
                          <span>{exchange.name}</span>
                        </Button>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </Card>
  );
}

function CentralisedExchanges({ chainId, externalLinks }) {
  return (
    <Card title={`Buy MMY from centralized services`}>
      <div className="App-card-content">
        <div className="exchange-info-group">
          <div className="BuyGMXGLP-description">
            <span>Buy MMY from centralized exchanges:</span>
          </div>
          <div className="buttons-group">
            {CENTRALISED_EXCHANGES.filter((e) => e.networks.includes(chainId)).map((exchange) => {
              const icon = importImage(exchange.icon) || "";
              return (
                <Button key={exchange.name} className="solid-btn" href={exchange.link} imgSrc={icon}>
                  {exchange.name}
                </Button>
              );
            })}
          </div>
        </div>

        {/* <div className="exchange-info-group">
          <div className="BuyGMXGLP-description">
            <span>Buy MMY using FIAT gateways:</span>
          </div>
          <div className="buttons-group col-2">
            <Button href="https://www.binancecnt.com/en/buy-sell-crypto" imgSrc={Binance}>
              Binance Connect
            </Button>
            <Button href={externalLinks.buyGmx.banxa} imgSrc={Banxa}>
              Banxa
            </Button>
          </div>
        </div> */}
      </div>
    </Card>
  );
}

const TokenAPR = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  border-radius: 16px;
  overflow: hidden;
  gap: 24px;
  > .row {
    display: flex;
    padding: 16px;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 16px;
    border: 1px solid var(--white-10, rgba(255, 255, 255, 0.1));
    background: linear-gradient(90deg, rgba(19, 181, 236, 0.1) 0%, rgba(19, 181, 236, 0) 100%);
    img {
      width: 36px;
    }
    &.op {
      background-image: linear-gradient(to right, #ff04201a, #ff042000);
      border-top: 1px solid #ffffff1a;
    }
    &.arb {
      background-image: linear-gradient(90deg, rgba(0, 119, 230, 0.1) 0%, rgba(0, 119, 230, 0.1) 100%);
      border-top: 1px solid #ffffff1a;
    }
    &.base {
      background: linear-gradient(90deg, rgba(0, 82, 255, 0.1) 0%, rgba(0, 82, 255, 0) 100%);
      border-top: 1px solid #ffffff1a;
    }
    @media (max-width: 992px) {
      min-width: 320px;
    }
    .title {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: #ffffff99;
      margin-right: auto;
      display: flex;
      flex-direction: column;
      gap: 4px;

      > span {
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        color: #03f5ae;
      }
    }
  }

  @media (max-width: 992px) {
    width: 100%;
    align-items: stretch;
    align-content: space-around;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  @media (max-width: 1023px) {
    width: 100%;
  }
`;

const StyleModal = styled(Modal)`
  iframe {
    border: none;
  }

  .Modal-title-bar {
    display: none;
  }

  .divider {
    margin: 0 !important;
    display: none;
  }

  .Modal-body {
    margin: 0 !important;
    padding: 0 !important;
    max-height: unset !important;
  }

  .Modal-content {
    background-color: unset !important;
  }

  .Modal-backdrop {
    position: fixed;
    z-index: 10;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.9);
  }

  @media (max-width: 600px) {
    overflow: scroll;
  }
`;

const Description = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #ffffff99;

  a {
    color: #ffffff99;
  }
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  margin-bottom: 12px;
`;

const LeftHeading = styled.div`
  margin-bottom: 24px;
`;

const HeadingPage = styled.div`
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;

  @media (max-width: 1023px) {
    flex-direction: column;
    gap: 24px;
  }
`;

const Wrapper = styled.div`
  background-image: url(${patternImg});
  background-repeat: no-repeat;
  background-size: contain;

  @media (max-width: 767px) {
    .page-layout {
      padding-top: 64px !important;
    }
  }

  &.theme--light {
    background: linear-gradient(180deg, #f7fffd 0%, #eef1f7 100%);

    .BuyGMXGLP-container {
      .cards-row .card {
        background: #ffffff;
      }

      .row {
        border: 1px solid #e0e0e0;

        &.ftm {
          background: linear-gradient(90deg, rgba(18, 189, 248, 0.1) 0%, rgba(198, 241, 255, 0) 100%);
        }

        &.op {
          background: linear-gradient(90deg, rgba(248, 18, 44, 0.1) 0%, rgba(255, 198, 204, 0) 100%);
        }

        &.arb {
          background: linear-gradient(90deg, rgba(18, 163, 248, 0.1) 0%, rgba(198, 234, 255, 0) 100%);
        }

        &.base {
          background: linear-gradient(90deg, rgba(18, 90, 248, 0.1) 0%, rgba(198, 216, 255, 0) 100%);
        }

        .title {
          color: #0d1a16;

          span {
            color: #02b27f;
          }
        }
      }
    }
  }
`;
