import { useEffect, useState } from "react";
import axios from "axios";
import { getServerBaseUrl } from "config/backend";

function usePositionActions(chainId, posId) {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  // const [sort, setSort] = useState({
  //   field: "volume",
  //   type: "ASC",
  // });

  useEffect(() => {
    const source = axios.CancelToken.source();

    setIsLoading(true);
    async function getTraders() {
      if (posId) {
        try {
          const { data: response } = await axios(
            `${getServerBaseUrl(chainId, true)}/public/position_activities/${posId}`,
            {
              params: {
                page: currentPage,
                pageSize: rowsPerPage,
                // order: `${sort.field} ${sort.type}`,
                // from,
                // to: toNow ? Date.now() / 1000 : END_TIMESTAMP,
              },
              cancelToken: source.token,
            }
          );

          setData(response.data || {});
        } catch (error) {
          if (axios.isCancel(error)) {
            console.error("Request canceled", error.message);
          } else {
            console.error(error);
          }
        } finally {
          setIsLoading(false);
        }
      }
    }

    getTraders();
    const timerId = setInterval(getTraders, 30000);

    return () => {
      source.cancel();
      clearInterval(timerId);
    };
  }, [chainId, currentPage, posId, rowsPerPage]);

  // const handleSort = (field, type = undefined) => {
  //   if (type) {
  //     setSort({ field, type });
  //   } else {
  //     setSort((prev) => {
  //       if (prev.field === field) {
  //         return {
  //           field,
  //           type: prev.type === "ASC" ? "DESC" : "ASC",
  //         };
  //       }

  //       return {
  //         field,
  //         type: "DESC",
  //       };
  //     });
  //   }
  // };

  return {
    // sort,
    // handleSort,
    isLoading,
    data,
    currentPage,
    setCurrentPage,
    rowsPerPage,
    setRowsPerPage,
  };
}

export default usePositionActions;
