import React, { memo } from "react";

const ClockIcon = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={props.class}
  >
    <circle cx="12" cy="12" r="9" stroke={props.fill ?? "white"}stroke-opacity="0.6" stroke-width="2" />
    <path
      d="M12 6V12H15"
      stroke={props.fill ?? "white"}
      stroke-opacity="0.6"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export default memo(ClockIcon);
