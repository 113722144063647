import styled from "styled-components";

export default styled.div`
  display: flex;
  justify-content: center;
  max-width: calc(1320px + 48px);
  padding-left: 24px;
  padding-right: 24px;
  width: 100%;
  margin: 0 auto;
  /* margin-bottom: 300px; */

  @media (max-width: 1023px) {
    padding: 0 20px;
    max-width: unset;
  }
  @media (max-width: 700px) {
    margin-bottom: 0px;
  }

  @media only screen and (max-width: 1024px) {
    width: 100%;
  }

  .account-container {
    max-width: calc(1320px + 48px);
    width: 100%;
    margin: auto;
    position: relative;

    @media only screen and (max-width: 1024px) {
      width: 100%;
    }

    .group-back {
      cursor: pointer;

      display: flex;
      align-items: center;

      color: #ffffff;
      font-size: 14px;

      img {
        margin-right: 8px;
      }
    }

    .info {
      display: flex;
      align-items: center;

      margin: 20px 0;
      gap: 10px;

      span {
        font-size: 20px;
        font-weight: bold;
        color: white;
      }

      .avatar {
        height: 48px;
        width: 48px;
        border-radius: 999px;
        background-image: linear-gradient(225deg, rgb(137, 181, 133), rgb(242, 8, 199) 49%, rgb(120, 41, 35));
      }
    }

    .group-info {
      display: flex;
      gap: 30px;

      @media only screen and (max-width: 1024px) {
        flex-direction: column;

        gap: 10px;
      }

      .child {
        flex: 1 1 0%;
        padding: 24px;
        color: rgb(255, 255, 255);
        font-size: 13px;
        -webkit-box-align: center;
        align-items: center;
        display: flex;
        background: #191b2e;
        border-radius: 16px;

        img {
          margin-right: 19px;
        }

        .content {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: var(--Base-Unit-XS-8, 8px);
          .label {
            color: var(--Text-Text_Secondary, rgba(255, 255, 255, 0.6));
            font-size: 14px;
            font-weight: 400;
            line-height: 140%;
          }

          .value {
            color: #fff;
            font-size: 20px;
            font-weight: 700;
            line-height: 140%;

            &.green {
              color: #34f5ae;
            }
          }
        }
      }
    }

    .sub-tabs {
      display: flex;
      align-items: flex-start;
      margin: 24px 0 16px;
      overflow-y: auto;

      .child {
        display: flex;
        padding: 12px 16px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        color: var(--Text-Text_Secondary, rgba(255, 255, 255, 0.6));
        font-size: 14px;
        font-weight: 500;
        line-height: 140%; /* 19.6px */
        border-radius: 12px;
        text-wrap: nowrap;
        white-space: nowrap;
        cursor: pointer;

        &:hover {
          color: var(--Primary, #03f5ae);
        }

        &.active {
          background: var(--HighLight, rgba(255, 255, 255, 0.1));
          color: var(--Primary, #03f5ae);
        }
      }
    }

    .table {
      background: #212333;
      border-radius: 16px;
      .header {
        display: flex;
        font-size: 13px;

        padding: 10px 11px;
        color: rgb(185, 185, 185);
        /* background: hsla(0, 0%, 100%, 0.062745098); */
        .child {
          flex: 1;

          &.time {
            flex: 2;
          }
        }

        &.open-position {
          .net-value {
            flex: 1.5;
          }
        }
      }

      .content {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        height: 57px;
        padding: 0px 11px;
        /* background: hsla(0, 0%, 100%, 0.062745098); */
        border-bottom: 1px dashed hsla(0, 0%, 100%, 0.12745098);
        &:last-child {
          border-bottom: none;
        }
        &.open-position {
          height: 62.5px;

          .view-details {
            text-align: right;
            a {
              text-decoration: none;
              font-weight: 500;
              color: #04d397;
            }
          }

          .net-value {
            flex: 1.5;
          }
        }

        .child {
          flex: 1;

          &.time {
            flex: 2;

            color: rgb(132, 142, 156);
          }

          &.market {
            .wrapper {
              .coin {
                font-weight: 500;

                color: #fff;
              }

              .position {
                font-weight: 600;
                font-size: 12px;

                color: rgb(7, 186, 117);
              }
            }
          }

          &.size {
          }

          &.net-value {
            position: relative;
            top: -2px;
            flex: 1.5 1 0%;
            .Tooltip {
              position: relative;
              top: 2px;
            }
            .wrapper {
              .position {
                margin-top: 8px;

                color: rgb(246, 71, 93);
                font-size: 12px;
              }
            }
          }

          &.collateral {
          }

          &.pnl {
            color: #34f5ae;
          }

          &.liquidation {
            color: rgb(255, 179, 19);
          }

          &.view-details {
            text-align: right;
            a {
              text-decoration: none;
              font-weight: 500;
              color: #04d397;
            }
          }
        }
      }
    }

    .table-mobile {
      /* background-color: rgb(41, 41, 44); */
      background: #212333;
      border-radius: 16px;
      /* background-color: rgb(28, 28, 31); */
      padding: 14px;
      margin-bottom: 12px;

      .header {
        border-bottom: 1px dashed hsla(0, 0%, 100%, 0.062745098);
        padding-bottom: 14px;

        .coin {
          color: rgb(255, 227, 169);
          font-weight: 500;
        }

        .position {
          color: rgb(7, 186, 117);
          font-weight: 600;
        }
      }

      .content {
        .child {
          margin-top: 12px;

          display: flex;
          justify-content: space-between;

          &.close-on {
            .value {
              color: rgb(132, 142, 156);
            }
          }

          &.net-value {
            .coin {
              text-align: right;
            }

            .position {
              margin-top: 5px;

              font-size: 12px;
            }
          }

          &.view-details {
            justify-content: center;
            margin: 25px 0px 12px;
            border-top: 1px dashed rgb(64, 64, 64);
            padding-top: 20px;

            color: #03f5ae;
            font-size: 13px;
            font-weight: 500;
            a {
              text-decoration: none;
              color: #03f5ae;
            }
          }
        }
      }
    }
  }
`;

export const Table = styled.table`
  /* background: #202021; */
  /* border-radius: 0.4rem; */
  /* background: hsla(0, 0%, 100%, 0.062745098); */
  color: rgb(185, 185, 185);
  border-collapse: separate;
  border-spacing: 0px 12px;
`;

export const THead = styled.th`
  text-align: left;
  padding: 10px 11px;
`;

export const TRow = styled.tr`
  &:first-child {
    th {
      font-weight: 400;
      font-size: 12px;
      line-height: 140%;
      color: rgba(255, 255, 255, 0.6) !important;
      padding: 0 11px;
    }
  }
  /* background: #212333; */
  & + tr:not(:nth-child(1)) {
    /* border-top: 1px dashed rgba(255, 255, 255, 0.2); */
  }
`;

export const TDCell = styled.td`
  /* background: #29292c; */
  background: #212333;
  padding: 14px 10px;
  color: white;
  &:first-child {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }
  &:last-child {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }
`;
