import React, { useState, useCallback, useEffect, useMemo } from "react";
import { BigNumber, ethers } from "ethers";
import cx from "classnames";
import { Trans, t } from "@lingui/macro";
import { BsArrowRight } from "react-icons/bs";

import {
  DEFAULT_SLIPPAGE_AMOUNT,
  DEFAULT_HIGHER_SLIPPAGE_AMOUNT,
  USD_DECIMALS,
  importImage,
  TAKE_PROFIT,
  STOP_LOSS,
  getLiqPrice,
  getLiqRisk,
  getPnlWithoutFee,
} from "lib/legacy";
import { ARBITRUM, getChainName, getConstant, IS_NETWORK_DISABLED } from "config/chains";
import { createDecreaseOrder, useHasOutdatedUi } from "domain/legacy";
import { getContract } from "config/contracts";
import PositionRouter from "abis/PositionRouter.json";
import Checkbox from "../Checkbox/Checkbox";
import Tab from "../Tab/Tab";
import Modal from "../Modal/Modal";
import ExchangeInfoRow from "./ExchangeInfoRow";
import Tooltip from "../Tooltip/Tooltip";
import TokenSelector from "./TokenSelector";
import "./PositionSeller.css";
import StatsTooltipRow from "../StatsTooltip/StatsTooltipRow";
import { callContract, contractFetcher } from "lib/contracts";
import { getTokenAmountFromUsd } from "domain/tokens";
import { TRIGGER_PREFIX_ABOVE, TRIGGER_PREFIX_BELOW } from "config/ui";
import { useLocalStorageByChainId, useLocalStorageSerializeKey } from "lib/localStorage";
import { CLOSE_POSITION_RECEIVE_TOKEN_KEY, SLIPPAGE_BPS_KEY } from "config/localStorage";
import { getTokenInfo, getUsd } from "domain/tokens/utils";
import { usePrevious } from "lib/usePrevious";
import {
  bigNumberify,
  expandDecimals,
  formatAmount,
  formatAmountFree,
  getDisplayDecimalByAmount,
  limitDecimals,
  parseValue,
  formatNumber,
} from "lib/numbers";
import { getTokens, getWrappedToken } from "config/tokens";
import { formatDateTime, getTimeRemaining } from "lib/dates";
import ExternalLink from "components/ExternalLink/ExternalLink";
import PercentageButtons from "./PercentageButtons";
import useSWR from "swr";
import SettingsManager from "abis/SettingsManager.json";
import { preventSpecialCharacters } from "utils/helpers";
import { formatShortNumber } from "utils/formatPrice";

import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { renderRisk } from "lib/helper";
const antIcon = <LoadingOutlined style={{ fontSize: 16, color: "grey" }} spin />;
const { AddressZero } = ethers.constants;
const ORDER_SIZE_DUST_USD = expandDecimals(1, USD_DECIMALS - 1); // $0.10

export default function AddCollateral(props) {
  const {
    handleEditPosition,
    userTokenBalances,
    isVisible,
    position,
    setIsVisible,
    isHigherSlippageAllowed,
    setIsHigherSlippageAllowed,
    chainId,
    library,
    marketTokensInfo,
    isConfirm,
  } = props;
  const currentMarket = marketTokensInfo?.find((x) => x.id === position.tokenId);
  let [orderOption, setOrderOption] = useState(TAKE_PROFIT);
  const [fromValue, setFromValue] = useState("");
  const [triggerPriceInput, setTriggerPriceInput] = useState(position?.markPrice || "");
  const [closeQuantity, setCloseQuantity] = useState(0);
  const [savedSlippageAmount] = useLocalStorageSerializeKey([chainId, SLIPPAGE_BPS_KEY], DEFAULT_SLIPPAGE_AMOUNT);
  const [triggerPriceValue, setTriggerPriceValue] = useState("");
  const [keepLeverage, setKeepLeverage] = useLocalStorageSerializeKey([chainId, "Exchange-keep-leverage"], true);
  const ORDER_OPTIONS = [TAKE_PROFIT, STOP_LOSS];
  const ORDER_OPTION_LABELS = {
    [TAKE_PROFIT]: TAKE_PROFIT,
    [STOP_LOSS]: STOP_LOSS,
  };
  const onOrderOptionChange = (option) => {
    setOrderOption(option);
  };
  let allowedSlippage = savedSlippageAmount;
  if (isHigherSlippageAllowed) {
    allowedSlippage = DEFAULT_HIGHER_SLIPPAGE_AMOUNT;
  }
  const { positionNetValue } = position;
  const handleSelectPercentageTriggerPrice = (percentage) => {
    setTriggerPriceInput(percentage);
  };
  const freeQuantity = userTokenBalances?.nusdBalance.mul(parseValue(0.99, 30)).div(parseValue(1, 30));
  const handleSelectPercentageCloseQuantity = (percentage) => {
    if (percentage === 1) {
      const value = freeQuantity.mul(parseValue(percentage, 30)).div(parseValue(1, 30));
      setCloseQuantity(formatAmount(value, 30, 6, false));
    } else {
      const value = userTokenBalances?.nusdBalance.mul(parseValue(percentage, 30)).div(parseValue(1, 30));
      setCloseQuantity(formatAmount(value, 30, 6, false));
    }
  };
  const onEditPosition = () => {
    handleEditPosition && handleEditPosition(position, closeQuantity);
  };
  const settingsManagerAddress = getContract(chainId, "SettingsManager");
  const { data: liquidateThreshold } = useSWR(
    position.tokenId && [
      `ExChange:liquidateThreshold-${position.posId}`,
      chainId,
      settingsManagerAddress,
      "liquidateThreshold",
      position.tokenId,
    ],
    {
      fetcher: contractFetcher(library, SettingsManager),
    }
  );
  const markPrice = BigNumber.from(position.isLong ? currentMarket?.askPrice || 0 : currentMarket?.bidPrice || 0);
  const accruedFees =
    parseValue(position.accruedPositionFee, 30)
      .add(parseValue(position.accruedFundingFee, 30))
      .add(parseValue(position.accruedBorrowFee, 30)) || BigNumber.from(0);
  const newCollateral = closeQuantity ? parseValue(position.collateral, 30).add(parseValue(closeQuantity, 30)) : null;
  const newLeverage = newCollateral ? parseValue(position.size, 30).mul(parseValue(1, 30)).div(newCollateral) : null;
  const newLiquidPrice =
    newCollateral && newLeverage.gt(parseValue(1, 30)) && liquidateThreshold
      ? getLiqPrice(
          liquidateThreshold,
          newCollateral,
          parseValue(position.size, 30),
          accruedFees,
          position.isLong,
          parseValue(position.averagePrice, 30)
        )
      : null;
  const liquidChange = newLiquidPrice ? newLiquidPrice.sub(parseValue(position.liquidationPrice, 30)) : null;

  const pnlWithoutFees = getPnlWithoutFee(
    position.isLong,
    markPrice,
    parseValue(position.averagePrice, 30),
    parseValue(position.size, 30)
  );
  const pnl = pnlWithoutFees.sub(accruedFees);
  const LiqRisk = getLiqRisk(pnl, parseValue(position.collateral, 30));
  const newLiqRisk = newCollateral ? getLiqRisk(pnl, newCollateral) : null;
  const liqRiskChanged = newLiqRisk ? newLiqRisk.sub(LiqRisk) : null;
  // console.log("??????", {LiqRisk, pnlWithoutFees});
  const changeToLiq = useMemo(() => {
    if (!position.liquidationPrice || (currentMarket && !currentMarket.askPrice) || !liquidChange || !newLiquidPrice) {
      return {};
    }

    //% Change to Liq = (Est.liq price - Market price ) / Market price
    const current = parseValue(position.liquidationPrice, 30)
      .sub(BigNumber.from(currentMarket.askPrice))
      .mul(parseValue(100, 30))
      .div(BigNumber.from(currentMarket.askPrice));

    const _new = newLiquidPrice
      .sub(BigNumber.from(currentMarket.askPrice))
      .mul(parseValue(100, 30))
      .div(BigNumber.from(currentMarket.askPrice));

    const change = (_new.lt(0) ? _new.mul(-1) : _new).sub(current.lt(0) ? current.mul(-1) : current);

    return {
      current: current,
      change: change,
      new: _new,
    };
  }, [currentMarket?.askPrice, liquidChange, newLiquidPrice, position.liquidationPrice]);
  const getErrorLeverage = () => {
    if (newLeverage && newLeverage.lt(parseValue(1, 30))) {
      return "Leverage must be greater than 1";
    }

    return false;
  };

  const getErrorCollateral = () => {
    if (!Number(closeQuantity)) {
      return "Enter an amount";
    }

    return false;
  };

  const isDisableButton = () => {
    return !!getErrorLeverage() || !!getErrorCollateral();
  };
  return (
    <div className="PositionEditor">
      {position && (
        <Modal
          className={`PositionSeller-modal confirmation-modal`}
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          label={"Increase Collateral"}
          allowContentTouchMove
          maxWidth="480px"
        >
          <div className="order-title-line order-title-line-details">
            <img src={importImage("ic_" + position?.symbol?.toLowerCase() + "_24.svg")} alt="" />
            <div className="position-info-container">
              <div className="position-info">
                <div className="title">{position.symbol}</div>
                <div className={`side ${position?.isLong ? "side-long" : "side-short"}`}>
                  {position?.isLong ? "LONG" : "SHORT"}
                </div>
                <div className="side">
                  {position?.positionType === "Market Order" ? "Market" : position?.positionType}
                </div>
              </div>
              <div className="position-id">#{position?.posId}</div>
            </div>
          </div>
          <div className="content-container">
            <div
              className="align-right nonclickable free-colateral"
              style={{ opacity: 0.6, fontSize: "14px", marginBottom: "8px" }}
            >
              Free Collateral: ${formatAmount(userTokenBalances?.nusdBalance, USD_DECIMALS, 2, true)}
            </div>
            <div className="Exchange-swap-section" style={{ marginBottom: "8px", marginTop: "0px" }}>
              <div className="Exchange-swap-section-top">
                <div className="muted">
                  <span>Add Collateral</span>
                </div>
                {/* <div
                  className="muted align-right clickable"
                  onClick={() => setCloseQuantity(formatAmount(userTokenBalances?.nusdBalance, USD_DECIMALS, 30, true))}
                >
                  <span>Balance: ${formatAmount(userTokenBalances?.nusdBalance, USD_DECIMALS, 2, true)}</span>
                </div> */}
              </div>
              <div className="Exchange-swap-section-bottom">
                <div className="Exchange-swap-input-container">
                  <input
                    type="number"
                    min="0"
                    placeholder="0.0"
                    className="Exchange-swap-input"
                    value={closeQuantity}
                    onChange={(e) => setCloseQuantity(e.target.value)}
                    onKeyDown={preventSpecialCharacters}
                  />
                </div>
                <div className="PositionEditor-token-symbol">USD</div>
              </div>
            </div>
            {/* <span className="validate-error">{getErrorCollateral()}</span> */}
            <PercentageButtons
              onChangePercentage={handleSelectPercentageCloseQuantity}
              balance={Number(formatAmount(userTokenBalances?.nusdBalance, 30, 6, false))}
              value={closeQuantity}
              decimals={6}
              isUsd={true}
              fullBalance={userTokenBalances?.nusdBalance}
            />
            <div className="PositionEditor-info-box" style={{ marginTop: "16px" }}>
              <div className="square-container square-fee-container">
                <ExchangeInfoRow label={`Mark Price`}>
                  {currentMarket
                    ? `$${formatAmount(currentMarket.askPrice, 30, currentMarket?.decimals || 2, true)}`
                    : "--"}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Pos. Size`}>
                  ${formatAmount(parseValue(position.size, 30), 30, 2, true)}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Pos. Qty`}>
                  {formatAmount(position.quantity, 30, 4, true)} {position.symbol}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Avg. Entry Price`}>
                  ${formatAmount(parseValue(position.averagePrice, 30), 30, currentMarket?.decimals || 2, true)}
                </ExchangeInfoRow>
                <div className="divider" />

                <div className="position-change-line">
                  <div className="position-change-info" />
                  <div className="position-change-info">Current</div>
                  <div className="position-change-info">Change</div>
                  <div className="position-change-info">New</div>
                </div>
                <div className="position-change-line">
                  <div className="position-change-info">Collateral</div>
                  <div className="position-change-info change-value">
                    {" "}
                    ${formatAmount(parseValue(position.collateral, 30), 30, 2)}
                  </div>
                  <div className="position-change-info change-value">
                    {closeQuantity ? "+ $" + formatNumber(Number(closeQuantity) || 0, 2) : "$--"}
                  </div>
                  <div className="position-change-info change-value">
                    ${closeQuantity ? formatShortNumber(formatAmount(newCollateral, 30, 2, true), 2) : "--"}
                  </div>
                </div>
                <div className="position-change-line">
                  <div className="position-change-info">Leverage</div>
                  <div className="position-change-info change-value">
                    {formatAmount(parseValue(position.leverage, 30), 30, 2, true)}X
                  </div>
                  <div className="position-change-info change-value">
                    {newLeverage
                      ? `- ${formatAmount(parseValue(position.leverage, 30).sub(newLeverage), 30, 2, true)}`
                      : "--"}
                    X
                  </div>
                  <div
                    className="position-change-info change-value"
                    style={{
                      color: newLeverage && newLeverage.lt(parseValue(1, 30)) ? "#FF4D4F" : "white",
                    }}
                  >
                    {newLeverage ? formatAmount(newLeverage, 30, 2, false) : "--"}X
                  </div>
                </div>
                <div className="position-change-line">
                  <div className="position-change-info">Est. Liq. Price</div>
                  <div className="position-change-info change-value">
                    ${formatAmount(parseValue(position.liquidationPrice, 30), 30, currentMarket?.decimals || 2, true)}
                  </div>
                  <div className="position-change-info change-value">
                    {liquidChange && liquidChange.gt(0) ? "+" : "-"} $
                    {liquidChange
                      ? formatAmount(liquidChange, 30, currentMarket?.decimals || 2, true).replace("-", "")
                      : "--"}
                  </div>
                  <div className="position-change-info change-value">
                    ${newLiquidPrice ? formatAmount(newLiquidPrice, 30, currentMarket?.decimals || 2, true) : "--"}
                  </div>
                </div>
                <div className="position-change-line">
                  <div className="position-change-info">Liq. Risk</div>
                  <div className="position-change-info change-value">
                    {renderRisk(LiqRisk, false)}
                    {/* {formatAmount(LiqRisk, 30, 2, true).replace("-", "")}% */}
                  </div>
                  <div className="position-change-info change-value">
                    {" "}
                    {liqRiskChanged && liqRiskChanged.lt(0)
                      ? "+"
                      : liqRiskChanged && liqRiskChanged.gt(0)
                      ? "-"
                      : ""}{" "}
                    {liqRiskChanged !== null ? formatAmount(liqRiskChanged, 30, 2, true).replace("-", "") : "--"}%
                  </div>
                  <div className="position-change-info change-value">
                    {newLiqRisk !== null ? renderRisk(newLiqRisk, false) : "--%"}
                    {/* {newLiqRisk !== null
                      ? formatAmount(newLiqRisk, 30, 2, true).replace("-", "")
                      : "--"}
                    % */}
                  </div>
                </div>
                <div className="position-change-line">
                  <div className="position-change-info">% Change to Liq</div>
                  <div className="position-change-info change-value">
                    {changeToLiq.current ? formatAmount(changeToLiq.current, 30, 2, true).replace("-", "") : "--"}%
                  </div>
                  <div className="position-change-info change-value">
                    {changeToLiq?.change ? (changeToLiq.change.lt(0) ? "-" : changeToLiq.change.gt(0) ? "+" : "") : ""}{" "}
                    {changeToLiq.change ? formatAmount(changeToLiq.change, 30, 2, true).replace("-", "") : "--"}%
                  </div>
                  <div className="position-change-info change-value">
                    {changeToLiq.new ? formatAmount(changeToLiq.new, 30, 2, true).replace("-", "") : "--"}%
                  </div>
                </div>
              </div>
            </div>
            {/* {newLeverage && newLeverage.lt(parseValue(1, 30)) && (
              <div style={{ color: "#FF4D4F", fontSize: "14px", marginBottom: "1.05rem" }}>
                Leverage must be greater than 1
              </div>
            )} */}
            <span className="validate-error">{getErrorLeverage()}</span>
          </div>
          <div className="Exchange-swap-button-container">
            <button
              className="App-cta Exchange-swap-button"
              onClick={onEditPosition}
              disabled={isDisableButton() || isConfirm}
            >
              {getErrorCollateral() || (isConfirm ? "Increasing..." : "Increase Collateral")}
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
}
