import React, { DetailedHTMLProps, FC, useCallback, InputHTMLAttributes, useMemo } from "react";
import styled from "styled-components";
import { BigNumber } from "ethers";
import cx from "classnames";

import { formatAmount } from "lib/numbers";
import { Token } from "domain/tokens";
import { preventSpecialCharacters } from "utils/helpers";
import { useThemeContext } from "contexts/ThemeProvider";

type PercentAmounts = Record<"25" | "50" | "75" | "100", BigNumber>;

interface HasToken {
  token: Token & { balance: BigNumber };
  defaultTokenSymbol?: never;
  tokenDecimal?: never;
}

interface FixedToken {
  token?: never;
  defaultTokenSymbol: string;
  tokenDecimal: number;
}

interface HasRightLabel {
  rightLabel: string;
  onClickRightLabel?: () => void;
  getBalanceOnClickRightLabel?: boolean;
}

interface NoRightLabel {
  rightLabel?: never;
  onClickRightLabel?: never;
  getBalanceOnClickRightLabel?: never;
}

type Props = {
  leftLabel?: string;
  value: string;
  setValue?: React.Dispatch<React.SetStateAction<any>>;
  placeholder?: string;
  className?: string;
  balance?: BigNumber;
  withPercentSelect?: true;
  withMaxButton?: true;
  valueDecimal?: number;
  tokenLogo?: string;
} & (HasRightLabel | NoRightLabel) &
  (HasToken | FixedToken);

const PERCENT_AMOUNT_OPTIONS = [25, 50, 75, 100];

// Pass balance Props --> Percent buttons depend on `balance props`

const TokenInput: FC<Props & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>> = ({
  token,
  leftLabel,
  rightLabel,
  value,
  placeholder = "0.00",
  className = "",
  withPercentSelect,
  defaultTokenSymbol,
  setValue,
  onClickRightLabel,
  getBalanceOnClickRightLabel,
  balance,
  withMaxButton,
  tokenDecimal,
  valueDecimal,
  tokenLogo,
  ...inputProps
}) => {
  const { lightThemeClassName } = useThemeContext() as any;
  const _tokenDecimal = token ? token.decimals : tokenDecimal;
  const percentAmounts = useMemo<PercentAmounts>(() => {
    const result = {};

    if (token || balance) {
      for (const percent of PERCENT_AMOUNT_OPTIONS) {
        result[percent] = (!!token ? token.balance : balance)?.mul(BigNumber.from(percent)).div(100);
      }
    }

    return result as PercentAmounts;
  }, [token, balance]);

  const handleChoosePercent = useCallback(
    (percentAmount: BigNumber) => {
      if ((balance || token) && withPercentSelect && percentAmounts) {
        if (token) {
          if (token.balance?.eq(0)) {
            setValue?.("0");
            return;
          }
          setValue?.(formatAmount(percentAmount || BigNumber.from(0), _tokenDecimal, valueDecimal || _tokenDecimal));
          return;
        }
        if (balance?.eq(0)) {
          setValue?.("0");
          return;
        }
        setValue?.(formatAmount(percentAmount || BigNumber.from(0), _tokenDecimal, valueDecimal || _tokenDecimal));
      }
    },
    [balance, token, withPercentSelect, percentAmounts, setValue, _tokenDecimal, valueDecimal]
  );

  const handleClickRightLabel = () => {
    if (getBalanceOnClickRightLabel && setValue && percentAmounts) {
      if (percentAmounts["100"].eq(0)) {
        setValue("0");
        return;
      }
      setValue(formatAmount(percentAmounts["100"], _tokenDecimal, valueDecimal || _tokenDecimal));
      return;
    }
    onClickRightLabel?.();
  };

  const handleClickMax = () => {
    if (setValue && percentAmounts) {
      if (percentAmounts["100"].eq(0)) {
        setValue("0");
        return;
      }
      setValue(formatAmount(percentAmounts["100"], _tokenDecimal, valueDecimal || _tokenDecimal));
    }
  };

  return (
    <Wrapper className={`token-input-wrapper ${lightThemeClassName}`}>
      <BaseTokenInput className={`${className} base-input-wrapper`} {...inputProps}>
        <div className="label">
          <div className="left">{leftLabel}</div>
          <div className={getBalanceOnClickRightLabel || onClickRightLabel ? "clickable" : "nonclickable"} onClick={handleClickRightLabel}>
            {rightLabel}
          </div>
        </div>
        <div className="input-wrapper">
          <input
            type="number"
            className="input-buy"
            value={value}
            onKeyDown={preventSpecialCharacters}
            placeholder={placeholder}
            {...inputProps}
          />
          <div className="currency-symbol">
            {tokenLogo && <img src={tokenLogo} alt={defaultTokenSymbol || (token && token.symbol)} />}
            <span>{defaultTokenSymbol || (token && token.symbol)}</span>
            {withMaxButton && <button onClick={handleClickMax}>Max</button>}
          </div>
        </div>
      </BaseTokenInput>
      {withPercentSelect && percentAmounts && (balance || token) && (
        <div className="persent-wrapper">
          {Object.keys(percentAmounts).map((percent) => {
            return (
              <button
                key={percent}
                className={cx("percent-btn", {
                  active:
                    Number(formatAmount(percentAmounts[percent] || BigNumber.from(0), _tokenDecimal, _tokenDecimal)) ===
                      Number(value) && +value !== 0,
                })}
                onClick={() => handleChoosePercent(percentAmounts[percent])}
              >
                {percent}%
              </button>
            );
          })}
        </div>
      )}
    </Wrapper>
  );
};

const BaseTokenInput = styled.div`
  display: flex;
  padding: 8px 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 12px;
  background: #11121b;
  margin-top: 24px;

  @media screen and (max-width: 767px) {
    margin-top: 16px;
  }

  .label {
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--white-60, rgba(255, 255, 255, 0.6));
    font-size: 14px;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
  }

  .input-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    gap: 8px;

    input.input-buy {
      color: #fff;
      font-size: 16px;
      font-weight: 500;
      line-height: 140%; /* 22.4px */
      padding: 0;
      flex: 1;
    }

    input.input-buy:disabled {
      color: #fff !important;
    }

    .currency-symbol {
      display: flex;
      align-items: center;
      gap: 8px;

      &:has(img) {
        display: flex;
        align-items: center;
        gap: var(--Border_radius, 4px);

        img {
          width: 24px;
          height: 24px;
        }
      }

      span {
        /* color: var(--white-60, rgba(255, 255, 255, 0.6)); */
        color: #fff;
        font-size: 16px;
        font-weight: 500;
        line-height: 140%; /* 22.4px */
      }

      button {
        display: flex;
        padding: 0px 8px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 4px;
        background: #3bdeb7;
        border: none;

        color: #000;
        font-size: 14px;
        font-weight: 400;
        line-height: 140%; /* 19.6px */

        &:hover {
          background: #6be9ca;
        }
      }
    }
  }
`;

const Wrapper = styled.div`
  /* .head-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: var(--white-60, rgba(255, 255, 255, 0.6));

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  } */

  &.theme--light {
    .persent-wrapper {
      button {
        border: 1px solid var(--Border, rgba(13, 26, 22, 0.07)) !important;
        color: var(--Text-Text_Primary, #0d1a16) !important;
      }
    }

    ${BaseTokenInput} {
      background: var(--Background-Input_Background, rgba(38, 77, 65, 0.07)) !important;

      .label {
        * {
          color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7)) !important;
        }
      }

      input {
        background: transparent !important;
        color: var(--Text-Text_Primary, #0d1a16) !important;

        &.input-buy:disabled {
          color: var(--Text-Text_Primary, #0d1a16) !important;
        }
      }

      .currency-symbol {
        span {
          color: var(--Text-Text_Primary, #0d1a16) !important;
        }
      }
    }
  }

  .persent-wrapper {
    display: flex;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
    margin-top: 8px;

    button.percent-btn {
      display: flex;
      padding: 12px 8px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex: 1 0 0;
      border-radius: 8px;
      border: 1px solid var(--white-10, rgba(255, 255, 255, 0.1));
      color: #fff;
      background-color: transparent;
      font-size: 14px;
      font-weight: 500;
      line-height: 120%;
      height: 33px !important;
      cursor: pointer;

      &.active {
        border: 1px solid #3bdeb7 !important;
        background-color: var(--white-10, rgba(255, 255, 255, 0.1));
      }

      &:hover {
        background: var(--white-10, rgba(255, 255, 255, 0.1));
      }
    }
  }
`;

export default TokenInput;
