import { BigNumber } from "ethers";

import { expandDecimals } from "lib/numbers";

export default function useUsdPrice(
  value: BigNumber | undefined,
  nlpPrice: BigNumber | undefined,
  tokenDecimals?: number,
) {
  if (value && nlpPrice) {
    const valueUsd = value.mul(nlpPrice).div(expandDecimals(1, tokenDecimals || 18));
    return valueUsd;
  }

  return undefined;
}
