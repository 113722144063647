import { BigNumber, ethers } from "ethers";
import { useState, useEffect } from "react";

import { getContract } from "config/contracts";

import Multicall from "abis/Multicall.json";
import Token from "abis/Token.json";
import { getProvider } from "lib/rpc";
import multicall from "domain/multicall";
import { useWeb3React } from "@web3-react/core";
import { DEFAULT_FROM_V2_TOKEN, TOKENS, getNativeToken, getToken } from "config/tokens";
import { FANTOM } from "config/chains";

const convertTokenToArray = (chainId) => {
  const tokens = DEFAULT_FROM_V2_TOKEN[chainId || FANTOM] || [];
  const result: any = [];
  for (let i = 0; i < tokens.length; i++) {
    result.push({
      ...getToken(chainId, tokens[i]),
      balance: BigNumber.from(0),
    });
  }
  return result;
};

export default function usePoolTokenBalance(chainId: number, owner: string) {
  const initTokens = convertTokenToArray(chainId);
  const [data, setData] = useState(initTokens);
  const multicallAddress = getContract(chainId, "Multicall");

  useEffect(() => {
    const fetch = async () => {
      try {
        const provider = getProvider(undefined, chainId);
        if (!provider) return;

        const multicallContract = new ethers.Contract(multicallAddress, Multicall.abi, provider);
        const tokens = convertTokenToArray(chainId);
        const calls: any = [];
        for (let i = 0; i < tokens.length; i++) {
          calls.push({
            address: tokens[i].address,
            name: "balanceOf",
            params: [owner],
          });
        }
        const response = await multicall(
          multicallContract,
          Token.abi,
          calls.map(({ address, name, params }) => ({
            address,
            name,
            params,
          }))
        );

        const result: any = [];
        for (let i = 0; i < tokens.length; i++) {
          result.push({
            ...tokens[i],
            balance: response[i][0],
          });
        }
        setData(result);
      } catch (error) {
        console.error("[ERROR]: multicall", error);
      }
    };
    if (chainId && owner) {
      fetch();
      const timerId = setInterval(fetch, 15000);
      return () => clearInterval(timerId);
    } else {
      const tokens = convertTokenToArray(chainId || FANTOM);
      const result: any = [];
      for (let i = 0; i < tokens.length; i++) {
        result.push({
          ...tokens[i],
          balance: BigNumber.from(0),
        });
      }
      setData(result);
    }
  }, [owner, chainId, multicallAddress]);

  return data;
}
