const SuccessIcon = (props: JSX.IntrinsicElements["svg"]) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M9.99984 1.66663C8.35166 1.66663 6.7405 2.15537 5.37009 3.07105C3.99968 3.98672 2.93157 5.28821 2.30084 6.81093C1.67011 8.33365 1.50509 10.0092 1.82663 11.6257C2.14817 13.2422 2.94185 14.7271 4.10728 15.8925C5.27272 17.058 6.75758 17.8516 8.37409 18.1732C9.9906 18.4947 11.6662 18.3297 13.1889 17.699C14.7116 17.0682 16.0131 16.0001 16.9288 14.6297C17.8444 13.2593 18.3332 11.6481 18.3332 9.99996C18.3268 7.79179 17.4467 5.6759 15.8853 4.11449C14.3239 2.55307 12.208 1.67304 9.99984 1.66663V1.66663ZM8.61095 13.7597L4.85123 9.99996L5.83317 9.01801L8.61095 11.7958L14.1665 6.24024L15.1484 7.22218L8.61095 13.7597Z"
        fill="#03F5AE"
      />
    </svg>
  );
};

export default SuccessIcon;
