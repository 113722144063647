import { useEffect, useState } from "react";
import axios from "axios";
import { getServerBaseUrl, getServerUrl } from "config/backend";

export default function usePositionActivities(chainId, posId) {
  const [data, setData] = useState();
  const [count, setCount] = useState();

  useEffect(() => {
    (async () => {
      const { data: response } = await axios.get(
        `${getServerBaseUrl(chainId, true)}/public/position_activities/${posId}`
      );

      if (response.message === "Success") {
        const { count, rows } = response.data;
        setData(rows);
        setCount(count);
      }
    })();
  }, [chainId, posId]);

  return {
    data,
    count,
  };
}
