import Overview from "./Overview";
import TopTradersTab from "./TopTradersTab";
import TopPositionTab from "./TopPositionTab";
import ContestTab from "./ContestTab";
import SearchTab from "./SearchTab";
import PositionDetail from "./PositionDetail";
import TraderDetail from "./TraderDetail";

export const TYPE_MAP_TYPE = {
  EDIT_COLLATERAL: "Edit Collateral",
  CLOSE_POSITION: "Close",
  DECREASE_POSITION: "Decrease",
  ADD_POSITION: "Increase",
  OPEN_POSITION: "New",
  LIQUIDATE_POSITION: "Liquidated",
  ADD_TRAILING_STOP: "Decrease/Close",
};

export { Overview, TopTradersTab, TopPositionTab, ContestTab, SearchTab, PositionDetail, TraderDetail };
