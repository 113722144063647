import { useWeb3React } from "@web3-react/core";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { getExplorerUrl } from "config/chains";
import { useUserPayoutsData } from "domain/referrals";
import { useChainId } from "lib/chains";
import { formatDate } from "lib/dates";
import { formatAmount, parseValue } from "lib/numbers";
import PaginationV2 from "pages/Analytics/components/PaginationV2";
import { useEffect, useState } from "react";
import { useMedia } from "react-use";

const PayoutTab = () => {
  const { account } = useWeb3React();
  const { chainId } = useChainId();
  const { userPayoutsRows, userPayoutsCount } = useUserPayoutsData(chainId, account);
  const [payout, setPayout] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const PAGESIZE = 10;
  const isMobile = useMedia("(max-width: 700px)");
  useEffect(() => {
    if (userPayoutsRows && userPayoutsRows.length > 0) {
      const arr = userPayoutsRows.slice(
        (currentPage - 1) * PAGESIZE,
        Math.min(currentPage * PAGESIZE, userPayoutsCount)
      );
      setPayout(arr);
    }
  }, [currentPage, userPayoutsRows]);
  return (
    <div className="payout-content-container tab-payout-container">
      {!isMobile && (
        <div className="table-content table-header">
          <div className="line-content">Type</div>
          <div className="line-content">Day</div>
          <div className="line-content">Earned</div>
          <div className="line-content">Transaction</div>
        </div>
      )}
      {!isMobile && (
        <div className="table-body">
          {payout.map((item) => (
            <div className="table-content" key={item}>
              <div className="line-content">Referrer</div>
              <div className="line-content">{formatDate(item.createdAt)}</div>
              <div className="line-content">${formatAmount(parseValue(item.amount, 30), 30, 4, true)}</div>
              <ExternalLink className="line-content" href={getExplorerUrl(chainId) + "tx/" + item.trasactionHash}>
                {item.trasactionHash.slice(0, 4)}...
                {item.trasactionHash.slice(item.trasactionHash.length - 4, item.trasactionHash.length)}
              </ExternalLink>
            </div>
          ))}
        </div>
      )}
      {isMobile && (
        <div className="mobile-ref-container">
          {payout.map((item, index) => {
            return (
              <div className="ref-as-container" key={index}>
                <div className="ref-as-line">
                  <div className="line-title">Type</div>
                  <div className="line-value">Referrer</div>
                </div>
                <div className="ref-as-line">
                  <div className="line-title">Day</div>
                  <div className="line-value">{formatDate(item.createdAt)}</div>
                </div>
                <div className="ref-as-line">
                  <div className="line-title">Earn</div>
                  <div className="line-value">${formatAmount(parseValue(item.amount, 30), 30, 4, true)}</div>
                </div>
                <div className="ref-as-line">
                  <div className="line-title">Transaction</div>
                  <div className="line-value">
                    <ExternalLink className="line-content" href={getExplorerUrl(chainId) + "tx/" + item.trasactionHash}>
                      {item.trasactionHash.slice(0, 4)}...
                      {item.trasactionHash.slice(item.trasactionHash.length - 4, item.trasactionHash.length)}
                    </ExternalLink>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
      {userPayoutsCount === 0 ? <div className="no-data-record">No payout data</div>: null}
      {userPayoutsCount > PAGESIZE && (
        <PaginationV2
          rowsPerPage={PAGESIZE}
          rows={userPayoutsCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </div>
  );
};
export default PayoutTab;
