import { contractFetcher } from "lib/contracts";
import useSWR from "swr";

import PriceManager from "abis/PriceManager.json";
import { getContract } from "config/contracts";
import { bigNumberify, parseValue } from "lib/numbers";
import { useDebounce } from "lib/useDebounce";

const BASIS_POINTS_DIVISOR = 100000;

function useMintMslp({ chainId, fromValue, stakingFee, mslpPrice, isMint, totalUSD, totalSupplyBlp, fromAddress }) {
  const usdcAddress = fromAddress || getContract(chainId, "USDC");
  const priceManagerAddress = getContract(chainId, "PriceManager");

  const fromValueDebounce = useDebounce(fromValue, 500);

  const { data: usdAmount } = useSWR(
    fromValueDebounce &&
      isMint && [`PriceManager:tokenToUsd:${fromValueDebounce}`, chainId, priceManagerAddress, "tokenToUsd"],
    {
      fetcher: contractFetcher(undefined, PriceManager, [usdcAddress, parseValue(fromValueDebounce, 6)]),
    }
  );
  if (!isMint) {
    return {};
  }

  let _stakingFee;
  let usdAmountAfterFee;
  if (usdAmount && stakingFee) {
    _stakingFee = usdAmount.mul(stakingFee).div(BASIS_POINTS_DIVISOR);
    usdAmountAfterFee = usdAmount.sub(_stakingFee);
  }

  if (usdAmountAfterFee && totalSupplyBlp && totalUSD && bigNumberify(totalUSD).gt(0)) {
    const outputMint = usdAmountAfterFee.mul(totalSupplyBlp).div(totalUSD).mul(parseValue(1, 12));
    return {
      outputMint,
      stakingFee: _stakingFee,
    };
  }

  return {};
}

export default useMintMslp;
