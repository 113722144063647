import React from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import swiperImg1 from "../images/swiper1.svg";
import swiperImg2 from "../images/swiper2.svg";
import swiperImg3 from "../images/swiper3.svg";
import SwiperCore, { Pagination } from "swiper/core";

const Carousel = () => {
  SwiperCore.use([Autoplay, Pagination]);

  return (
    <Wrapper className="container">
      <Swiper
        breakpoints={{
          768: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 3,
          },
        }}
        noSwiping={true}
        speed={4000}
        slidesPerView={1}
        spaceBetween={24}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Pagination, Autoplay]}
        className="mySwiper"
      >
        <CarouselItem>
          <img src={swiperImg1} alt="" />
        </CarouselItem>
        <CarouselItem>
          <img src={swiperImg2} alt="" />
        </CarouselItem>
        <CarouselItem>
          <img src={swiperImg3} alt="" />
        </CarouselItem>
      </Swiper>
    </Wrapper>
  );
};

const CarouselItem = styled(SwiperSlide)`
  height: 212px;
  border-radius: 24px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @media (max-width: 1024px) {
    height: 200px;
  }

  @media screen and (max-width: 767px) {
    height: 171px;
  }
`;

const Wrapper = styled.div`
  padding: 32px 0px;
  .swiper {
  }

  @media (max-width: 1024px) {
    padding: 24px;
    .swiper {
      .swiper-pagination-bullets.swiper-pagination-horizontal {
        display: flex;
        height: 10px;
        align-items: center;
        justify-content: center;
        gap: 6px;
        margin-top: 24px;
      }
      .swiper-pagination-bullet {
        width: 8px;
        height: 8px;
        background: rgba(217, 217, 217, 0.24);
        border-radius: 50%;

        &.swiper-pagination-bullet-active {
          background: #d9d9d9;
        }
      }
    }
  }

  @media (max-width: 767px) {
    padding: 16px;
    .swiper {
      .swiper-pagination-bullets.swiper-pagination-horizontal {
        margin-top: 16px;
      }
    }
  }
`;

export default Carousel;
