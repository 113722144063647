import { useCallback } from "react";
import { useWeb3React } from "@web3-react/core";

import { switchNetwork } from "lib/wallets";

const useSwitchChain = (chainId) => {
  const { active } = useWeb3React();
  const changeNetwork = useCallback(
    (network) => {
      if (network === chainId) {
        return;
      }
      if (!active) {
        setTimeout(() => {
          return switchNetwork(network, active);
        }, 500);
      } else {
        return switchNetwork(network, active);
      }
    },
    [chainId, active]
  );

  return [changeNetwork];
};

export default useSwitchChain;
