import { ReactNode } from "react";
import cx from "classnames";
import "./Button.css";
import ExternalLink from "components/ExternalLink/ExternalLink";

type Props = {
  imgSrc: string;
  children: ReactNode;
  imgName?: string;
  className?: string;
  href?: string;
  size?: string;
  isDisabled?: boolean;
  onClick?: () => void;
};

export default function Button({ href, imgSrc, children, className, imgName, onClick, size = "lg", isDisabled }: Props) {
  let classNames = cx("btn btn-primary btn-left", `btn-${size}`, className);
  if (onClick) {
    return (
      <button className={classNames} onClick={onClick} disabled={isDisabled}>
        {imgSrc && <img className="btn-image" src={imgSrc} alt={imgName} />}
        <span className="btn-label">{children}</span>
      </button>
    );
  }
  return (
    <ExternalLink href={href || ''} className={classNames}>
      {imgSrc && <img className="btn-image" src={imgSrc} alt={imgName} />}
      <span className="btn-label">{children}</span>
    </ExternalLink>
  );
}
