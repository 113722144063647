const LinkSquareIcon = (props: JSX.IntrinsicElements["svg"]) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_12266_17137)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.598864 16C0.147435 15.8583 -0.00113632 15.5394 6.53459e-06 15.0811C0.00800654 11.3131 0.00457796 7.54399 0.00457796 3.77484C0.00457796 3.19199 0.282292 2.91199 0.862864 2.91199H7.92229C8.32915 2.91199 8.60229 3.10399 8.70058 3.4537C8.81486 3.85827 8.54172 4.28799 8.12686 4.35084C8.03544 4.36456 7.94058 4.36913 7.84801 4.36913C5.78058 4.36913 3.71429 4.37142 1.64801 4.36684C1.50058 4.36684 1.45601 4.39656 1.45601 4.55199C1.46058 7.82056 1.46058 11.0903 1.45601 14.36C1.45601 14.5108 1.49486 14.5497 1.64572 14.5486C4.91429 14.544 8.18286 14.544 11.4526 14.5486C11.608 14.5486 11.6389 14.5028 11.6389 14.3577C11.6343 12.2754 11.6354 10.1931 11.6354 8.11084C11.6354 7.71084 11.7989 7.43999 12.1063 7.32456C12.5463 7.1577 13.016 7.43884 13.0834 7.91199C13.0949 7.98856 13.0926 8.06742 13.0926 8.14513C13.0926 10.4571 13.0869 12.768 13.0971 15.0788C13.0994 15.536 12.9509 15.856 12.5006 16H0.598864Z"
          fill={props.fill || "#B9B9B9"}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16.0035 6.69028C15.9486 6.79542 15.9178 6.91085 15.8389 7.00457C15.7477 7.11595 15.6256 7.19792 15.4879 7.2402C15.3503 7.28249 15.2032 7.28321 15.0652 7.24228C14.9299 7.20169 14.8097 7.12177 14.72 7.01269C14.6302 6.90361 14.575 6.77029 14.5612 6.62971C14.553 6.54692 14.5492 6.46376 14.5498 6.38057L14.5486 2.70971V2.53028C14.4675 2.54514 14.4355 2.60228 14.3955 2.64342C11.9791 5.05673 9.56382 7.4712 7.14976 9.88685C6.93947 10.0983 6.70862 10.2309 6.3989 10.1634C6.2789 10.1378 6.16741 10.0821 6.07498 10.0014C5.98255 9.92066 5.91224 9.81771 5.8707 9.70225C5.82917 9.5868 5.81777 9.46265 5.83761 9.34157C5.85744 9.22048 5.90784 9.10645 5.98405 9.01028C6.03547 8.94514 6.0949 8.88685 6.15319 8.82743L13.3532 1.62742C13.3966 1.58514 13.4538 1.55428 13.5246 1.504C13.4218 1.44685 13.3589 1.46057 13.3006 1.46057L9.58405 1.45942C9.43824 1.46866 9.29222 1.44523 9.15662 1.39085C9.01114 1.32411 8.89206 1.2108 8.81817 1.06882C8.74428 0.926842 8.71981 0.764293 8.74862 0.606853C8.77528 0.448974 8.85381 0.304487 8.97179 0.196238C9.08977 0.087988 9.24046 0.0221519 9.40005 0.00913877C9.46176 0.00342448 9.52576 0.00456734 9.58747 0.00456734C11.4195 0.00456734 13.2515 0.0114245 15.0846 -4.09138e-06C15.5418 -0.00343266 15.8618 0.147424 16.0035 0.598853V6.69028Z"
          fill={props.fill || "#B9B9B9"}
        />
      </g>
      <defs>
        <clipPath id="clip0_12266_17137">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LinkSquareIcon;
