// import { AbiItem } from 'web3-utils'
import { Interface } from '@ethersproject/abi';
import { Contract } from 'ethers';

interface Call {
    address: string // Address of the contract
    name: string // Function name on the contract (example: balanceOf)
    params?: any[] // Function params
}

const multicall = async (multi: Contract, abi: any[], calls: Call[]) => {
    const itf = new Interface(abi)

    const calldata = calls.map((call) => [call?.address?.toLowerCase(), itf.encodeFunctionData(call.name, call.params)])
    const { returnData } = await multi.aggregate(calldata)
    const res = returnData.map((call: any, i: number) => itf.decodeFunctionResult(calls[i].name, call))

    return res
}

export default multicall
