import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import useSWR from "swr";
import { getServerUrl } from "config/backendV2";
import { DEFAULT_CHAIN_ID } from "config/chains";
import { fetcher } from "lib/fetcher";
import { getTokenSymbolFromString } from "domain/tokens";
import { CRYPTO, FOREX, METALS } from "config/tokens";

export type Result = Record<string, { value: string; type: string; assetSymbol: string }>;

function useOpenInterestStats(chainId: number = DEFAULT_CHAIN_ID) {
  const { data: response } = useSWR([getServerUrl(chainId, "/public/open_interests")], {
    fetcher,
    refreshInterval: 10000,
  });

  const result: Result = {};
  if (response) {
    for (const [key, value] of Object.entries(response.data)) {
      const newKey = getTokenSymbolFromString(key);
      let type = "other";
      if (CRYPTO.find((c) => c === newKey)) type = "crypto";
      if (FOREX.find((c) => c === newKey)) type = "forex";
      if (METALS.find((c) => c === newKey)) type = "metals";
      result[newKey] = { value: value as string, type, assetSymbol: key };
    }
  }

  return {
    data: response ? result : undefined,
    originData: response ? response.data : undefined,
  };
}

export default useOpenInterestStats;
