import { FC, ReactNode } from "react";
import styled from "styled-components";
import { default as AlertIc } from "img/icons/alert.svg";

interface NoticeProps {
  content: string | ReactNode;
}

const Notice: FC<NoticeProps> = ({ content }) => {
  return (
    <BaseNotice>
      <img alt="redirect-icon" src={AlertIc} />
      <div className="content">{content}</div>
    </BaseNotice>
  );
};

const BaseNotice = styled.div`
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 8px;
  border-radius: 12px;
  border: 1px solid #ffa722;
  background: rgba(255, 167, 34, 0.1);

  .content {
    color: #ffa722 !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
  }
`;

export default Notice;
