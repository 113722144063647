import { Menu } from "@headlessui/react";
import { useWeb3React } from "@web3-react/core";
import Modal from "components/Modal/Modal";
import Tooltip from "components/Tooltip/Tooltip";
import MintIc from "img/home/ic-mint.svg";
import MintIcLight from "img/home/ic-mintLight.svg";
import DropdownIcon from "img/icons/dropdown-ic.svg";
import InfoIc from "img/icons/ic-info.svg";
import InfoIcLight from "img/icons/ic-infoLight.svg";
import icPrev from "img/icons/ic-prev.svg";
import linkWhite from "img/icons/link-white.svg";
import { bigNumberify, formatAmount, formatNumber, parseValue } from "lib/numbers";
import { useEffect, useState } from "react";
import styled from "styled-components";
import "./dropdown.scss";
import "./MintModal.scss";
import icScan from "img/ic_scan.svg";
import icCheckbox from "img/bridge/Checkbox.svg";
import icCheckboxChecked from "img/bridge/Checkbox_Checked.svg";
import icNFTItem from "img/bridge/nft-item.svg";
import icBolt from "img/bridge/bolt.svg";
import icEmpty from "img/bridge/empty.png";
import MummyClubSale from "abis/MummyClubSale.json";
import NFTClub from "abis/NFTClub.json";
import NFTSaleV2 from "abis/NFTSale.json";
import { ethers } from "ethers";

import { commify } from "@ethersproject/units";
import { FANTOM, MUMBAI, OP, getConstant, getDocsClub, getLinkCDN } from "config/chains";
import { getContract } from "config/contracts";
import useProviderBalance from "hooks/useProviderBalance";
import { useChainId } from "lib/chains";
import { callContract, contractFetcher } from "lib/contracts";
import Slider from "react-slick";
import { useMedia } from "react-use";
import useSWR from "swr";
import { TXT } from "../multichainTxt";
import icPlus from "img/bridge/ic-plus.svg";
import { switchNetwork } from "lib/wallets";
import { Link } from "react-router-dom";
import useGetListStakedNFT from "hooks/useGetListStakedNFT";

import SelectedNftItem from "pages/Bridge/SelectedNftItem";
import ExternalLink from "components/ExternalLink/ExternalLink";
import ConfirmModal from "./ConfirmModal";
import useGetListStakedNFTv2 from "hooks/useGetListStakedNFTv2";
import { useThemeContext } from "contexts/ThemeProvider";
const abiDecoder = require("abi-decoder");
window.Buffer = window.Buffer || require("buffer").Buffer;
// eslint-disable-next-line no-sparse-arrays
const listOption = [
  {
    num: 1,
  },
  {
    num: 2,
  },
  {
    num: 3,
  },
  {
    num: 4,
  },
  {
    num: 5,
  },
];
export default function MintModal({ isVisible, setIsVisible, connectWallet, setPendingTxns, pendingTxns }) {
  const { isLightTheme } = useThemeContext();
  const [minting, setMinting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const [isVisibleConfirm, setIsVisibleConfirm] = useState(false);
  const [isVisibleNFT, setIsVisibleNFT] = useState(false);
  const [selectedNum, setSelectedNum] = useState(listOption[0]);
  const [isConfirming, setConfirming] = useState(false);

  const handleClick = (item) => {
    setSelectedNum(item);
    if (nftList.length > 0) {
      if (item.num < selectedNft.length) {
        const arr = [...nftList];
        for (let i = 0; i < nftList.length; i++) {
          if (i < item.num) arr[i].selected = true;
          else arr[i].selected = false;
        }
        setNftList(arr);
      }
    }
  };
  const isMobile = useMedia("(max-width: 676px)");

  const { active, activate, account, library } = useWeb3React();
  const { chainId } = useChainId();
  const [nftList, setNftList] = useState([]);
  const [selectedNft, setSelectedNft] = useState([]);

  const [modalNftList, setModaltNftList] = useState([]);
  const ftmBalance = useProviderBalance();
  const { listMyNFT } = useGetListStakedNFT();
  // console.log("????", listMyNFT);
  // const [nftId, setNFTId] = useState([]);

  const isV2 = chainId === FANTOM || chainId === MUMBAI;
  const [dataMinted, setDataMinted] = useState([]);
  const NFTSaleV2Address = getContract(chainId, "NFTSaleV2");
  const nftClubAddress = getContract(chainId, isV2 ? "NFTClubV2" : "NFTClub");
  const nftCluV1bAddress = getContract(chainId, "NFTClub");

  const MummyClubSaleAddress = getContract(chainId, isV2 ? "NFTSaleV2" : "MummyClubSale");

  const { data: estimateAmount } = useSWR(
    [`NFT:estimateAmount:${selectedNum.num}`, chainId, MummyClubSaleAddress, "estimateAmount"],
    {
      fetcher: contractFetcher(library, MummyClubSale, [selectedNum.num]),
    }
  );
  const { data: estimateAmountV2 } = useSWR(
    [`NFT:estimateAmountV2:${selectedNum.num}`, chainId, NFTSaleV2Address, "estimateAmount"],
    {
      fetcher: contractFetcher(library, NFTSaleV2, [selectedNum.num]),
    }
  );
  const { data: activeSaleV2 } = useSWR([`NFT:activeSaleV2`, chainId, NFTSaleV2Address, "saleIsActive"], {
    fetcher: contractFetcher(library, NFTSaleV2),
  });
  // console.log("????", activeSaleV2, NFTSaleV2Address);
  const { data: currentPP } = useSWR([`NFT:currentPP:${active}`, chainId, MummyClubSaleAddress, "getCurrentPP"], {
    fetcher: contractFetcher(library, MummyClubSale),
  });
  const { data: totalSupply } = useSWR([`NFT:totalSupply:${active}`, chainId, nftClubAddress, "totalSupply"], {
    fetcher: contractFetcher(library, NFTClub),
  });

  // const { data: totalPower } = useSWR([`NFT:totalSupply:${active}`, chainId, MummyClubSaleAddress, "totalPower"], {
  //   fetcher: contractFetcher(library, MummyClubSale),
  // });
  const { data: totalVolume } = useSWR([`NFT:totalSupply:${active}`, chainId, MummyClubSaleAddress, "totalVolume"], {
    fetcher: contractFetcher(library, MummyClubSale),
  });
  const nextPrice =
    currentPP && currentPP._mccPrice ? currentPP._mccPrice.mul(bigNumberify(101)).div(bigNumberify(100)) : 0;
  const nextPower = currentPP && currentPP._mccPower ? (parseFloat(currentPP._mccPower) * 99) / 100 : 0;
  const nextBonus =
    currentPP && currentPP._esMMYBonus ? currentPP._esMMYBonus.mul(bigNumberify(99)).div(bigNumberify(100)) : 0;
  const { data: isApprovedNFT } = useSWR(
    account && [`BurnNFT:isApprovedForAll:${active && account}`, chainId, nftCluV1bAddress, "isApprovedForAll"],
    {
      fetcher: contractFetcher(library, NFTClub, [account, NFTSaleV2Address]),
    }
  );

  useEffect(() => {
    if (!isVisible) {
      setIsSuccess(false);
    }
  }, [isVisible]);

  //NFT
  useEffect(() => {
    if (listMyNFT && listMyNFT.length > 0) {
      const availableNFTs = listMyNFT.filter((x) => !x[2]);
      if (availableNFTs.length !== nftList.length) {
        let arrNft = [];
        for (let i = 0; i < listMyNFT.length; i++) {
          if (!listMyNFT[i][2]) arrNft.push({ id: listMyNFT[i][0], selected: false, power: listMyNFT[i][1] });
        }
        setNftList([...arrNft]);
      }
    } else {
      setNftList([]);
      setModaltNftList([]);
    }
  }, [listMyNFT]);
  useEffect(() => {
    if (isVisibleNFT) {
      let arrModalNft = [];
      for (let i = 0; i < nftList.length; i++) {
        arrModalNft.push({ id: nftList[i].id, selected: nftList[i].selected, power: nftList[i].power });
      }
      setModaltNftList([...arrModalNft]);
    }
  }, [isVisibleNFT]);
  useEffect(() => {
    const selectedArr = [...nftList].filter((x) => x.selected);
    setSelectedNft(selectedArr);
  }, [nftList]);
  useEffect(() => {
    if (nftList.length > 0) {
      if (selectedNft.length === 0) {
        // setSelectedNum(listOption[0]);
      } else {
        const option = listOption.find((x) => x.num === selectedNft.length);
        setSelectedNum(option);
      }
    }
  }, [selectedNft]);
  useEffect(() => {
    if (isVisibleNFT && nftList.length > 0 && modalNftList.length === 0) {
      let arrModalNft = [];
      for (let i = 0; i < nftList.length; i++) {
        arrModalNft.push({ id: nftList[i].id, selected: nftList[i].selected, power: nftList[i].power });
      }
      setModaltNftList([...arrModalNft]);
    }
  }, [nftList]);

  const handleSelectModalNft = (nft) => {
    const length = modalNftList.filter((x) => x.selected).length;
    if (length < 5 || nft.selected) {
      let arrModal = [...modalNftList];
      for (let i = 0; i < arrModal.length; i++) {
        if (arrModal[i].id === nft.id) {
          arrModal[i].selected = !nft.selected;
          break;
        }
      }
      setModaltNftList(arrModal);
    }
  };

  const onApproveNFT = () => {
    const contract = new ethers.Contract(nftCluV1bAddress, NFTClub.abi, library.getSigner());
    callContract(chainId, contract, "setApprovalForAll", [NFTSaleV2Address, true], {
      sentMsg: `Approve NFT submitted!`,
      failMsg: `Approve NFT failed.`,
      successMsg: `Approve NFT completed!`,
      setPendingTxns,
    })
      .then(async (res) => {})
      .finally(() => {});
  };
  const handleSelectAllModalNft = () => {
    let arrModal = [...modalNftList];

    if (modalNftList.length > 5) {
      for (let i = 0; i < arrModal.length; i++) {
        if (i < 5) arrModal[i].selected = true;
        else arrModal[i].selected = false;
      }
    } else {
      for (let i = 0; i < arrModal.length; i++) {
        arrModal[i].selected = true;
      }
    }
    setModaltNftList(arrModal);
  };
  const handleDeselectAllModalNft = () => {
    let arrModal = [...modalNftList];
    for (let i = 0; i < arrModal.length; i++) {
      arrModal[i].selected = false;
    }
    setModaltNftList(arrModal);
  };
  const nativeTokenSymbol = getConstant(chainId, "nativeTokenSymbol");
  const handleContinueNftModal = () => {
    let arrModal = [];
    for (let i = 0; i < modalNftList.length; i++) {
      arrModal.push({ id: modalNftList[i].id, selected: modalNftList[i].selected, power: modalNftList[i].power });
    }
    setNftList(arrModal);
    setIsVisibleNFT(false);
  };
  const handleRemoveNft = (nft) => {
    let arrSelected = [];
    for (let i = 0; i < nftList.length; i++) {
      arrSelected.push({ id: nftList[i].id, selected: nftList[i].selected, power: nftList[i].power });
    }
    for (let i = 0; i < arrSelected.length; i++) {
      if (arrSelected[i].id === nft.id) {
        arrSelected[i].selected = false;
        break;
      }
    }
    setNftList(arrSelected);
  };
  const StakedNFTs = listMyNFT.filter((x) => x[2]);
  // const selectedNft = nftList.filter((x) => x.selected === true);
  const selectedModalNft = modalNftList.filter((x) => x.selected === true);

  const number = Number(totalSupply);
  const lastMintNFT = [];
  for (let index = number; index > number - 6 && index > 0; index--) {
    lastMintNFT.push(index);
  }
  const listIds = selectedNft.map((x) => Number(x.id));
  const { data: estimateDAIAmount } = useSWR(
    [`NFT:estimateDaiAmount:${[listIds, active]}`, chainId, NFTSaleV2Address, "estimateDaiAmount"],
    {
      fetcher: contractFetcher(library, NFTSaleV2, [listIds]),
    }
  );
  // console.log("????? estimateDAIAmount", estimateDAIAmount, listIds);
  const isDisabledMint = () => {
    if (Date.now() <= 1671462000000) return true;
    if (selectedNft.length > 0 && selectedNum.num !== selectedNft.length) return true;
    return false;
  };
  const mintNFT = () => {
    const opts = {
      value: estimateAmount?._totalPrice.toString(),
      // gasLimit: bigNumberify(7258590),
      successMsg: `Mint completed`,
      failMsg: `Mint failed`,
      sentMsg: `Mint submitted`,
      pendingTxns,
      setPendingTxns,
    };
    const params = [selectedNum.num];
    const method = "mintMummyClub";
    const contract = new ethers.Contract(MummyClubSaleAddress, MummyClubSale.abi, library.getSigner());
    callContract(chainId, contract, method, params, opts)
      .then(async (tx) => {
        try {
          setMinting(true);
          const receipt = await tx.wait();

          abiDecoder.addABI(MummyClubSale.abi);
          const decodedLogs = abiDecoder.decodeLogs(receipt.logs);
          const eventLog = decodedLogs.filter((item) => item.name === "AssetMinted");
          if (eventLog) {
            const listData = [];
            eventLog.forEach((element) => {
              listData.push({
                nftID: element.events[1].value,
                power: element.events[2].value,
                bonus: element.events[3].value,
              });
            });
            // setNFTId(listData);
            setDataMinted(listData);
          }
          setIsSuccess(true);
        } catch (error) {
          console.log(error);
          // history.push("/your-nft")
        }
      })
      .finally(() => {
        setMinting(false);
      });
    //TODO
    //   const receipt = {
    //     "to": "0xBf064Aa32DbEC74F176F7a24380c64b06ae35767",
    //     "from": "0xbdb0831e31e178820654DdD7cC8902C22c9280d5",
    //     "contractAddress": null,
    //     "transactionIndex": 1,
    //     "gasUsed": {
    //         "type": "BigNumber",
    //         "hex": "0x0830f6"
    //     },
    //     "logsBloom": "0x00000000000000000000000000000000000000000000000000000000000200000082000000000000000000100000000000008000000020000000000000008000000000000000000000000108000000800001000000000000000100000000000001000000020002000000000000000800000000000000040080000010000000000000000000000000000000200000000000000000000000000000000000000080200000000100002000080000002000000000000000000000000000002000004000100002000010000001000000001100000020000000800000108008000020000000000000000000000000100000010008000000000000000000000002100000",
    //     "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db",
    //     "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //     "logs": [
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0x0000000000000000000000000000000000001010",
    //             "topics": [
    //                 "0xe6497e3ee548a3372136af2fcb0696db31fc6cf20260707645068bd3fe97f3c4",
    //                 "0x0000000000000000000000000000000000000000000000000000000000001010",
    //                 "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d5",
    //                 "0x000000000000000000000000bf064aa32dbec74f176f7a24380c64b06ae35767"
    //             ],
    //             "data": "0x000000000000000000000000000000000000000000000000000d9a505f7fa2e000000000000000000000000000000000000000000000000003c386f53c670c9700000000000000000000000000000000000000000000000000799dce5f9df2c000000000000000000000000000000000000000000000000003b5eca4dce769b70000000000000000000000000000000000000000000000000087381ebf1d95a0",
    //             "logIndex": 2,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db"
    //         },
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0xBf064Aa32DbEC74F176F7a24380c64b06ae35767",
    //             "topics": [
    //                 "0x8d1457c1d60a6987eabbac898a50d8d7c81ba604c563663d2807027a4b079054"
    //             ],
    //             "data": "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d50000000000000000000000000000000000000000000000000000000000000026",
    //             "logIndex": 3,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db"
    //         },
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0xBf064Aa32DbEC74F176F7a24380c64b06ae35767",
    //             "topics": [
    //                 "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef",
    //                 "0x0000000000000000000000000000000000000000000000000000000000000000",
    //                 "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d5",
    //                 "0x0000000000000000000000000000000000000000000000000000000000000026"
    //             ],
    //             "data": "0x",
    //             "logIndex": 4,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db"
    //         },
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0x739E5C12DEf097ece09020902F0C3E432997d22e",
    //             "topics": [
    //                 "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef",
    //                 "0x000000000000000000000000bf064aa32dbec74f176f7a24380c64b06ae35767",
    //                 "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d5"
    //             ],
    //             "data": "0x000000000000000000000000000000000000000000000002733896c68d9a4000",
    //             "logIndex": 5,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db"
    //         },
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0xBf064Aa32DbEC74F176F7a24380c64b06ae35767",
    //             "topics": [
    //                 "0x8d1457c1d60a6987eabbac898a50d8d7c81ba604c563663d2807027a4b079054"
    //             ],
    //             "data": "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d50000000000000000000000000000000000000000000000000000000000000027",
    //             "logIndex": 6,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db"
    //         },
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0xBf064Aa32DbEC74F176F7a24380c64b06ae35767",
    //             "topics": [
    //                 "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef",
    //                 "0x0000000000000000000000000000000000000000000000000000000000000000",
    //                 "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d5",
    //                 "0x0000000000000000000000000000000000000000000000000000000000000027"
    //             ],
    //             "data": "0x",
    //             "logIndex": 7,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db"
    //         },
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0x739E5C12DEf097ece09020902F0C3E432997d22e",
    //             "topics": [
    //                 "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef",
    //                 "0x000000000000000000000000bf064aa32dbec74f176f7a24380c64b06ae35767",
    //                 "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d5"
    //             ],
    //             "data": "0x000000000000000000000000000000000000000000000002733896c68d9a4000",
    //             "logIndex": 8,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db"
    //         },
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0xBf064Aa32DbEC74F176F7a24380c64b06ae35767",
    //             "topics": [
    //                 "0x8d1457c1d60a6987eabbac898a50d8d7c81ba604c563663d2807027a4b079054"
    //             ],
    //             "data": "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d50000000000000000000000000000000000000000000000000000000000000028",
    //             "logIndex": 9,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db"
    //         },
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0xBf064Aa32DbEC74F176F7a24380c64b06ae35767",
    //             "topics": [
    //                 "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef",
    //                 "0x0000000000000000000000000000000000000000000000000000000000000000",
    //                 "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d5",
    //                 "0x0000000000000000000000000000000000000000000000000000000000000028"
    //             ],
    //             "data": "0x",
    //             "logIndex": 10,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db"
    //         },
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0x739E5C12DEf097ece09020902F0C3E432997d22e",
    //             "topics": [
    //                 "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef",
    //                 "0x000000000000000000000000bf064aa32dbec74f176f7a24380c64b06ae35767",
    //                 "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d5"
    //             ],
    //             "data": "0x000000000000000000000000000000000000000000000002733896c68d9a4000",
    //             "logIndex": 11,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db"
    //         },
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0x0000000000000000000000000000000000001010",
    //             "topics": [
    //                 "0x4dfe1bbbcf077ddc3e01291eea2d5c70c2b422b415d95645b9adcfd678cb1d63",
    //                 "0x0000000000000000000000000000000000000000000000000000000000001010",
    //                 "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d5",
    //                 "0x0000000000000000000000003a22c8bc68e98b0faf40f349dd2b2890fae01484"
    //             ],
    //             "data": "0x0000000000000000000000000000000000000000000000000043b467b83ecb9c000000000000000000000000000000000000000000000000040eb1c19bccc3ed00000000000000000000000000000000000000000000010c455e0c95b2fcabf100000000000000000000000000000000000000000000000003cafd59e38df85100000000000000000000000000000000000000000000010c45a1c0fd6b3b778d",
    //             "logIndex": 12,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db"
    //         }
    //     ],
    //     "blockNumber": 29787980,
    //     "confirmations": 4,
    //     "cumulativeGasUsed": {
    //         "type": "BigNumber",
    //         "hex": "0x08e586"
    //     },
    //     "effectiveGasPrice": {
    //         "type": "BigNumber",
    //         "hex": "0x0843f7031a"
    //     },
    //     "status": 1,
    //     "type": 2,
    //     "byzantium": true,
    //     "events": [
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0x0000000000000000000000000000000000001010",
    //             "topics": [
    //                 "0xe6497e3ee548a3372136af2fcb0696db31fc6cf20260707645068bd3fe97f3c4",
    //                 "0x0000000000000000000000000000000000000000000000000000000000001010",
    //                 "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d5",
    //                 "0x000000000000000000000000bf064aa32dbec74f176f7a24380c64b06ae35767"
    //             ],
    //             "data": "0x000000000000000000000000000000000000000000000000000d9a505f7fa2e000000000000000000000000000000000000000000000000003c386f53c670c9700000000000000000000000000000000000000000000000000799dce5f9df2c000000000000000000000000000000000000000000000000003b5eca4dce769b70000000000000000000000000000000000000000000000000087381ebf1d95a0",
    //             "logIndex": 2,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db"
    //         },
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0xBf064Aa32DbEC74F176F7a24380c64b06ae35767",
    //             "topics": [
    //                 "0x8d1457c1d60a6987eabbac898a50d8d7c81ba604c563663d2807027a4b079054"
    //             ],
    //             "data": "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d50000000000000000000000000000000000000000000000000000000000000026",
    //             "logIndex": 3,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db",
    //             "args": [
    //                 "0xbdb0831e31e178820654DdD7cC8902C22c9280d5",
    //                 {
    //                     "type": "BigNumber",
    //                     "hex": "0x26"
    //                 }
    //             ],
    //             "event": "AssetMinted",
    //             "eventSignature": "AssetMinted(address,uint256)"
    //         },
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0xBf064Aa32DbEC74F176F7a24380c64b06ae35767",
    //             "topics": [
    //                 "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef",
    //                 "0x0000000000000000000000000000000000000000000000000000000000000000",
    //                 "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d5",
    //                 "0x0000000000000000000000000000000000000000000000000000000000000026"
    //             ],
    //             "data": "0x",
    //             "logIndex": 4,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db",
    //             "args": [
    //                 "0x0000000000000000000000000000000000000000",
    //                 "0xbdb0831e31e178820654DdD7cC8902C22c9280d5",
    //                 {
    //                     "type": "BigNumber",
    //                     "hex": "0x26"
    //                 }
    //             ],
    //             "event": "Transfer",
    //             "eventSignature": "Transfer(address,address,uint256)"
    //         },
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0x739E5C12DEf097ece09020902F0C3E432997d22e",
    //             "topics": [
    //                 "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef",
    //                 "0x000000000000000000000000bf064aa32dbec74f176f7a24380c64b06ae35767",
    //                 "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d5"
    //             ],
    //             "data": "0x000000000000000000000000000000000000000000000002733896c68d9a4000",
    //             "logIndex": 5,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db"
    //         },
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0xBf064Aa32DbEC74F176F7a24380c64b06ae35767",
    //             "topics": [
    //                 "0x8d1457c1d60a6987eabbac898a50d8d7c81ba604c563663d2807027a4b079054"
    //             ],
    //             "data": "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d50000000000000000000000000000000000000000000000000000000000000027",
    //             "logIndex": 6,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db",
    //             "args": [
    //                 "0xbdb0831e31e178820654DdD7cC8902C22c9280d5",
    //                 {
    //                     "type": "BigNumber",
    //                     "hex": "0x27"
    //                 }
    //             ],
    //             "event": "AssetMinted",
    //             "eventSignature": "AssetMinted(address,uint256)"
    //         },
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0xBf064Aa32DbEC74F176F7a24380c64b06ae35767",
    //             "topics": [
    //                 "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef",
    //                 "0x0000000000000000000000000000000000000000000000000000000000000000",
    //                 "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d5",
    //                 "0x0000000000000000000000000000000000000000000000000000000000000027"
    //             ],
    //             "data": "0x",
    //             "logIndex": 7,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db",
    //             "args": [
    //                 "0x0000000000000000000000000000000000000000",
    //                 "0xbdb0831e31e178820654DdD7cC8902C22c9280d5",
    //                 {
    //                     "type": "BigNumber",
    //                     "hex": "0x27"
    //                 }
    //             ],
    //             "event": "Transfer",
    //             "eventSignature": "Transfer(address,address,uint256)"
    //         },
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0x739E5C12DEf097ece09020902F0C3E432997d22e",
    //             "topics": [
    //                 "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef",
    //                 "0x000000000000000000000000bf064aa32dbec74f176f7a24380c64b06ae35767",
    //                 "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d5"
    //             ],
    //             "data": "0x000000000000000000000000000000000000000000000002733896c68d9a4000",
    //             "logIndex": 8,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db"
    //         },
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0xBf064Aa32DbEC74F176F7a24380c64b06ae35767",
    //             "topics": [
    //                 "0x8d1457c1d60a6987eabbac898a50d8d7c81ba604c563663d2807027a4b079054"
    //             ],
    //             "data": "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d50000000000000000000000000000000000000000000000000000000000000028",
    //             "logIndex": 9,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db",
    //             "args": [
    //                 "0xbdb0831e31e178820654DdD7cC8902C22c9280d5",
    //                 {
    //                     "type": "BigNumber",
    //                     "hex": "0x28"
    //                 }
    //             ],
    //             "event": "AssetMinted",
    //             "eventSignature": "AssetMinted(address,uint256)"
    //         },
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0xBf064Aa32DbEC74F176F7a24380c64b06ae35767",
    //             "topics": [
    //                 "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef",
    //                 "0x0000000000000000000000000000000000000000000000000000000000000000",
    //                 "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d5",
    //                 "0x0000000000000000000000000000000000000000000000000000000000000028"
    //             ],
    //             "data": "0x",
    //             "logIndex": 10,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db",
    //             "args": [
    //                 "0x0000000000000000000000000000000000000000",
    //                 "0xbdb0831e31e178820654DdD7cC8902C22c9280d5",
    //                 {
    //                     "type": "BigNumber",
    //                     "hex": "0x28"
    //                 }
    //             ],
    //             "event": "Transfer",
    //             "eventSignature": "Transfer(address,address,uint256)"
    //         },
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0x739E5C12DEf097ece09020902F0C3E432997d22e",
    //             "topics": [
    //                 "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef",
    //                 "0x000000000000000000000000bf064aa32dbec74f176f7a24380c64b06ae35767",
    //                 "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d5"
    //             ],
    //             "data": "0x000000000000000000000000000000000000000000000002733896c68d9a4000",
    //             "logIndex": 11,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db"
    //         },
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0x0000000000000000000000000000000000001010",
    //             "topics": [
    //                 "0x4dfe1bbbcf077ddc3e01291eea2d5c70c2b422b415d95645b9adcfd678cb1d63",
    //                 "0x0000000000000000000000000000000000000000000000000000000000001010",
    //                 "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d5",
    //                 "0x0000000000000000000000003a22c8bc68e98b0faf40f349dd2b2890fae01484"
    //             ],
    //             "data": "0x0000000000000000000000000000000000000000000000000043b467b83ecb9c000000000000000000000000000000000000000000000000040eb1c19bccc3ed00000000000000000000000000000000000000000000010c455e0c95b2fcabf100000000000000000000000000000000000000000000000003cafd59e38df85100000000000000000000000000000000000000000000010c45a1c0fd6b3b778d",
    //             "logIndex": 12,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db"
    //         }
    //     ]
    // }
    //   abiDecoder.addABI(NFTClub.abi);
    //   const decodedLogs = abiDecoder.decodeLogs(receipt.logs);
    //   const eventLog = decodedLogs.filter((item) => item.name === "AssetMinted");
    //   if (eventLog) {
    //     const listID = eventLog.map((item) => item.events[1].value)
    //     setNFTId(listID)
    //   }
    //   setIsSuccess(true);
    //   setMinting(false);
  };

  const NFT_SUPPLY = chainId === FANTOM || chainId === MUMBAI ? 10000 : 5000;
  const mintNFTFANTOMV2 = () => {
    setConfirming(true);
    const opts = {
      value: estimateAmountV2?._totalPrice.toString(),
      // gasLimit: bigNumberify(7258590),
      successMsg: `Mint completed`,
      failMsg: `Mint failed`,
      sentMsg: `Mint submitted`,
      pendingTxns,
      setPendingTxns,
    };
    const params = selectedNft.length > 0 ? [listIds, selectedNum.num] : [selectedNum.num];
    const method = selectedNft.length > 0 ? "redeemAndMintNFT" : "mintMummyClub";
    const contract = new ethers.Contract(NFTSaleV2Address, NFTSaleV2.abi, library.getSigner());
    callContract(chainId, contract, method, params, opts)
      .then(async (tx) => {
        try {
          setIsVisibleConfirm(false);
          setMinting(true);
          const receipt = await tx.wait();

          abiDecoder.addABI(NFTSaleV2.abi);
          const decodedLogs = abiDecoder.decodeLogs(receipt.logs);
          const eventLog = decodedLogs.filter((item) => item.name === "AssetMinted");
          if (eventLog) {
            const listData = [];
            eventLog.forEach((element) => {
              listData.push({
                nftID: element.events[1].value,
                power: element.events[2].value,
                bonus: element.events[3].value,
              });
            });
            // setNFTId(listData);
            setDataMinted(listData);
          }

          setIsSuccess(true);
        } catch (error) {
          console.log(error);
          // history.push("/your-nft")
        }
      })
      .finally(() => {
        setMinting(false);
        setConfirming(false);
      });
    //TODO
    //   const receipt = {
    //     "to": "0xBf064Aa32DbEC74F176F7a24380c64b06ae35767",
    //     "from": "0xbdb0831e31e178820654DdD7cC8902C22c9280d5",
    //     "contractAddress": null,
    //     "transactionIndex": 1,
    //     "gasUsed": {
    //         "type": "BigNumber",
    //         "hex": "0x0830f6"
    //     },
    //     "logsBloom": "0x00000000000000000000000000000000000000000000000000000000000200000082000000000000000000100000000000008000000020000000000000008000000000000000000000000108000000800001000000000000000100000000000001000000020002000000000000000800000000000000040080000010000000000000000000000000000000200000000000000000000000000000000000000080200000000100002000080000002000000000000000000000000000002000004000100002000010000001000000001100000020000000800000108008000020000000000000000000000000100000010008000000000000000000000002100000",
    //     "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db",
    //     "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //     "logs": [
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0x0000000000000000000000000000000000001010",
    //             "topics": [
    //                 "0xe6497e3ee548a3372136af2fcb0696db31fc6cf20260707645068bd3fe97f3c4",
    //                 "0x0000000000000000000000000000000000000000000000000000000000001010",
    //                 "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d5",
    //                 "0x000000000000000000000000bf064aa32dbec74f176f7a24380c64b06ae35767"
    //             ],
    //             "data": "0x000000000000000000000000000000000000000000000000000d9a505f7fa2e000000000000000000000000000000000000000000000000003c386f53c670c9700000000000000000000000000000000000000000000000000799dce5f9df2c000000000000000000000000000000000000000000000000003b5eca4dce769b70000000000000000000000000000000000000000000000000087381ebf1d95a0",
    //             "logIndex": 2,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db"
    //         },
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0xBf064Aa32DbEC74F176F7a24380c64b06ae35767",
    //             "topics": [
    //                 "0x8d1457c1d60a6987eabbac898a50d8d7c81ba604c563663d2807027a4b079054"
    //             ],
    //             "data": "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d50000000000000000000000000000000000000000000000000000000000000026",
    //             "logIndex": 3,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db"
    //         },
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0xBf064Aa32DbEC74F176F7a24380c64b06ae35767",
    //             "topics": [
    //                 "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef",
    //                 "0x0000000000000000000000000000000000000000000000000000000000000000",
    //                 "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d5",
    //                 "0x0000000000000000000000000000000000000000000000000000000000000026"
    //             ],
    //             "data": "0x",
    //             "logIndex": 4,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db"
    //         },
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0x739E5C12DEf097ece09020902F0C3E432997d22e",
    //             "topics": [
    //                 "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef",
    //                 "0x000000000000000000000000bf064aa32dbec74f176f7a24380c64b06ae35767",
    //                 "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d5"
    //             ],
    //             "data": "0x000000000000000000000000000000000000000000000002733896c68d9a4000",
    //             "logIndex": 5,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db"
    //         },
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0xBf064Aa32DbEC74F176F7a24380c64b06ae35767",
    //             "topics": [
    //                 "0x8d1457c1d60a6987eabbac898a50d8d7c81ba604c563663d2807027a4b079054"
    //             ],
    //             "data": "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d50000000000000000000000000000000000000000000000000000000000000027",
    //             "logIndex": 6,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db"
    //         },
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0xBf064Aa32DbEC74F176F7a24380c64b06ae35767",
    //             "topics": [
    //                 "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef",
    //                 "0x0000000000000000000000000000000000000000000000000000000000000000",
    //                 "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d5",
    //                 "0x0000000000000000000000000000000000000000000000000000000000000027"
    //             ],
    //             "data": "0x",
    //             "logIndex": 7,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db"
    //         },
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0x739E5C12DEf097ece09020902F0C3E432997d22e",
    //             "topics": [
    //                 "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef",
    //                 "0x000000000000000000000000bf064aa32dbec74f176f7a24380c64b06ae35767",
    //                 "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d5"
    //             ],
    //             "data": "0x000000000000000000000000000000000000000000000002733896c68d9a4000",
    //             "logIndex": 8,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db"
    //         },
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0xBf064Aa32DbEC74F176F7a24380c64b06ae35767",
    //             "topics": [
    //                 "0x8d1457c1d60a6987eabbac898a50d8d7c81ba604c563663d2807027a4b079054"
    //             ],
    //             "data": "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d50000000000000000000000000000000000000000000000000000000000000028",
    //             "logIndex": 9,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db"
    //         },
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0xBf064Aa32DbEC74F176F7a24380c64b06ae35767",
    //             "topics": [
    //                 "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef",
    //                 "0x0000000000000000000000000000000000000000000000000000000000000000",
    //                 "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d5",
    //                 "0x0000000000000000000000000000000000000000000000000000000000000028"
    //             ],
    //             "data": "0x",
    //             "logIndex": 10,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db"
    //         },
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0x739E5C12DEf097ece09020902F0C3E432997d22e",
    //             "topics": [
    //                 "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef",
    //                 "0x000000000000000000000000bf064aa32dbec74f176f7a24380c64b06ae35767",
    //                 "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d5"
    //             ],
    //             "data": "0x000000000000000000000000000000000000000000000002733896c68d9a4000",
    //             "logIndex": 11,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db"
    //         },
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0x0000000000000000000000000000000000001010",
    //             "topics": [
    //                 "0x4dfe1bbbcf077ddc3e01291eea2d5c70c2b422b415d95645b9adcfd678cb1d63",
    //                 "0x0000000000000000000000000000000000000000000000000000000000001010",
    //                 "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d5",
    //                 "0x0000000000000000000000003a22c8bc68e98b0faf40f349dd2b2890fae01484"
    //             ],
    //             "data": "0x0000000000000000000000000000000000000000000000000043b467b83ecb9c000000000000000000000000000000000000000000000000040eb1c19bccc3ed00000000000000000000000000000000000000000000010c455e0c95b2fcabf100000000000000000000000000000000000000000000000003cafd59e38df85100000000000000000000000000000000000000000000010c45a1c0fd6b3b778d",
    //             "logIndex": 12,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db"
    //         }
    //     ],
    //     "blockNumber": 29787980,
    //     "confirmations": 4,
    //     "cumulativeGasUsed": {
    //         "type": "BigNumber",
    //         "hex": "0x08e586"
    //     },
    //     "effectiveGasPrice": {
    //         "type": "BigNumber",
    //         "hex": "0x0843f7031a"
    //     },
    //     "status": 1,
    //     "type": 2,
    //     "byzantium": true,
    //     "events": [
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0x0000000000000000000000000000000000001010",
    //             "topics": [
    //                 "0xe6497e3ee548a3372136af2fcb0696db31fc6cf20260707645068bd3fe97f3c4",
    //                 "0x0000000000000000000000000000000000000000000000000000000000001010",
    //                 "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d5",
    //                 "0x000000000000000000000000bf064aa32dbec74f176f7a24380c64b06ae35767"
    //             ],
    //             "data": "0x000000000000000000000000000000000000000000000000000d9a505f7fa2e000000000000000000000000000000000000000000000000003c386f53c670c9700000000000000000000000000000000000000000000000000799dce5f9df2c000000000000000000000000000000000000000000000000003b5eca4dce769b70000000000000000000000000000000000000000000000000087381ebf1d95a0",
    //             "logIndex": 2,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db"
    //         },
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0xBf064Aa32DbEC74F176F7a24380c64b06ae35767",
    //             "topics": [
    //                 "0x8d1457c1d60a6987eabbac898a50d8d7c81ba604c563663d2807027a4b079054"
    //             ],
    //             "data": "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d50000000000000000000000000000000000000000000000000000000000000026",
    //             "logIndex": 3,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db",
    //             "args": [
    //                 "0xbdb0831e31e178820654DdD7cC8902C22c9280d5",
    //                 {
    //                     "type": "BigNumber",
    //                     "hex": "0x26"
    //                 }
    //             ],
    //             "event": "AssetMinted",
    //             "eventSignature": "AssetMinted(address,uint256)"
    //         },
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0xBf064Aa32DbEC74F176F7a24380c64b06ae35767",
    //             "topics": [
    //                 "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef",
    //                 "0x0000000000000000000000000000000000000000000000000000000000000000",
    //                 "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d5",
    //                 "0x0000000000000000000000000000000000000000000000000000000000000026"
    //             ],
    //             "data": "0x",
    //             "logIndex": 4,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db",
    //             "args": [
    //                 "0x0000000000000000000000000000000000000000",
    //                 "0xbdb0831e31e178820654DdD7cC8902C22c9280d5",
    //                 {
    //                     "type": "BigNumber",
    //                     "hex": "0x26"
    //                 }
    //             ],
    //             "event": "Transfer",
    //             "eventSignature": "Transfer(address,address,uint256)"
    //         },
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0x739E5C12DEf097ece09020902F0C3E432997d22e",
    //             "topics": [
    //                 "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef",
    //                 "0x000000000000000000000000bf064aa32dbec74f176f7a24380c64b06ae35767",
    //                 "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d5"
    //             ],
    //             "data": "0x000000000000000000000000000000000000000000000002733896c68d9a4000",
    //             "logIndex": 5,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db"
    //         },
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0xBf064Aa32DbEC74F176F7a24380c64b06ae35767",
    //             "topics": [
    //                 "0x8d1457c1d60a6987eabbac898a50d8d7c81ba604c563663d2807027a4b079054"
    //             ],
    //             "data": "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d50000000000000000000000000000000000000000000000000000000000000027",
    //             "logIndex": 6,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db",
    //             "args": [
    //                 "0xbdb0831e31e178820654DdD7cC8902C22c9280d5",
    //                 {
    //                     "type": "BigNumber",
    //                     "hex": "0x27"
    //                 }
    //             ],
    //             "event": "AssetMinted",
    //             "eventSignature": "AssetMinted(address,uint256)"
    //         },
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0xBf064Aa32DbEC74F176F7a24380c64b06ae35767",
    //             "topics": [
    //                 "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef",
    //                 "0x0000000000000000000000000000000000000000000000000000000000000000",
    //                 "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d5",
    //                 "0x0000000000000000000000000000000000000000000000000000000000000027"
    //             ],
    //             "data": "0x",
    //             "logIndex": 7,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db",
    //             "args": [
    //                 "0x0000000000000000000000000000000000000000",
    //                 "0xbdb0831e31e178820654DdD7cC8902C22c9280d5",
    //                 {
    //                     "type": "BigNumber",
    //                     "hex": "0x27"
    //                 }
    //             ],
    //             "event": "Transfer",
    //             "eventSignature": "Transfer(address,address,uint256)"
    //         },
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0x739E5C12DEf097ece09020902F0C3E432997d22e",
    //             "topics": [
    //                 "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef",
    //                 "0x000000000000000000000000bf064aa32dbec74f176f7a24380c64b06ae35767",
    //                 "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d5"
    //             ],
    //             "data": "0x000000000000000000000000000000000000000000000002733896c68d9a4000",
    //             "logIndex": 8,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db"
    //         },
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0xBf064Aa32DbEC74F176F7a24380c64b06ae35767",
    //             "topics": [
    //                 "0x8d1457c1d60a6987eabbac898a50d8d7c81ba604c563663d2807027a4b079054"
    //             ],
    //             "data": "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d50000000000000000000000000000000000000000000000000000000000000028",
    //             "logIndex": 9,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db",
    //             "args": [
    //                 "0xbdb0831e31e178820654DdD7cC8902C22c9280d5",
    //                 {
    //                     "type": "BigNumber",
    //                     "hex": "0x28"
    //                 }
    //             ],
    //             "event": "AssetMinted",
    //             "eventSignature": "AssetMinted(address,uint256)"
    //         },
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0xBf064Aa32DbEC74F176F7a24380c64b06ae35767",
    //             "topics": [
    //                 "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef",
    //                 "0x0000000000000000000000000000000000000000000000000000000000000000",
    //                 "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d5",
    //                 "0x0000000000000000000000000000000000000000000000000000000000000028"
    //             ],
    //             "data": "0x",
    //             "logIndex": 10,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db",
    //             "args": [
    //                 "0x0000000000000000000000000000000000000000",
    //                 "0xbdb0831e31e178820654DdD7cC8902C22c9280d5",
    //                 {
    //                     "type": "BigNumber",
    //                     "hex": "0x28"
    //                 }
    //             ],
    //             "event": "Transfer",
    //             "eventSignature": "Transfer(address,address,uint256)"
    //         },
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0x739E5C12DEf097ece09020902F0C3E432997d22e",
    //             "topics": [
    //                 "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef",
    //                 "0x000000000000000000000000bf064aa32dbec74f176f7a24380c64b06ae35767",
    //                 "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d5"
    //             ],
    //             "data": "0x000000000000000000000000000000000000000000000002733896c68d9a4000",
    //             "logIndex": 11,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db"
    //         },
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0x0000000000000000000000000000000000001010",
    //             "topics": [
    //                 "0x4dfe1bbbcf077ddc3e01291eea2d5c70c2b422b415d95645b9adcfd678cb1d63",
    //                 "0x0000000000000000000000000000000000000000000000000000000000001010",
    //                 "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d5",
    //                 "0x0000000000000000000000003a22c8bc68e98b0faf40f349dd2b2890fae01484"
    //             ],
    //             "data": "0x0000000000000000000000000000000000000000000000000043b467b83ecb9c000000000000000000000000000000000000000000000000040eb1c19bccc3ed00000000000000000000000000000000000000000000010c455e0c95b2fcabf100000000000000000000000000000000000000000000000003cafd59e38df85100000000000000000000000000000000000000000000010c45a1c0fd6b3b778d",
    //             "logIndex": 12,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db"
    //         }
    //     ]
    // }
    //   abiDecoder.addABI(NFTClub.abi);
    //   const decodedLogs = abiDecoder.decodeLogs(receipt.logs);
    //   const eventLog = decodedLogs.filter((item) => item.name === "AssetMinted");
    //   if (eventLog) {
    //     const listID = eventLog.map((item) => item.events[1].value)
    //     setNFTId(listID)
    //   }
    //   setIsSuccess(true);
    //   setMinting(false);
  };

  const handleConfirmMintV2 = () => {
    setIsVisibleConfirm(true);
  };

  const handleConfirm = () => {
    mintNFTFANTOMV2();
  };

  const getPrimaryText = () => {
    // const error = getError();
    // if (error) {
    //   return error;
    // }
    if (isConfirming) {
      return `Confirming...`;
    }
    return `Confirm`;
  };

  const renderContent = () => {
    const setting1 = {
      dots: false,
      infinite: true,
      speed: 2000,
      autoplay: true,

      slidesToShow: lastMintNFT.length > 5 ? 5 : lastMintNFT.length,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 4,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 3,
          },
        },
      ],
    };
    if (minting) return <ContentLoading />;
    if (isSuccess)
      return (
        <SuccessContent
          nftId={dataMinted}
          mccPower={currentPP?._mccPower}
          esMMYBonus={estimateAmount?._totalBonus}
          setIsSuccess={setIsSuccess}
          isV2={isV2}
        />
      );

    const mintTitle = chainId === FANTOM || chainId === MUMBAI ? "Mint NFT" : "Join Mummy Club, Earn Reward Together";
    const hasNFTv1 = listMyNFT.length > 0 ? true : false;
    // console.log("????", hasNFTv1, nftList, listMy);
    const handleSelectAllModalNft = () => {
      let arrModal = [...modalNftList];
      for (let i = 0; i < arrModal.length; i++) {
        arrModal[i].selected = true;
      }
      setModaltNftList(arrModal);
    };

    const renderMintContent = () => {
      if (chainId === FANTOM || chainId === MUMBAI)
        return (
          <>
            <div className="mint-stats-ftm">
              <div>
                <div className="first-box">
                  <div className="mint-stats-label">Current Bonus</div>
                  <div className="mint-stats-amount">{formatAmount(currentPP?._esMMYBonus, 18, 2, true)} esMMY</div>
                </div>
                <div>
                  <div className="mint-stats-label">
                    Next Bonus
                    <Tooltip
                      position={`${isMobile ? "left-bottom" : "right-bottom "}`}
                      handle={<img alt="mint" src={isLightTheme ? InfoIcLight : InfoIc} />}
                      renderContent={() => {
                        return "Bonus esMMY decrease 1% for each group of 100 NFTs minted. You are not required to reserve funds to vest your bonus esMMY ONLY if you have never staked it before.";
                      }}
                    ></Tooltip>
                  </div>
                  <div className="mint-stats-amount">{formatAmount(nextBonus, 18, 2, true)} esMMY</div>
                </div>
              </div>
              <div>
                <div className="first-box">
                  <div className="mint-stats-label">Current Price</div>
                  <div className="mint-stats-amount">
                    {" "}
                    <img alt="img" src={TXT[chainId]?.nativeIcon} /> {formatAmount(currentPP?._mccPrice, 18, 2, true)}{" "}
                    {TXT[chainId]?.nativeToken}
                  </div>
                </div>{" "}
                <div>
                  <div className="mint-stats-label">
                    Next Price
                    <Tooltip
                      position={`${isMobile ? "left-bottom " : "right-bottom"} `}
                      handle={<img alt="mint" src={isLightTheme ? InfoIcLight : InfoIc} />}
                      renderContent={() => {
                        return "Prices increase by 1% for each group of 100 NFTs minted";
                      }}
                    ></Tooltip>
                  </div>
                  <div className="mint-stats-amount">
                    {" "}
                    <img alt="img" src={TXT[chainId]?.nativeIcon} />
                    {formatAmount(nextPrice, 18, 2, true)} {TXT[chainId]?.nativeToken}
                  </div>
                </div>
              </div>
              <div>
                <div div className="first-box">
                  <div className="mint-stats-label">Current Power </div>
                  <div className="mint-stats-amount">{formatAmount(currentPP?._mccPower, 0, 0, true)}</div>
                </div>
                <div>
                  <div className="mint-stats-label">
                    Next Power
                    <Tooltip
                      position={`${isMobile ? "left-bottom" : "right-bottom "} `}
                      handle={<img alt="mint" src={isLightTheme ? InfoIcLight : InfoIc} />}
                      renderContent={() => {
                        return "Power decreases by 1% for each group of 100 NFTs minted";
                      }}
                    ></Tooltip>
                  </div>
                  <div className="mint-stats-amount">
                    {nextPower ? commify(Number(nextPower.toString().split(".")[0])) : "--"}
                  </div>
                </div>
              </div>
            </div>

            <div className="balance-box-ftm">
              <div>
                <div className="balance-box-head">
                  {" "}
                  <div className="your-balance number-nft"> Number of NFT</div>
                  <div className="your-balance">
                    Avail:{" "}
                    <span>
                      {formatAmount(ftmBalance, 18, 2, true)} {nativeTokenSymbol}
                    </span>
                  </div>
                </div>

                <Menu>
                  <Menu.Button as="div">
                    <button className=" PositionDropdown-dots-icon btn-dropdown">
                      <>
                        {" "}
                        <div className="content-dropdown">
                          {selectedNum.num} &nbsp;
                          <span>
                            (
                            {formatAmount(
                              chainId === FANTOM ? estimateAmountV2?._totalPrice : estimateAmount?._totalPrice,
                              18,
                              2,
                              true
                            )}{" "}
                            {TXT[chainId]?.nativeToken})
                          </span>
                        </div>
                        <img src={DropdownIcon} className="icon-down" alt="icon" />
                      </>
                    </button>
                  </Menu.Button>
                  <div className="PositionDropdown-extra-options">
                    <Menu.Items as="div" className="menu-items">
                      {listOption.map((item, id) => {
                        return (
                          <Menu.Item onClick={() => handleClick(item)}>
                            <div className="menu-item">
                              <p>{item.num} </p>
                            </div>
                          </Menu.Item>
                        );
                      })}
                    </Menu.Items>
                  </div>
                </Menu>
              </div>

              {hasNFTv1 && (
                <BurnBox>
                  <div className="burn-title">
                    Select NFT v1 to burn{" "}
                    <Tooltip
                      position={`${isMobile ? "left-bottom" : "right-bottom "} `}
                      handle={<img alt="mint" src={isLightTheme ? InfoIcLight : InfoIc} />}
                      renderContent={() => {
                        return "Get a multiDAI refund based on the total power of the NFTs you burn.";
                      }}
                    ></Tooltip>
                  </div>
                  {StakedNFTs.length > 0 && (
                    <div className="burn-desc">
                      You staked <span className="txt-hightlight">&nbsp; {StakedNFTs.length} NFT &nbsp; </span> in Mummy
                      Club v1, unstake there first &nbsp;
                      <a className="txt-hightlight  " href="https://legacy.mummy.finance/#/nft" target="_self">
                        Unstake
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M10.2221 1.33398C9.87167 1.33398 9.58722 1.61843 9.58722 1.96891C9.58722 2.31938 9.87167 2.60383 10.2221 2.60383H12.4989L7.23355 7.8692C6.98561 8.11714 6.98561 8.51908 7.23355 8.76702C7.35768 8.89115 7.51992 8.95303 7.68246 8.95303C7.845 8.95303 8.00724 8.89115 8.13137 8.76702L13.3967 3.50164V5.77843C13.3967 6.12891 13.6812 6.41335 14.0317 6.41335C14.3821 6.41335 14.6666 6.12891 14.6666 5.77843V1.96891C14.6666 1.61843 14.3821 1.33398 14.0317 1.33398H10.2221ZM3.23801 1.96891C2.18754 1.96891 1.33325 2.82319 1.33325 3.87367V12.7626C1.33325 13.813 2.18754 14.6673 3.23801 14.6673H12.1269C13.1774 14.6673 14.0317 13.813 14.0317 12.7626V8.95303C14.0317 8.60256 13.7472 8.31811 13.3967 8.31811C13.0463 8.31811 12.7618 8.60256 12.7618 8.95303V12.7626C12.7618 13.1127 12.4771 13.3975 12.1269 13.3975H3.23801C2.88786 13.3975 2.60309 13.1127 2.60309 12.7626V3.87367C2.60309 3.52351 2.88786 3.23875 3.23801 3.23875H7.04754C7.39801 3.23875 7.68246 2.9543 7.68246 2.60383C7.68246 2.25335 7.39801 1.96891 7.04754 1.96891H3.23801Z"
                            fill="#03F5AE"
                          />
                        </svg>
                      </a>
                    </div>
                  )}

                  <div className="nft-selected-container">
                    {selectedNft.map((item) => (
                      <SelectedNftItem item={item} onRemove={handleRemoveNft} />
                    ))}
                    {nftList.length > 0 && (
                      <div className="add-nft-item" onClick={() => setIsVisibleNFT(true)}>
                        <img alt="" src={icPlus} />
                      </div>
                    )}
                  </div>
                  {nftList.length > 0 && (
                    <div className="burn-est">
                      <div>
                        <span className="txt-muted">NFT will be burned: </span>
                        <span className="txt-hightlight">{selectedNft.length}</span>
                      </div>
                      <div>
                        <span className="txt-muted">Est. multiDAI to receive: </span>

                        <span className="txt-hightlight">
                          {estimateDAIAmount ? formatAmount(estimateDAIAmount, 18, 4, true) : "--"}
                        </span>
                      </div>
                    </div>
                  )}
                </BurnBox>
              )}
              <div style={{ marginTop: "24px" }}>
                {!active ? (
                  <button className="btn-primary" onClick={() => connectWallet()}>
                    Connect Wallet
                  </button>
                ) : estimateAmount &&
                  estimateAmount?._totalPrice &&
                  estimateAmount._totalPrice.lt(ftmBalance) &&
                  activeSaleV2 &&
                  Number(totalSupply) <= NFT_SUPPLY ? (
                  <button
                    className="btn-primary"
                    disabled={isDisabledMint()}
                    onClick={() => {
                      chainId === OP ? mintNFT() : handleConfirmMintV2();
                    }}
                  >
                    {" "}
                    <img alt="mint " src={isLightTheme ? MintIcLight : MintIc} /> Mint NFT
                  </button>
                ) : (
                  <button className="btn-primary disable" disabled>
                    {" "}
                    {!activeSaleV2
                      ? "Sale is not active"
                      : Number(totalSupply) <= NFT_SUPPLY
                      ? `Insufficient ${TXT[chainId]?.nativeToken} balance`
                      : "Sold out"}
                  </button>
                )}
                {isDisabledMint() && selectedNft.length > 0 && (
                  <div className="red-warning">Number of NFTs burned and minted must be equal.</div>
                )}
              </div>
            </div>
          </>
        );

      return (
        <>
          <div className="balance-box">
            <div>
              <div className="your-balance number-nft"> Number of NFT</div>
              <Menu>
                <Menu.Button as="div">
                  <button className=" PositionDropdown-dots-icon btn-dropdown">
                    <>
                      {" "}
                      <div className="content-dropdown">
                        {selectedNum.num} &nbsp;
                        <span>
                          ({formatAmount(estimateAmount?._totalPrice, 18, 2, true)} {TXT[chainId]?.nativeToken})
                        </span>
                      </div>
                      <img src={DropdownIcon} className="icon-down" alt="icon" />
                    </>
                  </button>
                </Menu.Button>
                <div className="PositionDropdown-extra-options">
                  <Menu.Items as="div" className="menu-items">
                    {listOption.map((item, id) => {
                      return (
                        <Menu.Item onClick={() => handleClick(item)}>
                          <div className="menu-item">
                            <p>{item.num} </p>
                          </div>
                        </Menu.Item>
                      );
                    })}
                  </Menu.Items>
                </div>
              </Menu>
            </div>
            <div>
              <div className="your-balance">
                Avail:{" "}
                <span>
                  {formatAmount(ftmBalance, 18, 2, true)} {TXT[chainId]?.nativeToken}
                </span>
              </div>
              {!active ? (
                <button className="btn-primary" onClick={() => connectWallet()}>
                  Connect Wallet
                </button>
              ) : estimateAmount &&
                estimateAmount?._totalPrice &&
                estimateAmount._totalPrice.lt(ftmBalance) &&
                Number(totalSupply) <= NFT_SUPPLY ? (
                <button className="btn-primary" disabled={Date.now() <= 1671462000000} onClick={mintNFT}>
                  {" "}
                  <img alt="mint " src={isLightTheme ? MintIcLight : MintIc} /> Mint NFT
                </button>
              ) : (
                <button className="btn-primary disable" disabled>
                  {Number(totalSupply) <= NFT_SUPPLY ? `Insufficient ${TXT[chainId]?.nativeToken} balance` : "Sold out"}
                </button>
              )}
            </div>
          </div>

          <div className="mint-stats">
            <div>
              <div div className="first-box">
                <div className="mint-stats-label">Current Power </div>
                <div className="mint-stats-amount">{formatAmount(currentPP?._mccPower, 0, 0, true)}</div>
              </div>
              <div>
                <div className="mint-stats-label">
                  Next Power
                  <Tooltip
                    position={`${isMobile ? "left-bottom" : "right-bottom "} `}
                    handle={<img alt="mint" src={isLightTheme ? InfoIcLight : InfoIc} />}
                    renderContent={() => {
                      return "Power decreases by 1% for each group of 100 NFTs minted";
                    }}
                  ></Tooltip>
                </div>
                <div className="mint-stats-amount">
                  {nextPower ? commify(Number(nextPower.toString().split(".")[0])) : "--"}
                </div>
              </div>
            </div>
            <div>
              <div className="first-box">
                <div className="mint-stats-label">Price</div>
                <div className="mint-stats-amount">
                  {" "}
                  <img alt="img" src={TXT[chainId]?.nativeIcon} /> {formatAmount(currentPP?._mccPrice, 18, 2, true)}{" "}
                  {TXT[chainId]?.nativeToken}
                </div>
              </div>{" "}
              <div>
                <div className="mint-stats-label">
                  Bonus
                  <Tooltip
                    position={`${isMobile ? "left-bottom" : "right-bottom "}`}
                    handle={<img alt="mint" src={isLightTheme ? InfoIcLight : InfoIc} />}
                    renderContent={() => {
                      return "You can stake or vest your bonus esMMY on the Earn tab. You are not required to reserve funds to vest your bonus esMMY ONLY if you have never staked it before.";
                    }}
                  ></Tooltip>
                </div>
                <div className="mint-stats-amount">{formatAmount(currentPP?._esMMYBonus, 18, 2, true) + " esMMY"}</div>
              </div>
              {/* <div>
                  <div className="mint-stats-label">
                    Next Price
                    <Tooltip
                      position={`${isMobile ? "left-bottom " : "right-bottom"} `}
                      handle={<img alt="mint" src={isLightTheme ? InfoIcLight: InfoIc} />}
                      renderContent={() => {
                        return "Price increases by 1% for each group of 100 NFTs minted";
                      }}
                    ></Tooltip>
                  </div>
                  <div className="mint-stats-amount">
                    {" "}
                    <img alt="img" src={TXT[chainId]?.nativeIcon} /> {formatAmount(nextPrice, 18, 2, true)}{" "}
                    {TXT[chainId]?.nativeToken}
                  </div>
                </div> */}
            </div>
            {/* <div>
                <div className="first-box">
                  <div className="mint-stats-label">Current Power</div>
                  <div className="mint-stats-amount">{formatAmount(currentPP?._mccPower, 0, 0, true)} </div>
                </div>
                <div>
                  <div className="mint-stats-label">
                    Next Power
                    <Tooltip
                      position={`${isMobile ? "left-bottom" : "right-bottom "}`}
                      handle={<img alt="mint" src={isLightTheme ? InfoIcLight: InfoIc} />}
                      renderContent={() => {
                        return "Power decreases by 1% for each group of 100 NFTs minted";
                      }}
                    ></Tooltip>
                  </div>
                  <div className="mint-stats-amount">
                    {commify(nextPower.toFixed(0))}
                  </div>
                </div>
              </div> */}
          </div>
        </>
      );
    };

    return (
      <ModalWrap>
        <NFT>
          <TitleMobile>
            <div className={`mint-title ${chainId !== FANTOM || chainId !== MUMBAI ? "mint-title-ftm" : ""} `}>
              {mintTitle}
            </div>
            {chainId !== FANTOM && chainId !== MUMBAI && (
              <div className="mint-desc">
                The earlier you mint, the higher the power to earn rewards.{" "}
                <a href={getDocsClub(chainId)} target={"blank"}>
                  Learn More <img src={linkWhite} alt="img" />{" "}
                </a>
              </div>
            )}
          </TitleMobile>
          <img alt="mint" className="mum1" src={TXT[chainId]?.logo1} />{" "}
          <div className="info-wrap">
            <img alt="mint" className="nft-mobile" src={TXT[chainId]?.logo1} />{" "}
            <div className="info-container">
              <div>
                <div className="label">Total NFT Minted</div>
                <div className="amount">{totalSupply ? formatAmount(totalSupply, 0, 0, true) : "0"}</div>
              </div>
              <div>
                <div className="label">Total NFT Supply</div>
                <div className="amount">
                  10,000
                  {/* {formatAmount(totalPower, 0, 0, true)} */}
                </div>
              </div>
              <div>
                <div className="label">Total Volume</div>
                <div className="amount">
                  <img alt="img" src={TXT[chainId]?.nativeIcon} /> {formatAmount(totalVolume, 18, 2, true)}
                </div>
              </div>{" "}
            </div>
          </div>
        </NFT>
        <MintInfo>
          <MintInfoContent>
            <div className={`mint-title ${chainId !== FANTOM || chainId !== MUMBAI ? "mint-title-ftm" : ""} `}>
              {mintTitle}
            </div>
            {chainId !== FANTOM && chainId !== MUMBAI && (
              <div className="mint-desc">
                The earlier you mint, the higher the power to earn rewards.{" "}
                <a href={getDocsClub(chainId)} target="_blank" rel="noreferrer">
                  Learn More <img src={linkWhite} alt="img" />{" "}
                </a>
              </div>
            )}

            {renderMintContent()}
          </MintInfoContent>
          {chainId !== FANTOM && chainId !== MUMBAI && (
            <div className="last-mint">
              <div className="last-mint-label">Latest NFT Minted</div>
              {lastMintNFT.length > 0 ? (
                <div className="last-mint-images1">
                  <Slider {...setting1}>
                    {lastMintNFT.map((item, id) => {
                      return (
                        <div key={id} className="nft-mint-item">
                          <img
                            style={{ width: "80px", height: "80px" }}
                            src={`${getLinkCDN(chainId)}${item}.png`}
                            alt="nft"
                          />
                        </div>
                      );
                    })}
                  </Slider>
                </div>
              ) : (
                <EmptyWrapper>No NFT has been minted yet.</EmptyWrapper>
              )}
            </div>
          )}
        </MintInfo>
      </ModalWrap>
    );
  };
  return (
    <div className="modals-container">
      <ConfirmModal
        className="modal-confirm-mint"
        handleConfirm={handleConfirm}
        isVisible={isVisibleConfirm}
        setIsVisible={setIsVisibleConfirm}
        text={getPrimaryText()}
      />
      <Modal className="modal-mint" isVisible={isVisible} setIsVisible={minting ? () => {} : setIsVisible}>
        {renderContent()}
      </Modal>
      <ModalWrapper className="StakeModal">
        <Modal isVisible={isVisibleNFT} setIsVisible={setIsVisibleNFT} label="Select NFT">
          {modalNftList.length > 0 && (
            <div>
              <div className="nft-select-container">
                <div className="selected-info">
                  <div className="available">
                    Available: <span>{modalNftList.length}</span>
                  </div>
                </div>
                <div className="info">
                  <div className="selected">
                    Selected: <span>{selectedModalNft.length}</span>
                  </div>
                  <div className="select-btn" onClick={() => handleSelectAllModalNft()}>
                    Select All
                  </div>
                  <div className="select-btn" onClick={() => handleDeselectAllModalNft()}>
                    Deselect All
                  </div>
                </div>
              </div>
              <div className="nft-list">
                {modalNftList.map((item) => (
                  <div
                    className={`list-item ${item.selected ? "list-item-selected" : ""}`}
                    key={item.id}
                    onClick={() => handleSelectModalNft(item)}
                  >
                    <div className="checkbox">
                      <img alt="" src={item?.selected ? icCheckboxChecked : icCheckbox} />
                    </div>
                    <img alt="" src={`${getLinkCDN(chainId)}${item.id}.png`} className="main-nft-modal" />
                    <div className="nft-info">
                      <div className="title">Mummy #{item.id}</div>
                      <div className="info">
                        <img alt="" src={icBolt} />
                        <span>{formatNumber(item.power)}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          {modalNftList.length === 0 && (
            <div className="empty-state">
              <img alt="" src={icEmpty} className="empty-img" />
              <div>You don't have any Mummy NFT.</div>
              {/* <Link className="get-your-nft" to="/nft">
                Get your Mummy NFT
                <img alt="" src={icScan} className="scan-img" />
              </Link> */}
            </div>
          )}
          <div className="actions">
            {!active ? (
              <button className="App-button-option App-card-option" onClick={() => connectWallet()}>
                Connect Wallet
              </button>
            ) : chainId !== FANTOM && chainId !== MUMBAI ? (
              <button className="App-button-option App-card-option" onClick={() => switchNetwork(MUMBAI, active)}>
                Switch to Fantom
              </button>
            ) : !isApprovedNFT ? (
              <button className="App-button-option App-card-option" onClick={() => onApproveNFT()}>
                Approve
              </button>
            ) : (
              <button
                className="App-button-option App-card-option"
                onClick={() => handleContinueNftModal()}
                disabled={selectedModalNft.length === 0 && selectedNft.length === 0}
              >
                {selectedModalNft.length === 0 && selectedNft.length === 0 ? "Select NFT" : "Continue"}
              </button>
            )}
          </div>
        </Modal>
      </ModalWrapper>
    </div>
  );
}

function SuccessContent(props) {
  const { nftId, mccPower, esMMYBonus, setPendingTxns, setIsSuccess, isV2 } = props;
  const { chainId } = useChainId();
  const setting = {
    dots: true,
    infinite: true,
    speed: 1000,

    autoplay: false,

    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  let bonus = bigNumberify(0);
  nftId.forEach((element) => {
    bonus = bonus.add(parseValue(element.bonus, 0));
  });
  return (
    <SuccessWrap>
      <div className="success-title">Congratulation!</div>
      <div className="success-content">
        {" "}
        <div className="success-desc">
          You have just received <span>{nftId.length}</span> NFT & <span>{formatAmount(bonus, 18, 2, true)}</span> esMMY
          bonus in your wallet
        </div>
        <div className="slider-wrapper">
          <Slider {...setting}>
            {nftId.map((item, id) => {
              return (
                <div className="item-nft" key={id}>
                  <img src={`${getLinkCDN(chainId, isV2)}${item.nftID}.png`} alt="img" />
                  <div className="nft-name">Mummy #{item.nftID}</div>
                  <div className="nft-info">
                    <div className="nft-power nft-info-power ">
                      Power: &nbsp; <span>{formatAmount(item.power, 0, 0, true)}</span>
                    </div>{" "}
                    <div className="nft-power">
                      Bonus: <span>{formatAmount(item.bonus, 18, 2, true)} esMMY</span>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
        <div className="nft-action">
          <button className="btn-primary" onClick={() => setIsSuccess(false)}>
            Mint more NFT
          </button>
          <a className="btn-outline" href="#/stake-nft">
            Go to stake NFT
          </a>
        </div>
      </div>
    </SuccessWrap>
  );
}
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <img src={icPrev} alt="img" className="ic-next" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      {" "}
      <img src={icPrev} alt="img" />
    </div>
  );
}

function ContentLoading() {
  const [widthPercent, setWidthPercent] = useState(0);

  useEffect(() => {
    let width = 0;
    let timmer;

    timmer = setInterval(() => {
      if (width < 90) {
        width += 1;
        setWidthPercent(width);
      }
    }, 500);

    return () => {
      clearInterval(timmer);
    };
  }, []);
  return (
    <LoadingWrap>
      <div className="loading-title">Minting</div>
      <div className="loading-wrap">
        <div className="loading-bar" style={{ width: `${widthPercent}%` }}></div>
      </div>
      <div className="loading-percent">{widthPercent}%</div>
    </LoadingWrap>
  );
}

const BurnBox = styled.div`
  margin-top: 24px;

  .add-nft-item {
    width: 64px;
    cursor: pointer;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.1);
    border: 2px dashed rgba(255, 255, 255, 0.1);
  }
  .burn-title {
    font-size: 14px;
    margin-bottom: 8px;
    font-weight: 700;
    line-height: 140%;
    position: relative;
    .Tooltip {
      display: inline-block;
      position: relative;
      top: 3px;
      font-weight: 400;
    }
  }
  .txt-hightlight {
    color: #03f5ae;
  }
  .burn-desc {
    display: flex;
    align-items: center;
    a {
      text-decoration: none;
      display: inline-flex;
      align-items: center;
      svg {
        margin-left: 4px;
      }
    }
    margin-bottom: 12px;
    @media screen and (max-width: 991px) {
      display: block;
    }
  }
  .burn-est {
    margin: 12px 0 0 0;
    display: flex;
    div:first-child {
      margin-right: 24px;
    }

    @media screen and (max-width: 700px) {
      flex-direction: column;
    }
  }
`;

const LoadingWrap = styled.div`
  text-align: center;
  .loading-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;
    color: #03f5ae;
  }
  .loading-wrap {
    margin: 24px 0;
    width: 375px;
    max-width: 100%;
    height: 6px;
    background: #222123;
    .loading-bar {
      height: 100%;
      background: linear-gradient(90deg, #03f5ae 0%, #e3fff7 100%);
    }
  }
  .loading-percent {
    font-size: 74px;
    line-height: 100%;
  }
`;

const ModalWrapper = styled.div`
  .nft-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 12px;
    gap: 12px;
    max-height: 240px;
    overflow-y: auto;
    padding-right: 10px;

    /* &::-webkit-scrollbar {
      width: 8px !important;
      background: #191b2e !important;
      border-radius: 100px !important;
    }

    &::-webkit-scrollbar-track {
      width: 8px !important;
      background: #191b2e !important;
      border-radius: 100px !important;
    }

    &::-webkit-scrollbar-thumb {
      width: 8px !important;
      background: #4d527c;
      border-radius: 100px !important;
    } */

    @media screen and (max-width: 700px) {
      padding-right: 8px;
    }

    .list-item-selected {
      border: 1px solid #03f5ae;
    }

    .list-item {
      width: calc(50% - 6px);
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 12px;
      background: #191b2e;
      border-radius: 12px;
      cursor: pointer;

      @media screen and (max-width: 700px) {
        width: 100% !important;
      }

      .nft-info {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .title {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%;
        }

        .info {
          opacity: 0.6;

          span {
            font-size: 12px;
            line-height: 140%;
          }
        }
      }
    }
  }

  .actions {
    display: flex;
    justify-content: center;
  }

  .empty-state {
    width: 100%;
    display: flex;
    height: 272px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    border-radius: 16px;
    background: #191b2e;

    .empty-img {
      width: 160px;
      height: 160px;
      filter: grayscale(1);
    }

    .get-your-nft {
      text-decoration: none;
      color: #03f5ae;

      img {
        margin-left: 4px;
      }
    }
  }
`;
const SuccessWrap = styled.div`
  .slider-wrapper {
    img {
      border-radius: 16px;

      width: 200px;
      height: 200px;
      margin-left: auto;
      margin-right: auto;
    }
    .item-nft {
    }
    .slick-next {
      right: -12px;
    }
    .slick-prev {
      left: -12px;
      z-index: 3 !important;
    }
    .slick-arrow {
      background: rgba(255, 255, 255, 0.1);
      border-radius: 8px;
      width: 32px;
      height: 32px;
      display: flex;

      img {
        width: 24px;
        height: 24px;
        margin-top: 4px;
      }
      .ic-next {
        transform: rotate(180deg);
      }
      &:before {
        display: none;
      }
    }
    .slick-dots li button:before {
      color: #fff;
      opacity: 0.6;
    }
    .slick-dots li.slick-active button:before {
      opacity: 1;
    }
    margin-bottom: 28px;
  }
  .success-title {
    font-weight: 700;
    font-size: 14px;
    line-height: 140%;
    margin: 0px 9px;
  }
  .nft-avt {
    width: 200px;
    height: 200px;
    margin: 16px auto;
    display: block;
  }
  .success-content {
    margin: 0px 9px;
    width: 272px;
    font-weight: 400;

    line-height: 140%;
    text-align: center;

    .success-desc {
      color: rgba(255, 255, 255, 0.6);
      font-size: 12px;
      margin: 16px 0;
      span {
        color: #fff;
      }
    }
    .nft-name {
      font-weight: 700;
      margin-top: 8px;
    }
    .nft-info {
      display: flex;
      align-items: center;
      justify-content: center;

      margin: 4px 0 12px 0;
      height: 14px;
    }
    .nft-info-power {
      margin-right: 4px;
      &::after {
        content: "";
        width: 1px;
        height: 14px;
        background: #fff;
        margin-left: 4px;
      }

      display: flex;
      align-items: center;
    }
    .nft-power {
      span {
        color: #03f5ae;
      }
    }
    .nft-action {
      a,
      button {
        text-decoration: none;
        height: 36px;
        width: 100%;
        &:first-child {
          margin-bottom: 12px;
        }
      }
    }
  }
`;
const ModalWrap = styled.div`
  .Tooltip-popup {
    @media screen and (max-width: 700px) {
      width: 260px !important;
      left: -55px !important;
    }
  }
  display: grid;
  grid-template-columns: 300px auto;
  /* overflow: hidden; */
  gap: 24px;
  @media screen and (max-width: 767px) {
    grid-template-columns: 100%;
    gap: 2px;
  }
  .mint-title {
    font-weight: 700;
    font-size: 24px;

    margin-bottom: 8px;
    @media screen and (max-width: 991px) {
      font-size: 16px;
    }
    @media screen and (max-width: 700px) {
      font-size: 14px;
    }
  }
  .mint-title-ftm {
    margin: 24px 0;

    @media screen and (max-width: 700px) {
      margin: 16px 0;
    }
  }
  .mint-desc {
    font-weight: 400;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.6);
    @media screen and (max-width: 700px) {
      font-size: 12px;
    }
    a {
      display: inline-flex;
      align-items: center;
      font-weight: 500;
      text-decoration: none;
      img {
        margin-left: 4px;
      }
    }
  }
`;

const NFT = styled.div`
  .mum1 {
    height: 300px;
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  .info-wrap {
    .nft-mobile {
      display: none;
    }
    @media screen and (max-width: 767px) {
      padding: 0;
      display: flex;
      align-items: center;
      margin-top: 16px;
      .nft-mobile {
        display: block;
        height: 80px;
      }
      .info-container {
        flex: 1;
        padding: 6px 16px 6px 0;
        > div {
          font-size: 12px !important;
          font-weight: 400;
        }

        .amount {
          font-weight: 400 !important;
          min-width: 100px;
          /* text-align: right; */
          justify-content: flex-end;
          img {
            width: 16px;
          }
        }
      }
    }
    position: relative;
    top: -3px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 12px;
    padding: 16px;
    .info-container > div {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      display: flex;
      justify-content: space-between;
      &:not(:last-child) {
        margin-bottom: 8px;
      }
      .amount {
        font-weight: 700;
        display: flex;
        align-items: center;
        img {
          margin-right: 4px;
        }
      }
    }
  }
`;
const MintInfoContent = styled.div`
  min-height: 300px;
  margin-top: 24px;

  @media screen and (max-width: 767px) {
    height: auto;
    .mint-title,
    .mint-desc {
      display: none;
    }
  }
`;
const TitleMobile = styled.div`
  display: none;
  @media screen and (max-width: 767px) {
    display: block;
  }
`;

const MintInfo = styled.div`
  .your-balance {
    margin-top: 14px;
    margin-bottom: 4px;
    text-align: right;
    font-weight: 400;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.7);
    span {
      color: #fff;
    }
    &.number-nft {
      text-align: left;
    }
  }
  .btn-primary {
    width: 100%;
    height: 48px;
    margin-bottom: 12px;
    &:hover {
      background: #04d397;
      color: #080715 !important;
    }
  }

  .mint-stats-ftm {
    margin-bottom: 24px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 12px 16px;
    font-weight: 400;

    .first-box {
      margin-bottom: 16px;
    }

    line-height: 140%;
    .mint-stats-label {
      font-size: 12px;
      display: flex;
      color: rgba(255, 255, 255, 0.7);
      .Tooltip {
        margin-left: 4px;
        position: relative;
      }
    }
    .mint-stats-amount {
      font-size: 14px;
      display: flex;
      align-items: center;
      img {
        width: 16px;
        margin-right: 4px;
      }
    }
    @media screen and (max-width: 767px) {
      > div {
        &:not(:last-child) {
          padding-bottom: 8px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        }
      }
      margin-bottom: 16px;
      gap: 8px;
      grid-template-columns: 100%;
      div {
        > div {
          display: flex;
          justify-content: space-between;
        }
      }
      .first-box {
        margin-bottom: 8px;
      }
    }
  }
  .mint-stats {
    margin-bottom: 36px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px 16px;
    font-weight: 400;

    .first-box {
      margin-bottom: 16px;
    }

    line-height: 140%;
    .mint-stats-label {
      font-size: 12px;
      display: flex;
      .Tooltip {
        margin-left: 4px;
        position: relative;
        top: 2px;
      }
    }
    .mint-stats-amount {
      font-size: 14px;
      display: flex;
      align-items: center;
      img {
        width: 16px;
        margin-right: 4px;
      }
    }
    @media screen and (max-width: 767px) {
      > div {
        &:not(:last-child) {
          padding-bottom: 8px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        }
      }
      margin-bottom: 16px;
      gap: 8px;
      grid-template-columns: 100%;
      div {
        > div {
          display: flex;
          justify-content: space-between;
        }
      }
      .first-box {
        margin-bottom: 8px;
      }
    }
  }
  .last-mint {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 12px;
    height: 112px;
    position: relative;

    .last-mint-label {
      position: absolute;
      top: -14px;
      left: 16px;
      font-weight: 400;
      font-size: 12px;
      line-height: 140%;
      color: #ffffff;
      border: 1px solid rgba(255, 255, 255, 0.1);
      border-radius: 8px;
      background: #262324;
      padding: 4px 8px;
      width: fit-content;
    }

    .last-mint-images1 {
      padding-top: 16px;
      margin-left: auto;
      margin-right: auto;
      width: 484px;
      @media screen and (max-width: 990px) {
        width: 360px;
      }
      @media screen and (max-width: 700px) {
        width: 280px;
      }
      img {
        border-radius: 12px;
      }
      mask-image: linear-gradient(270deg, rgba(217, 217, 217, 0.2) 0%, #d9d9d9 49.48%, rgba(217, 217, 217, 0.2) 100%);
    }
  }
  .balance-box {
    display: grid;
    grid-template-columns: 160px auto;
    gap: 12px;
  }

  .balance-box-ftm {
    .balance-box-head {
      display: flex;
      justify-content: space-between;
    }
    .btn-dropdown {
      width: 500px;
      @media screen and (max-width: 991px) {
        width: 100%;
      }
    }
  }
`;
const EmptyWrapper = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: rgba(255, 255, 255, 0.6);
`;
