import { useEffect } from "react";
import styled from "styled-components";
import cx from "classnames";
import { Select } from "antd";

import { useThemeContext } from "contexts/ThemeProvider";

import downIc from "img/downArrow.svg";
import leftIc from "img/ic_arrowleft16.svg";
import rightIc from "img/ic_arrowright16.svg";
import Button from "components/Common/Button";

const ROWS_PER_PAGE_OPTIONS = [
  {
    value: 10,
    label: 10,
  },
  {
    value: 20,
    label: 20,
  },
  {
    value: 50,
    label: 50,
  },
  {
    value: 100,
    label: 100,
  },
];

const Pagination = ({ currentPage, pageSize, totalRows, onNext, onPrev, onChangePageSize, className = "" }) => {
  const { lightThemeClassName } = useThemeContext();

  return (
    <Wrapper className={cx(lightThemeClassName, className)}>
      <div className="rowsPerPage">
        <span style={{ marginRight: "8px" }}>Rows per page:</span>
        <Select
          showArrow
          suffixIcon={<img className="downIc" src={downIc} alt="" />}
          onChange={onChangePageSize}
          defaultValue={pageSize}
          style={{
            width: "fit-content",
          }}
          bordered={true}
          options={ROWS_PER_PAGE_OPTIONS}
          className={`hasBorder ${lightThemeClassName}`}
          popupClassName={lightThemeClassName}
        />
      </div>
      <div className="movePage">
        <StyledButton isDisabled={currentPage <= 1} onClick={onPrev}>
          <img src={leftIc} alt="" />
        </StyledButton>
        <span>
          {currentPage * pageSize - (pageSize - 1)}-
          {currentPage * pageSize > totalRows ? totalRows : currentPage * pageSize} of page {currentPage}
        </span>
        <StyledButton isDisabled={currentPage * pageSize >= totalRows} onClick={onNext}>
          <img src={rightIc} alt="" />
        </StyledButton>
      </div>
    </Wrapper>
  );
};

const StyledButton = styled(Button)`
  width: 30px;
  height: 30px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .btn-label {
    margin: 0;
    padding-bottom: 5px;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: flex-start;
  margin-top: 20px;

  .rowsPerPage {
    select.decorated option:hover {
      box-shadow: 0 0 10px 100px #1882a8 inset;
      background: red;
    }
  }

  select {
    padding: 5px 10px;
    outline: none;
    background: transparent;
    border: 1px solid #ffffff1a;
    color: white;

    > * {
      color: black;
    }
  }

  option {
    background: #110e36;
    color: white;
  }

  .movePage {
    display: flex;
    align-items: center;
    span {
      margin: 0 8px;
    }
  }

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }
`;
export default Pagination;
