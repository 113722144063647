import { ethers } from "ethers";
import { useState, useEffect } from "react";

import { getContract } from "config/contracts";
import { PLACEHOLDER_ACCOUNT, NLP_DECIMALS } from "lib/legacy";

import Multicall from "abis/Multicall.json";
import Token from "abis/Token.json";
import { getProvider } from "lib/rpc";
import { formatMulticallReponse } from "lib/contracts";
import multicall from "domain/multicall";

export default function useMslpContract(
  chainId: number,
  active: boolean,
  account: string | null | undefined,
  library?: any
) {
  const nlpAddress = getContract(chainId, "MSLP");

  const [data, setData] = useState({});
  const multicallAddress = getContract(chainId, "Multicall");

  useEffect(() => {
    const fetch = async () => {
      try {
        const provider = getProvider(undefined, chainId);
        if (!provider) return;
        const multicallContract = new ethers.Contract(multicallAddress, Multicall.abi, provider);
        const calls = [
          {
            address: nlpAddress,
            name: "decimals",
            params: [],
            key: "decimals",
          },
          {
            address: nlpAddress,
            name: "symbol",
            params: [],
          },
          {
            address: nlpAddress,
            name: "totalSupply",
            params: [],
          },
          {
            address: nlpAddress,
            name: "balanceOf",
            params: [account || PLACEHOLDER_ACCOUNT],
          },
        ];

        const keyOfCalls = calls.map((call) => call.key || call.name);
        const response = await multicall(
          multicallContract,
          Token.abi,
          calls.map(({ address, name, params }) => ({
            address,
            name,
            params,
          }))
        );
        const result = {};
        for (const index in keyOfCalls) {
          result[keyOfCalls[index]] = response[index];
        }

        setData(formatMulticallReponse(result));
      } catch (error) {
        console.error("[ERROR]: useMslpContracts", error);
      }
    };

    fetch();
    const timerId = setInterval(fetch, 30000);

    return () => clearInterval(timerId);
  }, [nlpAddress, account, chainId, library, multicallAddress]);

  return {
    totalSupply: data["totalSupply"],
    balanceOf: data["balanceOf"],
    maxSupply: data["maxSupply"],
    decimals: data["decimals"] || NLP_DECIMALS,
    symbol: data["symbol"] || "MSLP",
  };
}
