import { useCallback, useEffect, useState } from "react";
import { useChainId } from "lib/chains";
import { getServerBaseUrl } from "config/backend";
import axios from "axios";
import { TOKEN_SHOWS_DECIMALS, TOKEN_WHITE_LIST } from "config/tokens";
import { getTokenSymbolFromString } from "domain/tokens";

const useMarketAssets = () => {
  const [assets, setAssets] = useState({});
  const { chainId } = useChainId();
  const fetchIds = useCallback(async () => {
    const res = await axios.get(`${getServerBaseUrl(chainId, true)}/assets`, {});
    if (res && res.data?.data) {
      let data = res.data.data || [];
      let list = {};
      data = Object.values(data)?.filter((item) =>
        TOKEN_WHITE_LIST[chainId].includes(getTokenSymbolFromString(item?.symbol))
      );
      for (let i = 0; i < data.length; i++) {
        list[data[i].id] = {
          tokenSymbol: getTokenSymbolFromString(data[i]?.symbol),
          ...data[i],
          decimals: TOKEN_SHOWS_DECIMALS[data[i]?.symbol] || 2,
        };
      }

      setAssets(list);
    }
  }, [chainId]);

  useEffect(() => {
    fetchIds();
    const interval = setInterval(async () => {
      fetchIds();
    }, 30000);
    return () => clearInterval(interval);
  }, [chainId]);

  return assets;
};

export default useMarketAssets;
