import { Select } from "antd";
import styled from "styled-components";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";

import LeaderboardItem from "./LeaderboardItem";
import Tooltip from "components/Tooltip/Tooltip";
import inInfo from "img/ic_info.svg";
import upIc from "img/upArrow.svg";
import upIcWhite from "img/upArrowWhite.svg";
import downIc from "img/downArrow.svg";

import "../styles/index.scss";

import { TabWrapper } from "../styles/AnalyticsV3.styled";

import Pagination from "./Pagination";
import DateFilter, { StyledDateFilter } from "components/DateFilter/DateFilter";
import Loader from "components/Common/Loader";
import ExternalLink from "components/ExternalLink/ExternalLink";
import Countdown from "react-countdown";
import NoItemFound from "./NoItemFound";
import { EXTERNAL_LINK, getApiAnalytics } from "../config";
import Tab from "components/Tab/Tab";
import moment from "moment";
import { FANTOM, OP } from "config/chains";

const ORDER_BY_OPTIONS = [
  {
    value: "descTotal",
    label: "Highest to lowest in Total volume",
  },
  {
    value: "ascTotal",
    label: "Lowest to highest in Total volume",
  },
  {
    value: "descMargin",
    label: "Highest to lowest in Margin",
  },
  {
    value: "ascMargin",
    label: "Lowest to highest in Margin",
  },
];

const CONTEST_TIMESTAMP = {
  [FANTOM]: ["2023-02-19", "2023-03-19", "12,000 $FTM"],
  [OP]: ["2023-03-15", "2023-03-16", "1 $ETH"],
};

export const ALL_STATS = "All Stats";
export const REF_CONTEST = "Referral Contest";

const TAB_OPTIONS = [ALL_STATS, REF_CONTEST];
const TAB_OPTIONS_LABELS = {
  [ALL_STATS]: `All Stats`,
  [REF_CONTEST]: `Referral Contest`,
};

export const getUnixDate = (str) => +moment.utc(str).toDate() / 1000;
export const getUnixDateHour = (str) => moment.utc(str).format("HH:mm");

const LeaderBoard = ({ chainId }) => {
  const [activeTab, setActiveTab] = useState(ALL_STATS);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [orderBy, setOrderBy] = useState("descTotal");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [sortType, setSortType] = useState("desc"); // desc down,asc up
  const [sortBy, setSortBy] = useState("total"); // totalReferrals,volumeReferred,rewards
  const [currentPage, setCurrentPage] = useState(1);
  const [row, setRows] = useState(100);
  const [perPage, setPerPage] = useState(10);
  const MAX_NUMBER_PAGE = 100; //HARD FIXED
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const startContestDate = useRef();
  const endContestDate = useRef();
  const [lastUpdate, setLastUpdate] = useState();

  useEffect(() => {
    startContestDate.current = getUnixDate(CONTEST_TIMESTAMP[chainId][0]);
    endContestDate.current = getUnixDate(CONTEST_TIMESTAMP[chainId][1]);
  }, [chainId]);

  const countdownRenderer = (countdownProps) => {
    const { days, hours, minutes, seconds } = countdownProps;
    const d = String(days);
    const h = String(hours);
    const m = String(minutes);
    const s = String(seconds);
    return (
      <div className="time-countdown">
        <div className="time-left">
          {d.padStart(2, "0")}
          <span>d </span>
        </div>{" "}
        <div className="space">: </div>
        <div className="time-left">
          {h.padStart(2, "0")}
          <span>h </span>
        </div>{" "}
        <div className="space">: </div>
        <div className="time-left">
          {m.padStart(2, "0")}
          <span>m</span>{" "}
        </div>{" "}
        <div className="space">: </div>
        <div className="time-left">
          {s.padStart(2, "0")}
          <span>s</span>
        </div>
      </div>
    );
  };

  const timeMonth = new Date();
  timeMonth.setMonth(timeMonth.getMonth() - 1);

  let querySort = "";

  if (sortBy) {
    if (sortBy === "total") {
      if (sortType === "asc") {
        querySort = `&order=total%20ASC`;
      } else {
        querySort = `&order=total%20DESC`;
      }
    } else {
      if (sortBy === "margin") {
        if (sortType === "asc") {
          querySort = `&order=margin%20ASC`;
        } else {
          querySort = `&order=margin%20DESC`;
        }
      } else {
        if (sortType === "asc") {
          querySort = `&order=total%20DESC`;
        } else {
          querySort = `&order=margin%20DESC`;
        }
      }
    }
  }

  const handleStartDate = (date) => {
    setStartDate(date);
  };

  const handleEndDate = (date) => {
    setEndDate(date);
  };

  const handleSearch = () => {
    if (endDate < startDate) {
      setIsLoading(false);
      return;
    }
    setFromDate(startDate / 1000);
    setToDate(endDate / 1000);
    setIsLoading(false);
  };

  const handleRefresh = () => {
    setIsLoading(true);
    setStartDate();
    setEndDate();
    setFromDate();
    setToDate();
    fetchData(currentPage, perPage, startDate, endDate, querySort);
  };

  const handleSortTotalVolume = () => {
    let resultSortType = "";

    if (sortBy === "total") {
      if (sortType !== "asc") {
        resultSortType = "asc";
      } else {
        resultSortType = "desc";
      }
    } else {
      resultSortType = "desc";
    }
    setSortBy("total");
    setSortType(resultSortType);
  };

  const handleSortMargin = () => {
    let resultSortType = "";

    if (sortBy === "margin") {
      if (sortType !== "asc") {
        resultSortType = "asc";
      } else {
        resultSortType = "desc";
      }
    } else {
      resultSortType = "desc";
    }
    setSortBy("margin");
    setSortType(resultSortType);
  };

  const fetchData = async (currentPage, perPage, fromDate, toDate, querySort) => {
    setIsLoading(true);
    const response = await axios(
      `${getApiAnalytics(chainId)}leaderboard/top-account-volume?page=${currentPage}&pageSize=${perPage}&from=${
        1685664000
        // }&to=${toDate || ""}
      }&to=${"1688256000"}
      ${querySort}`
    );

    setRows(response.data.count);
    setData(response.data.rows);
    setLastUpdate(response.data.lastDataUpdateTime);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData(currentPage, perPage, fromDate, toDate, querySort);
  }, [currentPage, perPage, querySort, fromDate, toDate, chainId, activeTab]);

  useEffect(() => {
    setActiveTab(ALL_STATS);
  }, [chainId]);

  useEffect(() => {
    if (activeTab === REF_CONTEST) {
      setFromDate(startContestDate.current);
      setToDate(endContestDate.current);
    } else {
      setFromDate(1652288400);
      setToDate("");
    }
  }, [activeTab]);

  const isSmallScreen = useMediaQuery({ query: "(max-width: 1024px)" });
  const renderTop = () => {
    switch (sortBy) {
      case "total":
        return "Sort by Total Volume";
      case "margin":
        return "Sort by Margin";
      default:
        return "Sort by Total Volume";
    }
  };
  const renderTopIndex = (index) => {
    let isDesc = true;
    if (sortType === "asc") isDesc = false;
    if (isDesc) return index + 1 + perPage * (currentPage - 1);
    else {
      return row - (index + perPage * (currentPage - 1));
    }
  };
  return (
    <TabWrapper>
      {
        <StyledText>
          Duration: Jun 2 2023, 00:00 UTC - Jul 2 2023, 00:00 UTC.
          <a
            style={{ color: "#34f5ae" }}
            rel="noopener noreferrer"
            href="https://medium.com/@mummyftm/mummy-launches-its-first-loyalty-program-to-reward-trading-activity-4e8a48b41851"
            target="_blank"
          >
            More details
          </a>
          {/* <p>{CONTEST_TIMESTAMP[chainId][2]}</p> */}
        </StyledText>
      }

      {/* <StyledTab>
        {chainId !== 10 && (
          <div className="tab-wrapper">
            <TabInner
              options={TAB_OPTIONS}
              optionLabels={TAB_OPTIONS_LABELS}
              option={activeTab}
              setOption={setActiveTab}
              onChange={setActiveTab}
            />
          </div>
        )}
        {activeTab === ALL_STATS && (
          <DateFilter
            startDate={startDate}
            endDate={endDate}
            handleEndDate={handleEndDate}
            handleStartDate={handleStartDate}
            handleSearch={handleSearch}
            handleRefresh={handleRefresh}
          />
        )}
      </StyledTab> */}

      {activeTab !== ALL_STATS && (
        <StyledTimestamp>
          The competition runs{" "}
          {activeTab === ALL_STATS
            ? "All time"
            : `from ${moment(startContestDate.current * 1000).format("ll")}, to ${moment(
                endContestDate.current * 1000
              ).format("ll")}`}
          .{" "}
          {Math.round(new Date().getTime() / 1000) > startContestDate.current ? (
            <>
              Ends in <Countdown date={new Date(endContestDate.current * 1000)} renderer={countdownRenderer} />
            </>
          ) : (
            <>
              Starts in <Countdown date={new Date(startContestDate.current * 1000)} renderer={countdownRenderer} />
            </>
          )}
          . <StyledExternalLink href={EXTERNAL_LINK[chainId].moreDetail}>More details</StyledExternalLink>
        </StyledTimestamp>
      )}

      {isSmallScreen && (
        <>
          <div className="filter-block">
            Ordered by:{" "}
            <Select
              showArrow
              suffixIcon={<img className="downIc" src={downIc} alt="" />}
              onChange={(value) => {
                setOrderBy(value);
                if (value === "ascTotal") {
                  setSortBy("total");
                  setSortType("asc");
                }

                if (value === "descTotal") {
                  setSortBy("total");
                  setSortType("desc");
                }

                if (value === "ascMargin") {
                  setSortBy("margin");
                  setSortType("asc");
                }

                if (value === "descmargin") {
                  setSortBy("margin");
                  setSortType("desc");
                }
              }}
              defaultValue={orderBy}
              style={{
                width: "fit-content",
              }}
              bordered={false}
              options={ORDER_BY_OPTIONS}
            />
          </div>
        </>
      )}
      <div className="header-table referrals">
        <div>
          <span>
            <Tooltip
              handle={`Top`}
              position="left-bottom"
              renderContent={() => {
                return <p className="text-white">{renderTop()}</p>;
              }}
            />
          </span>
          <span>Wallet</span>
        </div>
        <span
          style={{
            cursor: "pointer",
          }}
          onClick={handleSortTotalVolume}
        >
          <Tooltip handle={"Total volume"} renderContent={() => "Margin + Liquidation + Swap + MLP."} />
          <div className="group-arrow">
            <span>
              {sortBy === "total" && sortType === "asc" ? (
                <img src={upIcWhite} alt="" className="up" />
              ) : (
                <img src={upIc} alt="" className="up" />
              )}
            </span>

            <span>
              {sortBy === "total" && sortType === "desc" ? (
                <img src={upIcWhite} alt="" className="down" />
              ) : (
                <img src={upIc} alt="" className="down" />
              )}
            </span>
          </div>
        </span>
        <span
          style={{
            cursor: "pointer",
          }}
          onClick={handleSortMargin}
        >
          <Tooltip handle={"Margin"} renderContent={() => "Total size of all positions."} />
          <div className="group-arrow">
            <span>
              {sortBy === "margin" && sortType === "asc" ? (
                <img src={upIcWhite} alt="" className="up" />
              ) : (
                <img src={upIc} alt="" className="up" />
              )}
            </span>

            <span>
              {sortBy === "margin" && sortType === "desc" ? (
                <img src={upIcWhite} alt="" className="down" />
              ) : (
                <img src={upIc} alt="" className="down" />
              )}
            </span>
          </div>
        </span>
        <span>Liquidation</span>
        <span>Swap</span>
        <span>
          <Tooltip handle={"MLP"} renderContent={() => "The total volume resulting from buying and selling MLP."} />
        </span>
        <span>
          <Tooltip handle={"Rewards"} renderContent={() => "Prize = esMMY share pool * current MMY price."} />
        </span>
      </div>
      <div className="body-table referrals">
        {!isLoading ? (
          data.length > 0 ? (
            data.map((item, index) => {
              const rank = currentPage * perPage - (perPage - 1);
              return (
                <LeaderboardItem
                  hasPrize={sortBy === "total" && sortType === "desc"}
                  key={index}
                  data={item}
                  rank={renderTopIndex(index)}
                />
              );
            })
          ) : (
            <NoItemFound />
          )
        ) : (
          // <Loader />
          <div className="skeleton-box" style={{ height: "30px", marginTop: "20px" }} />
        )}
      </div>
      {data.length > 0 && (
        <Pagination
          rowsPerPage={perPage}
          setRowsPerPage={setPerPage}
          row={row}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          maxNumberOfPage={MAX_NUMBER_PAGE}
        />
      )}
    </TabWrapper>
  );
};

const StyledTab = styled.div`
  margin-bottom: 22.5px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .Tab-option {
    margin: auto;
    height: 45px;
    display: flex;
    align-items: center;
  }

  ${StyledDateFilter} {
    margin-left: auto;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const StyledTimestamp = styled.div`
  color: #b7b7bd;
  font-size: 14px;
  line-height: 2.15rem;
  margin-bottom: 2.325rem;

  .time-countdown {
    display: inline-flex;
  }

  .space {
    margin: 0 2.5px;
  }
`;

const StyledText = styled.div`
  margin: 10px 0 30px;
  display: flex;
  align-items: center;
  gap: 4px;

  p {
    display: inline-block;
    margin: 0;
    font-size: 1.75em;
    font-weight: 600;
    color: #34f5ae;
    text-shadow: rgb(56 229 103) 1px 1px 20px, rgb(56 229 103) 0px 0px 20px;
  }
`;

const StyledExternalLink = styled(ExternalLink)`
  color: #34f5ae;
`;

const TabInner = styled(Tab)`
  background-color: #191b2e !important;
  height: 60px;
  padding: 8px;
`;

export default LeaderBoard;
