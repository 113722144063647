import { useChainIdWithDefault } from "lib/chains";
import { useEffect, useState } from "react";
import styled from "styled-components";

import Footer from "components/Footer/Footer";
import { ARBITRUM, BASE, FANTOM, OP, SUPPORTED_V2_CHAINS } from "config/chains";
import StatsProviderV2 from "contexts/StatsProviderV2";
import { useThemeContext } from "contexts/ThemeProvider.js";
import imgHeader from "img/analytics/pattern-leader-board.svg";
import rightImg from "img/analytics/right-img-leaderboard.png";
import AccountV2 from "pages/AnalyticsV2/components/Account";
import ContestTabV2 from "pages/AnalyticsV2/components/ContestTab";
import PositionDetailV2 from "pages/AnalyticsV2/components/PositionDetail";
import SearchTabV2 from "pages/AnalyticsV2/components/SearchTab";
import TopPositionTabV2 from "pages/AnalyticsV2/components/TopPositionTab";
import TopTradersTabV2 from "pages/AnalyticsV2/components/TopTradersTab";
import { StyledAnalyticsContentV2 } from "pages/AnalyticsV2/styles/AnalyticsV2.styled.js";
import { TXT } from "pages/NFT/multichainTxt.js";
import { Link, Route, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import "styles/AnalyticV1.scss";
import SearchAddress from "./SearchAddress";
import Account from "./components/Account";
import LeaderBoard from "./components/LeaderBoard";
import Oracle from "./components/Oracle.jsx";
import PositionDetail from "./components/PositionDetail";
import TopPositionTab from "./components/TopPositionTab";
import TopReferrals from "./components/TopReferrals";
import TopTraderTab from "./components/TopTraderTab";
import { StyledAnalyticsContent } from "./styles/Analytics.styled";

export default function LeaderBoardSplits() {
  const { isLightTheme } = useThemeContext() as any;
  const location = useLocation();
  const { path } = useRouteMatch();
  // const { chainId } = useChainId();
  const history = useHistory();
  const chainId = useChainIdWithDefault({
    isUseDefaultChain: false,
  });
  const [activedTab, setActivedTab] = useState(location.pathname);

  useEffect(() => {
    if (history.location.pathname === "/leaderboard") {
      setActivedTab("/leaderboard/top-traders");
    } else if (history.location.pathname === "/leaderboard/v2") {
      setActivedTab("/leaderboard/v2/top-traders");
    } else setActivedTab(history.location.pathname);
  }, [history.location.pathname]);

  const TABS = [
    // {
    //   label: "Overview",
    //   networks: [FANTOM, OP, ARBITRUM, BASE],
    //   link: "/leaderboard/overview",
    // },

    {
      label: "Top Traders",
      networks: [FANTOM, OP, ARBITRUM, BASE],
      link: "/leaderboard/top-traders",
    },
    {
      label: "Top Positions",
      networks: [FANTOM, OP, ARBITRUM, BASE],
      link: "/leaderboard/top-positions",
    },
    {
      label: "Top Referrals",
      networks: [FANTOM, OP, ARBITRUM, BASE],
      link: "/leaderboard/top-referrals",
    },
    {
      label: "Loyalty",
      networks: [], //TODO legacy
      link: "/leaderboard/loyalty",
    },
    {
      label: "Oracle",
      networks: [FANTOM, OP, ARBITRUM, BASE],
      link: "/leaderboard/oracle",
    },
    {
      label: "Search",
      networks: [FANTOM, OP, ARBITRUM, BASE],
      link: "/leaderboard/search",
    },
    // {
    //   label: "Contest",
    //   networks: [FANTOM, OP, ARBITRUM, BASE],
    //   link: "/leaderboard/contest",
    // },
  ];
  const TABSV2 = [
    // {
    //   label: "Overview",
    //   networks: [FANTOM, OP, ARBITRUM, BASE],
    //   link: "/leaderboard/overview",
    // },

    {
      label: "Top Traders",
      // networks: [FANTOM],
      link: "/leaderboard/v2/top-traders",
    },
    {
      label: "Top Positions",
      // networks: [FANTOM],
      link: "/leaderboard/v2/top-positions",
    },
    {
      label: "Contest",
      // networks: [FANTOM],
      hideOn: [ARBITRUM, BASE],
      link: "/leaderboard/v2/contest",
    },
    {
      label: "Search",
      // networks: [FANTOM],
      link: "/leaderboard/v2/search",
    },
    // {
    //   label: "Contest",
    //   networks: [FANTOM, OP, ARBITRUM, BASE],
    //   link: "/leaderboard/contest",
    // },
  ];
  const Versions = [
    {
      name: "V1",
      links: ["/leaderboard/v1", "/leaderboard"],
      value: 1,
      href: "/leaderboard",
    },
    {
      name: "V2",
      links: [
        "/leaderboard/v2",
        "/leaderboard/v2/top-traders",
        "/leaderboard/v2/top-positions",
        "/leaderboard/v2/contest",
        "/leaderboard/v2/search",
        // "/leaderboard/v2/top-referrals",
        // "/leaderboard/v2/oracle",
        // "/leaderboard/v2/search",
      ],
      value: 2,
      href: "/leaderboard/v2",
    },
  ];

  const [curentVersion, setCurentVersion] = useState(1);
  useEffect(() => {
    if (history?.location?.pathname) {
      if (history?.location?.pathname.includes("/leaderboard/v2")) {
        if (curentVersion !== 2) setCurentVersion(2);
      } else {
        if (curentVersion !== 1) setCurentVersion(1);
      }
    }
  }, [history?.location?.pathname]);
  useEffect(() => {
    if (!SUPPORTED_V2_CHAINS.includes(chainId)) {
      if (history?.location?.pathname.includes("/leaderboard/v2")) {
        history.push("/leaderboard");
      }
    }
  }, [chainId]);
  const isValidChain = SUPPORTED_V2_CHAINS.includes(chainId);
  return (
    <div className="analytic-v1-wrapper">
      <HeadWraper $isThemeLight={isLightTheme}>
        <div className="analytic-container header leaderboard-container">
          <Title>
            <div className="title-container">
              <span className="title-head" style={{ textTransform: "none" }}>
                Leaderboard
              </span>{" "}
              <img src={TXT[chainId].icon} alt="img" />{" "}
            </div>
            <p className="desc">
              The ranked list based on trading performance <br />
            </p>
            {SUPPORTED_V2_CHAINS.includes(chainId) && (
              <div className="version-container version-container-leaderboad">
                {Versions.map((item) => (
                  <Link
                    to={item.href}
                    className={
                      item.value === curentVersion ? "version-content version-content--active" : "version-content"
                    }
                    key={item.value}
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
            )}
          </Title>
          <ImgContainer>
            <img src={rightImg} alt="img" className="img-header" />
          </ImgContainer>
        </div>
      </HeadWraper>
      <Wrapper>
        <TabWrapper>
          <div className="tab-container">
            {curentVersion === 1 &&
              TABS?.filter((x) => x?.networks.includes(chainId)).map((item) => (
                <TabItem
                  onClick={() => setActivedTab(item.link)}
                  key={item.link}
                  to={item.link}
                  className={`${activedTab === item.link ? "tab--actived" : ""}`}
                >
                  <div>{item.label}</div>
                </TabItem>
              ))}
            {curentVersion === 2 &&
              TABSV2.map((item) => {
                const hide = item.hideOn && item.hideOn.some((chain) => chainId === chain);
                if (hide) return null;
                return (
                  <TabItem
                    onClick={() => setActivedTab(item.link)}
                    key={item.link}
                    to={item.link}
                    className={`${activedTab === item.link ? "tab--item tab--actived" : "tab--item"}`}
                  >
                    <div>{item.label}</div>
                  </TabItem>
                );
              })}
          </div>
        </TabWrapper>
        {curentVersion === 1 && (
          <StyledAnalyticsContent>
            <Route path={`${path}/top-traders`}>
              <TopTraderTab chainId={chainId} isThemeLight={isLightTheme} />
            </Route>
            <Route path={`${path}/top-positions`}>
              <TopPositionTab chainId={chainId} isThemeLight={isLightTheme} />
            </Route>
            <Route path={`${path}/top-referrals`}>
              <TopReferrals chainId={chainId} isThemeLight={isLightTheme} />
            </Route>
            <Route path={`${path}/loyalty`}>
              <LeaderBoard chainId={chainId} />
            </Route>
            <Route path={`${path}/position/:position`}>
              <PositionDetail chainId={chainId} isThemeLight={isLightTheme} />
            </Route>
            <Route path={`${path}/account/:account`}>
              <Account chainId={chainId} isThemeLight={isLightTheme} />
            </Route>
            <Route path={`${path}/search`}>
              <SearchAddress />
            </Route>
            <Route path={`${path}/oracle`}>
              <Oracle chainId={chainId} />
            </Route>
            <Route exact path={`${path}/`}>
              <TopTraderTab chainId={chainId} isThemeLight={isLightTheme} />
            </Route>
          </StyledAnalyticsContent>
        )}

        {SUPPORTED_V2_CHAINS.includes(chainId) && curentVersion === 2 && (
          <StatsProviderV2>
            <StyledAnalyticsContentV2 className="v2-container">
              <Route path={`${path}/v2/top-traders`}>
                <TopTradersTabV2 chainId={chainId} isValidChain={isValidChain} />
              </Route>
              <Route path={`${path}/v2/top-positions`}>
                <TopPositionTabV2 chainId={chainId} isValidChain={isValidChain} />
              </Route>
              <Route path={`${path}/v2/position/:posId`}>
                <PositionDetailV2 chainId={chainId} isValidChain={isValidChain} />
              </Route>
              <Route path={`${path}/v2/account/:account`}>
                <AccountV2 chainId={chainId} isValidChain={isValidChain} />
              </Route>
              <Route path={`${path}/v2/contest`}>
                <ContestTabV2 chainId={chainId} isValidChain={isValidChain} />
              </Route>
              <Route path={`${path}/v2/search`}>
                <SearchTabV2 />
              </Route>
              <Route exact path={`${path}/v2`}>
                <TopTradersTabV2 chainId={chainId} isValidChain={isValidChain} />
              </Route>
            </StyledAnalyticsContentV2>
          </StatsProviderV2>
        )}
        <Footer />
      </Wrapper>
    </div>
  );
}
const Wrapper = styled.div`
  .analytic-container {
    width: 100%;
    max-width: calc(1320px + 48px);
    padding-left: 24px;
    padding-right: 24px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 700px) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
`;
const TabWrapper = styled.div`
  width: 100%;
  max-width: calc(1320px + 48px);
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 auto 24px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1023px) {
    flex-direction: column;
    padding-left: 20px;
  }

  @media (max-width: 767px) {
    padding-left: 16px;
    padding-right: 16px;

    > .tab-container {
      width: 100%;
    }

    > span {
      padding-left: 20px;
      margin-top: 10px;
      width: 100% !important;
    }
  }

  > span {
    flex: 1 45%;
    text-align: right;
    align-self: flex-end;
    color: #ffffff99 !important;

    @media (max-width: 1023px) {
      margin-top: 16px;
      padding-right: 20px;
    }
  }

  .tab-container {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    overflow-x: auto;
    padding: 4px;
    background-color: #191b2e;
    border-radius: 0px 0px 16px 16px;

    &::-webkit-scrollbar {
      height: 2px;
    }

    &::-webkit-scrollbar-track-piece:end {
      margin-right: 10px;
    }

    &::-webkit-scrollbar-track-piece:start {
      margin-left: 10px;
    }

    @media (max-width: 1023px) {
      justify-content: space-between;
    }
  }
`;
const TabItem = styled(Link)`
  padding: 12px 16px;
  white-space: nowrap;

  font-size: 14px;
  text-decoration: none;
  font-weight: 500 !important;
  color: rgb(255, 255, 255);
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    color: #04d397 !important;
  }
  &.tab--actived {
    color: #03f5ae !important;
    background-color: #ffffff1a;
    border-radius: 12px;
  }
`;
const Title = styled.div`
  width: 1218px;
  max-width: 100%;
  text-transform: uppercase;
  align-self: flex-start;
  margin-top: 64px;

  @media (max-width: 767px) {
    padding-bottom: 24px;
  }

  .title-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;

    @media (max-width: 1023px) {
      img {
        width: 32px;
        height: 32px;
      }
    }
  }

  span {
    font-weight: 700;
    font-size: 36px;
    line-height: 140%;
    color: rgb(255, 255, 255);

    @media (max-width: 1023px) {
      font-size: 24px;
      line-height: 140%;
    }
  }
  .version-styled {
    color: #03f5ae !important;
  }

  img {
    width: 48px;
    height: 48px;
  }

  .desc {
    text-transform: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: rgba(255, 255, 255, 0.6) !important;
    margin-top: 4px;
    margin-bottom: 0;
  }
`;
const HeadWraper = styled.div<{ $isThemeLight: Boolean }>`
  background-image: ${({ $isThemeLight }) =>
    $isThemeLight
      ? `url(${imgHeader}), linear-gradient(to right, #312289, #094783)`
      : `url(${imgHeader}), linear-gradient(to right, #312289, #094783)`};
  background-position: center;
  width: 100%;
  height: 289px;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  position: relative;
  @media (max-width: 767px) {
    height: unset;
  }

  .header {
    width: 100%;
    max-width: calc(1320px + 48px);
    padding-left: 24px;
    padding-right: 24px;
    margin: 0 auto;
    display: flex;
    position: relative;

    justify-content: space-between;
    align-items: flex-end;
    height: 100%;

    @media (max-width: 767px) {
      flex-direction: column;
    }
  }
`;
const ImgContainer = styled.div`
  width: 376px;
  height: 225px;
  overflow: hidden;
  object-fit: contain;
  margin-left: auto;
  flex-shrink: 0;

  @media screen and (max-width: 767px) {
    height: 160px;

    img {
      height: 100%;
    }
  }
`;
