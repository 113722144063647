const NOT_ALLOWED_KEYS = ["-", "e", "+"];

type GetButtonTextParams = {
  title: string;
  inprocess?: string | boolean;
  error?: string;
};

export function getButtonText(options: GetButtonTextParams) {
  const { inprocess, error, title } = options;
  if (error) return error || "Error";
  if (inprocess) return inprocess || "Loading...";
  return title;
}

export function preventSpecialCharacters(e: React.KeyboardEvent<HTMLInputElement>) {
  if (NOT_ALLOWED_KEYS.includes(e.key)) e.preventDefault();
}

export function isIphoneDevice() {
  return ["iPhone"].includes(navigator.platform);
}
