const TimerIcon = (props: JSX.IntrinsicElements["svg"]) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M12.1523 2.00196C9.25997 1.95868 6.64866 3.15639 4.79688 5.08985L2.85352 3.14649C2.65852 2.95149 2.34148 2.95149 2.14648 3.14649C2.04848 3.24349 2 3.37201 2 3.50001V8.00001C2 8.26522 2.10536 8.51958 2.29289 8.70711C2.48043 8.89465 2.73478 9.00001 3 9.00001H7.5C7.628 9.00001 7.75552 8.95052 7.85352 8.85352C8.04852 8.65852 8.04852 8.34149 7.85352 8.14649L6.2168 6.50977C8.02806 4.60118 10.7519 3.56907 13.6914 4.17579C16.7664 4.81079 19.2318 7.29987 19.8398 10.3809C20.8498 15.4989 16.941 20 12 20C7.881 20 4.47988 16.8702 4.04688 12.8652C3.99287 12.3682 3.5625 12 3.0625 12C2.4625 12 1.9955 12.5251 2.0625 13.1211C2.6215 18.1091 6.866 22 12 22C18.136 22 22.9993 16.4437 21.8223 10.0977C21.0773 6.07466 17.8171 2.86026 13.7871 2.15626C13.2347 2.05988 12.6888 2.00999 12.1523 2.00196Z"
        fill={props.color || "currentColor"}
      />
      <path
        d="M12.0001 5.99918C11.4481 5.99918 11.0001 6.44718 11.0001 6.99918V11.9992C11.0001 12.2642 11.105 12.5192 11.293 12.7062L13.586 14.9992C13.976 15.3892 14.6101 15.3892 15.0001 14.9992C15.3901 14.6092 15.3901 13.9751 15.0001 13.5851L13.0001 11.5851V6.99918C13.0001 6.44718 12.5521 5.99918 12.0001 5.99918Z"
        fill={props.stopColor || "#03F5AE"}
      />
    </svg>
  );
};

export default TimerIcon;
