import { useWeb3React } from "@web3-react/core";
import bg from "img/home/bg_nft.png";
import bgLarge from "img/home/bg_nft_large.png";
import mum1 from "img/home/mum1.png";
import icCheckbox from "img/icons/Checkbox--inactive.svg";
import icCheckboxLight from "img/icons/Checkbox--inactiveLight.svg";
import icCheckboxActive from "img/icons/Checkbox.svg";
import icCheckboxActiveLight from "img/icons/CheckboxLight.svg";
import icFlash from "img/icons/flash.svg";
import icLink from "img/icons/link-green.svg";
import icLinkLight from "img/icons/link-greenLight.svg";
import Countdown from "react-countdown";

import { getTokenBySymbol } from "config/tokens";
import { useInfoTokens } from "domain/tokens";
import { ethers } from "ethers";
import { bigNumberify, expandDecimals, formatAmount, formatNumber } from "lib/numbers";
import styled from "styled-components";

import { getContract } from "config/contracts";
import useGetListStakedNFT from "hooks/useGetListStakedNFT";
import useGetListStakedNFTv2 from "hooks/useGetListStakedNFTv2";
import { useChainId } from "lib/chains";
import { Link } from "react-router-dom";
import useSWR from "swr";

import MummyClubStaking from "abis/MummyClubStaking.json";
import NFTClub from "abis/NFTClub.json";

import { callContract, contractFetcher } from "lib/contracts";

import { commify } from "@ethersproject/units";
import { OP, FANTOM, getLinkCDN, MUMBAI } from "config/chains";
import iconLink from "img/icons/link-green.svg";
import iconLinkLight from "img/icons/link-greenLight.svg";
import { isEmpty } from "lodash";
import { useState } from "react";
import { TXT } from "../multichainTxt";
import { useThemeContext } from "contexts/ThemeProvider";

function StakeNWFTMain({ isVisible, setIsVisible, connectWallet, setPendingTxns, pendingTxns }) {
  const { lightThemeClassName, isLightTheme } = useThemeContext();
  const { active, activate, account, library } = useWeb3React();
  const { chainId } = useChainId();

  const [choosenNFT, setChoosenNFT] = useState([]);

  const handleChange = (clickItem) => {
    const lists = [...choosenNFT];

    if (lists.includes(clickItem.toString())) {
      const filterList = lists.filter((item) => {
        return item !== clickItem;
      });

      setChoosenNFT(filterList);
      return;
    } else {
      lists.push(clickItem.toString());
      setChoosenNFT(lists);
    }
  };

  const handleSelect = (type) => {
    if (type === "remove") {
      setChoosenNFT([]);
      return;
    }
    let array = [];
    listMyNFT.map((item) => {
      array.push(item.toString());
      return item;
    });

    setChoosenNFT(array);
  };

  const nftClubAddress = getContract(chainId, chainId === FANTOM ? "NFTClubV2" : "NFTClub");
  const mummyClubStakingAddress = getContract(chainId, chainId === FANTOM ? "MummyClubStakingV2" : "MummyClubStaking");

  const { listMyNFT: listMyNFTv1 } = useGetListStakedNFT();
  const { listMyNFT: listMyNFTv2 } = useGetListStakedNFTv2();

  const listMyNFT = chainId === FANTOM || chainId === MUMBAI ? listMyNFTv2 : listMyNFTv1;

  const yourStakedNFT = listMyNFT?.filter((item) => item.includes(true))?.length;
  const countdownRenderer = (countdownProps) => {
    const { days, hours, minutes, seconds } = countdownProps;
    const d = String(days);
    const h = String(hours);
    const m = String(minutes);
    const s = String(seconds);
    return (
      <div className="time-countdown">
        <div className="time-left">
          {d.padStart(2, "0")}
          <span>d </span>
        </div>{" "}
        <div className="space">: </div>
        <div className="time-left">
          {h.padStart(2, "0")}
          <span>h </span>
        </div>{" "}
        <div className="space">: </div>
        <div className="time-left">
          {m.padStart(2, "0")}
          <span>m</span>{" "}
        </div>{" "}
        <div className="space">: </div>
        <div className="time-left">
          {s.padStart(2, "0")}
          <span>s</span>
        </div>
      </div>
    );
  };
  const { data: isApprovedNFT } = useSWR(
    account && [`StakeNFT:isApprovedForAll:${active && account}`, chainId, nftClubAddress, "isApprovedForAll"],
    {
      fetcher: contractFetcher(library, NFTClub, [account, mummyClubStakingAddress]),
    }
  );
  const { data: epoch } = useSWR([`StakeNFT:epoch:${active}`, chainId, mummyClubStakingAddress, "epoch"], {
    fetcher: contractFetcher(library, MummyClubStaking),
  });
  const { data: nextEpoch } = useSWR(
    [`StakeNFT:nextEpochPoint:${active}`, chainId, mummyClubStakingAddress, "nextEpochPoint"],
    {
      fetcher: contractFetcher(library, MummyClubStaking, []),
    }
  );
  const { data: epochReward } = useSWR(
    [`StakeNFT:epochReward:${active}`, chainId, mummyClubStakingAddress, "epochReward"],
    {
      fetcher: contractFetcher(library, MummyClubStaking),
    }
  );
  const { data: totalRewardDistributed } = useSWR(
    [`StakeNFT:totalRewardDistributed:${active}`, chainId, mummyClubStakingAddress, "totalRewardDistributed"],
    {
      fetcher: contractFetcher(library, MummyClubStaking, []),
    }
  );
  const { data: stakedPower } = useSWR(
    [`StakeNFT:stakedPower:${active && account}`, chainId, mummyClubStakingAddress, "balances"],
    {
      fetcher: contractFetcher(library, MummyClubStaking, [account]),
    }
  );
  const { data: pendingReward } = useSWR(
    [`StakeNFT:pendingReward:${active}`, chainId, mummyClubStakingAddress, "earned"],
    {
      fetcher: contractFetcher(library, MummyClubStaking, [account]),
    }
  );
  const { data: totalPower } = useSWR(
    [`StakeNFT:totalPower:${active}`, chainId, mummyClubStakingAddress, "totalPower"],
    {
      fetcher: contractFetcher(library, MummyClubStaking),
    }
  );
  const { data: totalSupply } = useSWR(
    [`StakeNFT:totalSupply:${active}`, chainId, mummyClubStakingAddress, "totalSupply"],
    {
      fetcher: contractFetcher(library, MummyClubStaking),
    }
  );
  const { data: totalNFTMint } = useSWR([`NFT:totalNFTMint:${active}`, chainId, nftClubAddress, "totalSupply"], {
    fetcher: contractFetcher(library, NFTClub),
  });
  let listIDStaked = [];

  let listIDNoStake = [];
  choosenNFT.forEach((element) => {
    if (element.includes("true")) {
      listIDStaked.push(element.split(",")[0]);
    } else {
      listIDNoStake.push(element.split(",")[0]);
    }
  });
  let nextYourReward;
  if (totalPower && totalPower.gt(0) && stakedPower && epochReward) {
    nextYourReward = epochReward.mul(stakedPower).div(totalPower);
  }
  const { infoTokens } = useInfoTokens(library, chainId, active, undefined, undefined);
  const token =
    chainId === FANTOM
      ? infoTokens[getTokenBySymbol(250, "FTM").address]
      : infoTokens[getTokenBySymbol(10, "WETH").address];

  let estAPR, estYourAPR;
  // let priceFTM = bigNumberify("203784000000000000")
  let priceFTM = token?.minPrice || bigNumberify(0);
  let pricePowder = chainId === FANTOM ? priceFTM.div(bigNumberify(22)) : priceFTM.div(bigNumberify(30000));
  if (epochReward && totalPower && totalPower.gt(0) && priceFTM && priceFTM.gt(0) && pricePowder) {
    estAPR = epochReward
      .mul(bigNumberify(52))
      .mul(priceFTM)
      .div(expandDecimals(1, 30))
      .div(totalPower.mul(pricePowder).div(expandDecimals(1, 30)))
      .mul(bigNumberify(100));

    if (chainId === OP && totalSupply) {
      estAPR = epochReward
        .mul(bigNumberify(52))
        .div(totalSupply.mul(bigNumberify("130000000000000000")).div(expandDecimals(1, 18)))
        .mul(bigNumberify(100));
    }
  }

  if (nextYourReward && stakedPower && stakedPower.gt(0) && pricePowder && priceFTM.gt(0) && pricePowder) {
    estYourAPR = nextYourReward
      .mul(bigNumberify(52))
      .mul(priceFTM)
      .div(expandDecimals(1, 30))
      .div(stakedPower.mul(pricePowder).div(expandDecimals(1, 30)))
      .mul(bigNumberify(100));
    if (estYourAPR.gt(estAPR)) {
      estYourAPR = estAPR;
    }
    if (chainId === OP && totalSupply) {
      estYourAPR = nextYourReward
        .mul(bigNumberify(52))
        .div(totalSupply.mul(bigNumberify("130000000000000000")).div(expandDecimals(1, 18)))
        .mul(bigNumberify(100));
    }
  }

  const onApprove = () => {
    const contract = new ethers.Contract(nftClubAddress, NFTClub.abi, library.getSigner());
    callContract(chainId, contract, "setApprovalForAll", [mummyClubStakingAddress, true], {
      sentMsg: `Approve NFT submitted!`,
      failMsg: `Approve NFT failed.`,
      successMsg: `Approve NFT completed!`,
      setPendingTxns,
    })
      .then(async (res) => {})
      .finally(() => {});
  };
  const onClaim = () => {
    const contract = new ethers.Contract(mummyClubStakingAddress, MummyClubStaking.abi, library.getSigner());
    callContract(chainId, contract, "claimReward", [], {
      sentMsg: `Claim submitted!`,
      failMsg: `Claim failed.`,
      successMsg: `Claim completed!`,
      setPendingTxns,
    })
      .then(async (res) => {})
      .finally(() => {});
  };
  async function stakeNFT(listNftID) {
    if (listNftID) {
      const opts = {
        // gasLimit: bigNumberify(7258590),
        successMsg: `Stake completed`,
        failMsg: `Stake failed`,
        sentMsg: `Stake submitted`,
        setPendingTxns,
      };
      const params = [listNftID];
      const method = "stake";
      const contract = new ethers.Contract(mummyClubStakingAddress, MummyClubStaking.abi, library.getSigner());
      const res = await callContract(chainId, contract, method, params, opts);
      if (res) await res.wait();
      // let newList = choosenNFT.filter((item) => item.includes("true"));
      setChoosenNFT([]);
      // .then(async (tx) => {})
      // .finally(() => {
      //   // let newList = choosenNFT.filter((item) => item.includes("true"));
      //   // setChoosenNFT(newList);
      // });
    }
  }
  async function unStakeNFT(listNftID) {
    if (listNftID) {
      const opts = {
        // gasLimit: bigNumberify(7258590),
        successMsg: `Unstake completed`,
        failMsg: `Unstake failed`,
        sentMsg: `Unstake submitted`,
        setPendingTxns,
      };
      const params = [listNftID];
      const method = "withdraw";
      const contract = new ethers.Contract(mummyClubStakingAddress, MummyClubStaking.abi, library.getSigner());
      const res = await callContract(chainId, contract, method, params, opts);
      // .then(async (tx) => {})
      // .finally(() => {
      //   // let newList = choosenNFT.filter((item) => item.includes("false"));
      //   // setChoosenNFT(newList);
      // });
      if (res) await res.wait();
      // let newList = choosenNFT.filter((item) => item.includes("false"));
      setChoosenNFT([]);
    }
  }

  const renderButton = (item, isStaked) => {
    // if (chainId === FANTOM || chainId === MUMBAI) {
    //   return (
    //     <button className="btn-outline" disabled>
    //       Approve
    //     </button>
    //   );
    // }

    if (!isApprovedNFT)
      return (
        <button className="btn-outline" onClick={onApprove}>
          Approve
        </button>
      );
    //điều kiện là chưa stake
    if (!isStaked) {
      return (
        <button className="btn-primary" onClick={() => stakeNFT([item])}>
          Stake
        </button>
      );
    } else
      return (
        <button className="btn-outline" onClick={() => unStakeNFT([item])}>
          Unstake
        </button>
      );
  };

  const chosenStakeCountArr = choosenNFT.filter((item) => {
    return item.includes("false");
  });

  const chosenUnStakeCount = choosenNFT.filter((item) => {
    return item.includes("true");
  });

  const renderStats = () => {
    return (
      <>
        <EpochStats className={`epoch-stats ${lightThemeClassName}`}>
          <div className="current-epoch">
            <div className="label">Current epoch</div>
            <div className="amount">
              {epoch ? (
                "#" + commify(epoch)
              ) : (
                <span className="skeleton-box" style={{ width: "60px", height: "25px" }} />
              )}
            </div>
          </div>
          <div className="stake-nft">
            <div className="label">Staked NFT</div>
            {totalSupply ? (
              <div className="amount">
                {" "}
                {totalSupply ? commify(totalSupply) : "--"}/{totalNFTMint ? commify(totalNFTMint) : "--"}
              </div>
            ) : (
              <div className="amount">
                <div className="skeleton-box" style={{ width: "60px", height: "25px" }} />
              </div>
            )}
          </div>
          <div className="total-reward">
            <div className="label">Total distributed</div>
            {totalRewardDistributed ? (
              <div className="amount">
                {" "}
                <img alt="img" src={TXT[chainId]?.nativeIcon} />{" "}
                {formatAmount(totalRewardDistributed, 18, 2, true) + ` ${TXT[chainId]?.wrapToken}`}
              </div>
            ) : (
              <div className="amount">
                <div className="skeleton-box" style={{ width: "60px", height: "25px" }} />
              </div>
            )}
          </div>
          <div className="next-epoch">
            <div className="label">Next epoch</div>
            {nextEpoch ? (
              <div className="amount">
                {nextEpoch * 1000 > Date.now() ? (
                  <Countdown date={new Date(nextEpoch * 1000)} renderer={countdownRenderer} />
                ) : (
                  "-- : -- : --"
                )}
              </div>
            ) : (
              <div className="amount">
                <div className="skeleton-box" style={{ width: "60px", height: "25px" }} />
              </div>
            )}
          </div>{" "}
          <div className="apr">
            <div className="label">Max APR</div>
            {estAPR ? (
              <div className="amount amount-highlight"> ~{estAPR ? formatAmount(estAPR, 18, 2, true) : "--"}%</div>
            ) : (
              <div className="amount">
                <div className="skeleton-box" style={{ width: "60px", height: "25px" }} />
              </div>
            )}
          </div>
          <div className="next-epoch-reward">
            <div className="label">Next epoch reward</div>
            {epochReward ? (
              <div className="amount">
                {" "}
                <img alt="img" src={TXT[chainId]?.nativeIcon} />
                {formatAmount(epochReward, 18, 2, true) + ` ${TXT[chainId]?.wrapToken}`}
              </div>
            ) : (
              <div className="amount">
                <div className="skeleton-box" style={{ width: "60px", height: "25px" }} />
              </div>
            )}
          </div>
        </EpochStats>
        <StakePower className="stats-box">
          <div className="label">Your staked power</div>
          <div className="amount amount--large">
            {/* {stakedPower ? formatNumber(stakedPower.toString(), 0) : "--"} */}
            {formatAmount(stakedPower, 0, 0, true)}
          </div>
          <div className="est-apr">
            Est. your APR: &nbsp;
            {estYourAPR ? (
              <span className="amount-highlight">~{estYourAPR ? formatAmount(estYourAPR, 18, 2, true) : "--"}%</span>
            ) : (
              <span className="skeleton-box" style={{ width: "60px", height: "25px" }} />
            )}
          </div>
        </StakePower>
        <Reward className="stats-box">
          <div>
            <div className="label">Next reward</div>
            {nextYourReward ? (
              <div className="amount">
                <span className="amount-highlight">
                  {" "}
                  {nextYourReward
                    ? formatNumber(formatAmount(nextYourReward, 18, 18, false), chainId != FANTOM ? 4 : 2)
                    : "--"}
                </span>
                {TXT[chainId]?.wrapToken}
              </div>
            ) : (
              <div className="amount">
                <span className="skeleton-box" style={{ width: "60px", height: "25px" }} />
              </div>
            )}
          </div>
          <div>
            <div className="label">Pending reward</div>
            {pendingReward ? (
              <div className="amount ">
                <span className="amount-highlight">
                  {/* {formatAmount(pendingReward, 18, 2, true)} */}
                  {pendingReward
                    ? formatNumber(formatAmount(pendingReward, 18, 18, false), chainId != FANTOM ? 4 : 2)
                    : "--"}
                </span>
                {TXT[chainId]?.wrapToken}
              </div>
            ) : (
              <div className="amount">
                <span className="skeleton-box" style={{ width: "60px", height: "25px" }} />
              </div>
            )}
          </div>
          {!active ? (
            <button className="btn-primary btn-claim" onClick={() => connectWallet()}>
              Connect Wallet
            </button>
          ) : (
            <button
              className="btn-primary btn-claim"
              onClick={onClaim}
              disabled={!(pendingReward && pendingReward.gt(0))}
            >
              Claim
            </button>
          )}
        </Reward>
      </>
    );
  };
  return (
    <StyledHowTo className={lightThemeClassName}>
      <div className="container">
        <SectionTitle>
          Earn real profit with {chainId === FANTOM ? "Fantom Mummy Club" : "Optimism Mummy Club"}
        </SectionTitle>
        <Desc>Earn ${TXT[chainId]?.wrapToken} from platform's collected fees with your NFT</Desc>
        {chainId !== FANTOM && chainId !== MUMBAI && (
          <Steps>
            <div className="referral-step">
              <div className="referral-step-line">
                <div className="line"></div>
                <div className="step-box">
                  <div className="step-number">1</div>
                  <div className="step-item">Get NFT</div>
                </div>
                <div className="step-box">
                  <div className="step-number">2</div>
                  <div className="step-item">Stake NFT</div>
                </div>
                <div className="step-box">
                  <div className="step-number">3</div>
                  <div className="step-item">Earn reward</div>
                </div>
              </div>
            </div>
          </Steps>
        )}

        <NFTWrap>{renderStats()}</NFTWrap>
        <ListNFTWrap>
          <ListNFTHead>
            <div className="amount-nft">
              <div className="amount-box">
                <div>
                  Your NFT: <span>{listMyNFT?.length || "--"}</span>
                </div>
                <div>
                  Your staked NFT: <span>{yourStakedNFT || "--"}</span>
                </div>
              </div>
              {listMyNFT?.length > 0 && (
                <Link className="link-mint-more" to="/nft?min=true">
                  Mint more NFT <img src={isLightTheme ? iconLinkLight : iconLink} alt="img" />{" "}
                </Link>
              )}
            </div>
            <div className="head-right">
              {listMyNFT.length > 0 && (
                <div className="txt-select">
                  <div>Selected: {choosenNFT?.length}</div>
                  <div className="txt-green" onClick={handleSelect}>
                    Select All
                  </div>
                  <div className="txt-green" onClick={() => handleSelect("remove")}>
                    Deselect All
                  </div>
                </div>
              )}
              {!isApprovedNFT || isEmpty(listMyNFT) ? null : (
                <div className="action-nft">
                  {
                    <button
                      className="btn-primary"
                      onClick={() => stakeNFT(listIDNoStake)}
                      disabled={chosenStakeCountArr?.length < 1}
                    >
                      Stake ({chosenStakeCountArr?.length})
                    </button>
                  }
                  <button
                    className="border-btn btn-outline"
                    onClick={() => unStakeNFT(listIDStaked)}
                    disabled={chosenUnStakeCount?.length < 1}
                  >
                    Unstake ({chosenUnStakeCount?.length})
                  </button>
                </div>
              )}{" "}
            </div>
          </ListNFTHead>
          {isEmpty(listMyNFT) ? (
            <EmptyContent>
              <img src={TXT[chainId]?.noNFTImg} className="img-avt" alt="img" />
              <div className="content">
                <div className="txt-asset">You don't have any Mummy NFT</div>

                <Link
                  onClick={() => {
                    setIsVisible(true);
                  }}
                  to={`/nft`}
                  className="txt-link"
                >
                  Get Your Mummy NFT <img src={isLightTheme ? icLinkLight : icLink} alt="" />
                </Link>
              </div>
            </EmptyContent>
          ) : (
            <ListNFT>
              {listMyNFT.map((item) => {
                return (
                  <Item className={`${choosenNFT.includes(item.toString()) ? "item--active" : ""}`}>
                    <div className="wrap-item">
                      <div
                        className={`${
                          choosenNFT.includes(item.toString()) ? "checkbox-wrap checkbox-wrap--active" : "checkbox-wrap"
                        }`}
                      >
                        <div className="checkbox-bg" onClick={() => handleChange(item.toString())}></div>
                        {choosenNFT.includes(item.toString()) ? (
                          <img
                            onClick={() => handleChange(item.toString())}
                            src={isLightTheme ? icCheckboxActiveLight : icCheckboxActive}
                            alt="img"
                          />
                        ) : (
                          <img
                            src={isLightTheme ? icCheckboxLight : icCheckbox}
                            alt="img"
                            onClick={() => handleChange(item.toString())}
                          />
                        )}
                      </div>

                      <img className="nft-avt" alt="mint" src={`${getLinkCDN(chainId, true)}${item[0]}.png`} />
                      <div className="nft-profile">
                        <div className="nft-name">Mummy #{item[0]}</div>
                        <div className="nft-power">
                          <img alt="img" src={icFlash} />
                          <span>{item[1] ? formatNumber(item[1]) : "--"}</span>{" "}
                        </div>
                      </div>

                      {renderButton(item[0], item[2])}
                    </div>
                  </Item>
                );
              })}
            </ListNFT>
          )}
        </ListNFTWrap>
      </div>
    </StyledHowTo>
  );
}
const ListNFTWrap = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 24px;
  padding: 24px;
  margin-bottom: 144px;
  @media screen and (max-width: 991px) {
    margin-bottom: 112px;
  }
  @media screen and (max-width: 700px) {
    margin-bottom: 82px;
    padding: 16px;
    border: none;
    padding: 0;
  }
`;
const StakePower = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .est-apr {
    margin-top: 8px;
    display: flex;
    align-items: center;
  }
  .label {
    margin-bottom: 16px;
  }
  @media screen and (max-width: 700px) {
    padding: 24px !important;
  }
`;
const Reward = styled.div`
  > div {
    display: flex;
    justify-content: space-between;
    &:first-child {
      margin-bottom: 18px;
    }
  }
  .amount {
    font-size: 16px;
    span {
      margin-right: 4px;
    }
  }
  .btn-claim {
    height: 40px;
    width: 100%;
    margin-top: 16px;
  }
`;

const Steps = styled.div`
  margin-bottom: 48px;
  @media screen and (max-width: 700px) {
    margin-bottom: 32px;
  }
  .referral-step-line {
    max-width: 424px;
    margin-left: auto;
    margin-right: auto;
    font-weight: 400;
    font-size: 14px;
    display: grid;
    grid-template-columns: repeat(3, 84px);
    gap: 84px;
    position: relative;

    .step-box {
      display: flex;
      flex-direction: column;

      align-items: center;
      text-align: center;
      .step-item {
        max-width: 109px;
      }
    }
    .step-number {
      position: relative;
      z-index: 3;
      background: #09091a;
      border: 2px solid #03f5ae;
      border-radius: 50%;
      width: 36px;
      height: 36px;
      margin-bottom: 4px;
      font-size: 16px;
      line-height: 140%;
      color: #03f5ae;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .line {
      top: 18px;
      position: absolute;
      background: #03f5ae;
      height: 3px;
      width: 100%;
      z-index: 1;
    }
    /* @media screen and (max-width: 1200px) {
      grid-template-columns: 210px auto 210px;
    } */
    @media screen and (max-width: 700px) {
      /* .line {
        top: 30px;
      } */
      gap: 63px;
      grid-template-columns: 1fr 1fr 1fr;
      img {
        width: 64px;
        height: 64px;
      }
      .step-item {
        font-size: 12px;
      }
    }
  }
  .referral-step {
    text-align: center;
    margin-top: 48px;
    font-weight: 500;
    font-size: 24px;
    // line-height: 150%;
    &-desc {
      font-weight: 400;
      font-size: 14px;
      margin: 8px 0 16px 0;
      color: rgba(255, 255, 255, 0.6);
    }
    @media screen and (max-width: 700px) {
      margin-top: 32px;
      font-size: 16px;
      &-desc {
        font-size: 12px;
      }
    }
  }
`;

const EmptyContent = styled.div`
  .txt-link {
    justify-content: center;
  }
  .img-avt {
    filter: grayscale(1);
    margin-bottom: 16px;
    @media screen and (max-width: 700px) {
      width: 200px;
    }
  }

  a {
    display: flex;
    align-items: center;
    color: #03f5ae;
    margin-top: 8px;
    img {
      margin-left: 4px;
    }
  }
  text-align: center;
  border-radius: 24px;

  width: 100%;
  padding: 24px;
  height: 472px;
  margin-bottom: 82px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 700px) {
    height: fit-content;
    padding: 16px;
  }
`;
export default StakeNWFTMain;

const ListNFTHead = styled.div`
  .action-nft {
    display: flex;
    .btn-outline {
      margin-left: 16px;
    }
  }

  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  .amount-nft {
    .amount-box {
      display: flex;
      > div {
        &:first-child {
          margin-right: 24px;
        }
      }
    }
    font-weight: 400;
    font-size: 14px;

    a {
      font-weight: 500;
      font-size: 14px;
      color: #03f5ae;
      display: flex;
      align-items: center;
      margin-top: 8px;
      img {
        margin-left: 4px;
      }
    }
    span {
      margin-left: 4px;
      font-weight: 700;
      font-size: 16px;
    }
  }

  button {
    padding: 8px 16px;
    height: 40px;
  }
  @media screen and (max-width: 991px) {
    flex-direction: column;
    align-items: flex-start;
    .txt-select {
      > div:first-child {
        margin-left: 0;
      }
    }
  }

  @media screen and (max-width: 700px) {
    margin-bottom: 16px;
    padding: 16px 0 0 16px;
    .head-right {
      flex-direction: column;
      width: 100%;

      align-items: flex-start;
      .txt-select {
        margin-top: 8px;
      }
    }

    .action-nft {
      width: 100%;
      margin-top: 16px;
      > button {
        width: calc(50% - 4px);
        .btn-outline {
          margin-right: 8px;
        }
      }
    }
    .action-stake-only {
      button {
        width: 100%;
      }
    }
  }
`;

const ListNFT = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 12px;

  @media screen and (max-width: 991px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media screen and (max-width: 700px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }
`;
const NFTWrap = styled.div`
  .label {
    color: #fff;
  }
  > div {
    max-height: 164px;
  }
  .amount {
    font-weight: 700;
    font-size: 16px;
    display: flex;
    align-items: center;
    &-highlight {
      color: #03f5ae;
      margin-right: 4px;
    }

    &--large {
      font-weight: 700;
      font-size: 48px;
      line-height: 120%;
      justify-content: center;
      @media screen and (max-width: 700px) {
        font-size: 36px;
      }
    }
    img {
      width: 20px;
      margin-right: 4px;
      @media screen and (max-width: 700px) {
        width: 16px;
      }
    }
  }

  margin-bottom: 48px;

  display: grid;
  grid-template-columns: auto 256px 256px;
  gap: 24px;
  @media screen and (max-width: 1099px) {
    display: flex;

    flex-wrap: wrap;
    .epoch-stats {
      width: 100%;
    }
    .stats-box {
      width: calc(50% - 12px);
    }
  }
  /* @media screen and (max-width: 991px) {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
  } */
  @media screen and (max-width: 700px) {
    gap: 16px;
    padding: 0;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 32px;
    .epoch-stats {
      padding: 16px;
    }
    .stats-box {
      width: 100%;
      padding: 16px;
    }
  }
  .actions {
    display: flex;
    a {
      padding: 8px 16px;
      height: 40px;
      text-decoration: none;
      img {
        margin-right: 4px;
      }
    }
    a:first-child {
      margin-right: 12px;
    }
    @media screen and (max-width: 991px) {
      margin-top: 16px;
    }
    @media screen and (max-width: 700px) {
      flex-direction: column;
      width: 100%;
      a {
        width: 100%;
      }
      a:first-child {
        margin-right: 0;
        margin-bottom: 12px;
      }
    }
  }
  .nft-avt-box {
    display: flex;
    align-items: center;
    img {
      width: 120px;
      height: 120px;
      margin-right: 24px;
    }
    .total-nft {
      margin-right: 32px;
    }
    .label {
      font-weight: 400;
      font-size: 14px;

      margin-bottom: 4px;
    }
    .amount {
      font-weight: 700;
      font-size: 36px;
    }
    @media screen and (max-width: 700px) {
      width: 100%;
      .amount {
        font-size: 24px;
      }
      .total-nft {
        margin-right: 4px;
        flex: 1;
      }
      .avt-wrapper img {
        width: 80px;
        height: 80px;
        margin-right: 16px;
      }
      /* flex-wrap: wrap;
      .avt-wrapper {
        width: 100%;
        margin-bottom: 8px;
        display: flex;
      }
      .total-nft {
        width: calc(50% - 16px);
      } */
    }
  }
`;
const SectionTitle = styled.div`
  font-weight: 700;
  font-size: 36px;
  line-height: 140%;
  text-align: center;
  margin-top: 50px;
  @media screen and (max-width: 700px) {
    font-size: 22px;
    padding: 0;
    margin-top: 24px;
  }
`;

const Desc = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  margin: 4px 0 48px 0;
  text-align: center;
  @media screen and (max-width: 700px) {
    font-size: 12px;
    margin-bottom: 32px;
  }
`;
const Item = styled.div`
  .checkbox-bg {
    width: 0;
    height: 0;
    border-top: 36px solid transparent;
    border-bottom: 36px solid transparent;
    transform: rotate(45deg);
    border-right: 36px solid #262852;
  }
  position: relative;
  overflow: hidden;
  &.item--active {
    border: 1px solid #03f5ae;
    .checkbox-bg {
      border-right: 36px solid #239674;
    }
  }
  .checkbox-wrap {
    position: absolute;
    top: -24px;
    left: -5px;
    img {
      width: 16px;
      cursor: pointer;
      position: relative;
      top: -42px;
      right: -12px;
    }
  }

  .nft-profile {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .btn-outline,
  .btn-primary {
    width: 100%;
    height: 40px;
    margin-top: 12px;
  }
  background: #1e1f35;
  border-radius: 16px;
  padding: 16px;
  img {
    width: 100%;
    max-height: 168px;
    margin-bottom: 12px;
    @media screen and (max-width: 700px) {
      height: auto;
    }
  }
  .nft-name {
    font-weight: 500;
  }
  .nft-power {
    display: flex;

    align-items: center;
    img {
      width: 16px;
      margin-bottom: 0;
    }
    font-size: 12px;
    span {
      color: #ffffff;
    }
  }
`;

const EpochStats = styled.div`
  gap: 20px 16px;
  padding: 24px;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1);

  border-radius: 24px;
  background-image: url(${bgLarge});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  div.amount {
    font-size: 14px;
  }
  @media (max-width: 700px) {
    grid-template-columns: 1fr;
    max-height: unset !important;
    .current-epoch {
      order: 1;
    }
    .next-epoch {
      order: 2;
    }
    .stake-nft {
      order: 3;
    }
    .total-reward {
      order: 5;
    }
    .apr {
      order: 4;
    }
    .next-epoch-reward {
      order: 6;
    }

    > div {
      display: flex;
      justify-content: space-between;
      .label {
        margin-bottom: 0;
        font-size: 12px;
      }
      .amount {
        font-size: 14px;
      }
    }
  }
  .label {
    color: #fff;
    margin-bottom: 8px;
  }

  &.theme--light {
    .label {
      color: var(--Text-Text_Primary, #0d1a16);
    }
  }
`;

const StyledHowTo = styled.section`
  .txt-green {
    color: #03f5ae;
    cursor: pointer;
  }
  .head-right {
    display: flex;
    align-items: center;
  }
  .txt-select {
    margin-right: 32px;
    display: flex;
    > div {
      margin-left: 12px;
    }
  }
  .time-countdown {
    display: flex;
    .space {
      margin: 0 4px;
    }
  }
  .nft-avt {
    border-radius: 16px;
  }
  color: #ffffff;
  a {
    text-decoration: none;
  }

  .stats-box {
    padding: 24px;
    background: rgba(3, 245, 174, 0.2);
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1);

    backdrop-filter: blur(35px);

    border-radius: 24px;
    background-image: url(${bg});
    background-repeat: no-repeat;
    background-size: 100% 103%;
    @media screen and (max-width: 900px){
      box-shadow: none;
    }
  }

  &.theme--light {
    .stats-box {
      box-shadow: 0 0 0 2px rgba(13, 26, 22, 0.07);
    }

    ${Item} {
      border: 1px solid var(--Border, rgba(13, 26, 22, 0.07));
      background: var(--Nature-1, #fff);

      &.item-active {
        border: 1px solid var(--Primary, #02b27f);
      }
    }

    ${EpochStats} {
      box-shadow: 0 0 0 2px rgba(13, 26, 22, 0.07);
    }

    ${ListNFTWrap} {
      border: 1px solid var(--Border, rgba(13, 26, 22, 0.07));

      .link-mint-more {
        color: var(--Primary, #02b27f);
      }
    }

    ${StakePower} {
      .label {
        color: var(--Text-Text_Primary, #0d1a16);
      }
    }

    ${Reward} {
      .label {
        color: var(--Text-Text_Primary, #0d1a16);
      }
    }

    .checkbox-wrap {
      .checkbox-bg {
        border-right: 36px solid #e6ebe9;
      }

      &.checkbox-wrap--active {
        .checkbox-bg {
          border-right: 36px solid #239674;
        }
      }
    }

    .amount-highlight,
    .txt-green,
    .txt-link {
      color: var(--Primary, #02b27f);
    }

    .line {
      background: var(--Primary, #02b27f);
    }

    .step-number {
      background: var(--Nature-2, #f2f5f7);
      border-color: var(--Primary, #02b27f);
      color: var(--Primary, #02b27f);
    }
  }
`;
