import React from "react";
import styled from "styled-components";

import PARTNERS from "../configs";
import ExternalLink from "components/ExternalLink/ExternalLink";

const Partners = () => {
  return (
    <Wrapper className="container">
      <Title>Partners</Title>
      <Content>
        {PARTNERS.map((partner) => (
          <ExternalLink key={partner.link} href={partner.link} className="partner-wrapper">
            <img src={partner.img} alt="partner-logo" />
          </ExternalLink>
        ))}
      </Content>
    </Wrapper>
  );
};

const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(4, 1fr);
  row-gap: 32px;

  .partner-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 350ms;
    cursor: pointer;

    &:hover {
      scale: 1.2;
    }
  }

  @media (max-width: 1024px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .partner-wrapper {
      scale: 0.7;
      width: 18%;

      img {
        width: 100%;
      }
    }
  }

  @media (max-width: 767px) {
    gap: 16px;
    justify-content: center;
    .partner-wrapper {
      width: 45%;
    }
  }
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 56px;
  line-height: 140%;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin-bottom: 36px;
  color: #fff !important;
  @media (max-width: 1024px) {
    font-size: 40px;
  }
`;

const Wrapper = styled.div`
  margin-bottom: 120px;
  padding: 60px 24px 0px;

  @media (max-width: 1024px) {
    padding: 60px 24px 0px;
  }

  @media (max-width: 767px) {
    padding: 60px 16px 0px;
  }
`;

export default Partners;
