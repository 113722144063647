import HCaptcha from "@hcaptcha/react-hcaptcha";
import { useWeb3React } from "@web3-react/core";
import axios from "axios";
import ExternalLink from "components/ExternalLink/ExternalLink";
import Footer from "components/Footer/Footer";
import { FANTOM, MUMBAI, getExplorerUrl } from "config/chains";
import bg from "img/earn/bg.svg";
import icon from "img/vote/icon.svg";
import itemIcon from "img/vote/item-icon.svg";
import { useChainId } from "lib/chains";
import { helperToast } from "lib/helperToast";
import { switchNetwork } from "lib/wallets";
import { useState } from "react";
import styled from "styled-components";
import HowTo from "./Howto";
import "./style.scss";
const VoteGitcoin = ({ setPendingTxns, pendingTxns, connectWallet }) => {
  const { chainId } = useChainId();
  const [token, setToken] = useState(null);
  const { active, account } = useWeb3React();

  const [isLoading, setLoading] = useState(false);

  const siteKey = "df6d16a7-beed-4ab6-9f7f-22674aa1d631";

  const list = [
    {
      item: <div>Each IP address can claim GcV once. GcV is a valueless token.</div>,
    },
    {
      item: (
        <div>
          Please use the GcV you've claimed to vote for Mummy Finance in Fantom’s Ecosystem Vault funding round on
          Gitcoin.
        </div>
      ),
    },
    {
      item: (
        <div>
          All the rewards from the Fantom’s Ecosystem Vault funding round will be used to mint Mummy Rebirth, aiming to
          boost both liquidity for trading on Fantom and rewards for Mummy Rebirth stakers.
        </div>
      ),
    },
    {
      item: (
        <div>
          Voting period: <span className="txt-bold">Wed, 30 Aug 2023 00:00:00 UTC - Wed, 20 Sep 2023 00:00:00 UTC</span>
        </div>
      ),
    },
  ];

  const onFaucet = async (address, captcha) => {
    try {
      setLoading(true);

      const source = axios.CancelToken.source();
      const apiLink = `https://faucet.mummy.finance/faucet?address=${address}&captcha=${captcha}`;
      const res = await axios.get(apiLink, {
        cancelToken: source.token,
      });
      setLoading(false);

      if (res?.data?.status === 200) {
        const txUrl = getExplorerUrl(chainId) + "tx/" + res?.data?.tx;
        helperToast.success(
          <div>
            Claimed successfully!{" "}
            <ExternalLink href={txUrl}>
              <span>View</span>
            </ExternalLink>
            <br />
          </div>
        );
      } else {
        helperToast.error(res?.data?.message || "Error!");
      }
    } catch (error) {
      setLoading(false);
      if (axios.isCancel(error)) {
      } else {
        throw error;
      }
    }
  };

  const handleFaucet = () => {
    onFaucet(account, token);
  };

  const renderBtn = () => {
    if (!active)
      return (
        <button className="default-btn" onClick={() => connectWallet()}>
          Connect Wallet
        </button>
      );

    if (chainId !== FANTOM)
      return (
        <button className="default-btn" onClick={() => switchNetwork(FANTOM, active)}>
          Switch to Fantom
        </button>
      );
    if (!token)
      return (
        <button disabled className="default-btn">
          Verify the captcha first, then claim.
        </button>
      );
    return (
      <button onClick={handleFaucet} className="default-btn" disabled={isLoading}>
        {isLoading ? "Claiming..." : "Claim"}
      </button>
    );
  };

  const handleVerificationSuccess = (token) => {
    setToken(token);
  };
  return (
    <Wrapper>
      <div className="wrapper">
        <div className="title">
          Claiming GcV (GitcoinVote)
          <img src={icon} alt="icon" />
        </div>
        <div className="content">
          {list.map((child) => (
            <Item key={child.item}>
              {" "}
              <img src={itemIcon} alt="item" /> {child.item}
            </Item>
          ))}
          <CapchaContainer>
            <HCaptcha theme="dark" sitekey={siteKey} onVerify={handleVerificationSuccess} />
          </CapchaContainer>

          {renderBtn()}
        </div>
        <HowTo />
      </div>{" "}
      <Footer />
    </Wrapper>
  );
};
export default VoteGitcoin;
const CapchaContainer = styled.div`
  margin-top: 24px;
`;
const Wrapper = styled.div`
  .Footer-wrapper {
    bottom: unset;
    position: unset;
    transform: unset;
  }
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-size: contain;
  .wrapper {
    padding-left: 24px;
    padding-right: 24px;
    max-width: 100%;
    width: 1144px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 64px;
    @media screen and (max-width: 700px) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
  .content {
    margin-bottom: 64px;
  }

  .default-btn {
    width: 380px;
    margin-top: 24px;
    max-width: 100%;
  }

  .title {
    display: flex;
    align-items: flex-start;
    margin-bottom: 24px;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    img {
      margin-left: 8px;
    }
  }
`;
const Item = styled.div`
  .txt-bold {
    font-weight: 700;
  }
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  display: flex;
  align-items: flex-start;
  img {
    margin-right: 8px;
  }
  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;
