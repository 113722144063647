import React, { memo } from "react";

const Icon1 = (props) => (
  <svg
    width="42"
    height="40"
    viewBox="0 0 42 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={props.class}
  >
    <mask id="mask0_207_9765" maskUnits="userSpaceOnUse" x="0" y="0" width="41" height="40">
      <path d="M40.8359 0H0.835938V40H40.8359V0Z" fill="white" />
    </mask>
    <g mask="url(#mask0_207_9765)">
      <mask id="mask1_207_9765" maskUnits="userSpaceOnUse" x="3" y="3" width="35" height="35">
        <path d="M37.7565 3.57886H3.58203V37.7533H37.7565V3.57886Z" fill="white" />
      </mask>
      <g mask="url(#mask1_207_9765)">
        <path
          d="M27.0324 13.8352V7.93123C27.0324 5.5315 25.0798 3.57886 22.6798 3.57886H7.93415C5.53442 3.57886 3.58203 5.5315 3.58203 7.93123V17.7907C3.58203 19.8463 5.0142 21.5734 6.93292 22.0271V24.4934C6.93292 24.8983 7.17702 25.2633 7.55114 25.4184C7.67494 25.4698 7.80506 25.4946 7.93415 25.4946C8.19465 25.4946 8.45061 25.3929 8.64233 25.2012L11.7002 22.1434H12.304V20.19C12.304 16.6858 15.1545 13.8352 18.6585 13.8352H27.0324Z"
          fill="#296eff"
        />
        <path
          d="M33.4063 15.8372H18.6607C16.261 15.8372 14.3086 17.7895 14.3086 20.1895V30.0491C14.3086 32.4491 16.261 34.4015 18.6607 34.4015H29.6404L32.6982 37.4596C32.8899 37.6512 33.1459 37.7529 33.4066 37.7529C33.5354 37.7529 33.6655 37.7281 33.7893 37.6768C34.1635 37.5216 34.4075 37.1566 34.4075 36.7517V34.2855C36.3265 33.832 37.7587 32.1047 37.7587 30.0491V20.1895C37.7587 17.7895 35.8061 15.8372 33.4063 15.8372ZM30.2961 23.4576L25.5565 28.1971C25.361 28.3927 25.1047 28.4902 24.8487 28.4902C24.5924 28.4902 24.3361 28.3927 24.1408 28.1971L21.7708 25.8273C21.3796 25.4365 21.3796 24.8024 21.7708 24.4113C22.1615 24.0205 22.7957 24.0205 23.1865 24.4113L24.8487 26.0732L28.8801 22.0415C29.2712 21.6507 29.905 21.6507 30.2961 22.0415C30.6872 22.4327 30.6872 23.0667 30.2961 23.4576Z"
          fill={props.fill ?? "white"}
        />
      </g>
    </g>
  </svg>
);
export default memo(Icon1);
