const RedirectIcon = (props: JSX.IntrinsicElements["svg"]) => {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.7781 1.66675C12.34 1.66675 11.9845 2.0223 11.9845 2.4604C11.9845 2.89849 12.34 3.25405 12.7781 3.25405H15.6241L9.04237 9.83577C8.73244 10.1457 8.73244 10.6481 9.04237 10.958C9.19752 11.1132 9.40033 11.1906 9.6035 11.1906C9.80668 11.1906 10.0095 11.1132 10.1646 10.958L16.7464 4.37632V7.2223C16.7464 7.6604 17.1019 8.01595 17.54 8.01595C17.9781 8.01595 18.3337 7.6604 18.3337 7.2223V2.4604C18.3337 2.0223 17.9781 1.66675 17.54 1.66675H12.7781ZM4.04794 2.4604C2.73485 2.4604 1.66699 3.52826 1.66699 4.84135V15.9525C1.66699 17.2656 2.73485 18.3334 4.04794 18.3334H15.1591C16.4722 18.3334 17.54 17.2656 17.54 15.9525V11.1906C17.54 10.7525 17.1845 10.3969 16.7464 10.3969C16.3083 10.3969 15.9527 10.7525 15.9527 11.1906V15.9525C15.9527 16.3902 15.5968 16.7461 15.1591 16.7461H4.04794C3.61025 16.7461 3.25429 16.3902 3.25429 15.9525V4.84135C3.25429 4.40365 3.61025 4.0477 4.04794 4.0477H8.80985C9.24795 4.0477 9.6035 3.69215 9.6035 3.25405C9.6035 2.81595 9.24795 2.4604 8.80985 2.4604H4.04794Z"
        fill={props.color || "rgb(3, 245, 174)"}
      />
    </svg>
  );
};

export default RedirectIcon;
