import ExternalLink from "components/ExternalLink/ExternalLink";
import icLink from "img/icons/link-green.svg";
import styled from "styled-components";
export default function HowTo({ isVisible, setIsVisible }) {
  const Steps = [
    {
      name: "1",
      title: "Claim GcV",
      content: "Click the “Claim” button to claim your GcV. Each IP address can only claim once",
      link: "",
      linkName: "",
    },
    {
      name: "2",
      title: "Create your Gitcoin passport",
      content: (
        <div>
          <div>- Access Gitcoin Passport</div>
          <div>- Connect your wallet</div>
          <div>- Link different accounts to collect and verify your stamps</div>
        </div>
      ),
      link: "https://passport.gitcoin.co/",
      linkName: "Go to Gitcoin passport",
      external: false,
    },
    {
      name: "3",
      title: " Vote for Mummy Finance",
      content: (
        <div>
          <div>- Go to Mummy Finance on Gitcoin</div>
          <div>- Add Mummy Finance to your cart by clicking ‘Add to cart’</div>
          <div>- Go to your cart</div>
          <div>- Input your GcV amount</div>
          <div>- Click on ‘Submit your donation!’</div>
        </div>
      ),
      link: "https://explorer.gitcoin.co/#/round/250/0x8dce7a66e0c310f9f89e847dba83b2344d589161/0x8dce7a66e0c310f9f89e847dba83b2344d589161-9",
      linkName: "Go to Vote",
      external: false,
    },
  ];
  return (
    <StyledHowTo>
      <SectionTitle>How to vote?</SectionTitle>

      <HowToWrap>
        {Steps.map((item, idx) => (
          <Item key={item.name}>
            <div className="step-number">{idx + 1}</div>
            <div className="step-content">
              <div className="label">{item.title}</div>
              <div className="content">{item.content}</div>
              {item.linkName && (
                <ExternalLink href={item.link} className="howto-link">
                  {" "}
                  {item.linkName} <img alt="mint" src={icLink} />{" "}
                </ExternalLink>
              )}
            </div>
          </Item>
        ))}
      </HowToWrap>
    </StyledHowTo>
  );
}

// export default HowTo;

const StyledHowTo = styled.section`
  color: #ffffff;
  margin-bottom: 120px;
`;

const SectionTitle = styled.div`
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  margin-bottom: 24px;

  /* @media screen and (max-width: 700px) {
    font-size: 24px;
    padding: 0 24px;
  } */
`;

const Item = styled.div`
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 24px;
  padding: 24px;
  display: flex;
  @media screen and (max-width: 700px) {
    padding: 16px;
  }
  .step-number {
    background: linear-gradient(90deg, #03f5ae 0%, #e3fff7 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-weight: 700;
    font-size: 64px;
    line-height: 100% !important;
    margin-right: 24px;
  }
  .step-content {
    .label {
      font-weight: 700;
      font-size: 16px;
      line-height: 140%;
      color: #fff;
    }
    .content {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: rgba(255, 255, 255, 0.6);
      margin: 9px 0;
    }
    .howto-link {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: #03f5ae;
      text-decoration: none;
      img {
        margin-left: 8px;
      }
    }
    @media screen and (max-width: 700px) {
      .howto-link {
        img {
          margin-left: 4px;
        }
      }
    }
  }

  @media screen and (max-width: 700px) {
    .step-number {
      font-size: 48px;
    }
  }
`;

const HowToWrap = styled.div`
  display: grid;

  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  @media screen and (max-width: 991px) {
    grid-template-columns: 100%;
  }
  @media screen and (max-width: 700px) {
    gap: 16px;
  }
`;
