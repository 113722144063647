const LinkIcon = (props: JSX.IntrinsicElements["svg"]) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g opacity="0.6">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.16825 20.9289C8.73433 21.3628 8.73433 22.0664 9.16825 22.5003C9.60216 22.9342 10.3057 22.9342 10.7396 22.5003L19.8422 13.3976L19.8423 20.1433C19.8422 20.7569 20.3397 21.2544 20.9534 21.2544C21.567 21.2544 22.0645 20.7569 22.0645 20.1433L22.0645 10.7152C22.0645 10.4205 21.9474 10.1379 21.739 9.92949C21.5307 9.72112 21.248 9.60405 20.9534 9.60406L11.5253 9.60406C10.9116 9.60406 10.4142 10.1015 10.4142 10.7152C10.4142 11.3288 10.9116 11.8263 11.5253 11.8263L18.2709 11.8263L9.16825 20.9289Z"
          fill={props.fill || "white"}
        />
      </g>
    </svg>
  );
};

export default LinkIcon;
