import React, { memo } from "react";

const UpArrowIcon = (props) => (
  <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M0.441758 4.8772L7.56676 4.8772C7.63802 4.87592 7.70747 4.8545 7.76706 4.8154C7.82666 4.77631 7.87397 4.72114 7.90352 4.65628C7.93307 4.59142 7.94366 4.51952 7.93406 4.4489C7.92446 4.37827 7.89506 4.31181 7.84926 4.2572L4.28676 0.164698C4.25134 0.124737 4.20784 0.0927458 4.15915 0.0708361C4.11045 0.0489264 4.05766 0.0375977 4.00426 0.0375977C3.95086 0.0375977 3.89807 0.0489264 3.84937 0.0708361C3.80067 0.0927458 3.75718 0.124737 3.72176 0.164698L0.159258 4.2572C0.113461 4.31181 0.0840616 4.37827 0.0744591 4.4489C0.0648565 4.51952 0.0754433 4.59142 0.104996 4.65628C0.134548 4.72114 0.181859 4.77631 0.241454 4.8154C0.30105 4.8545 0.370495 4.87592 0.441758 4.8772Z"
      fill={props.fill || "white"}
    />
  </svg>
);
export default memo(UpArrowIcon);
