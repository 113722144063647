import { ethers } from "ethers";

import { getContract } from "config/contracts";
import { formatMulticallReponse } from "lib/contracts";

import SettingsManager from "abis/SettingsManager.json";
import { PLACEHOLDER_ACCOUNT } from "lib/legacy";
import { useEffect, useState } from "react";
import { getProvider } from "lib/rpc";
import multicall from "domain/multicall";

import Multicall from "abis/Multicall.json";

export default function useSettingsManagerContract(
  chainId: number,
  active: boolean,
  account: string | undefined | null,
  library?: any
) {
  const settingsManagerAddress = getContract(chainId, "SettingsManager");
  const usdcAddress = getContract(chainId, "USDC");
  const [data, setData] = useState({});
  const multicallAddress = getContract(chainId, "Multicall");

  useEffect(() => {
    const provider = getProvider(library, chainId);
    const multicallContract = new ethers.Contract(multicallAddress, Multicall.abi, provider);

    const fetchNoAccount = async () => {
      if (!account && provider) {
        const provider = getProvider(library, chainId);
        const multicallContract = new ethers.Contract(multicallAddress, Multicall.abi, provider);
        const calls = [
          {
            address: settingsManagerAddress,
            name: "stakingFee",
            params: [usdcAddress],
          },
          {
            address: settingsManagerAddress,
            name: "unstakingFee",
            params: [usdcAddress],
          },
          {
            address: settingsManagerAddress,
            name: "maxTotalNlp",
            params: [],
          },
          {
            address: settingsManagerAddress,
            name: "isStakingEnabled",
            params: [usdcAddress],
          },
          {
            address: settingsManagerAddress,
            name: "isUnstakingEnabled",
            params: [usdcAddress],
          },
          {
            address: settingsManagerAddress,
            name: "getReferFee",
            params: [PLACEHOLDER_ACCOUNT],
          },
        ];

        const keyOfCalls = calls.map((call) => call.name);

        try {
          const response = await multicall(
            multicallContract,
            SettingsManager.abi,
            calls.map(({ address, name, params }) => ({
              address,
              name,
              params,
            }))
          );
          const result = {};
          for (const index in keyOfCalls) {
            result[keyOfCalls[index]] = response[index];
          }
          setData((prev) => formatMulticallReponse({ ...prev, ...result }));
        } catch (error) {}
      }
    };

    const fetch = async () => {
      if (account && provider) {
        const calls = [
          {
            address: settingsManagerAddress,
            name: "stakingFee",
            params: [usdcAddress],
          },
          {
            address: settingsManagerAddress,
            name: "unstakingFee",
            params: [usdcAddress],
          },
          {
            address: settingsManagerAddress,
            name: "maxTotalNlp",
            params: [],
          },
          {
            address: settingsManagerAddress,
            name: "isStakingEnabled",
            params: [usdcAddress],
          },
          {
            address: settingsManagerAddress,
            name: "isUnstakingEnabled",
            params: [usdcAddress],
          },
          {
            address: settingsManagerAddress,
            name: "getReferFee",
            params: [account],
          },
        ];

        const keyOfCalls = calls.map((call) => call.name);

        try {
          const response = await multicall(
            multicallContract,
            SettingsManager.abi,
            calls.map(({ address, name, params }) => ({
              address,
              name,
              params,
            }))
          );
          const result = {};
          for (const index in keyOfCalls) {
            result[keyOfCalls[index]] = response[index];
          }
          setData((prev) => formatMulticallReponse({ ...prev, ...result }));
        } catch (err) {}
      }
    };

    fetchNoAccount();
    fetch();
    const timerId = setInterval(() => {
      fetchNoAccount();
      fetch();
    }, 10000);

    return () => {
      clearInterval(timerId);
    };
  }, [account, chainId, library, multicallAddress, settingsManagerAddress, usdcAddress]);

  return {
    stakingFee: data["stakingFee"],
    unstakingFee: data["unstakingFee"],
    maxTotalNlp: data["maxTotalNlp"],
    isStakingEnabled: data["isStakingEnabled"],
    isUnstakingEnabled: data["isUnstakingEnabled"],
    getReferFee: data["getReferFee"],
  };
}
