import { ARBITRUM, BASE, FANTOM, MUMBAI, OP } from "config/chains";
import ftm from "img/tokens/ftm.svg";
import op from "img/ic_op_24.svg";
import eth from "img/ic_eth_24.svg";
import avt from "img/home/banner-avt.png";
import avt2 from "img/home/banner-avt2.png";
import bannerVector from "img/home/banner-vector.png";
import bannerVector2 from "img/home/banner-vector2.png";
import banner from "img/home/banner-bg.png";
import banner2 from "img/home/banner-bg2.png";
import mum1 from "img/home/mum1.png";
import mum2 from "img/home/mum2.png";
import icOP from "img/icOP.svg";
import icFantom from "img/icFantom.svg";
import icArb from "img/icARB.svg";
import arbitrumIcon from "img/ic_arbitrum_24.svg";
import baseIcon from "img/ic_base_24.svg";

import mum3 from "img/home/mum3.png";

import mum4 from "img/home/mum4.png";
import mum5 from "img/home/mum5.png";
import middleBanner from "img/home/banner-bg-small.png";

import middleBannerIpad from "img/home/banner-bg-small-ip.png";
import bannerMobile from "img/home/banner-bg-small-mobile.png";
import middleBanner2 from "img/home/banner-bg-small2.png";
import middleBannerIpad2 from "img/home/banner-bg-small-ip2.png";
import bannerMobile2 from "img/home/banner-bg-small-mobile2.png";

export const TXT = {
  [FANTOM]: {
    chainName: "Fantom",
    nativeToken: "FTM",
    nativeIcon: ftm,
    wrapToken: "WFTM",
    clubName: "Mummy Club",
    avt: avt,
    icon: ftm,
    bannerChain: bannerVector,
    bannerImg: banner,
    noNFTImg: mum1,
    middleBanner: middleBanner,
    middleBannerIP: middleBannerIpad,
    middleBannerMB: bannerMobile,
    logo1: mum1,
    logo2: mum2,
    chainLogoShare: icFantom,
  },
  [ARBITRUM]: {
    chainName: "ARBITRUM",
    nativeToken: "ETH",
    nativeIcon: eth,
    wrapToken: "WETH",
    clubName: "Mummy Club",
    avt: avt,
    icon: arbitrumIcon,
    bannerChain: bannerVector,
    bannerImg: banner,
    noNFTImg: mum1,
    middleBanner: middleBanner,
    middleBannerIP: middleBannerIpad,
    middleBannerMB: bannerMobile,
    logo1: mum1,
    logo2: mum2,
    chainLogoShare: arbitrumIcon,
  },
  [BASE]: {
    chainName: "BASE",
    nativeToken: "ETH",
    nativeIcon: eth,
    wrapToken: "WETH",
    clubName: "Mummy Club",
    avt: avt,
    icon: baseIcon,
    bannerChain: bannerVector,
    bannerImg: banner,
    noNFTImg: mum1,
    middleBanner: middleBanner,
    middleBannerIP: middleBannerIpad,
    middleBannerMB: bannerMobile,
    logo1: mum1,
    logo2: mum2,
    chainLogoShare: baseIcon,
  },
  [OP]: {
    chainName: "Optimism",
    nativeToken: "ETH",
    nativeIcon: eth,
    wrapToken: "WETH",
    clubName: "Mummy Club",
    avt: avt2,
    icon: op,
    bannerChain: bannerVector2,
    bannerImg: banner2,
    noNFTImg: mum3,
    middleBanner: middleBanner2,
    middleBannerIP: middleBannerIpad2,
    middleBannerMB: bannerMobile2,

    logo1: mum4,
    logo2: mum5,
    chainLogoShare: icOP,
  },
  [MUMBAI]: {
    chainName: "Fantom",
    nativeToken: "FTM",
    nativeIcon: ftm,
    wrapToken: "WFTM",
    clubName: "Mummy Club",
    avt: avt,
    icon: ftm,
    bannerChain: bannerVector,
    bannerImg: banner,
    noNFTImg: mum1,
    middleBanner: middleBanner,
    middleBannerIP: middleBannerIpad,
    middleBannerMB: bannerMobile,
    logo1: mum1,
    logo2: mum2,
    chainLogoShare: icFantom,
  },
};
