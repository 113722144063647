export const formatTimestamp2LocalDate = (date?: number | string | Date, format?: string) => {
  if (!date) return "";
  if (!format) format = "YYYY-MM-DD";
  const dateObj = new Date(date);

  const year = dateObj.getFullYear();

  if (year === 1 || year === 1970) return "";
  const day = `0${dateObj.getDate()}`.substr(-2);
  const month = `0${dateObj.getMonth() + 1}`.substr(-2);
  const hours = `0${dateObj.getHours()}`.substr(-2);
  const minutes = `0${dateObj.getMinutes()}`.substr(-2);
  const seconds = `0${dateObj.getSeconds()}`.substr(-2);
  let dateFormat = format.replace("YYYY", year.toString());
  dateFormat = dateFormat.replace("MM", month);
  dateFormat = dateFormat.replace("DD", day);
  dateFormat = dateFormat.replace("hh", hours);
  dateFormat = dateFormat.replace("mm", minutes);
  dateFormat = dateFormat.replace("ss", seconds);

  return dateFormat;
};

// Change GMT/UTC to another time zone
export function convertToTimeZone(
  gmtDateTime: Date,
  gmtHour: any,
  targetTimeZoneOffset: number,
  initFromValue?: Date,
) {
  const gmtDate = gmtDateTime || initFromValue;
  gmtDate.setUTCHours(gmtHour);

  // to another time zone
  const targetDate = new Date(gmtDate.getTime() + targetTimeZoneOffset * 60 * 60 * 1000);

  return targetDate;
}
