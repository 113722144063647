import {
  Bar,
  Label,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
  Line,
} from "recharts";
import ChartWrapper from "./ChartWrapper";

import {
  COLORS,
  LIGHT_COLORS,
  convertToPercents,
  tooltipFormatter,
  tooltipLabelFormatter,
  yaxisFormatter,
} from "lib/helper";
import { useChartViewState } from "hooks/useChartViewState";

const convertToPercentsHandler = (data) =>
  convertToPercents(data, { ignoreKeys: ["cumulative", "movingAverageAll"], totalKey: "all" });

export default function VolumeChart(props) {
  const { data, loading, chartHeight, yaxisWidth, isLightTheme } = props;

  const csvFields = [
    { key: "swap", name: "Swap" },
    { key: "margin", name: "Margin trading" },
    { key: "mint", name: "Mint MLP" },
    { key: "burn", name: "Burn MLP" },
    { key: "liquidation", name: "Liquidation" },
    { key: "cumulative", name: "Cumulative" },
  ];

  const controls = {
    convertToPercents: convertToPercentsHandler,
  };

  const { viewState, togglePercentView, formattedData, ...viewSettings } = useChartViewState({ controls, data });

  const CustomTooltip = (props) => {
    const { active, payload, label } = props;
    if (active && payload) {
      return (
        <div className="custom-tooltip">
          <div className="label-total">{tooltipLabelFormatter(label, payload)}</div>
          {payload.map((item, index) => {
            return (
              <div className="item-group" key={index}>
                <div style={{ color: item.color || item.stroke || item.fill || "white" }}>{item.name}: </div>
                <div style={{ color: item.color || item.stroke || item.fill || "white" }}>
                  {tooltipFormatter(item.value, item.name, item)} {item.unit || ""}
                </div>
              </div>
            );
          })}
        </div>
      );
    }
    return null;
  };
  const ChartColorForTheme = (index) => {
    const result = isLightTheme ? LIGHT_COLORS[index] : COLORS[index];
    return result;
  };
  return (
    <ChartWrapper
      title="Volume"
      loading={loading}
      csvFields={csvFields}
      data={formattedData}
      controls={controls}
      viewState={viewState}
      togglePercentView={togglePercentView}
    >
      <ResponsiveContainer width="100%" height={chartHeight}>
        <ComposedChart data={formattedData} syncId="syncA">
          <CartesianGrid strokeDasharray="1 1" />
          <XAxis dataKey="timestamp" tickFormatter={tooltipLabelFormatter} minTickGap={30} />
          <YAxis
            dataKey="all"
            interval="preserveStartEnd"
            tickCount={viewState.isPercentsView ? undefined : 7}
            tickFormatter={viewSettings.yaxisTickFormatter}
            width={yaxisWidth}
          />

          <YAxis
            dataKey="cumulative"
            orientation="right"
            yAxisId="right"
            tickFormatter={yaxisFormatter}
            width={yaxisWidth}
          />

          {/* <Tooltip
            formatter={tooltipFormatter}
            labelFormatter={tooltipLabelFormatter}
            contentStyle={{ textAlign: "left" }}
          /> */}
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Bar
            unit={viewSettings.itemsUnit}
            isAnimationActive={false}
            type="monotone"
            dataKey="swap"
            stackId="a"
            name="Swap"
            fill={ChartColorForTheme(0)}
          />
          <Bar
            unit={viewSettings.itemsUnit}
            isAnimationActive={false}
            type="monotone"
            dataKey="mint"
            stackId="a"
            name="Mint MLP"
            fill={ChartColorForTheme(1)}
          />
          <Bar
            unit={viewSettings.itemsUnit}
            isAnimationActive={false}
            type="monotone"
            dataKey="burn"
            stackId="a"
            name="Burn MLP"
            fill={ChartColorForTheme(2)}
          />
          <Bar
            unit={viewSettings.itemsUnit}
            isAnimationActive={false}
            type="monotone"
            dataKey="liquidation"
            stackId="a"
            name="Liquidation"
            fill={ChartColorForTheme(3)}
          />
          <Bar
            unit={viewSettings.itemsUnit}
            isAnimationActive={false}
            type="monotone"
            dataKey="margin"
            stackId="a"
            name="Margin trading"
            fill={ChartColorForTheme(4)}
          />
          <Line
            isAnimationActive={false}
            type="monotone"
            dot={false}
            strokeWidth={3}
            stroke={ChartColorForTheme(12)}
            dataKey="cumulative"
            yAxisId="right"
            name="Cumulative"
          />
        </ComposedChart>
      </ResponsiveContainer>
    </ChartWrapper>
  );
}
