import { ARBITRUM } from "config/chains";

const MMY_VAULT = "MMY vault";
const MLP_VAULT = "MLP vault";
const MMY_WFTM_OLD_VAULT = "MMY_WFTM_OLD vault";
const MMY_WFTM_VAULT = "MMY_WFTM vault";
const MMY_WETH_VAULT = "MMY_WETH vault";

const FANTOM = 250;
const OP = 10;

const CHAINS = {
  [FANTOM]: {
    strategy: {
      [MMY_VAULT]: {
        link: "https://ftmscan.com/address/0x4fedf117114Eb32518cCa913F8a9FECF04719547",
        text: "MMY is staked to earn FTM, esMMY, and MPs. FTM reward is compounded to more MMY, while esMMY and MPs are staked to acquire more FTM rewards, compounded back for more MMY and MPs. All of this works to boost APR optimally.",
      },
      [MLP_VAULT]: {
        link: "https://ftmscan.com/address/0x12E2611b357EE749D3C202dF1342108598365497",
        text: "MLP is staked to earn FTM and esMMY. The claimed fees from esMMY staked and FTM then are used to mint more MLP to earn more fees. All of this works to boost APR optimally. A 2-hour cooldown will begin each time the vault auto-compounds. You can only withdraw after the cooldown has ended.",
      },
      [MMY_WFTM_OLD_VAULT]: {
        link: "https://ftmscan.com/address/0xc614D4543d973ac24b02929127f299327f3898B1",
        text: "The vault deposits the user's MMY-WFTM LP in an Equalizer farm to earn EQUAL. The earned token is then swapped to acquire more of the same LP token. To complete the compounding cycle, the new MMY-WFTM LP is added to the farm, ready for the next earning event.",
      },
      [MMY_WFTM_VAULT]: {
        link: "https://ftmscan.com/address/0x5A262Aa9d09cF519C347405663a043a6E6474Df0",
        text: "The vault deposits the user's MMY-WFTM LP in an Equalizer farm to earn EQUAL. The earned token is then swapped to acquire more of the same LP token. To complete the compounding cycle, the new MMY-WFTM LP is added to the farm, ready for the next earning event.",
      },
    },
    token: {
      [MMY_VAULT]: {
        link: "https://ftmscan.com/token/0x01e77288b38b416F972428d562454fb329350bAc",
        text: "MMY is the utility and governance token of Mummy Finance Spot and Perpetual DEX. Stake MMY to earn esMMY, MPs, and 30% of platform fees in FTM.",
      },
      [MLP_VAULT]: {
        link: "https://ftmscan.com/address/0x7585501faeF28e4439215e2c8057370cA1d6A94C",
        text: "MLP is the liquidity provider token. Accrues 60% of the platform's generated fees.",
      },
      [MMY_WFTM_OLD_VAULT]: {
        link: [
          "https://ftmscan.com/token/0x01e77288b38b416F972428d562454fb329350bAc",
          "https://ftmscan.com/token/0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83",
        ],
        text: [
          "MMY is the utility and governance token of Mummy Finance Spot and Perpetual DEX. Stake MMY to earn esMMY, MPs, and 30% of platform fees in FTM.",
          "Wrapped Fantom (WFTM) is a wrapped token of the FTM token, though the FTM token is the native token of the Fantom Opera chain.",
        ],
      },
      [MMY_WFTM_VAULT]: {
        link: [
          "https://ftmscan.com/token/0x01e77288b38b416F972428d562454fb329350bAc",
          "https://ftmscan.com/token/0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83",
        ],
        text: [
          "MMY is the utility and governance token of Mummy Finance Spot and Perpetual DEX. Stake MMY to earn esMMY, MPs, and 30% of platform fees in FTM.",
          "Wrapped Fantom (WFTM) is a wrapped token of the FTM token, though the FTM token is the native token of the Fantom Opera chain.",
        ],
      },
    },
  },
  [OP]: {
    strategy: {
      [MMY_WETH_VAULT]: {
        link: "https://optimistic.etherscan.io/address/0xc1d4e4743dde709831e1cb485dcdbd44def893b0",
        text: "The vault deposits the user's MMY-WETH LP in a Velodrome farm to earn VELO. The earned token is then swapped to acquire more of the same LP token. To complete the compounding cycle, the new MMY-WETH LP is added to the farm, ready for the next earning event.",
      },
    },
    token: {
      [MMY_WETH_VAULT]: {
        link: [
          "https://optimistic.etherscan.io/address/0x385719545Ef34d457A88e723504544A53F0Ad9BC",
          "https://optimistic.etherscan.io/token/0x4200000000000000000000000000000000000006",
        ],
        text: [
          "MMY is the utility and governance token of Mummy Finance Spot and Perpetual DEX. Stake MMY to earn esMMY, MPs, and 30% of platform fees in ETH.",
          "WETH is an ERC-20 token on Ethereum pegged to the price of Ether (ETH).",
        ],
      },
    },
  },
  [ARBITRUM]: {
    strategy: {
      [MMY_VAULT]: {
        link: "https://arbiscan.io/address/0xc55E86aF2F11e912a48e47f82a51eA782e082ed2",
        text: "MMY is staked to earn FTM, esMMY, and MPs. FTM reward is compounded to more MMY, while esMMY and MPs are staked to acquire more FTM rewards, compounded back for more MMY and MPs. All of this works to boost APR optimally.",
      },
      [MLP_VAULT]: {
        link: "https://arbiscan.io/address/0x703Dc0F50fEB31F040F92158A2dd571DB1A472B2",
        text: "MLP is staked to earn FTM and esMMY. The claimed fees from esMMY staked and FTM then are used to mint more MLP to earn more fees. All of this works to boost APR optimally.",
      },
    },
    token: {
      [MMY_VAULT]: {
        link: "https://arbiscan.io/token/0xA6D7D0e650aa40FFa42d845A354c12c2bc0aB15f",
        text: "MMY is the utility and governance token of Mummy Finance Spot and Perpetual DEX. Stake MMY to earn esMMY, MPs, and 30% of platform fees in FTM.",
      },
      [MLP_VAULT]: {
        link: "https://arbiscan.io/address/0x421b4c1Db159936834D5DcA1aF35DBD5443420D1",
        text: "MLP is the liquidity provider token. Accrues 60% of the platform's generated fees.",
      },
    },
  },
};

export default CHAINS;
