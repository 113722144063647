import axios from "axios";

import { getServerBaseUrl } from "config/backend";
import { parseValue } from "lib/numbers";
import { useEffect, useState } from "react";

function usePositionInfo(chainId, posId) {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const source = axios.CancelToken.source();

    setIsLoading(true);
    async function getPositionInfo() {
      try {
        const { data: response } = await axios.get(
          `${getServerBaseUrl(chainId, true)}/public/position-detail/${posId}`,
          {
            cancelToken: source.token,
          }
        );

        setData(formatPositionInfo(response.data) || {});
      } catch (error) {
        if (axios.isCancel(error)) {
          console.error("Request canceled", error.message);
        } else {
          console.error(error);
        }
      } finally {
        setIsLoading(false);
      }
    }

    getPositionInfo();
    const timerId = setInterval(getPositionInfo, 30000);

    return () => {
      source.cancel();
      clearInterval(timerId);
    };
  }, [posId, chainId]);

  return {
    data,
    isLoading,
  };
}

function formatPositionInfo(raw) {
  //String -> BigNumber
  const keysNeedFormat = [
    "size",
    "collateral",
    "pendingPnl",
    "realisedPnl",
    "liquidationPrice",
    "averagePrice",
    "paidPositionFee",
    "paidBorrowFee",
    "paidFundingFee",
    "pendingPnl",
    "accruedFundingFee",
    "accruedBorrowFee",
    "netPnl",
  ];
  const formatted = Object.entries(raw).map(([key, rawValue]) => {
    if (keysNeedFormat.includes(key)) {
      return [key, stringToBigNumber(rawValue)];
    }

    return [key, rawValue];
  });

  const formattedObj = Object.fromEntries(formatted);

  //Caculate
  let pnlAfterFee;
  if (raw.positionStatus === "OPEN") {
    pnlAfterFee = formattedObj.netPnl;
  }

  let liquidationPrice = formattedObj.liquidationPrice;

  const isClosed = ["CLOSED", "LIQUIDATED"].includes(raw.positionStatus);

  //if is closed position
  if (isClosed) {
    liquidationPrice = null;
    pnlAfterFee = formattedObj.realisedPnl;
  }

  const paidFees = formattedObj.paidPositionFee.add(formattedObj.paidBorrowFee).add(formattedObj.paidFundingFee);
  return {
    ...formattedObj,
    paidFees,
    pnlAfterFee,
    liquidationPrice,
    isClosed,
  };

  function stringToBigNumber(string) {
    return string ? parseValue(string, 30) : undefined;
  }
}

export default usePositionInfo;
