import { useContext, useMemo } from "react";
import CountUp from "react-countup";
import Fade from "react-reveal/Fade";
import styled, { css, keyframes } from "styled-components";
import openInterestIc from "img/dashboard/openInterest.svg";
import totalFeesIc from "img/dashboard/tfee.svg";
import totalUsersIc from "img/dashboard/tuser.svg";
import totalValueLockedIc from "img/dashboard/tvl.svg";
import totalTradingVolumeIc from "img/dashboard/tvolumn.svg";
import chainlink_icon from "img/home/chainlink_icon.svg";
import verichain_icon from "img/home/verichain.svg";
import bnbIc from "img/home/ic_bnb.svg";
import btcIc from "img/home/ic_btc.svg";
import daiIc from "img/home/ic_dai.svg";
import ethIc from "img/home/ic_eth.svg";
import ftmIc from "img/home/ic_ftm.svg";
import usdcIc from "img/home/ic_usdc.svg";
import usdtIc from "img/home/ic_usdt.svg";
import pyth_icon from "img/home/pyth_icon.svg";
import { bigNumberify, formatAmount, parseValue } from "lib/numbers";
import { StatsContext } from "contexts/StatsProvider";
import HomeBannerMobile from "img/home/home-banner-mobile.png";
import HeadBanner from "img/home/home-banner.png";
import { USD_DECIMALS } from "lib/legacy";
import { useMediaQuery } from "react-responsive";
import { getSkeletonClassName } from "utils/skeletonHelper";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { ARBITRUM, BASE, FANTOM, OP, getChainName } from "config/chains";
import { BigNumber } from "ethers";

const Hero = ({ chainId }) => {
  const {
    v2,
    totalValueLocked,
    totalFees,
    totalUsers,
    totalTradingVolume,
    longOpenInterest,
    shortOpenInterest,
    totalAllVersion,
  } = useContext(StatsContext);
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  const totalOpenInterest = useMemo(() => {
    let totalLongOpenInterest = bigNumberify(0);
    let totalShortOpenInterest = bigNumberify(0);
    if (longOpenInterest && totalAllVersion.longOpenInterest) {
      totalLongOpenInterest = totalAllVersion?.longOpenInterest;
    }
    if (shortOpenInterest && totalAllVersion.shortOpenInterest) {
      totalShortOpenInterest = totalAllVersion?.shortOpenInterest;
    }
    return totalLongOpenInterest.add(totalShortOpenInterest);
  }, [longOpenInterest, shortOpenInterest]);

  return (
    <Wrapper>
      <video className="videoTag" autoPlay loop muted playsInline>
        <source src={"https://cdn.mummy.finance/bg_videoMMY.mp4"} type="video/mp4" />
      </video>
      <Inner>
        <Fade bottom>
          <Heading>
            <LeftHead>
              <Title>Decentralized Liquidity Marketplace</Title>
              <Description className="desc-head">
                Effortless trading, continuous liquidity. Across Crypto, Forex, and beyond.
              </Description>
              <TradeButton href="https://app.mummy.finance/#/trade">Start Trading</TradeButton>
              <div style={{ display: "flex" }}>
                <PoweredByGroup className="audit">
                  <Label>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <g id="Frame">
                        <path
                          id="Vector"
                          d="M8 1.5C4.41025 1.5 1.5 4.41025 1.5 8C1.5 11.5898 4.41025 14.5 8 14.5C11.5898 14.5 14.5 11.5898 14.5 8C14.5 4.41025 11.5898 1.5 8 1.5ZM7.25 10.5L4.5 7.75L5 7L7.25 8.5L10.7393 5.5L11.5 6.25L7.25 10.5Z"
                          fill="#03F5AE"
                        />
                      </g>
                    </svg>
                    Audited by
                  </Label>
                  <PowerOwner>
                    <ExternalLink
                      href={
                        "https://github.com/verichains/public-audit-reports/blob/main/Verichains%20Public%20Audit%20Report%20-%20Mummy%20Finance%20-%20v1.0.pdf"
                      }
                      className={"link-styled"}
                    >
                      <img src={verichain_icon} />
                    </ExternalLink>
                  </PowerOwner>
                </PoweredByGroup>
                <PoweredByGroup>
                  <Label>Powered by</Label>
                  <PowerOwner>
                    <ExternalLink href={"https://chain.link/"} className={"link-styled"}>
                      <img src={chainlink_icon} />
                    </ExternalLink>
                    <div className="line" />
                    <ExternalLink
                      href={"https://pyth.network/consumers?blockchain=base&protocol-type=derivatives"}
                      className={"link-styled"}
                    >
                      <img src={pyth_icon} />
                    </ExternalLink>
                  </PowerOwner>
                </PoweredByGroup>
              </div>
            </LeftHead>
            <RightHead>
              {/* <HomeBanner className="home-banner-styled" /> */}
              <img src={isMobile ? HomeBannerMobile : HeadBanner} className="head-banner" />
            </RightHead>
          </Heading>
        </Fade>
        <Stats className="container">
          <StatItem>
            <img src={totalTradingVolumeIc} alt="stat_ic" />
            <div className="content">
              <div className={`value ${getSkeletonClassName(totalAllVersion?.totalTradingVolume)}`}>
                <CountUp
                  end={parseFloat(formatAmount(totalAllVersion?.totalTradingVolume, USD_DECIMALS, 0))}
                  separator=","
                  prefix="$"
                />
              </div>
              <div className="title">Total Trading Volume</div>
            </div>
          </StatItem>
          <StatItem>
            <img src={totalValueLockedIc} alt="stat_ic" />
            <div className="content">
              <div className={`value ${getSkeletonClassName(totalValueLocked?.["totalAll"])}`}>
                {totalValueLocked && totalValueLocked?.["totalAll"] && (
                  <CountUp
                    end={formatAmount(totalValueLocked?.["totalAll"], USD_DECIMALS, 0)}
                    separator=","
                    prefix="$"
                  />
                )}
              </div>
              <div className="title">Total Value Locked</div>
            </div>
          </StatItem>
          <StatItem>
            <img src={totalFeesIc} alt="stat_ic" />
            <div className="content">
              <div className={`value ${getSkeletonClassName(totalAllVersion.totalFees)}`}>
                {totalAllVersion && totalAllVersion.totalFees && (
                  <CountUp end={formatAmount(totalAllVersion?.totalFees, USD_DECIMALS, 0)} separator="," prefix="$" />
                )}
              </div>
              <div className="title">Total Fees</div>
            </div>
          </StatItem>
          <StatItem>
            <img src={openInterestIc} alt="stat_ic" />
            <div className="content">
              <div className={`value ${getSkeletonClassName(totalOpenInterest)}`}>
                <CountUp end={parseFloat(formatAmount(totalOpenInterest, USD_DECIMALS, 0))} separator="," prefix="$" />
              </div>
              <div className="title">Open Interest</div>
            </div>
          </StatItem>
          <StatItem>
            <img src={totalUsersIc} alt="stat_ic" />

            <div className="content">
              <div className={`value ${getSkeletonClassName(totalAllVersion.totalUsers)}`}>
                {totalAllVersion && totalAllVersion?.totalUsers && (
                  <CountUp end={formatAmount(totalAllVersion?.totalUsers, 0, 0)} />
                )}
              </div>
              <div className="title">Total Users</div>
            </div>
          </StatItem>
        </Stats>
      </Inner>
      <FloatTokens>
        <img src={bnbIc} alt="bnbIc" />
        <img src={ftmIc} alt="ftmIc" />
        <img src={ethIc} alt="ethIc" />
        <img src={btcIc} alt="btcIc" />
        <img src={usdtIc} alt="usdtIc" />
        <img src={usdcIc} alt="usdcIc" />
        <img src={daiIc} alt="daiIc" />
      </FloatTokens>
    </Wrapper>
  );
};

function createCSS() {
  const altAttrNames = {
    bnbIc: [7, 0, 11, 20.93],
    daiIc: [21.42, 10.42, 14.67, 16.67],
    usdtIc: [10.17, 19.37, 26.66, 4.53],
    btcIc: [87.67, 20.83, 10.33, 12.5],
    ethIc: [84.26, 40.22, 26.92, 45.35],
    usdcIc: [8.42, 10.42, 50.07, 7.05],
    ftmIc: [89, 10.42, 47.07, 7.05],
  };

  let styles = "";

  Object.keys(altAttrNames).forEach((name) => {
    styles += `
      img[alt="${name}"] {
        left: ${altAttrNames[name][0]}%;
        right: ${altAttrNames[name][1]}%;
        top: ${altAttrNames[name][2]}%;
        bottom: ${altAttrNames[name][3]}%;

        @media (max-width: 1024px) {
          top: ${altAttrNames[name][2] / 2}%;
          scale: 0.6;
        }

        @media (max-width: 767px) {
          top: ${altAttrNames[name][2] / 2.5}%;
          scale: 0.4;
        }
      }
    `;
  });

  return css`
    ${styles}
  `;
}

const floating = keyframes`
  0% { transform: translate(0,  0px); }
  50%  { transform: translate(0, 5px); }
  100%   { transform: translate(0, -0px); }
`;

const FloatTokens = styled.div`
  position: absolute;
  inset: 0;
  z-index: 5;

  img {
    position: absolute;
    animation: ${floating} 2.5s ease-in-out 0.33s infinite normal none;
  }
  @media (max-width: 1840px) {
    img {
      opacity: 0.4;
    }
  }
  @media (max-width: 1024px) {
    img {
      opacity: 1;
    }
  }
  ${createCSS()}
`;

const GradientText = styled.div`
  height: 75px;

  @media (max-width: 1024px) {
    height: 50px;
  }

  @media (max-width: 767px) {
    height: 35px;
  }
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 36px;
  line-height: 43.2px;
  text-align: left;
  background-image: linear-gradient(to right, #03f5ae, #e3fff7);
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  margin-bottom: 16px;
  width: 100%;
  @media (max-width: 1024px) {
    line-height: 36px;
    text-align: center;
  }

  @media (max-width: 767px) {
    font-size: 24px;
    line-height: 28.8px;
    margin-bottom: 12px;
  }
`;

const Description = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  color: #fff !important;
  text-align: left;
  margin-bottom: 24px;
  @media (max-width: 1024px) {
    text-align: center;
  }
  @media (max-width: 767px) {
    font-size: 16px;
    margin-bottom: 16px;
  }
`;

const StatItem = styled.div`
  flex: 1;
  background-color: #ffffff1a;
  border-radius: 24px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);

  img {
    width: 36px;
    height: 36px;
    filter: drop-shadow(rgb(0, 255, 200) 0px 4px 12px);
    /* box-shadow: 0px 4px 24px 0px #03f5ae; */
  }
  .content {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 12px;
    height: 100%;

    .value {
      font-weight: 700;
      font-size: 24px;
      line-height: 140%;
    }

    .title {
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
      color: #ffffff99;
      margin-top: auto;
    }
  }

  @media (max-width: 1024px) {
    min-width: calc(100% / 4);

    &:nth-child(4),
    &:nth-child(5) {
      width: 224px;
      flex: unset;
    }

    .content {
      .value {
        font-size: 16px;
      }

      .title {
        font-size: 14px;
      }
    }
  }

  @media (max-width: 767px) {
    min-width: unset;
    flex-direction: row;
    padding: 16px;

    &:nth-child(4),
    &:nth-child(5) {
      width: unset;
      flex: unset;
    }

    .content {
      gap: 8px;
      align-items: flex-start;

      .value {
        font-size: 16px;
      }
    }
  }
`;

const Stats = styled.div`
  width: 100%;
  gap: 24px;
  display: flex;
  grid-template-columns: repeat(5, 1fr);
  .value span {
    color: #fff !important;
  }
  @media (max-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0;
  }

  @media (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
    flex-direction: column;
    gap: 16px;
  }
`;

const Inner = styled.div`
  position: relative;
  z-index: 10;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 24px;

  @media (max-width: 1024px) {
    gap: 40px;
    padding: 0px 24px;
  }

  @media (max-width: 767px) {
    gap: 16px;
    padding: 0px 16px;
  }
`;

const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  width: 100%;
  max-width: 1320px;
  margin: 0 auto;
  @media (max-width: 1660px) {
    max-width: 1250px;
  }
  @media (max-width: 1024px) {
    gap: 40px;
    justify-content: center;
    flex-direction: column;
  }
  @media (max-width: 767px) {
    gap: 24px;
  }
`;
const LeftHead = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  @media (max-width: 1024px) {
    align-items: center;
  }
`;
const RightHead = styled.div`
  .head-banner {
  }
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  img {
    width: 659px;
    height: 446px;
    @media (max-width: 1660px) {
      width: 620px;
      height: 415px;
    }
    @media (max-width: 1024px) {
      width: 600px;
      height: 406px;
    }
    @media (max-width: 767px) {
      width: 362px;
      height: 253px;
    }
  }
`;

const TradeButton = styled.a`
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #080715;
  background-color: #03f5ae;
  padding: 12px 24px;
  border-radius: 12px;
  border: none;
  outline: none;
  text-decoration: none;
  margin-bottom: 48px;
  &:hover {
    background: #04d397;
    color: #080715 !important;
  }

  @media (max-width: 1024px) {
    margin-bottom: 32px;
  }
  @media (max-width: 767px) {
    font-size: 14px;
    font-weight: 500;
    padding: 8px;
    margin-bottom: 24px;
    border-radius: 8px;
  }
`;

const PoweredByGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const PowerOwner = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;
  height: 100%;
  position: relative;
  .link-styled {
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      cursor: pointer;
    }
  }
  .line {
    height: 48px;
    width: 1px;
    background-color: rgba(255, 255, 255, 0.6);
  }
  img {
    width: 140px;
    height: 48px;
  }

  @media (max-width: 1024px) {
    img {
      width: 120px;
      height: 40px;
    }
    .line {
      height: 40px;
    }
  }
  @media (max-width: 767px) {
    gap: 12px;
    img {
      width: 100px;
      height: 32px;
    }
    .line {
      height: 32px;
    }
  }
`;
const Label = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
  color: rgba(255, 255, 255, 0.6) !important;
  width: 100%;
  text-align: left;
  display: flex;
  svg {
    margin-right: 2px;
  }
  @media (max-width: 1024px) {
    text-align: center;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 24px 0;
  position: relative;

  .videoTag {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
  }
  .audit {
    margin-right: 24px;
    padding-right: 24px;
    border-right: 1px solid #ffffff30;
    @media (max-width: 767px) {
      margin-right: 12px;
      padding-right: 12px;
    }
  }
  @media (max-width: 1024px) {
    padding: 60px 0 48px;
  }

  @media (max-width: 767px) {
    padding: 40px 0;
  }

  > video {
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
    z-index: 1;
    object-fit: cover;
    background-color: #000000;
    filter: brightness(30%) blur(4px);
  }
`;

export default Hero;
