import React, { useEffect, useState } from "react";
import cx from "classnames";

import {
  DEFAULT_SLIPPAGE_AMOUNT,
  DEFAULT_HIGHER_SLIPPAGE_AMOUNT,
  importImage,
  TAKE_PROFIT,
  STOP_LOSS,
  getPnlWithoutFee,
} from "lib/legacy";

import Checkbox from "../Checkbox/Checkbox";

import Modal from "../Modal/Modal";
import ExchangeInfoRow from "./ExchangeInfoRow";

import "./PositionSeller.css";

import { formatAmount, limitDecimals, parseValue } from "lib/numbers";
import PercentageButtons from "./PercentageButtons";
import { BigNumber } from "ethers";
import { preventSpecialCharacters } from "utils/helpers";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { THEME_KEY } from "config/localStorage";
const antIcon = <LoadingOutlined style={{ fontSize: 16, color: "grey" }} spin />;
export default function AddTrailingStop(props) {
  const { isConfirm, onConfirm, isVisible, position, setIsVisible, chainId, marketTokensInfo } = props;
  const currentMarket = marketTokensInfo?.find((x) => x.id === position.tokenId);
  const markPrice = BigNumber.from(position.isLong ? currentMarket?.askPrice : currentMarket?.bidPrice || 1);
  const [fromValue, setFromValue] = useState("");

  const [closeQuantity, setCloseQuantity] = useState("");

  const [showPercentage, setShowPercentage] = useState(true);

  const handleSelectPercentageCloseQuantity = (percentage) => {
    const value = position.quantity.mul(parseValue(percentage, 30)).div(parseValue(1, 30));
    setCloseQuantity(formatAmount(value, 30, 30, false));
  };

  const size = parseValue(position.size, 30);
  const averagePrice = parseValue(position.averagePrice, 30);
  const { quantity } = position;
  const onChangeAmount = (e) => {
    const value = e.target.value;
    if (value !== "") {
      if (!value.includes("-")) {
        const number = Number(value);
        if (value.includes(".")) {
          const arr = value.split(".")[1];
          if (arr.length > 2) {
            return;
          }
        }
        if (showPercentage) {
          if (number <= 100) {
            setFromValue(value);
          }
        } else {
          if (number >= 0) {
            setFromValue(value);
          }
        }
      }
    } else setFromValue(value);
  };
  const handleAddTrailing = () => {
    if (fromValue && closeQuantity) {
      const sizeDelTa = parseValue(closeQuantity, 30).eq(position.quantity)
        ? parseValue(position.size, 30)
        : parseValue(closeQuantity, 30).mul(averagePrice).div(parseValue(1, 30));
      const collateralDelta = sizeDelTa.mul(parseValue(1, 30)).div(parseValue(position.leverage, 30));
      const entryPrice = parseValue(position.averagePrice, 30);
      const params = [collateralDelta, sizeDelTa];
      params.push(showPercentage ? 1 : 0);
      const deltaPrice = showPercentage
        ? entryPrice.mul(parseValue(fromValue, 30)).div(parseValue(100, 30))
        : parseValue(fromValue, 30);
      const stpPrice = position.isLong ? entryPrice.sub(deltaPrice) : entryPrice.add(deltaPrice);
      params.push(stpPrice);
      params.push(showPercentage ? Number(fromValue) * 1000 : parseValue(fromValue, 30));

      onConfirm && onConfirm(position.posId, params);
    }
  };
  const entryPrice = parseValue(position.averagePrice, 30);
  const sizeDelTa = closeQuantity
    ? parseValue(closeQuantity, 30).eq(position.quantity)
      ? parseValue(position.size, 30)
      : parseValue(closeQuantity, 30).mul(averagePrice).div(parseValue(1, 30))
    : null;
  const deltaPrice = fromValue
    ? showPercentage
      ? entryPrice.mul(parseValue(fromValue, 30)).div(parseValue(100, 30))
      : parseValue(fromValue, 30)
    : null;
  const stpPrice = deltaPrice ? (position.isLong ? entryPrice.sub(deltaPrice) : entryPrice.add(deltaPrice)) : null;
  const pnlWithoutFee =
    sizeDelTa && stpPrice ? getPnlWithoutFee(position.isLong, stpPrice, averagePrice, sizeDelTa) : null;
  useEffect(() => {
    setFromValue("");
  }, [showPercentage]);
  const isDisableBtn = () => {
    if (!fromValue && fromValue <= 0) return true;
    if (!closeQuantity && closeQuantity <= 0) return true;
    if (parseValue(closeQuantity, 30).gt(position.quantity)) return true;
    if (showPercentage) {
      if (fromValue < 0.5) return true;
    } else {
      if (
        parseValue(fromValue, 30)
          .mul(parseValue(1, 30))
          .div(parseValue(position.averagePrice, 30))
          .lt(parseValue(0.005, 30))
      )
        return true;
    }
  };
  const renderText = () => {
    if (!fromValue && fromValue <= 0) return "Enter Trigger Amount";
    if (!closeQuantity && closeQuantity <= 0) return "Enter Close Quantity";
    if (parseValue(closeQuantity, 30).gt(position.quantity)) return "Amount exceeds balance";
    if (showPercentage) {
      if (fromValue < 0.5) return "Min percent: 0.5%";
    } else {
      if (
        parseValue(fromValue, 30)
          .mul(parseValue(1, 30))
          .div(parseValue(position.averagePrice, 30))
          .lt(parseValue(0.005, 30))
      )
        return "Min amount: 0.5% entry price";
    }
    return isConfirm ? "Adding..." : "Add Trailing Stop";
  };
  return (
    <div className="PositionEditor">
      {position && (
        <Modal
          className={`PositionSeller-modal confirmation-modal trailing-modal`}
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          label={"Add Trailing Stop"}
          allowContentTouchMove
        >
          <div className="order-title-line order-title-line-details">
            <img src={importImage("ic_" + position?.symbol?.toLowerCase() + "_24.svg")} alt="" />
            <div className="position-info-container">
              <div className="position-info">
                <div className="title">{position.symbol}</div>
                <div className={`side ${position?.isLong ? "side-long" : "side-short"}`}>
                  {position?.isLong ? "LONG" : "SHORT"}
                </div>
                <div className="side">
                  {position?.positionType === "Market Order" ? "Market" : position?.positionType}
                </div>
              </div>
              <div className="position-id">#{position?.posId}</div>
            </div>
          </div>
          <div className="content-container">
            <div className="Exchange-swap-section" style={{ marginBottom: "8px", marginTop: "0px" }}>
              <div className="Exchange-swap-section-top">
                <div className="muted">
                  <span>Close Quantity</span>
                </div>
                <div className="right-balance" style={{ marginLeft: "auto" }}>
                  <span>Max: {formatAmount(position.quantity, 30, 4, true)}</span>
                </div>
              </div>
              <div className="Exchange-swap-section-bottom">
                <div className="Exchange-swap-input-container">
                  <input
                    type="number"
                    min="0"
                    placeholder="0.0"
                    className="Exchange-swap-input"
                    value={closeQuantity}
                    onChange={(e) => setCloseQuantity(e.target.value)}
                    onKeyDown={preventSpecialCharacters}
                  />
                </div>
                <div className="PositionEditor-token-symbol">{position.symbol}</div>
              </div>
            </div>
            <PercentageButtons
              onChangePercentage={handleSelectPercentageCloseQuantity}
              balance={formatAmount(position.quantity, 30, 30, false)}
              value={closeQuantity}
            />
            <Checkbox
              isChecked={showPercentage}
              setIsChecked={setShowPercentage}
              className={cx("showPercentage", { active: showPercentage })}
            >
              <div className="group-actions">
                <span>Percentage</span>
              </div>
            </Checkbox>
            <div className="Exchange-swap-section" style={{ marginBottom: "8px" }}>
              <div className="Exchange-swap-section-top">
                <div className="muted">
                  <div className="Exchange-swap-usd">
                    <span>
                      {/* Close: {convertedAmountFormatted} {position.collateralToken.symbol} */}
                      Trailing Amount
                    </span>
                  </div>
                </div>
                {/* <div className="muted align-right clickable">
                  <span>Balance: {limitDecimals(currentMarket.askPrice, 2)}</span>
                </div> */}
              </div>
              <div className="Exchange-swap-section-bottom">
                <div className="Exchange-swap-input-container">
                  <input
                    type="number"
                    min="0"
                    placeholder="0.0"
                    className="Exchange-swap-input"
                    value={fromValue}
                    onChange={onChangeAmount}
                    onKeyDown={preventSpecialCharacters}
                  />
                </div>
                {showPercentage && <div className="PositionEditor-token-symbol">%</div>}
              </div>
            </div>
            <div className="keep-levergae-modal"></div>
            <div className="PositionEditor-info-box" style={{ marginTop: "16px" }}>
              <div className="square-container square-fee-container">
                <ExchangeInfoRow label={`Entry Price`}>
                  ${formatAmount(averagePrice, 30, currentMarket?.decimals || 2, true)}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Mark Price`}>
                  ${formatAmount(markPrice, 30, currentMarket?.decimals || 2, true)}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Pos. Size`}>${formatAmount(size, 30, 2, true)}</ExchangeInfoRow>
                <ExchangeInfoRow label={`Pos. Qty`}>
                  {formatAmount(quantity, 30, 4, true)} {position.symbol}
                </ExchangeInfoRow>
                <div className="divider" />
                <ExchangeInfoRow label={`Min. Est. Loss`} isPositive={pnlWithoutFee && pnlWithoutFee.gt(0)}>
                  {pnlWithoutFee && pnlWithoutFee.gt(0) ? "+" : "-"}$
                  {pnlWithoutFee ? formatAmount(pnlWithoutFee, 30, 2, true).replace("-", "") : "--"}
                </ExchangeInfoRow>
              </div>
            </div>
          </div>
          <div className="Exchange-swap-button-container">
            <button
              className="App-cta Exchange-swap-button"
              disabled={isDisableBtn() || isConfirm}
              onClick={handleAddTrailing}
            >
              {renderText()}
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
}
