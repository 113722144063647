import { t } from "@lingui/macro";
import { ethers } from "ethers";
import { switchNetwork } from "lib/wallets";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import useSWR from "swr";
import { getServerUrl } from "config/backend";
import UniswapV2 from "abis/UniswapV2.json";
import NewVaultJson from "abis/NewVault.json";

import { ARBITRUM, BASE, DEFAULT_CHAIN_ID, FANTOM, OP } from "config/chains";
import { getWhitelistedTokens } from "config/tokens";
import { useGmxPrice, useTotalGmxStaked, useTotalGmxSupplyAll } from "domain/legacy";
import { useInfoTokens } from "domain/tokens";
import { contractFetcher } from "lib/contracts";
import {
  BASIS_POINTS_DIVISOR,
  GLP_DECIMALS,
  GMX_DECIMALS,
  PLACEHOLDER_ACCOUNT,
  SECONDS_PER_YEAR,
  USD_DECIMALS,
  WEEK_PER_YEAR,
  isActiveV2,
} from "lib/legacy";
import { bigNumberify, expandDecimals, formatAmount, formatAmountFree, formatNumber, parseValue } from "lib/numbers";

import TooltipComponent from "components/Tooltip/Tooltip";

import APRLabel from "components/APRLabel/APRLabel";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";

import ExternalLink from "components/ExternalLink/ExternalLink";
import arb24Icon from "img/ic_arbitrum_24.svg";
import base24Icon from "img/ic_base_24.svg";
import ftm24Icon from "img/ic_ftm_24.svg";
import glp40Icon from "img/mlp_coin.svg";
import msp40Icon from "img/mslp_coin.svg";
import gmx40Icon from "img/mmy_coin.svg";
import green_corner_ic from "img/green_corner.svg";
import purple_corner_ic from "img/purple_corner.svg";
import blue_corner_ic from "img/blue_corner.svg";
import mmy_ftm_coin from "img/mmy_ftm_coin.svg";
import op24Icon from "img/ic_op_24.svg";
import useMspPrice from "hooks/useMspPrice";
import useMslpContract from "hooks/contracts/useMslpContract";
import useUsdPrice from "hooks/useUsdPrice";
import useMMYFarmContractMulticall from "hooks/contracts/useMMYFarmContractMulticall";
import useTokensPrice from "hooks/useTokensPrice";
import useTokensPriceOP from "hooks/useTokensPriceOP";
import useMslpApr from "hooks/useMslpApr";
import { getContract } from "config/contracts";
import { useMedia } from "react-use";
import { caculateAPYHourly2 } from "lib/helper";
import { useAprContext } from "contexts/AprProvider";
import { useThemeContext } from "contexts/ThemeProvider";
import useMspPriceMultiChain from "hooks/useMspPriceMultiChain";
import useMslpAprOP from "hooks/useMslpAprOP";
import useInfoMslp from "hooks/useInfoMslp";

const ArrowIcon = ({ isLightTheme }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.6">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.87521 15.6967C6.54977 16.0221 6.54977 16.5498 6.87521 16.8752C7.20065 17.2006 7.72828 17.2006 8.05372 16.8752L14.8807 10.0482L14.8807 15.1074C14.8807 15.5677 15.2538 15.9408 15.714 15.9408C16.1743 15.9408 16.5474 15.5677 16.5474 15.1074L16.5474 8.03638C16.5474 7.81536 16.4596 7.6034 16.3033 7.44712C16.147 7.29084 15.9351 7.20304 15.714 7.20304L8.64298 7.20304C8.18274 7.20304 7.80964 7.57614 7.80964 8.03638C7.80964 8.49661 8.18274 8.86971 8.64298 8.86971L13.7022 8.86971L6.87521 15.6967Z"
          fill={isLightTheme ? "#0D1A16" : "white"}
        />
      </g>
    </svg>
  );
};

const Tokens = ({ chainId, active, library, apy }) => {
  const processedData = useAprContext();
  const { lightThemeClassName, isLightTheme } = useThemeContext();
  const isTabletOnly = useMedia("(min-width: 768px) and (max-width: 1023px)");

  const poolAddress = getContract(FANTOM, "TraderJoeGmxAvaxPool");
  const vaultMMY_WFTMAddress = getContract(FANTOM, "VaultMMY_WFTM");

  const whitelistedTokens = getWhitelistedTokens(chainId);
  const tokenList = whitelistedTokens.filter((t) => !t.isWrapped);

  const { data: mlpInfo } = useSWR(getServerUrl(chainId, "/mlp-info"), {
    refreshInterval: 30000,
  });
  const { data: mlpInfoFantom } = useSWR(getServerUrl(FANTOM, "/mlp-info"), {
    refreshInterval: 30000,
  });

  const { data: mlpInfoOP } = useSWR(getServerUrl(OP, "/mlp-info"), {
    refreshInterval: 30000,
  });

  const { data: mlpInfoArbitrum } = useSWR(getServerUrl(ARBITRUM, "/mlp-info"), {
    refreshInterval: 30000,
  });
  const { data: mlpInfoBase } = useSWR(getServerUrl(BASE, "/mlp-info"), {
    refreshInterval: 30000,
  });

  const { data: poolSupply } = useSWR(
    [`TraderJoeGmxAvaxReserves:Dashboard:${poolAddress}`, FANTOM, poolAddress, "totalSupply"],
    {
      fetcher: contractFetcher(undefined, UniswapV2),
      refreshInterval: 30000,
    }
  );

  const { data: totalSupplyMMY_WFTM } = useSWR(
    [`StakeV2:totalSupplyMMY_WFTM`, FANTOM, vaultMMY_WFTMAddress, "balance"],
    {
      fetcher: contractFetcher(undefined, NewVaultJson),
      refreshInterval: 30000,
    }
  );

  let {
    op: totalGmxSupplyOP,
    fantom: totalGmxSupplyFantom,
    arb: totalGmxSupplyArb,
    base: totalGmxSupplyBase,
    total: totalGmxSupply,
  } = useTotalGmxSupplyAll();
  const { infoTokens } = useInfoTokens(library, chainId, active, undefined, undefined);
  const { gmxPriceFromFantom, gmxPriceFromOP, gmxPriceFromArbitrum, gmxPriceFromBase, gmxPrice } = useGmxPrice(
    chainId,
    { arbitrum: chainId === ARBITRUM ? library : undefined, op: chainId === OP ? library : undefined },
    active
  );

  let {
    op: opStakedGmx,
    // oplegacy: opStakedGmxLegacy,
    fantom: fantomStakedGmx,
    // fantomlegacy: fantomStakedGmxLegacy,
    arbitrum: arbitrumStakedGmx,
    base: baseStakedGmx,
    // total: totalStakedGmx,
  } = useTotalGmxStaked();

  let fantomStakedGmxUsd;
  if (fantomStakedGmx && gmxPriceFromFantom) {
    fantomStakedGmxUsd = fantomStakedGmx.mul(gmxPriceFromFantom).div(expandDecimals(1, GMX_DECIMALS));
  }
  // let fantomStakedGmxUsdLegacy;
  // if (fantomStakedGmxLegacy && gmxPriceFromFantom) {
  //   fantomStakedGmxUsdLegacy = fantomStakedGmxLegacy.mul(gmxPriceFromFantom).div(expandDecimals(1, GMX_DECIMALS));
  // }

  let opStakedGmxUsd;
  if (opStakedGmx && gmxPriceFromOP) {
    opStakedGmxUsd = opStakedGmx.mul(gmxPriceFromOP).div(expandDecimals(1, GMX_DECIMALS));
  }

  // let opStakedGmxUsdLegacy;
  // if (opStakedGmxLegacy && gmxPriceFromOP) {
  //   opStakedGmxUsdLegacy = opStakedGmxLegacy.mul(gmxPriceFromOP).div(expandDecimals(1, GMX_DECIMALS));
  // }

  let arbitrumStakedGmxUsd;
  if (arbitrumStakedGmx && gmxPriceFromArbitrum) {
    arbitrumStakedGmxUsd = arbitrumStakedGmx.mul(gmxPriceFromArbitrum).div(expandDecimals(1, GMX_DECIMALS));
  }

  let baseStakedGmxUsd;
  if (baseStakedGmx && gmxPriceFromBase) {
    baseStakedGmxUsd = baseStakedGmx.mul(gmxPriceFromBase).div(expandDecimals(1, GMX_DECIMALS));
  }
  let gmxMarketCap;
  let gmxMarketCapFantom;
  let gmxMarketCapOp;
  let gmxMarketCapArbitrum;
  let gmxMarketCapBase;
  if (
    gmxPriceFromFantom &&
    totalGmxSupplyFantom &&
    gmxPriceFromOP &&
    totalGmxSupplyOP &&
    gmxPriceFromArbitrum &&
    totalGmxSupplyArb &&
    gmxPriceFromBase &&
    totalGmxSupplyBase
  ) {
    gmxMarketCap = gmxPriceFromFantom
      .mul(totalGmxSupplyFantom)
      .add(gmxPriceFromOP.mul(totalGmxSupplyOP))
      .add(gmxPriceFromArbitrum.mul(totalGmxSupplyArb))
      .add(gmxPriceFromBase.mul(totalGmxSupplyBase))
      .div(expandDecimals(1, GMX_DECIMALS));

    gmxMarketCapFantom = gmxPriceFromFantom.mul(totalGmxSupplyFantom).div(expandDecimals(1, GMX_DECIMALS));
    gmxMarketCapOp = gmxPriceFromOP.mul(totalGmxSupplyOP).div(expandDecimals(1, GMX_DECIMALS));
    gmxMarketCapArbitrum = gmxPriceFromArbitrum.mul(totalGmxSupplyArb).div(expandDecimals(1, GMX_DECIMALS));
    gmxMarketCapBase = gmxPriceFromBase.mul(totalGmxSupplyBase).div(expandDecimals(1, GMX_DECIMALS));
  }

  let stakedGmxSupplyUsd;
  if (
    gmxPriceFromFantom &&
    fantomStakedGmx &&
    gmxPriceFromOP &&
    opStakedGmx &&
    gmxPriceFromArbitrum &&
    baseStakedGmx &&
    gmxPriceFromBase &&
    arbitrumStakedGmx
    // fantomStakedGmxLegacy
  ) {
    stakedGmxSupplyUsd = fantomStakedGmx
      .mul(gmxPriceFromFantom)
      .add(opStakedGmx.mul(gmxPriceFromOP))
      .add(arbitrumStakedGmx.mul(gmxPriceFromArbitrum))
      // .add(fantomStakedGmxLegacy.mul(gmxPriceFromFantom))
      .add(baseStakedGmx.mul(gmxPriceFromBase))
      .div(expandDecimals(1, GMX_DECIMALS));
  }

  // let aum;
  // if (aums && aums.length > 0) {
  //   aum = aums[0].add(aums[1]).div(2);
  // }
  // let aumOP;
  // if (aumsOP && aumsOP.length > 0) {
  //   aumOP = aumsOP[0].add(aumsOP[1]).div(2);
  // }

  // let aumFANTOM;
  // if (aumsFANTOM && aumsFANTOM.length > 0) {
  //   aumFANTOM = aumsFANTOM[0].add(aumsFANTOM[1]).div(2);
  // }

  // let aumFANTOMLegacy;
  // if (aumsFANTOMLegacy && aumsFANTOMLegacy.length > 0) {
  //   aumFANTOMLegacy = aumsFANTOMLegacy[0].add(aumsFANTOMLegacy[1]).div(2);
  // }

  // let aumArb;
  // if (aumsArb && aumsArb.length > 0) {
  //   aumArb = aumsArb[0].add(aumsArb[1]).div(2);
  // }

  // let aumBase;
  // if (aumsBase && aumsBase.length > 0) {
  //   aumBase = aumsBase[0].add(aumsBase[1]).div(2);
  // }

  let glpPrice;
  let glpSupply;
  let glpMarketCap;
  // let glpPriceFantomLegacy;
  let glpPriceFantom;
  let glpPriceOp;
  let glpPriceArb;
  let glpPriceBase;
  // let glpSupplyFantomLegacy;
  let glpSupplyFantom;
  let glpSupplyOp;
  let glpSupplyArbitrum;
  let glpSupplyBase;
  // let glpMarketCapFantomLegacy;
  let glpMarketCapFantom;
  let glpMarketCapOp;
  let glpMarketCapArbitrum;
  let glpMarketCapBase;
  let totalGlpSupply;
  if (mlpInfo) {
    glpSupply = bigNumberify(mlpInfo?.totalSupply || "0");

    glpPrice = bigNumberify(mlpInfo?.price || "0");
    glpMarketCap = glpPrice.mul(glpSupply).div(expandDecimals(1, GLP_DECIMALS));
  }
  if (mlpInfoFantom) {
    glpSupplyFantom = bigNumberify(mlpInfoFantom?.totalSupply || "0");

    glpPriceFantom = bigNumberify(mlpInfoFantom?.price || "0");
    glpMarketCapFantom = glpPriceFantom.mul(glpSupplyFantom).div(expandDecimals(1, GLP_DECIMALS));
  }

  // if (aumFANTOMLegacy && totalSuppliesFantomLegacy && totalSuppliesFantomLegacy[3]) {
  //   glpSupplyFantomLegacy = totalSuppliesFantomLegacy[3];

  //   glpPriceFantomLegacy =
  //     aumFANTOMLegacy && aumFANTOMLegacy.gt(0) && glpSupplyFantomLegacy.gt(0)
  //       ? aumFANTOMLegacy.mul(expandDecimals(1, GLP_DECIMALS)).div(glpSupplyFantomLegacy)
  //       : expandDecimals(1, USD_DECIMALS);
  //   glpMarketCapFantomLegacy = glpPriceFantomLegacy.mul(glpSupplyFantomLegacy).div(expandDecimals(1, GLP_DECIMALS));
  // }

  if (mlpInfoOP) {
    glpSupplyOp = bigNumberify(mlpInfoOP?.totalSupply || "0");

    glpPriceOp = bigNumberify(mlpInfoOP?.price || 0);
    glpMarketCapOp = glpPriceOp.mul(glpSupplyOp).div(expandDecimals(1, GLP_DECIMALS));
  }

  if (mlpInfoArbitrum) {
    glpSupplyArbitrum = bigNumberify(mlpInfoArbitrum?.totalSupply || "0");

    glpPriceArb = bigNumberify(mlpInfoArbitrum?.price || "0");
    glpMarketCapArbitrum = glpPriceArb.mul(glpSupplyArbitrum).div(expandDecimals(1, GLP_DECIMALS));
  }

  if (mlpInfoBase) {
    glpSupplyBase = bigNumberify(mlpInfoBase?.totalSupply || "0");

    glpPriceBase = bigNumberify(mlpInfoBase?.price || "0");
    glpMarketCapBase = glpPriceBase.mul(glpSupplyBase).div(expandDecimals(1, GLP_DECIMALS));
  }

  if (glpSupplyArbitrum && glpSupplyFantom && glpSupplyOp && glpSupplyBase) {
    totalGlpSupply = glpSupplyArbitrum
      .add(glpSupplyFantom)
      .add(glpSupplyOp)
      // .add(glpSupplyFantomLegacy)
      .add(glpSupplyBase);
  }
  if (glpMarketCapArbitrum && glpMarketCapFantom && glpMarketCapOp && glpMarketCapBase) {
    glpMarketCap = glpMarketCapArbitrum
      .add(glpMarketCapFantom)
      .add(glpMarketCapOp)
      // .add(glpMarketCapFantomLegacy)
      .add(glpMarketCapBase);
  }
  let adjustedUsdgSupply = bigNumberify(0);

  for (let i = 0; i < tokenList.length; i++) {
    const token = tokenList[i];
    const tokenInfo = infoTokens[token.address];
    if (tokenInfo && tokenInfo.usdgAmount) {
      adjustedUsdgSupply = adjustedUsdgSupply.add(tokenInfo.usdgAmount);
    }
  }

  let stableGlp = 0;
  let totalGlp = 0;

  tokenList.forEach((token) => {
    const tokenInfo = infoTokens[token.address];
    if (tokenInfo.usdgAmount && adjustedUsdgSupply && adjustedUsdgSupply.gt(0)) {
      const currentWeightBps = tokenInfo.usdgAmount.mul(BASIS_POINTS_DIVISOR).div(adjustedUsdgSupply);
      if (tokenInfo.isStable) {
        stableGlp += parseFloat(`${formatAmount(currentWeightBps, 2, 2, false)}`);
      }
      totalGlp += parseFloat(`${formatAmount(currentWeightBps, 2, 2, false)}`);
    }
  });

  let stablePercentage = totalGlp > 0 ? ((stableGlp * 100) / totalGlp).toFixed(2) : "0.0";

  const changeNetwork = useCallback(
    (network) => {
      if (network === chainId) {
        return;
      }
      if (!active) {
        setTimeout(() => {
          return switchNetwork(network, active);
        }, 500);
      } else {
        return switchNetwork(network, active);
      }
    },
    [chainId, active]
  );

  const BuyLink = ({ className, to, children, network }) => {
    return (
      <Link to={to} className={className} onClick={() => changeNetwork(network)}>
        {children}
      </Link>
    );
  };

  const mspPrices = useMspPriceMultiChain();
  const mspPrice = mspPrices[chainId] || mspPrices[DEFAULT_CHAIN_ID];

  const { totalSupply: totalSupplyNlpFantom, decimals: nlpDecimalFantom } = useMslpContract(
    FANTOM,
    active,
    PLACEHOLDER_ACCOUNT
  );

  const { totalSupply: totalSupplyNlpOp, decimals: nlpDecimalOp } = useMslpContract(OP, active, PLACEHOLDER_ACCOUNT);
  // const totalSupplyNlp = totalSupplyNlpFantom && totalSupplyNlpOp ? totalSupplyNlpFantom.add(totalSupplyNlpOp) : undefined;
  const { totalSupply: totalSupplyNlp } = useInfoMslp(chainId);

  const {
    mlpPoolInfo: [totalStakedFantom],
    poolRewardsPerSec: poolRewardsPerSecFantom,
  } = useMMYFarmContractMulticall(FANTOM, active, PLACEHOLDER_ACCOUNT, library);

  const {
    mlpPoolInfo: [totalStakedOp],
    poolRewardsPerSec: poolRewardsPerSecOp,
  } = useMMYFarmContractMulticall(OP, active, PLACEHOLDER_ACCOUNT, library);

  const totalStaked = {
    [FANTOM]: useUsdPrice(totalStakedFantom, mspPrices[FANTOM], 30),
    [OP]: useUsdPrice(totalStakedOp, mspPrices[OP], 30),
  };
  const totalStakedAll = totalStaked[FANTOM] && totalStaked[OP] ? totalStaked[FANTOM].add(totalStaked[OP]) : undefined;
  const mspMarketCapFantom =
  mspPrices[FANTOM] && totalSupplyNlpFantom && mspPrices[FANTOM].mul(totalSupplyNlpFantom).div(expandDecimals(1, 30));
  const mspMarketCapOp = mspPrices[OP] && totalSupplyNlpOp && mspPrices[OP].mul(totalSupplyNlpOp).div(expandDecimals(1, 30));

  const mspMarketCap = mspMarketCapFantom && mspMarketCapOp ? mspMarketCapFantom.add(mspMarketCapOp) : undefined;
  //MSLP only for FANTOM
  const rewardsPriceFantom = useTokensPrice({
    gmxPrice: gmxPriceFromFantom,
    FANTOM,
  });
  const rewardsPriceOp = useTokensPriceOP({
    gmxPrice: gmxPriceFromOP,
    OP,
  });
  const aprFantom = useMslpApr({
    mslpPrice: mspPrices[FANTOM],
    rewardsPrice: rewardsPriceFantom,
    totalStaked: totalStakedFantom,
    poolRewardsPerSec: poolRewardsPerSecFantom,
  });
  const aprOp = useMslpAprOP({
    mslpPrice: mspPrices[OP],
    rewardsPrice: rewardsPriceOp,
    totalStaked: totalStakedOp,
    poolRewardsPerSec: poolRewardsPerSecOp,
    chainId: OP,
  });
  const pairPrice = apy.priceMMY_FTM[FANTOM] ? parseValue(apy.priceMMY_FTM[FANTOM], 30) : undefined;

  let poolSupplyUsd;
  if (poolSupply && pairPrice) {
    poolSupplyUsd = poolSupply.mul(pairPrice).div(expandDecimals(1, 18));
  }

  let totalStakedPair;
  if (totalSupplyMMY_WFTM && pairPrice) {
    totalStakedPair = totalSupplyMMY_WFTM.mul(pairPrice).div(expandDecimals(1, 18));
  }

  //MMY
  const mmyAprFantom = parseFloat(formatAmount(processedData[FANTOM]?.gmxAprTotal, 2)) / 100;
  const mmyApyFantom = caculateAPYHourly2(mmyAprFantom) * 100;

  const mmyAprOp = parseFloat(formatAmount(processedData[OP]?.gmxAprTotal, 2)) / 100;
  const mmyApyOp = caculateAPYHourly2(mmyAprOp) * 100;

  const mmyAprArb = parseFloat(formatAmount(processedData[ARBITRUM]?.gmxAprTotal, 2)) / 100;
  const mmyApyArb = caculateAPYHourly2(mmyAprArb) * 100;

  const mmyAprBase = parseFloat(formatAmount(processedData[BASE]?.gmxAprTotal, 2)) / 100;
  const mmyApBase = caculateAPYHourly2(mmyAprBase) * 100;

  //MLP
  const mlpAprFantom = parseFloat(formatAmount(processedData[FANTOM]?.glpAprTotal, 2)) / 100;
  const mlpApyFantom = caculateAPYHourly2(mlpAprFantom) * 100;

  const mlpAprOp = parseFloat(formatAmount(processedData[OP]?.glpAprTotal, 2)) / 100;
  const mlpApyOp = caculateAPYHourly2(mlpAprOp) * 100;

  const mlpAprArb = parseFloat(formatAmount(processedData[ARBITRUM]?.glpAprTotal, 2)) / 100;
  const mlpApyArb = caculateAPYHourly2(mlpAprArb) * 100;

  const mlpAprBase = parseFloat(formatAmount(processedData[BASE]?.glpAprTotal, 2)) / 100;
  const mlpApBase = caculateAPYHourly2(mlpAprBase) * 100;

  return (
    <Wrapper className={lightThemeClassName}>
      <Title>
        <span>Tokens</span>
      </Title>
      <TokenCardContainer>
        <TokenCard>
          <img src={purple_corner_ic} alt="purple_corner_ic" />
          <CardTitle>
            <TokenImg>
              <img src={gmx40Icon} alt="MMY Token Icon" />
            </TokenImg>
            <TokenInfo>
              <div>
                <div className="token-symbol">
                  MMY{" "}
                  <ExternalLink href="https://docs.mummy.finance/tokenomics">
                    <ArrowIcon isLightTheme={isLightTheme} />
                  </ExternalLink>
                </div>
                <div className="token-desc">Mummy Finance Governance Token</div>
                <div className="token-price">
                  Price:{" "}
                  {!gmxPrice && (
                    <div
                      className="skeleton-box"
                      style={{
                        width: 64,
                        height: 19.59,
                      }}
                    />
                  )}
                  {gmxPrice && (
                    <TooltipComponent
                      position="left-bottom"
                      className="nowrap excep"
                      handle={"$" + formatAmount(gmxPrice, USD_DECIMALS, 2, true)}
                      renderContent={() => (
                        <div
                          className="customTooltip"
                          style={{
                            minWidth: 220,
                          }}
                        >
                          <StatsTooltipRow
                            label={`Price on Fantom`}
                            value={formatAmount(gmxPriceFromFantom, USD_DECIMALS, 2, true)}
                            showDollar={true}
                          />
                          <StatsTooltipRow
                            label={`Price on Optimism`}
                            value={formatAmount(gmxPriceFromOP, USD_DECIMALS, 2, true)}
                            showDollar={true}
                          />
                          <StatsTooltipRow
                            label={t`Price on Arbitrum`}
                            value={formatAmount(gmxPriceFromArbitrum, USD_DECIMALS, 2, true)}
                            showDollar={true}
                          />
                          <StatsTooltipRow
                            label={t`Price on Base`}
                            value={formatAmount(gmxPriceFromBase, USD_DECIMALS, 2, true)}
                            showDollar={true}
                          />
                        </div>
                      )}
                    />
                  )}
                </div>
              </div>
              {/* <ExternalLink
                href="https://docs.mummy.finance/tokenomics"
                target={"_blank"}
                className="btn-readmore border-btn"
              >
                Read more
              </ExternalLink> */}
            </TokenInfo>
          </CardTitle>

          <TokenCardContent>
            <TokenCardInfo>
              <div className="label">
                <span>Supply</span>
              </div>
              <div className={`value`}>
                {totalGmxSupply ? (
                  <TooltipComponent
                    position={isTabletOnly ? "left-bottom" : "right-bottom"}
                    className="nowrap excep"
                    handle={`${formatAmount(totalGmxSupply, GMX_DECIMALS, 0, true)} MMY`}
                    renderContent={() => (
                      <>
                        <StatsTooltipRow
                          label={t`Supply on Fantom`}
                          value={`${formatAmount(totalGmxSupplyFantom, GMX_DECIMALS, 0, true)} MMY`}
                          showDollar={false}
                        />
                        <StatsTooltipRow
                          label={t`Supply on Optimism`}
                          value={`${formatAmount(totalGmxSupplyOP, GMX_DECIMALS, 0, true)} MMY`}
                          showDollar={false}
                        />
                        <StatsTooltipRow
                          label={t`Supply on Arbitrum`}
                          value={`${formatAmount(totalGmxSupplyArb, GMX_DECIMALS, 0, true)} MMY`}
                          showDollar={false}
                        />
                        <StatsTooltipRow
                          label={t`Supply on Base`}
                          showDollar={false}
                          value={`${formatAmount(totalGmxSupplyBase, GMX_DECIMALS, 0, true)} MMY`}
                        />
                        <div className="divider"></div>
                        <StatsTooltipRow
                          label={t`Total`}
                          value={`${formatAmount(totalGmxSupply, GMX_DECIMALS, 0, true)} MMY`}
                          showDollar={false}
                        />
                      </>
                    )}
                  />
                ) : (
                  <div
                    className="skeleton-box"
                    style={{
                      width: 48,
                    }}
                  />
                )}
              </div>
            </TokenCardInfo>
            <TokenCardInfo>
              <div className="label">
                <span>Total Staked</span>
              </div>
              <div className="value">
                {stakedGmxSupplyUsd ? (
                  <TooltipComponent
                    position="right-bottom"
                    className="nowrap excep"
                    handle={`$${formatAmount(stakedGmxSupplyUsd, USD_DECIMALS, 0, true)}`}
                    renderContent={() => (
                      <>
                        <StatsTooltipRow
                          label={`Staked on Fantom`}
                          value={formatAmount(fantomStakedGmxUsd, USD_DECIMALS, 0, true)}
                          showDollar={true}
                        />
                        {/* <StatsTooltipRow
                          label={`Staked on Fantom legacy`}
                          value={formatAmount(fantomStakedGmxUsdLegacy, USD_DECIMALS, 0, true)}
                          showDollar={true}
                        /> */}
                        <StatsTooltipRow
                          label={`Staked on Optimism`}
                          value={formatAmount(opStakedGmxUsd, USD_DECIMALS, 0, true)}
                          showDollar={true}
                        />
                        {/* <StatsTooltipRow
                          label={`Staked on Optimism legacy`}
                          value={formatAmount(opStakedGmxUsdLegacy, USD_DECIMALS, 0, true)}
                          showDollar={true}
                        /> */}

                        <StatsTooltipRow
                          label={t`Staked on Arbitrum`}
                          value={formatAmount(arbitrumStakedGmxUsd, USD_DECIMALS, 0, true)}
                          showDollar={true}
                        />
                        <StatsTooltipRow
                          label={t`Staked on Base`}
                          value={formatAmount(baseStakedGmxUsd, USD_DECIMALS, 0, true)}
                          showDollar={true}
                        />
                        <div className="divider"></div>
                        <StatsTooltipRow
                          label={`Total`}
                          value={formatAmount(stakedGmxSupplyUsd, USD_DECIMALS, 0, true)}
                          showDollar={true}
                        />
                      </>
                    )}
                  />
                ) : (
                  <div
                    className="skeleton-box"
                    style={{
                      width: 48,
                    }}
                  />
                )}
              </div>
            </TokenCardInfo>
            <TokenCardInfo>
              <div className="label">
                <span>Market Cap</span>
              </div>
              <div className={`value`}>
                {gmxMarketCap ? (
                  <TooltipComponent
                    position="right-bottom"
                    className="nowrap excep"
                    handle={`$${formatAmount(gmxMarketCap, USD_DECIMALS, 0, true)}`}
                    renderContent={() => (
                      <>
                        <StatsTooltipRow
                          label={t`Marketcap on Fantom`}
                          value={formatAmount(gmxMarketCapFantom, USD_DECIMALS, 0, true)}
                          showDollar={true}
                        />
                        <StatsTooltipRow
                          label={t`Marketcap on Optimism`}
                          value={formatAmount(gmxMarketCapOp, USD_DECIMALS, 0, true)}
                          showDollar={true}
                        />
                        <StatsTooltipRow
                          label={t`Marketcap on Arbitrum`}
                          value={formatAmount(gmxMarketCapArbitrum, USD_DECIMALS, 0, true)}
                          showDollar={true}
                        />
                        <StatsTooltipRow
                          label={t`Marketcap on Base`}
                          value={formatAmount(gmxMarketCapBase, USD_DECIMALS, 0, true)}
                          showDollar={true}
                        />
                        <div className="divider"></div>
                        <StatsTooltipRow
                          label={t`Total`}
                          value={formatAmount(gmxMarketCap, USD_DECIMALS, 0, true)}
                          showDollar={true}
                        />
                      </>
                    )}
                  />
                ) : (
                  <div
                    className="skeleton-box"
                    style={{
                      width: 48,
                    }}
                  />
                )}
              </div>
            </TokenCardInfo>
          </TokenCardContent>
          <TokenAPR>
            <div className="row ftm">
              <div className="title">
                <img src={ftm24Icon} alt="ftm-symbol" />
                <div className="apr-wrap">
                  APY
                  <span>{formatNumber(mmyApyFantom, 2)}%</span>
                </div>
              </div>
              <BuyLink to="/buy_mmy" className="btn-buy border-btn" network={FANTOM}>
                Buy
              </BuyLink>
            </div>
            <div className="row op">
              <div className="title">
                <img src={op24Icon} alt="ftm-symbol" />
                <div className="apr-wrap">
                  APY
                  <span>{formatNumber(mmyApyOp, 2)}%</span>
                </div>
              </div>
              <BuyLink to="/buy_mmy" className="btn-buy border-btn" network={OP}>
                Buy
              </BuyLink>
            </div>
            <div className="row arb">
              <div className="title">
                <img src={arb24Icon} alt="ftm-symbol" />
                <div className="apr-wrap">
                  APY
                  <span>{formatNumber(mmyApyArb, 2)}%</span>
                </div>
              </div>
              <BuyLink to="/buy_mmy" className="btn-buy border-btn" network={ARBITRUM}>
                Buy
              </BuyLink>
            </div>
            <div className="row base">
              <div className="title">
                <img src={base24Icon} alt="base-symbol" />
                <div className="apr-wrap">
                  APY
                  <span>{formatNumber(mmyApBase, 2)}%</span>
                </div>
              </div>
              <BuyLink to="/buy_mmy" className="btn-buy border-btn" network={BASE}>
                Buy
              </BuyLink>
            </div>
          </TokenAPR>
        </TokenCard>
        <TokenCard>
          <img src={purple_corner_ic} alt="purple_corner_ic" />
          <CardTitle>
            <TokenImg>
              <img src={glp40Icon} alt="mlp40Icon" />
            </TokenImg>
            <TokenInfo>
              <div>
                <div className="token-symbol">
                  MLP{" "}
                  <ExternalLink href="https://docs.mummy.finance/earn/mlp">
                    <ArrowIcon isLightTheme={isLightTheme} />
                  </ExternalLink>
                </div>
                <div className="token-desc">Mummy Liquidity Pool Token</div>

                {/* <div className="token-price">
                  {glpPrice ? `$${formatAmount(glpPrice, USD_DECIMALS, 3, true)}` : <div className="skeleton-box" style={{
                    width: 48
                  }} />}
                </div> */}
                <div className="token-price">
                  Price:{" "}
                  {!glpPrice && (
                    <div
                      className="skeleton-box"
                      style={{
                        width: 64,
                        height: 19.59,
                      }}
                    />
                  )}
                  {glpPrice && (
                    <TooltipComponent
                      position="left-bottom"
                      className="nowrap excep"
                      handle={"$" + formatAmount(glpPrice, USD_DECIMALS, 2, true)}
                      renderContent={() => (
                        <div
                          className="customTooltip"
                          style={{
                            minWidth: 220,
                          }}
                        >
                          <StatsTooltipRow
                            label={`Price on Fantom`}
                            value={formatAmount(glpPriceFantom, USD_DECIMALS, 2, true)}
                            showDollar={true}
                          />
                          {/* <StatsTooltipRow
                            label={`Price on Fantom legacy`}
                            value={formatAmount(glpPriceFantomLegacy, USD_DECIMALS, 2, true)}
                            showDollar={true}
                          /> */}
                          <StatsTooltipRow
                            label={`Price on Optimism`}
                            value={formatAmount(glpPriceOp, USD_DECIMALS, 2, true)}
                            showDollar={true}
                          />
                          <StatsTooltipRow
                            label={t`Price on Arbitrum`}
                            value={formatAmount(glpPriceArb, USD_DECIMALS, 2, true)}
                            showDollar={true}
                          />
                          <StatsTooltipRow
                            label={t`Price on Base`}
                            value={formatAmount(glpPriceBase, USD_DECIMALS, 2, true)}
                            showDollar={true}
                          />
                        </div>
                      )}
                    />
                  )}
                </div>
              </div>{" "}
              {/* <ExternalLink href="https://docs.mummy.finance/mlp" target={"_blank"} className="btn-readmore border-btn">
                Read more
              </ExternalLink> */}
            </TokenInfo>
          </CardTitle>

          <TokenCardContent>
            <TokenCardInfo>
              <div className="label">
                <span>Supply</span>
              </div>
              <div className={`value`}>
                {totalGlpSupply ? (
                  <TooltipComponent
                    position={isTabletOnly ? "left-bottom" : "right-bottom"}
                    className="nowrap excep"
                    handle={`${formatAmount(totalGlpSupply, GLP_DECIMALS, 0, true)} MLP`}
                    renderContent={() => (
                      <>
                        <StatsTooltipRow
                          label={t`Supply on Fantom`}
                          value={`${formatAmount(glpSupplyFantom, GLP_DECIMALS, 0, true)} MLP`}
                          showDollar={false}
                        />
                        {/* <StatsTooltipRow
                          label={t`Supply on Fantom legacy`}
                          showDollar={false}
                          value={`${formatAmount(glpSupplyFantomLegacy, GLP_DECIMALS, 0, true)} MLP`}
                        /> */}
                        <StatsTooltipRow
                          label={t`Supply on Optimism`}
                          showDollar={false}
                          value={`${formatAmount(glpSupplyOp, GLP_DECIMALS, 0, true)} MLP`}
                        />
                        <StatsTooltipRow
                          label={t`Supply on Arbitrum`}
                          showDollar={false}
                          value={`${formatAmount(glpSupplyArbitrum, GLP_DECIMALS, 0, true)} MLP`}
                        />
                        <StatsTooltipRow
                          showDollar={false}
                          label={t`Supply on Base`}
                          value={`${formatAmount(glpSupplyBase, GLP_DECIMALS, 0, true)} MLP`}
                        />
                        <div className="divider"></div>
                        <StatsTooltipRow
                          label={t`Total`}
                          showDollar={false}
                          value={`${formatAmount(totalGlpSupply, GLP_DECIMALS, 0, true)} MLP`}
                        />
                      </>
                    )}
                  />
                ) : (
                  <div
                    className="skeleton-box"
                    style={{
                      width: 48,
                    }}
                  />
                )}
              </div>
            </TokenCardInfo>
            <TokenCardInfo>
              <div className="label">
                <span>Total Staked</span>
              </div>
              <div className="value">
                {glpMarketCap ? (
                  <TooltipComponent
                    position="right-bottom"
                    className="nowrap excep"
                    handle={`$${formatAmount(glpMarketCap, USD_DECIMALS, 0, true)}`}
                    renderContent={() => (
                      <>
                        <StatsTooltipRow
                          label={t`Staked on Fantom`}
                          value={formatAmount(glpMarketCapFantom, USD_DECIMALS, 0, true)}
                          showDollar={true}
                        />
                        {/* <StatsTooltipRow
                          label={t`Staked on Fantom legacy`}
                          value={formatAmount(glpMarketCapFantomLegacy, USD_DECIMALS, 0, true)}
                          showDollar={true}
                        /> */}
                        <StatsTooltipRow
                          label={t`Staked on Optimism`}
                          value={formatAmount(glpMarketCapOp, USD_DECIMALS, 0, true)}
                          showDollar={true}
                        />
                        <StatsTooltipRow
                          label={t`Staked on Arbitrum`}
                          value={formatAmount(glpMarketCapArbitrum, USD_DECIMALS, 0, true)}
                          showDollar={true}
                        />
                        <StatsTooltipRow
                          label={t`Staked on Base`}
                          value={formatAmount(glpMarketCapBase, USD_DECIMALS, 0, true)}
                          showDollar={true}
                        />
                        <div className="divider"></div>
                        <StatsTooltipRow
                          label={t`Total`}
                          value={formatAmount(glpMarketCap, USD_DECIMALS, 0, true)}
                          showDollar={true}
                        />
                      </>
                    )}
                  />
                ) : (
                  <div
                    className="skeleton-box"
                    style={{
                      width: 48,
                    }}
                  />
                )}
              </div>
            </TokenCardInfo>
            <TokenCardInfo>
              <div className="label">
                <span>Market Cap</span>
              </div>
              <div className="value">
                {glpMarketCap ? (
                  <TooltipComponent
                    position="right-bottom"
                    className="nowrap excep"
                    handle={`$${formatAmount(glpMarketCap, USD_DECIMALS, 0, true)}`}
                    renderContent={() => (
                      <>
                        <StatsTooltipRow
                          label={t`Marketcap on Fantom`}
                          value={formatAmount(glpMarketCapFantom, USD_DECIMALS, 0, true)}
                          showDollar={true}
                        />
                        {/* <StatsTooltipRow
                          label={t`Marketcap on Fantom legacy`}
                          value={formatAmount(glpMarketCapFantomLegacy, USD_DECIMALS, 0, true)}
                          showDollar={true}
                        /> */}
                        <StatsTooltipRow
                          label={t`Marketcap on Optimism`}
                          value={formatAmount(glpMarketCapOp, USD_DECIMALS, 0, true)}
                          showDollar={true}
                        />
                        <StatsTooltipRow
                          label={t`Marketcap on Arbitrum`}
                          value={formatAmount(glpMarketCapArbitrum, USD_DECIMALS, 0, true)}
                          showDollar={true}
                        />
                        <StatsTooltipRow
                          label={t`Marketcap on Base`}
                          value={formatAmount(glpMarketCapBase, USD_DECIMALS, 0, true)}
                          showDollar={true}
                        />
                        <div className="divider"></div>
                        <StatsTooltipRow
                          label={t`Total`}
                          value={formatAmount(glpMarketCap, USD_DECIMALS, 0, true)}
                          showDollar={true}
                        />
                      </>
                    )}
                  />
                ) : (
                  <div
                    className="skeleton-box"
                    style={{
                      width: 48,
                    }}
                  />
                )}
              </div>
            </TokenCardInfo>
            <TokenCardInfo>
              <div className="label">
                <span>Stablecoin Pct.</span>
              </div>
              <div className="value">{stablePercentage}%</div>
            </TokenCardInfo>
          </TokenCardContent>
          <TokenAPR>
            <div className="row ftm">
              <div className="title">
                <img src={ftm24Icon} alt="ftm-symbol" />
                <div className="apr-wrap">
                  APY
                  <span>{formatNumber(mlpApyFantom, 2)}%</span>
                </div>
              </div>
              <BuyLink to="/buy_mlp" className="btn-buy border-btn" network={FANTOM}>
                Buy
              </BuyLink>
            </div>
            <div className="row op">
              <div className="title">
                <img src={op24Icon} alt="ftm-symbol" />
                <div className="apr-wrap">
                  APY
                  <span>{formatNumber(mlpApyOp, 2)}%</span>
                </div>
              </div>
              <BuyLink to="/buy_mlp" className="btn-buy border-btn" network={OP}>
                Buy
              </BuyLink>
            </div>
            <div className="row arb">
              <div className="title">
                <img src={arb24Icon} alt="ftm-symbol" />
                <div className="apr-wrap">
                  APY
                  <span>{formatNumber(mlpApyArb, 2)}%</span>
                </div>
              </div>
              <BuyLink to="/buy_mlp" className="btn-buy border-btn" network={ARBITRUM}>
                Buy
              </BuyLink>
            </div>
            <div className="row base">
              <div className="title">
                <img src={base24Icon} alt="base-symbol" />
                <div className="apr-wrap">
                  APY
                  <span>{formatNumber(mlpApBase, 2)}%</span>
                </div>
              </div>
              <BuyLink to="/buy_mlp" className="btn-buy border-btn" network={BASE}>
                Buy
              </BuyLink>
            </div>
          </TokenAPR>
        </TokenCard>
        <TokenCard>
          <img src={green_corner_ic} alt="green_corner_ic" />
          <CardTitle>
            <TokenImg className="golden">
              <img src={msp40Icon} alt="msp40Icon" />
            </TokenImg>
            <TokenInfo>
              <div>
                <div className="token-symbol">
                  MSLP{" "}
                  <ExternalLink href="https://docs.mummy.finance/earn/mslp">
                    <ArrowIcon isLightTheme={isLightTheme} />
                  </ExternalLink>
                </div>
                <div className="token-desc">Mummy Stable Liquidity Pool Token</div>

                {/* <div className="token-price">
                  {glpPrice ? `$${formatAmount(glpPrice, USD_DECIMALS, 3, true)}` : <div className="skeleton-box" style={{
                    width: 48
                  }} />}
                </div> */}
                <div className="token-price">
                  Price:{" "}
                  {!mspPrice && (
                    <div
                      className="skeleton-box"
                      style={{
                        width: 64,
                        height: 19.59,
                      }}
                    />
                  )}
                  {mspPrice && (
                    <TooltipComponent
                      position="left-bottom"
                      className="nowrap excep"
                      handle={"$" + formatAmount(mspPrice, 30, 2)}
                      renderContent={() => (
                        <div
                          className="customTooltip"
                          style={{
                            minWidth: 220,
                          }}
                        >
                          <StatsTooltipRow
                            label={`Price on Fantom`}
                            value={formatAmount(mspPrices[FANTOM], 30, 2)}
                            showDollar={true}
                          />
                          <StatsTooltipRow
                            label={`Price on Optimism`}
                            value={formatAmount(mspPrices[OP], 30, 2)}
                            showDollar={true}
                          />
                        </div>
                      )}
                    />
                  )}
                </div>
              </div>{" "}
              {/* <ExternalLink
                href="https://docs.mummy.finance/earn/mslp"
                target={"_blank"}
                className="btn-readmore border-btn"
              >
                Read more
              </ExternalLink> */}
            </TokenInfo>
          </CardTitle>

          <TokenCardContent>
            <TokenCardInfo>
              <div className="label">
                <span>Supply</span>
              </div>
              <div className={`value `}>
                {totalSupplyNlp ? (
                  <TooltipComponent
                    position="right-bottom"
                    className="nowrap"
                    handle={`${formatAmount(totalSupplyNlp, nlpDecimalFantom, 0, true)} MSLP`}
                    renderContent={() => (
                      <>
                        <StatsTooltipRow
                          label={t`Supply on Fantom`}
                          value={`${formatAmount(totalSupplyNlpFantom, nlpDecimalFantom, 0, true)} MSLP`}
                          showDollar={false}
                        />
                        <StatsTooltipRow
                          label={t`Supply on Optimism`}
                          showDollar={false}
                          value={`${formatAmount(totalSupplyNlpOp, nlpDecimalFantom, 0, true)} MSLP`}
                        />
                        <div className="divider"></div>
                        <StatsTooltipRow
                          label={t`Total`}
                          showDollar={false}
                          value={`${formatAmount(totalSupplyNlp, nlpDecimalFantom, 0, true)} MSLP`}
                        />
                      </>
                    )}
                  />
                ) : (
                  <div
                    className="skeleton-box"
                    style={{
                      width: 48,
                    }}
                  />
                )}
              </div>
            </TokenCardInfo>
            <TokenCardInfo>
              <div className="label">
                <span>Total Staked</span>
              </div>
              <div className="value">
                {totalStakedAll ? (
                  <TooltipComponent
                    position="right-bottom"
                    className="nowrap"
                    handle={`$${formatAmount(totalStakedAll, nlpDecimalFantom, 0, true)}`}
                    renderContent={() => (
                      <>
                        <StatsTooltipRow
                          label={t`Total Staked on Fantom`}
                          value={formatAmount(totalStaked[FANTOM], nlpDecimalFantom, 0, true)}
                          showDollar={true}
                        />
                        <StatsTooltipRow
                          label={t`Total Staked on Optimism`}
                          showDollar={true}
                          value={formatAmount(totalStaked[OP], nlpDecimalFantom, 0, true)}
                        />
                        <div className="divider"></div>
                        <StatsTooltipRow
                          label={t`Total`}
                          value={formatAmount(totalStakedAll, nlpDecimalFantom, 0, true)}
                          showDollar={true}
                        />
                      </>
                    )}
                  />
                ) : (
                  <div
                    className="skeleton-box"
                    style={{
                      width: 48,
                    }}
                  />
                )}
              </div>
            </TokenCardInfo>
            <TokenCardInfo>
              <div className="label">
                <span>Market Cap</span>
              </div>
              <div className="value">
                {/* {mspMarketCap ? (
                  <>${formatAmount(mspMarketCap, 18, 0, true)}</>
                ) : (
                  <div
                    className="skeleton-box"
                    style={{
                      width: 48,
                    }}
                  />
                )} */}
                {mspMarketCap ? (
                  <TooltipComponent
                    position="right-bottom"
                    className="nowrap"
                    handle={`$${formatAmount(mspMarketCap, GLP_DECIMALS, 0, true)}`}
                    renderContent={() => (
                      <>
                        <StatsTooltipRow
                          label={t`Marketcap on Fantom`}
                          value={formatAmount(mspMarketCapFantom, GLP_DECIMALS, 0, true)}
                          showDollar={true}
                        />
                        <StatsTooltipRow
                          label={t`Marketcap on Optimism`}
                          value={formatAmount(mspMarketCapOp, GLP_DECIMALS, 0, true)}
                          showDollar={true}
                        />
                        <div className="divider"></div>
                        <StatsTooltipRow
                          label={t`Total`}
                          value={formatAmount(mspMarketCap, GLP_DECIMALS, 0, true)}
                          showDollar={true}
                        />
                      </>
                    )}
                  />
                ) : (
                  <div
                    className="skeleton-box"
                    style={{
                      width: 48,
                    }}
                  />
                )}
              </div>
            </TokenCardInfo>
            {/* <TokenCardInfo>
              <div className="label">
                <span>Stablecoin Pct.</span>
              </div>
              <div className="value">100%</div>
            </TokenCardInfo> */}
          </TokenCardContent>
          <TokenAPR>
            <div className="row ftm">
              <div className="title">
                <img src={ftm24Icon} alt="ftm-symbol" />
                <div className="apr-wrap">
                  APR
                  <span>
                    {/* {apr === undefined && (
                      <div
                        className="skeleton-box"
                        style={{
                          width: 48,
                          height: 19.6,
                        }}
                      />
                    )} */}
                    {aprFantom && aprFantom.total !== undefined && !isNaN(aprFantom.total) ? (
                      <>{aprFantom.total === 0 ? "0.000" : `${formatNumber(aprFantom.total, 2)}%`}</>
                    ) : (
                      <div className="skeleton-box" style={{ width: "60px" }} />
                    )}
                  </span>
                </div>
              </div>
              <BuyLink to="/buy/mint" className="btn-buy border-btn" network={FANTOM}>
                Buy
              </BuyLink>
            </div>
            <div className="row op">
              <div className="title">
                <img src={op24Icon} alt="ftm-symbol" />
                <div className="apr-wrap">
                  APR
                  <span>
                    {aprOp && aprOp.total !== undefined && !isNaN(aprOp.total) ? (
                      <>{aprOp.total === 0 ? "0.000%" : `${formatNumber(aprOp.total, 2)}%`}</>
                    ) : (
                      <div className="skeleton-box" style={{ width: "60px" }} />
                    )}
                  </span>
                </div>
              </div>
              <BuyLink to="/buy/mint" className="btn-buy border-btn" network={OP}>
                Buy
              </BuyLink>
            </div>
            {/* <div className="row op">
              <div className="title">
                <img src={op24Icon} alt="ftm-symbol" />
                <div className="apr-wrap">
                  APR
                  <span>
                    <APRLabel   chainId={OP} label="glpAprTotal" key="OP" />
                  </span>
                </div>
              </div>
              <BuyLink to="/buy_mlp" className="btn-buy border-btn" network={OP}>
                Buy
              </BuyLink>
            </div>
            <div className="row arb">
              <div className="title">
                <img src={arb24Icon} alt="ftm-symbol" />
                <div className="apr-wrap">
                  APR
                  <span>
                    <APRLabel   chainId={ARBITRUM} label="glpAprTotal" key="ARBITRUM" />
                  </span>
                </div>
              </div>
              <BuyLink to="/buy_mlp" className="btn-buy border-btn" network={ARBITRUM}>
                Buy
              </BuyLink>
            </div>
            <div className="row base">
              <div className="title">
                <img src={base24Icon} alt="base-symbol" />
                <div className="apr-wrap">
                  APR
                  <span>
                    <APRLabel   chainId={BASE} label="glpAprTotal" key="BASE" />
                  </span>
                </div>
              </div>
              <BuyLink to="/buy_mlp" className="btn-buy border-btn" network={BASE}>
                Buy
              </BuyLink>
            </div> */}
          </TokenAPR>
        </TokenCard>
      </TokenCardContainer>
      <Title>
        <span>MMY-WFTM LP</span>
        <div className="desc">Provide MMY-WFTM LP on Equalizer to earn yield</div>
      </Title>
      <div className="lp">
        <img src={blue_corner_ic} alt="blue_corner_ic" />
        <div className="lp-col">
          <img src={mmy_ftm_coin} alt="mmy_ftm_coin" />
          <div className="lp-info">
            <div className="lp-symbol">MMY-WFTM LP</div>
            <div className="lp-price">
              Price: <span>{"$" + formatAmount(pairPrice, USD_DECIMALS, 2, true)}</span>
            </div>
          </div>
        </div>

        <div className="lp-col tablet">
          <Link to="/vault" className="default-btn">
            Start Earning
          </Link>
        </div>
        <div className="lp-col">
          <div className="lp-col-key">Supply</div>
          <div className="lp-col-value">{formatAmount(poolSupply, 18, 0, true)}</div>
        </div>
        <div className="lp-col">
          <div className="lp-col-key">Total Staked</div>
          <div className="lp-col-value">${formatAmount(totalStakedPair, USD_DECIMALS, 0, true)}</div>
        </div>
        <div className="lp-col">
          <div className="lp-col-key">Market Cap</div>
          <div className="lp-col-value">${formatAmount(poolSupplyUsd, USD_DECIMALS, 0, true)}</div>
        </div>
        <div className="lp-col">
          <div className="lp-col-key">APY</div>
          <div className="lp-col-value green">{apy.apyMMY_FTM[FANTOM]}%</div>
        </div>
        <div className="lp-col">
          <Link to="/vault" className="default-btn">
            Start Earning
          </Link>
        </div>
      </div>
    </Wrapper>
  );
};

const TokenAPR = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: stretch;
  flex-direction: row;
  border: 1px solid #ffffff1a;
  border-radius: 16px;
  overflow: hidden;
  margin-top: auto;

  .apr-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;

    color: var(--Text-Text_Primary, #fff);
    font-size: 14px;
    font-weight: 700;
    line-height: 140%; /* 19.6px */
    & > span {
      color: var(--main-primary, #03f5ae);
      font-size: 14px;
      font-weight: 700;
      line-height: 140%; /* 19.6px */
      display: flex;
    }

    @media screen and (max-width: 767px) {
      flex-direction: row;
      gap: 4px;
    }
  }

  > .row {
    width: 100%;
    padding: 18px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 8px;
    background-image: linear-gradient(180deg, rgba(19, 181, 236, 0.1) 0%, rgba(19, 181, 236, 0) 100%);

    &.op {
      background-image: linear-gradient(180deg, rgba(255, 4, 32, 0.1) 0%, rgba(255, 4, 32, 0) 100%);
    }
    &.arb {
      background-image: linear-gradient(180deg, rgba(0, 119, 229, 0.1) 0%, rgba(0, 119, 229, 0) 100%);
    }
    &.base {
      background-image: linear-gradient(180deg, rgba(0, 82, 255, 0.1) 0%, rgba(0, 82, 255, 0) 100%);
    }

    .title {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: #ffffff99;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;

      > span {
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        color: #03f5ae;
      }

      @media screen and (max-width: 767px) {
        flex-direction: row;
      }
    }

    .btn-buy {
      display: flex;
      height: 40px;
      padding: 8px 16px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      align-self: stretch;
      border-radius: 8px;
      border: 1px solid var(--stroke-10, rgba(255, 255, 255, 0.1));
      background-color: transparent;
      /* color: #080715; */
      text-decoration: none;

      color: var(--white-100, #fff);
      font-size: 14px;
      font-weight: 500;
      line-height: 140%; /* 19.6px */

      &:hover {
        color: #03f5ae !important;

        box-shadow: none !important;
        border: 1px solid #03f5ae !important;
      }
    }

    @media (max-width: 767px) {
      flex-direction: row;

      .btn-buy {
        width: fit-content;
      }
    }
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`;

const TokenCardInfo = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .label {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 4px;
  }
  .value {
    font-weight: 700;
    font-size: 14px;
    line-height: 140%;
  }
  /* .Tooltip-popup {
    left: 0;
    right: unset;
  } */

  @media (min-width: 768px) and (max-width: 1023px) {
    flex-direction: column;
    align-items: flex-start;
  }

  @media (max-width: 767px) {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    /* .Tooltip-popup {
      left: unset;
      right: 0;
    } */
  }
`;

const TokenCardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  gap: 12px;
  margin: 16px 0;

  @media (min-width: 768px) and (max-width: 1023px) {
    flex-direction: row;
  }

  @media (max-width: 767px) {
    gap: 12px;
  }
`;

const TokenInfo = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }

  .token-symbol {
    color: var(--Text-Text_Primary, #fff);
    font-size: 16px;
    font-weight: 700;
    line-height: 120%; /* 22.4px */

    display: flex;
    align-items: center;
    gap: var(--Border_radius, 4px);

    & > a {
      &:hover {
        svg {
          g {
            opacity: 1;
          }
          path {
            fill: #03f5ae;
          }
        }
      }
    }
  }
  .token-desc {
    color: var(--Text-Text_Secondary, rgba(255, 255, 255, 0.6));
    font-size: 14px;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
  }
  .token-price {
    color: var(--Text-Text_Secondary, rgba(255, 255, 255, 0.6));
    font-size: 14px;
    font-weight: 400;
    line-height: 140%; /* 19.6px */

    .Tooltip-handle {
      color: var(--Primary, #03f5ae);
      font-size: 14px;
      font-weight: 700;
      line-height: 140%; /* 19.6px */
    }
  }
  /* .btn-readmore {
    height: 40px;
    display: flex;
    text-decoration: none;
    align-items: center;
    padding: 8px 16px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    color: #fff;
  } */
`;

const TokenImg = styled.div`
  img {
    width: 56px;
    height: 56px;
  }
`;

const CardTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* position: absolute;
  top: 0;
  left: 24px;
  right: 24px; */

  /* @media (max-width: 767px) {
    left: 16px;
    right: 16px;
  } */
`;

const TokenCard = styled.div`
  padding: 24px;
  background-color: #191b2e;
  border-radius: 12px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border: 1px solid transparent;
  overflow: hidden;

  & > img {
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 767px) {
    padding: 16px;
  }
`;

const TokenCardContainer = styled.div`
  margin-top: 16px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;

  .content-mlp {
    grid-template-columns: repeat(4, 1fr);

    @media (max-width: 767px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  @media (max-width: 1023px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (max-width: 767px) {
    gap: 16px;
  }
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Base-Unit-XS-8, 8px);

  &:not(:first-child) {
    margin-top: 64px;

    @media screen and (max-width: 767px) {
      margin-top: 48px;
    }
  }

  span {
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;
    margin-bottom: 4px;
  }

  div {
    color: var(--Text-Text_Secondary, rgba(255, 255, 255, 0.6));
    font-size: 14px;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
  }
`;

const Wrapper = styled.div`
  margin: 0 0 64px;

  .lp {
    margin-top: 16px;
    display: flex;
    padding: 24px;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    border-radius: 16px;
    border: 1px solid transparent;
    background: linear-gradient(180deg, #191b2e 0%, #191b2e 100%);
    position: relative;
    overflow: hidden;

    & > img {
      position: absolute;
      top: 0;
      left: 0;
    }

    & > div.lp-col {
      &.tablet {
        display: none;
      }

      .default-btn {
        color: #000;
        font-weight: 700;
      }

      &:has(.lp-symbol) {
        display: flex;
        align-items: center;
        gap: 24px;

        .lp-info {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: var(--Border_radius, 4px);
          width: 178px;
        }

        .lp-symbol {
          display: flex;
          align-items: center;
          gap: var(--Border_radius, 4px);
          color: var(--Text-Text_Primary, #fff);
          font-size: 16px;
          font-weight: 700;
          line-height: 120%; /* 22.4px */
        }

        .lp-price {
          color: var(--Text-Text_Secondary, rgba(255, 255, 255, 0.6));
          font-size: 14px;
          font-weight: 400;
          line-height: 140%; /* 19.6px */

          span {
            color: var(--Primary, #03f5ae);
            font-size: 14px;
            font-weight: 700;
            line-height: 140%; /* 19.6px */
          }
        }
      }

      &:not(:has(.default-btn, img)) {
        flex: 1;

        &:has(.lp-sumbol) {
        }

        .lp-col-key {
          color: var(--Text-Text_Secondary, rgba(255, 255, 255, 0.6));
          font-size: 14px;
          font-weight: 400;
          line-height: 140%; /* 19.6px */
        }

        .lp-col-value {
          color: var(--Text-Text_Primary, #fff);
          font-size: 14px;
          font-weight: 700;
          line-height: 140%; /* 19.6px */

          &.green {
            color: var(--Primary, #03f5ae);
          }

          &:has(span) {
            display: flex;
            align-items: center;
            gap: var(--Border_radius, 4px);
            align-self: stretch;
          }

          span {
            color: var(--Text-Text_Secondary, rgba(255, 255, 255, 0.6));
            font-size: 14px;
            font-weight: 400;
            line-height: 140%; /* 19.6px */
          }
        }
      }
    }

    @media screen and (max-width: 1023px) {
      display: grid;
      grid-template-columns: repeat(4, 1fr);

      & > div.lp-col {
        &.tablet {
          display: block;
          grid-column: 3/5;
          margin-left: auto;
        }

        &:has(.lp-symbol) {
          grid-column: 1/3;
        }

        &:not(.tablet):has(.default-btn) {
          display: none;
        }
      }
    }

    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
      gap: 12px;
      padding: 16px;

      & > div.lp-col {
        grid-column: unset !important;

        &:has(.lp-symbol) {
          margin-bottom: 12px;
        }

        &.tablet {
          display: none;
        }

        &:not(.tablet):has(.default-btn) {
          display: block;

          .default-btn {
            width: 100%;
          }
        }

        &:has(.lp-col-key, .lp-col-value) {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }
  }

  @media (max-width: 767px) {
    margin: 0;
    margin-bottom: 48px;

    div.Tooltip-popup:has(.customTooltip) {
      transform: translateX(-100px);
    }
  }

  &.theme--light {
    ${TokenCard} {
      border: 1px solid var(--Border, rgba(13, 26, 22, 0.07));
      background: var(--Nature-1, #fff);

      /* ${TokenImg} {
        background: var(
          --v3-bg_icon,
          linear-gradient(139deg, rgba(3, 245, 174, 0.15) 8.79%, rgba(3, 245, 174, 0) 100%)
        );

        &.golden {
          background: linear-gradient(139deg, rgba(245, 206, 3, 0.3) 8.79%, rgba(245, 206, 3, 0) 100%);
        }
      } */

      ${TokenInfo} {
        .token-symbol {
          color: var(--Text-Text_Primary, #0d1a16);

          & > a {
            &:hover {
              svg {
                g {
                  opacity: 1;
                }
                path {
                  fill: #02b27f;
                }
              }
            }
          }
        }

        .token-desc,
        .token-price {
          color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7)) !important;

          span {
            color: #02b27f !important;
          }
        }
      }

      ${TokenAPR} {
        border-color: var(--Border, rgba(13, 26, 22, 0.07)) !important;

        .row {
          background: linear-gradient(180deg, rgba(19, 181, 236, 0.1) 0%, rgba(19, 181, 236, 0) 100%);

          &.op {
            background: linear-gradient(180deg, rgba(255, 4, 32, 0.1) 0%, rgba(255, 4, 32, 0) 100%);
          }

          &:not(.ftm, .op) {
            background: linear-gradient(180deg, rgba(0, 119, 229, 0.1) 0%, rgba(0, 119, 229, 0) 100%);
          }

          &.base {
            background: linear-gradient(180deg, rgba(0, 82, 255, 0.1) 0%, rgba(0, 82, 255, 0) 100%);
          }

          .title {
            .apr-wrap {
              color: var(--Text-Text_Primary, #0d1a16);

              span {
                color: var(--Primary, #02b27f);
              }
            }
          }
        }

        .btn-buy.border-btn {
          color: var(--Text-Text_Primary, #0d1a16) !important;
          border: 1px solid var(--Border, rgba(13, 26, 22, 0.07)) !important;

          &:hover {
            color: #02b27f !important;
            border-color: #02b27f !important;
          }
        }
      }

      ${TokenCardInfo} {
        .label span {
          color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7)) !important;
        }
      }
    }

    .lp {
      border: 1px solid var(--Border, rgba(13, 26, 22, 0.07));
      background: var(--Nature-1, #fff);

      .lp-info {
        .lp-symbol {
          color: var(--Text-Text_Primary, #0d1a16) !important;
        }

        .lp-price {
          color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7)) !important;

          span {
            color: var(--Primary, #02b27f) !important;
          }
        }
      }

      .lp-col-key {
        color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7)) !important;
      }

      .lp-col-value {
        color: var(--Text-Text_Primary, #0d1a16) !important;

        &.green {
          color: var(--Primary, #02b27f) !important;
        }

        span {
          color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7)) !important;
        }
      }
    }
  }
`;
export default Tokens;
