import React, { useState, useEffect, useMemo } from "react";
import cx from "classnames";

import { BiChevronDown } from "react-icons/bi";

import Modal from "../Modal/Modal";

import dropDownIcon from "img/DROP_DOWN.svg";
// import triangleIcon from "img/triangle.svg";
import "./TokenSelector.css";
import TooltipWithPortal from "../Tooltip/TooltipWithPortal";
import { bigNumberify, expandDecimals, formatAmount } from "lib/numbers";
import { getToken } from "config/tokens";
import { importImage } from "lib/legacy";
// import { t } from "@lingui/macro";
import searchIc from "img/icons/search.svg";
import ArrowDownSelectIcon from "components/Icon/ArrowDownSelect";
import { formatUnits } from "ethers/lib/utils";

export default function TokenSelector(props) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const tokenInfo = getToken(props.chainId, props.tokenAddress);
  const {
    tokens,
    mintingCap,
    infoTokens,
    showMintingCap,
    disabled,
    selectedTokenLabel,
    showBalances = true,
    showTokenImgInDropdown = false,
    showSymbolImage = false,
    showNewCaret = false,
    getTokenState = () => ({ disabled: false, message: null }),
    disableBodyScrollLock,
  } = props;

  const visibleTokens = tokens.filter((t) => !t.isTempHidden);

  const onSelectToken = (token) => {
    setIsModalVisible(false);
    props.onSelectToken(token);
  };

  useEffect(() => {
    if (isModalVisible) {
      setSearchKeyword("");
    }
  }, [isModalVisible]);

  const filteredTokens = visibleTokens.filter((item) => {
    return (
      item.name.toLowerCase().indexOf(searchKeyword.toLowerCase()) > -1 ||
      item.symbol.toLowerCase().indexOf(searchKeyword.toLowerCase()) > -1 ||
      item.address.toLowerCase().indexOf(searchKeyword.toLowerCase()) > -1
    );
  });

  const computedTokens = useMemo(() => {
    const tokenWithInfos = filteredTokens.map((token) => {
      const tokenPopupImage = importImage(`ic_${token.symbol.toLowerCase()}_40.svg`);
      let info = infoTokens ? infoTokens[token.address] : {};
      let mintAmount;
      let balance = info.balance;
      if (showMintingCap && mintingCap && info.usdgAmount) {
        mintAmount = mintingCap.sub(info.usdgAmount);
      }
      if (mintAmount && mintAmount.lt(0)) {
        mintAmount = bigNumberify(0);
      }
      let balanceUsd;
      if (balance && info.maxPrice) {
        balanceUsd = balance.mul(info.maxPrice).div(expandDecimals(1, token.decimals));
      }

      const tokenState = getTokenState(info) || {};

      return {
        ...token,
        tokenState,
        tokenPopupImage,
        balance,
        mintAmount,
        balanceUsd,
      };
    });

    const sorted = tokenWithInfos.toSorted((aToken, bToken) => {
      //sort by name if balance == 0
      if (aToken?.balance?.eq(0) && bToken?.balance?.eq(0)) {
        if (aToken.name < bToken.name) {
          return -1;
        }
        if (aToken.name > bToken.name) {
          return 1;
        }
      }

      if (bToken?.balance && aToken?.balance && bToken?.decimals && aToken?.decimals) {
        //sort by balance
        return (
          Number(formatUnits(bToken?.balance, bToken?.decimals)) -
          Number(formatUnits(aToken?.balance, aToken?.decimals))
        );
      }

      return {};
    });

    return sorted;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filteredTokens), JSON.stringify(infoTokens), mintingCap, showMintingCap]);

  if (!tokenInfo) {
    return null;
  }

  const tokenImage = showSymbolImage && importImage(`ic_${tokenInfo.symbol.toLowerCase()}_24.svg`);

  const onSearchKeywordChange = (e) => {
    setSearchKeyword(e.target.value);
  };

  const _handleKeyDown = (e) => {
    if (e.key === "Enter" && filteredTokens.length > 0) {
      onSelectToken(filteredTokens[0]);
    }
  };

  return (
    <div className={cx("TokenSelector", { disabled }, props.className)}>
      <Modal
        disableBodyScrollLock={disableBodyScrollLock}
        isVisible={isModalVisible}
        setIsVisible={setIsModalVisible}
        label={props.label}
      >
        <div className="TokenSelector-tokens">
          <div className="TokenSelector-token-row TokenSelector-token-input-row">
            <img src={searchIc} alt="searchIc" className="TokenSelector-token-icon" />
            <input
              type="text"
              placeholder={`WETH, USDC, Ox...`}
              value={searchKeyword}
              onChange={(e) => onSearchKeywordChange(e)}
              onKeyDown={_handleKeyDown}
              autoFocus
            />
          </div>
          {computedTokens.map(
            ({ tokenState, tokenPopupImage, balance, mintAmount, balanceUsd, ...token }, tokenIndex) => {
              return (
                <div
                  key={token.address}
                  className={cx("TokenSelector-token-row", { disabled: tokenState.disabled })}
                  onClick={() => !tokenState.disabled && onSelectToken(token)}
                >
                  {tokenState.disabled && tokenState.message && (
                    <TooltipWithPortal
                      className="TokenSelector-tooltip"
                      portalClassName="TokenSelector-tooltip-portal"
                      handle={<div className="TokenSelector-tooltip-backing" />}
                      position={tokenIndex < filteredTokens.length / 2 ? "center-bottom" : "center-top"}
                      disableHandleStyle
                      closeOnDoubleClick
                      fitHandleWidth
                      renderContent={() => tokenState.message}
                    />
                  )}
                  <div className="Token-info">
                    {showTokenImgInDropdown && <img src={tokenPopupImage} alt={token.name} className="token-logo" />}
                    <div className="Token-symbol">
                      <div className="Token-text">{token.symbol}</div>
                      <span className="text-accent">{token.name}</span>
                    </div>
                  </div>
                  <div className="Token-balance">
                    {showBalances && balance && (
                      <div className="Token-text">
                        {balance.gt(0) && formatAmount(balance, token.decimals, 4, true)}
                        {balance.eq(0) && "-"}
                      </div>
                    )}
                    <span className="text-accent">
                      {mintAmount && <div>Mintable: {formatAmount(mintAmount, token.decimals, 2, true)} USDG</div>}
                      {showMintingCap && !mintAmount && <div>-</div>}
                      {!showMintingCap && showBalances && balanceUsd && balanceUsd.gt(0) && (
                        <div className="muted">${formatAmount(balanceUsd, 30, 2, true)}</div>
                      )}
                    </span>
                  </div>
                </div>
              );
            }
          )}
          {/* <div className="Exchange-swap-button-container"></div> */}
        </div>
      </Modal>
      {selectedTokenLabel ? (
        <div className="TokenSelector-box" onClick={() => setIsModalVisible(true)}>
          {selectedTokenLabel}
          {!showNewCaret && <BiChevronDown className="TokenSelector-caret" />}
        </div>
      ) : (
        <div className="TokenSelector-box" onClick={() => setIsModalVisible(true)}>
          {showSymbolImage && <img src={tokenImage} alt={tokenInfo.symbol} className="TokenSelector-box-symbol" />}
          {tokenInfo.symbol}
          {showNewCaret && <img src={dropDownIcon} alt="Dropdown Icon" className="TokenSelector-box-caret" />}
          {!showNewCaret && <ArrowDownSelectIcon class="TokenSelector-caret" />}
        </div>
      )}
    </div>
  );
}
