import { ARBITRUM, AVALANCHE, BASE, FANTOM, OP } from "./chains";

export const SUBGRAPH_URLS = {
  [ARBITRUM]: {
    stats: "https://api.studio.thegraph.com/query/74668/mummy-arb-stats/version/latest",
    referrals: "https://api.studio.thegraph.com/query/74668/mummy-arb-referrals/version/latest",
    nissohVault: "https://api.thegraph.com/subgraphs/name/nissoh/gmx-vault",
  },
  [AVALANCHE]: {
    stats: "https://api.thegraph.com/subgraphs/name/gmx-io/gmx-avalanche-stats",
    referrals: "https://api.thegraph.com/subgraphs/name/gmx-io/gmx-avalanche-referrals",
  },
  [OP]: {
    stats: "https://api.studio.thegraph.com/query/74668/mummy-op-stats/version/latest",
    referrals: "https://api.studio.thegraph.com/query/74668/mummy-op-referrals/version/latest",
  },
  [BASE]: {
    stats: "https://api.studio.thegraph.com/query/74668/mummy-base-stats/version/latest",
    referrals: "https://api.studio.thegraph.com/query/74668/mummy-base-referrals/version/latest",
  },
  [FANTOM]: {
    stats: "https://api.studio.thegraph.com/query/74668/mummy-fantom-stats/version/latest",
    referrals: "https://api.studio.thegraph.com/query/74668/mummy-fantom-referrals/version/latest",
  },

  common: {
    chainLink: "https://api.thegraph.com/subgraphs/name/deividask/chainlink",
  },
};
