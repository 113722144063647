import TooltipComponent from "components/Tooltip/Tooltip";

import MintIc from "img/home/ic-mint.svg";
import MintIcLight from "img/home/ic-mintLight.svg";
import { bigNumberify, expandDecimals, formatAmount } from "lib/numbers";
import styled from "styled-components";
import MintModal from "./MintModal";

import { useChainId } from "lib/chains";
import { useLocation } from "react-router-dom";
import { TXT } from "../multichainTxt";

import { useWeb3React } from "@web3-react/core";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
import { contractFetcher } from "lib/contracts";
import useSWR from "swr";

import GlpManager from "abis/GlpManager.json";
import ReaderV2 from "abis/ReaderV2.json";
import RewardReader from "abis/RewardReader.json";

import { ARBITRUM, BASE, FANTOM, MUMBAI, OP } from "config/chains";
import { getContract } from "config/contracts";
import { useGmxPrice } from "domain/legacy";
import { ethers } from "ethers";
import { GLP_DECIMALS, USD_DECIMALS, getDepositBalanceData, getStakingData } from "lib/legacy";
import { switchNetwork } from "lib/wallets";
import { useThemeContext } from "contexts/ThemeProvider";
const { AddressZero } = ethers.constants;

function Banner({ isVisible, setIsVisible, connectWallet, setPendingTxns, pendingTxns }) {
  // const [isVisible, setIsVisible] = useState(false);
  const { isLightTheme, lightThemeClassName } = useThemeContext();
  const { chainId } = useChainId();
  const location = useLocation();
  const { active, library } = useWeb3React();
  const account = "0xCe52c2E8e54CC717D1B35ac730406141DDCCb47d";
  const readerAddress = getContract(chainId, "Reader");
  const glpManagerAddress = getContract(chainId, "GlpManager");
  const banner = TXT[chainId]?.bannerImg;
  // useEffect(() => {
  //   if (isShow) {
  //     setIsVisible(true);
  //   }
  // }, [isShow]);

  const glpAddress = getContract(chainId, "GLP");
  const usdgAddress = getContract(chainId, "USDG");
  const gmxAddress = getContract(chainId, "GMX");
  const esGmxAddress = getContract(chainId, "ES_GMX");
  const bnGmxAddress = getContract(chainId, "BN_GMX");
  const tokensForSupplyQuery = [gmxAddress, glpAddress, usdgAddress];
  const rewardReaderAddress = getContract(chainId, "RewardReader");
  const stakedGmxTrackerAddress = getContract(chainId, "StakedGmxTracker");
  const bonusGmxTrackerAddress = getContract(chainId, "BonusGmxTracker");
  const depositTokens = [
    gmxAddress,
    esGmxAddress,
    stakedGmxTrackerAddress,
    bonusGmxTrackerAddress,
    bnGmxAddress,
    glpAddress,
  ];

  const feeGmxTrackerAddress = getContract(chainId, "FeeGmxTracker");

  const feeGlpTrackerAddress = getContract(chainId, "FeeGlpTracker");
  const stakedGlpTrackerAddress = getContract(chainId, "StakedGlpTracker");

  const rewardTrackersForDepositBalances = [
    stakedGmxTrackerAddress,
    stakedGmxTrackerAddress,
    bonusGmxTrackerAddress,
    feeGmxTrackerAddress,
    feeGmxTrackerAddress,
    feeGlpTrackerAddress,
  ];

  const rewardTrackersForStakingInfo = [
    stakedGmxTrackerAddress,
    bonusGmxTrackerAddress,
    feeGmxTrackerAddress,
    stakedGlpTrackerAddress,
    feeGlpTrackerAddress,
  ];

  const { data: aums } = useSWR([`Dashboard:getAums:${active}`, chainId, glpManagerAddress, "getAums"], {
    fetcher: contractFetcher(library, GlpManager),
  });

  const { data: totalSupplies } = useSWR(
    [`Dashboard:totalSupplies:${active}`, chainId, readerAddress, "getTokenBalancesWithSupplies", AddressZero],
    {
      fetcher: contractFetcher(library, ReaderV2, [tokensForSupplyQuery]),
    }
  );

  const { data: depositBalances } = useSWR(
    [`StakeV2:depositBalances:${active}`, chainId, rewardReaderAddress, "getDepositBalances", account],
    {
      fetcher: contractFetcher(library, RewardReader, [depositTokens, rewardTrackersForDepositBalances]),
    }
  );
  const { data: stakingInfo } = useSWR(
    [`StakeV2:stakingInfo:${active}`, chainId, rewardReaderAddress, "getStakingInfo", account],
    {
      fetcher: contractFetcher(library, RewardReader, [rewardTrackersForStakingInfo]),
    }
  );
  let aum = bigNumberify(0);
  if (aums) {
    aum = aums[0].add(aums[1]).div(2);
  }

  let glpPrice;
  let glpSupply;
  if (aum && totalSupplies && totalSupplies[3]) {
    glpSupply = totalSupplies[3];
    glpPrice =
      aum && aum.gt(0) && glpSupply.gt(0)
        ? aum.mul(expandDecimals(1, GLP_DECIMALS)).div(glpSupply)
        : expandDecimals(1, USD_DECIMALS);
  }
  const { gmxPrice } = useGmxPrice(chainId, {}, active);

  const depositBalanceData = getDepositBalanceData(depositBalances);

  const esGmxInStakedGmx =
    depositBalanceData &&
    gmxPrice &&
    depositBalanceData["esGmxInStakedGmx"].mul(gmxPrice).div(expandDecimals(1, GLP_DECIMALS));
  const glpInStakedGlp =
    depositBalanceData &&
    glpPrice &&
    depositBalanceData["glpInStakedGlp"].mul(glpPrice).div(expandDecimals(1, GLP_DECIMALS));
  const totalStaked = glpInStakedGlp && esGmxInStakedGmx && glpInStakedGlp.add(esGmxInStakedGmx);

  const stakingData = getStakingData(stakingInfo);

  const stakedGmxTrackerRewards = stakingData && stakingData["stakedGmxTracker"].claimable;
  const feeGlpTrackerRewards = stakingData && stakingData["feeGlpTracker"].claimable;
  const stakedGlpTrackerRewards = stakingData && stakingData["stakedGlpTracker"].claimable;
  return (
    <StyledBanner className={lightThemeClassName}>
      <MintModal
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        connectWallet={connectWallet}
        setPendingTxns={setPendingTxns}
        pendingTxns={pendingTxns}
      />
      <ImgBanner>
        <img alt="avt" src={TXT[chainId]?.avt} className="avt-img" />

        {(chainId === ARBITRUM || chainId === BASE) && (
          <div className="nft-notavailable">
            <div className="title">NFT is only available on Fantom & Optimism</div>
            <div className="button-group">
              <button className="default-btn" onClick={() => switchNetwork(FANTOM, active)}>
                Switch to Fantom
              </button>
              <button className="default-btn" onClick={() => switchNetwork(OP, active)}>
                Switch to Optimism
              </button>
            </div>
          </div>
        )}
        {chainId !== ARBITRUM && chainId !== BASE && (
          <div className="avt-content">
            {" "}
            <img alt="mint" className="deep-banner" src={TXT[chainId]?.bannerChain} />
            <div className="title">
              {chainId === FANTOM || chainId === MUMBAI ? "Mummy Rebirth" : "Optimism Mummy Club"}

              <img src={TXT[chainId]?.icon} alt="img" />
            </div>
            <div className="desc">
              A collection of {chainId === FANTOM || chainId === MUMBAI || chainId === OP ? "10,000" : "5,000"} Mummy NFT on{" "}
              {TXT[chainId]?.chainName}, creating a community centered around strong identity, values, and rewards
            </div>
            <div className="actions">
              {
                chainId === OP || chainId === FANTOM || chainId === MUMBAI ? (
                  <button
                    className="btn-primary"
                    onClick={() => {
                      setIsVisible(true);
                    }}
                  >
                    {" "}
                    <img alt="mint" src={isLightTheme ? MintIcLight : MintIc} /> Mint NFT
                  </button>
                ) : null
                // <>
                //   <button className="btn-primary" disabled={true} style={{ background: "#9a9494" }}>
                //     {" "}
                //     <img
                //       alt="mint"
                //       src={MintIc}
                //       onClick={() => {
                //         setIsVisible(true);
                //       }}
                //     />{" "}
                //     Sold out
                //   </button>
                //   <a
                //     className="btn-primary"
                //     href="https://paintswap.finance/marketplace/fantom/collections/mummy-club"
                //     target="_blank"
                //     rel="noreferrer"
                //   >
                //     Trade on PaintSwap
                //   </a>
                // </>
              }
            </div>
            {(chainId === OP || chainId === MUMBAI || chainId === FANTOM) && (
              <TotalValue>
                <div>
                  <div className="total-title">Total treasury value</div>
                  <div className="value">
                    {" "}
                    {totalStaked ? (
                      <TooltipComponent
                        handle={`$${formatAmount(totalStaked, 30, 2, true)}`}
                        position="left-bottom"
                        className="nowrap"
                        renderContent={() => {
                          return (
                            <>
                              <StatsTooltipRow
                                label={`Staked MLP`}
                                value={formatAmount(glpInStakedGlp, 30, 2, true)}
                                showDollar={true}
                              />
                              <StatsTooltipRow
                                label={`Staked esMMY`}
                                value={formatAmount(esGmxInStakedGmx, 30, 2, true)}
                                showDollar={true}
                              />
                              <div className="Tooltip-divider" />
                              <StatsTooltipRow
                                label={`Compounding Reward`}
                                value={formatAmount(stakedGmxTrackerRewards, 18, 2, true) + " esMMY"}
                                showDollar={false}
                              />
                              <StatsTooltipRow
                                label={`Pending Reward`}
                                value={formatAmount(stakedGlpTrackerRewards, 18, 2, true) + " esMMY"}
                                showDollar={false}
                              />

                              <StatsTooltipRow
                                label={``}
                                value={
                                  formatAmount(feeGlpTrackerRewards, 18, 2, true) +
                                  (chainId === FANTOM ? " WFTM" : " WETH")
                                }
                                showDollar={false}
                                showTwoDots={false}
                              />
                            </>
                          );
                        }}
                      />
                    ) : (
                      <span className="skeleton-box" style={{ width: "80px", height: "25px" }} />
                    )}
                  </div>
                </div>
                {/* <button className="btn-outline">Details</button> */}
              </TotalValue>
            )}
          </div>
        )}
      </ImgBanner>
      <DeepBanner>
        <div className="banner-content" style={{ backgroundImage: `url(${banner})` }}></div>{" "}
      </DeepBanner>
    </StyledBanner>
  );
}

const TotalValue = styled.div`
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(35px);
  padding: 16px;
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 16px;
  .total-title {
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 4px;
    text-align: left;
  }
  .value {
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;
    text-align: left;
  }
  button {
    width: 76px;
    height: 40px;
  }

  @media screen and (max-width: 700px) {
    margin-top: 16px;
  }
`;

const DeepBanner = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 348px;
  @media screen and (max-width: 700px) {
    height: 375px;
  }
  left: 0;
  overflow: hidden;
  -webkit-mask-image: linear-gradient(
    270deg,
    rgba(217, 217, 217, 0.2) 0%,
    #d9d9d9 49.48%,
    rgba(217, 217, 217, 0.2) 100%
  );
  @keyframes slide {
    0% {
      transform: translate3d(0, 0, 0);
    }
    100% {
      transform: translate3d(-1692px, 0, 0); /* The image width */
    }
  }
  .banner-content {
    height: 348px;
    background-repeat: repeat-x;
    width: 5076px;
    animation: slide 60s linear infinite;
  }
  @media screen and (max-width: 991px) {
    top: -20px;
  }
  @media screen and (max-width: 700px) {
    top: 79px;
  }
`;
const ImgBanner = styled.div`
  height: 396px;
  position: relative;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
  width: 872px;
  max-width: calc(100% - 48px);
  background: rgba(0, 0, 0, 0.2);
  border: 2px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(35px);
  padding: 36px;
  border-radius: 36px;
  display: grid;
  grid-template-columns: 320px auto;
  gap: 24px;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  .avt-content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
  }
  .title {
    font-weight: 700;
    font-size: 36px;
    line-height: 140%;
    img {
      width: 44px;
      height: 44px;
      margin-left: 16px;
    }
    @media screen and (max-width: 991px) {
      font-size: 24px;
      img {
        width: 32px;
        height: 32px;
        margin-left: 8px;
      }
    }
    @media screen and (max-width: 700px) {
      font-size: 16px;
      img {
        width: 24px;
        height: 24px;
      }
    }
  }
  .desc {
    margin: 16px 0;
    @media screen and (max-width: 991px) {
      margin: 16px 0;
    }
    @media screen and (max-width: 700px) {
      font-size: 14px;
      margin: 8px 0 16px 0;
    }
  }
  .actions {
    margin-top: 0;
    display: flex;
    gap: 16px;
    a,
    button {
      text-decoration: none;
      padding: 8px 16px;
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      height: 48px;
      width: fit-content;

      img {
        margin-right: 8px;
        margin-left: 0;
      }
    }
    @media screen and (max-width: 700px) {
      flex-direction: column;
      a,
      button {
        height: 40px;
        width: 100%;
        img {
          width: 24px;
          margin-right: 4px;
        }
      }
    }
  }
  .deep-banner {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0px;
    z-index: -2;
    @media screen and (max-width: 991px) {
      width: 100%;
      top: 0;
    }
  }

  @media screen and (max-width: 991px) {
    height: fit-content;
    padding: 24px;
    grid-template-columns: 260px auto;
    .avt-img {
      width: 260px;
      height: 260px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  @media screen and (max-width: 700px) {
    height: fit-content;
    padding: 16px;
    grid-template-columns: 100%;
    text-align: center;
    gap: 16px;
    .avt-img {
      width: 240px;
      height: 240px;
    }
  }
`;

const StyledBanner = styled.section`
  position: relative;
  background: var(--Linear, linear-gradient(116deg, #18235d 1.03%, #191b2e 100%));
  padding: 48px 0;
  margin-bottom: 120px;
  @media screen and (max-width: 991px) {
    margin-bottom: 72px;
  }
  @media screen and (max-width: 700px) {
    padding-top: 24px;
    margin-bottom: 48px;
  }

  &.theme--light {
    ${TotalValue} {
      background: var(--ScrollBar-Background, rgba(0, 0, 0, 0.1));
    }

    .nft-notavailable {
      .title {
        color: #fff !important;
      }
    }

    .PositionDropdown-dots-icon.btn-dropdown {
      background: #f2f5f7;

      &:hover {
        background: #f2f5f7 !important;
      }
    }

    .PositionDropdown-extra-options {
      .menu-items {
        background: #f2f5f7 !important;
      }
    }

    .info-wrap {
      background: var(--Nature-2, #f2f5f7);
    }
  }
`;

export default Banner;
