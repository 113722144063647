import useSWR from "swr";
import { getServerUrl } from "config/backendV2";
import { DEFAULT_CHAIN_ID } from "config/chains";
import { fetcher } from "lib/fetcher";
import { useEffect, useState } from "react";
import axios from "axios";
import { helperToast } from "lib/helperToast";

export interface Last24hChartInterface {
  volume: string;
  fees: string;
  trades: string;
  realizedPnl: string;
  timestamp: number;
}

function useDailyInfoLast24hCharts(chainId: number = DEFAULT_CHAIN_ID, params?: Object) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<any>([]);

  const apiUrl = `${getServerUrl(chainId, "/public/last-24h-charts")}`;
  const fetchdata = async () => {
    try {
      const res = await axios.get(apiUrl, { params });

      if (res && res.data?.data) {
        setData(res.data?.data);
        setIsLoading(false);

        return res.data?.data;
      } else {
        setIsLoading(true);
        setData([]);
      }
    } catch (error) {
      setIsLoading(true);
      helperToast.error(error);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchdata();
  }, [params]);

  return {
    data,
    isLoading,
  };

  // const { data: response } = useSWR([getServerUrl(chainId, "/public/last-24h-charts"), params], {
  //   fetcher,
  //   refreshInterval: 10000,
  // });

  // return {
  //   data: response ? response.data : undefined,
  // };
}

export default useDailyInfoLast24hCharts;
