import { approveTokens } from "domain/tokens";
import { BigNumber } from "ethers";
import { contractFetcher } from "lib/contracts";
import useSWR from "swr";
import { useState } from "react";

import Token from "abis/Token.json";
import { parseValue } from "lib/numbers";
import { useWeb3React } from "@web3-react/core";
import { DEFAULT_CHAIN_ID } from "config/chains";
import { LoadingCpn } from "pages/Stake/modals/Stakes";

type DisabledType = Array<[boolean, string]> | boolean;

type Params = {
  tokenAddress: string;
  spender: string;
  fromValue: string;
  fromValueDecimal: number;
  maxAmount: BigNumber;
  isSubmitting: boolean;
  actionText: any;
  disabled?: DisabledType;
};

export default function useValidateAction(options: Params) {
  const { tokenAddress, spender, fromValue, maxAmount, isSubmitting, actionText, disabled, fromValueDecimal } = options;

  const { active, chainId = DEFAULT_CHAIN_ID, account, library } = useWeb3React();

  const [isApproving, setIsApproving] = useState<boolean>(false);
  const [isWaitingForApproval, setIsWaitingForApproval] = useState<boolean>(false);

  const { data: allowance } = useSWR<BigNumber>(
    active ? [`Token:allowance:${active}`, chainId, tokenAddress, "allowance", account, spender] : null,
    {
      fetcher: contractFetcher(undefined, Token),
    }
  );

  const getValidateDisabledText = () => {
    if (!disabled) return;
    if (typeof disabled === "boolean") return "Error";

    const errorText = disabled.find((d) => !!d[0])?.[1];
    return errorText;
  };

  const isNeedApproval = allowance && fromValue && parseValue(fromValue, fromValueDecimal)?.gt(allowance);

  const approve = () => {
    approveTokens({
      setIsApproving,
      library,
      tokenAddress: tokenAddress,
      spender: spender,
      chainId,
      onApproveSubmitted: () => {
        setIsWaitingForApproval(true);
      },
    });
  };

  const getError = () => {
    if (!fromValue || parseValue(fromValue, fromValueDecimal)?.eq(0)) {
      return `Enter an amount`;
    }
    if (maxAmount && parseValue(fromValue, fromValueDecimal)?.gt(maxAmount)) {
      return `Amount exceeds balance`;
    }
  };

  const getButtonText = () => {
    if (disabled) {
      if (getValidateDisabledText()) return getValidateDisabledText();
    }

    const error = getError();
    if (error) {
      return error;
    }
    if (isNeedApproval && isWaitingForApproval) {
      return `Waiting for Approval`;
    }
    if (isApproving) {
      return "Approving...";
    }
    if (isNeedApproval) {
      return `Approve`;
    }
    if (isSubmitting) {
      return actionText[1];
    }
    return actionText[0];
  };

  const isButtonEnabled = () => {
    if (disabled) {
      if (getValidateDisabledText()) return false;
    }
    const error = getError();
    if (error) {
      return false;
    }
    if ((isNeedApproval && isWaitingForApproval) || isApproving) {
      return false;
    }
    if (isApproving) {
      return false;
    }
    if (isSubmitting) {
      return false;
    }
    return true;
  };

  return {
    approve,
    getButtonText,
    isButtonEnabled,
    isNeedApproval,
  };
}
