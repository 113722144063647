import axios from "axios";
import { useChainId } from "lib/chains";
import { formatAmount, parseValue } from "lib/numbers";
import { getServerBaseUrl, getServerUrl } from "config/backend";
import { useEffect, useRef, useState } from "react";
import { FANTOM, SUPPORTED_V2_CHAINS } from "config/chains";
const useUserOrder = (account) => {
  const [positions, setPossition] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { chainId } = useChainId();
  var timeout = useRef(null);
  var oldAccount = useRef(null);
  const fetchPositions = async () => {
    const res = await axios.get(
      `${getServerBaseUrl(chainId, true)}/public/open_orders/${account}`,
      {},
    );

    if (res && res.data?.data) {
      const triggerData = res.data?.data?.triggerOrders || [];
      const pendingData = res.data?.data?.pendingOrders || [];
      const trailingData = res.data?.data?.trailingStops || [];
      let orders = [];
      for (let i = 0; i < triggerData.length; i++) {
        const itemTrigerData = triggerData[i];
        const { triggers } = itemTrigerData;
        for (let trigerIndex = 0; trigerIndex < triggers.length; trigerIndex++) {
          const trigger = triggers[trigerIndex];
          orders.push({
            type: "TRIGGER",
            trigger,
            createdAt: trigger.createdAt,
            posId: itemTrigerData.posId,
            tokenId: itemTrigerData.tokenId,
            isLong: itemTrigerData.isLong,
            status: itemTrigerData.status,
            size: itemTrigerData?.size ? parseValue(itemTrigerData?.size, 30) : 0,
            collateral: itemTrigerData?.collateral ? parseValue(itemTrigerData?.collateral, 30) : 0,
            leverage: itemTrigerData?.leverage ? itemTrigerData?.leverage : 0,
            positionType: itemTrigerData?.positionType,
            averagePrice: itemTrigerData?.averagePrice
              ? parseValue(itemTrigerData?.averagePrice, 30)
              : 0,
            liquidationPrice: itemTrigerData?.liquidationPrice
              ? parseValue(itemTrigerData?.liquidationPrice, 30)
              : 0,
          });
        }
        if (triggers.length === 0) {
          orders.push({
            trigger: null,
            posId: itemTrigerData.posId,
            tokenId: itemTrigerData.tokenId,
            isLong: itemTrigerData.isLong,
            status: itemTrigerData.status,
          });
        }
      }
      for (let i = 0; i < pendingData.length; i++) {
        orders.push({
          type: "PENDDING",
          ...pendingData[i],
        });
      }
      for (let i = 0; i < trailingData.length; i++) {
        orders.push({
          type: "TRAILING",
          ...trailingData[i],
          posId: trailingData[i].id,
        });
      }
      orders.sort((a, b) => {
        return b.createdAt - a.createdAt;
      });
      setPossition(orders);
    } else {
      setPossition([]);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    if (account && SUPPORTED_V2_CHAINS.includes(chainId)) {
      if (oldAccount.current !== account) oldAccount.current = account
      if (timeout.current) {
        clearTimeout(timeout.current)

      }
      fetchPositions();
        const interval = setInterval(async () => {
          fetchPositions();
        }, 5000);
        return () => clearInterval(interval);
    }
    else {
      if (!oldAccount.current)
        timeout.current = setTimeout(() => {
          setIsLoading(false)
          setPossition([])
        }, 3500)
      else {
        setIsLoading(false)
        setPossition([])
      }
    }
  }, [account, chainId]);
  return { positions, isLoading };
};
export default useUserOrder;
