import React, { useState } from "react";
import { BigNumber } from "ethers";
import cx from "classnames";
import SettingsManager from "abis/SettingsManager.json";
import { getPnlWithoutFee, importImage } from "lib/legacy";
import Modal from "../Modal/Modal";
import ExchangeInfoRow from "./ExchangeInfoRow";
import Tooltip from "../Tooltip/Tooltip";
import "./PositionSeller.css";
import { formatAmount, limitDecimals, parseValue } from "lib/numbers";
import moment from "moment";
import RowsDropdown, { RowsDropdownHandler } from "./RowsDropdown";
import PositionHistory from "./PositionHistory";
import usePositionActivities from "hooks/usePositionActivities";
import { renderRisk } from "lib/helper";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { THEME_KEY } from "config/localStorage";
import { contractFetcher } from "lib/contracts";
import { useWeb3React } from "@web3-react/core";
import useSWR from "swr";
import { getContract } from "config/contracts";
const antIcon = <LoadingOutlined style={{ fontSize: 16, color: "grey" }} spin />;

export const MAPPED_ACTION_TYPES = {
  STOP_LOSS: "Stop Loss",
  TAKE_PROFIT: "Take Profit",
  TRAILING_STOP: "Trailing Stop",
};

export default function PositionDetails(props) {
  const {
    onConfirm,
    isVisible,
    positions,
    selectedPosition,
    setIsVisible,
    chainId,
    marketTokensInfo,
    isConfirm,
    hideClose,
  } = props;
  const { library } = useWeb3React();
  const currentMarket = marketTokensInfo?.find((x) => x.id === selectedPosition.tokenId);
  const currentPos = positions?.find((x) => x.posId === selectedPosition.posId) || {};
  const position = { ...selectedPosition, ...currentPos };
  const [isOpenPositionHistory, setIsOpenPositionHistory] = useState(false);
  const { positionNetValue } = position;
  const accruedPositionFee = parseValue(position.accruedPositionFee, 30);
  const accruedFundingFee = parseValue(position.accruedFundingFee, 30);
  const accruedBorrowFee = parseValue(position.accruedBorrowFee, 30);
  const accruedFees = accruedPositionFee.add(accruedFundingFee).add(accruedBorrowFee);
  const avgEntryPrice = parseValue(position.averagePrice, 30);
  const markPrice = BigNumber.from(position?.isLong ? currentMarket?.askPrice || 0 : currentMarket?.bidPrice || 0);

  const collateral = parseValue(position.collateral, 30);
  const pnlWithoutFee = getPnlWithoutFee(
    position.isLong,
    markPrice,
    parseValue(position.averagePrice, 30),
    parseValue(position.size, 30)
  );
  const uPnL = pnlWithoutFee.sub(accruedFees);
  const pendingCollateral = parseValue(position.pendingCollateral, 30);
  const dateFormat = "YYYY-MM-DD";
  const timeFormat = "HH:mm:ss";
  const paidFee =
    parseValue(position.paidPositionFee, 30)
      .add(parseValue(position.paidBorrowFee, 30))
      .add(parseValue(position.paidFundingFee, 30)) || BigNumber.from(0);
  const paidPositionFee = parseValue(position.paidPositionFee, 30);
  const paidBorrowFee = parseValue(position.paidBorrowFee, 30);
  const paidFundingFee = parseValue(position.paidFundingFee, 30);
  const rPnL = parseValue(position.realisedPnl || 0, 30);
  const handleConfirm = () => {
    onConfirm && onConfirm(position, parseValue(position.size, 30));
  };
  const netValue = parseValue(position.collateral, 30).add(uPnL);
  const { data: positionActivities } = usePositionActivities(chainId, selectedPosition.posId);

  const mappedPosOrders = {};
  if (position.posOrders) {
    position.posOrders
      .map((o) => {
        if (o.type !== "TRIGGER") {
          return {
            ...o,
            actionType: "TRAILING_STOP",
          };
        }
        if (o.trigger.isTP) {
          return {
            ...o,
            actionType: "TAKE_PROFIT",
          };
        }
        return {
          ...o,
          actionType: "STOP_LOSS",
        };
      })
      .forEach((order) => {
        if (!Array.isArray(mappedPosOrders[order.actionType])) {
          mappedPosOrders[order.actionType] = [order];
        } else {
          mappedPosOrders[order.actionType].push(order);
        }
      });
  }
  const settingsManagerAddress = getContract(chainId, "SettingsManager");
  const { data: minProfitDurations } = useSWR(
    ["ExChange:minProfitDurations", chainId, settingsManagerAddress, "minProfitDurations", position.tokenId],
    {
      fetcher: contractFetcher(library, SettingsManager),
    }
  );
  const { data: maxCloseProfitPercents } = useSWR(
    ["ExChange:maxCloseProfitPercents", chainId, settingsManagerAddress, "maxCloseProfitPercents", position.tokenId],
    {
      fetcher: contractFetcher(library, SettingsManager),
    }
  );

  const { data: maxCloseProfits } = useSWR(
    ["ExChange:maxCloseProfits", chainId, settingsManagerAddress, "maxCloseProfits", position.tokenId],
    {
      fetcher: contractFetcher(library, SettingsManager),
    }
  );
  const isDisableCondition = () => {
    if (!minProfitDurations || !maxCloseProfitPercents || !maxCloseProfits) return true;
    const inTime = Date.now() / 1000 - position.createdAt < minProfitDurations.toNumber();
    if (inTime) {
      if (uPnL.gt(maxCloseProfits) || ROI.gt(parseValue(1, 27).mul(maxCloseProfitPercents))) return true;
      return false;
    }
    return false;
  };
  const ROI = uPnL ? uPnL.mul(parseValue(100, 30)).div(parseValue(position.collateral, 30)) : null;
  const theme = window.localStorage.getItem(`"${THEME_KEY}"`);
  return (
    <div className="PositionEditor">
      {position && (
        <Modal
          className={`PositionSeller-modal  confirmation-modal details-position-modal`}
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          label={"Open Position"}
          allowContentTouchMove
        >
          <div className="order-title-line order-title-line-details">
            <img src={importImage("ic_" + position?.symbol?.toLowerCase() + "_24.svg")} alt="" />
            <div className="position-info-container">
              <div className="position-info">
                <div className="title">{position.symbol}</div>
                <div className={`side ${position?.isLong ? "side-long" : "side-short"}`}>
                  {position?.isLong ? "LONG" : "SHORT"}
                </div>
                <div className="side">
                  {position?.positionType === "Market Order" ? "Market" : position?.positionType}
                </div>
              </div>
              <div className="position-id">#{position?.posId}</div>
            </div>

            <div className="postion-history" onClick={() => setIsOpenPositionHistory(true)}>
              Position History
            </div>
          </div>
          <div className="content-container">
            <div className="order-details-container">
              <div className="title">Position Details</div>
              <div className="square-container">
                <ExchangeInfoRow label={`Unrealized PnL`}>
                  <div className={cx({ positive: uPnL.gt(0), negative: uPnL.lt(0) })}>
                    {uPnL.gt(0) ? "+" : "-"}${formatAmount(uPnL, 30, 2, 0).replace("-", "")}&nbsp;(
                    {positionNetValue.prefix}
                    {formatAmount(positionNetValue?.percentPnl, 30, 2, true).replace("-", "")}
                    %)
                  </div>
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Realized PnL`}>
                  <div className={cx({ positive: rPnL.gt(0), negative: rPnL.lt(0) })}>
                    {rPnL ? (rPnL.gt(0) ? "+" : "-") : ""}$
                    {rPnL ? formatAmount(rPnL, 30, 2, true).replace("-", "") : "--"}{" "}
                  </div>
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Net Value`}>{`${netValue.gt(0) ? "" : "-"}$${formatAmount(
                  netValue,
                  30,
                  2,
                  true
                ).replace("-", "")}`}</ExchangeInfoRow>
                <ExchangeInfoRow label={`Received Amount`}>{`${netValue.gt(0) ? "" : "-"}$${formatAmount(
                  netValue,
                  30,
                  2,
                  true
                ).replace("-", "")}`}</ExchangeInfoRow>
                <ExchangeInfoRow label={`Liq. Price`}>
                  ${formatAmount(parseValue(position.liquidationPrice, 30), 30, currentMarket?.decimals || 2, true)}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Pos.Size`}>
                  ${formatAmount(parseValue(position.size, 30), 30, 2, true)}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Pos.Qty`}>
                  {formatAmount(position.quantity, 30, 4, true)} {position.symbol}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Leverage`}>
                  <div className="position-list-leve">
                    {formatAmount(parseValue(position.leverage, 30), 30, 2, true)}X
                  </div>
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Mark Price`}>
                  ${formatAmount(markPrice, 30, currentMarket?.decimals || 2, true)}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Avg. Entry Price`}>
                  ${formatAmount(avgEntryPrice, 30, currentMarket?.decimals || 2, true)}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Liq. Risk`}>
                  {position.LiqRisk && renderRisk(position.LiqRisk, false)}
                  {/* {formatAmount(position.LiqRisk, 30, position.LiqRisk.eq(0) ? 0 : 2, true).replace(
                    "-",
                    "",
                  )}
                  % */}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Collateral`}>${formatAmount(collateral, 30, 2, true)}</ExchangeInfoRow>
                <ExchangeInfoRow label={`Pending Collateral`}>
                  ${formatAmount(pendingCollateral, 30, 2, true)}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Date/Time Opened`}>
                  {moment(new Date(position.createdAt * 1000)).format(dateFormat)}&nbsp;
                  {moment(new Date(position.createdAt * 1000)).format(timeFormat)}
                </ExchangeInfoRow>
              </div>
              {Object.keys(mappedPosOrders).length > 0 && (
                <div className="square-container-yellow" style={{ margin: "4px 0" }}>
                  <div className="title title-linked-order">Linked Orders</div>
                  <div className="square-container fee-square">
                    {Object.keys(mappedPosOrders).map((type) => (
                      <RowsDropdown
                        key={type}
                        handler={
                          <RowsDropdownHandler>
                            {MAPPED_ACTION_TYPES[type]} ({mappedPosOrders[type].length})
                          </RowsDropdownHandler>
                        }
                      >
                        {mappedPosOrders[type].map((order) => (
                          <>
                            {order.type === "TRIGGER" && (
                              <div className="square-container">
                                <ExchangeInfoRow label={`Pos. Size`}>
                                  ${formatAmount(order.size, 30, 2, true)}
                                </ExchangeInfoRow>
                                <ExchangeInfoRow label={`Pos. Qty`}>
                                  {formatAmount(order.size.mul(parseValue(1, 30)).div(order.averagePrice), 30, 4, true)}{" "}
                                  {position.symbol}
                                </ExchangeInfoRow>
                                <ExchangeInfoRow label={`Trigger Price`}>
                                  {" "}
                                  ${formatAmount(order.trigger.price, 30, currentMarket?.decimals || 2, true)}
                                </ExchangeInfoRow>
                              </div>
                            )}
                            {order.type === "TRAILING" && (
                              <div className="square-container">
                                <ExchangeInfoRow label={`Pos. Size`}>
                                  ${formatAmount(order.size, 30, 2, true)}
                                </ExchangeInfoRow>
                                <ExchangeInfoRow label={`Pos. Qty`}>
                                  {formatAmount(
                                    BigNumber.from(order.size)
                                      .mul(parseValue(1, 30))
                                      .div(BigNumber.from(order.averagePrice)),
                                    30,
                                    4,
                                    true
                                  )}{" "}
                                  {position.symbol}
                                </ExchangeInfoRow>
                                <ExchangeInfoRow label={`Paramaters`}>
                                  <div>
                                    <div>
                                      {order.stepType == 0
                                        ? formatAmount(
                                            BigNumber.from(order.stepAmount),
                                            30,
                                            currentMarket?.decimals || 2,
                                            true
                                          )
                                        : formatAmount(BigNumber.from(order.stepAmount), 3, 2, true)}{" "}
                                      <span className="muted">{order.stepType == 1 ? "T%" : "TA"}</span>
                                    </div>
                                    <div>
                                      {" "}
                                      {BigNumber.from(order.size).eq(BigNumber.from(order.sizeDelta))
                                        ? 100
                                        : formatAmount(
                                            BigNumber.from(order.sizeDelta)
                                              .mul(parseValue(1, 30))
                                              .div(BigNumber.from(order.size)),
                                            30,
                                            2,
                                            true
                                          )}{" "}
                                      <span className="muted">%</span>
                                    </div>
                                  </div>
                                </ExchangeInfoRow>
                              </div>
                            )}
                          </>
                        ))}
                      </RowsDropdown>
                    ))}
                  </div>
                </div>
              )}

              <div className="fw-500">Fees</div>
              <div className="square-container square-fee-container">
                <RowsDropdown
                  handler={
                    <ExchangeInfoRow label={`Paid`} isPositive={paidFee.gte(0)}>
                      {paidFee.lt(0) ? "-" : ""}${formatAmount(paidFee, 30, 4, true).replace("-", "")}
                    </ExchangeInfoRow>
                  }
                >
                  <ExchangeInfoRow label={`Position`}>
                    {paidPositionFee.lt(0) && "-"}${formatAmount(paidPositionFee, 30, 4, true).replace("-", "")}
                  </ExchangeInfoRow>
                  <ExchangeInfoRow label={`Funding`} isPositive={paidFundingFee.gte(0)}>
                    {paidFundingFee.lt(0) && "-"}${formatAmount(paidFundingFee, 30, 4, true).replace("-", "")}
                  </ExchangeInfoRow>
                  <ExchangeInfoRow label={`Borrow`}>
                    {paidBorrowFee.lt(0) && "-"}${formatAmount(paidBorrowFee, 30, 4, true).replace("-", "")}
                  </ExchangeInfoRow>
                  {/* <ExchangeInfoRow label={`Fee Discount (--%)`}>$--</ExchangeInfoRow> */}
                </RowsDropdown>
                <div className="divider" />
                <RowsDropdown
                  handler={
                    <ExchangeInfoRow label={`Accrued`} isPositive={accruedFees.gte(0)}>
                      {accruedFees.lt(0) && "-"}${formatAmount(accruedFees, 30, 4, true).replace("-", "")}
                    </ExchangeInfoRow>
                  }
                >
                  <ExchangeInfoRow label={`Close Position`}>
                    {accruedPositionFee.lt(0) && "-"}${formatAmount(accruedPositionFee, 30, 4, true).replace("-", "")}
                  </ExchangeInfoRow>
                  <ExchangeInfoRow label={`Funding`} isPositive={accruedFundingFee.gte(0)}>
                    {accruedFundingFee.lt(0) && "-"}${formatAmount(accruedFundingFee, 30, 4, true).replace("-", "")}
                  </ExchangeInfoRow>
                  <ExchangeInfoRow label={`Borrow`}>
                    {accruedBorrowFee.lt(0) && "-"}${formatAmount(accruedBorrowFee, 30, 4, true).replace("-", "")}
                  </ExchangeInfoRow>
                  {/* <ExchangeInfoRow label={`Fee Discount (--%)`}>$--</ExchangeInfoRow> */}
                </RowsDropdown>
              </div>
            </div>
          </div>
          {!hideClose && (
            <div className="Exchange-swap-button-container">
              <button
                className="App-cta Exchange-swap-button"
                onClick={handleConfirm}
                disabled={(position && !position.liquidationPrice) || isConfirm || isDisableCondition()}
              >
                {isConfirm ? "Closing..." : "Close Position"}
              </button>
            </div>
          )}
          <PositionHistory
            isVisible={isOpenPositionHistory}
            setIsVisible={setIsOpenPositionHistory}
            rows={positionActivities}
            position={position}
            chainId={chainId}
            optionalHeader={
              <div className="order-title-line order-title-line-details">
                <img src={importImage("ic_" + position?.symbol?.toLowerCase() + "_24.svg")} alt="" />
                <div className="position-info-container">
                  <div className="position-info">
                    <div className="title">{position.symbol}</div>
                    <div className={`side ${position?.isLong ? "side-long" : "side-short"}`}>
                      {position?.isLong ? "LONG" : "SHORT"}
                    </div>
                    <div className="side">
                      {position?.positionType === "Market Order" ? "Market" : position?.positionType}
                    </div>
                  </div>
                  <div className="position-id">#{position?.posId}</div>
                </div>
              </div>
            }
          />
        </Modal>
      )}
    </div>
  );
}
