import {
  Bar,
  Label,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
  Line,
} from "recharts";
import ChartWrapper from "./ChartWrapper";

import {
  COLORS,
  LIGHT_COLORS,
  convertToPercents,
  tooltipFormatter,
  tooltipLabelFormatter,
  yaxisFormatter,
} from "lib/helper";
import { useChartViewState } from "hooks/useChartViewState";
import { CHART_COLORS } from "utils/analyticsV3";

const convertToPercentsHandler = (data) =>
  convertToPercents(data, { ignoreKeys: ["cumulative", "movingAverageAll"], totalKey: "all" });

export default function FeesBreakdownChart(props) {
  const { data, loading, chartHeight, yaxisWidth, isLightTheme, hideLegend } = props;

  const csvFields = [
    { key: "v1SwapFees", name: "V1 Swap" },
    { key: "mintMLPFees", name: "Mint MLP" },
    { key: "burnMLPFees", name: "Burn MLP" },
    { key: "mintMSLPFees", name: "Mint MSLP" },
    { key: "burnMSLPFees", name: "Burn MSLP" },
    { key: "v1LiquidationFees", name: "V1 Liquidation" },
    { key: "v1TradingFees", name: "V1 Margin Trading" },
    { key: "v2TradingFees", name: "V2 Margin Trading" },
    { key: "totalCumulativeFees", name: "Cumulative" },
  ];

  const controls = {
    convertToPercents: convertToPercentsHandler,
  };

  const { viewState, togglePercentView, formattedData, ...viewSettings } = useChartViewState({ controls, data });

  const CustomTooltip = (props) => {
    const { active, payload, label } = props;
    if (active && payload) {
      return (
        <div className="custom-tooltip">
          <div className="label-total">{tooltipLabelFormatter(label, payload)}</div>
          {payload.map((item, index) => {
            return (
              <div className="item-group" key={index}>
                <div style={{ color: item.color || item.stroke || item.fill || "white" }}>{item.name}: </div>
                <div style={{ color: item.color || item.stroke || item.fill || "white" }}>
                  {tooltipFormatter(item.value, item.name, item)} {item.unit || ""}
                </div>
              </div>
            );
          })}
        </div>
      );
    }
    return null;
  };
  const ChartColorForTheme = (index) => {
    const result = CHART_COLORS[index];
    return result;
  };
  return (
    <ChartWrapper
      title="Fees Breakdown"
      loading={loading}
      csvFields={csvFields}
      data={formattedData}
      controls={controls}
      viewState={viewState}
      togglePercentView={togglePercentView}
    >
      <div className="merged-chart-container">
        <ResponsiveContainer width="100%" height={chartHeight}>
          <ComposedChart data={formattedData}>
            <CartesianGrid strokeDasharray="4" stroke={isLightTheme ? "#0000001A" : "#FFFFFF1A"} />
            <XAxis dataKey="timestamp" tickFormatter={tooltipLabelFormatter} minTickGap={30} />
            <YAxis
              dataKey="allFees"
              interval="preserveStartEnd"
              tickCount={viewState.isPercentsView ? undefined : 7}
              tickFormatter={viewSettings.yaxisTickFormatter}
              width={yaxisWidth}
            />
            <YAxis
              dataKey="totalCumulativeFees"
              orientation="right"
              yAxisId="right"
              tickFormatter={yaxisFormatter}
              width={yaxisWidth}
            />
            {/* <Tooltip
            formatter={tooltipFormatter}
            labelFormatter={tooltipLabelFormatter}
            contentStyle={{ textAlign: "left" }}
          /> */}
            <Tooltip content={<CustomTooltip />} />
            {/* <Legend /> */}
            <Bar
              unit={viewSettings.itemsUnit}
              isAnimationActive={false}
              type="monotone"
              dataKey="mintMLPFees"
              stackId="a"
              name="Mint MLP"
              fill={ChartColorForTheme(0)}
            />
            <Bar
              unit={viewSettings.itemsUnit}
              isAnimationActive={false}
              type="monotone"
              dataKey="burnMLPFees"
              stackId="a"
              name="Burn MLP"
              fill={ChartColorForTheme(1)}
            />
            <Bar
              unit={viewSettings.itemsUnit}
              isAnimationActive={false}
              type="monotone"
              dataKey="mintMSLPFees"
              stackId="a"
              name="Mint MSLP"
              fill={ChartColorForTheme(2)}
            />
            <Bar
              unit={viewSettings.itemsUnit}
              isAnimationActive={false}
              type="monotone"
              dataKey="burnMSLPFees"
              stackId="a"
              name="Burn MSLP"
              fill={ChartColorForTheme(3)}
            />
            <Bar
              unit={viewSettings.itemsUnit}
              isAnimationActive={false}
              type="monotone"
              dataKey="v1LiquidationFees"
              stackId="a"
              name="V1 Liquidation"
              fill={ChartColorForTheme(4)}
            />{" "}
            <Bar
              unit={viewSettings.itemsUnit}
              isAnimationActive={false}
              type="monotone"
              dataKey="v1TradingFees"
              stackId="a"
              name="V1 Margin Trading"
              fill={ChartColorForTheme(5)}
            />{" "}
            <Bar
              unit={viewSettings.itemsUnit}
              isAnimationActive={false}
              type="monotone"
              dataKey="v2TradingFees"
              stackId="a"
              name="V2 Margin Trading"
              fill={ChartColorForTheme(6)}
            />
            <Bar
              unit={viewSettings.itemsUnit}
              isAnimationActive={false}
              type="monotone"
              dataKey="v1SwapFees"
              stackId="a"
              name="V1 Swap"
              fill={ChartColorForTheme(7)}
            />
            <Line
              isAnimationActive={false}
              type="monotone"
              dot={false}
              strokeWidth={2}
              stroke={ChartColorForTheme(8)}
              dataKey="totalCumulativeFees"
              yAxisId="right"
              name="Cumulative"
            />
          </ComposedChart>
        </ResponsiveContainer>
        {!hideLegend && (
          <div className="custom-legend">
            {csvFields?.map((item, index) => {
              return (
                <div className="legend-item" key={index}>
                  <div className="legend-name">{item.name}</div>
                  <div
                    className="legend-color"
                    style={{ backgroundColor: item.color || CHART_COLORS[index] }}
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </ChartWrapper>
  );
}
