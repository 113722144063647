import React, { useCallback, useState, useMemo } from "react";
import "./ConfirmationAggregatorBox.scss";
import { USD_DECIMALS, BASIS_POINTS_DIVISOR, INCREASE, DEFAULT_SLIPPAGE_AMOUNT, DECREASE } from "lib/legacy";
import SwapIcon from "img/trade/swap_icon.svg";
import Modal from "../Modal/Modal";
import Tooltip from "../Tooltip/Tooltip";
import ExchangeInfoRow from "./ExchangeInfoRow";
import { useLocalStorageSerializeKey } from "lib/localStorage";
import { SLIPPAGE_BPS_KEY } from "config/localStorage";
import { formatAmount } from "lib/numbers";
import { getWrappedToken } from "config/tokens";
import ArrowDownGreen from "img/trade/arrow-green.svg";
import ArrowDownGreenLight from "img/trade/arrow-green-light.svg";
import cx from "classnames";
import SmallTextNumber from "components/Tooltip/SmallTextNumber";

export default function ConfirmationAggregatorBox(props) {
  const {
    fromToken,
    toToken,
    isSwap,
    isLong,
    isMarketOrder,
    orderOption,
    toAmount,
    fromAmount,
    onConfirmationClick,
    setIsConfirming,
    isPendingConfirmation,
    isSubmitting,
    feeBps,
    chainId,
    rateTypeNormal,
    setRateTypeNormal,
    isLightTheme,
    toUsd,
    fromUsd,
    calcRate,
  } = props;

  const [savedSlippageAmount] = useLocalStorageSerializeKey([chainId, SLIPPAGE_BPS_KEY], DEFAULT_SLIPPAGE_AMOUNT);
  const [isTriggerWarningAccepted, setIsTriggerWarningAccepted] = useState(false);

  let minOut;

  if (isSwap) {
    minOut = toAmount;
  }

  const getTitle = () => {
    if (isSwap) {
      return `Confirm Swap`;
    }
  };
  const title = getTitle();

  const getPrimaryText = () => {
    if (!isPendingConfirmation) {
      if (isSwap) {
        return title;
      }
    }
    return `Swapping...`;
  };

  const isPrimaryEnabled = () => {
    return !isPendingConfirmation && !isSubmitting;
  };

  const renderMain = useCallback(() => {
    if (isSwap) {
      return (
        <div className="Confirmation-box-wrapper">
          <span>
            <span>Pay</span>&nbsp;{formatAmount(fromAmount, fromToken.decimals, 4, true)} {fromToken.symbol} ($
            {formatAmount(fromUsd || 0, fromToken.decimals || USD_DECIMALS, 2, true)})
          </span>
          <img src={isLightTheme ? ArrowDownGreenLight : ArrowDownGreen} className="Confirmation-box-wrapper-icon" />
          <span>
            <span>Receive</span>&nbsp;{formatAmount(toAmount, toToken.decimals, 4, true)} {toToken.symbol} ($
            {formatAmount(toUsd || 0, toToken.decimals || USD_DECIMALS, 2, true)})
          </span>
        </div>
      );
    }

    return (
      <div className="Confirmation-box-main">
        <span>
          <span>Pay</span>&nbsp;{formatAmount(fromAmount, fromToken.decimals, 4, true)} {fromToken.symbol} ($
          {formatAmount(0, USD_DECIMALS, 2, true)})
        </span>
        <div className="Confirmation-box-main-icon"></div>
        <div>
          {isLong ? `Long` : `Short`}&nbsp;
          {formatAmount(toAmount, toToken.decimals, 4, true)} {toToken.symbol} ($
          {formatAmount(0, USD_DECIMALS, 2, true)})
        </div>
      </div>
    );
  }, [isSwap, fromAmount, fromToken, toToken, isLong, toAmount]);

  const renderSwapSection = useCallback(() => {
    return (
      <>
        <div>
          {renderMain()}

          <div className="Exchange-desc-label">
            Output is estimated. You will receive at least{" "}
            {formatAmount(
              !(fromToken.isNative && toToken.isWrapped) && !(fromToken.isWrapped && toToken.isNative)
                ? toAmount.mul(BASIS_POINTS_DIVISOR - savedSlippageAmount).div(BASIS_POINTS_DIVISOR)
                : toAmount,
              toToken.decimals,
              4,
              true
            )}{" "}
            {toToken.symbol} or the transaction will revert.
          </div>
          <ExchangeInfoRow label={`Price`}>
            <div className="price-token-styled">
              <div className="exchange-token-value">
                <span className="price-styled">1</span>
                <span className="symbol-styled">{rateTypeNormal ? fromToken.symbol : toToken.symbol}</span> =
                <span className="price-styled">
                  {" "}
                  {<SmallTextNumber val={formatAmount(calcRate, 6, 6, false) || "--"} decimal={4} />}
                </span>
                <span className="symbol-styled">{rateTypeNormal ? toToken.symbol : fromToken.symbol}</span>
              </div>
              <div className="icon-styled" onClick={setRateTypeNormal}>
                <img src={SwapIcon} alt="swap icon" />
              </div>
            </div>
          </ExchangeInfoRow>

          <div className={cx("Exchange-info-row", { "top-line": false })}>
            <Tooltip
              handle={<div className="Exchange-info-label">Minimum received</div>}
              position="left-bottom"
              renderContent={() => {
                return (
                  <>
                    <span className="text-desc">
                      Your transaction will revert if there is a large, unfavorable price movement before it is
                      confirmed.
                    </span>
                  </>
                );
              }}
            ></Tooltip>
            <div className={`align-right`} style={{ fontWeight: 700 }}>
              {formatAmount(
                !(fromToken.isNative && toToken.isWrapped) && !(fromToken.isWrapped && toToken.isNative)
                  ? toAmount.mul(BASIS_POINTS_DIVISOR - savedSlippageAmount).div(BASIS_POINTS_DIVISOR)
                  : toAmount,
                toToken.decimals,
                4,
                true
              )}{" "}
              {toToken.symbol}
            </div>
          </div>
        </div>
      </>
    );
  }, [renderMain, toToken, orderOption, feeBps, isMarketOrder]);

  return (
    <div className="Confirmation-box">
      <Modal isVisible={true} setIsVisible={() => setIsConfirming(false)} label={title} allowContentTouchMove>
        {isSwap && renderSwapSection()}
        <div className="Confirmation-box-row">
          <button
            onClick={onConfirmationClick}
            className={`App-cta Confirmation-box-button ${isLong || isSwap ? "" : "short-btn-default"}`}
            disabled={!isPrimaryEnabled()}
          >
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
    </div>
  );
}
