import React, { useContext, useEffect, useState } from "react";
import Footer from "components/Footer/Footer";
import styled, { keyframes } from "styled-components";

import { useChainId } from "lib/chains";

import Hero from "./components/Hero";
import Carousel from "./components/Carousel";
import Superiority from "./components/Superiority";
import Optimize from "./components/Optimize";
import AvailableNetworks from "./components/AvailableNetworks";
import EcosystemTokens from "./components/EcosystemTokens";

import gmx40Icon from "img/ic_mmy_40.svg";
import Boundries from "./components/Boundries";
import Partners from "./components/Partners";

export default function HomeV3() {
  const { chainId } = useChainId();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => {
      clearTimeout(timerId);
    };
  }, []);

  return (
    <>
      {isLoading && (
        <PageLoader>
          <Loader />
          <img src={gmx40Icon} alt="gmxIcon" />
        </PageLoader>
      )}
      <Wrapper>
        <Hero chainId={chainId}/>
        <Carousel />
        <Boundries />
        {/* <Superiority /> */}
        <Optimize chainId={chainId} />
        <AvailableNetworks chainId={chainId} />
        <EcosystemTokens />
        <Partners />
        <Footer />
      </Wrapper>
    </>
  );
}

const loading = keyframes`
  0% {

    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
`;

const PageLoader = styled.div`
  background-color: #000;
  position: fixed;
  inset: 0;
  z-index: 999;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80px;
    height: 80px;
  }
`;

const Loader = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  border-radius: 999px;
  border: 3px solid #34f5ae;
  border-top-color: transparent;
  animation: ${loading} 1s linear infinite forwards;
`;

const Wrapper = styled.div`
  min-height: 100vh;
  background: linear-gradient(to bottom, #000000 27.03%, #0c1123 100%);
  padding-bottom: 284px;

  .container {
    width: 100%;
    max-width: 1320px;
    margin: 0 auto;
  }
`;
