import styled from "styled-components";

import icDot from "img/home/dot-desc-ic.svg";
import Img1 from "img/home/home-content-img-1.svg";
import Img2 from "img/home/home-content-img-2.svg";
import Img3 from "img/home/home-content-img-3.svg";
import Img4 from "img/home/home-content-img-4.svg";
import LineLeftToRightImg from "img/home/line-left-to-right.svg";
import LineLeftToRightMobileImg from "img/home/line-left-to-right-mobile.svg";
import LineRightToLeftImg from "img/home/line-right-to-left.svg";
import LineRightToLeftMobileImg from "img/home/line-right-to-left-mobile.svg";
import { useMediaQuery } from "react-responsive";

const Boundries = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  return (
    <Wrapper className="container">
      <ChildCpn
        imgUrl={Img1}
        titleContent="Perpetual Trade Everything"
        listDescContent={[
          "Mummy V2 offers trading in Cryptocurrencies, Forex, and Commodities.",
          "We have over 20 trading pairs available at launch and plan to add more in the future.",
          "Enjoy super high leverage, up to 250x for maximum trading potential.",
        ]}
      />
      <LineLeftToRight>
        <img src={isMobile ? LineLeftToRightMobileImg : LineLeftToRightImg} alt="Line" />
      </LineLeftToRight>
      <ChildCpn
        imgUrl={Img2}
        isSwapContent={isMobile ? false : true}
        titleContent="CEX Trading Features"
        listDescContent={[
          "Mummy V2 offers essential features for position management: TP/SL, Add Collateral, Increase Leverage, Increase Position, and Trailing Stop.",
          "Earn rebates by referring friends to trade on Mummy V2.",
          "Easily share your position on your social media platforms.",
        ]}
      />
      <LineRightToLeft>
        <img src={isMobile ? LineRightToLeftMobileImg : LineRightToLeftImg} alt="Line" />
      </LineRightToLeft>
      <ChildCpn
        imgUrl={Img3}
        titleContent="Stablecoin-only Liquidity Pool"
        listDescContent={[
          "All trades on Mummy V2 are routed through a stablecoin-only liquidity pool.",
          "The stablecoin-only liquidity pool aids in stabilizing liquidity providers' capital from the volatility of blue-chip tokens.",
          "No need to worry about liquidity issues when trading any pair.",
        ]}
      />
      <LineLeftToRight>
        <img src={isMobile ? LineLeftToRightMobileImg : LineLeftToRightImg} alt="Line" />
      </LineLeftToRight>
      <ChildCpn
        imgUrl={Img4}
        isSwapContent={isMobile ? false : true}
        titleContent="Decentralization & Transparency"
        listDescContent={[
          "Mummy V2 utilizes On-chain Pyth Price Feeds to prevent trading fraud and scam-wicks.",
          "All funds on Mummy V2 are self-custody.",
          "The smart contracts are fully transparent and open-source.",
        ]}
      />
    </Wrapper>
  );
};

const ChildCpn = (props) => {
  const { isSwapContent, imgUrl, titleContent, listDescContent } = props;
  return (
    <WrapperChild>
      <div className={`container-child ${!isSwapContent ? "image-to-left" : "image-to-right"}`}>
        <ImageGroupChild className="img-group">
          <img src={imgUrl} className="img-styled" />
        </ImageGroupChild>
        <ContentGroupChild className="content-group">
          <TitleContent>{titleContent}</TitleContent>
          <DescContentGroup>
            {listDescContent.map((item) => (
              <DescItem>
                <img src={icDot} className="dot-icon-styled" />
                <span>{item}</span>
              </DescItem>
            ))}
          </DescContentGroup>
        </ContentGroupChild>
      </div>
    </WrapperChild>
  );
};
const WrapperChild = styled.div`
  .container-child {
    display: flex;
    justify-content: space-between;
    gap: 24px;
  }
  .image-to-left .img-group {
    order: 1;
  }

  .image-to-left .content-group {
    order: 2;
  }

  .image-to-right .img-group {
    order: 2;
  }

  .image-to-right .content-group {
    order: 1;
  }
  @media (max-width: 767px) {
    .container-child {
      gap: 16px;
      flex-direction: column;
    }
  }
`;
const ImageGroupChild = styled.div`
  display: flex;
  /* padding: 0px 42px 0px 42px; */
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  transition: transform 0.5s ease;
  img {
    width: 424px;
    height: 260px;
  }
  &:hover {
    transform: scale(1.1);
    transition: transform 0.5s ease;
  }
  @media (max-width: 1024px) {
    img {
      width: 326px;
      height: 200px;
    }
  }

  @media (max-width: 767px) {
    img {
      width: 260px;
      height: 160px;
    }
  }
`;
const ContentGroupChild = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  max-width: 648px;
`;
const TitleContent = styled.div`
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  background: linear-gradient(90deg, #03f5ae 0%, #e3fff7 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media (max-width: 767px) {
    font-size: 20px;
  }
`;
const DescContentGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
const DescItem = styled.div`
  display: flex;
  align-items: start;
  gap: 8px;
  .dot-icon-styled {
    margin-top: 5px;
  }
  span {
    font-size: 16px;
    font-weight: 400;
    line-height: 140%;
    color: #fff !important;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 64px 24px 120px;
  align-items: center;

  @media (max-width: 1024px) {
  }

  @media (max-width: 767px) {
    padding: 40px 16px 120px;
  }
`;
const LineRightToLeft = styled.div`
  img {
    width: 662px;
    height: 105px;
  }
  @media (max-width: 1024px) {
    img {
      width: 405px;
    }
  }

  @media (max-width: 767px) {
    img {
      width: 343px;
    }
  }
`;
const LineLeftToRight = styled.div`
  img {
    width: 662px;
    height: 105px;
  }
  @media (max-width: 1024px) {
    img {
      width: 405px;
    }
  }

  @media (max-width: 767px) {
    img {
      width: 343px;
    }
  }
`;

export default Boundries;
