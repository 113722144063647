import { Pagination } from "antd";
import "../styles/PaginationV2.scss";
import EllipsisIcon from "components/Icon/EllipsisIcon";
import ChevronRightIcon from "components/Icon/ChevronRightIcon";
import ChevronLeftIcon from "components/Icon/ChevronLeftIcon";

import { useMedia } from "react-use";

const PaginationV2 = ({ rowsPerPage, setRowsPerPage, rows, currentPage, setCurrentPage }) => {
  const isTablet = useMedia("(max-width: 900px)");
  const isSmall = useMedia("(max-width: 400px)");
  return (
    <Pagination
      defaultCurrent={1}
      className="pagination-v2"
      total={rows}
      jumpNextIcon={<EllipsisIcon></EllipsisIcon>}
      jumpPrevIcon={<EllipsisIcon></EllipsisIcon>}
      prevIcon={<ChevronLeftIcon></ChevronLeftIcon>}
      nextIcon={<ChevronRightIcon></ChevronRightIcon>}
      current={currentPage}
      showLessItems={isTablet ? true : false}
      size={isSmall ? 'small' : 'default'}
      onChange={setCurrentPage}
      showSizeChanger={false}
      pageSize={rowsPerPage}
    />
  );
};

export default PaginationV2;
