import axios from "axios";
import { useChainId } from "lib/chains";
import { useEffect, useState } from "react";
import { getServerBaseUrl, getServerUrl } from "config/backend";
import { FANTOM, SUPPORTED_V2_CHAINS } from "config/chains";

const useUserStats = (account) => {
  const [stats, setStats] = useState(null);
  const { chainId } = useChainId();
  const fetchPositions = async () => {
    if (account) {
      const res = await axios.get(`${getServerBaseUrl(chainId, true)}/public/account-stats/${account}`, {});

      if (res && res.data?.data) {
        setStats(res.data?.data);
      } else {
        setStats([]);
      }
    }
  };
  useEffect(() => {
    if (SUPPORTED_V2_CHAINS.includes(chainId)) {
      fetchPositions();
      const interval = setInterval(async () => {
        fetchPositions();
      }, 15000);
      return () => clearInterval(interval);
    } else setStats([]);
  }, [account, chainId]);
  return stats;
};
export default useUserStats;
