import { RiLoader5Fill } from "react-icons/ri";
import cx from "classnames";
import CsvLink from "./CsvLink";

type ChartWrapper = {
  title: string;
  loading?: boolean;
  data?: Array<any>;
  csvFields?: Array<{ key: string; name: string }>;
  controls?: {
    convertToPercents?: (data) => void;
  };
  viewState?: {
    isPercentsView?: boolean;
  };
  togglePercentView?: () => void;
  children?: React.ReactNode;
};

export default function ChartWrapper(props: ChartWrapper) {
  const { title, loading, csvFields, data, controls, viewState, togglePercentView, children } = props;

  return (
    <>
      <div className="chart-header">
        <h3 className="chart-name">
          {title}
          <CsvLink fields={csvFields} name={title} data={data} />
        </h3>
        {controls && (
          <div className="chart-controls">
            {controls && (
              <div
                className={cx({ "chart-control-checkbox": true, active: viewState  ? viewState.isPercentsView : false })}
                onClick={togglePercentView}
              >
                %
              </div>
            )}
          </div>
        )}
      </div>
      {loading && <div className="skeleton-box" style={{ height: "20px", marginBottom: "10px" }} />}
      {children}
    </>
  );
}
