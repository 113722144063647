import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

// import MMY_ETH from "img/MMY-ETH.svg";
import MMY_FTM from "img/MMY-FTM.svg";
import ftmBackdrop from "img/home/ftmBackdrop.svg";
import ftmBackdropLight from "img/home/ftmBackdropLight.svg";
import playIcon from "img/home/ic_play.svg";
import playIconLight from "img/home/ic_playLight.svg";
// import opBackdrop from "img/home/opBackdrop.svg";
import arbBackdrop from "img/home/arbBackdrop.svg";
import arbBackdropLight from "img/home/arbBackdropLight.svg";
import tokensIcon from "img/tokens.svg";
import blue_corner_ic from "img/blue_corner.svg";
import mmy_ftm_coin from "img/mmy_ftm_coin.svg";

import { ARBITRUM, FANTOM, OP } from "config/chains";
import { formatAmountFree, formatNumber } from "lib/numbers";

import useProcessedData from "hooks/useProcessedData";
import { caculateAPYDaily, caculateAPYHourly } from "lib/helper";
import useSwitchChain from "hooks/useSwitchChain";
import { useThemeContext } from "contexts/ThemeProvider";
import Tokens from "./Tokens";

// const MMY_WETH_Address = "0x104d0312f95a1b3056435d19668f2272ab1e7df2"; // fix me later

const YieldVault = ({ chainId, active, library }) => {
  const { lightThemeClassName, isLightTheme } = useThemeContext();
  const [switchChain] = useSwitchChain(chainId);

  const processedDataFANTOM = useProcessedData(FANTOM);
  // const processedDataOP = useProcessedData(OP);
  const processedDataARB = useProcessedData(ARBITRUM);

  const [statsMMYWFTM, setStatsMMYWFTM] = useState({
    price: 0,
    apr: 10,
  });

  // const [statsMMYWETH, setStatsMMYWETH] = useState({
  //   price: 0,
  //   apr: 1,
  // });

  const [statsMMYARB, setStatsMMYARB] = useState({
    price: 0,
    apr: 10,
  });

  // const totalApr = statsMMYWFTM.apr;

  // const totalApy = !isNaN(formatNumber(String(caculateAPYHourly(Number(totalApr), 4080)), 2))
  //   ? formatNumber(String(caculateAPYHourly(Number(totalApr), 4080)), 2)
  //   : "--";

  useEffect(() => {
    const fetchStatsMMYWFTM = async () => {
      const res = await axios.get(`https://api.mummy.finance/api/tokens/info?symbols=MMY/FTM&chain=FANTOM`);
      // const resAPR = await axios.get(`https://api.grim.finance/apy/breakdown`);

      if (res && res.data) {
        const { price } = res.data["MMY/FTM"];
        // const apr = resAPR.data["equalizerv2-mmy-ftm"]?.vaultApr
        //   ? resAPR.data["equalizerv2-mmy-ftm"]?.vaultApr * 100
        //   : 0;
        setStatsMMYWFTM({
          apr: 10,
          price,
        });
      }

      if (res && res.data) {
        const { price } = res.data["MMY/FTM"];

        setStatsMMYARB((prev) => ({
          ...prev,
          price,
        }));
      }
    };

    // const fetchStatsMMYWETH = async () => {
    //   const res = await axios.get(`https://api.velodrome.finance/api/v1/pairs`, {});

    //   if (
    //     res &&
    //     res.data &&
    //     res.data.data &&
    //     res.data.data.find((item) => item.address.toLowerCase() === MMY_WETH_Address)
    //   ) {
    //     const { apr, tvl, totalSupply } = res.data.data.find((item) => item.address.toLowerCase() === MMY_WETH_Address);

    //     setStatsMMYWETH({
    //       price: tvl / totalSupply,
    //       apr,
    //     });
    //   }
    // };

    fetchStatsMMYWFTM();
    const timerId = setInterval(() => {
      fetchStatsMMYWFTM();
      // fetchStatsMMYWETH();
    }, 30000);

    return () => {
      clearInterval(timerId);
    };
  }, []);

  const totalApyMMY_FTM = statsMMYWFTM.apr
    ? formatNumber(String(caculateAPYHourly(Number(statsMMYWFTM.apr), 4080)), 2)
    : "--";

  let aprTotalGmx;
  let apyGmx;
  // let dailyApyGmx;
  aprTotalGmx = parseFloat(formatAmountFree(processedDataFANTOM?.gmxAprTotal, 2));
  apyGmx = aprTotalGmx ? formatNumber(caculateAPYHourly(aprTotalGmx, 4080), 2) : null;
  // dailyApyGmx = aprTotalGmx ? formatNumber(caculateAPYDaily(aprTotalGmx, 1), 4) : null;

  let aprTotalGlp;
  let apyGlp;
  // let dailyApyGlp;
  aprTotalGlp = parseFloat(formatAmountFree(processedDataFANTOM?.glpAprTotal, 2));
  apyGlp = aprTotalGlp ? formatNumber(caculateAPYHourly(aprTotalGlp, 4080), 2) : null;
  // dailyApyGlp = aprTotalGlp ? formatNumber(caculateAPYDaily(aprTotalGlp, 1), 4) : null;

  // let aprTotalGmxFtm;
  // let apyGmxFtm;
  // let dailyApyGmxFtm;
  // aprTotalGmxFtm = parseFloat(statsMMYWFTM.apr);
  // apyGmxFtm = aprTotalGmxFtm ? formatNumber(caculateAPYHourly(aprTotalGmxFtm, 4080), 2) : null;
  // dailyApyGmxFtm = aprTotalGmxFtm ? formatNumber(caculateAPYDaily(aprTotalGmxFtm, 1), 4) : null;

  // let aprTotalGmxEth;
  // let apyGmxEth;
  // let dailyApyGmxEth;
  // aprTotalGmxEth = parseFloat(statsMMYWETH.apr);
  // apyGmxEth = aprTotalGmxEth ? formatNumber(caculateAPYHourly(aprTotalGmxEth, 4080), 2) : null;
  // dailyApyGmxEth = aprTotalGmxEth ? formatNumber(caculateAPYDaily(aprTotalGmxEth, 1), 4) : null;

  let aprTotalGmxARB;
  let apyGmxARB;
  // let dailyApyGmxARB;
  aprTotalGmxARB = parseFloat(formatAmountFree(processedDataARB?.gmxAprTotal, 2));
  apyGmxARB = aprTotalGmxARB ? formatNumber(caculateAPYHourly(aprTotalGmxARB, 4080), 2) : null;
  // dailyApyGmxARB = aprTotalGmxARB ? formatNumber(caculateAPYDaily(aprTotalGmxARB, 1), 4) : null;

  let aprTotalGlpARB;
  let apyGlpARB;
  // let dailyApyGlpARB;
  aprTotalGlpARB = parseFloat(formatAmountFree(processedDataARB?.glpAprTotal, 2));
  apyGlpARB = aprTotalGlpARB ? formatNumber(caculateAPYHourly(aprTotalGlpARB, 4080), 2) : null;
  // dailyApyGlpARB = aprTotalGlpARB ? formatNumber(caculateAPYDaily(aprTotalGlpARB, 1), 4) : null;

  const apy = {
    apyGmx: {
      [FANTOM]: apyGmx,
      [ARBITRUM]: apyGmxARB,
    },
    apyGlp: {
      [FANTOM]: apyGlp,
      [ARBITRUM]: apyGlpARB,
    },
    apyMMY_FTM: {
      [FANTOM]: totalApyMMY_FTM,
    },
    priceMMY_FTM: {
      [FANTOM]: statsMMYWFTM.price,
    },
  };

  return (
    <Wrapper>
      {/* <Title>
        <span>Mummy Yield Optimizer Vault</span>
        <div className="desc">
          Our cutting-edge auto-compound Yield Optimizer strategy is designed to empower investors to effortlessly earn
          rewards.
        </div>
      </Title> */}
      {/* <TokenCardContainer>
        <TokenItem>
          <div className="info">
            <img src={isLightTheme ? ftmBackdropLight : ftmBackdrop} alt="ftmBackdrop" className="backdrop" />
            <div className="symbol">
              <img src={gmx40Icon} alt="mmy_icon" />
              <div className="symbol">MMY</div>
            </div>
            <div className="stats">
              <div className="stat-row">
                APY: <span className={`value ${getSkeletonClassName(apyGmx)}`}>{apyGmx}%</span>
              </div>
              <div className="stat-row">
                Daily: <span className={`value ${getSkeletonClassName(dailyApyGmx)}`}>{dailyApyGmx}%</span>
              </div>
            </div>
          </div>
          <ViewPoolBtn to="vault" onClick={() => switchChain(FANTOM)}>
            <img src={isLightTheme ? playIconLight : playIcon} alt="playIcon" />
          </ViewPoolBtn>
        </TokenItem>
        <TokenItem>
          <div className="info">
            <img src={isLightTheme ? ftmBackdropLight : ftmBackdrop} alt="ftmBackdrop" className="backdrop" />
            <div className="symbol">
              <img src={tokensIcon} alt="tokensIcon" />
              <div className="symbol">MLP</div>
            </div>
            <div className="stats">
              <div className="stat-row">
                APY: <span className={`value ${getSkeletonClassName(apyGlp)}`}>{apyGlp}%</span>
              </div>
              <div className="stat-row">
                Daily: <span className={`value ${getSkeletonClassName(dailyApyGlp)}`}>{dailyApyGlp}%</span>
              </div>
            </div>
          </div>
          <ViewPoolBtn to="vault" onClick={() => switchChain(FANTOM)}>
            <img src={isLightTheme ? playIconLight : playIcon} alt="playIcon" />
          </ViewPoolBtn>
        </TokenItem>
        <TokenItem>
          <div className="info">
            <img src={isLightTheme ? ftmBackdropLight : ftmBackdrop} alt="ftmBackdrop" className="backdrop" />
            <div className="symbol">
              <img src={MMY_FTM} alt="MMY_FTM" />
              <div className="symbol">MMY-WFTM</div>
            </div>
            <div className="stats">
              <div className="stat-row">
                APY: <span className={`value ${getSkeletonClassName(apyGmxFtm)}`}>{totalApy}% </span>
              </div>
              <div className="stat-row">
                Daily: <span className={`value ${getSkeletonClassName(dailyApyGmxFtm)}`}>{dailyApyGmxFtm}%</span>
              </div>
            </div>
          </div>
          <ViewPoolBtn to="vault" onClick={() => switchChain(FANTOM)}>
            <img src={isLightTheme ? playIconLight : playIcon} alt="playIcon" />
          </ViewPoolBtn>
        </TokenItem>
        <TokenItem>
          <div className="info">
            <img src={isLightTheme ? arbBackdropLight : arbBackdrop} alt="arbBackdrop" className="backdrop" />
            <div className="symbol">
              <img src={gmx40Icon} alt="mmy_icon" />
              <div className="symbol">MMY</div>
            </div>
            <div className="stats">
              <div className="stat-row">
                APY: <span className={`value ${getSkeletonClassName(apyGmxARB)}`}>{apyGmxARB}%</span>
              </div>
              <div className="stat-row">
                Daily: <span className={`value ${getSkeletonClassName(dailyApyGmxARB)}`}>{dailyApyGmxARB}%</span>
              </div>
            </div>
          </div>
          <ViewPoolBtn to="vault" onClick={() => switchChain(ARBITRUM)}>
            <img src={isLightTheme ? playIconLight : playIcon} alt="playIcon" />
          </ViewPoolBtn>
        </TokenItem>
        <TokenItem className="arb">
          <div className="info">
            <img src={isLightTheme ? arbBackdropLight : arbBackdrop} alt="arbBackdrop" className="backdrop" />
            <div className="symbol">
              <img src={tokensIcon} alt="tokensIcon" />
              <div className="symbol">MLP</div>
            </div>
            <div className="stats">
              <div className="stat-row">
                APY: <span className={`value ${getSkeletonClassName(apyGlpARB)}`}>{apyGlpARB}%</span>
              </div>
              <div className="stat-row">
                Daily: <span className={`value ${getSkeletonClassName(dailyApyGlpARB)}`}>{dailyApyGlpARB}%</span>
              </div>
            </div>
          </div>
          <ViewPoolBtn to="vault" onClick={() => switchChain(ARBITRUM)}>
            <img src={isLightTheme ? playIconLight : playIcon} alt="playIcon" />
          </ViewPoolBtn>
        </TokenItem>
      </TokenCardContainer> */}
      <Tokens active={active} chainId={chainId} library={library} apy={apy} />
    </Wrapper>
  );
};

const TokenCardContainer = styled.div`
  margin-top: 24px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;

  @media (max-width: 1023px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (max-width: 767px) {
    margin-top: 16px;
    gap: 16px;
  }

  .content-mlp {
    grid-template-columns: repeat(4, 1fr);

    @media (max-width: 767px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--base-unit-xs-8, 8px);
  align-self: stretch;

  & > span {
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    line-height: 140%; /* 33.6px */
  }

  .desc {
    color: var(--white-60, rgba(255, 255, 255, 0.6));
    font-size: 16px;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
  }
`;

const TokenItem = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border: 1px solid #ffffff1a;
  border-radius: 16px;
  flex: 1;
  background: linear-gradient(90deg, rgba(19, 181, 236, 0.1) 0%, rgba(19, 181, 236, 0) 100%);

  &.op {
    background: linear-gradient(90deg, rgba(255, 4, 32, 0.1) 0%, rgba(255, 4, 32, 0) 100%);
  }

  .info {
    .symbol {
      display: flex;
      align-items: center;
      gap: 12px;
      font-weight: 700;
      font-size: 16px;
      line-height: 140%;

      img[alt="mmy_icon"] {
        width: 36px;
        height: 36px;
      }
      img:not([alt="mmy_icon"]) {
        width: 54px;
      }
    }

    .backdrop {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 16px;
    }

    .stats {
      display: flex;
      gap: 16px;
      margin-top: 16px;

      .stat-row {
        color: #ffffff99;
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;

        .value {
          color: #03f5ae;

          &.skeleton-box {
            width: 50px !important;
          }
        }

        &:last-child {
          padding-left: 16px;
          border-left: 1px solid #ffffff1a;
        }
      }
    }
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`;

const ViewPoolBtn = styled(Link)`
  width: 32px;
  height: 32px;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #080715;
  background-color: #03f5ae;
  padding: 4px;
  border-radius: 8px;
  border: none;
  outline: none;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 12px;
  right: 12px;
  &:hover {
    background: #04d397;
    color: #080715 !important;
  }
`;

const Wrapper = styled.div`
  margin: 64px 0 0;

  @media (max-width: 767px) {
    margin: 36px 0 0;

    div.Tooltip-popup:has(.customTooltip) {
      transform: translateX(-100px);
    }
  }

  &.theme--light {
    .desc {
      color: #0d1a16 !important;
    }

    ${TokenItem} {
      border-color: var(--Border, rgba(13, 26, 22, 0.07));
      background: linear-gradient(90deg, rgba(19, 181, 236, 0.1) 0%, rgba(19, 181, 236, 0) 100%);

      &.arb {
        background: linear-gradient(90deg, rgba(18, 140, 255, 0.1) 0%, rgba(4, 134, 255, 0) 100%);
      }

      .stat-row {
        color: var(--Text-Text_Primary, #0d1a16);

        .value {
          color: var(--Primary, #02b27f);
        }
      }
    }

    ${ViewPoolBtn} {
      background: #02b27f;
    }
  }
`;

export default YieldVault;
