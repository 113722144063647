import React, { useState } from "react";
import { BigNumber } from "ethers";

import { importImage } from "lib/legacy";
import Modal from "../Modal/Modal";
import ExchangeInfoRow from "./ExchangeInfoRow";
import "./PositionSeller.css";
import { formatAmount } from "lib/numbers";
import { titleCase } from "./TradeActivityList";
import moment from "moment";
import PositionHistory from "./PositionHistory";
import usePositionActivities from "hooks/usePositionActivities";
import { THEME_KEY } from "config/localStorage";
import { getTokenSymbolFromString } from "domain/tokens";

const dateFormat = "YYYY-MM-DD";
const timeFormat = "HH:mm:ss";

export default function TradeActivity(props) {
  const { isVisible, position, setIsVisible, chainId } = props;

  const [isOpenPositionHistory, setIsOpenPositionHistory] = useState(false);
  const { data: positionActivities } = usePositionActivities(chainId, position.posId);
  const profit = BigNumber.from(position.profitLoss);
  const paidFeeds = BigNumber.from(position.positionFee)
    .add(BigNumber.from(position.fundingFee))
    .add(BigNumber.from(position.borrowFee));

  return (
    <div className="PositionEditor">
      {position && (
        <Modal
          className={`PositionSeller-modal  confirmation-modal details-position-modal`}
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          label={"Trade Activity"}
          allowContentTouchMove
        >
          <div className="order-title-line order-title-line-details">
            <img
              src={importImage("ic_" + getTokenSymbolFromString(position?.symbol).toLowerCase() + "_24.svg")}
              alt=""
            />
            <div className="position-info-container">
              <div className="position-info">
                <div className="title">{getTokenSymbolFromString(position?.symbol)}</div>
                <div className={`side ${position?.isLong ? "side-long" : "side-short"}`}>
                  {position?.isLong ? "LONG" : "SHORT"}
                </div>
                <div className="side">
                  {position?.positionType === "Market Order" ? "Market" : position?.positionType}
                </div>
              </div>
              <div className="position-id">#{position?.posId}</div>
            </div>
            <div className="postion-history" onClick={() => setIsOpenPositionHistory(true)}>
              Position History
            </div>
          </div>
          <div className="content-container">
            <div className="order-details-container">
              <div className="title">Activity Details</div>
              <div className="square-container">
                <ExchangeInfoRow label={`Action`}>{titleCase(position.actionType.replace("_", " "))}</ExchangeInfoRow>
                <ExchangeInfoRow label={`Order Type`}>{position.positionType}</ExchangeInfoRow>
                <ExchangeInfoRow label={`Date/Time`}>
                  {moment(new Date(position.createdAt * 1000)).format(dateFormat)}&nbsp;
                  {moment(new Date(position.createdAt * 1000)).format(timeFormat)}
                </ExchangeInfoRow>
                {/* <ExchangeInfoRow label={`Execution Price`}>!$0.0900</ExchangeInfoRow> */}
                <ExchangeInfoRow label={`Pos. Collateral`}>
                  ${formatAmount(position.collateral, 30, 2, true)}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Pos. Size`}>
                  ${formatAmount(position.tradeVolume, 30, 2, true)}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Pos. Qty`}>
                  {formatAmount(position.quantity, 30, 4, true)} {getTokenSymbolFromString(position?.symbol)}
                </ExchangeInfoRow>
                <ExchangeInfoRow
                  label={`Realized PnL`}
                  isPositive={profit.gte(0)}
                  textStyled={{
                    color: profit.gt(0) ? "#03F5AE" : profit.isZero() ? "#fff" : "#F6475D",
                  }}
                >
                  {profit.gt(0) ? "+" : profit.isZero() ? "" : "-"}${formatAmount(profit, 30, 2, true).replace("-", "")}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Fees Paid`}>
                  {" "}
                  {paidFeeds.lt(0) ? "-" : ""}${formatAmount(paidFeeds, 30, 2, true).replace("-", "")}
                </ExchangeInfoRow>
              </div>
            </div>
          </div>
          <PositionHistory
            isVisible={isOpenPositionHistory}
            setIsVisible={setIsOpenPositionHistory}
            position={position}
            rows={positionActivities}
            chainId={chainId}
            optionalHeader={
              <div className="order-title-line order-title-line-details">
                <img
                  src={importImage("ic_" + getTokenSymbolFromString(position?.symbol).toLowerCase() + "_24.svg")}
                  alt=""
                />
                <div className="position-info-container">
                  <div className="position-info">
                    <div className="title">{position.symbol}</div>
                    <div className={`side ${position?.isLong ? "side-long" : "side-short"}`}>
                      {position?.isLong ? "LONG" : "SHORT"}
                    </div>
                    <div className="side">
                      {position?.positionType === "Market Order" ? "Market" : position?.positionType}
                    </div>
                  </div>
                  <div className="position-id">#{position?.posId}</div>
                </div>
              </div>
            }
          />
        </Modal>
      )}
    </div>
  );
}
