import React from "react";
import noItemFoundIc from "img/ic_noitemfound.svg";
import noItemFoundIcLight from "img/ic_noitemfound_light.svg";
import { THEME_LIGHT } from "lib/legacy";
import { THEME_KEY } from "config/localStorage";
import { useThemeContext } from "contexts/ThemeProvider";
const NoItemFound = () => {
  const theme = window.localStorage.getItem(`"${THEME_KEY}"`);
  const { isLightTheme } = useThemeContext();

  return (
    <div
      style={{
        margin: "0 auto",
        width: "100%",
        height: "200px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: "5px",
      }}
      className="no-data-record"
    >
      <img src={isLightTheme ? noItemFoundIcLight : noItemFoundIc} alt="" />
      <span>No item found</span>
    </div>
  );
};

export default NoItemFound;
