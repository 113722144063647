import {
  Bar,
  Label,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
  Line,
} from "recharts";
import ChartWrapper from "./ChartWrapper";

import {
  COLORS,
  LIGHT_COLORS,
  convertToPercents,
  tooltipFormatter,
  tooltipLabelFormatter,
  yaxisFormatter,
} from "lib/helper";
import { useChartViewState } from "hooks/useChartViewState";
import { CHART_COLORS } from "utils/analyticsV3";

const convertToPercentsHandler = (data) =>
  convertToPercents(data, { ignoreKeys: ["cumulative", "movingAverageAll"], totalKey: "all" });

export default function VolumeChartMerged(props) {
  const { data, loading, chartHeight, yaxisWidth, isLightTheme, hideLegend, columnLegend } = props;

  const csvFields = [
    { key: "v1SwapVolume", name: "V1 Swap" },
    { key: "mintMLPVolume", name: "Mint MLP" },
    { key: "burnMLPVolume", name: "Burn MLP" },
    { key: "mintMSLPVolume", name: "Mint MSLP" },
    { key: "burnMSLPVolume", name: "Burn MSLP" },
    { key: "v1LiquidationVolume", name: "V1 Liquidation" },
    { key: "v2LiquidationVolume", name: "V2 Liquidation" },
    { key: "v1TradingVolume", name: "V1 Margin Trading" },
    { key: "v2TradingVolume", name: "V2 Margin Trading" },
    { key: "totalCummulative", name: "Cumulative" },
  ];

  const controls = {
    convertToPercents: convertToPercentsHandler,
  };

  const { viewState, togglePercentView, formattedData, ...viewSettings } = useChartViewState({ controls, data });

  const CustomTooltip = (props) => {
    const { active, payload, label } = props;
    if (active && payload) {
      return (
        <div className="custom-tooltip">
          <div className="label-total">{tooltipLabelFormatter(label, payload)}</div>
          {payload.map((item, index) => {
            return (
              <div className="item-group" key={index}>
                <div style={{ color: item.color || item.stroke || item.fill || "white" }}>{item.name}: </div>
                <div style={{ color: item.color || item.stroke || item.fill || "white" }}>
                  {tooltipFormatter(item.value, item.name, item)} {item.unit || ""}
                </div>
              </div>
            );
          })}
        </div>
      );
    }
    return null;
  };
  const ChartColorForTheme = (index) => {
    const result = isLightTheme ? LIGHT_COLORS[index] : COLORS[index];
    return result;
  };
  return (
    <ChartWrapper
      title="Volume Breakdown"
      loading={loading}
      csvFields={csvFields}
      data={formattedData}
      controls={controls}
      viewState={viewState}
      togglePercentView={togglePercentView}
    >
      <div className={`merged-chart-container ${columnLegend ? "legend-column" : ""}`}>
        <ResponsiveContainer width="100%" height={chartHeight}>
          <ComposedChart data={formattedData}>
            <CartesianGrid strokeDasharray="4" stroke={isLightTheme ? "#0000001A" : "#FFFFFF1A"} />
            <XAxis dataKey="timestamp" tickFormatter={tooltipLabelFormatter} minTickGap={30} />
            <YAxis
              dataKey="all"
              interval="preserveStartEnd"
              tickCount={viewState.isPercentsView ? undefined : 7}
              tickFormatter={viewSettings.yaxisTickFormatter}
              width={yaxisWidth}
            />
            <YAxis
              dataKey="totalCummulative"
              orientation="right"
              yAxisId="right"
              tickFormatter={yaxisFormatter}
              width={yaxisWidth}
            />
            {/* <Tooltip
            formatter={tooltipFormatter}
            labelFormatter={tooltipLabelFormatter}
            contentStyle={{ textAlign: "left" }}
          /> */}
            <Tooltip content={<CustomTooltip />} />
            {/* <Legend /> */}
            <Bar
              unit={viewSettings.itemsUnit}
              isAnimationActive={false}
              type="monotone"
              dataKey="mintMLPVolume"
              stackId="a"
              name="Mint MLP"
              fill={CHART_COLORS[0]}
            />
            <Bar
              unit={viewSettings.itemsUnit}
              isAnimationActive={false}
              type="monotone"
              dataKey="burnMLPVolume"
              stackId="a"
              name="Burn MLP"
              fill={CHART_COLORS[1]}
            />
            <Bar
              unit={viewSettings.itemsUnit}
              isAnimationActive={false}
              type="monotone"
              dataKey="mintMSLPVolume"
              stackId="a"
              name="Mint MSLP"
              fill={CHART_COLORS[2]}
            />
            <Bar
              unit={viewSettings.itemsUnit}
              isAnimationActive={false}
              type="monotone"
              dataKey="burnMSLPVolume"
              stackId="a"
              name="Burn MSLP"
              fill={CHART_COLORS[3]}
            />
            <Bar
              unit={viewSettings.itemsUnit}
              isAnimationActive={false}
              type="monotone"
              dataKey="v1LiquidationVolume"
              stackId="a"
              name="V1 Liquidation"
              fill={CHART_COLORS[4]}
            />{" "}
            <Bar
              unit={viewSettings.itemsUnit}
              isAnimationActive={false}
              type="monotone"
              dataKey="v2LiquidationVolume"
              stackId="a"
              name="V2 Liquidation"
              fill={CHART_COLORS[5]}
            />{" "}
            <Bar
              unit={viewSettings.itemsUnit}
              isAnimationActive={false}
              type="monotone"
              dataKey="v1TradingVolume"
              stackId="a"
              name="V1 Margin Trading"
              fill={CHART_COLORS[6]}
            />{" "}
            <Bar
              unit={viewSettings.itemsUnit}
              isAnimationActive={false}
              type="monotone"
              dataKey="v2TradingVolume"
              stackId="a"
              name="V2 Margin Trading"
              fill={CHART_COLORS[7]}
            />
            <Bar
              unit={viewSettings.itemsUnit}
              isAnimationActive={false}
              type="monotone"
              dataKey="v1SwapVolume"
              stackId="a"
              name="V1 Swap"
              fill={CHART_COLORS[8]}
            />
            <Line
              isAnimationActive={false}
              type="monotone"
              dot={false}
              strokeWidth={2}
              stroke={CHART_COLORS[10]}
              dataKey="totalCummulative"
              yAxisId="right"
              name="Cumulative"
            />
          </ComposedChart>
        </ResponsiveContainer>
        {!hideLegend && (
          <div className="custom-legend">
            {csvFields?.map((item, index) => {
              return (
                <div className="legend-item" key={index}>
                  <div className="legend-name">{item.name}</div>
                  <div
                    className="legend-color"
                    style={{ backgroundColor: item.color || CHART_COLORS[index % CHART_COLORS.length] }}
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </ChartWrapper>
  );
}
