

const ToastInfoIcon = (props: JSX.IntrinsicElements["svg"]) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
            <path d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17316C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8078C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C21.9971 9.34871 20.9426 6.80684 19.0679 4.9321C17.1932 3.05736 14.6513 2.00287 12 2V2ZM12.8567 17.6033H11.1358V10.3333H12.8567V17.6033ZM12.7142 8.04917C12.6192 8.13618 12.5077 8.20317 12.3863 8.24612C12.2649 8.28908 12.136 8.30712 12.0075 8.29917C11.8766 8.30787 11.7453 8.29023 11.6213 8.24729C11.4974 8.20435 11.3833 8.13698 11.2858 8.04917C11.1997 7.95647 11.1332 7.84737 11.0903 7.72839C11.0473 7.6094 11.0288 7.48297 11.0358 7.35667C11.0271 7.22742 11.0448 7.09775 11.0878 6.97555C11.1307 6.85335 11.1981 6.74117 11.2858 6.64583C11.3836 6.55841 11.4977 6.49129 11.6216 6.44837C11.7455 6.40545 11.8766 6.3876 12.0075 6.39583C12.136 6.38836 12.2647 6.40662 12.3861 6.44955C12.5074 6.49248 12.619 6.55921 12.7142 6.64583C12.8019 6.74117 12.8693 6.85335 12.9122 6.97555C12.9552 7.09775 12.9729 7.22742 12.9642 7.35667C12.9712 7.48297 12.9527 7.6094 12.9097 7.72839C12.8668 7.84737 12.8003 7.95647 12.7142 8.04917Z" fill="currentColor" />
        </svg>
    );
};

export default ToastInfoIcon;
