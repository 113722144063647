import { formatAmount, limitDecimals } from "lib/numbers";
import { useEffect, useState } from "react";
import { preventSpecialCharacters } from "utils/helpers";
import "./PercentageButtons.scss";
const PercentageButtons = ({
  onChangePercentage,
  value,
  balance,
  percentArray = [0.25, 0.5, 0.75, 1],
  decimals = 16,
  fullBalance = 0,
  isUsd = false,
}) => {
  const [val, setVal] = useState(0);
  const [isTyping, setIsTyping] = useState(false);
  let timeout = null;
  const onChange = (e) => {
    const inPutValue = e.target.value;
    if (timeout) clearTimeout(timeout);
    if (!isTyping) setIsTyping(true);
    if (!inPutValue.includes("-")) {
      if (Number(inPutValue)) {
        if (inPutValue <= 100) {
          if (inPutValue.includes(".")) {
            const arr = inPutValue.split(".")[1];
            if (arr.length <= 2) {
              setVal(inPutValue);
              if (inPutValue) onChangePercentage && onChangePercentage(inPutValue / 100);
            }
          } else {
            setVal(inPutValue);
            if (inPutValue) onChangePercentage && onChangePercentage(inPutValue / 100);
          }
        }
      } else {
        setVal(inPutValue);
        onChangePercentage && onChangePercentage(0.001);
      }
    }
  };
  const handleOnChangePercentage = (percentage) => {
    onChangePercentage && onChangePercentage(percentage);
    setTimeout(() => {
      setVal(percentage * 100);
    }, 3);
  };
  useEffect(() => {
    if (!timeout) {
      timeout = setTimeout(() => {
        setIsTyping(false);
      }, 1500);
    }
  }, [val]);
  useEffect(() => {
    if (!isTyping) {
      if (value && balance && balance > 0) {
        const percent = limitDecimals((value / balance) * 100, 2);
        if (percent !== val) setVal(Number(percent));
      } else setVal(0);
    }
  }, [value]);
  return (
    <div className="percentage-btn-container">
      <div className={`percentahe-value ${val <= 0 && "init-value"}`}>
        <input onChange={onChange} value={val} placeholder="0" type="number" onKeyDown={preventSpecialCharacters} />
        <span>%</span>
      </div>
      {percentArray.map((item) => (
        <div
          className={`percentage-btn ${
            val == item * 100 ||
            ((limitDecimals(balance * item, decimals) == limitDecimals(value, decimals) ||
              (isUsd &&
                fullBalance &&
                item === 1 &&
                limitDecimals(
                  Number(formatAmount(fullBalance?.mul(99)?.div(100) || 0, 30, decimals, false)),
                  decimals
                ) == limitDecimals(value, decimals))) &&
              balance !== 0)
              ? "percentage-btn-selected"
              : ""
          }`}
          key={item}
          onClick={() => handleOnChangePercentage(item)}
        >
          {`${item * 100}%`}
        </div>
      ))}
    </div>
  );
};
export default PercentageButtons;
