import { THEME_KEY } from "config/localStorage";
import { THEME_DARK, THEME_LIGHT } from "lib/legacy";
import { useLocalStorageSerializeKey } from "lib/localStorage";
import React, { createContext, useContext } from "react";

const ThemeContext = createContext({ isLightTheme: false });

const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useLocalStorageSerializeKey(THEME_KEY, THEME_DARK);

  const toggleTheme = (targetTheme = undefined) => {
    if (["light", "dark"].includes(targetTheme)) {
      setTheme(targetTheme === "dark" ? THEME_DARK : THEME_LIGHT);
    } else {
      setTheme(theme === THEME_DARK ? THEME_LIGHT : THEME_DARK);
    }
  };

  const isLightTheme = theme === THEME_LIGHT;
  const lightThemeClassName = theme === THEME_LIGHT ? THEME_LIGHT : "";

  return (
    <ThemeContext.Provider value={{ theme, setTheme, toggleTheme, isLightTheme, lightThemeClassName }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useThemeContext = () => {
  const value = useContext(ThemeContext);

  if (!value) throw new Error("theme muse be inside ThemeProvider");

  return value;
};

export default ThemeProvider;
