// import Davatar from "@davatar/react";
import { Menu } from "@headlessui/react";
// import { Trans } from "@lingui/macro";
// import { ETH_MAINNET } from "config/chains";
import copy from "img/ic_copy_v2_16.svg";
import externalLink from "img/ic_new_link_v2_16.svg";
import disconnect from "img/ic_sign_out_v2_16.svg";

import copyLight from "img/header/ic_copy_v2_16_light.svg";
import externalLinkLight from "img/header/ic_new_link_v2_16_light.svg";
import disconnectLight from "img/header/ic_sign_out_v2_16_light.svg";

import { helperToast } from "lib/helperToast";
import { shortenAddress, useENS } from "lib/legacy";
// import { useJsonRpcProvider } from "lib/rpc";
import { FaChevronDown } from "react-icons/fa";
import { createBreakpoint, useCopyToClipboard, useMedia } from "react-use";
import "./AddressDropdown.css";
import ExternalLink from "components/ExternalLink/ExternalLink";
// import Avatar from "components/Avatar/Avatar";
// import useYourNFTId from "hooks/useYourNFTId";
// import { useChainId } from "lib/chains";

type Props = {
  account: string;
  accountUrl: string;
  disconnectAccountAndCloseSettings: () => void;
  isLightMode?: boolean;
};

function AddressDropdown({ account, accountUrl, disconnectAccountAndCloseSettings, isLightMode }: Props) {
  const useBreakpoint = createBreakpoint({ L: 600, M: 550, S: 400 });
  const breakpoint = useBreakpoint();
  // const isMobile = useMedia("(max-width: 400px)");
  const [, copyToClipboard] = useCopyToClipboard();
  const { ensName } = useENS(account);
  // const { provider: ethereumProvider } = useJsonRpcProvider(ETH_MAINNET);
  // const NFTid = useYourNFTId(account);
  // const { chainId } = useChainId();

  return (
    <Menu>
      <Menu.Button as="div">
        <button className="App-cta small transparent address-btn">
          {/* {isMobile ? null : (
            <div className="user-avatar">
              {ethereumProvider && <Davatar size={20} address={account} provider={ethereumProvider} />}
              {ethereumProvider && <Avatar address={account} size="20px" chainId={chainId} NFTid={NFTid} />}
            </div>
          )} */}

          <span className="user-address">{ensName || shortenAddress(account, breakpoint === "S" ? 9 : 13)}</span>
          <FaChevronDown />
        </button>
      </Menu.Button>
      <div>
        <Menu.Items as="div" className="menu-items">
          <Menu.Item>
            <div
              className="menu-item"
              onClick={() => {
                copyToClipboard(account);
                helperToast.success("Address copied to your clipboard");
              }}
            >
              <img src={isLightMode ? copyLight : copy} alt="Copy user address" />
              <p>
                <span>Copy Address</span>
              </p>
            </div>
          </Menu.Item>
          <Menu.Item>
            <ExternalLink href={accountUrl} className="menu-item">
              <img src={isLightMode ? externalLinkLight : externalLink} alt="Open address in explorer" />
              <p>
                <span>View in Explorer</span>
              </p>
            </ExternalLink>
          </Menu.Item>
          <Menu.Item>
            <div className="menu-item" onClick={disconnectAccountAndCloseSettings}>
              <img src={isLightMode ? disconnectLight : disconnect} alt="Disconnect the wallet" />
              <p>
                <span>Disconnect</span>
              </p>
            </div>
          </Menu.Item>
        </Menu.Items>
      </div>
    </Menu>
  );
}

export default AddressDropdown;
