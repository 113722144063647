import TooltipComponent from "components/Tooltip/Tooltip";
import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Trans, t } from "@lingui/macro";
import { useWeb3React } from "@web3-react/core";
import useSWR from "swr";
import { ethers } from "ethers";
import Tab from "../Tab/Tab";
import cx from "classnames";
import { getContract } from "config/contracts";
import styled from "styled-components";
import {
  getBuyGlpToAmount,
  getBuyGlpFromAmount,
  getSellGlpFromAmount,
  getSellGlpToAmount,
  adjustForDecimals,
  GLP_DECIMALS,
  USD_DECIMALS,
  BASIS_POINTS_DIVISOR,
  GLP_COOLDOWN_DURATION,
  SECONDS_PER_YEAR,
  USDG_DECIMALS,
  PLACEHOLDER_ACCOUNT,
  importImage,
  DEFAULT_MAX_USDG_AMOUNT,
  LONG,
  SWAP,
} from "lib/legacy";
import { formatDateTime } from "lib/dates";

import { useGmxPrice } from "domain/legacy";

import TokenSelector from "../Exchange/TokenSelector";
import BuyInputSection from "../BuyInputSection/BuyInputSection";
import Tooltip from "../Tooltip/Tooltip";

import ReaderV2 from "abis/ReaderV2.json";
import RewardReader from "abis/RewardReader.json";
import VaultV2 from "abis/VaultV2.json";
import GlpManager from "abis/GlpManager.json";
import RewardTracker from "abis/RewardTracker.json";
import Vester from "abis/Vester.json";
import RewardRouter from "abis/RewardRouter.json";
import Token from "abis/Token.json";

import glp24Icon from "img/ic_mlp_24.svg";
import glp40Icon from "img/ic_mlp_40.svg";
import arrowIcon from "img/ic_arrow.svg";
import arrowIconLight from "img/ic_arrowLight.svg";

import avalanche16Icon from "img/ic_avalanche_16.svg";
import fantom16Icon from "img/ic_ftm_16.svg";
import base16Icon from "img/ic_base_16.svg";

import op16Icon from "img/ic_op_16.svg";
import arb16Icon from "img/ic_arbitrum_16.svg";

import "./GlpSwap.scss";
import AssetDropdown from "pages/Dashboard/AssetDropdown";
import SwapErrorModal from "./SwapErrorModal";
import StatsTooltipRow from "../StatsTooltip/StatsTooltipRow";
import { ARBITRUM, OP, FANTOM, getChainName, IS_NETWORK_DISABLED, MUMBAI, BASE } from "config/chains";
import { callContract, contractFetcher } from "lib/contracts";
import { approveTokens, useInfoTokens } from "domain/tokens";
import { useLocalStorageByChainId } from "lib/localStorage";
import { helperToast } from "lib/helperToast";
import { getTokenInfo, getUsd } from "domain/tokens/utils";
import { bigNumberify, expandDecimals, formatAmount, formatAmountFree, formatKeyAmount, parseValue } from "lib/numbers";
import { getNativeToken, getToken, getTokens, getWhitelistedTokens, getWrappedToken } from "config/tokens";
import { useChainId } from "lib/chains";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { useMediaQuery } from "react-responsive";
import { useThemeContext } from "contexts/ThemeProvider";

const { AddressZero } = ethers.constants;

function getStakingData(stakingInfo) {
  if (!stakingInfo || stakingInfo.length === 0) {
    return;
  }

  const keys = ["stakedGlpTracker", "feeGlpTracker"];
  const data = {};
  const propsLength = 5;

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    data[key] = {
      claimable: stakingInfo[i * propsLength],
      tokensPerInterval: stakingInfo[i * propsLength + 1],
      averageStakedAmounts: stakingInfo[i * propsLength + 2],
      cumulativeRewards: stakingInfo[i * propsLength + 3],
      totalSupply: stakingInfo[i * propsLength + 4],
    };
  }

  return data;
}

function getTooltipContent(managedUsd, tokenInfo, token) {
  return (
    <>
      <StatsTooltipRow
        label={`Current Pool Amount`}
        value={[
          `$${formatAmount(managedUsd, USD_DECIMALS, 0, true)}`,
          `(${formatKeyAmount(tokenInfo, "managedAmount", token.decimals, 0, true)} ${token.symbol})`,
        ]}
      />
      <StatsTooltipRow label={`Max Pool Capacity`} value={formatAmount(tokenInfo.maxUsdgAmount, 18, 0, true)} />
    </>
  );
}

export default function GlpSwap(props) {
  const { lightThemeClassName, isLightTheme } = useThemeContext();
  const {
    savedSlippageAmount,
    isBuying,
    setPendingTxns,
    connectWallet,
    setIsBuying,
    savedShouldDisableValidationForTesting,
  } = props;
  const history = useHistory();
  const swapLabel = isBuying ? "BuyGlp" : "SellGlp";
  const tabLabel = isBuying ? `Buy MLP` : `Sell MLP`;
  const { active, library, account } = useWeb3React();
  const { chainId } = useChainId();
  // const chainName = getChainName(chainId)
  const tokens = getTokens(chainId);
  const whitelistedTokens = getWhitelistedTokens(chainId);
  const tokenList = whitelistedTokens.filter((t) => !t.isWrapped);
  const visibleTokens = tokenList.filter((t) => !t.isTempHidden);
  const [swapValue, setSwapValue] = useState("");
  const [glpValue, setGlpValue] = useState("");
  const [swapTokenAddress, setSwapTokenAddress] = useLocalStorageByChainId(
    chainId,
    `${swapLabel}-swap-token-address2`,
    AddressZero
  );
  const [isApproving, setIsApproving] = useState(false);
  const [isWaitingForApproval, setIsWaitingForApproval] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [anchorOnSwapAmount, setAnchorOnSwapAmount] = useState(true);
  const [feeBasisPoints, setFeeBasisPoints] = useState("");
  const [modalError, setModalError] = useState(false);

  const readerAddress = getContract(chainId, "Reader");
  const rewardReaderAddress = getContract(chainId, "RewardReader");
  const vaultAddress = getContract(chainId, "Vault");
  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
  const stakedGlpTrackerAddress = getContract(chainId, "StakedGlpTracker");
  const feeGlpTrackerAddress = getContract(chainId, "FeeGlpTracker");
  const usdgAddress = getContract(chainId, "USDG");
  const glpManagerAddress = getContract(chainId, "GlpManager");
  const rewardRouterAddress = getContract(chainId, "RewardRouter");
  const tokensForBalanceAndSupplyQuery = [stakedGlpTrackerAddress, usdgAddress];

  const [_, setSwapOption] = useLocalStorageByChainId(chainId, "Swap-option-v2", LONG);

  const tokenAddresses = tokens.map((token) => token.address);
  const { data: tokenBalances } = useSWR(
    [`GlpSwap:getTokenBalances:${active}`, chainId, readerAddress, "getTokenBalances", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, ReaderV2, [tokenAddresses]),
    }
  );

  const { data: balancesAndSupplies } = useSWR(
    [
      `GlpSwap:getTokenBalancesWithSupplies:${active}`,
      chainId,
      readerAddress,
      "getTokenBalancesWithSupplies",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [tokensForBalanceAndSupplyQuery]),
    }
  );

  const { data: aums } = useSWR([`GlpSwap:getAums:${active}`, chainId, glpManagerAddress, "getAums"], {
    fetcher: contractFetcher(library, GlpManager),
  });

  const { data: totalTokenWeights } = useSWR(
    [`GlpSwap:totalTokenWeights:${active}`, chainId, vaultAddress, "totalTokenWeights"],
    {
      fetcher: contractFetcher(library, VaultV2),
    }
  );

  const tokenAllowanceAddress = swapTokenAddress === AddressZero ? nativeTokenAddress : swapTokenAddress;
  const { data: tokenAllowance } = useSWR(
    [active, chainId, tokenAllowanceAddress, "allowance", account || PLACEHOLDER_ACCOUNT, glpManagerAddress],
    {
      fetcher: contractFetcher(library, Token),
    }
  );

  const { data: lastPurchaseTime } = useSWR(
    [`GlpSwap:lastPurchaseTime:${active}`, chainId, glpManagerAddress, "lastAddedAt", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, GlpManager),
    }
  );

  const { data: glpBalance } = useSWR(
    [`GlpSwap:glpBalance:${active}`, chainId, feeGlpTrackerAddress, "stakedAmounts", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, RewardTracker),
    }
  );

  const glpVesterAddress = getContract(chainId, "GlpVester");
  const { data: reservedAmount } = useSWR(
    [`GlpSwap:reservedAmount:${active}`, chainId, glpVesterAddress, "pairAmounts", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, Vester),
    }
  );

  const glpVesterV1Address = getContract(chainId, "GlpVesterV1");
  const { data: reservedAmountV1 } = useSWR(
    chainId !== BASE && [
      `GlpSwap:reservedAmountV1:${active}`,
      chainId,
      glpVesterV1Address,
      "pairAmounts",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, Vester),
    }
  );

  const { gmxPrice } = useGmxPrice(chainId, { arbitrum: chainId === FANTOM ? library : undefined }, active);

  const rewardTrackersForStakingInfo = [stakedGlpTrackerAddress, feeGlpTrackerAddress];
  const { data: stakingInfo } = useSWR(
    [`GlpSwap:stakingInfo:${active}`, chainId, rewardReaderAddress, "getStakingInfo", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, RewardReader, [rewardTrackersForStakingInfo]),
    }
  );

  const stakingData = getStakingData(stakingInfo);

  const redemptionTime = lastPurchaseTime ? lastPurchaseTime.add(GLP_COOLDOWN_DURATION) : undefined;
  const inCooldownWindow = redemptionTime && parseInt(Date.now() / 1000) < redemptionTime;
  // const inCooldownWindow = false;
  const glpSupply = balancesAndSupplies ? balancesAndSupplies[1] : bigNumberify(0);
  const usdgSupply = balancesAndSupplies ? balancesAndSupplies[3] : bigNumberify(0);
  let aum;
  if (aums && aums.length > 0) {
    aum = isBuying ? aums[0] : aums[1];
  }
  const glpPrice =
    aum && aum.gt(0) && glpSupply.gt(0)
      ? aum.mul(expandDecimals(1, GLP_DECIMALS)).div(glpSupply)
      : expandDecimals(1, USD_DECIMALS);
  let glpBalanceUsd;
  if (glpBalance) {
    glpBalanceUsd = glpBalance.mul(glpPrice).div(expandDecimals(1, GLP_DECIMALS));
  }
  const glpSupplyUsd = glpSupply.mul(glpPrice).div(expandDecimals(1, GLP_DECIMALS));

  let reserveAmountUsd;
  if (reservedAmount) {
    reserveAmountUsd = reservedAmount.mul(glpPrice).div(expandDecimals(1, GLP_DECIMALS));
  }
  var maxSellAmount = glpBalance;
  if (glpBalance && reservedAmount) {
    maxSellAmount = maxSellAmount.sub(reservedAmount);
  }
  if (glpBalance && reservedAmountV1) {
    maxSellAmount = maxSellAmount.sub(reservedAmountV1);
  }
  const { infoTokens } = useInfoTokens(library, chainId, active, tokenBalances, undefined);
  const swapToken = getToken(chainId, swapTokenAddress);
  const swapTokenInfo = getTokenInfo(infoTokens, swapTokenAddress);

  const swapTokenBalance = swapTokenInfo && swapTokenInfo.balance ? swapTokenInfo.balance : bigNumberify(0);

  const swapAmount = parseValue(swapValue, swapToken && swapToken.decimals);
  const glpAmount = parseValue(glpValue, GLP_DECIMALS);

  const needApproval =
    isBuying && swapTokenAddress !== AddressZero && tokenAllowance && swapAmount && swapAmount.gt(tokenAllowance);

  const swapUsdMin = getUsd(swapAmount, swapTokenAddress, false, infoTokens);
  const glpUsdMax = glpAmount && glpPrice ? glpAmount.mul(glpPrice).div(expandDecimals(1, GLP_DECIMALS)) : undefined;

  let isSwapTokenCapReached;
  if (swapTokenInfo.managedUsd && swapTokenInfo.maxUsdgAmount) {
    isSwapTokenCapReached = swapTokenInfo.managedUsd.gt(
      adjustForDecimals(swapTokenInfo.maxUsdgAmount, USDG_DECIMALS, USD_DECIMALS)
    );
  }

  const onSwapValueChange = (e) => {
    setAnchorOnSwapAmount(true);
    setSwapValue(e.target.value);
  };

  const onGlpValueChange = (e) => {
    setAnchorOnSwapAmount(false);
    setGlpValue(e.target.value);
  };

  const onSelectSwapToken = (token) => {
    setSwapTokenAddress(token.address);
    setIsWaitingForApproval(false);
  };

  const nativeToken = getTokenInfo(infoTokens, AddressZero);

  let totalApr = bigNumberify(0);

  let feeGlpTrackerAnnualRewardsUsd;
  let feeGlpTrackerApr;
  if (
    stakingData &&
    stakingData.feeGlpTracker &&
    stakingData.feeGlpTracker.tokensPerInterval &&
    nativeToken &&
    nativeToken.minPrice &&
    glpSupplyUsd &&
    glpSupplyUsd.gt(0)
  ) {
    feeGlpTrackerAnnualRewardsUsd = stakingData.feeGlpTracker.tokensPerInterval
      .mul(SECONDS_PER_YEAR)
      .mul(nativeToken.minPrice)
      .div(expandDecimals(1, 18));
    feeGlpTrackerApr = feeGlpTrackerAnnualRewardsUsd.mul(BASIS_POINTS_DIVISOR).div(glpSupplyUsd);
    totalApr = totalApr.add(feeGlpTrackerApr);
  }

  let stakedGlpTrackerAnnualRewardsUsd;
  let stakedGlpTrackerApr;

  if (
    gmxPrice &&
    stakingData &&
    stakingData.stakedGlpTracker &&
    stakingData.stakedGlpTracker.tokensPerInterval &&
    glpSupplyUsd &&
    glpSupplyUsd.gt(0)
  ) {
    stakedGlpTrackerAnnualRewardsUsd = stakingData.stakedGlpTracker.tokensPerInterval
      .mul(SECONDS_PER_YEAR)
      .mul(gmxPrice)
      .div(expandDecimals(1, 18));
    stakedGlpTrackerApr = stakedGlpTrackerAnnualRewardsUsd.mul(BASIS_POINTS_DIVISOR).div(glpSupplyUsd);
    totalApr = totalApr.add(stakedGlpTrackerApr);
  }

  useEffect(() => {
    const updateSwapAmounts = () => {
      if (anchorOnSwapAmount) {
        if (!swapAmount) {
          setGlpValue("");
          setFeeBasisPoints("");
          return;
        }

        if (isBuying) {
          const { amount: nextAmount, feeBasisPoints: feeBps } = getBuyGlpToAmount(
            swapAmount,
            swapTokenAddress,
            infoTokens,
            glpPrice,
            usdgSupply,
            totalTokenWeights
          );
          const nextValue = formatAmountFree(nextAmount, GLP_DECIMALS, GLP_DECIMALS);
          setGlpValue(nextValue);
          setFeeBasisPoints(feeBps);
        } else {
          const { amount: nextAmount, feeBasisPoints: feeBps } = getSellGlpFromAmount(
            swapAmount,
            swapTokenAddress,
            infoTokens,
            glpPrice,
            usdgSupply,
            totalTokenWeights
          );
          const nextValue = formatAmountFree(nextAmount, GLP_DECIMALS, GLP_DECIMALS);
          setGlpValue(nextValue);
          setFeeBasisPoints(feeBps);
        }

        return;
      }

      if (!glpAmount) {
        setSwapValue("");
        setFeeBasisPoints("");
        return;
      }

      if (swapToken) {
        if (isBuying) {
          const { amount: nextAmount, feeBasisPoints: feeBps } = getBuyGlpFromAmount(
            glpAmount,
            swapTokenAddress,
            infoTokens,
            glpPrice,
            usdgSupply,
            totalTokenWeights
          );
          const nextValue = formatAmountFree(nextAmount, swapToken.decimals, swapToken.decimals);
          setSwapValue(nextValue);
          setFeeBasisPoints(feeBps);
        } else {
          const { amount: nextAmount, feeBasisPoints: feeBps } = getSellGlpToAmount(
            glpAmount,
            swapTokenAddress,
            infoTokens,
            glpPrice,
            usdgSupply,
            totalTokenWeights,
            true
          );

          const nextValue = formatAmountFree(nextAmount, swapToken.decimals, swapToken.decimals);
          setSwapValue(nextValue);
          setFeeBasisPoints(feeBps);
        }
      }
    };

    updateSwapAmounts();
  }, [
    isBuying,
    anchorOnSwapAmount,
    swapAmount,
    glpAmount,
    swapToken,
    swapTokenAddress,
    infoTokens,
    glpPrice,
    usdgSupply,
    totalTokenWeights,
  ]);

  const switchSwapOption = (hash = "") => {
    history.push(`${history.location.pathname}#${hash}`);
    props.setIsBuying(hash === "redeem" ? false : true);
  };

  const fillMaxAmount = () => {
    if (isBuying) {
      setAnchorOnSwapAmount(true);
      setSwapValue(formatAmountFree(swapTokenBalance, swapToken.decimals, swapToken.decimals));
      return;
    }

    setAnchorOnSwapAmount(false);
    setGlpValue(formatAmountFree(maxSellAmount, GLP_DECIMALS, GLP_DECIMALS));
  };

  const getError = () => {
    if (IS_NETWORK_DISABLED[chainId]) {
      if (isBuying) return [`MLP buy disabled, pending ${getChainName(chainId)} upgrade`];
      return [`MLP sell disabled, pending ${getChainName(chainId)} upgrade`];
    }

    if (!isBuying && inCooldownWindow) {
      return [`Redemption time not yet reached`];
    }

    if (!swapAmount || swapAmount.eq(0)) {
      return [`Enter an amount`];
    }
    if (!glpAmount || glpAmount.eq(0)) {
      return [`Enter an amount`];
    }

    if (isBuying) {
      const swapTokenInfo = getTokenInfo(infoTokens, swapTokenAddress);
      if (
        !savedShouldDisableValidationForTesting &&
        swapTokenInfo &&
        swapTokenInfo.balance &&
        swapAmount &&
        swapAmount.gt(swapTokenInfo.balance)
      ) {
        return [`Insufficient ${swapTokenInfo.symbol} balance`];
      }

      if (swapTokenInfo.maxUsdgAmount && swapTokenInfo.usdgAmount && swapUsdMin) {
        const usdgFromAmount = adjustForDecimals(swapUsdMin, USD_DECIMALS, USDG_DECIMALS);
        const nextUsdgAmount = swapTokenInfo.usdgAmount.add(usdgFromAmount);
        if (swapTokenInfo.maxUsdgAmount.gt(0) && nextUsdgAmount.gt(swapTokenInfo.maxUsdgAmount)) {
          return [`${swapTokenInfo.symbol} pool exceeded, try different token`, true];
        }
      }
    }

    if (!isBuying) {
      if (maxSellAmount && glpAmount && glpAmount.gt(maxSellAmount)) {
        return [`Insufficient MLP balance`];
      }

      const swapTokenInfo = getTokenInfo(infoTokens, swapTokenAddress);
      if (
        swapTokenInfo &&
        swapTokenInfo.availableAmount &&
        swapAmount &&
        swapAmount.gt(swapTokenInfo.availableAmount)
      ) {
        return [`Insufficient liquidity`];
      }
    }

    return [false];
  };

  const isPrimaryEnabled = () => {
    if (IS_NETWORK_DISABLED[chainId]) {
      return false;
    }
    if (!active) {
      return true;
    }
    const [error, modal] = getError();
    if (error && !modal) {
      return false;
    }
    if ((needApproval && isWaitingForApproval) || isApproving) {
      return false;
    }
    if (isApproving) {
      return false;
    }
    if (isSubmitting) {
      return false;
    }
    if (isBuying && isSwapTokenCapReached) {
      return false;
    }

    return true;
  };

  const getPrimaryText = () => {
    if (!active) {
      return `Connect Wallet`;
    }
    const [error, modal] = getError();
    if (error && !modal) {
      return error;
    }
    if (isBuying && isSwapTokenCapReached) {
      return `Max Capacity for ${swapToken.symbol} Reached`;
    }

    if (needApproval && isWaitingForApproval) {
      return `Waiting for Approval`;
    }
    if (isApproving) {
      return `Approving ${swapToken.symbol}...`;
    }
    if (needApproval) {
      return `Approve ${swapToken.symbol}`;
    }

    if (isSubmitting) {
      return isBuying ? `Buying...` : `Selling...`;
    }

    return isBuying ? `Buy MLP` : `Sell MLP`;
  };

  const approveFromToken = () => {
    approveTokens({
      setIsApproving,
      library,
      tokenAddress: swapToken.address,
      spender: glpManagerAddress,
      chainId: chainId,
      onApproveSubmitted: () => {
        setIsWaitingForApproval(true);
      },
      infoTokens,
      getTokenInfo,
    });
  };

  const buyGlp = () => {
    setIsSubmitting(true);

    const minGlp = glpAmount.mul(BASIS_POINTS_DIVISOR - savedSlippageAmount).div(BASIS_POINTS_DIVISOR);

    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter.abi, library.getSigner());
    const method = swapTokenAddress === AddressZero ? "mintAndStakeGlpETH" : "mintAndStakeGlp";
    const params = swapTokenAddress === AddressZero ? [0, minGlp] : [swapTokenAddress, swapAmount, 0, minGlp];
    const value = swapTokenAddress === AddressZero ? swapAmount : 0;

    callContract(chainId, contract, method, params, {
      value,
      sentMsg: `Buy submitted.`,
      failMsg: `Buy failed.`,
      successMsg: `${formatAmount(glpAmount, 18, 4, true)} MLP bought with ${formatAmount(
        swapAmount,
        swapTokenInfo.decimals,
        4,
        true
      )} ${swapTokenInfo.symbol}!`,
      setPendingTxns,
    })
      .then(async () => {})
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const sellGlp = () => {
    setIsSubmitting(true);

    const minOut = swapAmount.mul(BASIS_POINTS_DIVISOR - savedSlippageAmount).div(BASIS_POINTS_DIVISOR);

    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter.abi, library.getSigner());
    const method = swapTokenAddress === AddressZero ? "unstakeAndRedeemGlpETH" : "unstakeAndRedeemGlp";
    const params =
      swapTokenAddress === AddressZero ? [glpAmount, minOut, account] : [swapTokenAddress, glpAmount, minOut, account];

    callContract(chainId, contract, method, params, {
      sentMsg: `Sell submitted!`,
      failMsg: `Sell failed.`,
      successMsg: `${formatAmount(glpAmount, 18, 4, true)} MLP sold for ${formatAmount(
        swapAmount,
        swapTokenInfo.decimals,
        4,
        true
      )} ${swapTokenInfo.symbol}!`,
      setPendingTxns,
    })
      .then(async () => {})
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const onClickPrimary = () => {
    if (!active) {
      connectWallet();
      return;
    }

    if (needApproval) {
      approveFromToken();
      return;
    }

    const [, modal] = getError();

    if (modal) {
      setModalError(true);
      return;
    }

    if (isBuying) {
      buyGlp();
    } else {
      sellGlp();
    }
  };

  let payLabel = `Pay`;
  let receiveLabel = `Receive`;
  let payBalance = "$0.00";
  let receiveBalance = "$0.00";
  if (isBuying) {
    if (swapUsdMin) {
      payBalance = `$${formatAmount(swapUsdMin, USD_DECIMALS, 2, true)}`;
    }
    if (glpUsdMax) {
      receiveBalance = `$${formatAmount(glpUsdMax, USD_DECIMALS, 2, true)}`;
    }
  } else {
    if (glpUsdMax) {
      payBalance = `$${formatAmount(glpUsdMax, USD_DECIMALS, 2, true)}`;
    }
    if (swapUsdMin) {
      receiveBalance = `$${formatAmount(swapUsdMin, USD_DECIMALS, 2, true)}`;
    }
  }

  const selectToken = (token) => {
    setAnchorOnSwapAmount(false);
    setSwapTokenAddress(token.address);
    helperToast.success(`${token.symbol} selected in order form`);
  };

  let feePercentageText = formatAmount(feeBasisPoints, 2, 2, true, "-");
  if (feeBasisPoints !== undefined && feeBasisPoints.toString().length > 0) {
    feePercentageText += "%";
  }

  const wrappedTokenSymbol = getWrappedToken(chainId).symbol;
  const nativeTokenSymbol = getNativeToken(chainId).symbol;

  const onSwapOptionChange = (opt) => {
    if (opt === `Sell MLP`) {
      switchSwapOption("redeem");
    } else {
      switchSwapOption();
    }
  };

  let adjustedUsdgSupply = bigNumberify(0);

  for (let i = 0; i < tokenList.length; i++) {
    const token = tokenList[i];
    const tokenInfo = infoTokens[token.address];
    if (tokenInfo && tokenInfo.usdgAmount) {
      adjustedUsdgSupply = adjustedUsdgSupply.add(tokenInfo.usdgAmount);
    }
  }

  let stableGlp = 0;
  let totalGlp = 0;

  tokenList.forEach((token) => {
    const tokenInfo = infoTokens[token.address];
    if (tokenInfo.usdgAmount && adjustedUsdgSupply && adjustedUsdgSupply.gt(0)) {
      const currentWeightBps = tokenInfo.usdgAmount.mul(BASIS_POINTS_DIVISOR).div(adjustedUsdgSupply);
      if (tokenInfo.isStable) {
        stableGlp += parseFloat(`${formatAmount(currentWeightBps, 2, 2, false)}`);
      }
      totalGlp += parseFloat(`${formatAmount(currentWeightBps, 2, 2, false)}`);
    }
  });

  let stablePercentage = totalGlp > 0 ? ((stableGlp * 100) / totalGlp).toFixed(2) : "0.0";

  const getWeightText = (tokenInfo) => {
    if (
      !tokenInfo.weight ||
      !tokenInfo.usdgAmount ||
      !adjustedUsdgSupply ||
      adjustedUsdgSupply.eq(0) ||
      !totalTokenWeights
    ) {
      return "...";
    }

    const currentWeightBps = tokenInfo.usdgAmount.mul(BASIS_POINTS_DIVISOR).div(adjustedUsdgSupply);
    // use add(1).div(10).mul(10) to round numbers up
    const targetWeightBps = tokenInfo.weight.mul(BASIS_POINTS_DIVISOR).div(totalTokenWeights).add(1).div(10).mul(10);

    const weightText = `${formatAmount(currentWeightBps, 2, 2, false)}% / ${formatAmount(
      targetWeightBps,
      2,
      2,
      false
    )}%`;

    return (
      <Tooltip
        handle={weightText}
        className="buy-tooltip"
        position="right-bottom"
        renderContent={() => {
          return (
            <>
              <StatsTooltipRow
                label={`Current Weight`}
                value={`${formatAmount(currentWeightBps, 2, 2, false)}%`}
                showDollar={false}
              />
              <StatsTooltipRow
                label={`Target Weight`}
                value={`${formatAmount(targetWeightBps, 2, 2, false)}%`}
                showDollar={false}
              />
              <div style={{ marginTop: "8px" }} />
              {currentWeightBps.lt(targetWeightBps) && (
                <div className="text-white">
                  {tokenInfo.symbol} is below its target weight.
                  <div style={{ marginTop: "8px" }} />
                  Get lower fees to <Link to="/buy_mlp">buy MLP</Link> with {tokenInfo.symbol},&nbsp; and to{" "}
                  <Link
                    to="/spot-native"
                    onClick={() => {
                      setSwapOption(SWAP);
                    }}
                  >
                    swap
                  </Link>{" "}
                  {tokenInfo.symbol} for other tokens.
                </div>
              )}
              {currentWeightBps.gt(targetWeightBps) && (
                <div className="text-white">
                  <div>
                    {tokenInfo.symbol} is above its target weight.
                    <div style={{ marginTop: "8px" }} />
                    Get lower fees to <Link to="/spot-native">swap</Link> tokens for {tokenInfo.symbol}.
                  </div>
                </div>
              )}
              <div style={{ marginTop: "8px" }} />
              <div>
                <ExternalLink href="https://docs.mummy.finance/mlp">More Info</ExternalLink>
              </div>
            </>
          );
        }}
      />
    );
  };
  return (
    <div className={`GlpSwap ${lightThemeClassName}`}>
      <SwapErrorModal
        isVisible={Boolean(modalError)}
        setIsVisible={setModalError}
        swapToken={swapToken}
        chainId={chainId}
        glpAmount={glpAmount}
        usdgSupply={usdgSupply}
        totalTokenWeights={totalTokenWeights}
        glpPrice={glpPrice}
        infoTokens={infoTokens}
        swapUsdMin={swapUsdMin}
      />
      {/* <div className="Page-title-section"> */}
      {/* <div className="Page-title">{isBuying ? "Buy MLP" : "Sell MLP"}</div> */}
      <div className="note-purchase">
        {isBuying && (
          <div>
            {/* Purchase <a href="https://docs.mummy.finance/mlp" target="_blank" rel="noopener noreferrer">MLP tokens</a> to earn {nativeTokenSymbol} fees from swaps and leverage trading.<br/> */}
            Note that there is a minimum holding time of 2 hours after a purchase.
            <br />
            {/* <div>View <Link to="/earn">staking</Link> page.</div> */}
          </div>
        )}
        {!isBuying && (
          <div>
            Redeem your MLP tokens for any supported asset.{" "}
            {inCooldownWindow && (
              <span>
                MLP tokens can only be redeemed 2 hours after your most recent purchase.
                <br />
                Your last purchase was at {formatDateTime(lastPurchaseTime)}, you can redeem MLP tokens after{" "}
                {formatDateTime(redemptionTime)}.<br />
              </span>
            )}
            {/* <div>View <Link to="/earn">staking</Link> page.</div> */}
          </div>
        )}
      </div>
      {/* </div> */}
      <GlpSwapBox className={`GlpSwap-content ${lightThemeClassName}`}>
        <TokenStatsContainer>
          {/* <div className="App-card-title">
            <PriceContainer>
              <TokenInfo>
                <img src={glp40Icon} alt="tokenIcon" />
                {chainId === FANTOM && <img src={fantom16Icon} alt="chainIcon" />}
                {chainId === OP && <img src={op16Icon} alt="chainIcon" />}
                {chainId === BASE && <img src={base16Icon} alt="baseIcon" />}
                {chainId === ARBITRUM && <img src={arb16Icon} alt="chainIcon" />}
              </TokenInfo>
              <PriceMLP className={lightThemeClassName}>
                MLP price:{" "}
                <span className="value">
                  {glpPrice ? (
                    "$" + formatAmount(glpPrice, USD_DECIMALS, 3, true)
                  ) : (
                    <span className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "3px" }} />
                  )}
                </span>
              </PriceMLP>
            </PriceContainer>
          </div> */}

          <StatCard className={`${!isBuying ? "hasReserved" : ""}`}>
            <StatRow>
              <div className="label">
                <span>MLP Price</span>
              </div>
              <div className="value green">
                {glpPrice ? (
                  "$" + formatAmount(glpPrice, USD_DECIMALS, 3, true)
                ) : (
                  <span className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "3px" }} />
                )}
              </div>
            </StatRow>
            <StatRow>
              <div className="label">
                <span>Stablecoin Percentage</span>
              </div>
              <div className="value">
                {stablePercentage ? (
                  stablePercentage + "%"
                ) : (
                  <span className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "3px" }} />
                )}
              </div>
            </StatRow>
          </StatCard>
          <StatCard className={`${!isBuying ? "hasReserved" : ""}`}>
            {!isBuying && (
              <StatRow>
                <div className="label">
                  <span>Reserved</span>
                </div>
                <div className="value">
                  {reservedAmount ? (
                    <Tooltip
                      handle={`${formatAmount(reservedAmount, 18, 4, true)} MLP`}
                      position="left-bottom"
                      renderContent={() => (
                        <div style={{ fontWeight: 400 }}>
                          <span style={{ fontWeight: 700, color: "#fff" }}>
                            {formatAmount(reservedAmount, 18, 4, true)} MLP
                          </span>{" "}
                          have been reserved for vesting.
                        </div>
                      )}
                    />
                  ) : (
                    <span className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "3px" }} />
                  )}
                </div>
                <div className="desc">${formatAmount(reserveAmountUsd, USD_DECIMALS, 2, true)}</div>
              </StatRow>
            )}
            <StatRow>
              <div className="label">
                <span>APR</span>
              </div>
              <div className="value-apr-container">
                {formatAmount(totalApr, 2, 2, true) !== "0.00" ? (
                  <div className="value green">{formatAmount(totalApr, 2, 2, true)}%</div>
                ) : (
                  <div className="value">
                    <span className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "3px" }} />
                  </div>
                )}
                {formatAmount(totalApr, 2, 2, true) !== "0.00" ? (
                  <div className="desc">
                    {nativeTokenSymbol} {formatAmount(feeGlpTrackerApr, 2, 2, false)}% <br className="br-mobile" />+
                    esMMY {formatAmount(stakedGlpTrackerApr, 2, 2, false)}%
                  </div>
                ) : (
                  <div className="desc">
                    <span className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "3px" }} />
                  </div>
                )}
              </div>
            </StatRow>
            <StatRow>
              <div className="label">
                <span>Total Supply</span>
              </div>
              {formatAmount(glpSupply, GLP_DECIMALS, 4, true) !== "0.0000" ? (
                <div className="value">{formatAmount(glpSupply, GLP_DECIMALS, 0, true)} MLP</div>
              ) : (
                <div className="value">
                  <span className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "3px" }} />
                </div>
              )}
              {formatAmount(glpSupply, GLP_DECIMALS, 4, true) !== "0.0000" ? (
                <div className="desc">${formatAmount(glpSupplyUsd, USD_DECIMALS, 2, true)}</div>
              ) : (
                <div className="desc">
                  <span className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "3px" }} />
                </div>
              )}
            </StatRow>
          </StatCard>
          <StatCard className={`${!isBuying ? "hasReserved" : ""}`}>
            <StatRow>
              <div className="label">
                <span>Wallet</span>
              </div>
              {glpBalance ? (
                <div className="value">{formatAmount(glpBalance, GLP_DECIMALS, 4, true)} MLP</div>
              ) : (
                <div className="value">
                  <span className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "3px" }} />
                </div>
              )}
              {glpBalanceUsd ? (
                <div className="desc">${formatAmount(glpBalanceUsd, USD_DECIMALS, 2, true)}</div>
              ) : (
                <div className="desc">
                  <span className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "3px" }} />
                </div>
              )}
            </StatRow>
            <StatRow>
              <div className="label">
                <span>Staked</span>
              </div>
              {glpBalance ? (
                <div className="value">{formatAmount(glpBalance, GLP_DECIMALS, 4, true)} MLP</div>
              ) : (
                <div className="value">
                  <span className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "3px" }} />
                </div>
              )}
              {glpBalanceUsd ? (
                <div className="desc">${formatAmount(glpBalanceUsd, USD_DECIMALS, 2, true)}</div>
              ) : (
                <div className="desc">
                  <span className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "3px" }} />
                </div>
              )}
            </StatRow>
          </StatCard>
        </TokenStatsContainer>
        <div className="GlpSwap-box App-box">
          <Tab
            options={[`Buy MLP`, `Sell MLP`]}
            option={tabLabel}
            onChange={onSwapOptionChange}
            className="Exchange-swap-option-tabs"
          />
          {isBuying && (
            <BuyInputSection
              topLeftLabel={payLabel}
              topRightLabel={`Balance:`}
              tokenBalance={`${formatAmount(swapTokenBalance, swapToken.decimals, 4, true)}`}
              inputValue={swapValue}
              onInputValueChange={onSwapValueChange}
              showMaxButton={swapValue !== formatAmountFree(swapTokenBalance, swapToken.decimals, swapToken.decimals)}
              onClickTopRightLabel={fillMaxAmount}
              onClickMax={fillMaxAmount}
              selectedToken={swapToken}
              balance={payBalance}
              onlyNumber
            >
              <TokenSelector
                label={`Pay`}
                chainId={chainId}
                tokenAddress={swapTokenAddress}
                onSelectToken={onSelectSwapToken}
                tokens={whitelistedTokens}
                infoTokens={infoTokens}
                className="GlpSwap-from-token"
                showSymbolImage={true}
                showTokenImgInDropdown={true}
              />
            </BuyInputSection>
          )}

          {!isBuying && (
            <BuyInputSection
              topLeftLabel={payLabel}
              topRightLabel={`Available:`}
              tokenBalance={`${formatAmount(maxSellAmount, GLP_DECIMALS, 4, true)}`}
              inputValue={glpValue}
              onInputValueChange={onGlpValueChange}
              showMaxButton={glpValue !== formatAmountFree(maxSellAmount, GLP_DECIMALS, GLP_DECIMALS)}
              onClickTopRightLabel={fillMaxAmount}
              onClickMax={fillMaxAmount}
              balance={payBalance}
              defaultTokenName={"MLP"}
              onlyNumber
            >
              <div className="selected-token">
                <img src={glp24Icon} alt="mlp24Icon" />
                MLP
              </div>
            </BuyInputSection>
          )}

          <div className="AppOrder-ball-container">
            <div className="AppOrder-ball">
              <img
                src={isLightTheme ? arrowIconLight : arrowIcon}
                alt="arrowIcon"
                onClick={() => {
                  setIsBuying(!isBuying);
                  switchSwapOption(isBuying ? "redeem" : "");
                }}
              />
            </div>
          </div>

          {isBuying && (
            <BuyInputSection
              topLeftLabel={receiveLabel}
              topRightLabel={`Balance:`}
              tokenBalance={`${formatAmount(glpBalance, GLP_DECIMALS, 4, true)}`}
              inputValue={glpValue}
              onInputValueChange={onGlpValueChange}
              balance={receiveBalance}
              defaultTokenName={"MLP"}
              onlyNumber
            >
              <div className="selected-token">
                <img src={glp24Icon} alt="mlp24Icon" />
                MLP
              </div>
            </BuyInputSection>
          )}

          {!isBuying && (
            <BuyInputSection
              topLeftLabel={receiveLabel}
              topRightLabel={`Balance:`}
              tokenBalance={`${formatAmount(swapTokenBalance, swapToken.decimals, 4, true)}`}
              inputValue={swapValue}
              onInputValueChange={onSwapValueChange}
              balance={receiveBalance}
              selectedToken={swapToken}
              onlyNumber
            >
              <TokenSelector
                label={`Receive`}
                chainId={chainId}
                tokenAddress={swapTokenAddress}
                onSelectToken={onSelectSwapToken}
                tokens={whitelistedTokens}
                infoTokens={infoTokens}
                className="GlpSwap-from-token"
                showSymbolImage={true}
                showTokenImgInDropdown={true}
              />
            </BuyInputSection>
          )}

          <div>
            <div className="Exchange-info-row">
              <div className="Exchange-info-label">{feeBasisPoints > 50 ? `WARNING: High Fees` : `Fees`}</div>
              <div className="align-right fee-block">
                {isBuying && (
                  <Tooltip
                    handle={isBuying && isSwapTokenCapReached ? "NA" : feePercentageText}
                    position="right-bottom"
                    renderContent={() => {
                      if (!feeBasisPoints) {
                        return (
                          <div className="text-white">
                            <span>Fees will be shown once you have entered an amount in the order form.</span>
                          </div>
                        );
                      }
                      return (
                        <div className="text-white">
                          {feeBasisPoints > 50 && <span>To reduce fees, select a different asset to pay with.</span>}
                          <span>Check the "Save on Fees" section below to get the lowest fee percentages.</span>
                        </div>
                      );
                    }}
                  />
                )}
                {!isBuying && (
                  <Tooltip
                    handle={feePercentageText}
                    position="right-bottom"
                    renderContent={() => {
                      if (!feeBasisPoints) {
                        return (
                          <div className="text-white">
                            <span>Fees will be shown once you have entered an amount in the order form.</span>
                          </div>
                        );
                      }
                      return (
                        <div className="text-white">
                          {feeBasisPoints > 50 && <span>To reduce fees, select a different asset to receive.</span>}
                          <span>Check the "Save on Fees" section below to get the lowest fee percentages.</span>
                        </div>
                      );
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="GlpSwap-cta Exchange-swap-button-container">
            <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
              {getPrimaryText()}
            </button>
          </div>
        </div>
      </GlpSwapBox>
      <div className="Tab-title-section">
        <div className="Page-title">
          <span>Save on Fees</span>
        </div>
        {isBuying && (
          <div className="Page-description">
            <span>
              Fees may vary depending on which asset you use to buy MLP. Enter the amount of MLP you want to purchase in
              the order form, then check here to compare fees.
            </span>
          </div>
        )}
        {!isBuying && (
          <div className="Page-description">
            <span>
              Fees may vary depending on which asset you sell MLP for. Enter the amount of MLP you want to redeem in the
              order form, then check here to compare fees.
            </span>
          </div>
        )}
      </div>
      <div className="DashboardV2-token-cards table-token-container">
        <table className="token-table">
          <thead>
            <tr>
              <th>
                <span>Token</span>
              </th>
              <th>
                <span>Pool</span>
              </th>
              <th>
                <span>Weight</span>
              </th>
              <th>
                <span>Utilization</span>
              </th>
              <th>
                {isBuying ? (
                  <Tooltip
                    handle={`Available`}
                    tooltipIconPosition="right"
                    position="right-bottom text-none"
                    renderContent={() => (
                      <p
                        style={{
                          color: "rgba(255, 255, 255, 0.6)",
                        }}
                        className="text-white"
                      >
                        <span>Available amount to deposit into MLP.</span>
                      </p>
                    )}
                  />
                ) : (
                  <Tooltip
                    handle={`Available`}
                    tooltipIconPosition="right"
                    position="center-bottom text-none"
                    renderContent={() => {
                      return (
                        <p className="">
                          <span>
                            Available amount to withdraw from MLP. Funds not utilized by current open positions.
                          </span>
                        </p>
                      );
                    }}
                  />
                )}
              </th>
              <th>
                <Tooltip
                  handle={`Fees`}
                  tooltipIconPosition="right"
                  position="right-bottom text-none"
                  renderContent={() => {
                    return (
                      <div className="text-white">
                        <span>Fees will be shown once you have entered an amount in the order form.</span>
                      </div>
                    );
                  }}
                />
              </th>
              {/* <th></th> */}
            </tr>
          </thead>
          <tbody>
            {visibleTokens.map((token) => {
              let tokenFeeBps;
              if (isBuying) {
                const { feeBasisPoints: feeBps } = getBuyGlpFromAmount(
                  glpAmount,
                  token.address,
                  infoTokens,
                  glpPrice,
                  usdgSupply,
                  totalTokenWeights
                );
                tokenFeeBps = feeBps;
              } else {
                const { feeBasisPoints: feeBps } = getSellGlpToAmount(
                  glpAmount,
                  token.address,
                  infoTokens,
                  glpPrice,
                  usdgSupply,
                  totalTokenWeights
                );
                tokenFeeBps = feeBps;
              }
              const tokenInfo = getTokenInfo(infoTokens, token.address);
              let managedUsd;
              if (tokenInfo && tokenInfo.managedUsd) {
                managedUsd = tokenInfo.managedUsd;
              }
              let availableAmountUsd;
              if (tokenInfo && tokenInfo.minPrice && tokenInfo.availableAmount) {
                availableAmountUsd = tokenInfo.availableAmount
                  .mul(tokenInfo.minPrice)
                  .div(expandDecimals(1, token.decimals));
              }
              // let balanceUsd;
              // if (tokenInfo && tokenInfo.minPrice && tokenInfo.balance) {
              //   balanceUsd = tokenInfo.balance.mul(tokenInfo.minPrice).div(expandDecimals(1, token.decimals));
              // }
              let isCapReached = tokenInfo.managedAmount?.gt(tokenInfo.maxUsdgAmount);

              let amountLeftToDeposit = bigNumberify(0);
              if (tokenInfo.maxUsdgAmount && tokenInfo.maxUsdgAmount.gt(0)) {
                amountLeftToDeposit = tokenInfo.maxUsdgAmount
                  .sub(tokenInfo.usdgAmount)
                  .mul(expandDecimals(1, USD_DECIMALS))
                  .div(expandDecimals(1, USDG_DECIMALS));
              }
              if (amountLeftToDeposit.lt(0)) {
                amountLeftToDeposit = bigNumberify(0);
              }

              let utilization = bigNumberify(0);
              if (tokenInfo && tokenInfo.reservedAmount && tokenInfo.poolAmount && tokenInfo.poolAmount.gt(0)) {
                utilization = tokenInfo.reservedAmount.mul(BASIS_POINTS_DIVISOR).div(tokenInfo.poolAmount);
              }
              let maxUsdgAmount = DEFAULT_MAX_USDG_AMOUNT;
              if (tokenInfo.maxUsdgAmount && tokenInfo.maxUsdgAmount.gt(0)) {
                maxUsdgAmount = tokenInfo.maxUsdgAmount;
              }
              const tokenImage = importImage("ic_" + token.symbol.toLowerCase() + "_40.svg");

              function renderFees() {
                const swapUrl = `https://app.1inch.io/#/${chainId}/swap/`;
                switch (true) {
                  case (isBuying && isCapReached) || (!isBuying && managedUsd?.lt(1)):
                    return (
                      <Tooltip
                        handle="NA"
                        position="right-bottom"
                        renderContent={() => (
                          <div className="text-white">
                            <div>
                              Max pool capacity reached for {tokenInfo.symbol}
                              <div style={{ marginTop: "8px" }} />
                              Please mint MLP using another token
                            </div>
                            <div style={{ marginTop: "8px" }} />
                            <p>
                              <ExternalLink href={swapUrl}>
                                <span> Swap {tokenInfo.symbol} on 1inch</span>
                              </ExternalLink>
                            </p>
                          </div>
                        )}
                      />
                    );
                  case (isBuying && !isCapReached) || (!isBuying && managedUsd?.gt(0)):
                    return `${formatAmount(tokenFeeBps, 2, 2, true, "-")}${
                      tokenFeeBps !== undefined && tokenFeeBps.toString().length > 0 ? "%" : ""
                    }`;
                  default:
                    return "";
                }
              }
              return (
                <tr key={token.symbol}>
                  <td>
                    <div className="token-symbol-wrapper">
                      <div className="App-card-title-info-icon">
                        <img src={tokenImage} alt={token.symbol} width="40px" />
                      </div>
                      <div className="App-card-title-info-text">
                        <div className="App-card-info-subtitle">
                          {token.name}{" "}
                          <div className="dropdown-clickable">
                            <AssetDropdown assetSymbol={token.symbol} assetInfo={token} />
                          </div>
                        </div>
                        <div className="App-card-info-title">{token.symbol}</div>
                      </div>
                    </div>
                  </td>
                  <td>
                    {tokenInfo?.managedUsd ? (
                      <TooltipComponent
                        handle={`$${formatKeyAmount(tokenInfo, "managedUsd", USD_DECIMALS, 0, true)}`}
                        position="right-bottom"
                        renderContent={() => {
                          return (
                            <>
                              <StatsTooltipRow
                                label={`Pool Amount`}
                                value={`${formatKeyAmount(tokenInfo, "managedAmount", token.decimals, 4, true)} ${
                                  token.symbol
                                }`}
                                showDollar={false}
                              />
                              <StatsTooltipRow
                                label={`Target Min Amount`}
                                value={`${formatKeyAmount(tokenInfo, "bufferAmount", token.decimals, 4, true)} ${
                                  token.symbol
                                }`}
                                showDollar={false}
                              />
                              <StatsTooltipRow
                                label={`Max ${tokenInfo.symbol} Capacity`}
                                value={formatAmount(maxUsdgAmount, 18, 0, true)}
                                showDollar={true}
                              />
                            </>
                          );
                        }}
                      />
                    ) : (
                      <span className="skeleton-box" style={{ width: "60px", height: "20px" }} />
                    )}
                  </td>
                  <td>
                    {getWeightText(tokenInfo) !== "..." ? (
                      getWeightText(tokenInfo)
                    ) : (
                      <span className="skeleton-box" style={{ width: "60px", height: "20px" }} />
                    )}
                  </td>
                  <td>{formatAmount(utilization, 2, 2, false) + "%"}</td>
                  <td>
                    {isBuying && (
                      <div>
                        {!amountLeftToDeposit ? (
                          <span className="skeleton-box" style={{ width: "60px", height: "20px" }} />
                        ) : (
                          <Tooltip
                            handle={
                              amountLeftToDeposit && amountLeftToDeposit.lt(0)
                                ? "$0.00"
                                : `$${formatAmount(amountLeftToDeposit, USD_DECIMALS, 2, true)}`
                            }
                            position="right-bottom"
                            tooltipIconPosition="right"
                            renderContent={() => getTooltipContent(managedUsd, tokenInfo, token)}
                          />
                        )}
                      </div>
                    )}
                    {!isBuying && (
                      <div>
                        {formatAmount(availableAmountUsd, USD_DECIMALS, 2, true) === "--" ? (
                          <span className="skeleton-box" style={{ width: "60px", height: "20px" }} />
                        ) : (
                          <Tooltip
                            handle={
                              availableAmountUsd && availableAmountUsd.lt(0)
                                ? "$0.00"
                                : `$${formatAmount(availableAmountUsd, USD_DECIMALS, 2, true)}`
                            }
                            position="right-bottom"
                            tooltipIconPosition="right"
                            renderContent={() => getTooltipContent(managedUsd, tokenInfo, token)}
                          />
                        )}
                      </div>
                    )}
                  </td>
                  <td>{renderFees()}</td>
                  {/* <td>
                    <button
                      className={cx("App-button-option action-btn", isBuying ? "buying" : "selling")}
                      onClick={() => selectToken(token)}
                    >
                      {isBuying ? `Buy with ${token.symbol}` : `Sell for ${token.symbol}`}
                    </button>
                  </td> */}
                </tr>
              );
            })}
          </tbody>
        </table>
        <TokenGrid>
          {visibleTokens.map((token) => {
            let tokenFeeBps;
            if (isBuying) {
              const { feeBasisPoints: feeBps } = getBuyGlpFromAmount(
                glpAmount,
                token.address,
                infoTokens,
                glpPrice,
                usdgSupply,
                totalTokenWeights
              );
              tokenFeeBps = feeBps;
            } else {
              const { feeBasisPoints: feeBps } = getSellGlpToAmount(
                glpAmount,
                token.address,
                infoTokens,
                glpPrice,
                usdgSupply,
                totalTokenWeights
              );
              tokenFeeBps = feeBps;
            }
            const tokenInfo = getTokenInfo(infoTokens, token.address);
            let managedUsd;
            if (tokenInfo && tokenInfo.managedUsd) {
              managedUsd = tokenInfo.managedUsd;
            }
            let availableAmountUsd;
            if (tokenInfo && tokenInfo.minPrice && tokenInfo.availableAmount) {
              availableAmountUsd = tokenInfo.availableAmount
                .mul(tokenInfo.minPrice)
                .div(expandDecimals(1, token.decimals));
            }
            let balanceUsd;
            if (tokenInfo && tokenInfo.minPrice && tokenInfo.balance) {
              balanceUsd = tokenInfo.balance.mul(tokenInfo.minPrice).div(expandDecimals(1, token.decimals));
            }

            let amountLeftToDeposit = bigNumberify(0);
            if (tokenInfo.maxUsdgAmount && tokenInfo.maxUsdgAmount.gt(0)) {
              amountLeftToDeposit = tokenInfo.maxUsdgAmount
                .sub(tokenInfo.usdgAmount)
                .mul(expandDecimals(1, USD_DECIMALS))
                .div(expandDecimals(1, USDG_DECIMALS));
            }
            if (amountLeftToDeposit.lt(0)) {
              amountLeftToDeposit = bigNumberify(0);
            }
            let isCapReached = tokenInfo.managedAmount?.gt(tokenInfo.maxUsdgAmount);

            function renderFees() {
              switch (true) {
                case (isBuying && isCapReached) || (!isBuying && managedUsd?.lt(1)):
                  return (
                    <Tooltip
                      handle="NA"
                      position="right-bottom"
                      renderContent={() => (
                        <span>
                          Max pool capacity reached for {tokenInfo.symbol}. Please mint MLP using another token
                        </span>
                      )}
                    />
                  );
                case (isBuying && !isCapReached) || (!isBuying && managedUsd?.gt(0)):
                  return `${formatAmount(tokenFeeBps, 2, 2, true, "-")}${
                    tokenFeeBps !== undefined && tokenFeeBps.toString().length > 0 ? "%" : ""
                  }`;
                default:
                  return "";
              }
            }

            let utilization = bigNumberify(0);
            if (tokenInfo && tokenInfo.reservedAmount && tokenInfo.poolAmount && tokenInfo.poolAmount.gt(0)) {
              utilization = tokenInfo.reservedAmount.mul(BASIS_POINTS_DIVISOR).div(tokenInfo.poolAmount);
            }

            let maxUsdgAmount = DEFAULT_MAX_USDG_AMOUNT;
            if (tokenInfo.maxUsdgAmount && tokenInfo.maxUsdgAmount.gt(0)) {
              maxUsdgAmount = tokenInfo.maxUsdgAmount;
            }
            const tokenImage = importImage("ic_" + token.symbol.toLowerCase() + "_24.svg");
            return (
              <div className="App-card" key={token.symbol}>
                <div className="App-card-content">
                  <div className="App-card-row">
                    <div className="label">
                      <span>Token</span>
                    </div>
                    <div>
                      <div className="mobile-token-card">
                        <img src={tokenImage} alt={token.symbol} width="24px" />
                        <div className="token-symbol-text">
                          {token.name}
                          <span className="token-symbol-subtext">{token.symbol}</span>
                        </div>

                        <div>
                          <AssetDropdown assetSymbol={token.symbol} assetInfo={token} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="App-card-row">
                    <div className="label">
                      <span>Pool</span>
                    </div>
                    <div>
                      {tokenInfo.managedUsd ? (
                        <TooltipComponent
                          handle={`$${formatKeyAmount(tokenInfo, "managedUsd", USD_DECIMALS, 0, true)}`}
                          position="right-bottom"
                          renderContent={() => {
                            return (
                              <>
                                <StatsTooltipRow
                                  label={`Pool Amount`}
                                  value={`${formatKeyAmount(tokenInfo, "managedAmount", token.decimals, 4, true)} ${
                                    token.symbol
                                  }`}
                                  showDollar={false}
                                />
                                <StatsTooltipRow
                                  label={`Target Min Amount`}
                                  value={`${formatKeyAmount(tokenInfo, "bufferAmount", token.decimals, 4, true)} ${
                                    token.symbol
                                  }`}
                                  showDollar={false}
                                />
                                <StatsTooltipRow
                                  label={`Max ${tokenInfo.symbol} Capacity`}
                                  value={formatAmount(maxUsdgAmount, 18, 0, true)}
                                  showDollar={true}
                                />
                              </>
                            );
                          }}
                        />
                      ) : (
                        <span className="skeleton-box" style={{ width: "60px", height: "20px" }} />
                      )}
                    </div>
                  </div>
                  <div className="App-card-row">
                    <div className="label">
                      <span>Weight</span>
                    </div>
                    <div>
                      {getWeightText(tokenInfo) !== "..." ? (
                        getWeightText(tokenInfo)
                      ) : (
                        <span className="skeleton-box" style={{ width: "60px", height: "20px" }} />
                      )}
                    </div>
                  </div>
                  <div className="App-card-row">
                    <div className="label">
                      <span>Utilization</span>
                    </div>
                    <div>
                      {formatAmount(utilization, 2, 6, false) !== "0.000000" ? (
                        formatAmount(utilization, 2, 2, false) + "%"
                      ) : (
                        <span className="skeleton-box" style={{ width: "60px", height: "20px" }} />
                      )}
                    </div>
                  </div>
                  {isBuying && (
                    <div className="App-card-row">
                      <Tooltip
                        handle="Available"
                        position="left-bottom"
                        renderContent={() => (
                          <p
                            style={{
                              color: "rgba(255, 255, 255, 0.6)",
                            }}
                            className="text-white"
                          >
                            <span>Available amount to deposit into MLP.</span>
                          </p>
                        )}
                      />
                      <div>
                        {formatAmount(amountLeftToDeposit, USD_DECIMALS, 2, true) !== "0.00" ? (
                          <Tooltip
                            handle={
                              amountLeftToDeposit && `$${formatAmount(amountLeftToDeposit, USD_DECIMALS, 2, true)}`
                            }
                            position="right-bottom"
                            tooltipIconPosition="right"
                            renderContent={() => getTooltipContent(managedUsd, tokenInfo, token)}
                          />
                        ) : (
                          <span className="skeleton-box" style={{ width: "60px", height: "20px" }} />
                        )}
                      </div>
                    </div>
                  )}
                  {!isBuying && (
                    <div className="App-card-row">
                      <div className="label">
                        <Tooltip
                          handle={`Available`}
                          position="left-bottom"
                          renderContent={() => {
                            return (
                              <p className="">
                                <span>
                                  Available amount to withdraw from MLP. Funds not utilized by current open positions.
                                </span>
                              </p>
                            );
                          }}
                        />
                      </div>

                      <div>
                        {formatAmount(availableAmountUsd, USD_DECIMALS, 6, true) !== "--" ? (
                          <Tooltip
                            handle={
                              availableAmountUsd && availableAmountUsd.lt(0)
                                ? "$0.00"
                                : `$${formatAmount(availableAmountUsd, USD_DECIMALS, 2, true)}`
                            }
                            position="right-bottom"
                            tooltipIconPosition="right"
                            renderContent={() => getTooltipContent(managedUsd, tokenInfo, token)}
                          />
                        ) : (
                          <span className="skeleton-box" style={{ width: "60px", height: "20px" }} />
                        )}
                      </div>
                    </div>
                  )}
                  <div className="App-card-row">
                    {tokenFeeBps ? (
                      `Fees`
                    ) : (
                      <Tooltip
                        handle="Fees"
                        renderContent={() => (
                          <p className="">
                            <span>Fees will be shown once you have entered an amount in the order form.</span>
                          </p>
                        )}
                      />
                    )}
                    <div>{renderFees()}</div>
                  </div>
                  {/* <div className="App-card-options">
                    {isBuying && (
                      <button className="App-button-option App-card-option" onClick={() => selectToken(token)}>
                        <span>Buy with {token.symbol}</span>
                      </button>
                    )}
                    {!isBuying && (
                      <button className="App-button-option App-card-option" onClick={() => selectToken(token)}>
                        <span>Sell for {token.symbol}</span>
                      </button>
                    )}
                  </div> */}
                </div>
              </div>
            );
          })}
        </TokenGrid>
      </div>
    </div>
  );
}

const PriceMLP = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  .value {
    font-weight: 700;
    color: #03f5ae;
  }

  &.theme--light {
    .value {
      color: var(--Primary, #02b27f);
    }
  }
`;

const TokenInfo = styled.div`
  width: 36px;
  height: 36px;
  position: relative;

  img {
    width: 100%;
    height: 100%;
  }

  img:not([alt="tokenIcon"]) {
    width: 16px;
    height: 16px;
    position: absolute;
    bottom: 0;
    right: 0;
  }
`;

const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StatRow = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 4px;

  &:not():first-child {
    padding-left: 24px;
  }

  .label {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
  }

  .value {
    font-weight: 700;
    font-size: 16px;
    line-height: 140%;

    &.green {
      color: #03f5ae;
    }
  }

  .desc {
    color: #ffffff99;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
  }

  @media (max-width: 767px) {
    .value {
      font-size: 14px;
    }
  }
`;

const StatCard = styled.div`
  padding: 24px;
  border-radius: 16px;
  background-image: linear-gradient(to left, #002e58cc, #191b2ecc);
  display: flex;

  &.hasReserved {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0;

    @media (max-width: 767px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  > div {
    flex: 1;
  }

  @media (max-width: 767px) {
    padding: 16px;
    gap: 16px;
  }
`;

const TokenStatsContainer = styled.div`
  .Tooltip-popup {
    width: fit-content !important;
  }

  width: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  gap: 24px;
  background-color: transparent !important;
  position: relative;
  padding: 0 !important;
  /* padding-top: 60px !important; */

  @media (max-width: 767px) {
    gap: 16px;
  }

  .App-card-title {
    position: absolute;
    top: 0;

    .App-card-title-mark-icon {
      max-height: 36px;
    }

    .App-card-title-mark-info {
      display: flex;
      align-items: center;
      gap: 4px;

      .value {
        color: #34f5ae;
      }
    }
  }

  .App-card-content {
    padding: 24px;
    border-radius: 16px;
    background-image: linear-gradient(to left, #002e58cc, #191b2ecc);
    display: flex;

    > div {
      flex: 1;
    }

    .App-card-row {
      display: flex;
      flex-direction: column;
      gap: 4px;

      > * {
        text-align: left;
      }

      .label {
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
      }

      .value {
        font-weight: 700;
        font-size: 16px;
        line-height: 140%;

        @media (max-width: 767px) {
          font-size: 14px;
        }
      }

      .desc {
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        color: #ffffff99;
      }
    }

    @media (max-width: 767px) {
      gap: 16px;
    }
  }
`;

const TokenGrid = styled.div`
  display: none;

  .App-card-row > .Tooltip {
    .Tooltip-handle {
      color: #ffffff99;
    }
  }

  @media (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .mobile-token-card {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .dropdown-arrow.center-both {
      margin-left: 0;
    }

    .token-symbol-text {
      display: flex;
      align-items: center;
    }

    .token-symbol-subtext {
      font-weight: 400;
      font-size: 12px;
      line-height: 140%;
      color: #aca4a4;
      margin-left: 4px;
    }
  }
`;

const GlpSwapBox = styled.div`
  &.theme--light {
    .heading-desc div {
      color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7));
    }

    ${StatCard} {
      border: 1px solid var(--Border, rgba(13, 26, 22, 0.07));
      background: linear-gradient(to left, #f2fffb 0%, #daf2eb 100%);
    }

    .Exchange-swap-balance {
      color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7));
    }

    ${StatRow} {
      .label span,
      .desc {
        color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7));
      }

      .value.green,
      .stake-now {
        color: var(--Primary, #02b27f);
      }
    }

    .GlpSwap-box {
      border: 1px solid var(--Border, rgba(13, 26, 22, 0.07));
      background: var(--Nature-1, #fff);

      .Exchange-swap-option-tabs {
        background-color: transparent;
      }

      .stats {
        .row {
          .title {
            color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7));
          }

          .value {
            color: var(--Text-Text_Primary, #0d1a16);
          }
        }
      }
    }
  }
`;
