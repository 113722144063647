import React from "react";
import styled from "styled-components";

import ExternalLink from "components/ExternalLink/ExternalLink";

import share from "img/share.svg";
import { formatAmount } from "lib/numbers";
import moment from "moment";

const Wrapper = styled.div`
  background: #212333;
  border-radius: 16px;
  overflow: hidden;

  &:not(:first-child) {
    margin-top: 10px;
  }

  &.custom-history {
    @media screen and (max-width: 1023px) {
      .body-position-mobile-wrapper {
        gap: 12px;
      }
    }
  }
`;

const Header = styled.div`
  /* background: #211f45; */
  padding: 14px;
  padding-bottom: 14px;
  border-bottom: 1px dashed hsla(0, 0%, 100%, 0.062745098);
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Body = styled.div`
  /* background-color: #29275c; */
  padding: 14px;

  display: flex;
  flex-direction: column;
  gap: 8px;

  > * {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &::before {
      color: hsla(0, 0%, 100%, 0.7);
      content: attr(data-name);
    }
  }
`;

const StyledExternalLink = styled(ExternalLink)`
  &:hover {
    img {
      filter: invert(82%) sepia(64%) saturate(513%) hue-rotate(87deg) brightness(98%) contrast(97%);
    }
  }
`;

const calcPnl = (realisedPnl) => {
  return formatAmount(realisedPnl, 30, 3, true);
};

const isPnlPlus = (realisedPnl) => {
  const pnl = calcPnl(realisedPnl);

  return !pnl.includes("-");
};
const renderType = (item) => {
  switch (item.type) {
    case "PartialClosePosition":
      return "Decrease";
    case "WithdrawCollateralPosition":
    case "DepositCollateralPosition":
      return "Edit Collateral";
    case "OpenPosition":
      return "New";
    case "ClosePosition":
      return "Close";
    case "LiquidatePosition":
      return "Liquidated";
    case "IncreasePosition":
      return "Increase";
    case "DecreasePosition":
      return "Decrease";
    default:
      return "";
  }
};
const MobilePositionHistoryItem = ({ position, className, isNewStyle }) => {
  return (
    <Wrapper className={`${className} position-history-mobile-wrapper`}>
      {!isNewStyle && (
        <Header>
          <span> {renderType(position)}</span>
          <StyledExternalLink href={`https://ftmscan.com/tx/${position.txhash}`}>
            <img src={share} alt="share" />
          </StyledExternalLink>
        </Header>
      )}
      <Body className="body-position-mobile-wrapper">
        <div data-name="Timestamp">{moment(position.timestamp * 1000).format("YYYY-MM-DD HH:mm:ss")}</div>
        <div data-name="Action">{renderType(position)}</div>

        {position?.collateralValue != 0 ? (
          <div data-name="Collateral Delta">
            {formatAmount(position?.collateralValue, 30, 2, true) !== "0.000"
              ? position?.type === "OpenPosition" ||
                position?.type === "DepositCollateralPosition" ||
                position?.type === "IncreasePosition"
                ? "+"
                : "-"
                ? "+"
                : "-"
              : ""}
            ${formatAmount(position?.collateralValue, 30, 2, true)}
          </div>
        ) : (
          <div data-name="Collateral Delta"></div>
        )}

        {position?.sizeDelta != 0 &&
          position?.type !== "DepositCollateralPosition" &&
          position?.type !== "WithdrawCollateralPosition" && (
            <div data-name="Size Delta">
              {formatAmount(position?.sizeDelta, 30, 2, true) !== "0.000"
                ? position?.type === "OpenPosition" ||
                  position?.type === "DepositCollateralPosition" ||
                  position?.type === "IncreasePosition"
                  ? "+"
                  : "-"
                  ? "+"
                  : "-"
                : ""}
              ${formatAmount(position?.sizeDelta, 30, 2, true)}
            </div>
          )}

        <div data-name="Paid Fee">{position?.fee ? "$" + formatAmount(position?.fee, 30, 2, true) : ""}</div>
        <div data-name="Executed Price">${formatAmount(position?.price, 30, 2, true)}</div>
        <div data-name="">
          <StyledExternalLink href={`https://ftmscan.com/tx/${position.txhash}`}>
            <img src={share} alt="share" width={16} height={16} />
          </StyledExternalLink>
        </div>
      </Body>
    </Wrapper>
  );
};

export default MobilePositionHistoryItem;
