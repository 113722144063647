import React, { memo } from "react";

const IconCopy = ({ noOpacity = false, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width ?? "24"}
    height={props.height ?? "24"}
    viewBox="0 0 24 24"
    fill="none"
    className={props.class}
    {...props}
  >
    <path
      d="M4 2C2.895 2 2 2.895 2 4V17C2 17.552 2.448 18 3 18C3.552 18 4 17.552 4 17V4H17C17.552 4 18 3.552 18 3C18 2.448 17.552 2 17 2H4ZM8 6C6.895 6 6 6.895 6 8V20C6 21.105 6.895 22 8 22H20C21.105 22 22 21.105 22 20V8C22 6.895 21.105 6 20 6H8ZM8 8H20V20H8V8Z"
      fill={props.fill ?? "white"}
      fill-opacity={noOpacity ? "1" : "0.6"}
    />
  </svg>
);
export default memo(IconCopy);
